// ** React Imports
import { useEffect, useState } from "react";

// ** MUI Imports
import { Box, Step, StepLabel, Stepper } from "@mui/material";

// ** Third Party Library
import { useNavigate } from "react-router-dom";

// ** Redux
import { useAppDispatch, useAppSelector } from "store/store";
import {
  getBrandInfo,
  draftData,
  publishContentTypeCreateJob,
  clearDraft,
} from "store/Thunk/createJobThunk";

// ** Custom Components
import { route_info } from "navigation/route";
import StepperWrapper from "@core/styles/mui/stepper";
import { stepper as steps } from "@core/constants/global-constants";
import CompleteBrandProfileDialog from "components/Dialogs/CompleteBrandProfile";
import BillingDetails from "pages/marketplace/request-content/create-job/BillingDetails";
import BudgetDivider from "pages/marketplace/request-content/create-job/BudgetDivider";
import JobDetail from "pages/marketplace/request-content/create-job/JobDetail";
import Placement from "pages/marketplace/request-content/create-job/Placement";
import Requirements from "pages/marketplace/request-content/create-job/Requirements";
import StepperCustomDot from "pages/marketplace/request-content/create-job/StepperCustomDot";
import ClearDraftConfirm from "pages/marketplace/request-content/create-job/dialogs/ClearDraftConfirm";

export default function CreateNewJob() {
  const navigate: any = useNavigate();
  const dispatch: any = useAppDispatch();
  let header: any = localStorage.getItem("header");
  let brandData: any = JSON.parse(header).brand;

  let jobBrandProfile: any = useAppSelector(
    (state: any) => state.createJobReducer.jobBrandDetail
  );

  let { storedDraftData }: any = useAppSelector(
    (state: any) => state.createJobReducer
  );

  const [isClearDraftConfirm, setIsClearDraftConfirm] =
    useState<boolean>(false);
  const [openRedirectToCreate, setOpenRedirectToCreate] =
    useState<boolean>(false);
  const [step, setStep] = useState<number>(0);
  const [state, setState] = useState({
    draft_id: null,
    placement: null,
    requirements: {},
    jobDetails: null,
    selectedBudget: null,
    billingDetails: {
      cardNumber: null,
      cardExpDate: null,
      cardCVV: null,
      billingAddress: null,
      cardAddition: null,
      cardCity: null,
      cardPostalCode: null,
      cardCountry: null,
      paymentway: null,
      saveBillingDetails: false,
      payment: {
        payment_type: null,
      },
    },
    thumbnail_url: null,
    brand_id: null,
  });

  useEffect(() => {
    if (brandData && brandData.length <= 0) {
      setOpenRedirectToCreate(true);
    }
  }, [brandData]);

  useEffect(() => {
    dispatch(getBrandInfo(dispatch));
    dispatch(draftData(dispatch));
  }, []);

  useEffect(() => {
    if (storedDraftData && storedDraftData.length > 0) {
      setStep(storedDraftData[0].current_page);
      setState({
        ...state,
        draft_id: storedDraftData[0].id,
        placement: storedDraftData[0].draft_data.placement,
        requirements: storedDraftData[0].draft_data.requirements,
        jobDetails: storedDraftData[0].draft_data.jobDetails,
        selectedBudget: storedDraftData[0].draft_data.selectedBudget,
        billingDetails: storedDraftData[0].draft_data.billingDetails,
        thumbnail_url: storedDraftData[0].thumbnail_url,
      });
      dispatch(
        publishContentTypeCreateJob(
          storedDraftData[0].draft_data.placement?.selectedPublishContent
        )
      );
    }
  }, [storedDraftData]);

  useEffect(() => {
    if (jobBrandProfile && jobBrandProfile.length <= 0) {
      setOpenRedirectToCreate(true);
    } else {
      setState({
        ...state,
        brand_id: jobBrandProfile && jobBrandProfile[0].id,
      });
    }
  }, [jobBrandProfile]);

  const handledForwardButtonClick = () => {
    if (state.requirements === null) {
      setState({ ...state, requirements: {} });
    }
    if (step <= steps.length) {
      let data = {
        current_page: step + 1,
        draft_data: state,
      };
      setStep(step + 1);
      dispatch(draftData({ data }))
        .then((response: any) => {
          let temp = response.payload.data.data[0];
          setState({
            ...state,
            thumbnail_url: temp?.thumbnail_url,
            draft_id: temp?.id,
          });
        })
        .catch((err: any) => {});
    }
    // dispatch(draftData(dispatch));
  };

  const handledBackwardButtonClick = () => {
    setStep(step - 1);
  };

  const handledStartButtonClick = () => {
    setStep(0);
  };

  const project = () => {
    switch (step) {
      case 0:
        return (
          <Placement
            onForwardButtonClick={handledForwardButtonClick}
            onClearDraftButtonClick={handleClearDraft}
            state={state}
            setState={setState}
          />
        );
      case 1:
        return (
          <Requirements
            onForwardButtonClick={handledForwardButtonClick}
            onBackwardButtonClick={handledBackwardButtonClick}
            onClearDraftButtonClick={handleClearDraft}
            state={state}
            setState={setState}
          />
        );
      case 2:
        return (
          <JobDetail
            onForwardButtonClick={handledForwardButtonClick}
            onBackwardButtonClick={handledBackwardButtonClick}
            onClearDraftButtonClick={handleClearDraft}
            state={state}
            setState={setState}
          />
        );
      case 3:
        return (
          <BudgetDivider
            onForwardButtonClick={handledForwardButtonClick}
            onBackwardButtonClick={handledBackwardButtonClick}
            onClearDraftButtonClick={handleClearDraft}
            state={state}
            setState={setState}
          />
        );
      case 4:
        return (
          <BillingDetails
            onForwardButtonClick={handledForwardButtonClick}
            onBackwardButtonClick={handledBackwardButtonClick}
            onClearDraftButtonClick={handleClearDraft}
            state={state}
            handledStartButtonClick={handledStartButtonClick}
          />
        );
      default:
        return <h1>No project match</h1>;
    }
  };

  const handleConfirmClearDraft = () => {
    dispatch(clearDraft({ id: state.draft_id })).then((res: any) => {
      setStep(0);
      handleClearDraft();
      setState({
        draft_id: null,
        placement: null,
        requirements: {},
        jobDetails: null,
        selectedBudget: null,
        billingDetails: {
          cardNumber: null,
          cardExpDate: null,
          cardCVV: null,
          billingAddress: null,
          cardAddition: null,
          cardCity: null,
          cardPostalCode: null,
          cardCountry: null,
          paymentway: null,
          saveBillingDetails: false,
          payment: {
            payment_type: null,
          },
        },
        brand_id: null,
        thumbnail_url: null,
      });
    });
  };

  const handleCompleteBrandProfileClick = () => {
    setOpenRedirectToCreate(false);
    navigate(route_info.market_place.my_brand_profile);
  };

  const handleClearDraft = () => {
    setIsClearDraftConfirm(!isClearDraftConfirm);
  };

  return (
    <Box className="create-job-block">
      <Box
        sx={{
          width: "100%",
          px: { md: "100px", xs: "20px" },
        }}
      >
        <StepperWrapper>
          <Stepper activeStep={step} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel StepIconComponent={StepperCustomDot}>
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </StepperWrapper>
      </Box>

      <Box>{project()}</Box>

      <CompleteBrandProfileDialog
        isOpen={openRedirectToCreate}
        onClickClose={handleCompleteBrandProfileClick}
      />

      <ClearDraftConfirm
        isOpen={isClearDraftConfirm}
        onCancelClick={handleClearDraft}
        onConfirmClick={handleConfirmClearDraft}
      />
    </Box>
  );
}
