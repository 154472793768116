// ** MUI Imports
import { Box, Typography, useTheme } from "@mui/material";

// ** Custom Components
import { STATUS, TITLE } from "@core/constants/enum";

// ** Assets
import { Pending, Approve, Decline, BlankImage } from "assets";

export default function SubmissionStatusCard(props: any) {
  const theme = useTheme();
  const { status } = props;

  return (
    <Box className="submission-status-block">
      <Box
        className="status-img"
        component="img"
        src={
          status === STATUS.APPROVAL_NEEDED ||
          status === STATUS.CREATOR_POST_PENDING ||
          status === STATUS.POST_CONFIRMATION_PENDING
            ? Pending
            : status === STATUS.ACCEPTED
            ? Approve
            : status === STATUS.DECLINED
            ? Decline
            : BlankImage
        }
      />
      <Typography
        variant="body2"
        sx={{
          color: theme.palette.customColors.white,
        }}
      >
        {status !== STATUS.DECLINED
          ? "Submission " +
            (status === STATUS.APPROVAL_NEEDED ||
            status === STATUS.CREATOR_POST_PENDING ||
            status === STATUS.POST_CONFIRMATION_PENDING
              ? TITLE.PENDING
              : status === STATUS.ACCEPTED
              ? TITLE.APPROVED
              : TITLE.DECLINED)
          : "Post Disapproved"}
      </Typography>
    </Box>
  );
}
