import { ChangeEvent, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { CircularProgress, Typography } from "@mui/material";
import Validations from "helper/validations";
import { useAppDispatch, useAppSelector } from "store/store";
import { enterpriseSendMail } from "store/Thunk/subscriptionThunk";

export default function ContactDialog({ open, handleClose }: any) {
  const [values, setValues] = useState<any>({
    monthly_adspend: "",
    note: "",
  });
  const [formError, setFormError] = useState<any>({});
  const { screenLoading } = useAppSelector((state) => state.authReducer);

  const dispatch = useAppDispatch();

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSendMessage = () => {
    let _request = {
      monthly_adspend: values.monthly_adspend.trim(),
      note: values.note.trim(),
    };
    let error = Validations.validateContactDialogForm(_request);

    if (Object.entries(error).length === 0 && error.constructor === Object) {
      dispatch(enterpriseSendMail(_request));
      handleClose();
    }
    setFormError(error);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs">
      <DialogContent>
        <Typography
          variant="body1"
          sx={{ fontWeight: 700, fontSize: "24px", lineHeight: "32px" }}
        >
          Glad to see you are considering an Enterprise upgrade!
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontWeight: 400,
            fontSize: "14px",
            lineHeight: "20px",
            my: "12px",
          }}
        >
          Tell us your estimated monthly ad spend below and add some additional
          notes if needed. We will contact you within 2 work days to discuss
          your needs.
        </Typography>
        <TextField
          fullWidth
          placeholder="Your estimated monthly ad spend"
          name="monthly_adspend"
          value={values.monthly_adspend}
          sx={{ mt: 2 }}
          inputProps={{ min: 0 }}
          type="number"
          onChange={(e: any) => handleChange(e)}
          error={formError && formError.monthly_adspend ? true : false}
          helperText={formError && formError.monthly_adspend}
        />
        <TextField
          fullWidth
          multiline
          rows={4}
          placeholder="Additional notes..."
          name="note"
          value={values.note}
          sx={{ mt: 2 }}
          onChange={(e: any) => handleChange(e)}
          //   error={formError && formError.note ? true : false}
          //   helperText={formError && formError.note}
        />
      </DialogContent>
      <DialogActions>
        <Button fullWidth variant="outlined" onClick={handleClose}>
          Cancel
        </Button>
        <Button
          fullWidth
          variant="contained"
          onClick={handleSendMessage}
          sx={{
            background:
              "linear-gradient(87.87deg, #FF4D49 0%, #666CFF 53.65%, #26C6F9 100%)",
          }}
        >
          {screenLoading ? <CircularProgress size={20} /> : "Send Message"}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
