export const MARKET_PLACE = {
  CREATE_CONTENT: {
    AMOUNT_IN_USD: "AMOUNT IN USD",
    ALLOW_JPG_PNG: "Allowed JPG or PNG. Max size of 800K",

    BACK_TO_OVERVIEW: "Back to overview",
    BANK_NAME: "Bank name:",
    BRAND: "BRAND",
    BUDGET: "Budget",
    BILL_TO: "Bill To:",

    CONFIRM_PAYOUT: "Confirm payout",
    CONNECT_INSTAGRAM: "Connect Instagram",
    CONNECT_INSTAGRAM_DESCRIPTION:
      "You are about to connect your Instagram account to Creator Marketplace. Here are the requirements:",
    CONNECT_SOCIAL_ACCOUNT: "Connect at least one social account",
    CONNECT_WITH: "Connect with",
    CONNECT_YOUR_PAYPAL: "Connect your PayPal to collect your earnings",
    COUNTRY: "Country:",
    COMPLETE_YOUR_CREATOR_PROFILE: "Complete your Creator Profile",
    COMPLETE_CREATOR_PROFILE: "Complete creator profile",
    COMPLETE_YOUR_CREATOR_DESCRIPTION:
      "To upload your content you must first complete your Creator Profile so your socials will be connected and your billing information is linked.",
    COLLECT_PAYOUT_VIEW:
      "Collect payouts and view your earnings and past transactions",
    CURRENT_BALANCE: "Current Balance",

    DATE: "DATE",
    DATE_EARNED: "DATE EARNED",
    DATE_DUE: "Date Due:",
    DATE_ISSUED: "Date Issued:",
    DETAILS: "DETAILS",
    DESCRIPTION: "DESCRIPTION",
    DISCARD: "Discard",
    DISCOUNT: "Discount:",
    DOWNLOAD: "Download",
    DROP_FILES_HERE: "Drop files here or click to upload.",

    EARNED: "Earned",

    FACEBOOK_LOGIN: "facebook login",
    FEEDBACK: "Feedback",

    IBAN: "IBAN:",
    INFORMATION_NEEDED_TO_BE_ABLE_TO_UPLOAD:
      "Information needed to be able to upload content to jobs",
    INVOICE: "Invoice",
    INVOICE_TO: "Invoice To:",

    JOB: "JOB",
    JOB_DETAILS: "Job details",
    JOB_MILESTONES: "Job milestones",
    JOB_REQUIREMENTS: "Job requirements",

    MAKET_SURE_THAT_YOU_FOLLOW:
      "Make sure that you follow up on the brand's feedback before resubmitting!",
    MILESTONES: "Milestones",
    MY_CREATOR_PROFILE: "My Creator Profile",
    MY_EARNING: "My Earnings",

    NEW_JOBS_FOR_YOU: "New jobs for you",
    NO_EARNING: "No earnings available.",
    NO_SUBMISSION_FOUND: "No Submission found",

    ONLY_VIDEO_ALLOWED: "Only videos allowed: .mp4, .wav, .mov",

    PAST_THE_LINK: "Paste the link to your social post here.",
    PAY_OUT: "Pay out",
    PAYING_ACCOUNT: "You will be paid out on the following paying accoung:",
    PAYOUT_SUCCESSFUL: "Payout successful 🎉",
    PRINT: "Print",

    RESET: "Reset",
    RESUBMIT_VIDEO: "RESUBMIT VIDEO",

    SAVE: "SAVE",
    SORT_BY_CATEGORY: "Sort jobs by category",
    SUBMISSIONS: "Submissions",
    SUBMISSION_HISTORY: "Submission history",
    SUBTOTAL: "Subtotal:",
    SUBMIT_LINK_TO_SOCIAL_POST: "Submit link to social post",
    STATUS: "STATUS",
    SWIFT_CODE: "SWIFT code:",

    TAX: "Tax:",
    THE_CREATOR_DID_NOT_SHOW:
      "The creator did not show our logo long enough in their video.",
    THE_EVERYWHEREAI_TEAM_WOULD:
      "The Kanhasoft team would like to thank you for your effort and participation in Creator Marketplace jobs. Bringing content creators and brands closer to create meaningful content together.",
    THERE_ARE_NO_EARNING:
      "There are no earnings to display here. Engage in jobs to start earning.",
    TOTAL: "Total:",
    TOTAL_EARNING: "Total Earnings",
    TOTAL_DUE: "Total Due:",
    TRANSACTIONS: "Transactions",
    TYPE_JOB: "TYPE JOB",
    TYPE: "TYPE",

    UPLOAD_PROFILE_PICTURE: "Upload profile picture",
    UPLOAD_YOUR_CONTENT: "Upload your content",

    VIEWS: "Views",
    VIEW_YOUR_SUBMISSIONS_PROGRESS:
      "View your submissions and their progress per type of job",
    VIEW_SUBMISSION: "View Submissions",
    VIDEO_SUBMITTED: "video Submitted",
    VIEW_EARNINGS: "VIEW EARNINGS",
    VIEWABLE_IN_YOUR_PAYPAL:
      "Your earning will be viewable in your Paypal account.",

    YOUR_SUBMISSION: "Your submission",
  },

  REQUEST_CONTENT: {
    ACTIVE_JOBS: "Active Jobs",
    ARCHIVED_JOBS: "Archived jobs",

    BILLING_DETAILS: "Billing details",
    BILLING_DETAILS_SUBTITLE:
      "Provide your credit card information or connect your Paypal account.",
    BUDGET_DIVIDER: "Budget divider",
    BUDGET_DIVIDER_SUBTITLE:
      "Maximum you want to spend in USD per approved video",

    CANNOT_CLOSE_JOB: "You cannot close this job",
    CANNOT_CLOSE_JOB_DESCRIPTION:
      "There are still active submissions that creators have posted to their channels.",
    CLEAR_DRAFT: "Clear Draft",
    CLOSE_THIS_JOB_TITLE: "Are you sure you want to close this job?",
    CLOSE_THIS_JOB_DESCRIPTION:
      "Creators that have posted their submission will receive a notification. If you still have budget left in this job, it will appear in your account as credit to use for your next job.",
    CLOSE_THIS_JOB: "close this job",
    COLLECT_VIDEO_AND_POST_SUBTITLE:
      "Select for which platform you are collecting content",
    COLLECT_VIDEO_AND_POST_TITLE:
      "On which platform do you want the creator to post their submission?",
    COLLECT_VIDEO_ONLY_TITLE: "What content do you want to receive?",
    COLLECT_VIDEO_ONLY_SUBTITLE:
      "Select the dimension for which you want to collect videos",
    COMPLETE_YOUR_BRAND_PROFILE: "Complete your Brand Profile",
    COMPLETE_BRAND_PROFILE_SUBTITLE:
      "To create a job you must first complete your Brand Profile so that creators can view information about your brand in the job description.",
    COMPLETE_BRAND_PROFILE: "Complete brand profile",
    COPY_SHARE_LINK: "Copy share link",
    CREATE_NEW_JOB: "create new job",

    DECLINE_SUBMISSION: "Decline submission",
    DOWNLOAD_ALL: "Download all",
    DROP_FILES_HERE: "Drop files here or click to upload.",

    FEEDBACK_THE_CREATOR: "Feedback the creator",
    FEEDBACK_THE_CREATOR_DESCRIPTION:
      "If you want to decline this submission, you must let the creator know why you have made this decision and how they can improve their content in the future.",
    FINISH: "FINISH",
    FILE_SIZE: "File size max. 2MB",

    IT_IS_PRETTY: "It is pretty empty here...",

    JOB_DESCRIPTION: "Create a description for your job",
    JOB_ERROR: "Title, Description, Thumbnail and Category fields are required",
    JOB_IS_CREATED: "Your job is created 🎉",
    JOB_IS_NOW_VIEWABLE:
      "Your job is now viewable for creators worldwide. Spread the word!",
    JOB_REQUIREMENT: "Job requirements",
    JOB_REQUIREMENT_SUBTITLE:
      "Set the requirements that you want creators to follow",

    LINK_COPIED: "link copied!",

    MY_BRAND_PROFILE: "My Brand Profile",
    MY_BRAND_PROFILE_SUBTITLE:
      "Information about your brand that will be in your job descriptions",

    NO_DATA:
      "No history available for the selected category at the moment. Please check back later.",
    NO_SUBMISSION:
      "No submissions available for the selected category at the moment. Please check back later.",
    NO_JOBS: "There are currently no jobs in this category",

    PAST_SUBMISSION: "Past submissions",
    PLACEMENT_TITLE: "How do you want the content to be published?",
    PLACEMENT_SUBTITLE:
      "Select if you want the creator to post the video as well or if you just want to collect content",

    RECOMMENDED_SIZE: "Recommended size: 4:1",

    SUBMISSION_DECLINED: "Submission declined",
    SUBMISSION_DECLINED_DESCRIPTION:
      "Thank you for your feedback, the creator will be notified.",

    THESE_CREATORS_HAVE_SUBMITTED:
      "These creators have submitted their content and are waiting for your approval",
    TOTAL_PAYMENT: "Total payment",
    TO_START_COLLECTING: "to start collecting content",

    UPLOAD_JOB_THUMBNAIL: "Upload job thumbnail",
    UPLOAD_YOUR_LOGO: "Upload your logo",
  },
};

export const MEDIA_PLANNER = {
  auto_scheduler: {
    ADD_COUNTRY: "Add Country",
    AD_ACCOUNT: "Advertiser account",
    AUTO_GENERATE: "Auto-generate campaign name",
    AD_SETTINGS: "3. Ad settings",
    ACCOUNT_DETAILS: "1. Account details",
    AGE_GROUP: "Age groups *",
    AGE_GROUP_REVIEW: "Age group",
    ACCELERATE_SPEND: "Accelerate spending",
    ADVANTAGE: "Advantage+ placement",
    APPLY_TO_ALL: "Apply to all",
    AD_BATCH: "AD BATCH",

    BATCH_DETAILS: "Batch details",
    BID_CAP: "Bid cap",
    BID_COST: "Bid cost",
    BY_COMMA_SEPERATE: "By comma seperated",
    BID_STRATEGY: "Bid strategy",
    BUNDLE_COUNTRIES: "Bundle countries into one adset",
    BATCH_SETTINGS: "3. Batch settings",
    BATCH_NAME: "Batch name (optional)",
    BUDGET: "Budget",
    BUNDLE_COUNTRY_LABEL: "Bundle countries",
    BATCH: "Batch",

    CLEAR_DRAFT: "Clear Draft",
    CREATIVES: "Creatives",
    CAMPAIGN: "Campaign",
    COUNTRIES: "Countries",
    CREATIVES_INSIDE: "Creatives inside",
    COVERED_IN_SCHEDULE:
      "Use a preset or reuse a previous batch to skip some steps.",
    CAMPAIGN_OBJECTIVES: "Campaign objective",
    CAPTION: "Caption",
    COMPANY_NAME: "Company Name",
    CHANGE_TIMEZONE: "Change timezones per country.",
    CUSTOM_AUDIENCE: "Use custom audience to exclude people",
    CREATE_FIRST:
      "Create a Pixel first to continue with a Conversions campaign",
    CREATE_NEW_CAMPAIGN: "Create new campaign",
    CAMPAIGN_NAME: "Campaign name",
    CUSTOM_AUDIENCE_REIVEW: "Custom audiences",
    CREATIVE_PER_AD: "Creatives per ad group",
    CLEAR_SELECTION: "Clear Selection",
    CANCEL: "CANCEL",
    CONTINUE: "CONTINUE",

    DATA_UPTO_DATE: "Data up to date",
    DAYPARTING: "Dayparting",
    DRAG_DROP: "Drag and drop your creatives here.",
    DROP_CREATIVE: "1. Drop creatives",
    DONE_WITH_REVIEW: "Done with your final checks? 🔎",
    DOUBLE_CHECK_REVIEW:
      "If you have double (or triple) checked your settings for all platforms, pick a date and hit the schedule button.",
    DATE: "DATE",

    PLACEHOLDER: "Ex. Reels Test (variation 1)",

    FB_PAGES: "Facebook page",
    FACEBOOK: "Facebook*",

    GROUP: "Group",

    HEADING: "Heading",
    HIGHEST_VOLUME: "Highest Volume",

    INTEREST: "Interests",
    IDENTITY: "Identity",
    INSTAGRAM: "Instagram*",
    INSTA_ACCOUNT: "Instagram account (optional)",
    INSTAGRAM_ACCOUNT: "Instagram account",

    LANDINGPAGE_URL: "LANDINGPAGE URL",
    LANGUAGES: "Languages",
    LABELS: "Labels...",
    LANDINGPAGE: "Landingpage... *",

    MIN_BUDGET: "Budget (min. 20)",
    MAX_CREATIVE: "Max. nr. creatives per adgroup",
    MAKE_SURE_EVERYTHING: "Make sure everything is set correctly per platform.",
    MAKE_SURE_REVIEW:
      "Make sure that all your settings per platform are correct.",

    NO_CAMPAIGN_FOUND: "No campaign were found",
    NO_INTEREST_FOUND: "No interests were found",
    NO_CREATIVES: "No creatives available.",
    NO_AUDIENCE_FOUND: "No audience were found",
    NO_RECORD_FOUND: "No Record Found",

    OPEN_HISTORY: "< OPEN HISTORY",
    OPTIMIZE_EVENT: "Optimize Event",

    PICK_INTEREST: "Pick a interests",
    PICK_AUDIENCE: "Pick a custom audiences",
    PRESET_USED: "Use predefined settings from your presets",
    PRESET_DELETE: "Preset Deleted",
    PRESET_ALREADY_EXIST: "Preset Name Already Exist.",
    PLEASE_SELECT_ALL_PLATFORMS: "Please select at list one platform.",
    PLATFORMS: "Platforms",
    PLATFORM: "PLATFORM",
    PLACEMENT: "Placement",
    PROFILE: "Profile",
    PIXEL: "Pixel",
    PIXELS: "Pixels",
    PLEASE_FILL_ALL_FIELDS: "Please fill all the required fields in tabs.",
    PRESET_USED_REVIEW: "Preset used:",
    PRESET_NAME: "Preset Name",
    PROGRESS: "PROGRESS",

    REUSE_BATCH: "REUSE BATCH",
    REFRESHING: "Refreshing",
    REFRESH_DATA: "Refresh data",
    REFRESHING_DATA: "Refreshing data...",
    REUSE_CREATIVES: "Reuse creatives and settings from a previous batch",
    REVIEW_YOUR_BATCH: "Review your batch",
    REVIEW_PRESET: "1. Review preset",
    REVIEW_CAMPAIGN: "2. Review campaign",
    REVIEW_SETTINGS: "3b. Review settings for Meta",

    SCHEDULE_ADS_IN_STEPS:
      "Schedule ads in a few steps on every desired platform",
    SCHEDULE_ADS_CAPTION: "On which platforms do you want to schedule?",
    SPEED_UP: "Speed up your scheduling process 🚀",
    SETTINGS_OVERWRITTEN:
      "Settings from previous batch will be overwritten by presets",
    SET_CAMPAIGN_DETAILS: "Set your campaign details",
    SELECT_OBJECTIVES: "Select your overall objectives.",
    SEARCH_COUTNRIES: "Search countries...",
    SELECT_LANGUAGE: "Select Languages",
    SELECT_LANGUAGES_FOR: "Select languages for: ",
    SEARCH_LANGUAGES: "Search languages...",
    SETTINGS: "Settings",
    STATUS: "STATUS",
    SETTINGS_PER_PLATFORM: "Settings per platform",
    SCHEDULE_DATE: "Schedule Date",
    SCHEDULE_BATCH: "SCHEDULE BATCH",
    SELECTED_TIME_PERIOD: "Selected time period",
    SELECT_WHICH_PRESET:
      "Select which action you want to take for your selected preset:",
    SCHEDULE_BATCH_TIME: "Please schedule the time for batch",

    TIMESAVER: "Timesaver",
    TARGETING: "2. Targeting ",
    TIMESLOTS:
      "Your timeslots will be automatically converted to the default timezone per country.",

    USE_EXISTING_CAMPAIGN: "Use existing campaign",
    UPLOAD_ONLY_MAX_SIZE: "You can only upload 2 files & maximum size of 2 MB.",
    UNSAVED_CHANGES: "Unsaved changes in your preset",

    WEEK_TIME: "Week/Time",

    YOU_HAVE_MADE_CHANGES: "You have changed the following settings:",
  },
  snackbar: {
    BUDGET_BELOW_BID: "Budget below bid",
    UPDATED_BUDGET:
      "We have updated the budget since bid needs to be higher than budget.",
  },
};
