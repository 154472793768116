// ** React
import { Fragment, useContext, useEffect, useState } from "react";

// ** Mui Imports
import {
  Typography,
  Box,
  Select,
  MenuItem,
  Button,
  FormControl,
  Switch,
  TextField,
  Chip,
  Stack,
  CircularProgress,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Divider,
  Card,
  InputLabel,
  Grow,
  Tooltip,
  Backdrop,
  IconButton,
  Avatar,
} from "@mui/material";

// ** Third Party Library
import axios from "axios";

// ** Redux
import { useAppDispatch, useAppSelector } from "store/store";
import {
  fetchLatestAdaccountData,
  getAdAccount,
  getCampaign,
  getCustomAudience,
  getFacebookPages,
  getInstagramPages,
  getInterests,
  getOptimizeEvents,
  getPixels,
  getProfiles,
} from "store/Thunk/autoSchedulerThunk";

import { AutoschudlerContext } from "context/autoschedulerContex";

// ** Custom Component
import DropdownNotFound from "components/DropdownNotFound/DropdownNotFound";
import { PLATFORMS, BATCH_STATUS, BID_STRATEGY } from "@core/constants/enum";
import { MEDIA_PLANNER } from "@core/constants/title-constants";
import { CAMPAIGN_OBJECTIVE } from "@core/constants/enum";
import {
  profileName,
  placements,
  age,
  tooltip,
  handledOptimizeEvent,
} from "@core/constants/global-constants";

// ** Mui Icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshTwoToneIcon from "@mui/icons-material/RefreshTwoTone";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";

// ** Assets
import { ProfileIcon, Vector } from "assets";

export interface Props {
  facebook: any;
  setFacebook: any;
  onBackwordButtonClick: any;
  onForwardButtonClick?: any;
  onClearDraftClick?: any;
  handleNextButton: any;
  handleNextButtonForAll?: any;
  flag: any;
  onNextPlatformClick: any;
}

export default function FacebookTabs({
  facebook,
  setFacebook,
  onBackwordButtonClick,
  onClearDraftClick,
  handleNextButton,
  handleNextButtonForAll,
  flag,
}: Props) {
  const dispatch: any = useAppDispatch();

  let autoSchedulerTitle = MEDIA_PLANNER.auto_scheduler;

  const {
    facebookProfiles,
    facebookAdAccount,
    facebookCampaign,
    facebookInterests,
    facebookPixels,
    facebookOptimizeEvent,
    facebookCustomAudience,
    facebookPages,
    instagramPages,
    schedulerDraftData,
  } = useAppSelector((state: any) => state.autoSchedulerSlice);
  const { campaign, availablePlatformTitle }: any =
    useContext(AutoschudlerContext);

  var CancelToken: any = axios.CancelToken;
  var cancel: any;

  const [loading, setLoading] = useState({
    adAccount: false,
    profile: false,
    campaign: false,
    identity: false,
    interest: false,
    pixel: false,
    optimizeEvent: false,
    customAudience: false,
    instagramPages: false,
    facebookPages: false,
  });

  const [allAgeGroup, setAllAgeGroup] = useState(true);
  const [campaignLoading, setCampaignLoading] = useState(false);
  const [interestLoading, setInterestLoading] = useState(false);
  const [instagramLoading, setInstagramLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState("");
  const [adAccountLoading, setAdAccountLoading] = useState(false);
  const [pagesLoading, setPagesLoading] = useState(false);
  const [isBackdrop, setIsBackdrop] = useState(false);
  const [pixelsLoading, setPixelsLoading] = useState(false);
  const [isClearDraftEnabled, setIsClearDraftEnabled] = useState(false);

  useEffect(() => {
    setIsClearDraftEnabled(!schedulerDraftData?.id);
  }, [schedulerDraftData]);

  useEffect(() => {
    const platform = {
      ad_platform: PLATFORMS.FACEBOOK,
    };
    dispatch(getProfiles({ platform })).then((res: any) => {
      res?.payload?.data?.data?.length
        ? setLoading({ ...loading, profile: false })
        : setLoading({ ...loading, profile: true });
    });

    // if (facebook?.profile) {
    //   handleProfileChange(facebook?.profile);
    // }

    // if (facebook?.profile && facebook?.advertiserAccount) {
    //   handleAdaccountChange(facebook?.advertiserAccount);
    //   if (facebook?.pixel) {
    //     handlePixelChange(facebook?.pixel);
    //   }
    // }

    //   if (facebook?.existingAccount) {
    //     handledExistingCampaingChange("");
    //   }
  }, []);

  useEffect(() => {
    setRefreshLoading("");
    if (facebook?.profile) {
      const platform = {
        ad_platform: PLATFORMS.FACEBOOK,
        profile_id: facebook?.profile,
      };
      dispatch(getAdAccount({ platform })).then((res: any) => {
        res?.payload?.data?.data?.length
          ? setAdAccountLoading(false)
          : setAdAccountLoading(true);
      });
    }
  }, [facebook?.profile]);

  useEffect(() => {
    setRefreshLoading("");
    if (facebook?.advertiserAccount && facebookAdAccount.length) {
      handleAdaccountChange(facebook?.advertiserAccount);
    }
  }, [facebook?.advertiserAccount, facebookAdAccount]);

  useEffect(() => {
    if (facebook?.pixel) {
      handlePixelChange(facebook?.pixel);
    }
  }, [facebook?.pixel]);

  useEffect(() => {
    if (age?.length == facebook?.ageGroup?.length) {
      setAllAgeGroup(true);
    } else {
      setAllAgeGroup(false);
    }
  }, [facebook?.ageGroup]);

  function handledInterstChange(e: any) {
    if (cancel != undefined) {
      cancel();
    }

    const interestReq = {
      ad_platform: PLATFORMS.FACEBOOK,
      profile_id: facebook?.profile,
      advertiser_id: facebook?.advertiserAccount,
      search_keyword: e,
    };

    const token = {
      cancelToken: new CancelToken(function executor(c: any) {
        cancel = c;
      }),
    };
    setInterestLoading(true);
    dispatch(getInterests({ interestReq, token }))
      .then((response: any) => {
        if (response?.payload?.status == 200) {
          setInterestLoading(false);
        }
        return response.data.response;
      })
      .catch((error: any) => {
        const result = error.response;
        return Promise.reject(result);
      });
  }

  const handleProfileChange = (e: any) => {
    const facebookProfileEmail = facebookProfiles?.find((obj: any) => {
      return obj?.id === e;
    });

    setFacebook({
      ...facebook,
      profile: e,
      profileEmail: facebookProfileEmail?.email
        ? facebookProfileEmail?.email
        : facebook?.profileEmail,
      firstName: facebookProfileEmail?.first_name,
      lastName: facebookProfileEmail?.last_name,
      selectedAudience: facebook.profile != e ? [] : facebook?.selectedAudience,
      interest: facebook.profile != e ? [] : facebook?.interest,
      optimizeEvent: facebook.profile != e ? [] : facebook?.optimizeEvent,
      existingAccount: facebook.profile != e ? "" : facebook?.existingAccount,
      pixel: facebook.profile != e ? "" : facebook?.pixel,
      advertiserAccount:
        facebook.profile != e ? "" : facebook?.advertiserAccount,
      instagramAccount: facebook.profile != e ? "" : facebook?.instagramAccount,
      facebookPage: facebook.profile != e ? "" : facebook?.facebookPage,
    });
  };

  const handleAdaccountChange = (e: any) => {
    const facebookAdAccountName = facebookAdAccount?.find((obj: any) => {
      return obj?.account_id === e;
    });
    var advertiseName = "";
    var customAudienceData = false;

    const platform = {
      ad_platform: PLATFORMS.FACEBOOK,
      profile_id: facebook?.profile,
      advertiser_id: e,
    };

    const platformData = {
      ad_platform: PLATFORMS.FACEBOOK,
      profile_id: facebook?.profile,
      account_id: e,
    };

    dispatch(getPixels({ platform })).then((res: any) => {
      if (res?.payload?.status == 200) {
        res?.payload?.data?.data?.length == 0
          ? setPixelsLoading(true)
          : setPixelsLoading(false);
      }
    });

    dispatch(getCustomAudience({ platformData })).then((res: any) => {
      customAudienceData = res?.payload?.data?.data[0]?.length ? false : true;
    });

    setLoading({
      ...loading,
      campaign: false,
      pixel: false,
      customAudience: customAudienceData,
      instagramPages: false,
    });

    const data = {
      business_id:
        facebookAdAccountName !== undefined
          ? facebookAdAccountName?.business
          : null,
    };

    dispatch(getFacebookPages({ data })).then((res: any) => {
      res?.payload?.data?.data?.length
        ? setPagesLoading(false)
        : setPagesLoading(true);
    });

    var name = facebookAdAccount?.find(
      (element: any) => element?.account_id == e
    )?.account_name;
    var objective =
      campaign?.objective != "" ? " - " + campaign?.objective : "";
    var countries =
      campaign?.countries.length >= 2
        ? " - " + "Multi Countries"
        : campaign?.countries.length == 0
        ? " - No Countries  "
        : " - " + campaign?.countries[0]?.Name;
    var language = campaign?.customLanguage
      ? " - Custom Language"
      : " - No Language";
    advertiseName = name + objective + countries + language;
    var disableCampaign =
      name +
      objective +
      " - {COUNTRY}" +
      " - {LANGUAGE_INFO}" +
      " - {LANDINGPAGE}";

    setFacebook({
      ...facebook,
      advertiserAccount: e,
      newAccount: advertiseName,
      disableCampaignName: disableCampaign,
      autoGenerateName: false,
      adAccountName: facebookAdAccountName?.account_name,
      selectedAudience:
        facebook?.advertiserAccount != e ? [] : facebook?.selectedAudience,
      interest: facebook?.advertiserAccount != e ? [] : facebook?.interest,
      optimizeEvent:
        facebook?.advertiserAccount != e ? [] : facebook?.optimizeEvent,
      existingAccount:
        facebook?.advertiserAccount != e ? "" : facebook?.existingAccount,
      pixel: facebook?.advertiserAccount != e ? "" : facebook?.pixel,
    });
  };

  const handleChangeAdAccount = (e: any) => {
    var advertiseName = "";

    var name = facebookAdAccount?.find(
      (element: any) => element?.account_id == e
    )?.account_name;
    var objective =
      campaign?.objective != "" ? " - " + campaign?.objective : "";
    var countries =
      campaign?.countries.length >= 2
        ? " - " + "Multi Countries"
        : campaign?.countries.length == 0
        ? " - No Countries  "
        : " - " + campaign?.countries[0]?.Name;
    var language = campaign?.customLanguage
      ? " - Custom Language"
      : " - No Language";
    advertiseName = name + objective + countries + language;
    var disableCampaign =
      name +
      objective +
      " - {COUNTRY}" +
      " - {LANGUAGE_INFO}" +
      " - {LANDINGPAGE}";

    setFacebook({
      ...facebook,
      advertiserAccount: e,
      newAccount: advertiseName,
      disableCampaignName: disableCampaign,
      autoGenerateName: false,
      selectedAudience:
        facebook?.advertiserAccount != e ? [] : facebook?.selectedAudience,
      interest: facebook?.advertiserAccount != e ? [] : facebook?.interest,
      optimizeEvent:
        facebook?.advertiserAccount != e ? [] : facebook?.optimizeEvent,
      existingAccount:
        facebook?.advertiserAccount != e ? "" : facebook?.existingAccount,
      pixel: facebook?.advertiserAccount != e ? "" : facebook?.pixel,
      facebookPage: "",
      instagramAccount: "",
    });
  };

  const handledExistingCampaingChange = (e: any) => {
    if (cancel !== undefined) {
      cancel();
    }

    const campaignReq = {
      ad_platform: PLATFORMS.FACEBOOK,
      profile_id: facebook?.profile,
      advertiser_id: facebook?.advertiserAccount,
      search_keyword: e,
      objective: campaign.objective,
    };

    const token = {
      cancelToken: new CancelToken(function executor(c: any) {
        cancel = c;
      }),
    };

    setCampaignLoading(true);
    dispatch(getCampaign({ campaignReq, token }))
      .then((response: any) => {
        if (response?.payload?.status == 200) {
          setCampaignLoading(false);
        }
      })
      .catch((error: any) => {
        const result = error?.response;
        return Promise.reject(result);
      });
  };

  const handledAgeGroupChange = (name: any, index: number) => {
    var agesArray: any = facebook?.ageGroup;
    if (facebook?.ageGroup.includes(name)) {
      var filterArray: any = agesArray;
      agesArray = filterArray.filter((item: any) => item != name);
    } else {
      agesArray.push(name);
    }
    if (agesArray.length) {
      var array: any = [];
      var minimum: any;
      var maximum: any;
      var finaleArray: any = [];
      age?.map((itemss: any, index: any) => {
        agesArray?.map((item: any) => {
          if (itemss?.name == item) {
            array.push(Number(item?.substring(0, 2)));
          }
        });
        if (
          array?.length &&
          array[0] == Number(itemss?.name?.substring(0, 2))
        ) {
          minimum = index;
        }
        if (array[array?.length - 1] == Number(itemss?.name?.substring(0, 2))) {
          maximum = index;
        }
      });
      for (let i = minimum; i < maximum + 1; i++) {
        finaleArray.push(age[i]?.name);
      }
      setFacebook({ ...facebook, ageGroup: finaleArray });
    } else {
      setFacebook({ ...facebook, ageGroup: [] });
    }
  };

  const handledAllAgeGroup = () => {
    setAllAgeGroup(!allAgeGroup);
    if (!allAgeGroup) {
      var ageArray: any = [];
      age?.map((item: any) => {
        ageArray.push(item?.name);
      });
      setFacebook({ ...facebook, ageGroup: ageArray });
    } else {
      setFacebook({ ...facebook, ageGroup: [] });
    }
  };

  const handlePixelChange = (e: any) => {
    const platformData = {
      ad_platform: PLATFORMS.FACEBOOK,
      profile_id: facebook?.profile,
      account_id: facebook?.advertiserAccount,
      pixel_id: e,
    };

    const pixelName = facebookPixels.find((obj: any) => {
      return obj.pixel_id === e;
    });

    dispatch(getOptimizeEvents({ platformData })).then((res: any) => {
      setLoading({
        ...loading,
        optimizeEvent: res?.payload?.data?.data[0]?.length > 0 ? false : true,
      });
    });
    setFacebook({ ...facebook, pixelName: pixelName?.name, pixel: e });
  };

  // const handleOptimizeEventChange = (e: any) => {
  //   const pixelName = Object.keys(facebookOptimizeEvent)?.find((obj: any) => {
  //     return obj === e;
  //   });

  //   setFacebook({
  //     ...facebook,
  //     optimizeEvent: e,
  //     optimizeEventName: pixelName,
  //   });
  // };

  const handleOptimizeEventChange = (e: any) => {
    const pixelName = Object.keys(facebookOptimizeEvent)?.find(
      (obj: any) => facebookOptimizeEvent[obj] === e
    );

    setFacebook({
      ...facebook,
      optimizeEvent: e,
      optimizeEventName: handledOptimizeEvent(pixelName),
    });
  };

  const handledFacebookPlatform = (check: boolean, value: any) => {
    let platformArray = facebook?.advantageAndPlacements?.length
      ? facebook?.advantageAndPlacements
      : [];
    if (check == true) {
      platformArray?.push(value);
    } else if (check == false) {
      const index = platformArray.indexOf(value);
      platformArray.splice(index, 1);
    }
    setFacebook({ ...facebook, advantageAndPlacements: platformArray });
  };

  const handledFacebookPage = (page: any) => {
    setFacebook({
      ...facebook,
      facebookPage: page,
      instagramAccount: "",
    });

    const facebookAdAccountName = facebookAdAccount?.find((obj: any) => {
      return obj?.account_id === facebook?.advertiserAccount;
    });

    const faceBookPageName = facebookPages?.find((obj: any) => {
      return obj.page_id === facebook?.facebookPage;
    });
    setFacebook({
      ...facebook,
      facebookPageName: faceBookPageName?.page_name,
    });

    const data = {
      profile_id: facebook?.profile,
      business_id:
        facebookAdAccountName !== undefined
          ? facebookAdAccountName?.business
          : null,
    };

    dispatch(getInstagramPages({ data })).then((res: any) => {
      setInstagramLoading(res?.payload?.data?.data?.length ? false : true);
    });
  };

  const handleInstagramPage = (page: any) => {
    const instaPageName = instagramPages?.find((obj: any) => {
      return obj.instagram_account_id === page;
    });

    setFacebook({
      ...facebook,
      instagramAccount: page,
      instaPageName: instaPageName.instagram_account_name,
    });
  };

  const handledNextButtonClick = () => {
    if (availablePlatformTitle?.includes(PLATFORMS.SNAP)) {
      handleNextButton();
    } else {
      handleNextButtonForAll();
    }
  };

  function handledButton() {
    var required =
      (campaign?.objective !== "Traffic" && facebook?.pixel == "") ||
      !facebook?.profile ||
      !facebook?.advertiserAccount ||
      (facebook?.activeButton == "Existing" && !facebook?.existingAccount) ||
      !facebook?.ageGroup?.length ||
      !facebook?.heading ||
      !facebook?.caption ||
      (!facebook?.advantage && !facebook?.advantageAndPlacements?.length);
    return (
      <Grow in={true}>
        <Box>
          <Divider sx={{ my: 5 }} />

          {flag &&
            (availablePlatformTitle?.includes(PLATFORMS.SNAP) ? null : (
              <FormHelperText sx={{ mb: 2 }} error id="password-error">
                Please fill all the required fields in tabs.
              </FormHelperText>
            ))}
          <Box
            className="footer-button"
            sx={{ justifyContent: { xs: "center", sm: "space-between" } }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
              gap={2}
            >
              <Button
                size="large"
                type="submit"
                variant="outlined"
                color="secondary"
                onClick={onBackwordButtonClick}
              >
                <ArrowBackIcon sx={{ mr: 2 }} />
                campaign
              </Button>
              <Button
                size="large"
                type="submit"
                variant="contained"
                onClick={handledNextButtonClick}
                disabled={required}
              >
                {availablePlatformTitle?.includes(PLATFORMS.SNAP)
                  ? "Snapchat"
                  : "Creatives"}
                <ArrowForwardIcon sx={{ ml: 2 }} />
              </Button>
            </Box>
            <Button
              size="large"
              variant="outlined"
              onClick={onClearDraftClick}
              disabled={isClearDraftEnabled}
              sx={{ mt: { xs: 3, sm: 0 } }}
              startIcon={<DeleteIcon />}
            >
              {autoSchedulerTitle.CLEAR_DRAFT}
            </Button>
          </Box>
        </Box>
      </Grow>
    );
  }

  const handleRefreshButton = () => {
    setIsBackdrop(true);
    setRefreshLoading("Refreshing");
    if (facebook?.profile && facebook?.advertiserAccount) {
      const data = {
        ad_platform: PLATFORMS.FACEBOOK,
        profile_id: facebook?.profile,
        advertiser_id: facebook?.advertiserAccount,
      };
      dispatch(fetchLatestAdaccountData({ data })).then((res: any) => {
        setIsBackdrop(false);
        if (res?.payload?.status == 200) {
          setRefreshLoading("Done");
          handleProfileChange(facebook?.profile);
          handleAdaccountChange(facebook?.advertiserAccount);
        } else {
          setRefreshLoading("");
        }
      });
    }
  };

  return (
    <Box>
      {/*--------------------------- 1. Account details Card-----------------------------------------------*/}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
        open={isBackdrop}
      ></Backdrop>
      <Grow in={true}>
        <Card sx={{ padding: 8 }}>
          <Box className="align-justify-item-center">
            <Chip
              label={autoSchedulerTitle.ACCOUNT_DETAILS}
              sx={{ flexGrow: 0 }}
            />
            <Divider sx={{ flexGrow: 1 }} />
          </Box>

          <Box
            sx={{
              mx: { md: 8, sm: 4, xs: 2 },
              maxWidth: "467px",
              width: { md: "50%", sm: "50%", xs: "100%" },
            }}
          >
            {/*-------------------------- Profile Dropdown --------------------------------------------*/}
            <>
              <Box sx={{ width: { md: "221px", sm: "221px", xs: "100%" } }}>
                <FormControl fullWidth sx={{ mt: 8 }}>
                  <InputLabel>{autoSchedulerTitle.PROFILE + " *"}</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    placeholder="Select Profile"
                    label={autoSchedulerTitle.PROFILE + " *"}
                    value={facebook?.profile}
                    onChange={(e: any) => handleProfileChange(e.target.value)}
                  >
                    {facebookProfiles.length == 0 && loading.profile && (
                      <DropdownNotFound name={"profile"} />
                    )}
                    {facebookProfiles.length == 0 && !loading.profile && (
                      <Box className="sync-align-justify-center full-height-width">
                        <CircularProgress />
                      </Box>
                    )}
                    {facebookProfiles?.map((item: any, index: number) => (
                      <MenuItem value={item?.id} key={index}>
                        <Box className="sync-align-center">
                          <Avatar
                            alt={ProfileIcon}
                            sx={{ width: 22, height: 22, mr: 2 }}
                            src={item?.avatar_url || ProfileIcon}
                          />
                          <Typography sx={{ fontSize: "14px" }}>
                            {profileName(item)}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>

              {facebook?.profile && (
                <Grow in={true}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      mt: 8,
                    }}
                    rowGap={2}
                  >
                    <Box
                      sx={{
                        width: { md: "221px", sm: "221px", xs: "100%" },
                      }}
                    >
                      <FormControl fullWidth>
                        <InputLabel>
                          {autoSchedulerTitle.AD_ACCOUNT + " *"}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          placeholder="Labels..."
                          label={autoSchedulerTitle.AD_ACCOUNT + " *"}
                          value={facebook?.advertiserAccount}
                          onChange={(e: any) =>
                            handleChangeAdAccount(e.target.value)
                          }
                        >
                          {facebookAdAccount.length == 0 &&
                            adAccountLoading && (
                              <DropdownNotFound name={"account"} />
                            )}
                          {facebookAdAccount.length == 0 &&
                            !adAccountLoading && (
                              <Box className="sync-align-justify-center full-height-width">
                                <CircularProgress />
                              </Box>
                            )}
                          {facebookAdAccount?.map((item: any, index: any) => (
                            <MenuItem value={item?.account_id} key={index}>
                              {item?.account_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box sx={{ ml: 2 }}>
                      {refreshLoading == BATCH_STATUS.DONE ? (
                        <Typography
                          sx={{
                            fontWeight: "500",
                            color: "rgba(103, 203, 36, 1)",
                            display: "flex",
                            alignItems: "center",
                            ml: 2,
                          }}
                        >
                          <DoneRoundedIcon sx={{ mr: 2 }} color="success" />
                          {autoSchedulerTitle.DATA_UPTO_DATE}
                        </Typography>
                      ) : refreshLoading == autoSchedulerTitle.REFRESHING &&
                        facebook?.advertiserAccount ? (
                        <Typography
                          sx={{
                            fontWeight: "500",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <CircularProgress
                            size={20}
                            sx={{ mr: 2, height: 2, width: 2 }}
                            color="secondary"
                          />
                          {autoSchedulerTitle.REFRESHING_DATA}
                        </Typography>
                      ) : refreshLoading == "" &&
                        facebook?.advertiserAccount ? (
                        <Button variant="text" onClick={handleRefreshButton}>
                          <RefreshTwoToneIcon sx={{ mr: 2 }} />
                          {autoSchedulerTitle.REFRESH_DATA}
                        </Button>
                      ) : (
                        <></>
                      )}
                    </Box>
                  </Box>
                </Grow>
              )}
            </>

            {facebook?.advertiserAccount && (
              <Grow in={true}>
                <Box>
                  <Box
                    sx={{
                      padding: 2,
                      border: 1,
                      borderRadius: 1,
                      color: "rgba(76, 78, 100, 0.22)",
                      mt: 8,
                      display: "flex",
                      width: "max-content",
                    }}
                    columnGap={2}
                  >
                    <Button
                      variant={
                        facebook?.activeButton == "Existing"
                          ? "contained"
                          : "text"
                      }
                      onClick={() =>
                        setFacebook({ ...facebook, activeButton: "Existing" })
                      }
                      sx={{
                        paddingLeft: 3,
                        paddingRight: 3,
                      }}
                    >
                      {autoSchedulerTitle.USE_EXISTING_CAMPAIGN}
                    </Button>
                    <Button
                      variant={
                        facebook?.activeButton == "New" ? "contained" : "text"
                      }
                      onClick={() =>
                        setFacebook({ ...facebook, activeButton: "New" })
                      }
                    >
                      {autoSchedulerTitle.CREATE_NEW_CAMPAIGN}
                    </Button>
                  </Box>

                  {/* ------------------------------Existing Campaign---------------------------------------*/}

                  {facebook?.activeButton == "Existing" && (
                    <Grow in={true}>
                      <Box sx={{ mt: 8 }}>
                        <FormControl fullWidth>
                          <Autocomplete
                            id="tags-outlined"
                            options={
                              facebookCampaign.length ? facebookCampaign : []
                            }
                            loading={campaignLoading}
                            noOptionsText={autoSchedulerTitle.NO_CAMPAIGN_FOUND}
                            onChange={(e: any, value: any) =>
                              setFacebook({
                                ...facebook,
                                existingAccount: value?.campaign_id,
                              })
                            }
                            onFocus={() => handledExistingCampaingChange("")}
                            value={
                              facebook?.existingAccount != ""
                                ? facebookCampaign?.find(
                                    (element: any) =>
                                      element?.campaign_id ==
                                      facebook?.existingAccount
                                  )
                                : null
                            }
                            renderInput={(params: any) => (
                              <TextField
                                onChange={(e: any) =>
                                  handledExistingCampaingChange(e.target.value)
                                }
                                {...params}
                                placeholder="SearchCampaign"
                                variant="outlined"
                                label={autoSchedulerTitle.CAMPAIGN + " *"}
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <Fragment>
                                      {campaignLoading ? (
                                        <CircularProgress size={20} />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </Fragment>
                                  ),
                                }}
                              />
                            )}
                            renderOption={(props, option: any) => (
                              <li {...props}>
                                <Box
                                  className="sync-align-center"
                                  sx={{ px: "16px", py: "6px" }}
                                  key={option?.campaign_id}
                                >
                                  <Box
                                    sx={{
                                      background:
                                        option?.active == "Yes"
                                          ? "green"
                                          : "red",
                                      height: "9px",
                                      width: "9px",
                                      borderRadius: "5px",
                                      mr: 3,
                                    }}
                                  ></Box>
                                  <Box className="sync-direction-column">
                                    <Typography
                                      noWrap
                                      variant="subtitle2"
                                      sx={{ color: "text.primary" }}
                                    >
                                      {option?.campaign_name}
                                    </Typography>
                                  </Box>
                                </Box>
                              </li>
                            )}
                            getOptionLabel={(option: any) =>
                              option.campaign_name
                            }
                            disableCloseOnSelect
                            filterSelectedOptions
                          />
                        </FormControl>
                      </Box>
                    </Grow>
                  )}

                  {facebook?.activeButton == "New" && (
                    <Grow in={true}>
                      <Box sx={{ mt: 8 }}>
                        {facebook?.autoGenerateName ? (
                          <Box>
                            <TextField
                              fullWidth
                              disabled
                              variant="outlined"
                              label={autoSchedulerTitle.CAMPAIGN_NAME}
                              placeholder={autoSchedulerTitle.CAMPAIGN_NAME}
                              value={facebook?.disableCampaignName}
                            />
                          </Box>
                        ) : (
                          <Box>
                            <TextField
                              fullWidth
                              variant="outlined"
                              label={autoSchedulerTitle.CAMPAIGN_NAME}
                              placeholder={autoSchedulerTitle.CAMPAIGN_NAME}
                              value={facebook?.newAccount}
                              onChange={(e: any) =>
                                setFacebook({
                                  ...facebook,
                                  newAccount: e.target.value,
                                })
                              }
                            />
                          </Box>
                        )}
                        <Box className="sync-align-center" sx={{ mt: 4 }}>
                          <Switch
                            checked={facebook?.autoGenerateName}
                            onChange={(e) =>
                              setFacebook({
                                ...facebook,
                                autoGenerateName: e.target.checked,
                              })
                            }
                          />
                          <Typography
                            variant="subtitle1"
                            sx={{ color: "rgba(76, 78, 100, 0.87)" }}
                          >
                            {autoSchedulerTitle.AUTO_GENERATE}
                          </Typography>
                        </Box>
                      </Box>
                    </Grow>
                  )}
                </Box>
              </Grow>
            )}
          </Box>
          {facebook?.existingAccount != "" ||
          facebook?.activeButton == "New" ? (
            <></>
          ) : (
            handledButton()
          )}
        </Card>
      </Grow>

      {facebook?.advertiserAccount && (
        <Grow in={true}>
          <Box>
            {(facebook?.existingAccount || facebook?.activeButton == "New") && (
              <Box>
                <Card sx={{ padding: 8, mt: 10 }}>
                  <Box className="align-justify-item-center">
                    <Chip label="2. Targeting" sx={{ flexGrow: 0 }} />
                    <Divider sx={{ flexGrow: 1 }} />
                  </Box>
                  <Box sx={{ mx: { md: 8, sm: 4, xs: 2 } }}>
                    <Box
                      sx={{
                        mt: 8,
                        maxWidth: "467px",
                        width: { md: "50%", sm: "50%", xs: "100%" },
                      }}
                    >
                      <FormControl fullWidth>
                        <Autocomplete
                          multiple
                          noOptionsText={autoSchedulerTitle.NO_INTEREST_FOUND}
                          id="tags-outlined"
                          value={facebook.interest}
                          loading={interestLoading}
                          onChange={(e: any, values) =>
                            setFacebook({ ...facebook, interest: values })
                          }
                          options={
                            facebookInterests.length ? facebookInterests : []
                          }
                          getOptionLabel={(option: any) => option.name}
                          disableCloseOnSelect
                          filterSelectedOptions
                          onFocus={() => handledInterstChange("")}
                          renderInput={(params: any) => (
                            <TextField
                              onChange={(e: any) =>
                                handledInterstChange(e.target.value)
                              }
                              {...params}
                              placeholder={autoSchedulerTitle.PICK_INTEREST}
                              label={autoSchedulerTitle.INTEREST}
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <Fragment>
                                    {interestLoading ? (
                                      <CircularProgress size={20} />
                                    ) : null}
                                    {params.InputProps.endAdornment}
                                  </Fragment>
                                ),
                              }}
                            />
                          )}
                        />
                      </FormControl>
                    </Box>

                    <Box sx={{ mt: 8 }}>
                      <Typography
                        variant="subtitle1"
                        sx={{
                          fontWeight: "400",
                          color: "rgba(76, 78, 100, 0.87)",
                        }}
                      >
                        {autoSchedulerTitle.AGE_GROUP}
                      </Typography>
                      <Stack
                        direction="row"
                        spacing={1}
                        sx={{ mt: 4, flexWrap: "wrap" }}
                        rowGap={2}
                      >
                        <Chip
                          sx={{
                            width: "80px",
                            color: allAgeGroup ? "white" : "primary.main",
                            borderColor: "red",
                            backgroundColor: allAgeGroup
                              ? "primary.main"
                              : "white",
                          }}
                          label={"All"}
                          variant={allAgeGroup ? "filled" : "outlined"}
                          onClick={handledAllAgeGroup}
                        />
                        {age &&
                          age?.map((item: any, index: any) => {
                            return (
                              <Chip
                                key={index}
                                sx={{
                                  width: "80px",
                                  color: facebook?.ageGroup?.includes(
                                    item?.name
                                  )
                                    ? "white"
                                    : "primary.main",
                                  borderColor: "red",
                                  backgroundColor: facebook?.ageGroup?.includes(
                                    item?.name
                                  )
                                    ? "primary.main"
                                    : "white",
                                }}
                                label={item?.name}
                                variant={
                                  facebook?.ageGroup?.includes(item?.name)
                                    ? "filled"
                                    : "outlined"
                                }
                                onClick={(e: any) =>
                                  handledAgeGroupChange(item?.name, index)
                                }
                              />
                            );
                          })}
                      </Stack>
                    </Box>

                    <Box className="sync-align-center" sx={{ mt: 4 }}>
                      <Switch
                        checked={facebook?.customAudiences}
                        onChange={(e) =>
                          setFacebook({
                            ...facebook,
                            customAudiences: e.target.checked,
                          })
                        }
                      />
                      <Typography
                        variant="subtitle1"
                        sx={{ color: "rgba(76, 78, 100, 0.87)" }}
                      >
                        {autoSchedulerTitle.CUSTOM_AUDIENCE}
                      </Typography>
                    </Box>

                    {facebook?.customAudiences && (
                      <Grow in={true}>
                        <Box
                          sx={{
                            maxWidth: "467px",
                            width: { md: "50%", sm: "50%", xs: "100%" },
                          }}
                        >
                          <FormControl fullWidth sx={{ mt: 4 }}>
                            <Autocomplete
                              multiple
                              id="tags-outlined"
                              options={facebookCustomAudience}
                              getOptionLabel={(option: any) => option.name}
                              noOptionsText={
                                autoSchedulerTitle.NO_AUDIENCE_FOUND
                              }
                              disableCloseOnSelect
                              value={facebook?.selectedAudience}
                              filterSelectedOptions
                              onChange={(value: any, select: any) =>
                                setFacebook({
                                  ...facebook,
                                  selectedAudience: select,
                                })
                              }
                              renderInput={(params: any) => (
                                <TextField
                                  label="Audience"
                                  variant="outlined"
                                  onChange={(e: any) =>
                                    handledInterstChange(e.target.value)
                                  }
                                  {...params}
                                  placeholder={autoSchedulerTitle.PICK_AUDIENCE}
                                />
                              )}
                            />
                          </FormControl>
                        </Box>
                      </Grow>
                    )}
                  </Box>
                </Card>

                <Card sx={{ padding: 8, mt: 10 }}>
                  <Box className="align-justify-item-center">
                    <Chip
                      label={autoSchedulerTitle.AD_SETTINGS}
                      sx={{ flexGrow: 0 }}
                    />
                    <Divider sx={{ flexGrow: 1 }} />
                  </Box>
                  <Box sx={{ mx: { md: 8, sm: 4, xs: 2 } }}>
                    {/*------------------------ Facebook page & Instagram accounts ---------------------*/}

                    <Box
                      sx={{ display: "flex", flexWrap: "wrap" }}
                      columnGap={4}
                    >
                      <Box
                        sx={{
                          width: { md: "221px", sm: "221px", xs: "100%" },
                        }}
                      >
                        <FormControl fullWidth sx={{ mt: 8 }}>
                          <InputLabel>{autoSchedulerTitle.FB_PAGES}</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            placeholder={autoSchedulerTitle.LABELS}
                            label={autoSchedulerTitle.FB_PAGES}
                            value={facebook?.facebookPage}
                            onChange={(e: any) =>
                              handledFacebookPage(e.target.value)
                            }
                          >
                            {facebookPages?.length == 0 && pagesLoading && (
                              <DropdownNotFound name={"facebook pages"} />
                            )}
                            {facebookPages?.length == 0 && !pagesLoading && (
                              <Box className="sync-align-justify-center full-height-width">
                                <CircularProgress />
                              </Box>
                            )}
                            {facebookPages?.map((item: any, index: number) => (
                              <MenuItem value={item?.page_id} key={index}>
                                <Box
                                  className="sync-align-center"
                                  key={item?.page_id}
                                >
                                  <Box
                                    sx={{
                                      background:
                                        item?.room_left >= 1 ? "green" : "red",
                                      height: "9px",
                                      width: "9px",
                                      borderRadius: "5px",
                                      mr: 3,
                                    }}
                                  ></Box>
                                  <Box className="sync-direction-column">
                                    <Typography
                                      noWrap
                                      variant="subtitle2"
                                      sx={{ color: "text.primary" }}
                                    >
                                      {item?.page_name}
                                    </Typography>
                                  </Box>
                                  <Box className="sync-direction-column">
                                    <Typography
                                      noWrap
                                      variant="subtitle2"
                                      sx={{ color: "text.primary", ml: 2 }}
                                    >
                                      {item?.room_left >= 1
                                        ? " room: " + item?.room_left
                                        : ""}
                                    </Typography>
                                  </Box>
                                </Box>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>

                      {facebook?.facebookPage && (
                        <Box
                          sx={{
                            mt: 8,
                            width: { md: "221px", sm: "221px", xs: "100%" },
                          }}
                        >
                          <FormControl fullWidth>
                            <InputLabel>
                              {autoSchedulerTitle.INSTA_ACCOUNT}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              placeholder={autoSchedulerTitle.LABELS}
                              label={autoSchedulerTitle.INSTA_ACCOUNT}
                              value={facebook?.instagramAccount}
                              onChange={(e: any) =>
                                handleInstagramPage(e.target.value)
                              }
                            >
                              {instagramPages?.length == 0 &&
                                instagramLoading && (
                                  <DropdownNotFound name={"instagram pages"} />
                                )}
                              {instagramPages?.length == 0 &&
                                !instagramLoading && (
                                  <Box className="sync-align-justify-center full-height-width">
                                    <CircularProgress />
                                  </Box>
                                )}
                              {instagramPages?.map(
                                (item: any, index: number) => (
                                  <MenuItem
                                    value={item?.instagram_account_id}
                                    key={index}
                                  >
                                    <Box className="sync-align-center">
                                      <Avatar
                                        alt={ProfileIcon}
                                        sx={{ width: 22, height: 22, mr: 2 }}
                                        src={
                                          item?.instagram_profile_pic ||
                                          ProfileIcon
                                        }
                                      />
                                      <Typography sx={{ fontSize: "14px" }}>
                                        {item?.instagram_account_name}
                                      </Typography>
                                    </Box>
                                  </MenuItem>
                                )
                              )}
                            </Select>
                          </FormControl>
                        </Box>
                      )}
                    </Box>

                    {/*--------------------------- Pixels Dropdown ------------------------------------- -*/}

                    <Box
                      sx={{ display: "flex", flexWrap: "wrap" }}
                      columnGap={4}
                    >
                      <Box
                        sx={{
                          width: { md: "221px", sm: "221px", xs: "100%" },
                        }}
                      >
                        <FormControl fullWidth sx={{ mt: 8 }}>
                          <InputLabel>
                            {autoSchedulerTitle.PIXELS}{" "}
                            {campaign?.objective == "Traffic" ? "" : "*"}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            placeholder={autoSchedulerTitle.LABELS}
                            label={autoSchedulerTitle.PIXELS + " *"}
                            value={facebook?.pixel}
                            onChange={(e: any) =>
                              handlePixelChange(e.target.value)
                            }
                          >
                            {facebookPixels.length == 0 && pixelsLoading && (
                              <DropdownNotFound name={"pixels"} />
                            )}
                            {facebookPixels.length == 0 && !pixelsLoading && (
                              <Box className="sync-align-justify-center full-height-width">
                                <CircularProgress />
                              </Box>
                            )}
                            {facebookPixels?.map((item: any, index: any) => (
                              <MenuItem value={item?.pixel_id} key={index}>
                                {item?.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>

                      {facebook?.pixel && (
                        <Grow in={true}>
                          <Box
                            sx={{
                              mt: 8,
                              width: { md: "221px", sm: "221px", xs: "100%" },
                            }}
                          >
                            <FormControl fullWidth>
                              <InputLabel>
                                {campaign?.objective ===
                                CAMPAIGN_OBJECTIVE.CONVERSIONS
                                  ? autoSchedulerTitle.OPTIMIZE_EVENT + " *"
                                  : autoSchedulerTitle.OPTIMIZE_EVENT}
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                placeholder={
                                  campaign?.objective ===
                                  CAMPAIGN_OBJECTIVE.CONVERSIONS
                                    ? autoSchedulerTitle.LABELS + " *"
                                    : autoSchedulerTitle.LABELS
                                }
                                label={
                                  campaign?.objective ===
                                  CAMPAIGN_OBJECTIVE.CONVERSIONS
                                    ? autoSchedulerTitle.OPTIMIZE_EVENT + " *"
                                    : autoSchedulerTitle.OPTIMIZE_EVENT
                                }
                                value={facebook?.optimizeEvent}
                                onChange={(e: any) =>
                                  handleOptimizeEventChange(e.target.value)
                                }
                              >
                                {Object.keys(facebookOptimizeEvent).length ==
                                  0 &&
                                  loading.optimizeEvent && (
                                    <DropdownNotFound name={"event"} />
                                  )}
                                {Object.keys(facebookOptimizeEvent).length ==
                                  0 &&
                                  !loading.optimizeEvent && (
                                    <Box className="sync-align-justify-center full-height-width">
                                      <CircularProgress />
                                    </Box>
                                  )}
                                {Object.keys(facebookOptimizeEvent)?.map(
                                  (item: any, index: any) => (
                                    <MenuItem
                                      value={facebookOptimizeEvent[item]}
                                      key={index}
                                    >
                                      {handledOptimizeEvent(item)}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </Box>
                        </Grow>
                      )}
                    </Box>

                    <Box
                      sx={{ width: { md: "221px", sm: "221px", xs: "100%" } }}
                    >
                      <FormControl fullWidth sx={{ mt: 8 }}>
                        <InputLabel>
                          {autoSchedulerTitle.MAX_CREATIVE}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          placeholder={autoSchedulerTitle.LABELS}
                          label={autoSchedulerTitle.MAX_CREATIVE}
                          value={facebook?.maximumCreative}
                          onChange={(e: any) =>
                            setFacebook({
                              ...facebook,
                              maximumCreative: e.target.value,
                            })
                          }
                        >
                          {[...Array(20)].map((_, index) => (
                            <MenuItem key={index} value={index + 1}>
                              {index + 1}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>

                    <Box sx={{ display: "flex", alignItems: "center", mt: 4 }}>
                      <Switch
                        value={facebook?.advantage}
                        checked={facebook?.advantage}
                        onChange={(e) =>
                          setFacebook({
                            ...facebook,
                            advantage: e.target.checked,
                          })
                        }
                      />
                      <Typography
                        variant="subtitle1"
                        sx={{ color: "rgba(76, 78, 100, 0.87)" }}
                      >
                        {autoSchedulerTitle.ADVANTAGE}
                      </Typography>
                      <Tooltip title={tooltip}>
                        <IconButton>
                          <Box
                            component={"img"}
                            sx={{ ml: 2 }}
                            src={Vector}
                          ></Box>
                        </IconButton>
                      </Tooltip>
                    </Box>

                    {!facebook?.advantage && (
                      <Grow in={true}>
                        <Box>
                          <Box>
                            <Typography variant="subtitle1" sx={{ mt: 4 }}>
                              {autoSchedulerTitle.FACEBOOK}
                            </Typography>
                            <Box
                              sx={{
                                mb: 2,
                                ml: 4,
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                              }}
                            >
                              {placements
                                ?.slice(0, 3)
                                ?.map((item: any, index: any) => (
                                  <FormControlLabel
                                    label={item?.name}
                                    key={index}
                                    control={
                                      <Checkbox
                                        value={facebook?.advantageAndPlacements?.includes(
                                          item?.value
                                        )}
                                        checked={facebook?.advantageAndPlacements?.includes(
                                          item?.value
                                        )}
                                        onChange={(e) =>
                                          handledFacebookPlatform(
                                            e.target.checked,
                                            item?.value
                                          )
                                        }
                                      />
                                    }
                                  />
                                ))}
                            </Box>
                          </Box>
                          <Box>
                            <Typography variant="subtitle1" sx={{ mt: 4 }}>
                              {autoSchedulerTitle.INSTAGRAM}
                            </Typography>
                            <Box
                              className="sync-align-center"
                              sx={{
                                mb: 2,
                                ml: 4,
                                flexWrap: "wrap",
                              }}
                            >
                              {placements
                                ?.slice(3, 6)
                                ?.map((item: any, index: any) => (
                                  <FormControlLabel
                                    key={index}
                                    label={item?.name}
                                    control={
                                      <Checkbox
                                        value={facebook?.advantageAndPlacements?.includes(
                                          item?.value
                                        )}
                                        checked={facebook?.advantageAndPlacements?.includes(
                                          item?.value
                                        )}
                                        onChange={(e) =>
                                          handledFacebookPlatform(
                                            e.target.checked,
                                            item?.value
                                          )
                                        }
                                      />
                                    }
                                  />
                                ))}
                            </Box>
                          </Box>
                        </Box>
                      </Grow>
                    )}
                    <Box
                      sx={{ width: { md: "221px", sm: "221px", xs: "100%" } }}
                    >
                      <FormControl fullWidth sx={{ mt: 4 }}>
                        <TextField
                          variant="outlined"
                          placeholder={autoSchedulerTitle.HEADING}
                          label={autoSchedulerTitle.HEADING + " *"}
                          value={facebook?.heading}
                          onChange={(e: any) =>
                            setFacebook({
                              ...facebook,
                              heading: e.target.value,
                            })
                          }
                          inputProps={{
                            maxlength: 25,
                          }}
                          helperText={`Remaining characters: ${
                            25 - facebook?.heading?.length
                          }`}
                        />
                      </FormControl>
                    </Box>

                    <Box maxWidth={"467px"}>
                      <FormControl fullWidth sx={{ mt: 8 }}>
                        <TextField
                          multiline
                          rows={4}
                          variant="outlined"
                          placeholder={autoSchedulerTitle.CAPTION}
                          label={autoSchedulerTitle.CAPTION + " *"}
                          inputProps={{
                            maxlength: 100,
                          }}
                          helperText={`Remaining characters: ${
                            100 - facebook?.caption?.length
                          }`}
                          value={facebook?.caption}
                          onChange={(e: any) =>
                            setFacebook({
                              ...facebook,
                              caption: e.target.value,
                            })
                          }
                        />
                      </FormControl>
                    </Box>

                    {campaign?.bidStategy === BID_STRATEGY.BID_CAP && (
                      <Box className="sync-align-center" sx={{ mt: 4 }}>
                        <Switch
                          checked={Boolean(facebook?.accelerateSpending)}
                          onChange={(e: any, checked: boolean) =>
                            setFacebook({
                              ...facebook,
                              accelerateSpending: checked,
                            })
                          }
                        />
                        <Typography
                          variant="subtitle1"
                          sx={{ color: "rgba(76, 78, 100, 0.87)" }}
                        >
                          {autoSchedulerTitle.ACCELERATE_SPEND}
                        </Typography>
                      </Box>
                    )}
                    {campaign?.objective === "Conversions" &&
                      facebookPixels?.length == 0 && (
                        <FormHelperText sx={{ color: "primary.main", mt: 5 }}>
                          {autoSchedulerTitle.CREATE_FIRST}
                        </FormHelperText>
                      )}
                  </Box>
                  {handledButton()}
                </Card>
              </Box>
            )}
          </Box>
        </Grow>
      )}
    </Box>
  );
}
