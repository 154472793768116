import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { AutoSchedulerIntialState } from 'store/interface';

export interface ResponseData {
	status: String
	messagecode: String
	message: String
	data: any
}

const initialState: AutoSchedulerIntialState = {
	snapchatProfiles: [],
	facebookProfiles: [],
	tiktokProfiles: [],

    snapchatAdAccount: [],
	facebookAdAccount: [],
    tiktokAdAccount: [],

	snapchatCampaign: [],
	facebookCampaign: [],
    tiktokCampaign: [],

	tiktokIdentity: [],

	snapchatInterests: [],
	facebookInterests: [],
	tiktokInterests: [],

	snapchatPixels: [],
	facebookPixels: [],
	tiktokPixels: [],

	snapchatOptimizeEvent: {},
	facebookOptimizeEvent: {},
	tiktokOptimizeEvent: {},

	snapchatCustomAudience: [],
	facebookCustomAudience: [],
	tiktokCustomAudience: [],

	creativesProgress: 0,
	creativesData: [],

	schedulerHistory: [],
	facebookPages: [],
	instagramPages: [],

	uploadsesId: null,
	reviewPageAdsetData: [],
	schedulerDraftData: {},

	presetsData: [],
	selectedPresetsData: [],
	reuseBatchCreative: [],

	schedulerHistoryRowData: []
};

const autoSchedulerSlice = createSlice({
	name: 'admin_client',
	initialState: initialState,
	reducers: {

		// ------------------Profiles-----------------------------------------------------
		SnapChatProfiles: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<[]>
		) => {
			state.snapchatProfiles = action.payload;
		},
        FacebookProfiles: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<[]>
		) => {
			state.facebookProfiles = action.payload;
		},
        TiktokProfiles: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<[]>
		) => {
			state.tiktokProfiles = action.payload;
		},

		// ------------------AdAccount-----------------------------------------------------
        SnapchatAdAccount: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<[]>
		) => {
			state.snapchatAdAccount = action.payload;
		},
        FacebookAdAccount: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<[]>
		) => {
			state.facebookAdAccount = action.payload;
		},
        TiktokAdAccount: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<[]>
		) => {
			state.tiktokAdAccount = action.payload;
		},

		// ------------------Campaign-----------------------------------------------------
		SnapchatCampaign: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<[]>
		) => {
			state.snapchatCampaign = action.payload;
		},
        FacebookCampaign: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<[]>
		) => {
			state.facebookCampaign = action.payload;
		},
        TiktokCampaign: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<[]>
		) => {
			state.tiktokCampaign = action.payload;
		},

		// ------------------Tiktok Identity-----------------------------------------------------
		TiktokIdentity: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<[]>
		) => {
			state.tiktokIdentity = action.payload;
		},

		// ------------------Interests-----------------------------------------------------
		SnapchatInterests: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<[]>
		) => {
			state.snapchatInterests = action.payload;
		},
		FacebookInterests: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<[]>
		) => {
			state.facebookInterests = action.payload;
		},
		TiktokInterests: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<[]>
		) => {
			state.tiktokInterests = action.payload;
		},

		// ------------------Pixels-----------------------------------------------------
		SnapchatPixels: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<[]>
		) => {
			state.snapchatPixels = action.payload;
		},
		FacebookPixels: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<[]>
		) => {
			state.facebookPixels = action.payload;
		},
		TiktokPixels: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<[]>
		) => {
			state.tiktokPixels = action.payload;
		},

		// ------------------Optimize Events-----------------------------------------------------
		SnapchatOptimizeEvents: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<{}>
		) => {
			state.snapchatOptimizeEvent = action.payload;
		},
		FacebookOptimizeEvents: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<{}>
		) => {
			state.facebookOptimizeEvent = action.payload;
		},
		TiktokOptimizeEvents: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<{}>
		) => {
			state.tiktokOptimizeEvent = action.payload;
		},

		// ------------------Custom Audience-----------------------------------------------------
		SnapchatCustomAudience: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<[]>
		) => {
			state.snapchatCustomAudience = action.payload;
		},
		FacebookCustomAudience: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<[]>
		) => {
			state.facebookCustomAudience = action.payload;
		},
		TiktokCustomAudience: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<[]>
		) => {
			state.tiktokCustomAudience = action.payload;
		},

		CreativesProgress: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<[]>
		) => {
			state.creativesProgress = action.payload;
		},
		CreativesData: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<[]>
		) => {
			state.creativesData = action.payload;
		},

		SchedulerHistoryData: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<[]>
		) => {
			state.schedulerHistory = action.payload;
		},

		SchedulerHistoryRowData: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<[]>
		) => {
			state.schedulerHistoryRowData = action.payload;
		},

		FacebookPageData: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<[]>
		) => {
			state.facebookPages = action.payload;
		},

		InstagramPageData: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<[]>
		) => {
			state.instagramPages = action.payload;
		},

		UploadSesId: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<any>
		) => {
			state.uploadsesId = action.payload;
		},

		ReviewPageAdsetData: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<[]>
		) => {
			state.reviewPageAdsetData = action.payload;
		},

		SchedulerDraftData: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<{}>
		) => {
			state.schedulerDraftData = action.payload;
		},

		PresetsData:  (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<[]>
		) => {
			state.presetsData = action.payload;
		},

		SelectedPresetsData: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<[]>
		) => {
			state.selectedPresetsData = action.payload;
		},
		reUseBatchCreatives: (
			state: Draft<AutoSchedulerIntialState>,
			action: PayloadAction<[]>
		) => {
			state.reuseBatchCreative = action.payload;
		},
	}
});

export const {
	SnapChatProfiles,
	FacebookProfiles,
	TiktokProfiles,
	ReviewPageAdsetData,
	SnapchatAdAccount,
	FacebookAdAccount,
    TiktokAdAccount,
	SnapchatCampaign,
	FacebookCampaign,
	TiktokCampaign,
	TiktokIdentity,
	TiktokInterests,
	SnapchatInterests,
	FacebookInterests,
	SnapchatPixels,
	TiktokPixels,
	FacebookPixels,
	SnapchatOptimizeEvents,
	FacebookOptimizeEvents,
	TiktokOptimizeEvents,
	SnapchatCustomAudience,
	FacebookCustomAudience,
	TiktokCustomAudience,
	CreativesProgress,
	CreativesData,
	SchedulerHistoryData,
	FacebookPageData,
	InstagramPageData,
	UploadSesId,
	SchedulerDraftData,
	PresetsData,
	SelectedPresetsData,
	reUseBatchCreatives,
	SchedulerHistoryRowData
} = autoSchedulerSlice.actions;

export default autoSchedulerSlice.reducer;