import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
    accountDetails: any;
}

const initialState: InitialState = {
    accountDetails: {},
};

const settingSlice = createSlice({
    name: 'settingSlice',
    initialState: initialState,
    reducers: {
        setAccountDetails: (
            state: Draft<InitialState>,
            action: PayloadAction<string>
        ) => {
            state.accountDetails = action.payload;
        },
    }
});

export const { setAccountDetails } =
    settingSlice.actions;

export default settingSlice.reducer;
