// ** React Imports
import { useEffect, useState } from "react";

// ** MUI Imports
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  LinearProgress,
  IconButton,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// ** Redux
import { useAppDispatch, useAppSelector } from "store/store";
import {
  deleteLinkfireGeneratorProfile,
  getLinkfireGeneratorConnection,
  getLinkfiregeneratorInfo,
} from "store/Thunk/platformConnectionThunk";

// ** Icons
import CloseIcon from "@mui/icons-material/Close";

// ** Custom Components
import LinkfireGeneratorTab from "pages/platform/Tabs/LinkfireGeneratorTab";

// ** Assets
import { ProfileIcon } from "assets";

interface Props {
  setSidebarDrawer?: any;
  title?: any;
  onSpotifyLogin?: any;
  setLoading?: any;
}

export default function LinkfireGeneratorDrawer({
  setSidebarDrawer,
  title,
  onSpotifyLogin,
  setLoading,
}: Props) {
  const dispatch: any = useAppDispatch();

  const { linkfireGeneratorConnection } = useAppSelector(
    (state: any) => state.PlatformSlice
  );

  const [scrapperloading, setScrapperLoading] = useState(true);
  const [connectionLoaging, setConnectionLoading] = useState(true);
  const [dialog, setDialog] = useState({ open: false, name: "", id: null });
  const [loadingDelete, setLoadingdelete] = useState(false);

  useEffect(() => {
    if (title == "Linkfire Generator") {
      let data: any = null;
      let page: any = 1;
      dispatch(getLinkfiregeneratorInfo({ data, page })).then((res: any) => {
        setScrapperLoading(false);
      });
      dispatch(getLinkfireGeneratorConnection({ data })).then((res: any) => {
        setConnectionLoading(false);
      });
    }
  }, []);

  const handledRemoveProfile = (id: any) => {
    setLoadingdelete(true);
    let data = {
      id: linkfireGeneratorConnection?.id,
    };
    dispatch(deleteLinkfireGeneratorProfile({ data })).then((response: any) => {
      setLoadingdelete(false);
      setLoading(true);
      setDialog({ open: false, name: "", id: null });
      setSidebarDrawer({ open: false, Drawer: "" });
      onSpotifyLogin();
    });
  };

  return (
    <>
      {scrapperloading || connectionLoaging ? (
        <>
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
          <Box
            className="sync-align-justify-center"
            sx={{
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <Box className="sync-justify-end" sx={{ padding: 3 }}>
            <IconButton
              onClick={() => setSidebarDrawer({ open: false, Drawer: "" })}
            >
              <CloseIcon style={{ height: 25, width: 25 }} />
            </IconButton>
          </Box>
          <Box sx={{ paddingLeft: "58px", paddingRight: "58px" }}>
            <Box>
              <Typography variant="h5" className="sync-justify-center">
                {title}
              </Typography>
            </Box>
            <Grid container sx={{ mt: 5 }}>
              <Grid item xs={12} sm={6} padding={2}>
                <Box
                  className="sync-align-center"
                  sx={{
                    border: "1px solid #EB5757",
                    borderWidth: "1.5px",
                    background: "rgba(255, 77, 73, 0.05)",
                    borderRadius: "10px",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    padding: "10px",
                  }}
                >
                  <Box className="sync-align-center">
                    <Box
                      component="img"
                      src={ProfileIcon}
                      height={30}
                      width={30}
                    />
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: 500, color: "#EB5757", ml: 3 }}
                    >
                      {linkfireGeneratorConnection?.first_name}{" "}
                      {linkfireGeneratorConnection?.last_name}
                    </Typography>
                  </Box>
                  <Box className="sync-align-center">
                    <IconButton
                      onClick={() =>
                        setDialog({
                          ...dialog,
                          open: true,
                          name:
                            linkfireGeneratorConnection?.first_name +
                            " " +
                            linkfireGeneratorConnection?.last_name,
                          id: null,
                        })
                      }
                    >
                      <CloseIcon style={{ height: 20, width: 20 }} />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>
            </Grid>

            <LinkfireGeneratorTab title={title} />
            <Box className="sync-justify-end">
              <Button
                variant="outlined"
                sx={{ margin: 3 }}
                onClick={() => setSidebarDrawer({ open: false, Drawer: "" })}
              >
                CANCEL
              </Button>
              <Button
                variant="contained"
                sx={{ margin: 3 }}
                onClick={() => setSidebarDrawer({ open: false, Drawer: "" })}
              >
                FINISH
              </Button>
            </Box>
          </Box>
        </>
      )}
      <Dialog
        fullWidth
        open={dialog?.open}
        maxWidth="sm"
        scroll="body"
        onClose={() =>
          setDialog({ ...dialog, open: false, name: "", id: null })
        }
      >
        <DialogContent
          sx={{
            px: { sm: 10 },
            py: { sm: 7.5 },
            position: "relative",
          }}
        >
          <Box>
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              Unlink Profile
            </Typography>
            <Typography variant="subtitle2" sx={{ fontWeight: 400, mt: 3 }}>
              Are you sure you want to unlink "{dialog?.name}"?{" "}
            </Typography>
            <Box sx={{ display: "flex", mt: 7 }}>
              <Button
                fullWidth
                size="large"
                variant="outlined"
                sx={{ mb: 4, mr: 2 }}
                onClick={() =>
                  setDialog({ ...dialog, open: false, name: "", id: null })
                }
              >
                CANCEL
              </Button>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                onClick={() => handledRemoveProfile(dialog?.id)}
                loading={loadingDelete}
                variant="contained"
                sx={{ mb: 4, ml: 2 }}
              >
                UNLINK
              </LoadingButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
