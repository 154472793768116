// ** MUI Imports
import { Box, Typography, useTheme } from "@mui/material";

// ** Assets
import { Dollar } from "assets";

export default function Earning(props: any) {
  const theme = useTheme();
  const { earning } = props;

  return (
    <Box className="post-type-block sync-flex-direction" alignItems="center">
      <Box className="sync-flex-direction-align">
        <Box
          className="milestone-img"
          component="img"
          alt="Publish type image not found"
          src={Dollar}
        />
        <Typography
          variant="h6"
          color={theme.palette.customColors.comet}
          sx={{ ml: "10px" }}
        >
          Job earning
        </Typography>
      </Box>
      <Box className="sync-justify-content">
        <Typography
          variant="subtitle1"
          className="earning-txt"
          sx={{ fontWeight: 800 }}
        >
          {"$" + earning.selected_budget}
        </Typography>
        <Typography
          variant="subtitle1"
          className="earning-txt"
          sx={{ fontWeight: 400 }}
        >
          per video
        </Typography>
      </Box>
    </Box>
  );
}
