import { Box, Typography, FormControl, TextField, styled, BoxProps } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import LogoImage from "components/logo/logo";
import ScreenLayout from "components/screenLayout/screen-layout";
import { Link } from "react-router-dom"
import React, { useEffect } from "react";
import Validations from "helper/validations";
import { useDispatch } from "react-redux";
import { forgetPassword } from "store/Thunk/authThunk";
import { useAppDispatch, useAppSelector } from "store/store";
import { LoadingButton } from "@mui/lab";
import { AuthLoading } from "store/Slice/AuthSlice";

interface FormValidation {
  email?: string;
}

interface State {
  email: string;
}

const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  maxWidth: '400px',
  [theme.breakpoints.down('md')]: {
    maxWidth: 400
  } 
}))

export default function ForgetPassword(){
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { authLoading } = useAppSelector((state) => state.authReducer);
  const [values, setValues] = React.useState({
    email: "",
  });

  const [formError, setFormError] = React.useState<FormValidation>();

  const [loading, setLoading] = React.useState<boolean>();

  useEffect(() => {
    setLoading(authLoading);
  }, [authLoading])

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };
  
  const handleSubmit = (event: any) => {
    event.preventDefault();
    let _request: any = {
      email: values.email,
  }
  let allError = Validations.validateForgetPasswordForm(_request);
  if (
      Object.entries(allError).length === 0 &&
      allError.constructor === Object
  ) {
    dispatch(AuthLoading(true));
      dispatch(forgetPassword({_request, navigate}))
  }else{
  setFormError(allError);
  }
  }
  
    return(
   <ScreenLayout >
   
        <BoxWrapper>
        <Box className="sync-align-justify-center" sx={{ mb: 8 }}>
            <LogoImage />
          </Box>
          <Box sx={{ mb: 4 }}>
            <Typography variant='h5' sx={{ mb: 1.5, fontWeight: 600, letterSpacing: '0.18px' }}>Forgot Password 🔒</Typography>
            <Typography variant="body2">No worries, just enter your email and we will send you instructions to reset your password</Typography>
          </Box>
        
          <form noValidate onSubmit={handleSubmit} autoComplete='off' >
          
          <FormControl fullWidth sx={{ mb: 4 }}>
                  <TextField
                    placeholder='Email'
                    label='Email'
                    onChange={handleChange("email")}
                        error={formError && formError.email ? true : false}
                        helperText={formError && formError.email}
                  />
            </FormControl>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          loading={loading}
          variant="contained"
          disabled={loading}
          sx={{ mb: 7 }}
        >
          SEND EMAIL
        </LoadingButton>
              <Box className="sync-align-justify-center" sx={{ flexWrap: 'wrap' }} >
                <Link to='/' className="sync-align-justify-center" style={{textDecoration: 'none', flexWrap: 'wrap'}}>
                <ArrowBackIosIcon sx={{color: 'primary.main', height: '21px'}}/> 
                <Typography variant="subtitle1" sx={{color: 'primary.main'}}>
                   Back to login
                  </Typography>
                  </Link>
            </Box>
          </form>
        </BoxWrapper>
  </ScreenLayout >
  )
}