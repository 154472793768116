// ** React
import { useEffect, useState } from "react";

// ** MUI Imports
import { Box, Button, Typography } from "@mui/material";

// ** Custom Component
import { weekDays } from "@core/constants/global-constants";
import { MEDIA_PLANNER } from "@core/constants/title-constants";

// ** Custom styles
import "styles/dayparting.scss";

const Timesheet = ({ tiktok, setTiktok }: any) => {
  let autoSchedulerTitle = MEDIA_PLANNER.auto_scheduler;

  const [selectedCells, setSelectedCells] = useState<any[]>([]);
  const [binaryCells, setBinaryCells] = useState<any[]>(
    tiktok?.dayparsingString?.length
      ? tiktok?.dayparsingString
      : Array(7).fill("0".repeat(48))
  );

  useEffect(() => {
    const handleContextMenu = (e: any) => {
      e.preventDefault(); // prevents the default right-click menu from appearing
    };
    // add the event listener to the component's root element
    const rootElement: any = document.getElementById("dayParsing");

    rootElement?.addEventListener("contextmenu", handleContextMenu);
    // remove the event listener when the component is unmounted

    return () => {
      rootElement?.removeEventListener("contextmenu", handleContextMenu);
    };
  }, []);

  const setCharAt = (str: string, index: number, chr: any) => {
    if (index > str.length - 1) return str;
    return str.substring(0, index) + chr + str.substring(index + 1);
  };

  const handleCellClick = (e: any, row: any, col: any) => {
    let temp = [...binaryCells];
    let getSelectedRow = temp[row].toString();
    let updatedString = temp[row].toString();
    if (e.buttons === 1) {
      if (getSelectedRow.charAt(col) === "0") {
        updatedString = setCharAt(getSelectedRow, col, "1");
      }

      temp[row] = updatedString;

      setSelectedCells([{ row, col }]);
      setBinaryCells(temp);
      setTiktok({ ...tiktok, dayparsingString: temp });
    } else if (e.buttons === 2) {
      if (getSelectedRow.charAt(col) === "1") {
        updatedString = setCharAt(getSelectedRow, col, "0");
      }
      temp[row] = updatedString;

      setSelectedCells([{ row, col }]);
      setBinaryCells(temp);
      setTiktok({ ...tiktok, dayparsingString: temp });
    }
  };

  const handleMouseOver = (e: any, row: any, col: any) => {
    const selectedRows = getRows(selectedCells);
    const selectedCols = getCols(selectedCells);

    const startRow = Math.min(selectedRows[0], row);
    const endRow = Math.max(selectedRows[selectedRows.length - 1], row);
    const startCol = Math.min(selectedCols[0], col);
    const endCol = Math.max(selectedCols[selectedCols.length - 1], col);

    const newSelectedCells: any = [];
    let newArray: any[] = [...binaryCells];
    if (e.buttons === 1) {
      for (let i = startRow; i <= endRow; i++) {
        for (let j = startCol; j <= endCol; j++) {
          newSelectedCells.push({ row: i, col: j });
        }
        let str = newArray[i];
        newArray[i] = `${str.substring(0, startCol)}${"1".repeat(
          endCol + 1 - startCol
        )}${str.substring(endCol + 1, 48)}`;
      }

      setBinaryCells(newArray);
      setTiktok({ ...tiktok, dayparsingString: newArray });

      setSelectedCells(newSelectedCells);
    } else if (e.buttons === 2) {
      for (let i = startRow; i <= endRow; i++) {
        for (let j = startCol; j <= endCol; j++) {
          newSelectedCells.push({ row: i, col: j });
        }
        let str = newArray[i];
        newArray[i] = `${str.substring(0, startCol)}${"0".repeat(
          endCol + 1 - startCol
        )}${str.substring(endCol + 1, 48)}`;
      }

      setBinaryCells(newArray);
      setTiktok({ ...tiktok, dayparsingString: newArray });

      setSelectedCells(newSelectedCells);
    }
  };

  const handleClearClick = () => {
    setSelectedCells([]);
    setBinaryCells(Array(7).fill("0".repeat(48)));
    setTiktok({ ...tiktok, dayparsingString: Array(7).fill("0".repeat(48)) });
  };

  const getRows = (cells: any) => {
    return cells.map((cell: any) => cell.row);
  };

  const getCols = (cells: any) => {
    return cells.map((cell: any) => cell.col);
  };

  const renderTableHeader = () => {
    const headerCells = [];

    for (let i = 0; i < 24; i++) {
      headerCells.push(<td colSpan={2}>{i}</td>);
    }

    return <tr>{headerCells}</tr>;
  };

  const renderTableRows = () => {
    const rows = [];

    for (let i = 0; i < 7; i++) {
      const cells = [];

      for (let j = 0; j < 48; j++) {
        const isSelected = binaryCells[i][j] === "1" ? true : false;
        const isStart =
          j > 0 && binaryCells[i][j] === "1" && binaryCells[i][j - 1] === "0";
        const isEnd =
          j < 47 && binaryCells[i][j] === "1" && binaryCells[i][j + 1] === "0";
        const startRow = binaryCells.findIndex((row) => row.includes("1"));
        const endRow =
          binaryCells.length -
          binaryCells
            .slice()
            .reverse()
            .findIndex((row) => row.includes("1")) -
          1;
        const startCol = isSelected && (j === 0 || !binaryCells[i][j - 1]);
        const endCol = isSelected && (j === 47 || !binaryCells[i][j + 1]);

        cells.push(
          <td
            key={`${i}-${j}`}
            className={[
              isSelected ? "selected" : "",
              isStart ? "start" : "",
              isEnd ? "end" : "",
              i === startRow && startCol ? "startCell" : "",
              i === endRow && endCol ? "endCell" : "",
            ].join(" ")}
            onMouseDown={(e) => handleCellClick(e, i, j)}
            onMouseOver={(e) => handleMouseOver(e, i, j)}
          ></td>
        );
      }

      rows.push(
        <tr
          key={i}
          style={{
            userSelect: "none",
          }}
        >
          <td
            style={{
              userSelect: "none",
              display: "table-cell",
            }}
          >
            {weekDays[i]}
          </td>
          {cells}
        </tr>
      );
    }

    return rows;
  };

  const getHours = (min: any) => {
    let minutes = min * 30;
    let hours = Math.floor(minutes / 60);
    let minutesRemainder = minutes % 60;
    let timeString =
      hours.toString().padStart(2, "0") +
      ":" +
      minutesRemainder.toString().padStart(2, "0");

    return timeString;
  };

  const findRanges = (arr: any) => {
    const ranges = [];
    for (let i = 0; i < arr.length; i++) {
      const row = arr[i];
      let start = null;
      let end = null;
      let rowRanges = [];

      for (let j = 0; j <= row.length; j++) {
        if (row[j] === "1" && start === null) {
          start = j;
        }
        if (row[j] === "0" && start !== null) {
          end = j - 1;
          rowRanges.push({ start: getHours(start), end: getHours(end + 1) });
          start = null;
          end = null;
        }
        if (row[j] === "1" && start !== null) {
          end = j;
        }
      }
      if (end !== null) {
        rowRanges.push({ start: getHours(start), end: getHours(end + 1) });
      }
      let tempRanges: any[] = [];
      let temp = null;

      rowRanges.map((data, index) => {
        temp = data.start + "~" + data.end;

        tempRanges.push(temp);
      });
      ranges.push(tempRanges);
    }
    return ranges;
  };

  let timeRanges = findRanges(binaryCells);

  return (
    <div
      id="dayParsing"
      className="timesheet"
      style={{
        width: "fit-content",
        marginRight: "10px",
      }}
    >
      <table>
        <tr>
          <th style={{ padding: "10px" }} rowSpan={3}>
            {autoSchedulerTitle.WEEK_TIME}
          </th>
        </tr>
        <tr>
          <td colSpan={24}>00:00 - 12:00</td>
          <td colSpan={24}>12:00 - 24:00</td>
        </tr>
        {renderTableHeader()}
        {renderTableRows()}
      </table>
      <Box display="flex" justifyContent="end">
        <Button variant="text" onClick={handleClearClick}>
          {autoSchedulerTitle.CLEAR_SELECTION}
        </Button>
      </Box>

      <Box>
        <Typography sx={{ mb: "20px" }}>
          {autoSchedulerTitle.SELECTED_TIME_PERIOD}
        </Typography>

        {weekDays.map((data: any, weekDaysIndex: number) => (
          <Box
            sx={{ display: "flex", flexDirection: "row" }}
            key={weekDaysIndex}
          >
            {binaryCells[weekDaysIndex].includes("1") && (
              <>
                <Typography sx={{ mr: "10px" }}>{data}</Typography>
                {timeRanges.map(
                  (data: any, index: number) =>
                    index === weekDaysIndex && (
                      <Typography>{data.join(", ")}</Typography>
                    )
                )}
              </>
            )}
          </Box>
        ))}
      </Box>
    </div>
  );
};

export default Timesheet;
