import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Typography } from "@mui/material";

interface Props {
  handleOnClick?(): void;
}

export default function SortByCategory(props: any) {
  const { handleOnClick } = props;
  return (
    <Typography className="showMore" onClick={handleOnClick}>
      SHOW MORE <KeyboardArrowDownIcon sx={{ ml: "10px" }} />
    </Typography>
  );
}
