import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Grow, useTheme } from "@mui/material";
import { Box, Typography } from "@mui/material";
import ContentTypeCard from "components/ContentTypeCard";
import {
  Tiktok,
  Snapchat,
  Instagram,
  BlankImage,
  Vertical,
  Horizontal,
  Square,
} from "assets";

export default function ListCard(props: any) {
  const {
    searchData,
    cardData,
    style,
    redirectURL,
    isArchived = false,
    isSubmission,
  } = props;

  const theme = useTheme();
  let hover_search = require("../../assets/all-jobs/hover_search.svg");
  const navigate = useNavigate();

  const handleJobDetailClick = () => {
    navigate(`${redirectURL}/${cardData.id}`);
  };

  return (
    <Grow in={true}>
    <Box
      style={{ ...style, cursor: "pointer" }}
      onClick={() => handleJobDetailClick()}
      className="list-card-block"
    >
      <Box className="heading-block">
        <Box
          className="detail-block"
          sx={{
            "&:hover": {
              "&::before": {
                content: `url(${hover_search})`,
                position: "absolute",
                top: "10px",
                right: "10px",
                zIndex: "2",
              },
              "&::after": {
                content: "''",
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                background:
                  "linear-gradient(183.61deg, rgba(0, 0, 0, 0.4) 2.97%, rgba(0, 0, 0, 0.33) 108.85%)",
                zIndex: "1",
              },
            },
          }}
        >
          <Box className="detail-left-icon">
            <ContentTypeCard contentType={searchData ? searchData?.job_requirements?.publish_content_type : cardData?.job_requirements?.publish_content_type } />
          </Box>
          <Box>
            <Typography
              color={theme.palette.background.default}
              className="list-card-title"
            >
              {searchData ? isSubmission ? searchData?.job_details?.title : searchData?.title : isSubmission ? cardData?.job_details.title : cardData?.title}
            </Typography>
            <Typography
              variant="caption"
              color={theme.palette.background.default}
            >
              {searchData ? isSubmission
                ? searchData?.brand?.brand_name
                : searchData?.brand_details?.brand_name:
                 isSubmission
                ? cardData?.brand?.brand_name
                : cardData?.brand_details?.brand_name
                }
            </Typography>
          </Box>
        </Box>

        <Box
          component="img"
          src={searchData ?
            isSubmission
            ? searchData?.job_details?.thumbnails_url
            : searchData?.thumbnails_url :
            isSubmission
              ? cardData?.job_details.thumbnails_url
              : cardData?.thumbnails_url 
           
          }
          className="list-card-image"
        />
      </Box>
      <Box sx={{ p: "10px", width: "100%" }}>
        <Box className="social-detail-block">
          {!isArchived ? (
            <Box
              component="img"
              sx={{ mr: "13px" }}
              alt="Social image not found"
              src={
                cardData.platforms
                  ? cardData.platforms === "Instagram"
                    ? Instagram
                    : cardData.platforms === "Snapchat"
                    ? Snapchat
                    : cardData.platforms === "Tiktok"
                    ? Tiktok
                    : BlankImage
                  : cardData.dimension === "Vertical"
                  ? Vertical
                  : cardData.dimension === "Horizontal"
                  ? Horizontal
                  : cardData.dimension === "Square"
                  ? Square
                  : BlankImage
              }
            />
          ) : (
            <Typography
              variant="body1"
              color={theme.palette.customColors.comet}
            >
              {isSubmission ? "Total earned" : "Total spend"}
            </Typography>
          )}
          <Typography variant="h6" className="list-card-bid">
            {"$" + (
              searchData ? (searchData &&
                (isSubmission
                  ? searchData?.job_details.budget
                  : searchData?.selected_budget)) :  (cardData &&
                    (isSubmission
                      ? cardData?.job_details.budget
                      : cardData?.selected_budget))
            )
               
              
            }
          </Typography>
        </Box>
      </Box>
    </Box>
    </Grow>
  );
}
