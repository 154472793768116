// ** React Imports
import { SyntheticEvent, useState } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiCardContent, { CardContentProps } from "@mui/material/CardContent";
import creatorMarketplace_FAQ from "data/json/marketplace_faq.json";
import mediaPlanner_FAQ from "data/json/mediaplanner_faq.json";

// ** Icons Imports
import ChevronDown from "mdi-material-ui/ChevronDown";

// ** Types
import { PricingDataType, PricingFaqType } from "components/plan-details/types";
import { Grid } from "@mui/material";

interface Props {
  data: PricingDataType | null;
}

// ** Styled Components
const CardContent = styled(MuiCardContent)<CardContentProps>(({ theme }) => ({
  padding: `${theme.spacing(20, 35)} !important`,
  [theme.breakpoints.down("lg")]: {
    padding: `${theme.spacing(12.5, 20)} !important`,
  },
  [theme.breakpoints.down("sm")]: {
    padding: `${theme.spacing(10, 5)} !important`,
  },
}));

const Accordion = styled(MuiAccordion)<AccordionProps>(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:before": {
    height: 0,
  },
  "&.Mui-expanded": {
    boxShadow: "none",
  },
}));

const PricingFooter = (props: Props) => {
  // ** Props
  const { data } = props;

  // ** Props
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const renderAccordion = (arr: any, name: string) => {
    return arr.map((item: PricingFaqType) => {
      return (
        <Accordion
          key={item.id}
          elevation={0}
          expanded={expanded === `${name}_${item.id}`}
          onChange={handleChange(`${name}_${item.id}`)}
        >
          <AccordionSummary
            expandIcon={<ChevronDown />}
            id={`pricing-accordion-${item.id}-header`}
            aria-controls={`pricing-accordion-${item.id}-content`}
          >
            <Typography>{item.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{item.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      );
    });
  };

  return (
    <CardContent>
      <Box sx={{ mb: 12, textAlign: "center" }}>
        <Typography variant="h5" sx={{ mb: 2.5 }}>
          FAQ
        </Typography>
        <Typography variant="body2">
          Have questions? We've got answers!
        </Typography>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Grid container spacing={3}>
          <Grid item md={12}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Media Planner
            </Typography>
            <Box>{renderAccordion(mediaPlanner_FAQ, "media_planner")}</Box>
          </Grid>
          {/* <Grid item md={6}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Creator Marketplace
            </Typography>
            <Box>
              {renderAccordion(creatorMarketplace_FAQ, "creator_marketplace")}
            </Box>
          </Grid> */}
        </Grid>
      </Box>
    </CardContent>
  );
};

export default PricingFooter;
