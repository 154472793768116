const MESSAGES = {
  //login
  LOGIN: {
    ERROR: {
      EMAIL: {
        EMPTY: "Please enter your email",
        EMAIL_VALIDATION: "Please enter a valid email address.",
      },
      PASSWORD: {
        EMPTY: "Please enter your password",
        STRONG_PASSWORD: "Please enter valid password.",
      },
    },
  },
  //register
  REGISTER: {
    ERROR: {
      FIRST_NAME: {
        EMPTY: "Please enter your first name.",
        INVALID: "Please enter valid first name",
        WHITE_SPACE: "Name should not contain space",
      },
      LAST_NAME: {
        EMPTY: "Please enter your last name.",
        INVALID: "Please enter valid last name",
        WHITE_SPACE: "Name should not contain space",
      },
      EMAIL: {
        EMPTY: "Please enter an email address.",
        EMAIL_VALIDATION: "Please enter a valid email address.",
      },
      CONFIRM_EMAIL: {
        EMPTY: "Please enter confirm email.",
        INVALID: "Emails don't match",
      },
      PASSWORD: {
        EMPTY: "Please enter a password.",
        STRONG_PASSWORD: "Please enter valid password.",
      },
      BUSINESS: {
        EMPTY: "Please select an option from the list.",
      },
      PRIVACY: {
        EMPTY: "Please accept our privacy Policy & Terms of Use.",
      },
      NOT_MATCH: {
        INVALID: "Password Did Not Match",
      },
      COMPANY_NAME: {
        EMPTY: "Please enter company name.",
      },
    },
  },
  LOGIN_LINKFIRE_GENERATOR: {
    ERROR: {
      API_KEY: {
        EMPTY: "Please enter an API key.",
      },
    },
  },
  ADD_LINKFIRE_GENERATOR: {
    ERROR: {
      URLS: {
        EMPTY: "Please enter your Linkfire link(s)",
      },
      GROUP_ID: {
        EMPTY: "Please choose a group from the list.",
      },
    },
  },
  ADD_SPOOTIFY: {
    ERROR: {
      NAME: {
        EMPTY: "Please enter name.",
      },
      GROUP_ID: {
        EMPTY: "Please select group.",
      },
    },
  },
  //add child
  CHILD: {
    ERROR: {
      NAME: {
        EMPTY: "Please enter full name.",
        INVALID: "Please enter valid full name",
        WHITE_SPACE: "Name should not contain space",
      },
      DOB: {
        EMPTY: "Please enter date of birth.",
        DOB_VALIDATION: "Please enter valid birth date",
      },
      GENDER: {
        EMPTY: "Please select gender.",
      },
      SCHOOL_NAME: {
        EMPTY: "Please enter school name.",
      },
      COUNTRY: {
        EMPTY: "Please select country.",
      },
      CLASS: {
        EMPTY: "Please select class.",
      },
      STAGE: {
        EMPTY: "Please select stage.",
      },
      VIDEO_COUNT: {
        EMPTY: "Please select no of video.",
      },
    },
  },
  VALID_PASSWORD: {
    ERROR: {
      EMAIL: {
        EMPTY: "Email is required.",
        EMAIL_VALIDATION: "Please enter a valid email address.",
      },
      PASSWORD: {
        EMPTY: "Password is required.",
        STRONG_PASSWORD:
          "Your password must be contain a mix of upper and lower case letters, a number, and a symbol.",
        INVALID_LENGTH: "Your password must be at least 8 characters long.",
        SMALL_LETTER: "Your password must contain at least one small letter.",
        CAPITAL_LETTER:
          "Your password must contain at least one capital letter.",
        DIGIT: "Your password must contain at least one digit.",
        SPECIAL_CHARACTERS:
          "Your password must contain at least one special characters.",
        WHITE_SPACE: "Password should not contain space",
      },
      CPASSWORD: {
        EMPTY: "Please enter confirm password.",
        CONFIRM_PASS_VALIDATION: "Confirm password shoud be same as password.",
      },
      PARENT_PASSWORD: {
        INVALID:
          "Sorry but that password doesn’t seem to be correct. Please check and try again",
      },
      CURRENT_PASSWORD: {
        EMPTY: "Required to change any password",
      },
    },
  },
  ADMIN_EDIT: {
    ERROR: {
      NAME: {
        EMPTY: "Please enter name.",
      },
      EMAIL: {
        EMPTY: "Please enter an email address.",
        EMAIL_VALIDATION: "Please enter a valid email address.",
      },
      OLD_PASSWORD: {
        EMPTY: "Please enter old password.",
      },
    },
  },
  VACATION: {
    ERROR: {
      TITLE: {
        EMPTY: "Please enter Title.",
      },
      COUNTRY: {
        EMPTY: "Please select country.",
      },
      DATE_PICKER: {
        EMPTY: "Please select start date and end date.",
      },
    },
  },
  WORKSHEET_MODEL: {
    ERROR: {
      TITLE: {
        EMPTY: "Title must be required.",
      },
      DESCRIPTION: {
        EMPTY: "Description must be required.",
      },
      FILE: {
        EMPTY: "File must be required.",
      },
    },
  },
  MAPPING_LESSON: {
    ERROR: {
      LESSON: {
        EMPTY: "Lesson must be required.",
      },
      WORKSHEET: {
        EMPTY: "Worksheet must be required.",
      },
      STAGE: {
        EMPTY: "Stage must be required.",
      },
      LESSON_NO: {
        EMPTY: "lesson no must be required.",
        INVALID: "This lesson no is already assign.",
      },
    },
  },
  VIDEO_FORM: {
    ERROR: {
      TITLE: {
        EMPTY: "Title must be required.",
      },
      DESCRIPTION: {
        EMPTY: "Description must be required.",
      },
    },
  },
  DISCOUNT_CODE: {
    ERROR: {
      NAME: {
        EMPTY: "Name must be required.",
      },
      CODE: {
        EMPTY: "Discount code must be required.",
      },
      CURRENCY: {
        EMPTY: "Currency must be required.",
      },
      AMOUNT: {
        EMPTY: "Amount must be required.",
      },
      EXPIRY_DATE: {
        EMPTY: "Expiry date must be required.",
      },
      DURATION_IN_MONTH: {
        EMPTY: "Duration months field must be required.",
      },
      SUBSCRIPTION_PLAN: {
        EMPTY: "Subscription plan must be required.",
      },
    },
  },
  ADD_PLAN: {
    ERROR: {
      TITLE: {
        EMPTY: "Title must be required.",
      },
      SUBTITLE: {
        EMPTY: "Subtitle must be required.",
      },
      NO_OF_STUDENT: {
        EMPTY: "No of student must be required.",
        ONLYT_NUMBER: "No of student can contain only numbers.",
      },
      AMOUNT: {
        EMPTY: "Amount must be required.",
        INVALID_AMOUNT: "Please enter valid amount.",
      },
      DESCRIPTION: {
        EMPTY: "Description must be required.",
      },
    },
  },
  JOB: {
    ERROR: {
      TITLE: {
        EMPTY: "Job title must be required.",
      },
      DESCRIPTION: {
        EMPTY: "Job Descrioption must be required.",
      },
      CATEGORY: {
        EMPTY: "Job Category must be required.",
      },
      THUMBNAIL: {
        EMPTY: "Job thumbnail must be required.",
      },
    },
  },
  RESET_PASSWORD: {
    ERROR: {
      OLD_PASSWORD: {
        EMPTY: "Please enter your current password.",
      },
      NEW_PASSWORD: {
        EMPTY: "Please enter a new password.",
        INVALID_LENGTH: "Your new password must be at least 8 characters long.",
        STRONG_PASSWORD:
          "Your new password must be at least 8 characters long and contain a mix of upper and lower case letters, a number, and a symbol.",
      },
      CONFIRM_PASSWORD: {
        EMPTY: "Please confirm your new password.",
        NOT_MATCH:
          "The new passwords you entered do not match. Please try again.",
      },
    },
  },
  ACCOUNT_FORM: {
    ERROR: {
      NAME: {
        EMPTY: "Please enter name.",
      },
      EMAIL: {
        EMPTY: "Please enter email.",
      },
      PHONE_NUMBER: {
        EMPTY: "Please enter phone number.",
        INVALID: "Please enter valid phone number.",
      },
      COMPANY: {
        EMPTY: "Please enter company.",
      },
      COUNTRY: {
        EMPTY: "Please enter country.",
      },
      GENDER: {
        EMPTY: "Please enter gender.",
      },
    },
  },
  CONTACT_DIALOG_FORM: {
    ERROR: {
      AD_SPEND: {
        EMPTY: "Please enter valid estimated monthly ad spend.",
      },
      ADDITIONAL_NOTES: {
        EMPTY: "Please enter additional notes.",
      },
    },
  },
  LANDING_PAGE_URL: {
    ERROR: {
      URL: {
        EMPTY: "Please enter landing page url.",
        INVALID: "Please enter valid landing page url.",
      },
    },
  },
};

export default MESSAGES;
