import { createAsyncThunk } from "@reduxjs/toolkit";
import { setCreatorInfo, setLoading } from "../Slice/CreateJobSlice";
import { notificationSuccess, notificationFail } from "../Slice/notificationSlice";
import API from "../api";
import { setFacebookConnect } from "store/Slice/CreateBrandSlice";


export const saveCreatorInfo = createAsyncThunk(
    "saveCreatorInfo",
    async (formData: any, {dispatch}) => {
        try {
            dispatch(setLoading(true));
            const response = await API.post(
                `api/v1/marketplace/creator-profile/`,
                formData
            );
            dispatch(notificationSuccess(response?.data.message));
            dispatch(setLoading(false));
            return response;
        } catch (err: any) {
            dispatch(notificationFail(err?.message));
            dispatch(setLoading(false));
            return err;
        }
    },
);

export const getCreatorInfo = createAsyncThunk(
    "getCreatorInfo",
    async ({navigate}: any, {dispatch}: any) => {
        dispatch(setLoading(true));

        try {
        const response = await API.get(
            `api/v1/marketplace/creator-profile?flag=my_creator`
        )
        dispatch(setCreatorInfo(response.data[0]));
        return response;
        } catch (err: any) {
            throw err;
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const updateCreatorInfo = createAsyncThunk(
    "updateCreatorInfo",
    async ({formData, id}: any, {dispatch}) => {
        try {
            dispatch(setLoading(true));
            const response = await API.patch(
                `api/v1/marketplace/creator-profile/${id}/`, formData
            )
            dispatch(notificationSuccess(response?.data.message));
            return response;
        } catch (error: any) {
            dispatch(notificationFail(error?.message));
            throw error;
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const connectFacebook = createAsyncThunk(
    "connectFacebook",
    async ({code, callback}: any, {dispatch}: any) => {
        try {
            dispatch(setLoading(true));
            const response = await API.get(
                `api/v1/marketplace/connect-facebook/?code=${code}`
            );
            localStorage.setItem("connectedInstagram", "true");
            if(response?.status === 200 || 201){
                dispatch(getCreatorInfo({ callback }));
            }
            dispatch(notificationSuccess(response?.data.message));
            dispatch()
            return response;
        } catch (error: any) {
            dispatch(notificationFail(error?.response?.data?.message));
            dispatch(setFacebookConnect(error?.response?.data));
            throw error;
        } finally {
            dispatch(setLoading(false));
        }
    },
);

export const connectTiktok = createAsyncThunk(
    "connectFacebook",
    async ({formData, dispatch}: any) => {
        try {
            dispatch(setLoading(true));
            const response = await API.get(
                `api/v1/marketplace/connect-tiktok/?code=${formData.code}`
                );
            localStorage.setItem("connectedTiktok", "true");
            dispatch(notificationSuccess(response?.data.message));
            return response;
        } catch (error: any) {
            dispatch(notificationFail(error?.message));
            throw error;
        } finally {
            dispatch(setLoading(false));
        }
    },
);

export const connectSnapChat = createAsyncThunk(
    "connectSnapChat",
    async ({formData, dispatch}: any) => {
        try {
            dispatch(setLoading(true));
            const response = await API.get(
                `api/v1/marketplace/connect-social-snapchat/?code=${formData.code}`
            );
            localStorage.setItem("connectedSnapchat", "true");
            // dispatch(setSnapChatData());
            dispatch(notificationSuccess(response?.data.message));
            return response;
        } catch (error: any) {
            dispatch(notificationFail(error?.message));
            throw error;
        } finally {
            dispatch(setLoading(false));
        }
    },
);
