// ** React Imports
import { useEffect, useRef, useState } from "react";

// ** MUI Imports
import { Box, Button, Grow, TextField, Typography, Grid } from "@mui/material";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";

// ** Redux
import { useAppSelector, useAppDispatch } from "store/store";
import {
  saveBrandProfile,
  updateBrandProfile,
} from "store/Thunk/myBrandProfileThunk";
import { getBrandInfo, setPresignedUrlAPI } from "store/Thunk/createJobThunk";

// ** Icons
import CropOriginalIcon from "@mui/icons-material/CropOriginal";

// ** Custom Components
import { MARKET_PLACE } from "@core/constants/title-constants";

const LinearProgressWithLabel = (
  props: LinearProgressProps & { value: number; isVisible: boolean }
) => {
  return (
    <Box
      className="linear-pogress-block"
      sx={{
        display: !props.isVisible ? "none" : "flex",
        transition: "all .8s",
        visibility: !props.isVisible ? "hidden" : "visible",
      }}
    >
      <Box className="progress-line">
        <LinearProgress variant="determinate" {...props} color="success" />
      </Box>
      <Typography
        sx={{ ml: "8px" }}
        variant="body2"
        color="text.secondary"
      >{`${Math.round(props.value || 0)}%complete`}</Typography>
    </Box>
  );
};

export default function MyBrandProfile() {
  const dispatch: any = useAppDispatch();

  let jobBrandProfile: any = useAppSelector(
    (state: any) => state.createJobReducer.jobBrandDetail
  );
  let { objectKey } = useAppSelector((state: any) => state.createJobReducer);

  const inputFile = useRef<any>(null);

  const [brand, setBrand] = useState<any>({
    id: 0,
    name: "",
    description: "",
    logo: "",
    logoFile: null,
    link: "",
    percentage: 0,
  });

  const [isButtonDisable, setIsButtonDisable] = useState<boolean>(true);
  const [isVisibleProgress, setIsVisibleProgress] = useState<boolean>(true);
  const [errorMessage, setErrorMessage] = useState<string>("");

  useEffect(() => {
    dispatch(getBrandInfo(dispatch));
  }, []);

  useEffect(() => {
    let { logo, name, description, link, percentage } = brand;

    let tempPercentage = 0;

    if (logo || name || description || link) tempPercentage = 25;
    if (
      (logo && name) ||
      (logo && description) ||
      (logo && link) ||
      (name && description) ||
      (name && link) ||
      (description && link)
    )
      tempPercentage = 50;
    if (
      (logo && name && description) ||
      (logo && name && link) ||
      (logo && description && link) ||
      (name && description && link)
    )
      tempPercentage = 75;
    if (logo && name && description && link) {
      tempPercentage = 100;
      setIsButtonDisable(false);
    }
    if (logo && name && description) {
      setIsButtonDisable(false);
    }

    setBrand({ ...brand, percentage: tempPercentage });

    if (percentage === 100) {
      setIsVisibleProgress(false);
    } else setIsVisibleProgress(true);
  }, [brand.logo, brand.description, brand.name, brand.percentage, brand.link]);

  useEffect(() => {
    if (jobBrandProfile && jobBrandProfile.length > 0) {
      setBrand({
        id: jobBrandProfile[0].id,
        name: jobBrandProfile[0].brand_name,
        logo: jobBrandProfile[0].brand_logo_url,
        description: jobBrandProfile[0].brand_description,
        percentage: jobBrandProfile[0].percentage,
        link: jobBrandProfile[0].website_url,
      });
    }
  }, [jobBrandProfile]);

  const handleDiscard = () => {
    if (
      brand.name !== jobBrandProfile[0].brand_name ||
      brand.logo !== jobBrandProfile[0].brand_logo ||
      brand.description !== jobBrandProfile[0].brand_description ||
      brand.link !== jobBrandProfile[0].website_url
    )
      setBrand({
        ...brand,
        name: jobBrandProfile[0].brand_name,
        logo: jobBrandProfile[0].brand_logo,
        description: jobBrandProfile[0].brand_description,
        link: jobBrandProfile[0].website_url,
      });
  };

  const handleReset = () => {
    if (jobBrandProfile[0].brand_logo !== brand.logo)
      setBrand({ ...brand, logo: jobBrandProfile[0].brand_logo });
  };

  const handleUploadBrandLogo = (event: any) => {
    const fileUploaded = event.target.files[0];
    const type = fileUploaded.type.split("/");
    const size = Math.ceil(fileUploaded.size / 1024); /* Convert to KB */
    const finalLink = URL.createObjectURL(fileUploaded);

    if (size > 800) {
      setErrorMessage("File size is larger then 800K");
    } else if (!["jpg", "png", "jpeg"].includes(type[1])) {
      setErrorMessage("Invalid file type. Please upload .jpg or .png file");
    } else {
      setBrand({ ...brand, logo: finalLink, logoFile: fileUploaded });
      setErrorMessage("");
    }

    fileUploaded && handleLogoUpload(event.target.files);
  };

  const handleSave = () => {
    let formData = new FormData();

    // Adding files to the formdata
    formData.append("brand_logo", objectKey);
    formData.append("brand_name", brand.name);
    formData.append("brand_description", brand.description);
    formData.append("website_url", brand.link);

    if (jobBrandProfile && jobBrandProfile.length > 0) {
      dispatch(updateBrandProfile({ formData, id: brand.id }));
    } else {
      dispatch(saveBrandProfile({ formData }));
    }
  };

  const handleLogoUpload = async (file: any) => {
    let data = {
      type: "upload_brand_logo",
      file_name: file[0].name,
    };
    dispatch(setPresignedUrlAPI({ data, file }));
  };

  return (
    <Box className="my-creator-info-block">
      <Box className="sync-flex-direction" sx={{ flexWrap: "wrap" }}>
        <Box>
          <Typography variant="h5" sx={{ mb: 2 }}>
            {MARKET_PLACE.REQUEST_CONTENT.MY_BRAND_PROFILE}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              color: (theme: any) =>
                theme?.palette?.customColors?.border_color_light,
              mb: 2,
            }}
          >
            {MARKET_PLACE.REQUEST_CONTENT.MY_BRAND_PROFILE_SUBTITLE}
          </Typography>
        </Box>
        <Box className="sync-flex-direction-align">
          <Button
            disabled={isButtonDisable}
            sx={{ mr: 5, background: "#666CFF" }}
            variant="contained"
            className="info-button"
            onClick={handleSave}
          >
            {MARKET_PLACE.CREATE_CONTENT.SAVE}
          </Button>
          <Button
            variant="outlined"
            className="info-button"
            onClick={handleDiscard}
          >
            {MARKET_PLACE.CREATE_CONTENT.DISCARD}
          </Button>
        </Box>
      </Box>
      <Grow in={true}>
        <Grid container className="brand-detail-block">
          <Grid item xs={12} md={7}>
            <LinearProgressWithLabel
              value={brand.percentage || 0}
              isVisible={isVisibleProgress}
            />
            <Box
              className="sync-flex-direction-align"
              sx={{ mb: "24px", flexWrap: "wrap" }}
            >
              <Box className="brand-logo-img">
                {brand.logo ? (
                  <Box
                    sx={{ height: "100%", width: "100%" }}
                    component="img"
                    alt="Brand Image"
                    src={brand.logo}
                  />
                ) : (
                  <CropOriginalIcon />
                )}
              </Box>
              <Box>
                <input
                  type="file"
                  onChange={handleUploadBrandLogo}
                  ref={inputFile}
                />

                <Box sx={{ mb: "10px" }}>
                  <Button
                    sx={{ mb: 2 }}
                    id="contained-button-file"
                    variant="contained"
                    className="upload-logo-btn"
                    onClick={() => inputFile?.current?.click()}
                    // onClick={(e: any) => handleLogoUpload(e)}
                  >
                    {MARKET_PLACE.REQUEST_CONTENT.UPLOAD_YOUR_LOGO}
                  </Button>
                  <Button
                    type="submit"
                    variant="outlined"
                    onClick={handleReset}
                  >
                    {MARKET_PLACE.CREATE_CONTENT.RESET}
                  </Button>
                </Box>

                {errorMessage ? (
                  <Typography
                    variant="caption"
                    sx={{
                      color: (theme: any) => theme.palette.customColors.error,
                    }}
                  >
                    {errorMessage}
                  </Typography>
                ) : (
                  <Typography
                    variant="caption"
                    sx={{
                      color: (theme: any) =>
                        theme?.palette?.customColors?.border_color_light,
                    }}
                  >
                    {MARKET_PLACE.CREATE_CONTENT.ALLOW_JPG_PNG}
                  </Typography>
                )}
              </Box>
            </Box>

            <TextField
              id="demo-helper-text-misaligned-no-helper"
              sx={{ width: "100%", mb: "24px" }}
              label="Brand name"
              value={brand.name}
              onChange={(e: any) =>
                setBrand({ ...brand, name: e.target.value })
              }
            />
            <TextField
              sx={{ width: "100%", mb: "24px" }}
              label="Brand description (max. 250 characters) "
              multiline
              rows={4}
              maxRows={4}
              value={brand.description}
              onChange={(e: any) =>
                setBrand({ ...brand, description: e.target.value })
              }
            />
            <TextField
              id="demo-helper-text-misaligned-no-helper"
              sx={{ width: "100%", mb: "24px" }}
              label="Link to website (optional)"
              value={brand.link || ""}
              onChange={(e: any) =>
                setBrand({ ...brand, link: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} md={5}></Grid>
        </Grid>
      </Grow>
    </Box>
  );
}
