// ** React Imports
import { useState } from "react";

// ** MUI Imports
import {
  Box,
  FormControl,
  MenuItem,
  Select,
  Typography,
  Grid,
} from "@mui/material";

// ** Redux
import { useAppDispatch } from "store/store";
import { setMySubmissionList } from "store/Slice/CreateJobSlice";

// ** Third Party Library
import "react-multi-carousel/lib/styles.css";

// ** Custom Components
import { POST_TYPE, IMAGE_NOT_FOUND } from "@core/constants/enum";
import { MARKET_PLACE } from "@core/constants/title-constants";
import {
  historyTabTitle,
  mySubmissionTabTitle,
  mySubmissionVideoOnlyTabTitle,
} from "@core/constants/global-constants";
import MySubmissionTabs from "components/MySubmissionTabs";
import MySubmissionHistoryTab from "components/MySubmissionTabs/MySubmissionHistoryTab";

// ** Assets
import { VideoLibrary, VideoAndPost } from "assets";

export default function MySubmissions() {
  const dispatch: any = useAppDispatch();
  const [contentPublishType, setContenPublishType] =
    useState<any>("collect_both");

  const handleChangePublishType = (event: any) => {
    setContenPublishType(event.target.value);
    dispatch(setMySubmissionList([]));
  };

  return (
    <Box className="all-jobs my-submission-block">
      <Grid container>
        <Grid item md={9} sm={7} xs={12}>
          <Typography variant="h6">
            {MARKET_PLACE.CREATE_CONTENT.SUBMISSIONS}
          </Typography>
          <Typography variant="subtitle2" sx={{ mb: 2 }}>
            {MARKET_PLACE.CREATE_CONTENT.VIEW_YOUR_SUBMISSIONS_PROGRESS}
          </Typography>
        </Grid>
        <Grid item md={3} sm={5} xs={12}>
          <FormControl fullWidth sx={{ width: "100%", mb: 2 }}>
            <Select
              value={contentPublishType}
              onChange={handleChangePublishType}
            >
              <MenuItem value={"collect_video_only"}>
                <Box
                  className="filter-publish-type-img"
                  component="img"
                  alt={IMAGE_NOT_FOUND.NOT_FOUND}
                  src={VideoLibrary}
                />
                {POST_TYPE.VIDEO_ONLY}
              </MenuItem>
              <MenuItem value={"collect_video_and_post"}>
                <Box
                  className="filter-publish-type-img"
                  component="img"
                  alt={IMAGE_NOT_FOUND.NOT_FOUND}
                  src={VideoAndPost}
                />
                {POST_TYPE.VIDEO_AND_POST}
              </MenuItem>
              <MenuItem value={"collect_both"}>
                <Box
                  className="filter-publish-type-img"
                  component="img"
                  alt={IMAGE_NOT_FOUND.NOT_FOUND}
                  src={VideoAndPost}
                />
                {POST_TYPE.ALL}
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Box>
        <MySubmissionTabs
          selectedPublishType={contentPublishType}
          submissionTabTitle={
            contentPublishType === "collect_video_only"
              ? mySubmissionVideoOnlyTabTitle
              : mySubmissionTabTitle
          }
        />
      </Box>
      <Box sx={{ mt: "20px" }}>
        <Typography variant="h6" sx={{ mb: "10px" }}>
          {MARKET_PLACE.CREATE_CONTENT.SUBMISSION_HISTORY}
        </Typography>
      </Box>
      <MySubmissionHistoryTab
        selectedPublishType={contentPublishType}
        submissionTabTitle={historyTabTitle}
        isHistory={true}
      />
    </Box>
  );
}
