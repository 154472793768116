import {
  Card,
  Tab,
  Tabs,
  Typography,
  Box,
  LinearProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import PersonIcon from "@mui/icons-material/Person";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import SecurityTab from "./Tabs/securityTab";
import BillingTab from "./Tabs/BillingTab";
import NotificationTab from "./Tabs/NotificationTab";
import AccountTab from "./Tabs/accountTab";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppSelector } from "store/store";
import { route_info } from "navigation/route";

interface Props {
  onForwardButtonClick?(): void;
  onBackwordButtonClick?(): void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
    innerHeight: "48px",
  };
}

export default function AccountSettings() {
  const [value, setValue] = useState(0);
  const [tabPanelArr, setTabPanelArr] = useState<any[]>([]);
  const [tabArray, setTabArray] = useState<any[]>([]);
  const location = useLocation();
  const navigate: any = useNavigate();
  const { screenLoading } = useAppSelector((state) => state.authReducer);

  useEffect(() => {
    if (location.hash && tabArray.includes(location.hash)) {
      const index = tabArray.findIndex((val) => val === location.hash);
      setValue(index);
    } else if (location.hash && !tabArray.includes(location.hash)) {
      navigate(route_info.common.setting);
    }
  }, [location]);

  useEffect(() => {
    const header: any = localStorage.getItem("header");
    const social_account = JSON.parse(header)?.social_account || "";

    if (social_account == 1 || social_account == 2) {
      setTabPanelArr([
        {
          children: <AccountTab />,
          label: "Account",
          icon: <PersonIcon />,
        },
        {
          children: <BillingTab />,
          label: "Your Plan",
          icon: <CreditCardIcon />,
        },
      ]);
      setTabArray(["#account", "#your_plan"]);
    } else {
      setTabPanelArr([
        {
          children: <AccountTab />,
          label: "Account",
          icon: <PersonIcon />,
        },
        {
          children: <SecurityTab />,
          label: "Password",
          icon: <LockOpenIcon />,
        },
        {
          children: <BillingTab />,
          label: "Your Plan",
          icon: <CreditCardIcon />,
        },
      ]);
      setTabArray(["#account", "#password", "#your_plan"]);
    }
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  return (
    <Card>
      <Box sx={{ width: "100%", padding: 0 }}>
        <Box sx={{ padding: 0 }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{
              "& .MuiTabs-indicator": {
                background: "none",
                backgroundImage:
                  "linear-gradient(87.87deg, #FF4D49 0%, #666CFF 53.65%, #26C6F9 100%)",
              },
              "& .MuiButtonBase-root": {
                minHeight: "48px",
              },
              borderBottom: "1px solid rgba(76, 78, 100, 0.12)",
            }}
          >
            {tabPanelArr.map((val, i) => (
              <Tab
                label={val?.label || ""}
                {...a11yProps(i)}
                icon={val?.icon}
                iconPosition="start"
              />
            ))}
            {/* <Tab
              label="Notifications"
              {...a11yProps(3)}
              icon={<NotificationsNoneIcon />}
              iconPosition="start"
            /> */}
          </Tabs>
          <Box sx={{ width: "100%" }}>
            {screenLoading && (
              <LinearProgress
                className="gradient-linear-progress"
                sx={{
                  "& .MuiLinearProgress-bar": {
                    backgroundImage:
                      "linear-gradient(87.87deg, #FF4D49 0%, #666CFF 53.65%, #26C6F9 100%)",
                  },
                }}
              />
            )}
          </Box>
        </Box>

        <Box>
          {tabPanelArr.map((val, i) => (
            <TabPanel value={value} index={i}>
              {val?.children}
            </TabPanel>
          ))}
          {/* <TabPanel value={value} index={1}>
            <SecurityTab />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <BillingTab />
          </TabPanel> */}
          {/* <TabPanel value={value} index={3}>
            <NotificationTab />
          </TabPanel> */}
        </Box>
      </Box>
    </Card>
  );
}
