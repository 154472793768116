// ** React Imports
import { useState, useEffect } from "react";

// ** MUI Imports
import { Box, Grid, Typography, Divider, Grow } from "@mui/material";

// ** Custom Components
import {
  contentPublishType,
  platform,
  dimension,
} from "@core/constants/global-constants";
import { MARKET_PLACE } from "@core/constants/title-constants";
import CustomRadioButton from "components/CustomRadioButton";
import FooterButton from "components/FooterButton/footerButtton";

interface Props {
  onForwardButtonClick?(): void;
  onClearDraftButtonClick?(): void;
  state?: any;
  setState?: any;
}

export default function PlatformStep({
  onForwardButtonClick,
  onClearDraftButtonClick,
  state,
  setState,
}: Props) {
  let placement = state?.placement;
  const [isDisable, setIsDisable] = useState(true);

  useEffect(() => {
    if (
      (placement?.publishContentType === "collect_video_only" &&
        placement?.hasOwnProperty("publishContentDimensions") &&
        placement?.publishContentDimensions !== null) ||
      (placement?.publishContentType === "collect_video_and_post" &&
        placement?.hasOwnProperty("publishContentPlatform") &&
        placement?.publishContentPlatform !== null)
    ) {
      setIsDisable(false);
    }
  }, [placement]);

  const handleChangeRadio = (name: any, value: any) => {
    setIsDisable(true);
    setState({
      ...state,
      placement: {
        ...state.placement,
        [name]: value,
      },
    });
  };

  const getTitle = (value: any) => {
    if (value === "collect_video_and_post") {
      return (
        <Box>
          <Typography variant="h5" sx={{ mb: 2 }}>
            {MARKET_PLACE.REQUEST_CONTENT.COLLECT_VIDEO_AND_POST_TITLE}
          </Typography>
          <Typography variant="subtitle2" sx={{ mb: 2 }}>
            {MARKET_PLACE.REQUEST_CONTENT.COLLECT_VIDEO_AND_POST_SUBTITLE}
          </Typography>
        </Box>
      );
    } else if (value === "collect_video_only") {
      return (
        <Box>
          <Typography variant="h5" sx={{ mb: "20px" }}>
            {MARKET_PLACE.REQUEST_CONTENT.COLLECT_VIDEO_ONLY_TITLE}
          </Typography>
          <Typography variant="subtitle2" sx={{ mb: 2 }}>
            {MARKET_PLACE.REQUEST_CONTENT.COLLECT_VIDEO_ONLY_SUBTITLE}
          </Typography>
        </Box>
      );
    } else {
      return null;
    }
  };

  const getRadioForm = (value: any) => {
    return (
      <Box
        className="borderRadiusSpecific"
        sx={{
          borderRadius: "10px",
          borderTopLeftRadius: placement?.publishContentType ? "10px" : 0,
          borderTopRightRadius: placement?.publishContentType ? "10px" : 0,
          boxShadow: "0px 2px 10px rgba(76, 78, 100, 0.22)",
        }}
      >
        {value === "collect_video_and_post" ? (
          <Grow in={true}>
            <Grid container className="collect-video-post">
              {platform &&
                platform.map((item, index) => (
                  <Grid
                    item
                    key={index}
                    sx={{
                      display: "flex",
                      flexBasis: "200px",
                    }}
                  >
                    <CustomRadioButton
                      name="publishContentPlatform"
                      radioBtnDetail={item}
                      selectedValue={placement?.publishContentPlatform || ""}
                      handleOnChange={handleChangeRadio}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grow>
        ) : value === "collect_video_only" ? (
          <Grow in={true}>
            <Grid container>
              {dimension &&
                dimension.map((item, index) => (
                  <Grid
                    item
                    key={index}
                    sx={{
                      display: "flex",
                      flexBasis: "160px",
                    }}
                  >
                    <CustomRadioButton
                      name="publishContentDimensions"
                      radioBtnDetail={item}
                      selectedValue={placement?.publishContentDimensions || ""}
                      handleOnChange={handleChangeRadio}
                    />
                  </Grid>
                ))}
            </Grid>
          </Grow>
        ) : null}

        <Box sx={{ pb: 7, pt: 5 }}>
          <Divider sx={{ mb: "30px" }} />
          <FooterButton
            state={state}
            forwardTitle="Requirements"
            isDisabled={isDisable}
            handleNextSliderClick={onForwardButtonClick}
            handleClearDraftButtonClick={onClearDraftButtonClick}
          />
        </Box>
      </Box>
    );
  };

  return (
    <Box className="platform-steps-block">
      <Box sx={{ mb: placement?.publishContentType ? "40px" : 0 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {MARKET_PLACE.REQUEST_CONTENT.PLACEMENT_TITLE}
        </Typography>
        <Typography variant="subtitle2" sx={{ mb: "20px" }}>
          {MARKET_PLACE.REQUEST_CONTENT.PLACEMENT_SUBTITLE}
        </Typography>
        <Grow in={true}>
          <Grid
            container
            className="borderRadiusSpecific"
            sx={{
              borderRadius: platform || dimension ? "10px" : 0,
              borderBottomLeftRadius: !placement?.publishContentType
                ? 0
                : "10px",
              borderBottomRightRadius: !placement?.publishContentType
                ? 0
                : "10px",
              pb: "30px",
              boxShadow: "0px 2px 10px rgba(76, 78, 100, 0.22)",
            }}
          >
            {contentPublishType &&
              contentPublishType.map((item, index) => (
                <Grid
                  // className="collect-video-post"
                  key={index}
                  item
                  sx={{
                    display: "flex",
                    flexBasis: { md: "290px", sm: "290px" },
                  }}
                >
                  <CustomRadioButton
                    name="publishContentType"
                    radioBtnDetail={item}
                    selectedValue={placement?.publishContentType || ""}
                    handleOnChange={handleChangeRadio}
                  />
                </Grid>
              ))}
          </Grid>
        </Grow>
      </Box>
      {getTitle(placement?.publishContentType || "")}

      <form>{getRadioForm(placement?.publishContentType || "")}</form>
    </Box>
  );
}
