// ** MUI Imports
import { Box, Button, Card, Grid, Typography } from "@mui/material";

// ** Custom Components
import UserTable from "pages/user_permission/user-table";

// ** Assets
import { Stacked } from "assets";

export default function UserAndPermission() {
  return (
    <Box>
      <Typography variant="h5">Users & Permissions</Typography>
      <Typography variant="subtitle2">
        Add users and create roles that have certain permissions
      </Typography>
      <Box sx={{ mt: 5 }}>
        <Grid container spacing={5}>
          <Grid item xs={12} md={4} sm={6}>
            <Card sx={{ padding: 5 }}>
              <Box className="sync-justify-between">
                <Typography variant="subtitle2">Total 4 users</Typography>
                <Box component="img" src={Stacked} />
              </Box>
              <Typography
                variant="h6"
                sx={{ mt: 5, color: "rgba(76, 78, 100, 0.68)" }}
              >
                Admin
              </Typography>
              <Button variant="text" sx={{ padding: "0px", mt: 2 }}>
                View permissions
              </Button>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} sm={6}>
            <Card sx={{ padding: 5 }}>
              <Box className="sync-justify-between">
                <Typography variant="subtitle2">Total 4 users</Typography>
                <Box component="img" src={Stacked} />
              </Box>
              <Typography
                variant="h6"
                sx={{ mt: 5, color: "rgba(76, 78, 100, 0.68)" }}
              >
                Campaign manager
              </Typography>
              <Button variant="text" sx={{ padding: "0px", mt: 2 }}>
                View permissions
              </Button>
            </Card>
          </Grid>
          <Grid item xs={12} md={4} sm={6}>
            <Card sx={{ padding: 5 }}>
              <Box className="sync-justify-between">
                <Typography variant="subtitle2">Total 4 users</Typography>
                <Box component="img" src={Stacked} />
              </Box>
              <Typography
                variant="h6"
                sx={{ mt: 5, color: "rgba(76, 78, 100, 0.68)" }}
              >
                Scheduler
              </Typography>
              <Button variant="text" sx={{ padding: "0px", mt: 2 }}>
                View permissions
              </Button>
            </Card>
          </Grid>
        </Grid>
      </Box>

      <UserTable />
    </Box>
  );
}
