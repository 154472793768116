// ** Icon imports
import HomeOutline from 'mdi-material-ui/HomeOutline'
import { VerticalNavItemsType,  } from '@core/layouts/types'
import CalendarToday from 'mdi-material-ui/CalendarToday'
import ContentPaste from 'mdi-material-ui/ContentPaste'
import LockOpen from 'mdi-material-ui/LockOpen'
import { PublicOutlined, TuneOutlined } from '@mui/icons-material'
import { Video, VideoBox, Apps, CogOutline } from 'mdi-material-ui';
import { route_info } from 'navigation/route';

const navigation = (module: any): VerticalNavItemsType => {

  const market_planner = [
    {
      sectionTitle: 'Media Planner'
    },
    {
      title: 'Dashboard',
      icon: HomeOutline,
      path: route_info.market_planner.dashboard,
      badgeColor: "error",
    },
    {
      title: 'Media Planner',
      icon: CalendarToday,
      path: route_info.market_planner.auto_scheduler,
    },
    // {
    //   title: "Campaigns",
    //   icon: ContentPaste,
    //   path: route_info.market_planner.campaigns,
    // },
    {
      sectionTitle: 'Integrations'
    },
    {
      title: 'Platforms',
      icon: PublicOutlined,
      path: '/platform'
    },
    // {
    //   sectionTitle: 'General'
    // },
    // {
    //     title: 'Users & Permission',
    //     icon: LockOpen,
    //     path: route_info.market_planner.user_permission,
    //   },
  ];

  const media_place = [
    {
      sectionTitle: "CREATOR MARKETPLACE",
    },
    {
      title: "Create Content",
      icon: Video,
      children: [
        {
          title: "All Jobs",
          path: route_info.market_place.all_job,
        },
        {
          title: "My Submissions",
          path: route_info.market_place.my_submission,
        },
        {
          title: "My Earnings",
          path: route_info.market_place.my_earnings,
        },
        {
          title: "My Creator Info",
          path: route_info.market_place.my_creator_info,
        },
      ],
    },
    {
      title: "Request Content",
      icon: VideoBox,
      children: [
        {
          title: "Create New Job",
          path: route_info.market_place.create_new_job,
        },
        {
          title: "My Jobs",
          path: route_info.market_place.my_brand_jobs,
        },
        {
          title: "Submissions",
          path: route_info.market_place.submissions,
        },
        {
          title: "My Brand Profile",
          path: route_info.market_place.my_brand_profile,
        },
      ],
    },
    {
      sectionTitle: "INTEGRATIONS",
    },
  ];

  const common = [
    {
      sectionTitle: "GENERAL",
    },
    {
      title: 'Settings',
      icon: CogOutline,
      path: route_info.common.setting,
      badgeColor: "error",
    },
  ]

  const default_menu = [{
    title: "Apps",
    icon: Apps,
    path: route_info.common.overview,
  }
  ]

  if (module && module === "market_planner") {
    return [...default_menu, ...market_planner];
  } else if (module && module === "market_place") {
    return [...default_menu, ...media_place];
  } else {
    return [...default_menu, ...common];
  }

};

export default navigation
