// ** React Imports
import { useState, Fragment, useEffect } from "react";

// ** MUI Imports
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

// ** Third Party Imports
import { useNavigate } from "react-router-dom";
import moment from "moment";

// ** Custom Component
import { route_info } from "navigation/route";
import { POST_TYPE } from "@core/constants/enum";
import { MARKET_PLACE } from "@core/constants/title-constants";

interface Props {
  earningPayoutData?: any;
}

const EarningNestedTable = ({ earningPayoutData }: Props) => {
  const navigate = useNavigate();
  const [tableData, setTableData] = useState([]);
  const redirect_url = route_info.market_place.job_detail;

  useEffect(() => {
    var tableArray: any = [];

    earningPayoutData?.map((item: any, index: number) => {
      tableArray.push({
        job: item?.job,
        brand: item?.job?.brand,
        date: moment(item?.created_at).format("DD/MM/YYYY"),
        type_job:
          item?.publish_content_type === "collect_video_only"
            ? POST_TYPE.VIDEO_ONLY
            : POST_TYPE.VIDEO_AND_POST,
        description: item?.milestone?.milestone_number,
        amount: item?.amount,
      });
    });

    setTableData(tableArray);
  }, [earningPayoutData]);

  const Row = (row: any, index: number) => {
    return (
      <Fragment>
        <TableRow key={index} sx={{ cursor: "default" }}>
          <TableCell sx={{ borderBottom: 0 }}>
            <Typography
              color="#666CFF"
              variant="subtitle2"
              sx={{ fontWeight: "bold", cursor: "pointer", width: 180 }}
              onClick={() => {
                navigate(`${redirect_url}/${row?.job?.id}`);
              }}
            >
              {row?.job?.title || "-"}
            </Typography>
          </TableCell>
          <TableCell sx={{ width: 650, borderBottom: 0 }}>
            {row?.brand?.brand_name || "-"}
          </TableCell>
          <TableCell sx={{ width: 500, borderBottom: 0 }}>
            {row?.date || "-"}
          </TableCell>
          <TableCell sx={{ width: 800, borderBottom: 0 }}>
            {row?.type_job || "-"}
          </TableCell>
          <TableCell sx={{ width: 1000, borderBottom: 0 }}>
            {row?.description || "-"}
          </TableCell>
          <TableCell sx={{ width: 1000, borderBottom: 0 }}>
            {row?.amount || "-"}
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>{MARKET_PLACE.CREATE_CONTENT.JOB}</TableCell>
              <TableCell>{MARKET_PLACE.CREATE_CONTENT.BRAND}</TableCell>
              <TableCell>{MARKET_PLACE.CREATE_CONTENT.DATE_EARNED}</TableCell>
              <TableCell>{MARKET_PLACE.CREATE_CONTENT.TYPE_JOB}</TableCell>
              <TableCell>{MARKET_PLACE.CREATE_CONTENT.DESCRIPTION}</TableCell>
              <TableCell>
                {MARKET_PLACE.CREATE_CONTENT.EARNED.toUpperCase()}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tableData &&
              tableData?.map((row: any, index: any) => Row(row, index))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default EarningNestedTable;
