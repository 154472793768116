// ** React Imports
import React from "react";

// ** MUI Imports
import { Box, Typography, FormControl, TextField, Button } from "@mui/material";
import { LoadingButton } from "@mui/lab";

// ** Redux
import { useAppDispatch } from "store/store";
import { postLoginWithLinkfireGenerator } from "store/Thunk/platformConnectionThunk";

// ** Custom Components
import CustomChip from "@core/components/mui/chip";

// ** Helper
import Validations from "helper/validations";

interface State {
  client_secret?: string;
}

interface Props {
  setDialogOpen?: any;
  onSpotifyLogin?: any;
  setSidebarDrawer?: any;
}

interface FormValidation {
  client_secret?: string;
}

export default function LinkFireGeneratorLoginDialog({
  setDialogOpen,
  onSpotifyLogin,
  setSidebarDrawer,
}: Props) {
  const dispatch = useAppDispatch();
  const [values, setValues] = React.useState({
    client_secret: "",
  });
  const [formError, setFormError] = React.useState<FormValidation>();
  const [loginLoading, setLoginLoading] = React.useState<boolean>(false);

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    let _data: any = {
      client_secret: values.client_secret.trim(),
    };

    let allError = Validations.validateLinkfireGeneratorLoginForm(_data);

    if (
      Object.entries(allError).length === 0 &&
      allError.constructor === Object
    ) {
      setLoginLoading(true);
      dispatch(postLoginWithLinkfireGenerator({ _data })).then((res: any) => {
        setLoginLoading(false);
        if (res.payload.status == 200) {
          setDialogOpen(false);
          if (
            res.payload.status == 200 &&
            res?.payload?.data?.data[0]?.is_multiple_board
          ) {
            setDialogOpen({ dialog: "Linkfire Board", open: true });
          } else {
            onSpotifyLogin();
            setSidebarDrawer({
              open: true,
              drawer: "Linkfire Generator",
              title: "Linkfire Generator",
            });
          }
          setFormError({});
        } else {
          setFormError({
            client_secret: res?.payload?.response?.data?.message,
          });
        }
      });
    } else {
      setFormError(allError);
    }
  };

  return (
    <Box>
      <Typography variant="h5" sx={{ fontWeight: 600 }}>
        Connect Linkfire Generator
      </Typography>
      <Typography variant="subtitle2" sx={{ fontWeight: 400, mt: 3 }}>
        Enter your Linkfire API key to automatically create Linkfire links for
        your ad campaigns. Make sure you have contacted Linkfire first to
        whitelist the following IP:
      </Typography>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <CustomChip
          size="small"
          skin="light"
          color={"warning"}
          label={"123.45.53.233"}
          sx={{ "& .MuiChip-label": { textTransform: "capitalize" }, mt: 4 }}
        />
        <FormControl fullWidth sx={{ mb: 4, mt: 5 }}>
          <TextField
            fullWidth
            id="client_secret"
            sx={{ mb: 4 }}
            placeholder="Linkfire API key"
            label="Linkfire API key"
            onChange={handleChange("client_secret")}
            error={formError && formError.client_secret ? true : false}
            helperText={formError && formError.client_secret}
          />
        </FormControl>
        <Box sx={{ display: "flex" }}>
          <Button
            fullWidth
            size="large"
            variant="outlined"
            sx={{ mb: 4, mr: 2 }}
            onClick={() => setDialogOpen({ open: false, dialog: "" })}
          >
            Cancel
          </Button>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            loading={loginLoading}
            variant="contained"
            sx={{ mb: 4, ml: 2 }}
          >
            CONNECT
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
}
