// ** React Imports
import { useState, useCallback, useEffect } from "react";

// ** MUI Imports
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  Grid,
  Typography,
  Grow,
} from "@mui/material";

// ** Third Party Library
import { useNavigate } from "react-router-dom";

// ** CleaveJS Imports
import "cleave.js/dist/addons/cleave-phone.us";

// ** Redux
import { useAppSelector, useAppDispatch } from "store/store";
import { createNewJob, getBrandInfo } from "store/Thunk/createJobThunk";
import { notificationFail } from "store/Slice/notificationSlice";
import { setPaymentDetails } from "store/Slice/CreateJobSlice";

// ** Custom Components
import { MARKET_PLACE } from "@core/constants/title-constants";
import FooterButton from "components/FooterButton/footerButtton";
import ReactPayPal from "components/Paypal/ReactPaypal";
import CompletedDialog from "pages/marketplace/request-content/create-job/CompletedDialog/CompletedDialog";

interface Props {
  state: any;
  onForwardButtonClick?(): void;
  onBackwardButtonClick(): void;
  onClearDraftButtonClick(): void;
  handledStartButtonClick(): void;
}

export default function BillingDetails({
  state,
  onBackwardButtonClick,
  onClearDraftButtonClick,
  handledStartButtonClick,
}: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  let jobBrandProfile: any = useAppSelector(
    (state: any) => state.createJobReducer.jobBrandDetail
  );
  const paymentDetails: any = useAppSelector(
    (state: any) => state?.createJobReducer?.paymentDetails
  );

  useEffect(() => {
    dispatch(getBrandInfo(dispatch));
  }, []);

  const [paymentway, setPaymentway] = useState<String>("");

  // const [cardDetail, setCardDetail] = useState<any>({
  //   cardNumber: null,
  //   cardExpDate: null,
  //   cardCVV: null,
  //   billingAddress: null,
  //   cardAddition: null,
  //   cardCity: null,
  //   cardPostalCode: null,
  //   cardCountry: null,
  //   paymentway: null,
  //   saveBillingDetails: false,
  // });

  const [jobCreatedDialog, setJobCreatedDialog] = useState(false);
  const [linkCopy, setLinkCopy] = useState(false);
  const [isCreateJob, setIsCreateJob] = useState(false);
  const [totalPayment, setTotalPayment] = useState<any>(10);
  const [orderID, setOrderID] = useState(false);

  useEffect(() => {
    localStorage.removeItem("payment_id");
    localStorage.removeItem("payment_status");
  }, []);

  const paymentDetailsHandle = (order: any) => {
    let temp = {
      ...paymentDetails,
      payment_type: order,
    };
    dispatch(setPaymentDetails(temp));
    localStorage.setItem("paymentDetails", temp?.payment_type);
  };

  const paymentDetailsHandleNew = (order: any) => {
    let temp = {
      ...paymentDetails,
      payment_id: order?.payment_id,
      payment_status: order?.payment_status,
    };
    dispatch(setPaymentDetails(temp));
    localStorage.setItem("payment_status", temp?.payment_status);
    localStorage.setItem("payment_id", temp?.payment_id);
  };

  const onLinkCopy = useCallback(() => {
    navigator.clipboard.writeText(
      `https://marketplace.fruitsagency.com/all-jobs/job-detail`
    );
    setLinkCopy(true);
  }, []);

  const onClickFinish = useCallback(() => {
    setJobCreatedDialog(!jobCreatedDialog);
    handledStartButtonClick();
    navigate("/my-brand-jobs");
  }, []);

  const handleCreateJobBtn = useCallback(() => {
    if (paymentway === "credit-card") {
      return true;
    } else {
      let payment_id: any = localStorage.getItem("payment_id");
      let payment_status: any = localStorage.getItem("payment_status");
      let payment_type: any = localStorage.getItem("paymentDetails");
      let formData = new FormData();

      setIsCreateJob(true);
      formData.append("brand", jobBrandProfile && jobBrandProfile[0].id);
      formData.append(
        "publish_content_type",
        state.placement.publishContentType
      );
      if (state.placement.publishContentType === "collect_video_only")
        formData.append("dimension", state.placement.publishContentDimensions);
      else formData.append("platforms", state.placement.publishContentPlatform);
      formData.append("title", state.jobDetails.title);
      formData.append("job_description", state.jobDetails.description);
      formData.append(
        "thumbnails",
        state.jobDetails.thumbnail || state?.thumbnail_url
      );
      formData.append("budget", state.jobDetails.budget);
      formData.append("selected_budget", state.selectedBudget);
      formData.append("category", state.jobDetails.category);
      formData.append("job_requirements", JSON.stringify(state.requirements));
      formData.append("payment_status", payment_status);
      formData.append("payment_id", payment_id);
      formData.append("payment_type", payment_type);
      dispatch(createNewJob({ formData }))
        .then((response: any) => {
          setIsCreateJob(false);
          if (!response.error) {
            setJobCreatedDialog(!jobCreatedDialog);
          } else {
            dispatch(notificationFail(response?.message));
          }
        })
        .catch((err: any) => {
          setIsCreateJob(false);
          setJobCreatedDialog(false);
          dispatch(notificationFail(err?.message));
        });
    }
  }, []);

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {MARKET_PLACE.REQUEST_CONTENT.BILLING_DETAILS}
      </Typography>
      <Typography variant="subtitle2" sx={{ mb: 4 }}>
        {MARKET_PLACE.REQUEST_CONTENT.BILLING_DETAILS_SUBTITLE}
      </Typography>
      <Grow in={true}>
        <Box className="borderRadiusSpecific billing-details">
          <Grid container className="job-requirement">
            <Grid item xs={12} md={4}>
              <Box className="total-payment">
                <Typography variant="body1" sx={{ color: "#636578" }}>
                  {MARKET_PLACE.REQUEST_CONTENT.TOTAL_PAYMENT}
                </Typography>

                <Typography variant="h6" sx={{ color: "#636578" }}>
                  $ {totalPayment}
                </Typography>
              </Box>

              <Box className="box-payment-option">
                <ReactPayPal
                  totalPayment={totalPayment}
                  setOrderID={setOrderID}
                  paymentDetailsHandle={paymentDetailsHandle}
                  paymentDetailsHandleNew={paymentDetailsHandleNew}
                />
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ mt: 8 }}>
            <Divider sx={{ my: "30px" }} />
            <FooterButton
              state={state}
              isDisabled={paymentway || !isCreateJob ? false : true}
              backwardTitle="Budget divider"
              forwardTitle="Create job"
              handledBackwardButtonClick={onBackwardButtonClick}
              handleNextSliderClick={handleCreateJobBtn}
              handleClearDraftButtonClick={onClearDraftButtonClick}
            />
          </Box>
        </Box>
      </Grow>
      <Dialog
        fullWidth
        open={jobCreatedDialog}
        scroll="body"
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "450px",
            },
          },
        }}
      >
        <DialogContent>
          <CompletedDialog
            setJobCreatedDialog={setJobCreatedDialog}
            onLinkCopy={onLinkCopy}
            setLinkCopy={setLinkCopy}
            onClickFinish={onClickFinish}
            linkCopy={linkCopy}
          />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
