import { ProtectedRouteProps } from "common/interface/interface";
import { Navigate } from "react-router-dom";



const ProtectedRoute = ({
    children,
  }: ProtectedRouteProps) => {
    
    const isAuthenticated = localStorage.getItem("userData");
    
    if (!isAuthenticated) {
      return <Navigate to={"/"} replace />;
    }
  
    return children;
  };


export default ProtectedRoute;