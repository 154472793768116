import { useEffect, useState } from "react";
import {
  Box,
  Typography,
  useTheme,
  Chip,
  Alert,
  AlertTitle,
  Grow,
} from "@mui/material";
import BoltIcon from "@mui/icons-material/Bolt";
// import { STATUS as TITLE } from "@core/constants/title-constants";
import { STATUS, TITLE } from "@core/constants/enum";
import { useAppDispatch } from "store/store";
import { getVideoOtp } from "store/Thunk/createJobThunk";
import SubmissionStatusCard from "./SubmissionStatusCard";
import StatusIcon from "components/StatusIcon";
import VideoModal from "components/Dialogs/VideoModal";
import { Avatar, VideoPlay, Approve, Decline } from "assets";

export default function SubmissionCard(props: any) {
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const { cardData, isHistory, handleFeedBackClick, handleAccept } = props;

  const [openVideoModal, setOpenVideoModal] = useState<boolean>(false);

  useEffect(() => {
    let formData = new FormData();

    formData.append("video_id", cardData?.video_id);
    dispatch(getVideoOtp({ formData }));
  }, []);

  const handleVideoPlay = () => {
    setOpenVideoModal(!openVideoModal);
  };

  const handleClose = () => {
    setOpenVideoModal(false);
  };

  const handleStatusClick = (statusTitle: string) => {
    if (statusTitle.toLowerCase() === "decline") {
      handleFeedBackClick(cardData);
    } else {
      handleAccept(statusTitle, cardData);
    }
  };

  const handleClick = () => {
    window.open(cardData.social_post_link, "_blank", "noreferrer");
  };
  return (
    <Grow in={true}>
      <Box className="list-card-block">
        <Box height={300}>
          <Box
            component="img"
            src={
              cardData?.vdo_payload &&
              cardData?.vdo_payload?.posters?.length > 0 &&
              cardData?.vdo_payload.posters[0].url
            }
            sx={{ objectFit: "cover", height: "100%", width: "100%" }}
          />
        </Box>
        <Box className="submission-image-wrapper">
          <Box sx={{ p: "10px" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                mb: "4px",
              }}
            >
              <Box component="img" src={Avatar} height="24px" width="24px" />
              <Typography
                variant="body2"
                color={theme.palette.customColors.white}
                ml="10px"
              >
                {"@" + cardData.creator.display_name}
              </Typography>
              {isHistory ? (
                <Chip
                  icon={<BoltIcon fontSize="small" />}
                  label="NEW"
                  color="primary"
                  size="small"
                />
              ) : null}
            </Box>
            <Typography
              sx={{ fontSize: "14px", fontWeight: "700" }}
              color={theme.palette.customColors.white}
            >
              {cardData.brand.brand_name}
            </Typography>
          </Box>
          <Box
            onClick={handleVideoPlay}
            component="img"
            src={VideoPlay}
            height="30px"
            width="30px"
            alignSelf="center"
          />
          {cardData.approval_status[0].approval_status ===
          STATUS.APPROVAL_NEEDED ? (
            <Box
              sx={{
                m: "6px",
                p: "4px",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                borderRadius: "26px",
                backgroundColor: "rgba(0, 0, 0, 0.6)",
              }}
            >
              <StatusIcon
                statusTitle="Decline"
                statusIcon={Decline}
                handleStatusClick={handleStatusClick}
              />
              <StatusIcon
                statusTitle="Approve"
                statusIcon={Approve}
                handleStatusClick={() => handleStatusClick("approved")}
              />
            </Box>
          ) : cardData.approval_status[0].approval_status ===
            STATUS.CREATOR_POST_PENDING ? (
            <SubmissionStatusCard
              status={STATUS.CREATOR_POST_PENDING}
              title={TITLE.CREATOR_POST_PENDING}
            />
          ) : cardData.approval_status[0].approval_status ===
            STATUS.POST_CONFIRMATION_PENDING ? (
            <Box>
              <Box sx={{ cursor: "pointer" }}>
                <Box onClick={() => handleClick()}>
                  <Alert
                    severity="info"
                    sx={{
                      mx: "6px",
                      padding: "0px 6px",
                      color: "rgba(38, 198, 249, 1)",
                      backgroundColor: "rgba(255, 255, 255, 0.88)",
                    }}
                  >
                    <AlertTitle>Submission posted</AlertTitle>
                    <span style={{ color: "rgba(38, 198, 249, 1)" }}>
                      Click here to view
                    </span>
                  </Alert>
                </Box>
                <Box
                  sx={{
                    m: "6px",
                    p: "4px",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    borderRadius: "26px",
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                  }}
                >
                  <StatusIcon
                    statusTitle="Disprove"
                    statusIcon={Decline}
                    handleStatusClick={() => handleStatusClick("decline")}
                  />
                  <StatusIcon
                    statusTitle="Confirm"
                    statusIcon={Approve}
                    handleStatusClick={() => handleStatusClick("accepted")}
                  />
                </Box>
                {/* <Box sx={{ display: "flex", flexDirection: "row" }}>
                <Box
                  onClick={() => handleStatusClick("decline")}
                  className="submission-status-block"
                >
                  <Box className="status-img" component="img" src={Decline} />
                  <Typography
                    variant="body2"
                    color={theme.palette.customColors.white}
                  >
                    Disprove
                  </Typography>
                </Box>
                <Box
                  onClick={() => handleStatusClick("accepted")}
                  className="submission-status-block"
                >
                  <Box className="status-img" component="img" src={Approve} />
                  <Typography
                    variant="body2"
                    color={theme.palette.customColors.white}
                  >
                    Confirm
                  </Typography>
                </Box>
              </Box> */}
              </Box>
            </Box>
          ) : null}
        </Box>
        <VideoModal isOpen={openVideoModal} handleClose={handleClose} />
      </Box>
    </Grow>
  );
}
