import EastIcon from "@mui/icons-material/East";
import WestIcon from "@mui/icons-material/West";
import { TITLE } from "@core/constants/enum";
import {
  VideoAndPost,
  VideoLibrary,
  Vertical,
  Horizontal,
  Square,
  Tiktok,
  Instagram,
} from "assets";

export const tabTitle = [
  {
    value: "0",
    label: "All",
  },
  {
    value: "1",
    label: "Art",
  },
  {
    value: "2",
    label: "Beauty",
  },
  {
    value: "3",
    label: "Fashion",
  },
  {
    value: "4",
    label: "Fitness",
  },
  {
    value: "5",
    label: "Food",
  },
  {
    value: "6",
    label: "Music",
  },
];

export const tabsContent = { value: "", component: <></> };

export let tabs = {
  tabsTitle: tabTitle,
  tabsContent: tabsContent,
};

export const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    slidesToSlide: 1,
  },
};

export const CustomRightArrow = ({ onClick }: any) => {
  return (
    <button className="carousel-arrow right" onClick={onClick}>
      <EastIcon fontSize="small" htmlColor="#fff" />
    </button>
  );
};

export const CustomLeftArrow = ({ onClick }: any) => {
  return (
    <button className="carousel-arrow left" onClick={onClick}>
      <WestIcon fontSize="small" htmlColor="#fff" />
    </button>
  );
};

export const steps = [
  "Video Approved",
  "25K views",
  "50K views",
  "75K views",
  "100K views",
];

export const historyTabTitle = [
  {
    value: "0",
    label: TITLE.APPROVED,
    name: "accepted",
  },
  {
    value: "1",
    label: TITLE.DECLINED,
    name: "declined",
  },
];

export const contentPublishType = [
  {
    id: 1,
    name: "Collect video and let creators post on their social feed ",
    value: "collect_video_and_post",
    img: VideoAndPost,
  },
  {
    id: 1,
    name: "Collect videos only ",
    value: "collect_video_only",
    img: VideoLibrary,
  },
];

export const platform = [
  {
    id: 1,
    name: "TikTok",
    value: "TikTok",
    img: Tiktok,
  },
  {
    id: 2,
    name: "Instagram",
    value: "Instagram",
    img: Instagram,
  },
  // {
  //   id: 3,
  //   name: "Snapchat",
  //   value: "Snapchat",
  //   img: Snapchat,
  // },
];

export const dimension = [
  {
    id: 1,
    name: "Vertical",
    value: "Vertical",
    img: Vertical,
  },
  {
    id: 2,
    name: "Horizontal",
    value: "Horizontal",
    img: Horizontal,
  },
  {
    id: 3,
    name: "Square",
    value: "Square",
    img: Square,
  },
];

export const video_post_marks = [
  {
    value: 50,
    label: "$50",
  },
  {
    value: 100,
    label: "$100",
  },
  {
    value: 200,
    label: "$200",
  },
];

export const video_only_marks = [
  {
    value: 10,
    label: "$10",
  },
  {
    value: 25,
    label: "$25",
  },
  {
    value: 40,
    label: "$40",
  },
];

export const submissionTabTitle = [
  {
    value: "0",
    label: TITLE.ALL,
    name: "all",
  },
  {
    value: "1",
    label: TITLE.VIDEO_APPROVAL_NEEDED,
    name: "approval_needed",
  },
  {
    value: "2",
    label: TITLE.POST_CONFIRMATION_NEEDED,
    name: "post_confirmation_pending",
  },
  {
    value: "3",
    label: TITLE.CREATOR_POST_PENDING,
    name: "creator_post_pending",
  },
];

export const mySubmissionTabTitle = [
  {
    value: "0",
    label: TITLE.ALL,
  },
  {
    value: "1",
    label: TITLE.EARNING,
  },
  {
    value: "2",
    label: TITLE.ACTION_REQUIRED,
  },
  {
    value: "3",
    label: TITLE.PENDING,
  },
  {
    value: "4",
    label: TITLE.DECLINED,
  },
];

export const mySubmissionVideoOnlyTabTitle = [
  {
    value: "0",
    label: TITLE.ALL,
  },
  {
    value: "1",
    label: TITLE.PENDING,
  },
  {
    value: "1",
    label: TITLE.DECLINED,
  },
];

export const placements = [
  { name: "Feed", value: "Facebook_Feed" },
  { name: "Facebook Reels", value: "Facebook_Reels" },
  { name: "Story", value: "Facebook_Story" },
  { name: "Stream", value: "Instagram_Stream" },
  { name: "Story", value: "Instagram_Story" },
  { name: "Reels", value: "Instagram_Reels" },
];

export const age = [
  {
    id: 1,
    name: "13-17",
  },
  {
    id: 2,
    name: "18-24",
  },
  {
    id: 3,
    name: "25-34",
  },
  {
    id: 4,
    name: "35-44",
  },
  {
    id: 5,
    name: "45-54",
  },
  {
    id: 6,
    name: "55+",
  },
];

export const snapChatAge = [
  {
    id: 1,
    name: "13-17",
  },
  {
    id: 2,
    name: "18-24",
  },
  {
    id: 3,
    name: "25-34",
  },
  {
    id: 4,
    name: "35-44",
  },
  {
    id: 5,
    name: "45+",
  },
];

export const profileName = (item: any) => {
  var f_name = item?.first_name ? item?.first_name + " " : "";
  var l_name = item?.last_name ? item?.last_name : "";
  var name = f_name + l_name;
  return name;
};

export const tooltip =
  "When Advantage+ placement is ON, Meta locates your budget automatically towards placements that will perform the best. These placements include Instagram, Facebook, Messenger and Audience Network.";

export const handledOptimizeEvent = (value: any) => {
  switch (value) {
    case "PURCHASE":
      return "Purchase";
    case "ADD_TO_CART":
      return "Add to cart";
    case "INITIATE_CHECKOUT":
      return "Initiated Checkout";
    case "VIEW_CONTENT":
      return "Content View";
    case "ADD_PAYMENT_INFO":
      return "Add Payment Info";
    case "IMPRESSIONS":
      return "Impressions";
    case "LEAD":
      return "Lead";
    case "PIXEL_PURCHASE":
      return "Purchase";
    case "PIXEL_ADD_TO_CART":
      return "Add to cart";
    case "PIXEL_PAGE_VIEW":
      return "Page View";
    case "PIXEL_SIGNUP":
      return "Sign up";
    case "STORY_OPENS":
      return "Story Opens";
    case "IMPRESSIONS":
      return "Impressions";
    case "SWIPES":
      return "Swipes / Clicks";
    case "ON_WEB_ORDER":
      return "Purchase";
    case "ON_WEB_CART":
      return "Add to cart";
    case "INITIATE_ORDER":
      return "Initiated Checkout";
    case "ON_WEB_DETAIL":
      return "Content View";
    case "SHOPPING":
      return "Complete Payment";

    default:
      return value;
  }
};

export const isSpendingValidate = [
  "IMPRESSIONS",
  "USES",
  "SWIPES",
  "VIDEO_VIEWS",
  "VIDEO_VIEWS_15_SEC",
  "STORY_OPENS",
];

export const weekDays = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

export const requirements = [
  "You have at least 1000 followers",
  "Your Instagram is a Creator or Business account",
  "You need to log in with your Facebook account that is linked to your Instagram account",
];

export const stepper = [
  "Placement",
  "Requirements",
  "Job details",
  "Budget divider",
  "Billing details",
];
