import { Box, Button, Typography, styled,  BoxProps } from "@mui/material";
import { melonFloating } from "assets";
import { useNavigate } from "react-router-dom";
import BlankLayout from "@core/layouts/BlankLayout";
import FooterIllustrationsV2 from "components/screenLayout/FooterIllustrationsV2";

const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
    [theme.breakpoints.down('md')]: {
      width: '90vw'
    }
  }))
  
  const Img = styled('img')(({ theme }) => ({
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(15),
    [theme.breakpoints.down('lg')]: {
      height: 450,
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(10)
    },
    [theme.breakpoints.down('md')]: {
      height: 400
    }
  }))

export default function ServerErrorPage() {
    const navigate = useNavigate()
  return (
    <BlankLayout>
    <Box className='content-center'>
      <Box className="sync-align-center" sx={{ p: 5, flexDirection: 'column', textAlign: 'center' }}>
        <BoxWrapper>
          <Typography variant='h1' sx={{ mb: 2.5 }}>
            500
          </Typography>
          <Typography variant='h5' sx={{ mb: 2.5 }}>
          Internal server error 🙈
          </Typography>
          <Typography variant='body2'>This function is not working at the moment. Try again in a bit or</Typography>
          <Typography variant='body2'>float around here forever.</Typography>
        </BoxWrapper>
        <Img alt='error-illustration' src={melonFloating} sx={{height: 300, width: 300}}/>
          <Button component='a' variant='contained' sx={{ px: 5.5 }} onClick={() => navigate('/')}>
            Back to Dashboard
          </Button>
      </Box>
      <FooterIllustrationsV2 />
    </Box>
    </BlankLayout>
  );
}
