import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { NotificationInitialState } from 'store/interface';

const initialState: NotificationInitialState = {
	message: null,
	status: false,
	type: "",
	position: ""
};

const notificationSlice = createSlice({
	name: 'notification',
	initialState: initialState,
	reducers: {
		notificationFail: (
			state: Draft<NotificationInitialState>,
			action: PayloadAction<string>
		) => {
			state.status = false;
			state.message = action.payload;
			state.position = "top";
		},
		notificationSuccess: (
			state: Draft<NotificationInitialState>,
			action: any
			) => {
				state.status = true;
				state.message = action.payload;
				state.position = "top";
		},
		notificationClear: (state: Draft<NotificationInitialState>) => {
			state.message = "";
			state.status = false;
			state.type = "";
		},
		autoschedulerNotificationFail: (
			state: Draft<NotificationInitialState>,
			action: any
		) => {
			state.status = false;
			state.message = action.payload;
			state.position = "bottom";
		},
		autoschedulerNotificationSuccess: (
			state: Draft<NotificationInitialState>,
			action: any
			) => {
				state.status = true;
				state.message = action.payload;
				state.position = "bottom";
		},
	}
});

export const { notificationFail, notificationSuccess, notificationClear, 
	autoschedulerNotificationFail, autoschedulerNotificationSuccess 
} =
	notificationSlice.actions;

export default notificationSlice.reducer;
