// ** React Imports
import { useCallback, useEffect, useState } from "react";

// ** Mui Imports
import {
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  LinearProgress,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

// ** Third Party Library
import Moment from "moment";
import { debounce } from "lodash";

// ** Store
import { useAppDispatch, useAppSelector } from "store/store";
import {
  getSchedulerHistory,
  postRetryScheduleBatch,
} from "store/Thunk/autoSchedulerThunk";
import { getAllScraperGroup } from "store/Thunk/platformConnectionThunk";
import { SchedulerHistoryRowData } from "store/Slice/autoSchedulerSlice";

// ** Custom Component
import DropdownNotFound from "components/DropdownNotFound/DropdownNotFound";
import NestedTableDialog from "../PlatformTableDialog/NestedTableDialog";
import { MEDIA_PLANNER } from "@core/constants/title-constants";
import { BATCH_STATUS } from "@core/constants/enum";

// ** Icons
import CloseIcon from "@mui/icons-material/Close";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import RefreshIcon from "@mui/icons-material/Refresh";
import FacebookIcon from "icon/facebook";
import SnapchatIcon from "icon/snapchat";
import TiktokIcon from "icon/tiktok";
import { jazzFruits } from "assets";

export interface Props {
  setOpen: any;
  setStep: any;
  onReuseCreativesSelect: any;
}

export default function SchedulerHisrotyDrawer({
  setOpen,
  setStep,
  onReuseCreativesSelect,
}: Props) {
  const dispatch: any = useAppDispatch();
  let autoSchedulerTitle = MEDIA_PLANNER.auto_scheduler;

  const { schedulerHistory } = useAppSelector(
    (state: any) => state.autoSchedulerSlice
  );
  const { scraperGroupData } = useAppSelector(
    (state: any) => state.PlatformSlice
  );
  const [searchBatch, setSearchBatch] = useState("");
  const [historyData, setHistoryData] = useState([]);
  const [groupLoading, setGroupLoading] = useState(false);
  const [nestedDialog, setNestedDialog] = useState(false);
  const [historyRowData, setHistoryRowData] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [retryLoading, setRetryLoading] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowPerPage] = useState(7);
  const [group, setGroup] = useState("");
  const [schedulingLoading, setSchedulingLoading] = useState<boolean>(false);
  const [selectedGroup, setSelectedGroup] = useState<string>("");

  useEffect(() => {
    getSchedylerHistoryData("", "", page);
    let data: any = null;
    dispatch(getAllScraperGroup({ data })).then((res: any) => {
      if (res?.payload?.data?.length) {
        setGroupLoading(false);
      } else {
        setGroupLoading(true);
      }
    });
  }, []);

  useEffect(() => {
    if (schedulerHistory?.results) {
      setHistoryData(
        schedulerHistory?.results?.length ? schedulerHistory?.results : []
      );
    }
  }, [schedulerHistory?.results]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      dispatch(getSchedulerHistory({ search: searchBatch, group, page }));
    }, 20000);

    return () => clearInterval(intervalId);
  }, [searchBatch, group, page]);

  const getSchedylerHistoryData = (search: any, group: any, page: number) => {
    setSearchBatch(search);
    setPage(page);
    dispatch(getSchedulerHistory({ search, group, page })).then((res: any) => {
      if (res?.payload?.status == 200) {
        setLoading(false);
        setRetryLoading(false);
        setSchedulingLoading(false);

        const index: any = res?.payload?.data?.results.findIndex(
          (item: any) => item?.id == historyRowData?.id
        );

        if (historyRowData) {
          setHistoryRowData(res?.payload?.data?.results[index]);
        }
      }
    });
  };

  const handleReloadClick = (uploadsesid: any) => {
    setRetryLoading(true);
    const data = {
      uploadsesid: uploadsesid,
    };
    dispatch(postRetryScheduleBatch({ data })).then((res: any) => {
      if (res?.payload?.status == 200) {
        getSchedylerHistoryData(searchBatch, group, page);
      }
    });
  };

  const handleGroupChange = (e: any) => {
    setGroup(e);
    setSelectedGroup(e);
    getSchedylerHistoryData(searchBatch, e, 0);
  };

  const debounceSearch = useCallback(
    debounce((search, group) => {
      getSchedylerHistoryData(search, group, 0);
    }, 500),
    []
  );

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
    getSchedylerHistoryData(searchBatch, group, newPage);
  };

  const handleChangeRowsPerPage = (e: any) => {
    setRowPerPage(parseInt(e.target.value, 7));
    setPage(1);
  };

  function LinearProgressWithLabel(
    props: any & { value: number; uploadsesid: any; error: any }
  ) {
    return (
      <Box>
        <Box className="sync-align-center">
          <Box sx={{ width: "100%", mr: 1 }}>
            <LinearProgress variant="determinate" {...props} />
          </Box>
          <Box sx={{ minWidth: 35 }}>
            {props.status == "Error" ? (
              <Box className="sync-align-center">
                <Tooltip title={props?.error}>
                  <ErrorOutlineIcon color="primary" />
                </Tooltip>
                <IconButton
                  sx={{ padding: 1 }}
                  onClick={() => handleReloadClick(props?.uploadsesid)}
                >
                  <RefreshIcon />
                </IconButton>{" "}
              </Box>
            ) : (
              <Typography variant="body2" color="text.secondary">{`${Math.round(
                props.value
              )}%`}</Typography>
            )}
          </Box>
        </Box>
      </Box>
    );
  }

  const handleDeleteValue = () => {
    setGroup("");
    setSelectedGroup("");
    getSchedylerHistoryData(searchBatch, "", 0);
  };

  const handleStatusUpdateClick = (historyRowData: any) => {
    // setHistoryRowData(historyRowData);
  };

  const handleNestedDialog = () => {
    setNestedDialog(false);
    dispatch(SchedulerHistoryRowData([]));
  };

  const percentageColor = (item: any) => {
    return item?.error?.status == BATCH_STATUS.YES
      ? "success"
      : item?.error?.status == BATCH_STATUS.ERROR
      ? "error"
      : "warning";
  };

  const color = (item: any) => {
    return item?.error?.status == BATCH_STATUS.YES
      ? "success.main"
      : item?.error?.status == BATCH_STATUS.ERROR
      ? "error.main"
      : "warning.main";
  };

  const name = (item: any) => {
    return item?.extra_name?.length > 15
      ? item?.extra_name?.substring(0, 15) + "..."
      : item?.extra_name?.substring(0, 15);
  };

  return (
    <>
      <>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme: any) => theme.zIndex.drawer + 1,
          }}
          open={retryLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </>

      <Box sx={{ height: "100%", flex: 1 }}>
        {loading ? (
          <>
            <Box sx={{ width: 470 }}>
              <LinearProgress />
            </Box>
            <Box
              className="sync-align-justify-center"
              sx={{
                height: "100%",
              }}
            >
              <CircularProgress />
            </Box>
          </>
        ) : (
          <>
            <Box sx={{ height: "100%" }}>
              {/*----------------------------- header ------------------------------------*/}
              <Box
                sx={{
                  margin: 5,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h6"
                  sx={{ color: "rgba(76, 78, 100, 0.87)" }}
                >
                  Schedule history
                </Typography>
                <IconButton onClick={() => setOpen(false)}>
                  <CloseIcon />
                </IconButton>
              </Box>

              <Box sx={{ display: "flex", px: "20px" }}>
                <FormControl fullWidth sx={{ paddingRight: "5px" }}>
                  <InputLabel>Group</InputLabel>
                  <Select
                    variant="outlined"
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    placeholder="Labels..."
                    label="Group"
                    value={selectedGroup}
                    onChange={(e) => handleGroupChange(e.target.value)}
                    endAdornment={
                      <IconButton
                        sx={{ p: 0, background: "transparent", mr: "18px" }}
                        onClick={handleDeleteValue}
                      >
                        <CloseIcon fontSize="small" />
                      </IconButton>
                    }
                  >
                    {scraperGroupData.length == 0 && groupLoading && (
                      <DropdownNotFound name={"group"} />
                    )}
                    {scraperGroupData.length == 0 && !groupLoading && (
                      <Box className="sync-align-justify-center full-height-width">
                        <CircularProgress />
                      </Box>
                    )}

                    {scraperGroupData?.map((item: any) => (
                      <MenuItem value={item?.id} key={item?.id?.toString()}>
                        <Box className="sync-align-center">
                          <Box
                            component="img"
                            src={item?.profile_url}
                            style={{
                              height: "20px",
                              width: "20px",
                              marginRight: "5px",
                              borderRadius: "20px",
                            }}
                            loading="lazy"
                          />
                          <Typography sx={{ fontSize: "14px" }}>
                            {item?.group_name}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ paddingLeft: "5px" }}>
                  <TextField
                    onChange={(e: any) => debounceSearch(e.target.value, group)}
                    variant="outlined"
                    label="Batch"
                    // value={searchBatch}
                    placeholder="Search Batch"
                  />
                </FormControl>
              </Box>

              {/*-------------------------------- table ---------------------------------------*/}

              <TableContainer component={Paper}>
                <Table aria-label="collapsible table" sx={{ width: "100%" }}>
                  <TableHead>
                    <TableRow key="title">
                      <TableCell>{autoSchedulerTitle.AD_BATCH}</TableCell>
                      <TableCell>{autoSchedulerTitle.DATE}</TableCell>
                      <TableCell>{autoSchedulerTitle.PROGRESS}</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ cursor: "pointer" }}>
                    {historyData?.length == 0 ? (
                      <TableRow key="no-record-found">
                        <TableCell colSpan={3}>
                          <Typography
                            sx={{ display: "flex", justifyContent: "center" }}
                          >
                            {autoSchedulerTitle.NO_RECORD_FOUND}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    ) : (
                      historyData?.map((item: any, index: any) => {
                        // percentageColor(item);

                        return (
                          <>
                            <TableRow
                              key={item?.uploadsesid}
                              onClick={() => {
                                setNestedDialog(true);
                                setHistoryRowData(item?.uploadsesid);
                              }}
                            >
                              {/*------------------------ column 1 --------------------------------------------*/}
                              <TableCell
                                component="th"
                                scope="row"
                                sx={{ border: "none" }}
                              >
                                <Box className="sync-align-center">
                                  <Box
                                    sx={{
                                      bgcolor: color(item),
                                      height: "9px",
                                      width: "9px",
                                      borderRadius: "5px",
                                      mr: 3,
                                    }}
                                  ></Box>
                                  <Box sx={{ mr: 2 }}>
                                    <Box
                                      component="img"
                                      style={{ borderRadius: "50%" }}
                                      src={jazzFruits}
                                    />
                                  </Box>
                                  <Box>
                                    <Typography
                                      variant="subtitle2"
                                      sx={{ fontWeight: "600" }}
                                    >
                                      {autoSchedulerTitle.BATCH}
                                    </Typography>
                                    <Typography sx={{ fontSize: "12px" }}>
                                      {name(item)}
                                    </Typography>
                                  </Box>
                                </Box>
                              </TableCell>

                              {/*------------------------ column 2 --------------------------------------------*/}
                              <TableCell align="left" sx={{ border: "none" }}>
                                {/* <Box> */}
                                <Typography sx={{ fontSize: "12px" }}>
                                  {Moment(item?.created_on).format(
                                    "DD MMM HH:mm"
                                  )}
                                </Typography>
                                {/* </Box> */}
                              </TableCell>

                              {/*------------------------ column 3 --------------------------------------------*/}
                              <TableCell
                                align="right"
                                sx={{ border: "none", display: "flex" }}
                              >
                                <>
                                  {item?.platform?.includes("Tiktok") && (
                                    <Box sx={{ mr: 2 }}>
                                      <TiktokIcon
                                        height="20"
                                        width="20"
                                        active={true}
                                      />
                                    </Box>
                                  )}
                                  {item?.platform?.includes("Facebook") && (
                                    <Box sx={{ mr: 2 }}>
                                      <FacebookIcon
                                        height="20"
                                        width="20"
                                        active={true}
                                      />
                                    </Box>
                                  )}
                                  {item?.platform?.includes("Snap") && (
                                    <Box sx={{ mr: 2 }}>
                                      <SnapchatIcon
                                        height="20"
                                        width="20"
                                        active={true}
                                      />
                                    </Box>
                                  )}
                                </>
                              </TableCell>
                            </TableRow>
                            <TableRow
                              sx={{
                                ".MuiTableCell-body:not(.MuiTableCell-sizeSmall):not(.MuiTableCell-paddingCheckbox):not(.MuiTableCell-paddingNone)":
                                  {
                                    paddingTop: "0px",
                                    paddingBottom: "5px",
                                  },
                              }}
                              key={index}
                            >
                              <TableCell colSpan={3} sx={{ padding: "0rem" }}>
                                <Box sx={{ width: "100%" }}>
                                  <LinearProgressWithLabel
                                    color={percentageColor(item)}
                                    status={item?.error?.status}
                                    value={item?.percentage}
                                    uploadsesid={item?.uploadsesid}
                                    error={item?.error?.error_message}
                                  />
                                </Box>
                              </TableCell>
                            </TableRow>
                          </>
                        );
                      })
                    )}
                  </TableBody>
                </Table>
                <TablePagination
                  rowsPerPageOptions={[7]}
                  component="div"
                  count={schedulerHistory?.count}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </TableContainer>
            </Box>

            <Dialog
              fullWidth
              open={nestedDialog}
              scroll="body"
              onClose={handleNestedDialog}
              sx={{
                "& .MuiDialog-container": {
                  "& .MuiPaper-root": {
                    width: "100%",
                    maxWidth: "1000px",
                  },
                },
              }}
            >
              <DialogContent sx={{ p: 0 }}>
                <NestedTableDialog
                  setDialogOpen={setOpen}
                  onReuseCreativesSelect={onReuseCreativesSelect}
                  setStep={setStep}
                  setNestedDialog={setNestedDialog}
                  historyRowData={historyRowData}
                  handleStatusUpdateClick={handleStatusUpdateClick}
                  schedulingLoading={schedulingLoading}
                  setSchedulingLoading={setSchedulingLoading}
                />
              </DialogContent>
            </Dialog>
          </>
        )}
      </Box>
    </>
  );
}
