// ** React Imports
import { useEffect, useState } from "react";

// ** MUI Imports
import { Box, Typography, Grid, Autocomplete, TextField } from "@mui/material";

// ** Third Party Library
import { useNavigate, useLocation } from "react-router-dom";
import "react-multi-carousel/lib/styles.css";

// ** Redux
import { useAppDispatch, useAppSelector } from "store/store";
import { getJobTitle } from "store/Thunk/myBrandProfileThunk";

// ** Custom Components
import { route_info } from "navigation/route";
import { MARKET_PLACE } from "@core/constants/title-constants";
import {
  historyTabTitle,
  submissionTabTitle,
} from "@core/constants/global-constants";
import SubmissionTabs from "components/SubmissionTabs";
import SubmissionHistoryTabs from "components/SubmissionTabs/HistoryTabs";

export default function Submissions() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { state } = useLocation();
  const { jobTitleList } = useAppSelector(
    (state) => state.createBrandProfileSlice
  );

  const [jobTitleValue, setJobTitleValue] = useState<any>({});
  const [contentPublishType, setContenPublishType] = useState<number>();

  useEffect(() => {
    if (state && state.job) {
      setContenPublishType(state && state.job && state.job.id);
      setJobTitleValue(state && state.job && state.job);
    } else {
      setContenPublishType(
        jobTitleList && jobTitleList.length && jobTitleList[0].id
      );
      setJobTitleValue(jobTitleList && jobTitleList.length && jobTitleList[0]);
    }
  }, [jobTitleList, state]);

  useEffect(() => {
    dispatch(getJobTitle({ dispatch }));
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handledJobChange = (event: any, newValue: any) => {
    setJobTitleValue(newValue);
    setContenPublishType(newValue.id);
  };

  return (
    <Box className="all-jobs main-submission-block">
      <Typography
        variant="subtitle2"
        onClick={() => navigate(route_info.market_place.my_brand_jobs)}
        className="txt-back-to-overview"
        sx={{ maxWidth: { md: "22%", xs: "100%" } }}
      >
        {MARKET_PLACE.CREATE_CONTENT.BACK_TO_OVERVIEW}
      </Typography>
      <Grid container>
        <Grid item md={9} sm={7} xs={12}>
          <Typography variant="h6">
            {MARKET_PLACE.CREATE_CONTENT.SUBMISSIONS}
          </Typography>
          <Typography variant="subtitle2" sx={{ mb: 2 }}>
            {MARKET_PLACE.REQUEST_CONTENT.THESE_CREATORS_HAVE_SUBMITTED}
          </Typography>
        </Grid>
        <Grid item md={3} sm={5} xs={12}>
          <Autocomplete
            id="tags-outlined"
            options={jobTitleList || []}
            onChange={(event, newValue) => handledJobChange(event, newValue)}
            getOptionLabel={(option: any) =>
              option.title || "Choose job title..."
            }
            value={jobTitleValue}
            sx={{ mt: "10px", mb: "10px" }}
            renderInput={(params: any) => <TextField {...params} />}
          />
        </Grid>
      </Grid>

      <Box>
        <SubmissionTabs
          selectedPublishType={contentPublishType}
          submissionTabTitle={submissionTabTitle}
        />
      </Box>
      <Box sx={{ mt: "20px" }}>
        <Typography variant="h6" sx={{ mb: "10px" }}>
          {MARKET_PLACE.REQUEST_CONTENT.PAST_SUBMISSION}
        </Typography>
      </Box>
      <Box>
        <SubmissionHistoryTabs
          selectedPublishType={contentPublishType}
          submissionTabTitle={historyTabTitle}
        />
      </Box>
    </Box>
  );
}
