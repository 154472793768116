// ** React
import { useContext, useEffect, useState } from "react";

// ** MUI Imports
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  Tab,
  Tabs,
  Typography,
  Chip,
  Divider,
  Tooltip,
  Grow,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// ** Third Party Library
import DatePicker from "react-datepicker";
import moment from "moment";

// ** Redux
import { useAppDispatch, useAppSelector } from "store/store";
import {
  UploadSesId,
  SchedulerDraftData,
  CreativesData,
} from "store/Slice/autoSchedulerSlice";
import {
  getIdentity,
  postCreateNewScheduler,
  reviewPageAdsetCalculation,
  getAutoSchedulerDraft,
} from "store/Thunk/autoSchedulerThunk";
import { AutoschudlerContext } from "context/autoschedulerContex";

// ** Custom Component
import { PLATFORMS } from "@core/constants/enum";
import DatePickerWrapper from "@core/styles/libs/react-datepicker";
import { MEDIA_PLANNER } from "@core/constants/title-constants";
import CompletedDialog from "pages/auto-scheduler/CompletedDialog/CompletedDialog";
import ReviewTiktokTabs from "pages/auto-scheduler/ReviewTabs/ReviewTiktokTabs";
import ReviewFacebookTabs from "pages/auto-scheduler/ReviewTabs/ReviewFacebookTabs";
import ReviewSnapchatTabs from "pages/auto-scheduler/ReviewTabs/ReviewSnapchatTabs";
import PickersComponent from "pages/auto-scheduler/ReviewTabs/DateAndTimePicker/PickersCustomInput";
import ChangePresetsDialog from "pages/auto-scheduler/ChangePresetsDialog";

// ** Icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import BoltIcon from "@mui/icons-material/Bolt";
import FacebookIcon from "icon/facebook";
import FacebookTabIcon from "icon/facebookTabIcon";
import SnapchatIcon from "icon/snapchat";
import SnapchatTabIcon from "icon/snapchatTabIcon";
import TiktokTabIcon from "icon/tiktokTabIcon";
import TiktokIcon from "icon/tiktok";

interface Props {
  onBackwordButtonClick?(): void;
  onClearDraftClick?(): void;
  onStartButtonClick?(): void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ReviewStep({
  onBackwordButtonClick,
  onClearDraftClick,
  onStartButtonClick,
}: Props) {
  const dispatch = useAppDispatch();
  let autoSchedulerTitle = MEDIA_PLANNER.auto_scheduler;

  const {
    campaign,
    tiktok,
    state,
    setState,
    setStep,
    snapchat,
    facebook,
    availablePlatform,
    availablePlatformTitle,
    getAvailablePlatform,
    setCampaign,
    setTiktok,
    setSnapchat,
    setFacebook,
    schedulerCreatives,
    setSchedulerCreatives,
    setAvailablePlatformTitle,
    setAvailablePlatform,
  }: any = useContext(AutoschudlerContext);

  const { tiktokIdentity, uploadsesId } = useAppSelector(
    (state: any) => state.autoSchedulerSlice
  );

  const [value, setValue] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [presetDialogOpen, setPresetDialogOpen] = useState(false);
  const [tiktokIdentityType, setTiktokIdentityType] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isPresetsOverwrite, setIsPresetsOverwrite] = useState("overwrite");

  useEffect(() => {
    if (tiktokIdentity) {
      const selectedIdentityType = tiktokIdentity.find((item: any) => {
        return item?.identity_id === tiktok?.identity;
      });
      if (selectedIdentityType?.identity_type) {
        setTiktokIdentityType(selectedIdentityType?.identity_type);
      }
      // setProfileName(selectedProfile?.first_name ? selectedProfile?.first_name + " " + selectedProfile?.last_name : selectedProfile?.last_name)
    }
  }, [tiktokIdentity]);

  useEffect(() => {
    if (availablePlatformTitle.includes("Tiktok")) {
      const data = {
        advertiser_id: tiktok?.advertiserAccount,
      };
      dispatch(getIdentity({ data }));
    }
  }, []);

  useEffect(() => {
    let placement_type = "";

    state?.existingPresets && state?.existingPresets?.id !== 0
      ? setPresetDialogOpen(true)
      : setPresetDialogOpen(false);
    const countriesString = campaign?.countries
      .map(function (val: any) {
        return val.Code;
      })
      .join(",");

    if (facebook?.instagramPlacement && facebook?.facebookPlacement) {
      placement_type =
        facebook?.facebookPlacement?.join(",") +
        "," +
        facebook?.instagramPlacement?.join(",");
    } else if (facebook?.instagramPlacement && !facebook?.facebookPlacement) {
      placement_type = facebook?.instagramPlacement?.join(",");
    } else if (!facebook?.instagramPlacement && facebook?.facebookPlacement) {
      placement_type = facebook?.facebookPlacement?.join(",");
    }

    const _request = {
      uploadsesid: uploadsesId,
      countries: countriesString,
      bundle_countries: campaign?.isBundleCountries,
      daily_budget: campaign?.budget,
      Facebook: availablePlatformTitle?.includes(PLATFORMS.FACEBOOK)
        ? {
            max_nr_creatives: facebook?.maximumCreative,
            placements: facebook?.advantage,
            placement_type:
              !facebook?.advantage && placement_type ? placement_type : "",
          }
        : {},
      Tiktok: availablePlatformTitle?.includes(PLATFORMS.TIKTOK)
        ? {
            max_nr_creatives: tiktok?.maximumCreative,
            placements: true,
            placement_type: "",
          }
        : {},
      Snap: availablePlatformTitle?.includes(PLATFORMS.SNAP)
        ? {
            max_nr_creatives: snapchat?.maximumCreative,
            placements: true,
            placement_type: "",
          }
        : {},
    };

    dispatch(reviewPageAdsetCalculation({ _request })).then((res: any) => {});
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const changeOverwritePopUp = (e: any) => {
    setIsPresetsOverwrite(e.target.value);
  };

  const handledScheduleBatchButtonClick = () => {
    let schedulerData = {};

    const preset = state?.existingPresets
      ? state?.existingPresets.id == 0 && isPresetsOverwrite === "new"
        ? {
            action: "New",
            preset_name: state?.presetName,
            id: null,
          }
        : isPresetsOverwrite === "overwrite"
        ? {
            action: "Overwrite",
            preset_name: state?.existingPresets?.preset_name,
            id: state?.existingPresets?.id,
          }
        : {}
      : {};

    const finalePreset = state?.changePresetName
      ? {
          action: "New",
          preset_name: state?.changePresetName,
          id: null,
        }
      : preset;

    setButtonLoading(true);
    schedulerData = {
      uploadsesid: uploadsesId,
      group_id: campaign?.group,
      campaign_objective: campaign?.objective,
      budget: campaign?.budget,
      bid_strategy: campaign?.bidStategy,

      bid_cost: campaign?.bidCost,
      countries: campaign?.countries,
      is_bundle_countries: campaign?.isBundleCountries,
      is_language: campaign?.isCustomLanguage,
      preset: finalePreset,
      schedule_date:
        state?.scheduleDate &&
        moment(state?.scheduleDate).format("YYYY-MM-DD HH:mm:ss"),
      ignore_until: "",
      languages: campaign?.isBundleCountries
        ? campaign?.bundlelanguages
        : campaign?.customLanguages,
      Tiktok: availablePlatformTitle?.includes(PLATFORMS.TIKTOK)
        ? {
            profile_id: tiktok?.profile,
            authkey_email_user: tiktok?.profileEmail,
            advertiser_id: tiktok?.advertiserAccount,
            is_auto_generate_campaign: tiktok?.autoGenerateName,
            campaign_name: tiktok?.newAccount,
            campaign_id: tiktok?.existingAccount,
            interests: tiktok?.interest,
            age_group: tiktok?.ageGroup?.join(","),
            is_custom_audience: tiktok?.customAudiences,
            custom_audience: tiktok?.selectedAudience,
            tiktok_identity_type: tiktokIdentityType,
            tiktok_identity_id: tiktok?.identity,
            pixel_id: tiktok?.pixel,
            event_type: tiktok?.optimizeEvent,
            maxcreatives: tiktok?.maximumCreative,
            auto_placement: false,
            placement_list: "Placement_tiktok",
            caption: tiktok?.caption,
            heading: tiktok?.heading,
            accelerated_spend:
              campaign?.bidStategy !== "Bid Cap"
                ? false
                : tiktok?.accelerateSpending,
            extra_name: state?.batchName,
            strategy: "min_cpc",
            music_sharingh: "",
            stitch_duet: "",
            dayparting: tiktok?.dayparting,
            dayparting_ofset: tiktok?.dayparting
              ? campaign?.isBundleCountries
                ? "0"
                : tiktok?.dayparsingOffset
                ? tiktok?.dayparsingOffset
                : {}
              : {},
            dayparting_list: tiktok?.dayparsingString,
          }
        : {},
      Facebook: availablePlatformTitle?.includes(PLATFORMS.FACEBOOK)
        ? {
            profile_id: facebook?.profile,
            authkey_email_user: facebook?.profileEmail,
            advertiser_id: facebook?.advertiserAccount,
            is_auto_generate_campaign: facebook?.autoGenerateName,
            campaign_name: facebook?.newAccount,
            campaign_id: facebook?.existingAccount,
            interests: facebook?.interest,
            age_group: facebook?.ageGroup?.join(","),
            is_custom_audience: facebook?.customAudiences,
            custom_audience: facebook?.selectedAudience,
            facebook_pages: facebook?.facebookPage,
            instagram_id: facebook?.instagramAccount,
            pixel_id: facebook?.pixel,
            event_type: facebook?.optimizeEvent,
            maxcreatives: facebook?.maximumCreative,
            auto_placement: facebook?.advantage,
            placement_list: facebook?.advantage
              ? ""
              : facebook?.advantageAndPlacements?.toString(),

            caption: facebook?.caption,
            heading: facebook?.heading,
            accelerated_spend:
              campaign?.bidStategy !== "Bid Cap"
                ? false
                : facebook?.accelerateSpending,
            extra_name: state?.batchName,
            strategy: "min_cpc",
            ad_type: "Single",
            dayparting: false,
            user_id: "",
          }
        : {},
      Snapchat: availablePlatformTitle?.includes(PLATFORMS.SNAP)
        ? {
            profile_id: snapchat?.profile,
            authkey_email_user: snapchat?.profileEmail,
            advertiser_id: snapchat?.advertiserAccount,
            is_auto_generate_campaign: snapchat?.autoGenerateName,
            campaign_name: snapchat?.newAccount,
            campaign_id: snapchat?.existingAccount,
            interests: snapchat?.interest,
            age_group: snapchat?.ageGroup?.join(","),
            is_custom_audience: snapchat?.customAudiences,
            custom_audience: snapchat?.selectedAudience,
            pixel_id: snapchat?.pixel,
            event_type: snapchat?.optimizeEvent,
            maxcreatives: snapchat?.maximumCreative,
            auto_placement: true,
            placement_list: "Automatic",
            caption: snapchat?.caption,
            heading: snapchat?.heading,
            accelerated_spend:
              campaign?.bidStategy !== "Bid Cap"
                ? false
                : snapchat?.accelerateSpending,
            extra_name: state?.batchName,
            strategy: "min_cpc",
            ad_type: "Single",
            dayparting: false,
            user_id: "",
          }
        : {},
    };

    dispatch(postCreateNewScheduler({ schedulerData })).then((res: any) => {
      setButtonLoading(false);
      if (res.payload.data.error) {
        setDialogOpen(false);
      } else {
        setDialogOpen(true);
        dispatch(SchedulerDraftData({}));
        dispatch(CreativesData([]));
        dispatch(getAutoSchedulerDraft({}));

        setAvailablePlatformTitle(["Tiktok", "Facebook", "Snap"]);

        setAvailablePlatform([
          {
            id: 1,
            title: "Tiktok",
            value: PLATFORMS.TIKTOK,
            icon: <TiktokIcon active={true} />,
            activeTabIcon: <TiktokTabIcon active={true} />,
            deactiveTabIcon: <TiktokTabIcon active={false} />,
          },
          {
            id: 2,
            title: "Meta",
            value: PLATFORMS.FACEBOOK,
            icon: <FacebookIcon active={true} />,
            activeTabIcon: <FacebookTabIcon active={true} />,
            deactiveTabIcon: <FacebookTabIcon active={false} />,
          },
          {
            id: 3,
            title: "Snapchat",
            value: PLATFORMS.SNAP,
            icon: <SnapchatIcon active={true} />,
            activeTabIcon: <SnapchatTabIcon active={true} />,
            deactiveTabIcon: <SnapchatTabIcon active={false} />,
          },
        ]);
        dispatch(UploadSesId(null));

        setState({
          platform: ["Facebook", "Tiktok", "Snap"],
          bidStategy: "",
          countries: [],
          bundleCountries: false,
          batchName: "",
          scheduleDate: new Date(),
          reviewPageData: {},
          predefinedSettings: false,
          existingPresets: "",
          presetName: "",
          changePresetName: "",
          reusePreviousBatch: false,
          settingPlatform: 0,
          autoLinkfire: false,
        });
        setCampaign({
          bidStategy: "",
          countries: [],
          isBundleCountries: false,
          objective: "",
          budget: "20",
          bidCost: "",
          isCustomLanguage: false,
          customLanguages: {},
          bundlelanguages: [],
          group: "",
        });
        setTiktok({
          advertiserAccount: "",
          existingAccount: "",
          profile: "",
          newAccount: "",
          activeButton: "Existing",
          autoGenerateName: true,
          customAudiences: false,
          selectedAudience: [],
          identity: "",
          maximumCreative: 8,
          interest: [],
          caption: "",
          accelerateSpending: false,
          pixel: "",
          optimizeEvent: "",
          disableCampaignName: "",
          ageGroup: ["13-17", "18-24", "25-34", "35-44", "45-54", "55+"],
          heading: "",
          dayparsingString: [],
          dayparsingOffset: {},
          profileEmail: "",
          firstName: "",
          lastName: "",
          adAccountName: "",
          dayparting: false,
        });
        setSnapchat({
          advertiserAccount: "",
          existingAccount: "",
          profile: "",
          newAccount: "",
          activeButton: "Existing",
          autoGenerateName: true,
          customAudiences: false,
          selectedAudience: [],
          maximumCreative: 5,
          interest: [],
          caption: "",
          accelerateSpending: false,
          pixel: "",
          optimizeEvent: "",
          disableCampaignName: "",
          ageGroup: ["13-17", "18-24", "25-34", "35-44", "45+"],
          companyName: "",
          heading: "",
          profileEmail: "",
          firstName: "",
          lastName: "",
          adAccountName: "",
        });
        setFacebook({
          advantage: true,
          advertiserAccount: "",
          existingAccount: "",
          profile: "",
          newAccount: "",
          activeButton: "Existing",
          autoGenerateName: true,
          customAudiences: false,
          selectedAudience: [],
          maximumCreative: 5,
          interest: [],
          caption: "",
          accelerateSpending: false,
          pixel: "",
          optimizeEvent: "",
          disableCampaignName: "",
          ageGroup: ["13-17", "18-24", "25-34", "35-44", "45-54", "55+"],
          facebookPlacement: [],
          instagramPlacement: [],
          heading: "",
          facebookPage: "",
          instagramAccount: "",
          profileEmail: "",
          firstName: "",
          lastName: "",
          adAccountName: "",
          advantageAndPlacements: [],
        });
        setSchedulerCreatives({
          creativesArray: [],
          files: [],
          creatives: [],
          updateCreative: [],
          batchName: "",
        });
      }
    });
  };

  const showMoreContent = () => {
    let temp = (
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              maxWidth: "500px",
            },
          },
        }}
        title={
          campaign?.countries?.length > 3
            ? campaign?.countries
                ?.slice(3, campaign?.countries?.length)
                .map((item: any, index: number) => {
                  return item.Name;
                })
                .join(", ")
            : null
        }
      >
        <Typography variant="subtitle2" sx={{ cursor: "default" }}>
          {campaign?.countries &&
            campaign?.countries
              ?.slice(0, 3)
              .map((item: any, index: number) => {
                return item.Name;
              })
              .join(", ")}
          {campaign?.countries?.length > 3
            ? ` +${campaign?.countries?.length - 3} more`
            : ""}
        </Typography>
      </Tooltip>
    );
    return temp;
  };

  return (
    <>
      <Grow in={true}>
        <Box>
          <Box sx={{ padding: 5 }}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              {autoSchedulerTitle.REVIEW_YOUR_BATCH}
            </Typography>
            <Typography variant="subtitle2" sx={{ mb: 2 }}>
              {autoSchedulerTitle.MAKE_SURE_REVIEW}
            </Typography>
          </Box>

          <Box sx={{ width: "100%" }}>
            {/* Preset Settings */}
            <Card sx={{ mt: 4, padding: 8, pb: 0 }}>
              <Box
                className="align-justify-item-center"
                sx={{ overflow: "hidden" }}
              >
                <Chip label={autoSchedulerTitle.REVIEW_PRESET} />
                <Divider sx={{ width: "100%" }} />
              </Box>
              <Box sx={{ display: "flex", margin: 7 }}>
                <Box sx={{ marginRight: 5 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontWeight: 500,
                      color: "rgba(76, 78, 100, 0.87)",
                      margin: 2,
                    }}
                  >
                    {autoSchedulerTitle.PRESET_USED_REVIEW}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle2" sx={{ margin: 2 }}>
                    {state?.changePresetName
                      ? state?.changePresetName
                      : state?.presetName
                      ? state?.presetName
                      : state?.existingPresets?.preset_name
                      ? state?.existingPresets?.preset_name
                      : "-"}
                    {state?.changePresetName && (
                      <Chip
                        icon={<BoltIcon fontSize="small" />}
                        label="NEW"
                        color="success"
                        size="small"
                        sx={{ ml: "10px" }}
                      />
                    )}
                  </Typography>
                </Box>
              </Box>
            </Card>

            {/* Campaign Settings */}
            <Card sx={{ mt: 4, padding: 8, pb: 0 }}>
              <Box
                className="align-justify-item-center"
                sx={{ overflow: "hidden" }}
              >
                <Chip label={autoSchedulerTitle.REVIEW_CAMPAIGN} />
                <Divider sx={{ width: "100%" }} />
              </Box>
              <Box sx={{ display: "flex", margin: 7 }}>
                <Box sx={{ marginRight: 5 }}>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontWeight: 500,
                      color: "rgba(76, 78, 100, 0.87)",
                      margin: 2,
                    }}
                  >
                    {autoSchedulerTitle.GROUP}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontWeight: 500,
                      color: "rgba(76, 78, 100, 0.87)",
                      margin: 2,
                    }}
                  >
                    {autoSchedulerTitle.CAMPAIGN_OBJECTIVES + ":"}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontWeight: 500,
                      color: "rgba(76, 78, 100, 0.87)",
                      margin: 2,
                    }}
                  >
                    {autoSchedulerTitle.BUDGET + ":"}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontWeight: 500,
                      color: "rgba(76, 78, 100, 0.87)",
                      margin: 2,
                    }}
                  >
                    {autoSchedulerTitle.BID_STRATEGY + ":"}
                  </Typography>
                  {campaign?.bidStategy !== "Bid Cap" ? null : (
                    <Typography
                      variant="subtitle2"
                      sx={{
                        fontWeight: 500,
                        color: "rgba(76, 78, 100, 0.87)",
                        margin: 2,
                      }}
                    >
                      {autoSchedulerTitle.BID_COST + ":"}
                    </Typography>
                  )}

                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontWeight: 500,
                      color: "rgba(76, 78, 100, 0.87)",
                      margin: 2,
                    }}
                  >
                    {autoSchedulerTitle.COUNTRIES + ":"}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontWeight: 500,
                      color: "rgba(76, 78, 100, 0.87)",
                      margin: 2,
                    }}
                  >
                    {autoSchedulerTitle.BUNDLE_COUNTRY_LABEL + ":"}
                  </Typography>
                  <Typography
                    variant="subtitle2"
                    sx={{
                      fontWeight: 500,
                      color: "rgba(76, 78, 100, 0.87)",
                      margin: 2,
                    }}
                  >
                    {autoSchedulerTitle.SELECT_LANGUAGE + ":"}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="subtitle2" sx={{ margin: 2 }}>
                    {campaign ? campaign?.group : "-"}
                  </Typography>

                  <Typography variant="subtitle2" sx={{ margin: 2 }}>
                    {campaign?.objective ? campaign?.objective : "-"}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ margin: 2 }}>
                    {campaign?.budget ? campaign?.budget : "-"}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ margin: 2 }}>
                    {campaign?.bidStategy ? campaign?.bidStategy : "-"}
                  </Typography>
                  {campaign?.bidStategy !== "Bid Cap" ? null : (
                    <Typography variant="subtitle2" sx={{ margin: 2 }}>
                      {campaign?.bidCost ? campaign?.bidCost : "-"}
                    </Typography>
                  )}
                  <Typography variant="subtitle2" sx={{ margin: 2 }}>
                    {showMoreContent() ? showMoreContent() : "-"}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ margin: 2 }}>
                    {campaign?.isBundleCountries ? "ON" : "OFF"}
                  </Typography>
                  <Typography variant="subtitle2" sx={{ margin: 2 }}>
                    {campaign?.isCustomLanguage ? "ON" : "OFF"}
                  </Typography>
                </Box>
              </Box>
            </Card>
            <Box pl={5}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                {availablePlatform
                  ?.filter((item: any) => {
                    return availablePlatformTitle?.includes(item?.value);
                  })
                  ?.map((title: any, index: any) => (
                    <Tab
                      key={title?.id}
                      label={title?.title}
                      iconPosition="start"
                      icon={
                        value === index
                          ? title?.activeTabIcon
                          : title?.deactiveTabIcon
                      }
                      {...a11yProps(title?.id)}
                    />
                  ))}
              </Tabs>
            </Box>
            <Box>
              {availablePlatform
                .filter((item: any) =>
                  availablePlatformTitle?.includes(item.value)
                )
                ?.map(
                  (item: any, index: any) =>
                    availablePlatformTitle?.includes(item?.value) && (
                      <TabPanel value={value} index={index}>
                        {item?.value === PLATFORMS.TIKTOK && (
                          <ReviewTiktokTabs />
                        )}
                        {item?.value === PLATFORMS.FACEBOOK && (
                          <ReviewFacebookTabs />
                        )}
                        {item?.value === PLATFORMS.SNAP && (
                          <ReviewSnapchatTabs />
                        )}
                      </TabPanel>
                    )
                )}
            </Box>
          </Box>

          <Box sx={{ padding: 5, mt: 5 }}>
            <Typography variant="h5" sx={{ mb: 2 }}>
              {autoSchedulerTitle.DONE_WITH_REVIEW}
            </Typography>
            <Typography variant="subtitle2" sx={{ mb: 2 }}>
              {autoSchedulerTitle.DOUBLE_CHECK_REVIEW}
            </Typography>
          </Box>
          <Card sx={{ padding: 10 }}>
            <Box sx={{ mt: 5, width: "320px" }}>
              <DatePickerWrapper>
                <DatePicker
                  showTimeSelect
                  timeFormat="HH:mm"
                  timeIntervals={15}
                  selected={new Date(state?.scheduleDate)}
                  id="date-time-picker"
                  minDate={moment().toDate()}
                  dateFormat="MM/dd/yyyy HH:mm:ss"
                  onChange={(date: any) =>
                    setState({ ...state, scheduleDate: date })
                  }
                  customInput={
                    <PickersComponent
                      label={autoSchedulerTitle.SCHEDULE_DATE}
                    />
                  }
                />
              </DatePickerWrapper>
            </Box>

            <Divider sx={{ mt: 10 }} />
            <Box sx={{ mt: 6 }}>
              <Box
                className="footer-button"
                sx={{ justifyContent: { xs: "center", sm: "space-between" } }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    justifyContent: "center",
                  }}
                  gap={2}
                >
                  <Button
                    size="large"
                    type="submit"
                    variant="outlined"
                    sx={{ mb: 4 }}
                    color="secondary"
                    onClick={onBackwordButtonClick}
                    startIcon={<ArrowBackIcon />}
                  >
                    {autoSchedulerTitle.CREATIVES}
                  </Button>
                  {/* <Button
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{ mb: 4 }}
                    onClick={() => handledScheduleBatchButtonClick()}
                  >
                    SCHEDULE BATCH
                  </Button> */}
                  <LoadingButton
                    loading={buttonLoading}
                    disabled={buttonLoading}
                    size="large"
                    type="submit"
                    variant="contained"
                    sx={{ mb: 4 }}
                    onClick={() => handledScheduleBatchButtonClick()}
                  >
                    {autoSchedulerTitle.SCHEDULE_BATCH}
                  </LoadingButton>
                </Box>
                <Button
                  size="large"
                  variant="outlined"
                  onClick={onClearDraftClick}
                  sx={{ mt: { xs: 3, sm: 0 } }}
                  startIcon={<DeleteIcon />}
                >
                  {autoSchedulerTitle.CLEAR_DRAFT}
                </Button>
              </Box>
            </Box>
          </Card>

          <Dialog
            fullWidth
            open={dialogOpen}
            scroll="body"
            // onClose={() => setDialogOpen(false)}
            sx={{
              "& .MuiDialog-container": {
                "& .MuiPaper-root": {
                  width: "100%",
                  maxWidth: "450px",
                },
              },
            }}
          >
            <DialogContent>
              <CompletedDialog onStartButtonClick={onStartButtonClick} />
            </DialogContent>
          </Dialog>
        </Box>
      </Grow>
      <Dialog
        fullWidth
        open={presetDialogOpen}
        scroll="body"
        onClose={() => setPresetDialogOpen(false)}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "450px",
            },
          },
        }}
      >
        <DialogContent>
          <ChangePresetsDialog
            state={state}
            setState={setState}
            isPresetsOverwrite={isPresetsOverwrite}
            setPresetDialogOpen={setPresetDialogOpen}
            onOverWriteChange={changeOverwritePopUp}
          />
        </DialogContent>
      </Dialog>
    </>
  );
}
