// ** MUI Imports
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  List,
} from "@mui/material";

// ** Custom Component
import { MARKET_PLACE } from "@core/constants/title-constants";

// ** Assets
import { PixelMelonLeaning2 } from "assets";

export default function PayoutDialog(props: any) {
  const {
    isOpen,
    onClickClose,
    isSubmissionCompleted,
    setOpen,
    handleUploadContentClose,
    handleContinue,
  } = props;

  const handleConnection = () => {
    setOpen(true);
    onClickClose();
  };

  return (
    <>
      <Dialog
        open={isOpen}
        aria-labelledby="user-view-edit"
        aria-describedby="user-view-edit-description"
      >
        <>
          <DialogTitle className="detail-dialog-title" sx={{ pb: 0 }}>
            {MARKET_PLACE.CREATE_CONTENT.CONFIRM_PAYOUT}
          </DialogTitle>

          <DialogContent>
            <Typography className="details-dialog-subtitle">
              {MARKET_PLACE.CREATE_CONTENT.PAYING_ACCOUNT}
              johndoe@mail.com
            </Typography>
            <List sx={{ listStyleType: "disc", pl: 6 }}></List>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ fontSize: { xs: "13px" } }}
              variant="outlined"
              onClick={onClickClose}
            >
              Cancel
            </Button>
            <Button
              sx={{
                p: { xs: "0.4375rem 0.5rem", md: "0.4375rem 1.375rem" },
                fontSize: { xs: "13px", md: "14px" },
              }}
              variant="contained"
              color="primary"
              onClick={() => handleConnection()}
              autoFocus
            >
              {MARKET_PLACE.CREATE_CONTENT.CONFIRM_PAYOUT}
            </Button>
          </DialogActions>
        </>
      </Dialog>

      <Dialog
        open={isSubmissionCompleted}
        onClose={handleUploadContentClose}
        aria-labelledby="user-view-edit"
        aria-describedby="user-view-edit-description"
      >
        <DialogTitle className="detail-dialog-title">
          {MARKET_PLACE.CREATE_CONTENT.PAYOUT_SUCCESSFUL}
        </DialogTitle>
        <DialogContent sx={{ pb: "25px" }}>
          <Typography sx={{ pb: "42px" }} className="dialog_content">
            {MARKET_PLACE.CREATE_CONTENT.VIEWABLE_IN_YOUR_PAYPAL}
          </Typography>

          <Box sx={{ display: "flex", justifyContent: "center", mb: "20px" }}>
            <Box
              className="submission-success-img"
              component="img"
              alt="Publish type image not found"
              src={PixelMelonLeaning2}
            />
          </Box>
          <Button
            variant="contained"
            className="continue-btn"
            onClick={handleContinue}
          >
            {MARKET_PLACE.REQUEST_CONTENT.FINISH}
          </Button>
        </DialogContent>
      </Dialog>
    </>
  );
}
