// ** React
import { useEffect, useState } from "react";

// ** MUI Imports
import {
  Avatar,
  Box,
  BoxProps,
  Button,
  CircularProgress,
  LinearProgress,
  Step,
  StepLabel,
  Stepper,
  SwipeableDrawer,
  useTheme,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";

// ** Third Party Library
import { Link, useNavigate } from "react-router-dom";

// ** Redux
import { useAppDispatch, useAppSelector } from "store/store";
import {
  getAutoSchedulerDraft,
  postAutoSchedulerDraft,
  clearDraft,
} from "store/Thunk/autoSchedulerThunk";
import {
  getAllScraperGroup,
  getPlatformCount,
} from "store/Thunk/platformConnectionThunk";
import {
  SchedulerDraftData,
  UploadSesId,
} from "store/Slice/autoSchedulerSlice";
import { AutoschudlerContext } from "context/autoschedulerContex";

// ** Custom Component
import { route_info } from "navigation/route";
import StepperWrapper from "@core/styles/mui/stepper";
import { PLATFORMS } from "@core/constants/enum";
import ClearDraftConfirmation from "components/Dialogs/ClearDraftConfirmation";
import PlatformStep from "pages/auto-scheduler/PlatformStep";
import StepperCustomDot from "pages/auto-scheduler/StepperCustomDot";
import Champaing from "pages/auto-scheduler/Champaing";
import SettingStep from "pages/auto-scheduler/SettingStep";
import CreativesStep from "pages/auto-scheduler/CreativesStep";
import ReviewStep from "pages/auto-scheduler/ReviewStep";
import TimesaverStep from "pages/auto-scheduler/TimesaverStep";
import SchedulerHisrotyDrawer from "pages/auto-scheduler/SwipableDrawer/SchedulerHisrotyDrawer";
import { MEDIA_PLANNER } from "@core/constants/title-constants";

// ** Icons
import TiktokIcon from "icon/tiktok";
import FacebookIcon from "icon/facebook";
import SnapchatIcon from "icon/snapchat";
import FacebookTabIcon from "icon/facebookTabIcon";
import SnapchatTabIcon from "icon/snapchatTabIcon";
import TiktokTabIcon from "icon/tiktokTabIcon";
import { PixelMelonHi } from "assets";

const steps = [
  "Platforms",
  "Timesaver",
  "Campaign",
  "Settings",
  "Creatives",
  "Review",
];

export default function AutoScheduler() {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch: any = useAppDispatch();

  const [step, setStep] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [draftLoading, setDraftLoading] = useState(true);
  const [state, setState] = useState<any>({
    platform: ["Facebook", "Tiktok", "Snap"],
    bidStategy: "",
    countries: [],
    bundleCountries: false,
    batchName: "",
    scheduleDate: new Date(),
    reviewPageData: {},
    predefinedSettings: false,
    existingPresets: "",
    presetName: "",
    changePresetName: "",
    reusePreviousBatch: false,
    settingPlatform: 0,
    autoLinkfire: false,
  });

  const [isDraftDelete, setIsDraftDelete] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [isPlatform, setIsPlatform] = useState(true);
  const [isPlatformAvailable, setIsPlatformAvailable] = useState(false);
  const [availablePlatformTitle, setAvailablePlatformTitle] = useState([
    "Tiktok",
    "Facebook",
    "Snap",
  ]);
  const [availablePlatform, setAvailablePlatform] = useState([
    {
      id: 1,
      title: "Tiktok",
      value: PLATFORMS.TIKTOK,
      icon: <TiktokIcon active={true} />,
      activeTabIcon: <TiktokTabIcon active={true} />,
      deactiveTabIcon: <TiktokTabIcon active={false} />,
    },
    {
      id: 2,
      title: "Meta",
      value: PLATFORMS.FACEBOOK,
      icon: <FacebookIcon active={true} />,
      activeTabIcon: <FacebookTabIcon active={true} />,
      deactiveTabIcon: <FacebookTabIcon active={false} />,
    },
    {
      id: 3,
      title: "Snapchat",
      value: PLATFORMS.SNAP,
      icon: <SnapchatIcon active={true} />,
      activeTabIcon: <SnapchatTabIcon active={true} />,
      deactiveTabIcon: <SnapchatTabIcon active={false} />,
    },
  ]);
  const [autoLinkfire, setAutoLinkfire] = useState(false);

  useEffect(() => {
    const validSteps = [0, 1, 2, 3, 4, 5];
    if (!validSteps.includes(step)) {
      setStep(0);
    }
  }, [step]);

  const { scraperGroupData } = useAppSelector(
    (state: any) => state.PlatformSlice
  );
  const { schedulerDraftData, uploadsesId } = useAppSelector(
    (state: any) => state.autoSchedulerSlice
  );

  const { selectedPresetsData }: any = useAppSelector(
    (state: any) => state.autoSchedulerSlice
  );

  const [campaign, setCampaign] = useState({
    bidStategy: "",
    countries: [],
    isBundleCountries: false,
    objective: "",
    budget: "20",
    bidCost: "",
    isCustomLanguage: false,
    customLanguages: {},
    bundlelanguages: [],
    group: "",
  });

  const [snapchat, setSnapchat] = useState({
    advertiserAccount: "",
    existingAccount: "",
    profile: "",
    newAccount: "",
    activeButton: "Existing",
    autoGenerateName: true,
    customAudiences: false,
    selectedAudience: [],
    maximumCreative: 5,
    interest: [],
    caption: "",
    accelerateSpending: false,
    pixel: "",
    optimizeEvent: "",
    disableCampaignName: "",
    ageGroup: ["13-17", "18-24", "25-34", "35-44", "45+"],
    companyName: "",
    heading: "",
    profileEmail: "",
    firstName: "",
    lastName: "",
    adAccountName: "",
  });

  const [tiktok, setTiktok] = useState({
    advertiserAccount: "",
    existingAccount: "",
    profile: "",
    newAccount: "",
    activeButton: "Existing",
    autoGenerateName: true,
    customAudiences: false,
    selectedAudience: [],
    identity: "",
    maximumCreative: 6,
    interest: [],
    caption: "",
    accelerateSpending: false,
    pixel: "",
    optimizeEvent: "",
    disableCampaignName: "",
    ageGroup: ["13-17", "18-24", "25-34", "35-44", "45-54", "55+"],
    heading: "",
    dayparsingString: [],
    dayparsingOffset: {},
    profileEmail: "",
    firstName: "",
    lastName: "",
    adAccountName: "",
    dayparting: false,
  });

  const [facebook, setFacebook] = useState({
    advantage: true,
    advertiserAccount: "",
    existingAccount: "",
    profile: "",
    newAccount: "",
    activeButton: "Existing",
    autoGenerateName: true,
    customAudiences: false,
    selectedAudience: [],
    maximumCreative: 5,
    interest: [],
    caption: "",
    accelerateSpending: false,
    pixel: "",
    optimizeEvent: "",
    disableCampaignName: "",
    ageGroup: ["13-17", "18-24", "25-34", "35-44", "45-54", "55+"],
    facebookPlacement: [],
    instagramPlacement: [],
    heading: "",
    facebookPage: "",
    instagramAccount: "",
    profileEmail: "",
    firstName: "",
    lastName: "",
    adAccountName: "",
    advantageAndPlacements: [],
  });

  const [schedulerCreatives, setSchedulerCreatives] = useState({
    creativesArray: [],
    files: [],
    creatives: [],
    updateCreative: [],
    batchName: "",
  });

  useEffect(() => {
    let data: any = null;
    dispatch(getAllScraperGroup({ data }));
    getAvailablePlatform();
  }, []);

  useEffect(() => {
    const preset_data = selectedPresetsData[0]?.preset_json_data;
    if (preset_data) {
      handledSchedulerData(preset_data);
    }
  }, [selectedPresetsData]);

  useEffect(() => {
    if (schedulerDraftData?.current_page)
      setStep(Number(schedulerDraftData?.current_page));
    if (schedulerDraftData?.draft_data) {
      setState(schedulerDraftData?.draft_data?.state);
      setCampaign(schedulerDraftData?.draft_data?.campaign);
      setFacebook(schedulerDraftData?.draft_data?.facebook);
      setTiktok(schedulerDraftData?.draft_data?.tiktok);
      setSnapchat(schedulerDraftData?.draft_data?.snapchat);
      if (schedulerDraftData?.draft_data?.availablePlatformTitle?.length) {
        setAvailablePlatformTitle(
          schedulerDraftData?.draft_data?.availablePlatformTitle
        );
      }
      if (schedulerDraftData?.draft_data?.availablePlatform?.length) {
        let array: any = [];
        schedulerDraftData?.draft_data?.availablePlatform?.map((item: any) => {
          if (item.value == PLATFORMS.TIKTOK) {
            array.push({
              id: 1,
              title: "Tiktok",
              value: PLATFORMS.TIKTOK,
              icon: <TiktokIcon active={true} />,
              activeTabIcon: <TiktokTabIcon active={true} />,
              deactiveTabIcon: <TiktokTabIcon active={false} />,
            });
          }
          if (item.value == PLATFORMS.FACEBOOK) {
            array.push({
              id: 2,
              title: "Meta",
              value: PLATFORMS.FACEBOOK,
              icon: <FacebookIcon active={true} />,
              activeTabIcon: <FacebookTabIcon active={true} />,
              deactiveTabIcon: <FacebookTabIcon active={false} />,
            });
          }
          if (item.value == PLATFORMS.SNAP) {
            array.push({
              id: 3,
              title: "Snapchat",
              value: PLATFORMS.SNAP,
              icon: <SnapchatIcon active={true} />,
              activeTabIcon: <SnapchatTabIcon active={true} />,
              deactiveTabIcon: <SnapchatTabIcon active={false} />,
            });
          }
        });
        setAvailablePlatform(array);
      }
      if (schedulerDraftData?.draft_data?.creatives) {
        setSchedulerCreatives(schedulerDraftData?.draft_data?.creatives);
      }
    }
  }, [schedulerDraftData]);

  useEffect(() => {
    if (!campaign?.group) {
      let default_group: any = scraperGroupData?.find(
        (element: any) => element?.group_name == "Default"
      )?.id;
      setCampaign({ ...campaign, group: default_group });
    }
  }, [scraperGroupData]);

  const getAvailablePlatform = () => {
    setIsPlatform(true);
    dispatch(getPlatformCount({ navigate }))
      .then((res: any) => {
        let response = res.payload.data.data[0];

        let temp: any = [];
        let tempTitle: any = [];

        if (response) {
          if (
            response.tiktok &&
            response.tiktok > 0 &&
            response.tiktok_ad_account_count > 0
          ) {
            temp.push({
              id: 1,
              title: "Tiktok",
              value: PLATFORMS.TIKTOK,
              icon: <TiktokIcon active={true} />,
              activeTabIcon: <TiktokTabIcon active={true} />,
              deactiveTabIcon: <TiktokTabIcon active={false} />,
            });
            tempTitle.push("Tiktok");
          }
          if (
            response.facebook &&
            response.facebook > 0 &&
            response.facebook_ad_account_count > 0
          ) {
            temp.push({
              id: 2,
              title: "Meta",
              value: PLATFORMS.FACEBOOK,
              icon: <FacebookIcon active={true} />,
              activeTabIcon: <FacebookTabIcon active={true} />,
              deactiveTabIcon: <FacebookTabIcon active={false} />,
            });
            tempTitle.push("Facebook");
          }
          if (
            response.snapchat &&
            response.snapchat > 0 &&
            response.snapchat_ad_account_count > 0
          ) {
            temp.push({
              id: 3,
              title: "Snapchat",
              value: PLATFORMS.SNAP,
              icon: <SnapchatIcon active={true} />,
              activeTabIcon: <SnapchatTabIcon active={true} />,
              deactiveTabIcon: <SnapchatTabIcon active={false} />,
            });
            tempTitle.push("Snap");
          }

          setAvailablePlatform(temp);
          if (tempTitle?.length) {
            setAvailablePlatformTitle(tempTitle);
          } else {
            setAvailablePlatformTitle([]);
          }
          temp?.length
            ? setIsPlatformAvailable(true)
            : setIsPlatformAvailable(false);
          setIsPlatform(false);
        } else {
          setIsPlatform(false);
          setIsPlatformAvailable(false);
        }
        setDraftLoading(true);
        dispatch(getAutoSchedulerDraft({})).then(() => {
          setDraftLoading(false);
        });
      })
      .catch((res: any) => {
        setIsPlatform(false);
        setIsPlatformAvailable(false);
        setDraftLoading(true);
        dispatch(getAutoSchedulerDraft({})).then(() => {
          setDraftLoading(false);
        });
      });
  };

  const Toggler = styled(Box)<BoxProps>(({ theme }) => ({
    right: 0,
    top: "80%",
    display: "flex",
    cursor: "pointer",
    position: "fixed",
    zIndex: theme.zIndex.modal,
    marginRight: -10,
    transform: "translateY(-50%)",
    backgroundColor: "white",
    borderTopLeftRadius: theme.shape.borderRadius,
    borderBottomLeftRadius: theme.shape.borderRadius,
    borderColor: "#FF4D49",
  }));

  const handledForwardButtonClick = () => {
    setStep(step + 1);
    const page = step + 1;
    handleAutodraftData(page);
  };

  const handledCreativesUploadDraftData = (creative: any) => {
    const page = step;
    const data = {
      current_page: page,
      draft_data: {
        state: state,
        campaign: campaign,
        snapchat: snapchat,
        tiktok: tiktok,
        facebook: facebook,
        creatives: creative,
        uploadsesId: uploadsesId,
        availablePlatformTitle: availablePlatformTitle,
        availablePlatform: availablePlatform,
      },
    };
    dispatch(postAutoSchedulerDraft({ data }));
  };

  const handledReviewPageData = (item: any) => {
    handledForwardButtonClick();
  };

  const handleAutodraftData = (page: any) => {
    const data = {
      current_page: page,
      draft_data: {
        state: state,
        campaign: campaign,
        snapchat: snapchat,
        tiktok: tiktok,
        facebook: facebook,
        creatives: schedulerCreatives,
        uploadsesId: uploadsesId,
        availablePlatformTitle: availablePlatformTitle,
        availablePlatform: availablePlatform,
      },
    };

    dispatch(postAutoSchedulerDraft({ data })).then((res: any) => {});
  };

  const handledBackwordButtonClick = () => {
    setStep(step - 1);
  };

  const handledStartButtonClick = () => {
    dispatch(SchedulerDraftData({}));

    setState({
      platform: state.platform,
      bidStategy: "",
      countries: [],
      bundleCountries: false,
      batchName: "",
      scheduleDate: new Date(),
      reviewPageData: {},
      predefinedSettings: false,
      existingPresets: "",
      presetName: "",
      changePresetName: "",
      reusePreviousBatch: false,
      settingPlatform: 0,
      autoLinkfire: false,
    });

    setCampaign({
      bidStategy: "",
      countries: [],
      isBundleCountries: false,
      objective: "",
      budget: "20",
      bidCost: "",
      isCustomLanguage: false,
      customLanguages: {},
      bundlelanguages: [],
      group: "",
    });
    setSchedulerCreatives({
      creativesArray: [],
      files: [],
      creatives: [],
      updateCreative: [],
      batchName: "",
    });
    setSnapchat({
      advertiserAccount: "",
      existingAccount: "",
      profile: "",
      newAccount: "",
      activeButton: "Existing",
      autoGenerateName: true,
      customAudiences: false,
      selectedAudience: [],
      maximumCreative: 5,
      interest: [],
      caption: "",
      accelerateSpending: false,
      pixel: "",
      optimizeEvent: "",
      disableCampaignName: "",
      ageGroup: ["13-17", "18-24", "25-34", "35-44", "45+"],
      companyName: "",
      heading: "",
      profileEmail: "",
      firstName: "",
      lastName: "",
      adAccountName: "",
    });
    setTiktok({
      advertiserAccount: "",
      existingAccount: "",
      profile: "",
      newAccount: "",
      activeButton: "Existing",
      autoGenerateName: true,
      customAudiences: false,
      selectedAudience: [],
      identity: "",
      maximumCreative: 7,
      interest: [],
      caption: "",
      accelerateSpending: false,
      pixel: "",
      optimizeEvent: "",
      disableCampaignName: "",
      ageGroup: ["13-17", "18-24", "25-34", "35-44", "45-54", "55+"],
      heading: "",
      dayparsingString: [],
      dayparsingOffset: {},
      profileEmail: "",
      firstName: "",
      lastName: "",
      adAccountName: "",
      dayparting: false,
    });
    setFacebook({
      advantage: true,
      advertiserAccount: "",
      existingAccount: "",
      profile: "",
      newAccount: "",
      activeButton: "Existing",
      autoGenerateName: true,
      customAudiences: false,
      selectedAudience: [],
      maximumCreative: 5,
      interest: [],
      caption: "",
      accelerateSpending: false,
      pixel: "",
      optimizeEvent: "",
      disableCampaignName: "",
      ageGroup: ["13-17", "18-24", "25-34", "35-44", "45-54", "55+"],
      facebookPlacement: [],
      instagramPlacement: [],
      heading: "",
      facebookPage: "",
      instagramAccount: "",
      profileEmail: "",
      firstName: "",
      lastName: "",
      adAccountName: "",
      advantageAndPlacements: [],
    });
    setAvailablePlatformTitle(availablePlatformTitle);
    setAvailablePlatform(availablePlatform);
    setStep(0);
  };

  const handledClearDraftData = () => {
    setIsDraftDelete(!isDraftDelete);
  };

  const handleConfirmClick = () => {
    setConfirmLoading(true);
    dispatch(clearDraft({ id: schedulerDraftData.id })).then((res: any) => {
      setConfirmLoading(false);

      handledClearDraftData();

      getAvailablePlatform();

      setState({
        platform: ["Facebook", "Tiktok", "Snap"],
        bidStategy: "",
        countries: [],
        bundleCountries: false,
        batchName: "",
        scheduleDate: new Date(),
        reviewPageData: {},
        predefinedSettings: false,
        existingPresets: "",
        presetName: "",
        changePresetName: "",
        reusePreviousBatch: false,
        settingPlatform: 0,
        autoLinkfire: false,
      });

      setCampaign({
        bidStategy: "",
        countries: [],
        isBundleCountries: false,
        objective: "",
        budget: "20",
        bidCost: "",
        isCustomLanguage: false,
        customLanguages: {},
        bundlelanguages: [],
        group: "",
      });

      setTiktok({
        advertiserAccount: "",
        existingAccount: "",
        profile: "",
        newAccount: "",
        activeButton: "Existing",
        autoGenerateName: true,
        customAudiences: false,
        selectedAudience: [],
        identity: "",
        maximumCreative: 8,
        interest: [],
        caption: "",
        accelerateSpending: false,
        pixel: "",
        optimizeEvent: "",
        disableCampaignName: "",
        ageGroup: ["13-17", "18-24", "25-34", "35-44", "45-54", "55+"],
        heading: "",
        dayparsingString: [],
        dayparsingOffset: {},
        profileEmail: "",
        firstName: "",
        lastName: "",
        adAccountName: "",
        dayparting: false,
      });

      setSnapchat({
        advertiserAccount: "",
        existingAccount: "",
        profile: "",
        newAccount: "",
        activeButton: "Existing",
        autoGenerateName: true,
        customAudiences: false,
        selectedAudience: [],
        maximumCreative: 5,
        interest: [],
        caption: "",
        accelerateSpending: false,
        pixel: "",
        optimizeEvent: "",
        disableCampaignName: "",
        ageGroup: ["13-17", "18-24", "25-34", "35-44", "45+"],
        companyName: "",
        heading: "",
        profileEmail: "",
        firstName: "",
        lastName: "",
        adAccountName: "",
      });

      setFacebook({
        advantage: true,
        advertiserAccount: "",
        existingAccount: "",
        profile: "",
        newAccount: "",
        activeButton: "Existing",
        autoGenerateName: true,
        customAudiences: false,
        selectedAudience: [],
        maximumCreative: 5,
        interest: [],
        caption: "",
        accelerateSpending: false,
        pixel: "",
        optimizeEvent: "",
        disableCampaignName: "",
        ageGroup: ["13-17", "18-24", "25-34", "35-44", "45-54", "55+"],
        facebookPlacement: [],
        instagramPlacement: [],
        heading: "",
        facebookPage: "",
        instagramAccount: "",
        profileEmail: "",
        firstName: "",
        lastName: "",
        adAccountName: "",
        advantageAndPlacements: [],
      });

      setSchedulerCreatives({
        creativesArray: [],
        files: [],
        creatives: [],
        updateCreative: [],
        batchName: "",
      });

      dispatch(SchedulerDraftData({}));
      dispatch(UploadSesId(null));
      dispatch(getAutoSchedulerDraft({}));

      setStep(0);
    });
  };

  const handledReuseCreativesSelect = (item: any) => {
    handledSchedulerData(item?.adscheduler_data);
  };

  const handledSchedulerData = (preset_data: any) => {
    if (preset_data) {
      let temp = preset_data;
      let tempPlatform: any = [];

      if (temp && temp?.Tiktok && Object.keys(temp?.Tiktok)?.length > 0) {
        tempPlatform.push("Tiktok");
      }
      if (temp && temp?.Facebook && Object.keys(temp?.Facebook)?.length > 0) {
        tempPlatform.push("Facebook");
      }
      if (temp && temp?.Snapchat && Object.keys(temp?.Snapchat)?.length > 0) {
        tempPlatform.push("Snap");
      }

      setAvailablePlatformTitle(tempPlatform);
    }

    const campaignData = {
      bidStategy: preset_data?.bid_strategy,
      countries: preset_data?.countries ? preset_data?.countries : [],
      isBundleCountries: preset_data?.is_bundle_countries,
      objective: preset_data?.campaign_objective,
      budget: preset_data?.budget,
      bidCost: preset_data?.bid_cost,
      isCustomLanguage: preset_data?.is_language,
      customLanguages: preset_data?.is_bundle_countries
        ? []
        : preset_data?.languages,
      bundlelanguages: preset_data?.is_bundle_countries
        ? preset_data?.languages
        : [],
      group: preset_data?.group_id,
    };
    setCampaign(campaignData);

    if (preset_data?.Tiktok) {
      const tiktokData: any = {
        advertiserAccount: preset_data?.Tiktok?.advertiser_id,
        existingAccount: preset_data?.Tiktok?.campaign_id,
        profile: preset_data?.Tiktok?.profile_id,
        newAccount: preset_data?.Tiktok?.campaign_name,
        activeButton: preset_data?.Tiktok?.campaign_id ? "Existing" : "New",
        autoGenerateName: preset_data?.Tiktok?.is_auto_generate_campaign,
        customAudiences: preset_data?.Tiktok?.is_custom_audience,
        selectedAudience: preset_data?.Tiktok?.custom_audience, // data change,

        identity: preset_data?.Tiktok?.tiktok_identity_id,
        maximumCreative: preset_data?.Tiktok?.maxcreatives,
        interest: preset_data?.Tiktok?.interests, // data change,
        caption: preset_data?.Tiktok?.caption,
        accelerateSpending: preset_data?.Tiktok?.accelerated_spend,

        pixel: preset_data?.Tiktok?.pixel_id,
        optimizeEvent: preset_data?.Tiktok?.event_type,
        disableCampaignName: "",
        dayparting: preset_data?.Tiktok?.dayparting,
        ageGroup: preset_data?.Tiktok?.age_group?.split(","),
        heading: preset_data?.Tiktok?.heading,
        dayparsingString: preset_data?.Tiktok?.dayparting_list,
        dayparsingOffset: preset_data?.Tiktok?.dayparting_ofset,
        profileEmail: "",
        firstName: "",
        lastName: "",
        adAccountName: "",
      };
      setTiktok(tiktokData);
    }

    if (preset_data?.Snapchat) {
      const snapchatData: any = {
        advertiserAccount: preset_data?.Snapchat?.advertiser_id,
        existingAccount: preset_data?.Snapchat?.campaign_id,
        profile: preset_data?.Snapchat?.profile_id,
        newAccount: preset_data?.Snapchat?.campaign_name,
        activeButton: preset_data?.Snapchat?.campaign_id ? "Existing" : "New",
        autoGenerateName: preset_data?.Snapchat?.is_auto_generate_campaign,
        customAudiences: preset_data?.Snapchat?.is_custom_audience,
        selectedAudience: preset_data?.Snapchat?.custom_audience, // data change,
        maximumCreative: preset_data?.Snapchat?.maxcreatives,
        interest: preset_data?.Snapchat?.interests, // data change,
        caption: preset_data?.Snapchat?.caption,
        accelerateSpending: preset_data?.Snapchat?.accelerated_spend,
        pixel: preset_data?.Snapchat?.pixel_id,
        optimizeEvent: preset_data?.Snapchat?.event_type,
        disableCampaignName: "",
        ageGroup: preset_data?.Snapchat?.age_group?.split(","),
        heading: preset_data?.Snapchat?.heading,
        profileEmail: "",
        firstName: "",
        lastName: "",
        adAccountName: "",
      };
      setSnapchat(snapchatData);
    }

    if (preset_data?.Facebook) {
      const facebookData: any = {
        advantage: true,
        advertiserAccount: preset_data?.Facebook?.advertiser_id,
        existingAccount: preset_data?.Facebook?.campaign_id,
        profile: preset_data?.Facebook?.profile_id,
        newAccount: preset_data?.Facebook?.campaign_name,
        activeButton: preset_data?.Facebook?.campaign_id ? "Existing" : "New",
        autoGenerateName: preset_data?.Facebook?.is_auto_generate_campaign,
        customAudiences: preset_data?.Facebook?.is_custom_audience,
        selectedAudience: preset_data?.Facebook?.custom_audience, // data change,
        maximumCreative: preset_data?.Facebook?.maxcreatives,
        interest: preset_data?.Facebook?.interests, // data change,
        caption: preset_data?.Facebook?.caption,
        accelerateSpending: preset_data?.Facebook?.accelerated_spend,
        pixel: preset_data?.Facebook?.pixel_id,
        optimizeEvent: preset_data?.Facebook?.event_type,
        disableCampaignName: "",
        ageGroup: preset_data?.Facebook?.age_group?.split(","),
        facebookPlacement: [],
        instagramPlacement: [],
        heading: preset_data?.Facebook?.heading,
        facebookPage: preset_data?.Facebook?.facebook_pages,
        instagramAccount: preset_data?.Facebook?.instagram_id,
        profileEmail: "",
        firstName: "",
        lastName: "",
        adAccountName: "",
      };
      setFacebook(facebookData);
    }
  };

  const project = () => {
    switch (step) {
      case 0:
        return (
          <PlatformStep
            onForwardButtonClick={handledForwardButtonClick}
            onClearDraftClick={handledClearDraftData}
            availablePlatform={availablePlatform}
          />
        );
      case 1:
        return (
          <TimesaverStep
            onForwardButtonClick={handledForwardButtonClick}
            onBackwordButtonClick={handledBackwordButtonClick}
            onClearDraftClick={handledClearDraftData}
            state={state}
            setState={setState}
            setOpen={setOpen}
            setTiktok={setTiktok}
            setCampaign={setCampaign}
            setFacebook={setFacebook}
            setSnapchat={setSnapchat}
            setSchedulerCreatives={setSchedulerCreatives}
            setStep={setStep}
            handleAutodraftData={handleAutodraftData}
            onReuseCreativesSelect={handledReuseCreativesSelect}
          />
        );
      case 2:
        return (
          <Champaing
            onForwardButtonClick={handledForwardButtonClick}
            onBackwordButtonClick={handledBackwordButtonClick}
            onClearDraftClick={handledClearDraftData}
            campaign={campaign}
            setCampaign={setCampaign}
          />
        );
      case 3:
        return (
          <SettingStep
            onForwardButtonClick={handledForwardButtonClick}
            onBackwordButtonClick={handledBackwordButtonClick}
            onClearDraftClick={handledClearDraftData}
            snapchat={snapchat}
            setSnapchat={setSnapchat}
            tiktok={tiktok}
            setTiktok={setTiktok}
            facebook={facebook}
            setFacebook={setFacebook}
            onNextPlatformClick={() => handleAutodraftData(step)}
          />
        );
      case 4:
        return (
          <CreativesStep
            onBackwordButtonClick={handledBackwordButtonClick}
            onClearDraftClick={handledClearDraftData}
            onBatchNameChange={(event: any) =>
              setState({ ...state, batchName: event?.target?.value })
            }
            schedulerCreatives={schedulerCreatives}
            setSchedulerCreatives={setSchedulerCreatives}
            onAutodraftData={handledCreativesUploadDraftData}
            onReviewPageData={handledReviewPageData}
          />
        );
      case 5:
        return (
          <ReviewStep
            onBackwordButtonClick={handledBackwordButtonClick}
            onClearDraftClick={handledClearDraftData}
            onStartButtonClick={handledStartButtonClick}
            // state={state}
            // setState={setState}
          />
        );
      default:
        return <h1>No project match</h1>;
    }
  };

  const getStepProgress = () => {
    const percentage = ((step + 1) * 100) / steps.length;
    return percentage;
  };

  return isPlatform ? (
    <>
      <Box sx={{ width: "100%", height: "0.5%" }}>
        <LinearProgress />
      </Box>
      <Box className="sync-align-justify-center" sx={{ height: "100%" }}>
        <CircularProgress />
      </Box>
    </>
  ) : (
    <>
      {!isPlatformAvailable ? (
        <>
          <Box
            className="sync-align-justify-center"
            sx={{
              mt: 20,
            }}
          >
            <Typography variant="body2">
              {/* Enable at least one ad account in your integrations */}
              <Link
                to={route_info.market_planner.platform}
                style={{ color: "#FF4D49" }}
              >
                Enable
              </Link>{" "}
              at least one ad account in your integrations
            </Typography>
          </Box>
          <Box className="sync-align-justify-center" sx={{ mt: 5 }}>
            <Box component="img" src={PixelMelonHi} />
          </Box>
        </>
      ) : (
        <>
          {draftLoading ? (
            <>
              <Box sx={{ width: "100%", height: "0.5%" }}>
                <LinearProgress />
              </Box>
              <Box
                className="sync-align-justify-center"
                sx={{
                  height: "100%",
                }}
              >
                <CircularProgress />
              </Box>
            </>
          ) : (
            <AutoschudlerContext.Provider
              value={{
                setStep,
                step,
                campaign,
                setCampaign,
                state,
                setState,
                facebook,
                setFacebook,
                tiktok,
                setTiktok,
                snapchat,
                setSnapchat,
                autoLinkfire,
                setAutoLinkfire,
                availablePlatform,
                setAvailablePlatform,
                availablePlatformTitle,
                setAvailablePlatformTitle,
                schedulerCreatives,
                setSchedulerCreatives,
                getAvailablePlatform,
                handleAutodraftData,
              }}
            >
              <Box>
                {!open && (
                  <Toggler
                    sx={{ zIndex: 1 }}
                    className="customizer-toggler"
                    onClick={() => setOpen(true)}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      sx={{ borderColor: "#FF4D49", color: "#FF4D49" }}
                    >
                      {MEDIA_PLANNER.auto_scheduler.OPEN_HISTORY}
                    </Button>
                  </Toggler>
                )}
                <Box
                  sx={{
                    display: { md: "none", sm: "none", xs: "flex" },
                    flexDirection: "column",
                  }}
                >
                  <Box display={"flex"} flexDirection={"row"}>
                    <Avatar
                      alt={`${step + 1}`}
                      sx={{
                        width: 40,
                        height: 40,
                        background: theme.palette.primary.main,
                        color: "#fff",
                      }}
                      src="/images/avatars/2.png"
                    />
                    <Box columnGap={1} ml={2}>
                      <Typography
                        variant="body1"
                        sx={{ fontSize: "12px", fontWeight: 500 }}
                      >
                        Step {step + 1} / 6
                      </Typography>
                      <Typography variant="h4" sx={{ fontSize: "20px" }}>
                        {steps[step]}
                      </Typography>
                    </Box>
                  </Box>
                  <LinearProgress
                    variant="determinate"
                    value={getStepProgress()}
                    sx={{ height: 3, mt: 3 }}
                  />
                </Box>
                <Box
                  sx={{
                    mb: 2,
                    display: { md: "block", sm: "block", xs: "none" },
                  }}
                >
                  <StepperWrapper sx={{ px: "10%" }}>
                    <Stepper activeStep={step} alternativeLabel>
                      {steps.map((label) => (
                        <Step key={label}>
                          <StepLabel StepIconComponent={StepperCustomDot}>
                            {label}
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </StepperWrapper>
                </Box>
                <Box>{project()}</Box>
                <Box
                  className="sync-justify-end"
                  sx={{
                    width: "100%",
                    mt: 10,
                  }}
                ></Box>
                {open && (
                  <SwipeableDrawer
                    anchor="right"
                    open={open}
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}
                    swipeAreaWidth={56}
                    disableSwipeToOpen={false}
                    ModalProps={{
                      keepMounted: true,
                    }}
                    BackdropProps={{ invisible: true }}
                  >
                    <SchedulerHisrotyDrawer
                      setOpen={setOpen}
                      setStep={setStep}
                      onReuseCreativesSelect={handledReuseCreativesSelect}
                    />
                  </SwipeableDrawer>
                )}
              </Box>
            </AutoschudlerContext.Provider>
          )}
        </>
      )}
      {isDraftDelete && (
        <ClearDraftConfirmation
          isOpen={isDraftDelete}
          onClickConfirm={handleConfirmClick}
          onClickClose={handledClearDraftData}
          confirmLoading={confirmLoading}
        />
      )}
    </>
  );
}
