import React, { useEffect } from "react";
import { styled } from "@mui/material/styles";
import MuiFormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";
import LogoImage from "components/logo/logo";
import EyeOutline from "mdi-material-ui/EyeOutline";
import EyeOffOutline from "mdi-material-ui/EyeOffOutline";
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  TextField,
  Typography,
  Link,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import Validations from "helper/validations";
import themeConfig from "config/themeConfig";
import ScreenLayout from "components/screenLayout/screen-layout";
import { login } from "store/Thunk/authThunk";
import { useAppDispatch, useAppSelector } from "store/store";
import { LoadingButton } from "@mui/lab";
import { AuthLoading } from "store/Slice/AuthSlice";
import config from "config/config";

interface FormValidation {
  password?: string;
  email?: string;
}

interface State {
  email: string;
  password: string;
  showPassword: boolean;
}

const FormControlLabel = styled(MuiFormControlLabel)<FormControlLabelProps>(
  ({ theme }) => ({
    "& .MuiFormControlLabel-label": {
      fontSize: "0.875rem",
      color: theme.palette.text.secondary,
    },
  })
);

const Login = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { authLoading } = useAppSelector((state) => state.authReducer);
  const [values, setValues] = React.useState({
    email: "",
    password: "",
    showPassword: false,
  });
  const [formError, setFormError] = React.useState<FormValidation>();
  const [loading, setLoading] = React.useState<boolean>();

  useEffect(() => {
    setLoading(authLoading);
  }, [authLoading]);

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    let _request: any = {
      email: values.email.trim(),
      password: values.password.trim(),
    };

    let allError = Validations.validateLoginForm(_request);
    if (
      Object.entries(allError).length === 0 &&
      allError.constructor === Object
    ) {
      dispatch(AuthLoading(true));
      dispatch(login({ _request, navigate }));
    } else {
      setFormError(allError);
    }
  };

  return (
    <ScreenLayout>
      <Box
        className="sync-align-justify-center"
        sx={{
          mb: 8,
        }}
      >
        <LogoImage />
      </Box>
      <Box sx={{ mb: 6 }}>
        <Typography
          variant="h5"
          sx={{ mb: 1.5, fontWeight: 600, letterSpacing: "0.18px" }}
        >
          {`Welcome to ${themeConfig.templateName}! 👋🏻`}
        </Typography>
        <Typography variant="body2">
          Sign in to start scheduling content on multiple platforms
        </Typography>
      </Box>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <TextField
          fullWidth
          id="email"
          sx={{ mb: 4 }}
          placeholder="Email"
          label="Email"
          onChange={handleChange("email")}
          error={formError && formError.email ? true : false}
          helperText={formError && formError.email}
        />

        <FormControl fullWidth>
          <InputLabel htmlFor="auth-login-password">Password</InputLabel>
          <OutlinedInput
            label="Password"
            value={values.password}
            id="auth-login-password"
            onChange={handleChange("password")}
            type={values.showPassword ? "text" : "password"}
            placeholder="Password"
            error={formError && formError.password ? true : false}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  edge="end"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  aria-label="toggle password visibility"
                >
                  {values.showPassword ? <EyeOutline /> : <EyeOffOutline />}
                </IconButton>
              </InputAdornment>
            }
          />
          {formError && formError.password && (
            <FormHelperText error id="password-error">
              {formError.password}
            </FormHelperText>
          )}
        </FormControl>
        <Box
          className="sync-align-center"
          sx={{
            mb: 4,
            flexWrap: "wrap",
            justifyContent: "space-between",
          }}
        >
          <FormControlLabel
            label="Remember Me"
            control={<Checkbox />}
            sx={{ "& .MuiFormControlLabel-label": { color: "text.primary" } }}
          />
          <Link onClick={() => navigate("/forgot-password")}>
            <Typography
              component={Link}
              variant="body2"
              sx={{ color: "primary.main" }}
            >
              Forgot Password?
            </Typography>
          </Link>
        </Box>

        <LoadingButton
          fullWidth
          size="large"
          type="submit"
          loading={loading}
          variant="contained"
          disabled={loading}
          sx={{ mb: 7 }}
        >
          Login
        </LoadingButton>
        <Box
          className="sync-align-justify-center"
          sx={{
            flexWrap: "wrap",
          }}
        >
          <Typography sx={{ mr: 2, color: "text.secondary" }}>
            New here?
          </Typography>
          <Typography>
            <Link onClick={() => navigate("/register")}>
              <Typography component={Link} sx={{ color: "primary.main" }}>
                Create an account
              </Typography>
            </Link>
          </Typography>
        </Box>
        <Divider sx={{ mt: 5, mb: 7.5, "& .MuiDivider-wrapper": { px: 4 } }}>
          or
        </Divider>
        <Box className="sync-align-justify-center">
          <Link href={config.FACEBOOK_AUTH_URL}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="10"
              height="18"
              viewBox="0 0 10 18"
              fill="none"
              style={{ margin: "15px" }}
            >
              <g clipPath="url(#clip0_2_8)">
                <path
                  d="M6.66546 17.9972V9.80115H9.43046L9.84146 6.59215H6.66546V4.54815C6.66546 3.62215 6.92346 2.98815 8.25246 2.98815H9.93646V0.12715C9.11746 0.03915 8.29346 -0.00285004 7.46946 0.000149963C5.02546 0.000149963 3.34746 1.49215 3.34746 4.23115V6.58615H0.600464V9.79515H3.35346V17.9972H6.66546Z"
                  fill="#497CE2"
                />
              </g>
              <defs>
                <clipPath id="clip0_2_8">
                  <rect width="10" height="18" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Link>
          <Link href={config.GOOGLE_AUTH_URL}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="19"
              height="18"
              viewBox="0 0 19 18"
              fill="none"
              style={{ margin: "15px" }}
            >
              <g clipPath="url(#clip0_2_10)">
                <path
                  d="M18.1975 7.37358H9.87052V10.8246H14.6625C14.2165 13.0176 12.3495 14.2776 9.87052 14.2776C6.94752 14.2776 4.59152 11.9216 4.59152 8.99757C4.59152 6.07457 6.94752 3.71858 9.87052 3.71858C11.1295 3.71858 12.2675 4.16558 13.1605 4.89657L15.7605 2.29758C14.1765 0.916575 12.1455 0.0645752 9.87052 0.0645752C4.91652 0.0645752 0.936523 4.04358 0.936523 8.99858C0.936523 13.9536 4.91552 17.9326 9.87052 17.9326C14.3375 17.9326 18.3995 14.6836 18.3995 8.99858C18.3995 8.47058 18.3185 7.90158 18.1975 7.37358Z"
                  fill="#DB4437"
                />
              </g>
              <defs>
                <clipPath id="clip0_2_10">
                  <rect width="19" height="18" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </Link>
        </Box>
      </form>
    </ScreenLayout>
  );
};

export default Login;
