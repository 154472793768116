// ** MUI Imports
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";

interface Props {
  isOpen?: any;
  onCancelClick?: any;
  onConfirmClick?: any;
}

export default function ClearDraftConfirm({
  isOpen,
  onCancelClick,
  onConfirmClick,
}: Props) {
  return (
    <Dialog open={isOpen}>
      <DialogTitle className="detail-dialog-title" sx={{ pb: 0 }}>
        Alert Clear Draft
      </DialogTitle>

      <DialogContent>
        <Typography>Are you sure you want to delete this draft?</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelClick}>Cancel</Button>
        <Button color="error" onClick={() => onConfirmClick()} autoFocus>
          Delete Draft
        </Button>
      </DialogActions>
    </Dialog>
  );
}
