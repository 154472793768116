import {
  Box,
  Typography,
  FormControl,
  FormControlLabel,
  Button,
  styled,
  BoxProps,
  Link,
  RadioGroup,
  Radio,
  FormHelperText,
  useTheme,
} from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import LogoImage from "components/logo/logo";
import ScreenLayout from "components/screenLayout/screen-layout";

const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  maxWidth: '400px',
  [theme.breakpoints.down('md')]: {
    maxWidth: 400
  } 
}))

export default function CreateAccount() {
  const navigate = useNavigate();
  const [value, setValue] = useState('');
  const [helperText, setHelperText] = useState(false);
  const [isMusicSelect, setIsMusicSelect] =useState(false);
  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
    if((event.target as HTMLInputElement).value === 'Music'){
      setIsMusicSelect(true);
    }
    else{
      setIsMusicSelect(false);
    }
  };
  const handledSubmit = (event: any) => {
    
    event.preventDefault();
    if(value === ''){
      setHelperText(true)
    }
    else{
      navigate('/')
    }
  }
  const theme = useTheme()
  return (
    <ScreenLayout>
      <BoxWrapper>
        <Box
        className="sync-align-justify-center"
          sx={{
            mb: 8
          }}
        >
          <LogoImage />
        </Box>
        <Box sx={{ mb: 4 }}>
          <Typography
            variant="h5"
            sx={{ mb: 1.5, fontWeight: 600, letterSpacing: "0.18px" }}
          >{`Let us know your business 💼`}</Typography>
          <Typography variant="body2">
            Get access to essential functionalities to your company
          </Typography>
        </Box>

        <form noValidate onSubmit={handledSubmit} autoComplete="off">
          <FormControl>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              value={value}
              name="radio-buttons-group"
              onChange={handleRadioChange}
              sx={{
                mt: 4,
                mb: 4,
              }}
            >
              <FormControlLabel
                value="Music"
                control={<Radio />}
                label="Music Label"
              />

              {isMusicSelect && (
                <FormHelperText sx={{ color: "primary.main" }}>

                  <Typography variant="subtitle2" sx={{mb:1}}>
                    Integrations with Spotify & Linkfire
                  </Typography>
                  <Typography variant="subtitle2" sx={{mb:1}}>
                    Another Unique Selling
                    Point
                  </Typography>
                  <Typography variant="subtitle2" sx={{mb:1}}>
                     And another one
                  </Typography>
                </FormHelperText>
              )}

              <FormControlLabel
                value="Other1"
                control={<Radio />}
                label="Other"
              />
              <FormControlLabel
                value="Other2"
                control={<Radio />}
                label="Other"
              />
            </RadioGroup>

            {helperText && (
              <FormHelperText sx={{ color: "primary.main" }}>
                Please Select One Of Above
              </FormHelperText>
            )}
          </FormControl>

          <Button
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            sx={{ my: 4 }}
            style={{ backgroundColor: "primary.main" }}
          >
            CREATE ACCOUNT
          </Button>
          <Box
          className="sync-align-justify-center"
            sx={{
              flexWrap: "wrap",
            }}
          >
            <Typography>
              <Link onClick={() => navigate(-1)} style={{ textDecoration: "none" }}>
                <Typography className="bottom-text" sx={{ color: "primary.main" }}>
                  Go back
                </Typography>
              </Link>
            </Typography>
          </Box>
        </form>
      </BoxWrapper>
    </ScreenLayout>
  );
}
