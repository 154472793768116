import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { PlatformIntialState } from 'store/interface';

const intialState: PlatformIntialState = {
	snapchat: [],
	facebook: [],
	tiktok: [],
	google: [],
	spootifyScrapper: [],
	spootifyConnection: [],
	linkfireScrapperInfo: [],
	linkfireScrapperConnection: [],
	linkfireGeneratorInfo: [],
	linkfireGeneratorConnection: [],
	scraperGroupData: [],
	linkfireGeneratorLoginData: [],
	latestProfileData: [],
	fbApiProgress: {
		loading: false,
		success: false,
		message: ""
	},
	ttApiProgress: {
		loading: false,
		success: false,
		message: ""
	},
	scApiProgress: {
		loading: false,
		success: false,
		message: ""
	},
};

const PlatformSlice = createSlice({
	name: 'admin_client',
	initialState: intialState,
	reducers: {
        SnapchatData: (
			state: Draft<PlatformIntialState>,
			action: PayloadAction<[]>
		) => {
			state.snapchat = action.payload;
		},
		FacebookData: (
			state: Draft<PlatformIntialState>,
			action: PayloadAction<[]>
		) => {
			state.facebook = action.payload;
		},
		TiktokData: (
			state: Draft<PlatformIntialState>,
			action: PayloadAction<[]>
		) => {
			state.tiktok = action.payload;
		},
		GoogleData: (
			state: Draft<PlatformIntialState>,
			action: PayloadAction<[]>
		) => {
			state.google = action.payload;
		},
		SpootifyData: (
			state: Draft<PlatformIntialState>,
			action: PayloadAction<[]>
			) => {
			state.spootifyScrapper = action.payload;
		},
		SpootifyConnection: (
			state: Draft<PlatformIntialState>,
			action: PayloadAction<[]>
			) => {
			state.spootifyConnection = action.payload;
		},
		LinkfireScrapperInfo: (
			state: Draft<PlatformIntialState>,
			action: PayloadAction<[]>
			) => {
			state.linkfireScrapperInfo = action.payload;
		},
		LinkfireScrapperConnection: (
			state: Draft<PlatformIntialState>,
			action: PayloadAction<[]>
			) => {
			state.linkfireScrapperConnection = action.payload;
		},
		LinkfireGeneratorInfo: (
			state: Draft<PlatformIntialState>,
			action: PayloadAction<[]>
			) => {
			state.linkfireGeneratorInfo = action.payload;
		},
		LinkfireGeneratorConnection: (
			state: Draft<PlatformIntialState>,
			action: PayloadAction<[]>
			) => {
			state.linkfireGeneratorConnection = action.payload;
		},
		ScrapperGroupData: (
			state: Draft<PlatformIntialState>,
			action: PayloadAction<[]>
			) => {
			state.scraperGroupData = action.payload;
		},

		LinkfireGeneratorLoginData: (
			state: Draft<PlatformIntialState>,
			action: PayloadAction<[]>
			) => {
			state.linkfireGeneratorLoginData = action.payload;
		},
		LatestProfileData: (
			state: Draft<PlatformIntialState>,
			action: PayloadAction<[]>
			) => {
			state.latestProfileData = action.payload;
		},
		setFBAPIProgress: (
			state: Draft<PlatformIntialState>,
			action: PayloadAction<any>
		) => {
			state.fbApiProgress = action.payload;
		},
		setSCAPIProgress: (
			state: Draft<PlatformIntialState>,
			action: PayloadAction<any>
		) => {
			state.scApiProgress = action.payload;
		},
		setTTAPIProgress: (
			state: Draft<PlatformIntialState>,
			action: PayloadAction<any>
		) => {
			state.ttApiProgress = action.payload;
		},
	}
});


export const {
	SnapchatData,
	FacebookData,
	TiktokData,
	GoogleData,
	SpootifyData,
	SpootifyConnection,
	LinkfireScrapperInfo,
	LinkfireScrapperConnection,
	LinkfireGeneratorInfo,
	LinkfireGeneratorConnection,
	ScrapperGroupData,
	LinkfireGeneratorLoginData,
	LatestProfileData,
	setFBAPIProgress,
	setSCAPIProgress,
	setTTAPIProgress
} = PlatformSlice.actions;

export default PlatformSlice.reducer;