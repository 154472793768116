import { AuthRouteProps } from "common/interface/interface";
import { Navigate } from "react-router-dom";

const AuthRoute = ({ children, path }: AuthRouteProps) => {
  const isAuthenticated = localStorage.getItem("userData");
  if (isAuthenticated) {
    return <Navigate to={"/overview"} replace />;
  }
  return children;
};

export default AuthRoute;
