// ** React Imports
import { Fragment, useEffect, useState } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Typography, { TypographyProps } from "@mui/material/Typography";

// ** Icons Imports
import Close from "mdi-material-ui/Close";
import FileDocumentOutline from "mdi-material-ui/FileDocumentOutline";

// ** Third Party Components
import toast from "react-hot-toast";
import { useDropzone } from "react-dropzone";
import { MARKET_PLACE } from "@core/constants/title-constants";

interface FileProp {
  name: string;
  type: string;
  size: number;
}

// Styled component for the upload image inside the dropzone area
const Img = styled("img")(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    marginRight: theme.spacing(10),
  },
  [theme.breakpoints.down("md")]: {
    marginBottom: theme.spacing(4),
  },
  [theme.breakpoints.down("sm")]: {
    width: 250,
  },
}));

// Styled component for the heading inside the dropzone area
const HeadingTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(4),
  },
}));

const handleSizeIssue = () => {};

const FileUploaderRestrictions = ({
  files,
  setFiles,
  handledAcceptedFiles,
  error,
  formError,
  recommendedSize,
}: any) => {
  const [thumbnailError, setThumbnailError] = useState<any>("");

  useEffect(() => {
    setThumbnailError(formError.thumbnail || "");
  }, [formError]);

  const handleOnDrop = (acceptedFiles: any) => {
    if (acceptedFiles && acceptedFiles.length) {
      const file = acceptedFiles[0];

      const reader = new FileReader();
      reader.onload = (event: any) => {
        const img: any = new Image();
        img.onload = () => {
          const aspectRatio = img.width / img.height;

          // const is16By9 = Math.abs(aspectRatio - 16 / 9) <= 0.05; // Tolerance of 5%
          let isRecommendedSize = true;
          if (recommendedSize === "16:9") {
            isRecommendedSize = aspectRatio === 16 / 9;
          } else if (recommendedSize === "4:1") {
            isRecommendedSize = aspectRatio === 4 / 1;
          }

          if (file.size > 2000000) {
            setThumbnailError(
              "You can only upload single image & maximum size of 2 MB."
            );
          } else {
            if (isRecommendedSize) {
              // Image has recommended 16:9 aspect ratio
              handledAcceptedFiles(acceptedFiles);
              setFiles(acceptedFiles[0]);
            } else {
              // Image does not have recommended 16:9 aspect ratio
              setThumbnailError(
                `Your image does not have recommended ratio.(Expected: ${recommendedSize})`
              );
            }
          }
        };
        img.src = event.target.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    // maxSize: 2000000,
    accept: {
      "image/*": [".png", ".jpg", ".jpeg", ".gif"],
    },
    onDrop: handleOnDrop,
    onDragOver: (isDragActive) => {
      console.log("isDragActive", isDragActive);
    },
    onDropRejected: () => {
      handleSizeIssue();
      // toast.error("maximum size of 2 MB.", {
      //   duration: 2000,
      // });
    },
  });

  return (
    <Fragment>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <Box
          sx={{
            display: "flex",
            flexDirection: ["column", "column", "row"],
            alignItems: "center",
            justifyContent: "space-between",
            border: 1,
            borderRadius: "8px",
            borderColor: thumbnailError ? "red" : "rgba(76, 78, 100, 0.38)",
            padding: "25px",
            borderStyle: "dashed",
            cursor: "pointer",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              textAlign: ["center", "center", "inherit"],
            }}
          >
            <HeadingTypography
              variant="subtitle1"
              color="textSecondary"
              sx={{ mb: 0 }}
            >
              {MARKET_PLACE.REQUEST_CONTENT.DROP_FILES_HERE}
            </HeadingTypography>
            <Typography variant="body1" color="textSecondary">
              {MARKET_PLACE.REQUEST_CONTENT.RECOMMENDED_SIZE}
              Recommended size: {recommendedSize}
            </Typography>
            <Typography color="textSecondary">{MARKET_PLACE.REQUEST_CONTENT.FILE_SIZE}</Typography>
          </Box>
        </Box>
        {thumbnailError && (
          <Typography
            sx={{ fontWeight: "400", fontSize: "14px" }}
            color="error"
          >
            {thumbnailError}
          </Typography>
        )}
      </div>
    </Fragment>
  );
};

export default FileUploaderRestrictions;
