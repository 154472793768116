import { Box, Radio, Typography } from "@mui/material";

const CustomRadioButton = (props: any) => {
  const {
    radioBtnDetail,
    selectedValue,
    handleOnChange,
    name = "radio-buttons",
  } = props;
  // const handleChange = (value: string) => {
  //   handleOnChange(value);
  // };
  return (
    <Box
      className="custom-radio-btn"
      component="div"
      onClick={() => handleOnChange(name, radioBtnDetail.value)}
    >
      <Box
      className="custom-radio-box"
        sx={{
          border: 1,
          borderColor: (theme) =>
            radioBtnDetail?.value === selectedValue
              ? theme.palette.primary.main
              : "#E9E9EC",
        }}
      >
        <Box sx={{ flexGrow: 1 }}>
          <img src={radioBtnDetail?.img} />
        </Box>
        <Box sx={{ flexGrow: 1, mt: 2 }}>
          <Typography>{radioBtnDetail?.name}</Typography>
        </Box>
        <Radio
          sx={{ flexGrow: 1 }}
          checked={radioBtnDetail?.value === selectedValue ? true : false}
          value={selectedValue}
          name={name}
          inputProps={{ "aria-label": "A" }}
        />
      </Box>
    </Box>
  );
};

export default CustomRadioButton;
