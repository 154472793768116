// ** MUI Imports
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box, { BoxProps } from "@mui/material/Box";
import CustomChip from "@core/components/mui/chip";

import ContactDialog from "pages/account-settings/dialog/ContactDialog";

// ** Icons Imports
import CircleOutline from "mdi-material-ui/CircleOutline";

import { PricingIllustration1, PricingIllustration3 } from "assets";
import { useState } from "react";

// ** Styled Component for the wrapper of whole component
const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  minWidth: "260px",
  position: "relative",
  padding: theme.spacing(5, 5, 5),
  borderRadius: theme.shape.borderRadius,
}));

// ** Styled Component for the wrapper of all the features of a plan
const BoxFeature = styled(Box)<BoxProps>(({ theme }) => ({
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(5),
  "& > :not(:first-of-type)": {
    marginTop: theme.spacing(4),
  },
}));

const pricingPlans = {
  freePlan: {
    imgWidth: 100,
    title: "Basic",
    imgHeight: 100,
    monthlyPrice: 0,
    currentPlan: true,
    popularPlan: false,
    daysLeft: 3,
    subtitle: "A simple start for everyone",
    imgSrc: PricingIllustration1,
    amountTitle: "FREE",
    yearlyPlan: {
      perMonth: 0,
      totalAnnual: 0,
    },
    planBenefits: [
      "Unlimited ad scheduling",
      "Ad manager integrations",
      "Using and creating presets",
      "Spotify integration",
      "Linkfire integration",
    ],
  },
  tierPlan: {
    planBenefits: [
      "Unlimited ad scheduling",
      "Ad manager integrations",
      "Using and creating presets",
      "Spotify integration",
      "Linkfire integration",
    ],
  },
  enterprisePlan: {
    planBenefits: ["Exclusive support"],
  },
};

const EnterpriseLayout = (props: any) => {
  // ** Props
  const { currentPlan, isSelected } = props;

  const [openContactDialog, setOpenContactDialog] = useState<boolean>(false);

  const handleContactDialog = () => {
    setOpenContactDialog(!openContactDialog);
  };

  const renderFeatures = (arr: any) => {
    return arr.map((item: string, index: number) => (
      <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
        <CircleOutline
          sx={{ mr: 2.5, fontSize: "0.875rem", color: "text.secondary" }}
        />
        <Typography variant="body2">{item}</Typography>
      </Box>
    ));
  };

  return (
    <Box className={isSelected ? "gradient-border-box" : "simple-border-box"}>
      <BoxWrapper>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 10 }}>
          <img src={PricingIllustration3} width={100} height={100} />
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h5" sx={{ mb: 1.5 }}>
            Enterprise
          </Typography>
          <Typography variant="body2">Solution for big spenders</Typography>
          <Box sx={{ mt: 5, mb: 10, position: "relative" }}>
            <Typography
              variant="h3"
              sx={{
                fontWeight: 500,
                color: "#666CFF",
                lineHeight: 1.17,
              }}
            >
              Let's talk
            </Typography>
          </Box>
        </Box>
        <Box textAlign="start">
          <Typography
            variant="body2"
            sx={{
              fontSize: "14px",
              fontWeight: 700,
            }}
          >
            All features plus
          </Typography>
          <BoxFeature>
            {renderFeatures(pricingPlans.enterprisePlan.planBenefits)}
          </BoxFeature>
        </Box>
        <Button
          fullWidth
          onClick={handleContactDialog}
          variant={isSelected ? "contained" : "outlined"}
          className={isSelected ? "gradient-btn" : ""}
        >
          Contact
        </Button>
      </BoxWrapper>
      {openContactDialog && (
        <ContactDialog
          open={openContactDialog}
          handleClose={handleContactDialog}
        />
      )}
    </Box>
  );
};

export default EnterpriseLayout;
