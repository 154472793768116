// ** MUI Imports
import { Box, IconButton, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import Typography, { TypographyProps } from "@mui/material/Typography";

// ** Icons Imports
import Close from "mdi-material-ui/Close";
import FileDocumentOutline from "mdi-material-ui/FileDocumentOutline";

// ** Third Party Components
import { MARKET_PLACE } from "@core/constants/title-constants";
import { useDropzone } from "react-dropzone";
import { PixelMelonSitting } from "assets";

// Styled component for the heading inside the dropzone area
const HeadingTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  marginBottom: theme.spacing(5),
  [theme.breakpoints.up("xs")]: {
  marginLeft: theme.spacing(3),
  },
  [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(4),
  },
}));

const FileUploaderRestrictions = ({
  files,
  setFiles,
  onClickSubmit,
  handleRemoveFile,
}: any) => {
  const { getRootProps, getInputProps } = useDropzone({
    maxSize: 32000000,
    accept: {
      "video/*": [".mp4", ".wav", ".mov"],
    },
    onDrop: async (acceptedFiles: any) => {
      setFiles(acceptedFiles[0]);
    },
  });

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      {files ? (
        <>
          <Box className="file-details-block">
            <Box className="file-preview">
              <FileDocumentOutline />
            </Box>
            <Typography className="file-name" noWrap={true}>
              {files?.name}
            </Typography>
            <Typography className="file-size" variant="body2">
              {Math.round(files.size / 100) / 10 > 1000
                ? `${(Math.round(files.size / 100) / 10000).toFixed(1)} mb`
                : `${(Math.round(files.size / 100) / 10).toFixed(1)} kb`}
            </Typography>

            <IconButton onClick={() => handleRemoveFile()}>
              <Close fontSize="small" />
            </IconButton>
          </Box>
          <Button
            sx={{ mt: "30px", mb: "20px" }}
            variant="contained"
            onClick={onClickSubmit}
          >
            Submit
          </Button>
        </>
      ) : (
        <Box {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <Box>
            <Box
              className="upload-img"
              component="img"
              alt="Upload img"
              src={PixelMelonSitting}
            />
            <Box>
              <HeadingTypography variant="h5">
                {MARKET_PLACE.CREATE_CONTENT.DROP_FILES_HERE}
              </HeadingTypography>
              <Typography color="textSecondary">
                {MARKET_PLACE.CREATE_CONTENT.ONLY_VIDEO_ALLOWED}
              </Typography>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default FileUploaderRestrictions;
