import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';
import { AuthIntialState,  ResponseData } from 'store/interface';

const intialState: AuthIntialState = {
	loginData: {},
	authLoading: false,
	screenLoading: false,
	buttonLoading: false
};

const AuthSlice = createSlice({
	name: 'admin_client',
	initialState: intialState,
	reducers: {
        AuthData: (
			state: Draft<AuthIntialState>,
			action: PayloadAction<ResponseData>
		) => {
			state.loginData = action.payload;
		},
		AuthLoading: (
			state: Draft<AuthIntialState>,
			action: PayloadAction<boolean>
		) => {
			state.authLoading = action.payload
		},
		ScreenLoading: (
			state: Draft<AuthIntialState>,
			action: PayloadAction<boolean>
		) => {
			state.screenLoading = action.payload
		},
		ButtonLoading: (
			state: Draft<AuthIntialState>,
			action: PayloadAction<boolean>
		) => {
			state.buttonLoading = action.payload
		}
	}
});


export const {
	AuthData,
	AuthLoading,
	ScreenLoading,
	ButtonLoading
} = AuthSlice.actions;

export default AuthSlice.reducer;