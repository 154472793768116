// ** MUI imports
import { styled } from '@mui/material/styles'
import Box, { BoxProps } from '@mui/material/Box'

// ** Util Imports
import { hexToRGBA } from '../../utils/hex-to-rgba'

const StepperWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== "jobMilestone"
})<any>(({ theme, jobMilestone }) => ({
  [theme.breakpoints.down('md')]: {
    '& .MuiStepper-horizontal:not(.MuiStepper-alternativeLabel)': {
      flexDirection: 'column',
      alignItems: 'flex-start'
    }
  },
  '& .MuiStep-root': {
    '& .step-label': {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    '& .step-number': {
      fontWeight: 600,
      letterSpacing: 0.25,
      fontSize: '2.125rem',
      marginRight: theme.spacing(2.5),
      color: theme.palette.text.primary
    },
    '& .step-title': {
      fontWeight: 500,
      fontSize: '0.875rem',
      letterSpacing: '0.1px',
      color: theme.palette.text.primary
    },
    '& .step-subtitle': {
      fontWeight: 400,
      fontSize: '0.75rem',
      letterSpacing: '0.4px',
      color: theme.palette.text.secondary
    },
    '& .MuiStepLabel-root.Mui-disabled': {
      '& .step-number': {
        color: theme.palette.text.disabled
      }
    },
    '& .MuiStepLabel-root': {
      '& .Mui-active': {
        '& .MuiBox-root': {
          borderColor: jobMilestone ? '#67CB24' : theme.palette.primary.main
        }
      }
    },
    '& .Mui-error': {
      '& .MuiStepLabel-labelContainer, & .step-number, & .step-title, & .step-subtitle': {
        color: theme.palette.error.main
      }
    }
  },
  '& .MuiStepConnector-root': {
    '& .MuiStepConnector-line': {
      borderWidth: 3,
      borderRadius: 3,
      color: jobMilestone ? '#67CB24' : theme.palette.primary.main
    },
    '&.Mui-active, &.Mui-completed': {
      '& .MuiStepConnector-line': {
        borderColor: jobMilestone ? '#67CB24' : theme.palette.primary.main
      }

    },
    '&.Mui-disabled .MuiStepConnector-line': {
      borderColor: jobMilestone ? hexToRGBA("#67CB24", 0.12) : hexToRGBA(theme.palette.primary.main, 0.12)
    },
    bottom: 10
  },
  '& .MuiStepper-alternativeLabel': {
    '& .MuiSvgIcon-root': {
      color: jobMilestone ? '#67CB24' : theme.palette.primary.main
    },
    '& .MuiStepConnector-root': {
      // top: 10,
      // top: -3
    },
    '& .MuiStepLabel-labelContainer': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      '& > * + *': {
        marginTop: theme.spacing(1)
      }
    }
  },
  '& .MuiStepper-vertical': {
    '& .MuiStep-root': {
      '& .step-label': {
        justifyContent: 'flex-start'
      },
      '& .MuiStepContent-root': {
        borderWidth: 3,
        marginLeft: theme.spacing(2.25),
        borderColor: jobMilestone ? '#67CB24' : theme.palette.primary.main
      },
      '& .button-wrapper': {
        marginTop: theme.spacing(4)
      },
      '&.active + .MuiStepConnector-root .MuiStepConnector-line': {
        borderColor: jobMilestone ? '#67CB24' : theme.palette.primary.main
      }
    },
    '& .MuiStepConnector-root': {
      marginLeft: theme.spacing(2.25),
      '& .MuiStepConnector-line': {
        borderRadius: 0
      }
    }
  }
}))

export default StepperWrapper
