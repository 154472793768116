// ** Reacct Imports
import React, { useEffect } from "react";

// ** MUI Imports
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
  styled,
  BoxProps,
  TypographyProps,
  Link,
  FormHelperText,
} from "@mui/material";

// ** Redux
import { resetPassword, verifyResetPasswordToken } from "store/Thunk/authThunk";
import { useAppDispatch, useAppSelector } from "store/store";
import { AuthLoading, ScreenLoading } from "store/Slice/AuthSlice";

// ** Third Party Library
import { useLocation, useNavigate, useParams } from "react-router-dom";

// ** Custom Components
import LogoImage from "components/logo/logo";
import ScreenLayout from "components/screenLayout/screen-layout";

// ** Helper
import Validations from "helper/validations";

// ** Icons
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import EyeOutline from "mdi-material-ui/EyeOutline";
import EyeOffOutline from "mdi-material-ui/EyeOffOutline";

interface State {
  password1: string;
  password2: string;
  showPassword: boolean;
  showConfirmPassword: boolean;
}

interface FormValidation {
  password1?: string;
  password2?: string;
}

const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  maxWidth: "400px",
  [theme.breakpoints.down("md")]: {
    maxWidth: 400,
  },
}));

const TypographyStyled = styled(Typography)<TypographyProps>(({ theme }) => ({
  fontWeight: 600,
  letterSpacing: "0.18px",
  marginBottom: theme.spacing(1.5),
  [theme.breakpoints.down("md")]: { marginTop: theme.spacing(8) },
}));

export default function ResetPassword() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();

  const { authLoading, screenLoading } = useAppSelector(
    (state) => state.authReducer
  );

  const token: any = new URLSearchParams(location.search).get("token");
  const userId: any = new URLSearchParams(location.search).get("useridb64");

  const [values, setValues] = React.useState({
    password1: "",
    password2: "",
    showPassword: false,
    showConfirmPassword: false,
  });
  const [formError, setFormError] = React.useState<FormValidation>();
  const [loading, setLoading] = React.useState<boolean>();
  const [screenLoad, setScreenLoad] = React.useState<boolean>(true);

  useEffect(() => {
    setLoading(authLoading);
  }, [authLoading]);

  useEffect(() => {
    setScreenLoad(screenLoading);
  }, [screenLoading]);

  useEffect(() => {
    let _request: any = {
      token: token,
      userId: userId,
    };
    dispatch(ScreenLoading(true));
    dispatch(verifyResetPasswordToken({ _request, navigate }));
  }, []);

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleClickShowConfirmPassword = () => {
    setValues({
      ...values,
      showConfirmPassword: !values.showConfirmPassword,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    let _request: any = {
      password1: values.password1,
      password2: values.password2,
    };
    let allError = Validations.validateResetPasswordForm(_request);

    let _params: any = {
      token: token,
      userId: userId,
    };

    if (
      Object.entries(allError).length === 0 &&
      allError.constructor === Object
    ) {
      dispatch(resetPassword({ _request, _params, navigate }));
    }
    setFormError(allError);
  };

  if (screenLoad) {
    return (
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroudColor: "red",
        }}
      ></Box>
    );
  } else {
    return (
      <ScreenLayout>
        <BoxWrapper>
          <Box
            sx={{
              mb: 8,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <LogoImage />
          </Box>
          <Box sx={{ mb: 4 }}>
            <Typography
              variant="h5"
              sx={{ mb: 1.5, fontWeight: 600, letterSpacing: "0.18px" }}
            >
              Reset your password 🔒
            </Typography>
            <Typography variant="body2">
              Create a new password that is different from your
            </Typography>
            <Typography variant="body2">previous passwords</Typography>
          </Box>

          <form noValidate onSubmit={handleSubmit} autoComplete="off">
            <FormControl fullWidth>
              <InputLabel>New Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password1}
                label="New Password"
                onChange={handleChange("password1")}
                placeholder="New Password"
                error={formError && formError.password1 ? true : false}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showPassword ? <EyeOutline /> : <EyeOffOutline />}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {formError && formError.password1 && (
                <FormHelperText error id="password-error">
                  {formError.password1}
                </FormHelperText>
              )}
            </FormControl>
            <FormControl fullWidth sx={{ mt: 4 }}>
              <InputLabel>Confirm Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password"
                type={values.showConfirmPassword ? "text" : "password"}
                value={values.password2}
                onChange={handleChange("password2")}
                error={formError && formError.password2 ? true : false}
                label="Confirm Password"
                placeholder="Confirm Password"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {values.showConfirmPassword ? (
                        <EyeOutline />
                      ) : (
                        <EyeOffOutline />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
              {formError && formError.password2 && (
                <FormHelperText error id="password-error">
                  {formError.password2}
                </FormHelperText>
              )}
            </FormControl>

            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              sx={{ my: 4 }}
              style={{ backgroundColor: "#FF4D49" }}
            >
              RESET PASSWORD
            </Button>
            <Box
              className="sync-align-justify-center"
              sx={{
                flexWrap: "wrap",
              }}
            >
              <Link
                onClick={() => navigate("/")}
                className="sync-align-justify-center"
                sx={{
                  flexWrap: "wrap",
                }}
                style={{ textDecoration: "none" }}
              >
                <ArrowBackIosIcon
                  style={{ color: "#FF4D49", height: "21px" }}
                />
                <Typography variant="subtitle1" sx={{ color: "#FF4D49" }}>
                  Back to login
                </Typography>
              </Link>
            </Box>
          </form>
        </BoxWrapper>
      </ScreenLayout>
    );
  }
}
