import { useEffect, useState, useCallback } from "react";
import {
  Box,
  Chip,
  ChipProps,
  Grid,
  styled,
  Typography,
  CircularProgress,
  TextField,
} from "@mui/material";
import { debounce } from "@mui/material/utils";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  getMySubmissionsHistory,
  getMySubmissionsSearch,
} from "store/Thunk/createJobThunk";
import ListCard from "components/ListCard";
import ShowMore from "components/ShowMore";
import { PixelMelonFalling } from "assets";

const MChip = styled(Chip)<ChipProps>(({ theme }: any) => ({
  minWidth: "70px",
  maxHeight: "24px",
  marginRight: theme.spacing(2),
}));

export default function MySubmissionHistoryTab(props: any) {
  const { selectedPublishType, submissionTabTitle } = props;
  const dispatch: any = useAppDispatch();
  const {
    loadingState,
    mySubmissionHistoryList,
    myNewSubmissionHistoryList,
    tempMySubmissionList,
  } = useAppSelector((state: any) => state.createJobReducer);

  const [selected, setSelected] = useState("Approved");
  const [page, setPage] = useState(1);
  const [submissionHistoryList, setSubmissionsHistoryList] = useState<any>([]);
  const [searchShow, setSearchShow] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);

  const handleAPICall = (currentPage: any) => {
    let data = {
      approval_status: selected,
      published_content_type: selectedPublishType,
      page: currentPage,
      submissionHistoryList: submissionHistoryList,
    };
    setLoader(true);
    dispatch(getMySubmissionsHistory(data))
      .then((res: any) => {
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
      });
  };

  useEffect(() => {
    handleAPICall(page);
  }, [selected, selectedPublishType]);

  useEffect(() => {
    setSubmissionsHistoryList(mySubmissionHistoryList);
  }, [mySubmissionHistoryList]);

  const handleClick = (event: any) => {
    setSelected(event.target.innerText);
    let data = {
      approval_status: event.target.innerText,
      page: page,
      published_content_type: selectedPublishType,
    };
    dispatch(getMySubmissionsHistory(data));
  };

  const handleSearch = (data: any) => {
    let temp = {
      approval_status: selected,
      published_content_type: selectedPublishType,
      searchTxt: data.target.value,
    };
    dispatch(getMySubmissionsSearch({ data: temp }));
    if (data.target.value) {
      setSearchShow(true);
    } else {
      setSearchShow(false);
    }
  };

  const handleSubmissionHistoryShowMore = () => {
    setPage(page + 1);
    let data = {
      approval_status: selected,
      published_content_type: selectedPublishType,
      page: page + 1,
      mySubmissionList: submissionHistoryList,
    };

    dispatch(getMySubmissionsHistory(data));
    // handleAPICall(page + 1);
  };
  return (
    <Box>
      <Box>
        <Grid spacing={2} container sx={{ alignItems: "center" }}>
          <Grid item md={6} sm={5} xs={12}>
            {myNewSubmissionHistoryList && myNewSubmissionHistoryList?.length
              ? submissionTabTitle.map((item: any) => (
                  <MChip
                    key={item.label}
                    className="category_chip"
                    label={item.label}
                    clickable
                    color="default"
                    onClick={(e: any) => handleClick(e)}
                    variant={
                      selected &&
                      selected.toLocaleLowerCase() ===
                        item.label.toLocaleLowerCase()
                        ? "filled"
                        : "outlined"
                    }
                  />
                ))
              : ""}
          </Grid>
          <Grid item md={6} sm={7} xs={12} sx={{ my: { xs: 3, sm: 0 } }}>
            <Box
              display="flex"
              flexWrap={"wrap"}
              flexDirection={"row"}
              justifyContent={{
                xs: "flex-start",
                sm: "flex-end",
                md: "flex-end",
              }}
              alignItems="center"
              gap={3}
            >
              <TextField
                size="small"
                placeholder="Search submissions..."
                label="Search submissions..."
                onChange={(e: any) => handleSearch(e)}
                sx={{ width: { md: "70%", sm: "70%", xs: "100%" } }}
              />
              {submissionHistoryList && submissionHistoryList?.length ? (
                <ShowMore handleOnClick={handleSubmissionHistoryShowMore} />
              ) : (
                ""
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box mt={4}>
        {loadingState ? (
          <Box className="loader">
            <CircularProgress size={20} />
          </Box>
        ) : (
          <Grid container>
            {searchShow == false &&
            submissionHistoryList &&
            submissionHistoryList?.length
              ? submissionHistoryList?.map((submissionHistoryListData: any) => (
                  <Grid
                    item
                    md={4}
                    sm={6}
                    xs={12}
                    key={submissionHistoryListData.title}
                  >
                    <ListCard
                      cardData={submissionHistoryListData}
                      isArchived={true}
                      isSubmission={true}
                    />
                  </Grid>
                ))
              : !loader &&
                searchShow == false && (
                  <Box className="no-data-found-block">
                    <Box
                      component="img"
                      src={PixelMelonFalling}
                      className="no-data-found-img"
                    />
                    <Typography variant="subtitle2">
                      There are currently no submissions history available for
                      this category. Please check back later.
                    </Typography>
                  </Box>
                )}
            {searchShow && tempMySubmissionList && tempMySubmissionList?.length
              ? tempMySubmissionList?.map((tempMySubmissionListData: any) => (
                  <Grid
                    item
                    md={4}
                    sm={6}
                    xs={12}
                    key={tempMySubmissionListData.title}
                  >
                    <ListCard
                      searchData={tempMySubmissionListData}
                      isArchived={true}
                      isSubmission={true}
                    />
                  </Grid>
                ))
              : !loader &&
                searchShow && (
                  <Box className="no-data-found-block">
                    <Box
                      component="img"
                      src={PixelMelonFalling}
                      className="no-data-found-img"
                    />
                    <Typography variant="subtitle2">
                      There are currently no submissions history available for
                      this category. Please check back later.
                    </Typography>
                  </Box>
                )}
          </Grid>
        )}
      </Box>
    </Box>
  );
}
