export default function SpootifyIcon({active = false}){

    if (active) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <rect width="30" height="30" rx="5" fill="#1ED760"/>
            <g clipPath="url(#clip0_916_13423)">
            <path fillRule="evenodd" clipRule="evenodd" d="M20.9153 13.8652C17.6918 11.951 12.375 11.7749 9.29775 12.7088C8.80361 12.8589 8.28107 12.5799 8.13132 12.0857C7.98158 11.5913 8.2603 11.069 8.75488 10.9188C12.2871 9.84665 18.1591 10.0537 21.87 12.2565C22.3145 12.5203 22.4604 13.0945 22.1969 13.5381C21.9332 13.9826 21.3588 14.1291 20.9153 13.8652ZM20.8097 16.7006C20.5837 17.0675 20.1039 17.1825 19.7374 16.9573C17.05 15.3054 12.9522 14.8269 9.77274 15.792C9.36047 15.9165 8.92494 15.6841 8.79979 15.2725C8.67551 14.8603 8.90815 14.4256 9.31969 14.3002C12.9516 13.1981 17.4667 13.7319 20.5533 15.6287C20.9197 15.8544 21.035 16.3344 20.8097 16.7006ZM19.5862 19.4235C19.4064 19.7181 19.0227 19.8104 18.7291 19.6309C16.3809 18.1957 13.4251 17.8716 9.94427 18.6667C9.60886 18.7435 9.27448 18.5333 9.19807 18.198C9.12121 17.8626 9.33058 17.5282 9.66687 17.4517C13.4761 16.5809 16.7436 16.9556 19.3793 18.5664C19.6732 18.7457 19.7658 19.1297 19.5862 19.4235ZM15 5C9.47723 5 5 9.47708 5 14.9999C5 20.5232 9.47723 25 15 25C20.5229 25 25 20.5232 25 14.9999C25 9.47708 20.5229 5 15 5Z" fill="white"/>
            </g>
            <defs>
            <clipPath id="clip0_916_13423">
            <rect width="20" height="20" fill="white" transform="translate(5 5)"/>
            </clipPath>
            </defs>
            </svg>
        );
    } else {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
<rect y="0.5" width="30" height="30" rx="5" fill="#AFAFAF"/>
<g clipPath="url(#clip0_916_13522)">
<path fillRule="evenodd" clipRule="evenodd" d="M20.9153 14.3652C17.6918 12.451 12.375 12.2749 9.29775 13.2088C8.80361 13.3589 8.28107 13.0799 8.13132 12.5857C7.98158 12.0913 8.2603 11.569 8.75488 11.4188C12.2871 10.3467 18.1591 10.5537 21.87 12.7565C22.3145 13.0203 22.4604 13.5945 22.1969 14.0381C21.9332 14.4826 21.3588 14.6291 20.9153 14.3652ZM20.8097 17.2006C20.5837 17.5675 20.1039 17.6825 19.7374 17.4573C17.05 15.8054 12.9522 15.3269 9.77274 16.292C9.36047 16.4165 8.92494 16.1841 8.79979 15.7725C8.67551 15.3603 8.90815 14.9256 9.31969 14.8002C12.9516 13.6981 17.4667 14.2319 20.5533 16.1287C20.9197 16.3544 21.035 16.8344 20.8097 17.2006ZM19.5862 19.9235C19.4064 20.2181 19.0227 20.3104 18.7291 20.1309C16.3809 18.6957 13.4251 18.3716 9.94427 19.1667C9.60886 19.2435 9.27448 19.0333 9.19807 18.698C9.12121 18.3626 9.33058 18.0282 9.66687 17.9517C13.4761 17.0809 16.7436 17.4556 19.3793 19.0664C19.6732 19.2457 19.7658 19.6297 19.5862 19.9235ZM15 5.5C9.47723 5.5 5 9.97708 5 15.4999C5 21.0232 9.47723 25.5 15 25.5C20.5229 25.5 25 21.0232 25 15.4999C25 9.97708 20.5229 5.5 15 5.5Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_916_13522">
<rect width="20" height="20" fill="white" transform="translate(5 5.5)"/>
</clipPath>
</defs>
</svg>
        );
    }
}