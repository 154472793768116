import { AlertTitle, Box, Card, Tab, Tabs, Typography } from "@mui/material";
import { useState } from "react";
import CampaignTab from "./Tabs";
import React from "react";
import { DateRangePicker, Stack } from "rsuite";
import subDays from "date-fns/subDays";
import addDays from "date-fns/addDays";
import startOfMonth from "date-fns/startOfMonth";
import endOfMonth from "date-fns/endOfMonth";
import addMonths from "date-fns/addMonths";
import Alert from "@mui/material/Alert";

interface Props {
  onForwardButtonClick?(): void;
  onBackwordButtonClick?(): void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const predefinedRanges: any = [
  {
    label: "Today",
    value: [new Date(), new Date()],
    placement: "left",
  },
  {
    label: "Yesterday",
    value: [addDays(new Date(), -1), addDays(new Date(), -1)],
    placement: "left",
  },
  {
    label: "Last 7 days",
    value: [subDays(new Date(), 6), new Date()],
    placement: "left",
  },
  {
    label: "Last 30 days",
    value: [subDays(new Date(), 29), new Date()],
    placement: "left",
  },
  {
    label: "This month",
    value: [startOfMonth(new Date()), new Date()],
    placement: "left",
  },
  {
    label: "Last month",
    value: [
      startOfMonth(addMonths(new Date(), -1)),
      endOfMonth(addMonths(new Date(), -1)),
    ],
    placement: "left",
  },
];

export default function Campaign() {
  const [value, setValue] = useState(0);
  const [open, setOpen] = React.useState(false);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  
  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Campaign management
      </Typography>
      <Typography variant="subtitle2" sx={{ mb: 2 }}>
        View and optimize your existing campaigns and adsets per platform
      </Typography>
      <Card sx={{ padding: 5 }}>
        <Alert severity="warning">
          <AlertTitle>
            You have no strategies set yet for Auto Optimization
          </AlertTitle>
          Click here to set a strategy for your campaigns
        </Alert>

        <Box sx={{ width: "100%", mt: 5, padding: 0 }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              position: { md: "absolute" },
            }}
          >
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="TIKTOK" {...a11yProps(0)} />
              <Tab label="META" {...a11yProps(1)} />
              <Tab label="SNAPCHAT" {...a11yProps(2)} />
            </Tabs>
          </Box>

          <Box className="sync-justify-end" sx={{ mt: 3 }}>
            <Stack direction="column" spacing={8} alignItems="flex-start">
              <DateRangePicker
                ranges={predefinedRanges}
                placeholder="Select Date"
                style={{ width: 300, zIndex: 100 }}
                placement="bottomEnd"
              />
            </Stack>
          </Box>

          <Box>
            <TabPanel value={value} index={0}>
              <CampaignTab />
            </TabPanel>
            <TabPanel value={value} index={1}>
              <CampaignTab />
            </TabPanel>
            <TabPanel value={value} index={2}>
              <CampaignTab />
            </TabPanel>
          </Box>
        </Box>
      </Card>
    </Box>
  );
}
