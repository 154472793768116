// ** React
import { Fragment, useContext, useEffect, useState } from "react";

// ** Mui Imports
import {
  Typography,
  Box,
  Select,
  MenuItem,
  Button,
  FormControl,
  Switch,
  TextField,
  Chip,
  Stack,
  CircularProgress,
  Autocomplete,
  FormHelperText,
  Divider,
  InputLabel,
  Card,
  Backdrop,
  Grow,
  Avatar,
  IconButton,
  Tooltip,
} from "@mui/material";

// ** Third Party Library
import axios from "axios";

// ** Redux
import { useAppDispatch, useAppSelector } from "store/store";
import {
  fetchLatestAdaccountData,
  getAdAccount,
  getCampaign,
  getCustomAudience,
  getIdentity,
  getInterests,
  getOptimizeEvents,
  getPixels,
  getProfiles,
} from "store/Thunk/autoSchedulerThunk";

import { AutoschudlerContext } from "context/autoschedulerContex";

// ** Custom Component
import {
  PLATFORMS,
  BATCH_STATUS,
  BID_STRATEGY,
  CAMPAIGN_OBJECTIVE,
  OPTIMIZE_EVENT_TYPE,
  OPTIMIZE_EVENT_TYPE_VALUE,
} from "@core/constants/enum";
import {
  age,
  profileName,
  handledOptimizeEvent,
} from "@core/constants/global-constants";
import { MEDIA_PLANNER } from "@core/constants/title-constants";
import DropdownNotFound from "components/DropdownNotFound/DropdownNotFound";
import CountryModal from "pages/auto-scheduler/SettingTabs/Dialogs/CountryModal";
import TimeSheets from "pages/auto-scheduler/Dayparsing";

// ** Mui Icons
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import RefreshTwoToneIcon from "@mui/icons-material/RefreshTwoTone";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { ProfileIcon, Info } from "assets";

export interface Props {
  tiktok?: any;
  setTiktok?: any;
  onBackwordButtonClick?: any;
  onForwardButtonClick?: any;
  onClearDraftClick?: any;
  handleNextButton?: any;
  availablePlatform?: any;
  handleNextButtonForAll?: any;
  flag: any;
  onNextPlatformClick: any;
}

export default function TiktokTabs({
  tiktok,
  setTiktok,
  onBackwordButtonClick,
  onForwardButtonClick,
  onClearDraftClick,
  handleNextButton,
  availablePlatform,
  handleNextButtonForAll,
  flag,
  onNextPlatformClick,
}: Props) {
  const dispatch: any = useAppDispatch();

  let autoSchedulerTitle = MEDIA_PLANNER.auto_scheduler;
  let CancelToken: any = axios.CancelToken;
  let cancel: any;

  const { campaign, availablePlatformTitle }: any =
    useContext(AutoschudlerContext);

  const {
    tiktokProfiles,
    tiktokAdAccount,
    tiktokCampaign,
    tiktokIdentity,
    tiktokInterests,
    tiktokPixels,
    tiktokOptimizeEvent,
    tiktokCustomAudience,
    schedulerDraftData,
  } = useAppSelector((state: any) => state.autoSchedulerSlice);

  const [allAgeGroup, setAllAgeGroup] = useState(true);
  const [interestList, setInterestList] = useState([]);
  const [loading, setLoading] = useState({
    adAccount: false,
    profile: false,
    campaign: false,
    identity: false,
    interest: false,
    pixel: false,
    optimizeEvent: false,
    customAudience: false,
  });
  const [campaignLoading, setCampaignLoading] = useState(false);
  const [interestLoading, setInterestLoading] = useState(false);
  const [isCountryModalOpen, setIsCountryModalOpen] = useState<boolean>(false);
  const [refreshLoading, setRefreshLoading] = useState("");
  const [adAccountLoading, setAdAccountLoading] = useState(false);
  const [isBackdrop, setIsBackdrop] = useState(false);
  const [identityLoading, setIdentityLoading] = useState(false);
  const [pixelsLoading, setPixelsLoading] = useState(false);
  const [customAudienceLoading, setCustomAudienceLoading] = useState(false);
  const [isClearDraftEnabled, setIsClearDraftEnabled] = useState(false);

  useEffect(() => {
    setIsClearDraftEnabled(!schedulerDraftData?.id);
  }, [schedulerDraftData]);

  useEffect(() => {
    if (age?.length == tiktok?.ageGroup?.length) {
      setAllAgeGroup(true);
    } else {
      setAllAgeGroup(false);
    }
  }, [tiktok?.ageGroup]);

  useEffect(() => {
    const platform = {
      ad_platform: PLATFORMS.TIKTOK,
    };
    dispatch(getProfiles({ platform })).then((res: any) => {
      res?.payload?.data?.data[0]?.length
        ? setLoading({ ...loading, profile: false })
        : setLoading({ ...loading, profile: true });
    });

    if (tiktok?.profile) {
      handleProfileChange(tiktok?.profile);
    }

    if (tiktok?.profile && tiktok?.advertiserAccount) {
      handleAdaccountChange(tiktok?.advertiserAccount);
      if (tiktok?.pixel) {
        handlePixelChange(tiktok?.pixel);
      }
    }

    if (tiktok?.existingAccount) {
      handledExistingCampaingChange("");
    }
  }, []);

  useEffect(() => {
    setInterestList(tiktokInterests);
  }, [tiktokInterests]);

  useEffect(() => {
    setRefreshLoading("");
  }, [tiktok?.advertiserAccount, tiktok.profile]);

  function handledInterstChange(e: any) {
    if (cancel != undefined) {
      cancel();
    }

    const interestReq = {
      ad_platform: PLATFORMS.TIKTOK,
      profile_id: tiktok?.profile,
      advertiser_id: tiktok?.advertiserAccount,
      search_keyword: e,
    };

    const token = {
      cancelToken: new CancelToken(function executor(c: any) {
        cancel = c;
      }),
    };
    setInterestLoading(true);
    dispatch(getInterests({ interestReq, token }))
      .then((response: any) => {
        if (response?.payload?.status == 200) {
          setInterestLoading(false);
        }
        return response.data.response;
      })
      .catch((error: any) => {
        const result = error.response;
        return Promise.reject(result);
      });
  }

  function handledExistingCampaingChange(e: any) {
    if (cancel != undefined) {
      cancel();
    }

    const campaignReq = {
      ad_platform: PLATFORMS.TIKTOK,
      profile_id: tiktok?.profile,
      advertiser_id: tiktok?.advertiserAccount,
      search_keyword: e,
      objective: campaign.objective,
    };

    const token = {
      cancelToken: new CancelToken(function executor(c: any) {
        cancel = c;
      }),
    };

    setCampaignLoading(true);
    dispatch(getCampaign({ campaignReq, token }))
      .then((response: any) => {
        if (response?.payload?.status == 200) {
        }
        setCampaignLoading(false);
        return response.data.response;
      })
      .catch((error: any) => {
        const result = error.response;
        return Promise.reject(result);
      });
  }

  const handleProfileChange = (e: any) => {
    const tiktokProfileEmail = tiktokProfiles?.find((obj: any) => {
      return obj?.id === e;
    });

    setTiktok({
      ...tiktok,
      profile: e,
      profileEmail: tiktokProfileEmail?.email
        ? tiktokProfileEmail?.email
        : tiktok?.profileEmail,
      firstName: tiktokProfileEmail?.first_name,
      lastName: tiktokProfileEmail?.last_name,
      selectedAudience: tiktok.profile != e ? [] : tiktok?.selectedAudience,
      interest: tiktok.profile != e ? [] : tiktok?.interest,
      optimizeEvent: tiktok.profile != e ? [] : tiktok?.optimizeEvent,
      existingAccount: tiktok.profile != e ? "" : tiktok?.existingAccount,
      pixel: tiktok.profile != e ? "" : tiktok?.pixel,
      advertiserAccount: tiktok.profile != e ? "" : tiktok?.advertiserAccount,
      identity: tiktok.profile != e ? "" : tiktok?.identity,
      // advertiserAccount: "",
      // newAccount: "",
      // autoGenerateName: false,
      // customAudiences: false,
      // maximumCreative: 5,
      // accelerateSpending: false,
    });

    const platform = {
      ad_platform: PLATFORMS.TIKTOK,
      profile_id: e,
    };
    dispatch(getAdAccount({ platform })).then((res: any) => {
      res?.payload?.data?.data?.length
        ? setAdAccountLoading(false)
        : setAdAccountLoading(true);
    });
  };

  const handleAdaccountChange = (e: any) => {
    const tiktokAdAccountName = tiktokAdAccount?.find((obj: any) => {
      return obj?.account_id === e;
    });
    let advertiseName = "";

    const platform = {
      ad_platform: PLATFORMS.TIKTOK,
      profile_id: tiktok?.profile,
      advertiser_id: e,
    };

    const platformData = {
      ad_platform: PLATFORMS.TIKTOK,
      profile_id: tiktok?.profile,
      account_id: e,
    };

    const data = {
      advertiser_id: e,
    };
    let name = tiktokAdAccount?.find(
      (element: any) => element?.account_id == e
    )?.account_name;
    let objective =
      campaign?.objective != "" ? " - " + campaign?.objective : "";
    let countries =
      campaign?.countries.length >= 2
        ? " - " + "Multi Countries"
        : campaign?.countries.length == 0
        ? " - No Countries  "
        : " - " + campaign?.countries[0]?.Name;
    let language = campaign?.customLanguage
      ? " - Custom Language"
      : " - No Language";
    advertiseName = name + objective + countries + language;
    let disableCampaign =
      name +
      objective +
      " - {COUNTRY}" +
      " - {LANGUAGE_INFO}" +
      " - {LANDINGPAGE}";

    dispatch(getIdentity({ data })).then((res: any) => {
      res?.payload?.data?.data[0]?.length
        ? setIdentityLoading(false)
        : setIdentityLoading(true);
    });

    dispatch(getPixels({ platform })).then((res: any) => {
      res?.payload?.data?.data[0]?.length
        ? setPixelsLoading(false)
        : setPixelsLoading(true);
    });

    dispatch(getCustomAudience({ platformData })).then((res: any) => {
      res?.payload?.data?.data[0]?.length
        ? setCustomAudienceLoading(false)
        : setCustomAudienceLoading(true);
    });

    setTiktok({
      ...tiktok,
      advertiserAccount: e,
      newAccount: advertiseName,
      disableCampaignName: disableCampaign,
      autoGenerateName: false,
      adAccountName: tiktokAdAccountName?.account_name,
      selectedAudience:
        tiktok?.advertiserAccount != e ? [] : tiktok?.selectedAudience,
      interest: tiktok?.advertiserAccount != e ? [] : tiktok?.interest,
      optimizeEvent:
        tiktok?.advertiserAccount != e ? [] : tiktok?.optimizeEvent,
      existingAccount:
        tiktok?.advertiserAccount != e ? "" : tiktok?.existingAccount,
      pixel: tiktok?.advertiserAccount != e ? "" : tiktok?.pixel,
      identity: tiktok?.advertiserAccount != e ? "" : tiktok?.identity,
    });
  };

  const handledAgeGroupChange = (name: any, index: number) => {
    let agesArray: any = tiktok?.ageGroup;
    if (tiktok?.ageGroup.includes(name)) {
      let filterArray: any = agesArray;
      agesArray = filterArray.filter((item: any) => item != name);
    } else {
      agesArray.push(name);
    }
    if (agesArray.length) {
      let array: any = [];
      let minimum: any;
      let maximum: any;
      let finaleArray: any = [];
      age?.map((itemss: any, index: any) => {
        agesArray?.map((item: any) => {
          if (itemss?.name == item) {
            array.push(Number(item?.substring(0, 2)));
          }
        });
        if (
          array?.length &&
          array[0] == Number(itemss?.name?.substring(0, 2))
        ) {
          minimum = index;
        }
        if (array[array?.length - 1] == Number(itemss?.name?.substring(0, 2))) {
          maximum = index;
        }
      });

      for (let i = minimum; i < maximum + 1; i++) {
        finaleArray.push(age[i]?.name);
      }
      setTiktok({ ...tiktok, ageGroup: finaleArray });
    } else {
      setTiktok({ ...tiktok, ageGroup: [] });
    }
  };

  const handledAllAgeGroup = () => {
    setAllAgeGroup(!allAgeGroup);
    if (!allAgeGroup) {
      let ageArray: any = [];
      age?.map((item: any) => {
        ageArray.push(item?.name);
      });
      setTiktok({ ...tiktok, ageGroup: ageArray });
    } else {
      setTiktok({ ...tiktok, ageGroup: [] });
    }
  };

  const handlePixelChange = (e: any) => {
    const platformData = {
      ad_platform: PLATFORMS.TIKTOK,
      profile_id: tiktok?.profile,
      account_id: tiktok?.advertiserAccount,
      pixel_id: e,
    };

    const pixelName = tiktokPixels.find((obj: any) => {
      return obj.pixel_id === e;
    });

    dispatch(getOptimizeEvents({ platformData })).then((res: any) => {
      setLoading({
        ...loading,
        optimizeEvent: res?.payload?.data?.data[0]?.length > 0 ? false : true,
      });
    });
    setTiktok({ ...tiktok, pixel: e, pixelName: pixelName?.name });
  };

  const handledNextButtonClick = () => {
    if (availablePlatformTitle?.includes(PLATFORMS.FACEBOOK)) {
      handleNextButton();
      // onNextPlatformClick();
    } else if (
      !availablePlatformTitle?.includes(PLATFORMS.FACEBOOK) &&
      availablePlatformTitle?.includes(PLATFORMS.SNAP)
    ) {
      handleNextButton();
      // onNextPlatformClick();
    } else {
      handleNextButtonForAll();
    }
  };

  function handledButton() {
    let required =
      (campaign?.objective !== CAMPAIGN_OBJECTIVE.TRAFFIC &&
        tiktok?.pixel == "") ||
      !tiktok?.profile ||
      !tiktok?.advertiserAccount ||
      (tiktok?.activeButton == "Existing" && !tiktok?.existingAccount) ||
      !tiktok?.ageGroup?.length ||
      !tiktok?.caption ||
      !tiktok?.identity ||
      !tiktok?.dayparsingOffset;

    return (
      <Grow in={true}>
        <Box>
          <Divider sx={{ my: 5 }} />
          {flag &&
            (availablePlatformTitle?.includes(
              PLATFORMS.FACEBOOK
            ) ? null : !availablePlatformTitle?.includes(PLATFORMS.FACEBOOK) &&
              availablePlatformTitle?.includes(PLATFORMS.SNAP) ? (
              PLATFORMS.SNAP
            ) : (
              <FormHelperText sx={{ mb: 2 }} error id="password-error">
                {autoSchedulerTitle.PLEASE_FILL_ALL_FIELDS}
              </FormHelperText>
            ))}
          <Box
            className="footer-button"
            sx={{ justifyContent: { xs: "center", sm: "space-between" } }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
              gap={2}
            >
              <Button
                size="large"
                type="submit"
                variant="outlined"
                color="secondary"
                onClick={onBackwordButtonClick}
                startIcon={<ArrowBackIcon />}
              >
                {autoSchedulerTitle.CAMPAIGN}
              </Button>
              <Button
                size="large"
                type="submit"
                variant="contained"
                onClick={handledNextButtonClick}
                disabled={required}
                endIcon={<ArrowForwardIcon />}
              >
                {availablePlatformTitle?.includes(PLATFORMS.FACEBOOK)
                  ? "Meta"
                  : !availablePlatformTitle?.includes(PLATFORMS.FACEBOOK) &&
                    availablePlatformTitle?.includes(PLATFORMS.SNAP)
                  ? PLATFORMS.SNAP
                  : autoSchedulerTitle.CREATIVES}
              </Button>
            </Box>
            <Button
              size="large"
              variant="outlined"
              onClick={onClearDraftClick}
              sx={{ mt: { xs: 3, sm: 0 } }}
              disabled={isClearDraftEnabled}
              startIcon={<DeleteIcon />}
            >
              {autoSchedulerTitle.CLEAR_DRAFT}
            </Button>
          </Box>
        </Box>
      </Grow>
    );
  }

  const handleTimezoneChange = (code: any, utc_offset: any) => {
    setTiktok({
      ...tiktok,
      dayparsingOffset: {
        ...tiktok?.dayparsingOffset,
        [code]: utc_offset?.slice(0, 2),
      },
    });
  };

  const handleRefreshButton = () => {
    setIsBackdrop(true);
    setRefreshLoading("Refreshing");
    if (tiktok?.profile && tiktok?.advertiserAccount) {
      const data = {
        ad_platform: PLATFORMS.TIKTOK,
        profile_id: tiktok?.profile,
        advertiser_id: tiktok?.advertiserAccount,
      };
      dispatch(fetchLatestAdaccountData({ data })).then((res: any) => {
        setIsBackdrop(false);
        if (res?.payload?.status == 200) {
          handleAdaccountChange(tiktok?.advertiserAccount);
          setRefreshLoading(BATCH_STATUS.DONE);
          handleProfileChange(tiktok?.profile);
        } else {
          setRefreshLoading("");
        }
      });
    }
  };

  const handleOptimizeEventChange = (e: any) => {
    const pixelName = Object.keys(tiktokOptimizeEvent)?.find(
      (obj: any) => tiktokOptimizeEvent[obj] === e
    );

    setTiktok({
      ...tiktok,
      optimizeEvent: e,
      optimizeEventName: handledOptimizeEvent(pixelName),
    });
  };

  const handleTiktokIdentity = (e: any) => {
    const identityName = tiktokIdentity?.find(
      (obj: any) => obj.identity_id === e.target.value
    );

    setTiktok({
      ...tiktok,
      identity: e.target.value,
      identityName: identityName?.display_name,
    });
  };

  return (
    <>
      {/*--------------------------- 1. Account details Card-----------------------------------------------*/}
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
        open={isBackdrop}
      ></Backdrop>
      <Grow in={true}>
        <Card sx={{ padding: 8 }}>
          <Box className="align-justify-item-center">
            <Chip
              label={autoSchedulerTitle.ACCOUNT_DETAILS}
              sx={{ flexGrow: 0 }}
            />
            <Divider sx={{ flexGrow: 1 }} />
          </Box>
          <Box
            sx={{
              mx: { md: 8, sm: 4, xs: 2 },
              maxWidth: "467px",
              width: { md: "50%", sm: "50%", xs: "100%" },
            }}
          >
            <Box
              sx={{ mt: 8, width: { md: "221px", sm: "221px", xs: "100%" } }}
            >
              <FormControl fullWidth>
                <InputLabel>{autoSchedulerTitle.PROFILE + " *"}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  placeholder={autoSchedulerTitle.LABELS}
                  label={autoSchedulerTitle.PROFILE + " *"}
                  value={tiktok?.profile}
                  onChange={(e: any) => {
                    handleProfileChange(e.target.value);
                  }}
                >
                  {tiktokProfiles.length == 0 && loading.profile && (
                    <DropdownNotFound name={"profile"} />
                  )}
                  {tiktokProfiles.length == 0 && !loading.profile && (
                    <Box className="sync-align-justify-center full-height-width">
                      <CircularProgress />
                    </Box>
                  )}
                  {tiktokProfiles?.map((item: any) => (
                    <MenuItem value={item?.id} key={item?.id}>
                      <Box className="sync-align-center">
                        <Avatar
                          alt={ProfileIcon}
                          sx={{ width: 22, height: 22, mr: 2 }}
                          src={item?.avatar_url || ProfileIcon}
                        />
                        <Typography sx={{ fontSize: "14px" }}>
                          {profileName(item)}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {tiktok?.profile && (
              <Grow in={true}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                    mt: 8,
                  }}
                  rowGap={2}
                >
                  <Box
                    sx={{
                      width: { md: "221px", sm: "221px", xs: "100%" },
                    }}
                  >
                    <FormControl fullWidth>
                      <InputLabel>
                        {autoSchedulerTitle.AD_ACCOUNT + " *"}
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        placeholder={autoSchedulerTitle.LABELS}
                        variant="outlined"
                        label={autoSchedulerTitle.AD_ACCOUNT + " *"}
                        value={tiktok?.advertiserAccount}
                        onChange={(e: any) =>
                          handleAdaccountChange(e.target.value)
                        }
                      >
                        {tiktokAdAccount.length == 0 && adAccountLoading && (
                          <DropdownNotFound name={"account"} />
                        )}
                        {tiktokAdAccount.length == 0 && !adAccountLoading && (
                          <Box className="sync-align-justify-center full-height-width">
                            <CircularProgress />
                          </Box>
                        )}
                        {tiktokAdAccount?.map((item: any) => (
                          <MenuItem
                            value={item?.account_id}
                            key={item?.account_id}
                          >
                            {item?.account_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                  <Box sx={{ ml: 2 }}>
                    {refreshLoading == BATCH_STATUS.DONE ? (
                      <Typography
                        sx={{
                          fontWeight: "500",
                          color: "rgba(103, 203, 36, 1)",
                          display: "flex",
                          alignItems: "center",
                          ml: 2,
                        }}
                      >
                        <DoneRoundedIcon sx={{ mr: 2 }} color="success" />
                        {autoSchedulerTitle.DATA_UPTO_DATE}
                      </Typography>
                    ) : refreshLoading == autoSchedulerTitle.REFRESHING &&
                      tiktok?.advertiserAccount ? (
                      <Typography
                        sx={{
                          fontWeight: "500",
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <CircularProgress
                          size={20}
                          sx={{ mr: 2, height: 2, width: 2 }}
                          color="secondary"
                        />
                        {autoSchedulerTitle.REFRESHING_DATA}
                      </Typography>
                    ) : refreshLoading == "" && tiktok?.advertiserAccount ? (
                      <Button variant="text" onClick={handleRefreshButton}>
                        <RefreshTwoToneIcon sx={{ mr: 2 }} />
                        {autoSchedulerTitle.REFRESH_DATA}
                      </Button>
                    ) : (
                      <></>
                    )}
                  </Box>
                </Box>
              </Grow>
            )}

            {tiktok?.advertiserAccount && (
              <Grow in={true}>
                <Box>
                  <Box
                    sx={{
                      padding: 2,
                      border: 1,
                      borderRadius: 1,
                      mt: 8,
                      color: "rgba(76, 78, 100, 0.22)",
                      display: "flex",
                      width: "max-content",
                    }}
                    columnGap={2}
                  >
                    <Button
                      variant={
                        tiktok?.activeButton == "Existing"
                          ? "contained"
                          : "text"
                      }
                      onClick={() =>
                        setTiktok({ ...tiktok, activeButton: "Existing" })
                      }
                      sx={{
                        paddingLeft: 3,
                        paddingRight: 3,
                      }}
                    >
                      {autoSchedulerTitle.USE_EXISTING_CAMPAIGN}
                    </Button>
                    <Button
                      variant={
                        tiktok?.activeButton == "New" ? "contained" : "text"
                      }
                      onClick={() =>
                        setTiktok({ ...tiktok, activeButton: "New" })
                      }
                    >
                      {autoSchedulerTitle.CREATE_NEW_CAMPAIGN}
                    </Button>
                  </Box>

                  {/* ------------------------------Existing Campaign---------------------------------------*/}

                  {tiktok?.activeButton == "Existing" && (
                    <Grow in={true}>
                      <Box sx={{ mt: 8 }}>
                        <FormControl fullWidth sx={{ mb: 2 }}>
                          <Autocomplete
                            id="tags-outlined"
                            options={
                              tiktokCampaign.length ? tiktokCampaign : []
                            }
                            loading={campaignLoading}
                            onChange={(e: any, value: any) =>
                              setTiktok({
                                ...tiktok,
                                existingAccount: value?.campaign_id,
                              })
                            }
                            value={
                              tiktok?.existingAccount != ""
                                ? tiktokCampaign?.find(
                                    (element: any) =>
                                      element?.campaign_id ==
                                      tiktok?.existingAccount
                                  )
                                : null
                            }
                            noOptionsText={autoSchedulerTitle.NO_CAMPAIGN_FOUND}
                            onFocus={() => handledExistingCampaingChange("")}
                            getOptionLabel={(option: any) =>
                              option.campaign_name
                            }
                            disableCloseOnSelect
                            filterSelectedOptions
                            renderInput={(params: any) => (
                              <TextField
                                onChange={(e: any) => {
                                  handledExistingCampaingChange(e.target.value);
                                }}
                                {...params}
                                placeholder={autoSchedulerTitle.CAMPAIGN}
                                label={autoSchedulerTitle.CAMPAIGN + " *"}
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <Fragment>
                                      {campaignLoading ? (
                                        <CircularProgress size={20} />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </Fragment>
                                  ),
                                }}
                              />
                            )}
                            renderOption={(props, option: any) => (
                              <li {...props}>
                                <Box
                                  className="sync-align-center"
                                  sx={{ px: "16px", py: "6px" }}
                                >
                                  <Box
                                    sx={{
                                      background:
                                        option?.active == "Yes"
                                          ? "green"
                                          : "red",
                                      height: "9px",
                                      width: "9px",
                                      borderRadius: "5px",
                                      mr: 3,
                                    }}
                                  ></Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Typography
                                      noWrap
                                      variant="subtitle2"
                                      sx={{ color: "text.primary" }}
                                    >
                                      {option?.campaign_name}
                                    </Typography>
                                  </Box>
                                </Box>
                              </li>
                            )}
                          />
                        </FormControl>
                      </Box>
                    </Grow>
                  )}

                  {tiktok?.activeButton == "New" && (
                    <Grow in={true}>
                      <Box sx={{ mt: 8 }}>
                        {tiktok?.autoGenerateName ? (
                          <Box>
                            <TextField
                              fullWidth
                              disabled
                              label={autoSchedulerTitle.CAMPAIGN_NAME}
                              placeholder={autoSchedulerTitle.CAMPAIGN_NAME}
                              variant="outlined"
                              value={tiktok?.disableCampaignName}
                            />
                          </Box>
                        ) : (
                          <Box>
                            <TextField
                              fullWidth
                              label={autoSchedulerTitle.CAMPAIGN_NAME}
                              placeholder={autoSchedulerTitle.CAMPAIGN_NAME}
                              value={tiktok?.newAccount}
                              variant="outlined"
                              onChange={(e: any) =>
                                setTiktok({
                                  ...tiktok,
                                  newAccount: e.target.value,
                                })
                              }
                            />
                          </Box>
                        )}

                        <Box className="sync-align-center" sx={{ mt: 4 }}>
                          <Switch
                            checked={tiktok?.autoGenerateName}
                            onChange={(e) =>
                              setTiktok({
                                ...tiktok,
                                autoGenerateName: e.target.checked,
                              })
                            }
                          />
                          <Typography
                            variant="subtitle1"
                            sx={{ color: "rgba(76, 78, 100, 0.87)" }}
                          >
                            {autoSchedulerTitle.AUTO_GENERATE}
                          </Typography>
                        </Box>
                      </Box>
                    </Grow>
                  )}
                </Box>
              </Grow>
            )}
          </Box>
          {tiktok?.existingAccount != "" || tiktok?.activeButton == "New" ? (
            <></>
          ) : (
            handledButton()
          )}
        </Card>
      </Grow>

      {tiktok?.advertiserAccount && (
        <Grow in={true}>
          <Box>
            {(tiktok?.existingAccount || tiktok?.activeButton == "New") && (
              <Grow in={true}>
                <Box>
                  {/*-------------------------------------- 2. Targeting -----------------------------------------*/}
                  <Grow in={true}>
                    <Card sx={{ padding: 8, mt: 10 }}>
                      <Box className="align-justify-item-center">
                        <Chip
                          label={autoSchedulerTitle.TARGETING}
                          sx={{ flexGrow: 0 }}
                        />
                        <Divider sx={{ flexGrow: 1 }} />
                      </Box>

                      <Box sx={{ ml: { md: 8, sm: 4, xs: 2 } }}>
                        <Box
                          sx={{
                            mt: 8,
                            maxWidth: "467px",
                            width: { md: "50%", sm: "50%", xs: "100%" },
                          }}
                        >
                          <Autocomplete
                            multiple
                            id="tags-outlined"
                            options={
                              tiktokInterests.length ? tiktokInterests : []
                            }
                            getOptionLabel={(option: any) => option.name}
                            loading={interestLoading}
                            disableCloseOnSelect
                            noOptionsText={autoSchedulerTitle.NO_INTEREST_FOUND}
                            filterSelectedOptions
                            value={tiktok.interest}
                            onChange={(e: any, values) => {
                              setTiktok({ ...tiktok, interest: values });
                            }}
                            onFocus={() => handledInterstChange("")}
                            renderInput={(params: any) => (
                              <TextField
                                onChange={(e: any) =>
                                  handledInterstChange(e.target.value)
                                }
                                {...params}
                                placeholder={autoSchedulerTitle.PICK_INTEREST}
                                label={autoSchedulerTitle.INTEREST}
                                varian="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <Fragment>
                                      {interestLoading ? (
                                        <CircularProgress size={20} />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </Box>

                        <Box sx={{ mt: 8 }}>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: "400",
                              color: "rgba(76, 78, 100, 0.87)",
                            }}
                          >
                            {autoSchedulerTitle.AGE_GROUP}
                          </Typography>
                          <Stack
                            direction="row"
                            spacing={1}
                            sx={{ mt: 4, flexWrap: "wrap" }}
                            rowGap={2}
                          >
                            <Chip
                              sx={{
                                width: "80px",
                                color: allAgeGroup ? "white" : "primary.main",
                                borderColor: "red",
                                backgroundColor: allAgeGroup
                                  ? "primary.main"
                                  : "white",
                              }}
                              label={"All"}
                              variant={allAgeGroup ? "filled" : "outlined"}
                              onClick={handledAllAgeGroup}
                            />
                            {age &&
                              age?.map((item: any, index: any) => {
                                return (
                                  <Chip
                                    key={item?.id}
                                    sx={{
                                      width: "80px",
                                      color: tiktok?.ageGroup?.includes(
                                        item?.name
                                      )
                                        ? "white"
                                        : "primary.main",
                                      borderColor: "red",
                                      backgroundColor:
                                        tiktok?.ageGroup?.includes(item?.name)
                                          ? "primary.main"
                                          : "white",
                                    }}
                                    label={item?.name}
                                    variant={
                                      tiktok?.ageGroup?.includes(item?.name)
                                        ? "filled"
                                        : "outlined"
                                    }
                                    onClick={(e: any) =>
                                      handledAgeGroupChange(item?.name, index)
                                    }
                                  />
                                );
                              })}
                          </Stack>
                        </Box>

                        <Box className="sync-align-center" sx={{ mt: 4 }}>
                          <Switch
                            checked={tiktok?.customAudiences}
                            onChange={(e) =>
                              setTiktok({
                                ...tiktok,
                                customAudiences: e.target.checked,
                              })
                            }
                          />
                          <Typography
                            variant="subtitle1"
                            sx={{ color: "rgba(76, 78, 100, 0.87)" }}
                          >
                            {autoSchedulerTitle.CUSTOM_AUDIENCE}
                          </Typography>
                        </Box>

                        {tiktok?.customAudiences && (
                          <Grow in={true}>
                            <Box
                              sx={{
                                maxWidth: "467px",
                                width: { md: "50%", sm: "50%", xs: "100%" },
                              }}
                            >
                              <FormControl fullWidth sx={{ mt: 4 }}>
                                <Autocomplete
                                  multiple
                                  id="tags-outlined"
                                  options={tiktokCustomAudience}
                                  getOptionLabel={(option: any) => option.name}
                                  noOptionsText={
                                    autoSchedulerTitle.NO_AUDIENCE_FOUND
                                  }
                                  onChange={(value: any, select: any) =>
                                    setTiktok({
                                      ...tiktok,
                                      selectedAudience: select,
                                    })
                                  }
                                  value={tiktok?.selectedAudience}
                                  renderInput={(params: any) => (
                                    <TextField
                                      {...params}
                                      placeholder={
                                        autoSchedulerTitle.PICK_AUDIENCE
                                      }
                                      variant="outlined"
                                      label="Audience"
                                    />
                                  )}
                                  disableCloseOnSelect
                                  filterSelectedOptions
                                />
                              </FormControl>
                            </Box>
                          </Grow>
                        )}
                      </Box>
                    </Card>
                  </Grow>

                  {/*--------------------------------------- 3. Ad settings -----------------------------------------*/}
                  <Grow in={true}>
                    <Card sx={{ padding: 8, mt: 10 }}>
                      <Box className="align-justify-item-center">
                        <Chip
                          label={autoSchedulerTitle.AD_SETTINGS}
                          sx={{ flexGrow: 0 }}
                        />
                        <Divider sx={{ flexGrow: 1 }} />
                      </Box>
                      <Box sx={{ mx: { md: 8, sm: 4, xs: 2 } }}>
                        <Box
                          sx={{
                            width: { md: "221px", sm: "221px", xs: "100%" },
                          }}
                        >
                          <FormControl fullWidth sx={{ mt: 8 }}>
                            <InputLabel>
                              {autoSchedulerTitle.IDENTITY + " *"}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              placeholder={autoSchedulerTitle.LABELS}
                              label={autoSchedulerTitle.IDENTITY + " *"}
                              value={tiktok?.identity}
                              onChange={
                                (e: any) => handleTiktokIdentity(e)
                                // setTiktok({
                                //   ...tiktok,
                                //   identity: e.target.value,
                                // })
                              }
                            >
                              {tiktokIdentity?.length == 0 &&
                                identityLoading && (
                                  <DropdownNotFound name={"identity"} />
                                )}
                              {tiktokIdentity?.length == 0 &&
                                !identityLoading && (
                                  <Box className="sync-align-justify-center full-height-width">
                                    <CircularProgress />
                                  </Box>
                                )}
                              {tiktokIdentity?.map((item: any) => (
                                <MenuItem
                                  value={item?.identity_id}
                                  key={item?.identity_id}
                                >
                                  <Box className="sync-align-center">
                                    {item?.profile_image ? (
                                      <Box
                                        component="img"
                                        src={item?.profile_image}
                                        style={{
                                          height: "30px",
                                          width: "30px",
                                          marginRight: "5px",
                                          borderRadius: "20px",
                                        }}
                                        loading="lazy"
                                      />
                                    ) : (
                                      <Box
                                        sx={{ height: "30px", width: "30px" }}
                                      ></Box>
                                    )}{" "}
                                    <Typography sx={{ fontSize: "14px" }}>
                                      {item?.display_name}
                                    </Typography>
                                  </Box>
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>

                        <Box
                          sx={{ display: "flex", flexWrap: "wrap" }}
                          columnGap={4}
                        >
                          <Box
                            sx={{
                              mt: 8,
                              width: { md: "221px", sm: "221px", xs: "100%" },
                            }}
                          >
                            <FormControl fullWidth>
                              <InputLabel>
                                {autoSchedulerTitle.PIXELS + " "}
                                {campaign?.objective ==
                                CAMPAIGN_OBJECTIVE.TRAFFIC
                                  ? ""
                                  : "*"}
                              </InputLabel>
                              <Select
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                placeholder={autoSchedulerTitle.LABELS}
                                value={tiktok?.pixel}
                                label={autoSchedulerTitle.PIXELS + " *"}
                                onChange={(e: any) =>
                                  handlePixelChange(e.target.value)
                                }
                              >
                                {tiktokPixels.length == 0 && pixelsLoading && (
                                  <DropdownNotFound name={"pixel"} />
                                )}
                                {tiktokPixels.length == 0 && !pixelsLoading && (
                                  <Box className="sync-align-justify-center full-height-width">
                                    <CircularProgress />
                                  </Box>
                                )}
                                {tiktokPixels?.map((item: any) => (
                                  <MenuItem
                                    value={item?.pixel_id}
                                    key={item?.pixel_id}
                                  >
                                    {item?.name}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </Box>

                          {tiktok?.pixel && (
                            <Grow in={true}>
                              <Box
                                sx={{
                                  mt: 8,
                                  width: {
                                    md: "221px",
                                    sm: "221px",
                                    xs: "100%",
                                  },
                                }}
                              >
                                <FormControl fullWidth>
                                  <InputLabel>
                                    {campaign?.objective ===
                                    CAMPAIGN_OBJECTIVE.CONVERSIONS
                                      ? autoSchedulerTitle.OPTIMIZE_EVENT + " *"
                                      : autoSchedulerTitle.OPTIMIZE_EVENT}
                                  </InputLabel>
                                  <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    placeholder={autoSchedulerTitle.LABELS}
                                    label={
                                      campaign?.objective ===
                                      CAMPAIGN_OBJECTIVE.CONVERSIONS
                                        ? autoSchedulerTitle.OPTIMIZE_EVENT +
                                          " *"
                                        : autoSchedulerTitle.OPTIMIZE_EVENT
                                    }
                                    value={tiktok?.optimizeEvent}
                                    onChange={
                                      (e: any) =>
                                        handleOptimizeEventChange(
                                          e.target.value
                                        )
                                      // setTiktok({
                                      //   ...tiktok,
                                      //   optimizeEvent: e.target.value,
                                      // })
                                    }
                                  >
                                    {Object.keys(tiktokOptimizeEvent).length ==
                                      0 &&
                                      loading.optimizeEvent && (
                                        <DropdownNotFound name={"event"} />
                                      )}
                                    {Object.keys(tiktokOptimizeEvent).length ==
                                      0 &&
                                      !loading.optimizeEvent && (
                                        <Box className="sync-align-justify-center full-height-width">
                                          <CircularProgress />
                                        </Box>
                                      )}
                                    {Object.keys(tiktokOptimizeEvent)?.map(
                                      (item: any, index: number) => (
                                        <MenuItem
                                          value={tiktokOptimizeEvent[item]}
                                          key={index}
                                        >
                                          {handledOptimizeEvent(item)}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </FormControl>
                              </Box>
                            </Grow>
                          )}
                        </Box>

                        <Box
                          sx={{
                            mt: 8,
                            width: {
                              md: "221px",
                              sm: "221px",
                              xs: "100%",
                            },
                          }}
                        >
                          <FormControl fullWidth>
                            <InputLabel>
                              {autoSchedulerTitle.MAX_CREATIVE}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              placeholder={autoSchedulerTitle.LABELS}
                              label={autoSchedulerTitle.MAX_CREATIVE}
                              value={tiktok?.maximumCreative}
                              onChange={(e: any) =>
                                setTiktok({
                                  ...tiktok,
                                  maximumCreative: e.target.value,
                                })
                              }
                            >
                              {[...Array(20)].map((_, index) => (
                                <MenuItem key={index} value={index + 1}>
                                  {index + 1}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>

                        <Box maxWidth={"467px"}>
                          <FormControl fullWidth sx={{ mt: 8 }}>
                            <TextField
                              multiline
                              rows={4}
                              variant="outlined"
                              placeholder={autoSchedulerTitle.CAPTION}
                              label={autoSchedulerTitle.CAPTION + " *"}
                              inputProps={{
                                maxlength: 100,
                              }}
                              helperText={`Remaining characters: ${
                                100 - tiktok?.caption?.length
                              }`}
                              value={tiktok?.caption}
                              onChange={(e: any) =>
                                setTiktok({
                                  ...tiktok,
                                  caption: e.target.value,
                                })
                              }
                            />
                          </FormControl>
                        </Box>

                        {campaign?.bidStategy === BID_STRATEGY.BID_CAP && (
                          <Box className="sync-align-center" sx={{ mt: 4 }}>
                            <Switch
                              checked={Boolean(tiktok?.accelerateSpending)}
                              inputProps={{
                                "aria-label": "controlled",
                                name: "accereate",
                              }}
                              onChange={(e: any, checked: boolean) => {
                                setTiktok({
                                  ...tiktok,
                                  accelerateSpending: checked,
                                });
                              }}
                            />
                            <Typography
                              variant="subtitle1"
                              sx={{ color: "rgba(76, 78, 100, 0.87)" }}
                            >
                              {autoSchedulerTitle.ACCELERATE_SPEND}
                            </Typography>
                          </Box>
                        )}

                        <Box className="sync-align-center" sx={{ mt: 4 }}>
                          <>
                            <Switch
                              checked={tiktok?.dayparting}
                              onChange={(e: any, checked: boolean) =>
                                setTiktok({
                                  ...tiktok,
                                  dayparting: checked,
                                })
                              }
                            />
                            <Typography
                              variant="subtitle1"
                              sx={{ color: "rgba(76, 78, 100, 0.87)" }}
                            >
                              {autoSchedulerTitle.DAYPARTING}
                            </Typography>
                          </>
                          <Tooltip
                            title="When Dayparting is turned ON, your ads will be displayed for specific hours and days only."
                            placement="top"
                          >
                            <Box
                              component="img"
                              src={Info}
                              sx={{ height: 20, width: 20, ml: 2 }}
                              className="tooltip-icon"
                            />
                          </Tooltip>
                        </Box>
                        {tiktok?.dayparting && (
                          <Grow in={true}>
                            <Box sx={{ overflow: "auto" }}>
                              <Typography component="div">
                                {autoSchedulerTitle.TIMESLOTS}
                                {!campaign?.isBundleCountries && (
                                  <Box
                                    onClick={() => setIsCountryModalOpen(true)}
                                    display="inline"
                                    color="rgba(255, 77, 73, 1)"
                                    sx={{ cursor: "pointer", ml: "10px" }}
                                  >
                                    {autoSchedulerTitle.CHANGE_TIMEZONE}
                                  </Box>
                                )}
                              </Typography>
                              <TimeSheets
                                tiktok={tiktok}
                                setTiktok={setTiktok}
                              />
                            </Box>
                          </Grow>
                        )}

                        {campaign?.objective ===
                          CAMPAIGN_OBJECTIVE.CONVERSIONS &&
                          tiktokPixels?.length == 0 && (
                            <FormHelperText sx={{ color: "primary.main" }}>
                              {autoSchedulerTitle.CREATE_FIRST}
                            </FormHelperText>
                          )}
                      </Box>
                      {handledButton()}
                    </Card>
                  </Grow>
                </Box>
              </Grow>
            )}
          </Box>
        </Grow>
      )}
      {isCountryModalOpen && (
        <CountryModal
          isOpen={isCountryModalOpen}
          onClickClose={() => setIsCountryModalOpen(false)}
          onTimezoneChange={handleTimezoneChange}
        />
      )}
    </>
  );
}
