// ** React
import { useEffect, useState, useContext } from "react";

// ** MUI Imports
import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  Grow,
  TextField,
  CircularProgress,
  FormHelperText,
} from "@mui/material";

// ** Redux
import { useAppDispatch, useAppSelector } from "store/store";
import {
  SchedulerDraftData,
  CreativesData,
} from "store/Slice/autoSchedulerSlice";
import {
  deletePresetsData,
  getAllPresets,
  getSinglPresetsData,
} from "store/Thunk/autoSchedulerThunk";
import { AutoschudlerContext } from "context/autoschedulerContex";

// ** Custom Component
import PlatformTableDialog from "pages/auto-scheduler/PlatformTableDialog/PlatformTableDialog";
import NestedTableDialog from "pages/auto-scheduler/PlatformTableDialog/NestedTableDialog";
import { MEDIA_PLANNER } from "@core/constants/title-constants";

// ** Icons
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DoneIcon from "@mui/icons-material/Done";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

interface Props {
  onForwardButtonClick(): void;
  onBackwordButtonClick?(): void;
  onClearDraftClick?(): void;
  onAutoDraftData?(page: any): any;
  handleAutodraftData(page: any): void;

  setOpen?: any;
  state?: any;
  setState?: any;
  setCampaign?: any;
  setStep?: any;
  setSnapchat?: any;
  setFacebook?: any;
  setTiktok?: any;
  setSchedulerCreatives?: any;
  onReuseCreativesSelect?: any;
}

export default function TimesaverStep({
  onForwardButtonClick,
  onBackwordButtonClick,
  onClearDraftClick,
  handleAutodraftData,
  setOpen,
  state,
  setStep,
  onReuseCreativesSelect,
}: Props) {
  let autoSchedulerTitle = MEDIA_PLANNER.auto_scheduler;

  const {
    setCampaign,
    setState,
    setFacebook,
    setTiktok,
    setSnapchat,
    setSchedulerCreatives,
    availablePlatformTitle,
  }: any = useContext(AutoschudlerContext);

  const { presetsData, schedulerDraftData }: any = useAppSelector(
    (state: any) => state.autoSchedulerSlice
  );
  const dispatch: any = useAppDispatch();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [nestedDialog, setNestedDialog] = useState(false);
  const [isPresetDeleted, setIsPresetDeleted] = useState("");
  const [presetListData, setPresetListData] = useState([]);
  const [isNextButtonDisable, setIsNextButtonDisable] = useState(false);
  const [isDeleteButtonDisable, setIsDeleteButtonDisable] = useState(false);
  const [isPresetExist, setIsPresetExist] = useState(false);
  const [isClearDraftEnabled, setIsClearDraftEnabled] = useState(false);

  console.log("schedulerDraftDatqqqq ", !schedulerDraftData?.id);

  useEffect(() => {
    setIsClearDraftEnabled(!schedulerDraftData?.id);
  }, [schedulerDraftData]);

  useEffect(() => {
    if (state?.existingPresets) {
      setIsPresetDeleted("Delet");
    } else setIsPresetDeleted("");
  }, []);

  useEffect(() => {
    if (presetsData?.length) {
      setPresetListData(presetsData);
    }
  }, [presetsData]);

  useEffect(() => {
    const data = {
      isData: true,
    };
    if (!presetListData?.length) {
      dispatch(getAllPresets({ data }));
    }
  }, [state?.predefinedSettings]);

  useEffect(() => {
    if (state?.predefinedSettings) {
      if (state?.existingPresets) {
        if (state?.existingPresets?.id == 0 && !state?.presetName) {
          setIsNextButtonDisable(true);
        } else {
          setIsNextButtonDisable(false);
        }
      } else {
        setIsNextButtonDisable(true);
      }
    }
  }, [state]);

  const handledChoosePresets = (e: any) => {
    setIsPresetDeleted("Delet");
    const Id = e.target.value.id;
    if (Id != 0) {
      dispatch(CreativesData([]));
      setState({ ...state, existingPresets: e.target.value });
      setIsNextButtonDisable(false);
      dispatch(getSinglPresetsData({ Id }));
    }
    if (Id == 0) {
      // dispatch(SchedulerDraftData({}));
      setState({
        ...state,
        platform: ["Facebook", "Tiktok", "Snap"],
        bidStategy: "",
        countries: [],
        bundleCountries: false,
        batchName: "",
        scheduleDate: new Date(),
        reviewPageData: {},
        existingPresets: e.target.value,
        // predefinedSettings: false,
        // existingPresets: "",
        presetName: "",
        changePresetName: "",
        // reusePreviousBatch: false,
        settingPlatform: 0,
        autoLinkfire: false,
      });
      dispatch(CreativesData([]));
      setCampaign({
        bidStategy: "",
        countries: [],
        isBundleCountries: false,
        objective: "",
        budget: "20",
        bidCost: "",
        isCustomLanguage: false,
        customLanguages: {},
        bundlelanguages: [],
        group: "",
      });

      setSchedulerCreatives({
        creativesArray: [],
        files: [],
        creatives: [],
        updateCreative: [],
        batchName: "",
      });
      setSnapchat({
        advertiserAccount: "",
        existingAccount: "",
        profile: "",
        newAccount: "",
        activeButton: "Existing",
        autoGenerateName: true,
        customAudiences: false,
        selectedAudience: [],
        maximumCreative: 5,
        interest: [],
        caption: "",
        accelerateSpending: false,
        pixel: "",
        optimizeEvent: "",
        disableCampaignName: "",
        ageGroup: ["13-17", "18-24", "25-34", "35-44", "45+"],
        companyName: "",
        heading: "",
        profileEmail: "",
        firstName: "",
        lastName: "",
        adAccountName: "",
      });
      setTiktok({
        advertiserAccount: "",
        existingAccount: "",
        profile: "",
        newAccount: "",
        activeButton: "Existing",
        autoGenerateName: true,
        customAudiences: false,
        selectedAudience: [],
        identity: "",
        maximumCreative: 7,
        interest: [],
        caption: "",
        accelerateSpending: false,
        pixel: "",
        optimizeEvent: "",
        disableCampaignName: "",
        ageGroup: ["13-17", "18-24", "25-34", "35-44", "45-54", "55+"],
        heading: "",
        dayparsingString: [],
        dayparsingOffset: {},
        profileEmail: "",
        firstName: "",
        lastName: "",
        adAccountName: "",
        dayparting: false,
      });
      setFacebook({
        advantage: true,
        advertiserAccount: "",
        existingAccount: "",
        profile: "",
        newAccount: "",
        activeButton: "Existing",
        autoGenerateName: true,
        customAudiences: false,
        selectedAudience: [],
        maximumCreative: 5,
        interest: [],
        caption: "",
        accelerateSpending: false,
        pixel: "",
        optimizeEvent: "",
        disableCampaignName: "",
        ageGroup: ["13-17", "18-24", "25-34", "35-44", "45-54", "55+"],
        facebookPlacement: [],
        instagramPlacement: [],
        heading: "",
        facebookPage: "",
        instagramAccount: "",
        profileEmail: "",
        firstName: "",
        lastName: "",
        adAccountName: "",
        advantageAndPlacements: [],
      });
    }
  };

  const handledPresetDeleted = () => {
    setIsDeleteButtonDisable(true);
    if (state?.existingPresets?.id) {
      const Id = state?.existingPresets?.id;
      dispatch(deletePresetsData({ Id })).then((res: any) => {
        setIsPresetDeleted("Deleted");
        setIsDeleteButtonDisable(false);
        const data = {
          isData: true,
        };
        dispatch(getAllPresets({ data }));
        setState({ ...state, existingPresets: "" });
      });
    }
  };

  const handlePresetNameChange = (e: any) => {
    if (/\S/.test(e.target.value)) {
      setState({ ...state, presetName: e.target.value });
    } else setState({ ...state, presetName: "" });
  };

  const handleNextButton = () => {
    if (state?.predefinedSettings && state?.existingPresets?.id != 0) {
      {
        handleAutodraftData(4);
        setStep(4);
      }
    } else {
      onForwardButtonClick();
    }
  };

  const handledPredifinedCheck = (check: boolean) => {
    setState({
      ...state,
      predefinedSettings: check,
    });
    setIsNextButtonDisable(false);
    setCampaign({
      bidStategy: "",
      countries: [],
      isBundleCountries: false,
      objective: "",
      budget: "20",
      bidCost: "",
      isCustomLanguage: false,
      customLanguages: {},
      bundlelanguages: [],
      group: "",
    });
    setSnapchat({
      advertiserAccount: "",
      existingAccount: "",
      profile: "",
      newAccount: "",
      activeButton: "Existing",
      autoGenerateName: true,
      customAudiences: false,
      selectedAudience: [],
      maximumCreative: 5,
      interest: [],
      caption: "",
      accelerateSpending: false,
      pixel: "",
      optimizeEvent: "",
      disableCampaignName: "",
      ageGroup: ["13-17", "18-24", "25-34", "35-44", "45+"],
      companyName: "",
      heading: "",
      profileEmail: "",
      firstName: "",
      lastName: "",
      adAccountName: "",
    });
    setTiktok({
      advertiserAccount: "",
      existingAccount: "",
      profile: "",
      newAccount: "",
      activeButton: "Existing",
      autoGenerateName: true,
      customAudiences: false,
      selectedAudience: [],
      identity: "",
      maximumCreative: 5,
      interest: [],
      caption: "",
      accelerateSpending: false,
      pixel: "",
      optimizeEvent: "",
      disableCampaignName: "",
      ageGroup: ["13-17", "18-24", "25-34", "35-44", "45-54", "55+"],
      heading: "",
      dayparsingString: [],
      dayparsingOffset: {},
      profileEmail: "",
      firstName: "",
      lastName: "",
      adAccountName: "",
      dayparting: false,
    });
    setFacebook({
      advantage: true,
      advertiserAccount: "",
      existingAccount: "",
      profile: "",
      newAccount: "",
      activeButton: "Existing",
      autoGenerateName: true,
      customAudiences: false,
      selectedAudience: [],
      maximumCreative: 5,
      interest: [],
      caption: "",
      accelerateSpending: false,
      pixel: "",
      optimizeEvent: "",
      disableCampaignName: "",
      ageGroup: ["13-17", "18-24", "25-34", "35-44", "45-54", "55+"],
      facebookPlacement: [],
      instagramPlacement: [],
      heading: "",
      facebookPage: "",
      instagramAccount: "",
      profileEmail: "",
      firstName: "",
      lastName: "",
      adAccountName: "",
      advantageAndPlacements: [],
    });
    setSchedulerCreatives({
      creativesArray: [],
      files: [],
      creatives: [],
      updateCreative: [],
      batchName: "",
    });
    dispatch(CreativesData([]));
  };

  const handleNextButtonClick = () => {
    if (state?.existingPresets?.id == 0 && state?.predefinedSettings) {
      const temp = presetsData?.find(
        (item: any) =>
          item?.preset_name?.toLowerCase()?.trim() ==
          state?.presetName?.toLowerCase()?.trim()
      );

      if (temp) {
        setIsPresetExist(true);
      } else {
        setIsPresetExist(false);
        handleNextButton();
      }
    } else {
      handleNextButton();
    }
  };

  // const renderPresetList = (data: any) => {
  //   let isDisabled = false;
  //   const platforms = ["Tiktok", "Facebook", "Snapchat"];
  //   let temp: any = data?.schedule_presets_settings[0]?.preset_json_data
  //     ? data?.schedule_presets_settings[0]?.preset_json_data
  //     : [];

  //   for (const platform of platforms) {
  //     const platformData = temp?.[platform];

  //     if (
  //       platformData &&
  //       Object.keys(platformData).length > 0 &&
  //       !availablePlatformTitle.includes(platform)
  //     ) {
  //       isDisabled = true;
  //       break;
  //     }
  //   }

  //   return (
  //     <MenuItem value={data} disabled={isDisabled}>
  //       {data?.preset_name}
  //     </MenuItem>
  //   );
  // };

  const renderPresetList = (data: any) => {
    let isDisabled = true;
    const platforms = availablePlatformTitle;
    let temp: any = data?.schedule_presets_settings[0]?.preset_json_data
      ? data?.schedule_presets_settings[0]?.preset_json_data
      : [];

    for (const platform of platforms) {
      const platformData =
        platform === "Snap" ? temp?.["Snapchat"] : temp?.[platform];

      if (platformData && Object.keys(platformData).length > 0) {
        isDisabled = false;
        break;
      }
    }

    return (
      <MenuItem
        value={data}
        disabled={
          state?.predefinedSettings && state?.existingPresets?.id != 0
            ? false
            : isDisabled
        }
      >
        {data?.preset_name}
      </MenuItem>
    );
  };

  return (
    <Box>
      <Box>
        <Box sx={{ padding: 5 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            {autoSchedulerTitle.SPEED_UP}
          </Typography>
          <Typography variant="subtitle2" sx={{ mb: 2 }}>
            {autoSchedulerTitle.COVERED_IN_SCHEDULE}
          </Typography>
        </Box>
        <Grow in={true}>
          <Card sx={{ height: "100%", padding: 10 }}>
            <form noValidate autoComplete="off">
              <Box
                className="sync-flex-wrap"
                sx={{
                  mb: 2,
                  ml: 4,
                }}
              >
                <FormControlLabel
                  label={autoSchedulerTitle.REUSE_CREATIVES}
                  control={
                    <Checkbox
                      checked={state?.reusePreviousBatch}
                      onChange={() => {
                        setState({
                          ...state,
                          reusePreviousBatch: !state?.reusePreviousBatch,
                        });
                        setOpen(!state?.reusePreviousBatch);
                      }}
                    />
                  }
                />
              </Box>
              <Box
                sx={{
                  mb: 5,
                  ml: 4,
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  justifyContent: "space-between",
                }}
              >
                <FormControlLabel
                  label={autoSchedulerTitle.PRESET_USED}
                  control={
                    <Checkbox
                      checked={state?.predefinedSettings}
                      onChange={(e) => handledPredifinedCheck(e.target.checked)}
                    />
                  }
                />
              </Box>

              {state?.predefinedSettings && (
                <Grow in={true}>
                  <Box>
                    <Box sx={{ display: "flex" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            maxWidth: { md: 240, sm: 240, xs: "100%" },
                            width: { md: "40%", sm: "40%", xs: "100%" },
                            mb: 5,
                            ml: { md: 10, sm: 10, xs: 0 },
                            mt: 5,
                          }}
                        >
                          <FormControl fullWidth>
                            <InputLabel
                              shrink={
                                state?.existingPresets?.id == 0 ||
                                state?.existingPresets?.id
                                  ? true
                                  : false
                              }
                            >
                              Choose preset...
                            </InputLabel>
                            <Select
                              fullWidth
                              notched={true}
                              autoFocus={
                                presetListData?.find(
                                  (item: any) =>
                                    item?.id == state?.existingPresets?.id
                                )
                                  ? true
                                  : false
                              }
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              label={
                                state?.existingPresets?.id == 0 ||
                                state?.existingPresets?.id
                                  ? "Choose preset..."
                                  : ""
                              }
                              placeholder={
                                state?.existingPresets?.id == 0 ||
                                state?.existingPresets?.id
                                  ? "Choose preset..."
                                  : ""
                              }
                              value={
                                presetListData?.find(
                                  (item: any) =>
                                    item?.id == state?.existingPresets?.id
                                )
                                  ? presetListData?.find(
                                      (item: any) =>
                                        item?.id == state?.existingPresets?.id
                                    )
                                  : null
                              }
                              onChange={(e: any) => {
                                handledChoosePresets(e);
                              }}
                            >
                              {presetListData?.length == 0 && (
                                <Box className="sync-align-justify-center full-height-width">
                                  <CircularProgress />
                                </Box>
                              )}
                              {presetListData?.map((presetList: any) =>
                                renderPresetList(presetList)
                              )}
                            </Select>
                          </FormControl>
                        </Box>
                        <>
                          {isPresetDeleted == "Deleted" &&
                          state?.existingPresets?.id != 0 ? (
                            <Typography
                              sx={{
                                color: "rgba(103, 203, 36, 1)",
                                display: "flex",
                                alignItems: "center",
                                ml: 5,
                              }}
                            >
                              <DoneIcon /> {autoSchedulerTitle.PRESET_DELETE}
                            </Typography>
                          ) : (
                            isPresetDeleted == "Delet" &&
                            state?.existingPresets?.id != 0 && (
                              <Button
                                disabled={isDeleteButtonDisable}
                                sx={{ color: "rgba(255, 77, 73, 1)", ml: 5 }}
                                onClick={handledPresetDeleted}
                              >
                                <DeleteOutlineIcon />
                                Delete Preset
                              </Button>
                            )
                          )}
                        </>
                      </Box>
                    </Box>
                    {state?.existingPresets?.id === 0 && (
                      <Box sx={{ mt: 5, ml: { md: 10, sm: 10, xs: 0 }, mb: 5 }}>
                        <TextField
                          sx={{ width: { md: "auto", sm: "auto", xs: "100%" } }}
                          variant="outlined"
                          placeholder="Preset Name"
                          value={state?.presetName || ""}
                          label="Preset Name"
                          onChange={(e: any) => handlePresetNameChange(e)}
                        />
                        {isPresetExist && (
                          <FormHelperText error id="password-error">
                            {autoSchedulerTitle.PRESET_ALREADY_EXIST}
                          </FormHelperText>
                        )}
                      </Box>
                    )}
                  </Box>
                </Grow>
              )}

              <Box sx={{ mt: 5 }}>
                {state?.predefinedSettings && state?.reusePreviousBatch && (
                  <Grow in={true}>
                    <Alert severity="info" sx={{ mb: 4, maxWidth: 480 }}>
                      {autoSchedulerTitle.SETTINGS_OVERWRITTEN}
                    </Alert>
                  </Grow>
                )}

                <Divider />

                <Box
                  className="footer-button"
                  sx={{ justifyContent: { xs: "center", sm: "space-between" } }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                    }}
                    gap={2}
                  >
                    <Button
                      size="large"
                      type="submit"
                      variant="outlined"
                      onClick={onBackwordButtonClick}
                      color="secondary"
                      startIcon={<ArrowBackIcon />}
                    >
                      {autoSchedulerTitle.PLATFORMS}
                    </Button>
                    <Button
                      size="large"
                      // type="submit"
                      variant="contained"
                      onClick={() => handleNextButtonClick()}
                      disabled={isNextButtonDisable}
                      endIcon={<ArrowForwardIcon />}
                    >
                      {state?.predefinedSettings &&
                      state?.existingPresets?.id != 0
                        ? autoSchedulerTitle.CREATIVES
                        : autoSchedulerTitle.CAMPAIGN}
                    </Button>
                  </Box>
                  <Button
                    size="large"
                    variant="outlined"
                    onClick={onClearDraftClick}
                    sx={{ mt: { xs: 3, sm: 0 } }}
                    startIcon={<DeleteIcon />}
                    disabled={isClearDraftEnabled}
                  >
                    {autoSchedulerTitle.CLEAR_DRAFT}
                  </Button>
                </Box>
              </Box>
            </form>
          </Card>
        </Grow>
      </Box>

      {dialogOpen && (
        <Dialog
          fullWidth
          open={dialogOpen}
          scroll="body"
          onClose={() => setDialogOpen(false)}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "1000px",
              },
            },
          }}
        >
          <DialogContent>
            <PlatformTableDialog
              setDialogOpen={setDialogOpen}
              setNestedDialog={setNestedDialog}
            />
          </DialogContent>
        </Dialog>
      )}

      {nestedDialog && (
        <Dialog
          fullWidth
          open={nestedDialog}
          scroll="body"
          onClose={() => setNestedDialog(false)}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "1000px",
              },
            },
          }}
        >
          <DialogContent>
            <NestedTableDialog
              setDialogOpen={setDialogOpen}
              setNestedDialog={setNestedDialog}
              handleStatusUpdateClick={() => {}}
              onReuseCreativesSelect={onReuseCreativesSelect}
            />
          </DialogContent>
        </Dialog>
      )}
    </Box>
  );
}
