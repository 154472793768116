import { Box, Button } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";
import { MARKET_PLACE } from "@core/constants/title-constants";

export default function FooterButton(props: any) {
  const {
    state,
    forwardTitle,
    backwardTitle,
    isDisabled = false,
    handleNextSliderClick,
    handledBackwardButtonClick,
    handleClearDraftButtonClick,
  } = props;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: {
          md: "space-between",
          sm: "space-between",
          xs: "center",
        },
        flexWrap: "wrap",
      }}
    >
      <Box sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}>
        {backwardTitle && (
          <Button
            size="large"
            type="submit"
            variant="outlined"
            sx={{ mr: { sm: 0, md: 4 }, mb: { xs: 3, sm: 0 } }}
            onClick={handledBackwardButtonClick}
          >
            <ArrowBackIcon sx={{ mr: "10px" }} />
            {backwardTitle}
          </Button>
        )}
        {forwardTitle && (
          <Button
            size="large"
            disabled={isDisabled}
            sx={{ mb: { xs: 3, sm: 0 } }}
            type="submit"
            variant="contained"
            onClick={handleNextSliderClick}
          >
            {forwardTitle}
            <ArrowForwardIcon sx={{ ml: "10px" }} />
          </Button>
        )}
      </Box>
      <Button
        size="large"
        disabled={!state?.draft_id}
        variant="outlined"
        type="submit"
        onClick={() => handleClearDraftButtonClick()}
      >
        <DeleteIcon sx={{ mr: "10px" }} />
        {MARKET_PLACE.REQUEST_CONTENT.CLEAR_DRAFT}
      </Button>
    </Box>
  );
}
