// ** React Imports
import React from "react";

// ** MUI Imports
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
} from "@mui/material";

// ** Icons
import CancelIcon from "@mui/icons-material/Cancel";
import TiktokIcon from "icon/tiktok";
import FacebookIcon from "icon/facebook";
import SnapchatIcon from "icon/snapchat";

interface Props {
  open: any;
  handleClose: any;
  platform: any;
  profiles: any;
  onReconnect: any;
  handledRemoveProfile: any;
}

export default function ConnectionLostDialog({
  open,
  handleClose,
  platform,
  profiles,
  onReconnect,
  handledRemoveProfile,
}: Props) {
  const getPlatformIcon = (val: any) => {
    if (platform === "facebook") {
      return <FacebookIcon active={val?.is_connection_established} />;
    } else if (platform === "tiktok") {
      return <TiktokIcon active={val?.is_connection_established} />;
    } else if (platform === "snapchat") {
      return <SnapchatIcon active={val?.is_connection_established} />;
    }
    return null;
  };
  const handleUnlinkProfile = (id: any) => {
    handledRemoveProfile(id);
  };

  return (
    <Dialog
      fullWidth
      open={open}
      maxWidth="xs"
      scroll="body"
      onClose={handleClose}
    >
      <DialogContent>
        <Box my={3}>
          <Box>
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              Access lost
            </Typography>
            <Typography variant="subtitle2" sx={{ fontWeight: 400, mt: 1 }}>
              Reconnect the following profiles to restore access.
            </Typography>
          </Box>
          <Box my={4}>
            {profiles.map((val: any, index: any) => (
              <Grid container spacing={2} mt={2} key={index}>
                <Grid item md={8} sm={8} xs={12}>
                  <Box
                    display={"flex"}
                    flexDirection={"row"}
                    alignItems={"center"}
                    gap={3}
                  >
                    <Box
                      sx={{
                        background: val?.is_connection_established
                          ? "green"
                          : "red",
                        height: "9px",
                        width: "9px",
                        borderRadius: "5px",
                      }}
                    />
                    {getPlatformIcon(val)}
                    <Box>
                      <Typography variant="body2" sx={{ fontWeight: 400 }}>
                        {val?.first_name + " " + val?.last_name}
                      </Typography>
                      <Typography
                        variant="body2"
                        sx={{
                          fontWeight: 400,
                          color: "rgba(76, 78, 100, 0.38)",
                        }}
                      >
                        {val?.social_profile_id || ""}
                      </Typography>
                    </Box>
                    {!val?.is_connection_established && (
                      <IconButton onClick={() => handleUnlinkProfile(val?.id)}>
                        <CancelIcon
                          fontSize="small"
                          sx={{ color: "rgba(76, 78, 100, 0.26)" }}
                        />
                      </IconButton>
                    )}
                  </Box>
                </Grid>
                <Grid item md={4} sm={4} xs={12}>
                  <Button
                    size="medium"
                    disabled={val?.is_connection_established}
                    variant={
                      val?.is_connection_established ? "contained" : "outlined"
                    }
                    onClick={onReconnect}
                  >
                    RECONNECT
                  </Button>
                </Grid>
              </Grid>
            ))}
          </Box>
          <Box display={"flex"} mt={7} gap={2}>
            <Button
              fullWidth
              size="large"
              variant="outlined"
              onClick={handleClose}
            >
              CANCEL
            </Button>
            <Button
              fullWidth
              size="large"
              variant="contained"
              onClick={handleClose}
            >
              FINISH
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
