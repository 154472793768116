import { useEffect, useState } from "react";
import { Box, Typography, useTheme, Grid, Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "store/store";
import { getVideoOtp } from "store/Thunk/createJobThunk";
import { STATUS } from "@core/constants/enum";
import SubmissionStatusCard from "pages/marketplace/create-content/SubmissionStatusCard";
import VideoModal from "components/Dialogs/VideoModal";
import { route_info } from "navigation/route";
import { BlankImage, VideoPlay, Submission } from "assets";
import { MARKET_PLACE } from "@core/constants/title-constants";

export default function DetailSubmissionCard(props: any) {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const navigate = useNavigate();

  const { cardData, handleResubmitVideo } = props;

  const submissionStatus = cardData?.submission?.approval_status?.length
    ? cardData?.submission?.approval_status[0].approval_status
    : STATUS.APPROVAL_NEEDED;

  const [openVideoModal, setOpenVideoModal] = useState<boolean>(false);

  const handleViewEarning = () => {
    navigate(route_info.market_place.my_earnings);
  };

  const status = () => {
    switch (submissionStatus) {
      case STATUS.APPROVAL_NEEDED:
      case STATUS.CREATOR_POST_PENDING:
      case STATUS.POST_CONFIRMATION_PENDING:
        return (
          <>
            <Box>
              <Typography
                variant="body1"
                color={theme.palette.customColors.comet}
              >
                {MARKET_PLACE.CREATE_CONTENT.VIEWS}
              </Typography>
              <Typography
                variant="body1"
                color={theme.palette.customColors.comet}
              >
                -
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="body1"
                color={theme.palette.customColors.comet}
              >
                {MARKET_PLACE.CREATE_CONTENT.MILESTONES}
              </Typography>
              <Typography
                variant="body1"
                color={theme.palette.customColors.comet}
              >
                -
              </Typography>
            </Box>
            <Box>
              <Typography
                variant="body1"
                color={theme.palette.customColors.comet}
              >
              {MARKET_PLACE.CREATE_CONTENT.EARNED}
              </Typography>
              <Typography
                variant="body1"
                color={theme.palette.customColors.comet}
              >
                -
              </Typography>
            </Box>
            <Button
              sx={{ mt: "30px" }}
              variant="text"
              onClick={() => handleViewEarning()}
            >
               {MARKET_PLACE.CREATE_CONTENT.VIEW_EARNINGS}
            </Button>
          </>
        );
      case STATUS.ACCEPTED:
        return (
          <>
            {cardData.publish_content_type === "collect_video_and_post" ? (
              <>
                <Box>
                  <Typography
                    variant="body1"
                    color={theme.palette.customColors.comet}
                    sx={{}}
                  >
                    {MARKET_PLACE.CREATE_CONTENT.VIEWS}
                  </Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "700" }}
                    color={theme.palette.customColors.comet}
                  >
                    {cardData.view_count}
                  </Typography>
                </Box>
                <Box>
                  <Typography
                    variant="body1"
                    color={theme.palette.customColors.comet}
                  >
                    {MARKET_PLACE.CREATE_CONTENT.MILESTONES}
                  </Typography>
                  <Typography
                    sx={{ fontSize: "16px", fontWeight: "700" }}
                    color={theme.palette.customColors.comet}
                  >
                    {"1 to " + cardData?.milestone?.length}
                  </Typography>
                </Box>
              </>
            ) : null}
            <Box>
              <Typography
                variant="body1"
                color={theme.palette.customColors.comet}
              >
              {MARKET_PLACE.CREATE_CONTENT.EARNED}
              </Typography>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "700" }}
                color={theme.palette.customColors.comet}
              >
                -
              </Typography>
            </Box>
            <Button
              sx={{ mt: "30px" }}
              variant="text"
              onClick={() => handleViewEarning()}
            >
              {MARKET_PLACE.CREATE_CONTENT.VIEW_EARNINGS}
            </Button>
          </>
        );
      case STATUS.DECLINED:
        return (
          <>
            <Box>
              <Typography>{MARKET_PLACE.CREATE_CONTENT.FEEDBACK}</Typography>
              <Typography
                color={theme.palette.customColors.comet}
                sx={{
                  fontWeight: "700",
                  fontSize: "16px",
                }}
              >
                “{MARKET_PLACE.CREATE_CONTENT.THE_CREATOR_DID_NOT_SHOW}”
              </Typography>
            </Box>

            <Box>
              <Button
                variant="text"
                className="resubmit-btn"
                onClick={handleResubmitVideo}
              >
                {MARKET_PLACE.CREATE_CONTENT.RESUBMIT_VIDEO}
              </Button>
              <Typography>
                {MARKET_PLACE.CREATE_CONTENT.MAKET_SURE_THAT_YOU_FOLLOW}
              </Typography>
            </Box>
          </>
        );
      default:
        return <h1>{MARKET_PLACE.CREATE_CONTENT.NO_SUBMISSION_FOUND}</h1>;
    }
  };

  useEffect(() => {
    let formData = new FormData();

    formData.append("video_id", cardData?.submission.video_id);
    dispatch(getVideoOtp({ formData }));
  }, []);

  const handleVideoPlay = () => {
    setOpenVideoModal(!openVideoModal);
  };

  const handleClose = () => {
    setOpenVideoModal(false);
  };

  return (
    <Box className="post-type-block">
      <Box className="jobmilestone-block">
        <Box
          className="milestone-img"
          component="img"
          alt="Submission image not found"
          src={Submission}
        />
        <Typography
          variant="h6"
          color={theme.palette.customColors.comet}
          sx={{ ml: "10px" }}
        >
          {MARKET_PLACE.CREATE_CONTENT.YOUR_SUBMISSION}
        </Typography>
      </Box>
      <Box>
        <Grid container>
          <Grid item md={6}>
            <Box className="submission-left-block">
              {cardData?.submission?.vdo_payload?.posters?.length &&
              cardData?.submission?.vdo_payload?.posters[0]?.url ? (
                <Box
                  component="img"
                  src={
                    cardData?.submission?.vdo_payload?.posters?.length &&
                    cardData?.submission?.vdo_payload?.posters[0]?.url
                  }
                  sx={{
                    height: "300px",
                  }}
                />
              ) : (
                <Box
                  sx={{
                    height: "300px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Box
                    component="img"
                    src={BlankImage}
                    sx={{ height: "40px", width: "40px" }}
                  />
                </Box>
              )}

              <Box className="left-absolute-block">
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      mb: "10px",
                    }}
                  >
                    <Box
                      component="img"
                      src={
                        cardData?.submission?.creator?.profile_picture_url || ""
                      }
                      sx={{
                        height: "24px",
                        width: "24px",
                      }}
                    />
                    <Typography
                      variant="body2"
                      color={theme.palette.customColors.white}
                      sx={{ ml: "10px" }}
                    >
                      {`@${cardData?.submission?.creator?.display_name}` || ""}
                    </Typography>
                  </Box>
                  <Typography
                    variant="subtitle2"
                    color={theme.palette.customColors.white}
                  >
                    {cardData?.brand_details?.brand_name}
                  </Typography>
                </Box>
                <Box
                  onClick={() => handleVideoPlay()}
                  component="img"
                  src={VideoPlay}
                  className="video-btn"
                />
                <SubmissionStatusCard status={submissionStatus} />
              </Box>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box className="status-block">{status()}</Box>
          </Grid>
        </Grid>
        <VideoModal isOpen={openVideoModal} handleClose={handleClose} />
      </Box>
    </Box>
  );
}
