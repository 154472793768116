import { Background } from "assets";
import Box, { BoxProps } from '@mui/material/Box'
import { styled, useTheme } from '@mui/material/styles'
import FooterIllustrationsV2 from "./FooterIllustrationsV2";
import BlankLayout from "@core/layouts/BlankLayout";
import {  CardContent } from "@mui/material";
import MuiCard, { CardProps } from '@mui/material/Card'

const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  maxWidth: "400px",
  [theme.breakpoints.down("md")]: {
    maxWidth: 400,
  },
}));

const Card = styled(MuiCard)<CardProps>(({ theme }) => ({
  [theme.breakpoints.up('sm')]: { width: 450 }
}))


export default function ScreenLayout({children}: any){
    return (
      <BlankLayout>
      <Box className='content-center'>
        <Card sx={{ zIndex: 1 }}>
          <CardContent sx={{ p: theme => `${theme.spacing(13, 7, 6.5)} !important` }}>
          {children}
          </CardContent>
        </Card>
        <FooterIllustrationsV2 />
      </Box>
    </BlankLayout>
    )
}