// ** React
import { Fragment, useCallback, useContext, useEffect, useState } from "react";

// ** MUI Imports
import {
  Alert,
  Box,
  Button,
  Card,
  Chip,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Grow,
  IconButton,
  InputLabel,
  LinearProgress,
  LinearProgressProps,
  List,
  ListItem,
  MenuItem,
  Radio,
  Select,
  styled,
  Switch,
  TextField,
  Tooltip,
  Typography,
  TypographyProps,
  Snackbar,
} from "@mui/material";
import Slide, { SlideProps } from "@mui/material/Slide";

// ** Third Party Library
import toast from "react-hot-toast";
import { useDropzone } from "react-dropzone";

// ** Redux
import { useAppDispatch, useAppSelector } from "store/store";
import { getAllScraperGroup } from "store/Thunk/platformConnectionThunk";
import { AutoschudlerContext } from "context/autoschedulerContex";

// ** Custom Component
import DropdownNotFound from "components/DropdownNotFound/DropdownNotFound";
import CustomChip from "@core/components/mui/chip";
import { MEDIA_PLANNER } from "@core/constants/title-constants";
import { UPLOAD_TYPE, PLACEMENT_TYPE } from "@core/constants/enum";

// ** Mui Icons
import AddIcon from "@mui/icons-material/Add";
import Close from "mdi-material-ui/Close";
import FileDocumentOutline from "mdi-material-ui/FileDocumentOutline";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

// ** Images
import {
  PixelMelonSitting,
  Tiktok,
  MediaPlannerMeta,
  MediaPlannerSnapchat,
  DragDropImage,
} from "assets";

interface FileProp {
  name: string;
  type: string;
  size: number;
}

type TransitionProps = Omit<SlideProps, "direction">;

const HeadingTypography = styled(Typography)<TypographyProps>(({ theme }) => ({
  marginBottom: theme.spacing(5),
  [theme.breakpoints.down("sm")]: {
    marginBottom: theme.spacing(4),
  },
}));

const FileUploaderRestrictions = ({
  files,
  setFiles,
  handledAcceptedFiles,
  handleRemoveFile,
  creatives,
  creativesArray,
  handleValueChange,
  handleButton,
  updateCreative,
  handleApplyToAll,
  showApplyToAll,
  platform,
  landingUrlError,
}: any) => {
  const dispatch = useAppDispatch();
  let autoSchedulerTitle = MEDIA_PLANNER.auto_scheduler;

  const { scraperGroupData } = useAppSelector(
    (state: any) => state.PlatformSlice
  );

  const {
    autoLinkfire,
    setAutoLinkfire,
    campaign,
    availablePlatformTitle,
  }: any = useContext(AutoschudlerContext);

  const [groupLoading, setGroupLoading] = useState(false);
  const [groupId, setGroupId] = useState("");
  const [userEmail, setUserEmail] = useState<String>("");

  const TransitionDown = (props: TransitionProps) => {
    return <Slide {...props} direction="down" />;
  };

  useEffect(() => {
    const header: any = localStorage.getItem("header");
    if (header) setUserEmail(JSON.parse(header).user);
  }, []);

  useEffect(() => {
    let data: any = null;
    dispatch(getAllScraperGroup({ data })).then((res: any) => {
      if (res?.payload?.data?.length) {
        setGroupLoading(false);
      } else {
        setGroupLoading(true);
      }
    });
  }, []);

  useEffect(() => {
    setGroupId(campaign?.group);
  }, [campaign?.group]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: platform.includes("Facebook")
      ? {
          "image/*": [".png", ".jpg", ".jpeg", ".gif"],
          "video/*": [".mp4"],
        }
      : {
          "video/*": [".mp4"],
        },
    onDrop: (acceptedFiles: File[]) => {
      let temp = acceptedFiles.map((file: File) => Object.assign(file));
      handledAcceptedFiles([...files, ...temp]);
    },
    onDropRejected: () => {
      toast.error(autoSchedulerTitle.UPLOAD_ONLY_MAX_SIZE, {
        duration: 2000,
      });
    },
  });

  const renderFilePreview = (file: FileProp) => {
    if (file?.type?.startsWith("image")) {
      return (
        <Box
          component="img"
          width={38}
          height={38}
          src={URL.createObjectURL(file as any)}
        />
      );
    } else {
      return <FileDocumentOutline />;
    }
  };

  const handleFilePreview = (file: any) => {
    if (file?.url != "") {
      return (
        <>
          {file?.creative_type == UPLOAD_TYPE.VIDEO ? (
            <video
              width={38}
              height={38}
              style={{
                borderRadius: "10px",
                padding: "3px",
                border: `1px solid rgba(93, 89, 98, 0.14)`,
              }}
              src={file?.url}
            />
          ) : (
            <Box component="img" width={38} height={38} src={file?.url} />
          )}
        </>
      );
    } else {
      return <FileDocumentOutline />;
    }
  };

  const fileList = (creativesArray: any) => {
    if (creativesArray.length) {
      return creativesArray?.map((file: any, index: any) => (
        <Grid
          container
          columnSpacing={2}
          rowSpacing={2}
          className="sync-align-center"
        >
          <Grid item xs={12} sm={12} md={7}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                margin: 1,
                padding: "10px",
                border: `1px solid rgba(93, 89, 98, 0.14)`,
                borderRadius: 1,
                width: "100%",
              }}
            >
              <Box
                key={file?.filename}
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: { md: "row", sm: "row", xs: "column" },
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      width: "100%",
                      mb: 1,
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        mr: 2,
                        height: 38,
                        width: 38,
                      }}
                    >
                      {handleFilePreview(file)}
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        overflow: "hidden",
                      }}
                    >
                      <Typography
                        variant="subtitle2"
                        sx={{ fontWeight: "600" }}
                      >
                        {file?.filename?.slice(0, 20) +
                          (file?.filename?.length > 20 ? "..." : "")}
                      </Typography>
                      <Typography variant="subtitle2">
                        {file?.creative_size}
                      </Typography>
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      justifyContent: {
                        md: "flex-end",
                        sm: "flex-end",
                        xs: "space-between",
                      },
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mr: 2,
                        pl: 1,
                      }}
                    >
                      {file?.creative_type === UPLOAD_TYPE.VIDEO &&
                        file?.placement_type === PLACEMENT_TYPE.STORY &&
                        availablePlatformTitle.includes("Tiktok") && (
                          <Box
                            component="img"
                            src={Tiktok}
                            sx={{ height: "20px", width: "20px", mr: "10px" }}
                          />
                        )}
                      {(file?.creative_type === UPLOAD_TYPE.VIDEO ||
                        file?.creative_type === UPLOAD_TYPE.IMAGE) &&
                        availablePlatformTitle.includes("Facebook") && (
                          <Box
                            component="img"
                            src={MediaPlannerMeta}
                            sx={{ height: "20px", width: "20px", mr: "10px" }}
                          />
                        )}

                      {file?.creative_type === UPLOAD_TYPE.VIDEO &&
                        file?.placement_type === PLACEMENT_TYPE.STORY &&
                        availablePlatformTitle.includes("Snap") && (
                          <Box
                            component="img"
                            src={MediaPlannerSnapchat}
                            sx={{ height: "20px", width: "20px", mr: "10px" }}
                          />
                        )}
                      <CustomChip
                        size="small"
                        skin="light"
                        color="info"
                        label={`${file?.placement_type} format`}
                        sx={{
                          "& .MuiChip-label": { textTransform: "capitalize" },
                        }}
                      />
                    </Box>
                    <Box>
                      <IconButton
                        onClick={() => handleRemoveFile(file)}
                        sx={{ p: 0, m: 0 }}
                      >
                        <Close fontSize="small" />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={
              !autoLinkfire && showApplyToAll && creativesArray.length > 1
                ? 8
                : 12
            }
            md={3}
          >
            {autoLinkfire ? (
              index == 0 && (
                <Grow in={true}>
                  <FormControl fullWidth sx={{ pl: "5px" }}>
                    <InputLabel>{autoSchedulerTitle.GROUP}</InputLabel>
                    <Select
                      variant="outlined"
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      placeholder={autoSchedulerTitle.LABELS}
                      label={autoSchedulerTitle.GROUP}
                      value={groupId}
                      disabled={true}
                    >
                      {scraperGroupData.length == 0 && groupLoading && (
                        <DropdownNotFound name={"group"} />
                      )}
                      {scraperGroupData.length == 0 && !groupLoading && (
                        <Box className="sync-align-justify-center full-height-width">
                          <CircularProgress />
                        </Box>
                      )}
                      {scraperGroupData?.map((item: any) => (
                        <MenuItem value={item?.id} key={item?.id?.toString()}>
                          <Box className="sync-align-center">
                            <Box
                              component="img"
                              src={item?.profile_url}
                              style={{
                                height: "20px",
                                width: "20px",
                                marginRight: "5px",
                                borderRadius: "20px",
                              }}
                              loading="lazy"
                            />
                            <Typography sx={{ fontSize: "14px" }}>
                              {item?.group_name}
                            </Typography>
                          </Box>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grow>
              )
            ) : (
              <Grow in={true}>
                <Box>
                  <TextField
                    sx={{ margin: 1 }}
                    fullWidth
                    name="Landingpage"
                    value={file?.landingpage_url}
                    error={landingUrlError.length && landingUrlError[index]}
                    helperText={
                      landingUrlError.length && landingUrlError[index]
                        ? landingUrlError[index]
                        : ""
                    }
                    onChange={(e: any) =>
                      handleValueChange(
                        file?.id,
                        file?.uploadsesid,
                        e?.target?.value,
                        "Landingpage"
                      )
                    }
                    placeholder={autoSchedulerTitle.LANDINGPAGE}
                  />
                </Box>
              </Grow>
            )}
          </Grid>
          {!autoLinkfire && showApplyToAll && creativesArray.length > 1 && (
            <Grid item xs={12} sm={4} md={2}>
              {index == 0 && (
                <Button onClick={() => handleApplyToAll()}>
                  {autoSchedulerTitle.APPLY_TO_ALL}
                </Button>
              )}
            </Grid>
          )}
        </Grid>
      ));
    } else {
      return null;
    }
  };

  const calculateSize = (file: any) => {
    return Math.round(file?.size / 100) / 10 > 1000
      ? `${(Math.round(file?.size / 100) / 10000).toFixed(1)} mb`
      : `${(Math.round(file?.size / 100) / 10).toFixed(1)} kb`;
  };

  const creativeStatus = (creative: any, file: any) => {
    if (creative?.isError) {
      return (
        <Tooltip title={creative?.errorMessage}>
          <ErrorOutlineIcon color="error" />
        </Tooltip>
      );
    } else if (creative?.isWarning) {
      return (
        <Tooltip title={creative?.warningMessage}>
          <WarningAmberIcon color="warning" />
        </Tooltip>
      );
    } else if (creative?.isSucess) {
      return <TaskAltIcon color="success" />;
    } else if (creative?.name == file?.name) {
      return <LinearProgress />;
    }
  };

  return (
    <Fragment>
      <>
        <>
          <Grow in={true}>
            <Box>
              <Box
                sx={{
                  backgroundColor: "#fff",
                  padding: 10,
                  borderRadius: "10px",
                  boxShadow: "0px 2px 10px 0px rgb(76 78 100 / 22%)",
                }}
              >
                <Box className="align-justify-item-center" sx={{ mb: 5 }}>
                  <Chip
                    label={autoSchedulerTitle.DROP_CREATIVE}
                    sx={{ flexGrow: 0 }}
                  />
                  <Divider sx={{ flexGrow: 1 }} />
                </Box>
                <Box className="dropzone" {...getRootProps()}>
                  {/* <Box className="dropzone"> */}
                  {files && files?.length > 0 ? (
                    <div
                      className="file-details-container"
                      style={{ margin: "50px" }}
                    >
                      {files &&
                        files?.length > 0 &&
                        files?.map((file: FileProp, index: number) => (
                          <Box>
                            <ListItem key={file?.name}>
                              <div className="file-details-planner">
                                <div className="file-preview-container">
                                  <div className="file-preview">
                                    {renderFilePreview(file)}
                                  </div>
                                  <Typography
                                    className="file-name"
                                    noWrap={true}
                                  >
                                    {file?.name}
                                  </Typography>
                                  <Typography
                                    className="file-size"
                                    variant="body2"
                                  >
                                    {calculateSize(file)}
                                  </Typography>
                                  {creativeStatus(creatives[index], file)}
                                </div>
                              </div>
                              {/* {!creatives[index]?.isError &&
                              creatives[index]?.isSucess && (
                                <IconButton
                                  onClick={() => handleRemoveFile(file)}
                                >
                                  <Close fontSize="small" />
                                </IconButton>
                              )} */}
                            </ListItem>
                          </Box>
                        ))}
                      <Box
                        sx={{
                          mt: "8px",
                          ml: "17px",
                          height: "10px",
                          minHeight: "120px !important",
                          width: "120px",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: "10px",
                          borderStyle: "dashed",
                          borderColor: "#ccc",
                        }}
                        // {...getRootProps({ className: "dropzone" })}
                      >
                        <AddIcon fontSize="large" color="action" />
                      </Box>
                    </div>
                  ) : (
                    <Box>
                      <input {...getInputProps()} />
                      <Box
                        className="sync-align-center"
                        sx={{
                          flexDirection: ["column", "column", "row"],
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          component="img"
                          width={"250px"}
                          height={"188px"}
                          style={{ margin: 36, marginRight: 60 }}
                          alt="Upload img"
                          src={DragDropImage}
                        />
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            textAlign: ["center", "center", "inherit"],
                          }}
                        >
                          <HeadingTypography variant="h5">
                            Drop files here or click to upload.
                          </HeadingTypography>
                          <Typography color="textSecondary">
                            {platform.includes("Facebook")
                              ? "Videos and images allowed"
                              : "Videos allowed"}
                          </Typography>
                          <Typography color="textSecondary">
                            {platform.includes("Facebook")
                              ? "Max video upload 512M per creative and max image uplaod 16M per creative"
                              : "Max video upload 512M per creative"}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  )}
                </Box>
                {creativesArray && !creativesArray?.length && handleButton()}
              </Box>
              {/* {creativesArray?.length == 0 && (handleButton())} */}
            </Box>
          </Grow>
        </>
        <Box>
          {creativesArray && creativesArray?.length ? (
            <>
              <Grow in={true}>
                <List>
                  <Box
                    sx={{
                      backgroundColor: "#fff",
                      mt: 10,
                      padding: 10,
                      borderRadius: "10px",
                      boxShadow: "0px 2px 10px 0px rgb(76 78 100 / 22%)",
                    }}
                  >
                    <Box className="align-justify-item-center" sx={{ mb: 5 }}>
                      <Chip label="2. Landingpages" sx={{ flexGrow: 0 }} />
                      <Divider sx={{ flexGrow: 1 }} />
                    </Box>
                    <Box>
                      <Grid container spacing={2} className="sync-align-center">
                        <Grid item xs={12} sm={6} md={8}>
                          <Typography>
                            {
                              // creativesData[0]?.creative_placement_type_counting
                              //   ?.length
                              creativesArray?.length
                            }{" "}
                            creatives uploaded
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={4}>
                          {userEmail === "nilays@kanhasoft.com" ? (
                            <FormControlLabel
                              value="Other"
                              control={
                                <Switch
                                  onChange={(e: any) =>
                                    setAutoLinkfire(e.target.checked)
                                  }
                                  checked={autoLinkfire}
                                />
                              }
                              label="Auto-generate linkfires"
                            />
                          ) : null}
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={{ mt: 4 }}>{fileList(creativesArray)}</Box>
                    {/* {!autoLinkfire && handleButton()} */}
                  </Box>
                </List>
              </Grow>
            </>
          ) : null}
        </Box>
      </>
    </Fragment>
  );
};

export default FileUploaderRestrictions;
