// ** React Imports
import { useEffect, useState } from "react";

// ** MUI Imports
import {
  Box,
  Switch,
  Typography,
  TextField,
  Grid,
  Divider,
  FormControlLabel,
  Grow,
} from "@mui/material";

// ** Icons
import Autocomplete from "@mui/material/Autocomplete";

// ** Custom COmponents
import { MARKET_PLACE } from "@core/constants/title-constants";
import FooterButton from "components/FooterButton/footerButtton";

interface Props {
  onForwardButtonClick(): void;
  onBackwardButtonClick?(): void;
  onClearDraftButtonClick?(): void;
  state: any;
  setState: any;
}

export default function Requirements({
  onForwardButtonClick,
  onBackwardButtonClick,
  onClearDraftButtonClick,
  state,
  setState,
}: Props) {
  let requirements = state?.requirements;

  let publishContentTypeCreateJobData = state?.placement?.publishContentType;

  const [isAllError, setIsAllError] = useState(false);
  const [allErrorMsg, setAllErrorMsg] = useState<string>("");
  const [inputValue, setInputValue] = useState<any>("");
  const [hashtagValue, setHashtagValue] = useState<any>(
    requirements?.hashtags || []
  );
  const [taggedAccountInputValue, setTaggedAccountInputValue] =
    useState<any>("");
  const [taggedAccountValue, setTaggedAccountValue] = useState<any>(
    requirements?.taggedAccountValue || []
  );

  useEffect(() => {
    setIsAllError(false);
  }, [requirements]);

  useEffect(() => {
    setState({
      ...state,
      requirements: {
        ...requirements,
        hashtags: hashtagValue,
        taggedAccountValue: taggedAccountValue,
      },
    });
  }, [hashtagValue, taggedAccountValue]);

  const handleNext = () => {
    if (publishContentTypeCreateJobData === "collect_video_and_post") {
      if (requirements?.hashtag && !requirements?.hasOwnProperty("hashtags")) {
        setIsAllError(true);
        setAllErrorMsg("Hashtag field is requried");
      } else if (
        requirements?.caption &&
        !requirements?.hasOwnProperty("captionValue")
      ) {
        setIsAllError(true);
        setAllErrorMsg("Caption field is requried");
      } else if (
        requirements?.taggedAccount &&
        !requirements?.hasOwnProperty("taggedAccountValue")
      ) {
        setIsAllError(true);
        setAllErrorMsg("Tagged accounts field is requried");
      } else if (
        requirements?.videoLength &&
        !requirements?.hasOwnProperty("videoLengthValue")
      ) {
        setIsAllError(true);
        setAllErrorMsg("Video length field is requried");
      } else if (
        requirements?.footage &&
        !requirements?.hasOwnProperty("footageValue")
      ) {
        setIsAllError(true);
        setAllErrorMsg("Link to footage is required");
      } else if (
        requirements?.sound &&
        !requirements?.hasOwnProperty("soundValue")
      ) {
        setIsAllError(true);
        setAllErrorMsg("Link to sound is required");
      } else onForwardButtonClick();
    } else {
      if (
        requirements?.videoLength &&
        !requirements?.hasOwnProperty("videoLengthValue")
      ) {
        setIsAllError(true);
        setAllErrorMsg("Video length field is requried");
      } else if (
        requirements?.footage &&
        !requirements?.hasOwnProperty("footageValue")
      ) {
        setIsAllError(true);
        setAllErrorMsg("Footage download link field is requried");
      } else if (
        requirements?.sound &&
        !requirements?.hasOwnProperty("soundValue")
      ) {
        setIsAllError(true);
        setAllErrorMsg("Sound download link field is requried");
      } else onForwardButtonClick();
    }
  };

  const handleChangeSwitch = (event: any, checked: boolean) => {
    setState({
      ...state,
      requirements: {
        ...state.requirements,
        [event.target.name]: checked,
      },
    });
  };

  const handleInputChange = (event: any) => {
    setState({
      ...state,
      requirements: {
        ...state.requirements,
        [event.target.name]: event.target.value,
      },
    });
  };

  return (
    <Box className="requirement-block">
      <Typography variant="h5" sx={{ mb: 2 }}>
        {MARKET_PLACE.REQUEST_CONTENT.JOB_REQUIREMENT}
      </Typography>
      <Typography variant="subtitle2" sx={{ mb: 4 }}>
        {MARKET_PLACE.REQUEST_CONTENT.JOB_REQUIREMENT_SUBTITLE}
      </Typography>
      <Grow in={true}>
        <Box className="borderRadiusSpecific box-border">
          <Grid container className="swtich-btn">
            <Grid item xs={12} md={5}>
              {publishContentTypeCreateJobData === "collect_video_and_post" ? (
                <>
                  <Box sx={{ mb: "20px" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={Boolean(requirements?.hashtag)}
                          onChange={handleChangeSwitch}
                          inputProps={{
                            "aria-label": "controlled",
                            name: "hashtag",
                          }}
                        />
                      }
                      label={
                        Boolean(requirements?.hashtag)
                          ? "Use specific hashtags (max. 7 hashtags)"
                          : "Use specific hashtags"
                      }
                    />

                    {Boolean(requirements?.hashtag) && (
                      <Grow in={true}>
                        <Autocomplete
                          limitTags={7}
                          multiple
                          freeSolo
                          id="tags-standard"
                          options={[]}
                          value={hashtagValue}
                          inputValue={inputValue}
                          onChange={(event: any, newValue: any) => {
                            setHashtagValue(newValue);
                          }}
                          onInputChange={(event: any, newInputValue: any) => {
                            const options = newInputValue.split(",");

                            if (options.length > 1) {
                              if (hashtagValue.length <= 6) {
                                setHashtagValue(
                                  hashtagValue
                                    .concat(options)
                                    .map((x: any) => x.trim())
                                    .filter((x: any) => x)
                                );

                                setInputValue("");
                              }
                            } else {
                              setInputValue(newInputValue);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="hashtags"
                              placeholder="Separate by comma: #kanhasoft, #ai"
                            />
                          )}
                        />
                      </Grow>
                    )}
                  </Box>
                  <Box sx={{ mb: "20px" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={Boolean(requirements?.caption)}
                          onChange={handleChangeSwitch}
                          inputProps={{
                            "aria-label": "controlled",
                            name: "caption",
                          }}
                        />
                      }
                      label={
                        Boolean(requirements?.caption)
                          ? "Use a specific caption (max. 200 characters)"
                          : "Use a specific caption"
                      }
                    />

                    {Boolean(requirements?.caption) && (
                      <Grow in={true}>
                        <TextField
                          fullWidth
                          id="caption-input"
                          name="captionValue"
                          multiline
                          rows={4}
                          maxRows={5}
                          value={requirements?.captionValue}
                          placeholder="Your caption here"
                          label="Caption"
                          inputProps={{ maxLength: 200 }}
                          onChange={handleInputChange}
                        />
                      </Grow>
                    )}
                  </Box>

                  <Box sx={{ mb: "20px" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={Boolean(requirements?.taggedAccount)}
                          onChange={handleChangeSwitch}
                          inputProps={{
                            "aria-label": "controlled",
                            name: "taggedAccount",
                          }}
                        />
                      }
                      label={
                        Boolean(requirements?.caption)
                          ? "Use tagged accounts (max. 3 accounts)"
                          : "Use tagged accounts"
                      }
                    />

                    {Boolean(requirements?.taggedAccount) && (
                      <Grow in={true}>
                        <Autocomplete
                          multiple
                          freeSolo
                          id="tags-standard"
                          options={[]}
                          value={taggedAccountValue}
                          inputValue={taggedAccountInputValue}
                          onChange={(event: any, newValue: any) => {
                            setTaggedAccountValue(newValue);
                          }}
                          onInputChange={(event: any, newInputValue: any) => {
                            const options = newInputValue.split(",");

                            if (options.length > 1) {
                              if (taggedAccountValue.length <= 2) {
                                setTaggedAccountValue(
                                  taggedAccountValue
                                    .concat(options)
                                    .map((x: any) => x.trim())
                                    .filter((x: any) => x)
                                );
                                setTaggedAccountInputValue("");
                              }
                            } else {
                              setTaggedAccountInputValue(newInputValue);
                            }
                          }}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              name="taggedAccountValue"
                              placeholder="Separate by comma: @kanhasoft, @ai"
                            />
                          )}
                        />
                      </Grow>
                    )}
                  </Box>
                  <Box sx={{ mb: "20px" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={Boolean(requirements?.videoLength)}
                          onChange={handleChangeSwitch}
                          inputProps={{
                            "aria-label": "controlled",
                            name: "videoLength",
                          }}
                        />
                      }
                      label="Set minimum video length (in seconds)"
                    />

                    {Boolean(requirements?.videoLength) && (
                      <Grow in={true}>
                        <TextField
                          type="number"
                          fullWidth
                          id="video-length-input"
                          name="videoLengthValue"
                          placeholder="Minimum video length in seconds"
                          onChange={handleInputChange}
                          defaultValue={0}
                          value={requirements?.videoLengthValue}
                        />
                      </Grow>
                    )}
                  </Box>
                  <Box sx={{ mb: "20px" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={Boolean(requirements?.footage)}
                          onChange={handleChangeSwitch}
                          inputProps={{
                            "aria-label": "controlled",
                            name: "footage",
                          }}
                        />
                      }
                      label="Use specific footage"
                    />

                    {Boolean(requirements?.footage) && (
                      <Grow in={true}>
                        <TextField
                          fullWidth
                          placeholder="Download link to footage"
                          name="footageValue"
                          onChange={handleInputChange}
                          value={requirements?.footageValue}
                        />
                      </Grow>
                    )}
                  </Box>
                  <Box sx={{ mb: "20px" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={Boolean(requirements?.sound)}
                          onChange={handleChangeSwitch}
                          inputProps={{
                            "aria-label": "controlled",
                            name: "sound",
                          }}
                        />
                      }
                      label="Use specific sound"
                    />

                    {Boolean(requirements?.sound) && (
                      <Grow in={true}>
                        <TextField
                          fullWidth
                          placeholder="Download link to sound"
                          name="soundValue"
                          onChange={handleInputChange}
                          value={requirements?.soundValue}
                        />
                      </Grow>
                    )}
                  </Box>
                </>
              ) : publishContentTypeCreateJobData === "collect_video_only" ? (
                <>
                  <Box sx={{ mb: "20px" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={Boolean(requirements?.videoLength)}
                          onChange={handleChangeSwitch}
                          inputProps={{
                            "aria-label": "controlled",
                            name: "videoLength",
                          }}
                        />
                      }
                      label="Set minimum video length (in seconds)"
                    />

                    {Boolean(requirements?.videoLength) && (
                      <Grow in={true}>
                        <TextField
                          type="number"
                          fullWidth
                          id="video-length-input"
                          name="videoLengthValue"
                          placeholder="Minimum video length in seconds"
                          onChange={handleInputChange}
                          defaultValue={0}
                          value={requirements?.videoLengthValue}
                        />
                      </Grow>
                    )}
                  </Box>
                  <Box sx={{ mb: "20px" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={Boolean(requirements?.footage)}
                          onChange={handleChangeSwitch}
                          inputProps={{
                            "aria-label": "controlled",
                            name: "footage",
                          }}
                        />
                      }
                      label="Use specific footage"
                    />

                    {Boolean(requirements?.footage) && (
                      <Grow in={true}>
                        <TextField
                          fullWidth
                          placeholder="Download link to footage"
                          name="footageValue"
                          onChange={handleInputChange}
                          value={requirements?.footageValue}
                        />
                      </Grow>
                    )}
                  </Box>
                  <Box sx={{ mb: "20px" }}>
                    <FormControlLabel
                      control={
                        <Switch
                          checked={Boolean(requirements?.sound)}
                          onChange={handleChangeSwitch}
                          inputProps={{
                            "aria-label": "controlled",
                            name: "sound",
                          }}
                        />
                      }
                      label="Use specific sound"
                    />

                    {Boolean(requirements?.sound) && (
                      <Grow in={true}>
                        <TextField
                          fullWidth
                          placeholder="Download link to sound"
                          name="soundValue"
                          onChange={handleInputChange}
                          value={requirements?.soundValue}
                        />
                      </Grow>
                    )}
                  </Box>
                </>
              ) : null}

              {isAllError ? (
                <Typography variant="subtitle1" sx={{ color: "red" }}>
                  {allErrorMsg}
                </Typography>
              ) : null}
            </Grid>
          </Grid>

          <Box
          // sx={{  justifyContent: {md: "space-between", sm: "space-between", xs: "center"}, }}
          >
            <Divider sx={{ mb: "30px", mt: 0 }} />
            <FooterButton
              state={state}
              backwardTitle="Placement"
              forwardTitle="Job details"
              handledBackwardButtonClick={onBackwardButtonClick}
              handleNextSliderClick={handleNext}
              handleClearDraftButtonClick={onClearDraftButtonClick}
            />
          </Box>
        </Box>
      </Grow>
    </Box>
  );
}
