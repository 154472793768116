// ** React Imports
import { Fragment, useEffect, useState } from "react";

// ** MUI Imports
import {
  Autocomplete,
  Box,
  FormControl,
  Grid,
  ListItem,
  TextField,
  Divider,
  Grow,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

// ** Third Party Library
import { useNavigate } from "react-router-dom";

// ** Redux
import { useAppDispatch, useAppSelector } from "store/store";
import { getJobCategory, setPresignedUrlAPI } from "store/Thunk/createJobThunk";

// ** Helper Imports
import Validations from "helper/validations";

// ** Custom Components
import { MARKET_PLACE } from "@core/constants/title-constants";
import FileUploaderRestrictions from "components/CreateNewJobFileUpload/FileUploaderRestrictions";
import FooterButton from "components/FooterButton/footerButtton";

// ** Icons
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Close from "mdi-material-ui/Close";
import FileDocumentOutline from "mdi-material-ui/FileDocumentOutline";

interface Props {
  onForwardButtonClick(): void;
  onBackwardButtonClick?(): void;
  onClearDraftButtonClick(): void;
  state: any;
  setState: any;
}

interface FileProp {
  name: string;
  type: string;
  size: number;
}

export default function PresetsStep({
  onForwardButtonClick,
  onBackwardButtonClick,
  onClearDraftButtonClick,
  state,
  setState,
}: Props) {
  const navigate = useNavigate();
  const dispatch: any = useAppDispatch();

  let jobDetails = state?.jobDetails;

  let { jobCategoryArray, objectKey } = useAppSelector(
    (state: any) => state.createJobReducer
  );

  const [files, setFiles] = useState<any>();
  const [open, setOpen] = useState(false);
  // const [isDisable, setIsDisable] = useState(true);
  // const [isAllError, setIsAllError] = useState(false);
  const [loading, setLoading] = useState(true);
  const [categoryArr, setCategoryArr] = useState<any[]>([]);
  const [jobDetail, setJobDetail] = useState<any>(
    jobDetails || {
      title: "",
      description: "",
      category: null,
      thumbnail: null,
      budget: 1000,
    }
  );
  const [formError, setFormError] = useState<any>({
    title: "",
    description: "",
    category: null,
    thumbnail: null,
  });

  useEffect(() => {
    if (jobCategoryArray && jobCategoryArray.length) {
      setCategoryArr(jobCategoryArray);
    }
    setLoading(!loading);
  }, [jobCategoryArray]);

  useEffect(() => {
    // if (
    //   jobDetail.title !== "" &&
    //   jobDetail.description !== "" &&
    //   (jobDetail.thumbnail !== null || state?.thumbnail_url !== null) &&
    //   jobDetail.category !== null
    // ) {
    //   setIsDisable(false);
    // } else {
    //   setIsDisable(true);
    // }

    setState({
      ...state,
      jobDetails: jobDetail,
    });
  }, [jobDetail]);

  useEffect(() => {
    if (loading) {
      dispatch(getJobCategory({ navigate }));
    }
  }, [loading]);

  useEffect(() => {
    if (open) {
      dispatch(getJobCategory({ navigate }));
    } else {
      setCategoryArr([]);
    }
  }, [open]);

  useEffect(() => {
    if (objectKey) setFormError({ ...formError, thumbnail: null });
    setJobDetail({ ...jobDetail, thumbnail: objectKey });
  }, [objectKey]);

  const renderFilePreview = () => {
    if (state?.thumbnail_url) {
      return <img width={38} height={38} src={state?.thumbnail_url} />;
    } else {
      if (files && files?.type.startsWith("image")) {
        return (
          <img
            width={38}
            height={38}
            alt={files?.name}
            src={URL.createObjectURL(files as any)}
          />
        );
      } else {
        return <FileDocumentOutline />;
      }
    }
  };

  const getValue = (jobDetail: any) => {
    let category =
      jobCategoryArray && jobCategoryArray.length
        ? jobCategoryArray.filter((val: any) => val.id == jobDetail.category)[0]
        : {};
    return category;
  };

  const handleChange = (e: any) => {
    setJobDetail({ ...jobDetail, [e.target.name]: e.target.value });
    if (e.target.value) {
      setFormError({ ...formError, [e.target.name]: "" });
    }
  };

  const handleAutoCompleteChange = (e: any, value: any) => {
    setJobDetail({ ...jobDetail, category: value.id });
    if (value.id) {
      setFormError({ ...formError, category: "" });
    }
  };

  const descriptionCount = () => {
    const count = 200 - jobDetail.description.length;
    return `Remaining ${count}/200`;
  };

  const uploadOnPresignedURL = (file: any) => {
    file && handleLogoUpload(file);
  };

  const handleLogoUpload = async (file: any) => {
    let data = {
      type: "upload_job_thumbnail",
      file_name: file[0].name,
    };
    dispatch(setPresignedUrlAPI({ data, file }));
  };

  const handleNextSliderClick = () => {
    let _request = {
      title: jobDetail.title.trim(),
      description: jobDetail.description.trim(),
      category: jobDetail.category,
      thumbnail: jobDetail.thumbnail,
      thumbnail_url: state?.thumbnail_url || files,
    };

    let error = Validations.validateCreateJobForm(_request);

    if (Object.entries(error).length === 0 && error.constructor === Object) {
      onForwardButtonClick();
    }

    setFormError(error);
  };

  const handleClearThumbnail = () => {
    setJobDetail({ ...jobDetail, thumbnail: null });
    setState({ ...state, thumbnail_url: null });
    setFiles("");
  };

  const getRecommendedSize = () => {
    if (state?.placement?.publishContentType === "collect_video_only") {
      return "4:1";
    } else {
      return "16:9";
    }
  };

  return (
    <Box sx={{ height: "auto" }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {MARKET_PLACE.CREATE_CONTENT.JOB_DETAILS}
      </Typography>
      <Typography variant="subtitle2" sx={{ mb: 3 }}>
        {MARKET_PLACE.REQUEST_CONTENT.JOB_DESCRIPTION}
      </Typography>
      <Grow in={true}>
        <Box className="borderRadiusSpecific box-border">
          <Grid container className="job-requirement">
            <Grid item xs={12} md={6}>
              <TextField
                value={jobDetail.title}
                fullWidth
                sx={{ mb: "30px", input: { cursor: "pointer" } }}
                id="title-input"
                placeholder="Title..."
                label="Job title"
                name="title"
                onChange={(e: any) => handleChange(e)}
                error={formError && formError.title ? true : false}
                helperText={formError && formError.title}
              />
              <TextField
                fullWidth
                sx={{ mb: "30px", input: { cursor: "pointer" } }}
                placeholder="Write a description for your job..."
                label="Job description"
                multiline
                rows={4}
                maxRows={4}
                inputProps={{
                  maxLength: 200,
                  cursor: "pointer",
                }}
                value={jobDetail.description}
                name="description"
                onChange={(e: any) => handleChange(e)}
                error={formError && formError.description ? true : false}
                helperText={
                  formError && formError.description
                    ? formError && formError.description
                    : descriptionCount()
                }
              />
              <Autocomplete
                className="job-category"
                open={open}
                value={getValue(jobDetail)}
                onOpen={() => {
                  setOpen(true);
                }}
                onClose={() => {
                  setOpen(false);
                }}
                isOptionEqualToValue={(option: any, value: any) =>
                  option?.category_name === value?.category_name
                }
                getOptionLabel={(option: any) => option?.category_name || ""}
                options={categoryArr}
                loading={loading}
                onChange={handleAutoCompleteChange}
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    name="category"
                    sx={{ input: { cursor: "pointer" } }}
                    placeholder="Choose category..."
                    label="Job category"
                    error={formError && formError.category ? true : false}
                    helperText={formError && formError.category}
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </Fragment>
                      ),
                    }}
                  />
                )}
              />
              <Box sx={{ mb: "30px" }}>
                <Typography
                  variant="subtitle1"
                  sx={{ mb: 2, color: "rgba(76, 78, 100, 0.87)" }}
                >
                  {MARKET_PLACE.REQUEST_CONTENT.UPLOAD_JOB_THUMBNAIL}
                </Typography>

                {files || state?.thumbnail_url ? (
                  <div className="file-details-container-new-job">
                    <Box
                      key={
                        state?.thumbnail_url ? "Thumbnail_Image" : files.name
                      }
                    >
                      <ListItem
                        key={
                          state?.thumbnail_url ? "Thumbnail_Image" : files.name
                        }
                        sx={{ p: 0 }}
                      >
                        <div
                          key={
                            state?.thumbnail_url
                              ? "Thumbnail_Image"
                              : files.name
                          }
                          className="file-details-block"
                        >
                          <div className="file-preview-container">
                            <div className="file-preview">
                              {renderFilePreview()}
                            </div>
                            <Typography className="file-name" noWrap={true}>
                              {state?.thumbnail_url
                                ? "Thumbnail_Image"
                                : files.name}
                            </Typography>
                            {state?.thumbnail_url ? null : (
                              <Typography className="file-size" variant="body2">
                                {Math.round(files.size / 100) / 10 > 1000
                                  ? `${(
                                      Math.round(files.size / 100) / 10000
                                    ).toFixed(1)} mb`
                                  : `${(
                                      Math.round(files.size / 100) / 10
                                    ).toFixed(1)} kb`}
                              </Typography>
                            )}
                          </div>
                        </div>

                        <IconButton onClick={() => handleClearThumbnail()}>
                          <Close fontSize="small" />
                        </IconButton>
                      </ListItem>
                    </Box>
                  </div>
                ) : (
                  <FileUploaderRestrictions
                    files={files}
                    setFiles={setFiles}
                    handledAcceptedFiles={uploadOnPresignedURL}
                    error={formError && formError.thumbnail ? true : false}
                    formError={formError}
                    recommendedSize={getRecommendedSize()}
                  />
                )}
              </Box>
              <FormControl
                fullWidth
                sx={{ minWidth: 120, width: { md: "20%", xs: "100%" }, mb: 5 }}
              >
                <TextField
                  placeholder="Budget"
                  type="number"
                  name="budget"
                  label="Budget (in USD)"
                  onChange={(e: any) => handleChange(e)}
                  value={jobDetail.budget}
                  inputProps={{ min: 1000 }}
                  sx={{ input: { cursor: "pointer" } }}
                />
              </FormControl>

              {/* {isAllError ? (
                <Typography variant="subtitle1" sx={{ color: "red" }}>
                  {MARKET_PLACE.REQUEST_CONTENT.JOB_ERROR}
                </Typography>
              ) : null} */}
            </Grid>
          </Grid>
          <Box>
            <Divider sx={{ mb: "30px", mt: 0 }} />
            <FooterButton
              state={state}
              backwardTitle="requirements"
              forwardTitle="budget divider"
              handledBackwardButtonClick={onBackwardButtonClick}
              handleNextSliderClick={handleNextSliderClick}
              handleClearDraftButtonClick={onClearDraftButtonClick}
            />
          </Box>
        </Box>
      </Grow>
    </Box>
  );
}
