import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";
import { ButtonLoading, ScreenLoading } from "store/Slice/AuthSlice";
import {
  notificationFail,
  notificationSuccess,
} from "../Slice/notificationSlice";
import { setCurrentPlan, setPlanList, setStripeCheckoutURL } from "store/Slice/SubscriptionSlice";
import axios from "axios";
import config from "config/config";

export const enterpriseSendMail = createAsyncThunk(
    "enterpriseSendMail",
    async ({ _request }: any, {dispatch}) => {
        try {
            const response: any = await API.post(
            "/api/v1/payment/request-enterprise-plan/",
            _request
            );
            dispatch(ScreenLoading(false));            
            dispatch(notificationSuccess(response?.data?.message));
            return response;
        } catch (err: any) {
            dispatch(ScreenLoading(false));
            dispatch(notificationFail(err.response.data.message));
            return err;
        }
    }
);

export const getPlanList = createAsyncThunk(
    "getPlanList",
    async ({}: any, {dispatch}) => {
        try {
            fetch('https://api.stripe.com/v1/plans', {
                headers: {
                    'Authorization': `Bearer ${config.STRIPE_SCRETE_KEY}` 
                }
            })
            .then(response => response.json())
                .then(data => {
                    let plan = data.data.sort((a: any, b: any)=> a.amount - b.amount)
                    dispatch(setPlanList(plan))
                })
            .catch(error => console.error(error))
            dispatch(ScreenLoading(false));
            return true;
        } catch (err: any) {
            dispatch(ScreenLoading(false));
            return err;
        }
    }
);

export const getCurrentSubscription = createAsyncThunk(
    "getCurrentSubscription",
    async ({}: any, {dispatch}) => {
        try {
            const response = await API.get(
                `/api/v1/payment/customer-subscription/`
            )
            dispatch(ScreenLoading(false));            
            dispatch(setCurrentPlan(response.data.data))
            return response;
        } catch (err: any) {
            dispatch(ScreenLoading(false));
            return err;
        }
    }
);

export const upgradeSubscription = createAsyncThunk(
    "upgradeSubscription",
    async ({ _request }: any, {dispatch}) => {
        try {
            const response = await API.post(
                `api/v1/payment/subscribe-plan/`,
                _request
            )
            dispatch(ButtonLoading(false));
            dispatch(setStripeCheckoutURL(response?.data?.data || ""))
            dispatch(ScreenLoading(true));
            dispatch(getCurrentSubscription({}));
            return response;
        } catch (err: any) {
            dispatch(ButtonLoading(false));
            return err;
        }
    }
);
