// ** React
import { useState, useContext } from "react";

// ** MUI Imports
import {
  Box,
  Button,
  Card,
  Divider,
  FormHelperText,
  Grow,
  Typography,
} from "@mui/material";

// ** Redux
import { useAppSelector } from "store/store";
import { AutoschudlerContext } from "context/autoschedulerContex";

// ** Custom Component
import CustomCheckboxIcons from "components/customCheckBox";
import { MEDIA_PLANNER } from "@core/constants/title-constants";

// ** Icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import DeleteIcon from "@mui/icons-material/Delete";

interface Props {
  onForwardButtonClick?(): void;
  onClearDraftClick?(): void;
  availablePlatform?: any;
}

export default function PlatformStep({
  onForwardButtonClick,
  onClearDraftClick,
  availablePlatform,
}: Props) {
  let autoSchedulerTitle = MEDIA_PLANNER.auto_scheduler;
  const [formError, setFormError] = useState(false);

  const { schedulerDraftData } = useAppSelector(
    (state: any) => state.autoSchedulerSlice
  );

  const {
    setAvailablePlatformTitle,
    availablePlatformTitle,
    setCampaign,
    campaign,
  }: any = useContext(AutoschudlerContext);

  const handlePlatform = (check: any, value: any) => {
    if (check) {
      setFormError(false);
      setAvailablePlatformTitle([...availablePlatformTitle, value]);
    } else if (check == false) {
      setCampaign({ ...campaign, customLanguages: {}, bundlelanguages: [] });
      setAvailablePlatformTitle(
        availablePlatformTitle?.filter((item: any) => item !== value)
      );
    }
    if (availablePlatformTitle?.length) {
      setFormError(false);
    }
  };

  const handledNextButton = () => {
    setFormError(true);
  };

  return (
    <Box>
      <Box sx={{ padding: 5 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {autoSchedulerTitle.SCHEDULE_ADS_IN_STEPS}
        </Typography>
        <Typography variant="subtitle2" sx={{ mb: 2 }}>
          {autoSchedulerTitle.SCHEDULE_ADS_CAPTION}
        </Typography>
      </Box>
      <Grow in={true}>
        <Card sx={{ height: "100%", padding: 10 }}>
          <form>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: { xs: "center", sm: "normal" },
              }}
              className="sync-direction-row"
            >
              {availablePlatform &&
                availablePlatform?.length > 0 &&
                availablePlatform?.map((item: any, index: number) => (
                  <Box
                    key={index}
                    sx={{
                      mb: 2,
                      ml: 4,
                    }}
                  >
                    <CustomCheckboxIcons
                      data={availablePlatform[index]}
                      selected={availablePlatformTitle}
                      icon={item?.icon}
                      handleChange={(e: any, value: any) =>
                        handlePlatform(e, item?.value)
                      }
                      name="custom-checkbox-icons"
                      gridProps={{ sm: 4, xs: 12 }}
                    />
                  </Box>
                ))}
            </Box>
            <Divider />
            {formError && (
              <FormHelperText sx={{ mb: 2 }} error id="password-error">
                {autoSchedulerTitle.PLEASE_SELECT_ALL_PLATFORMS}
              </FormHelperText>
            )}
            <Box
              className="footer-button"
              sx={{ justifyContent: { xs: "center", sm: "space-between" } }}
            >
              <Button
                size="large"
                variant="contained"
                onClick={
                  availablePlatformTitle?.length
                    ? onForwardButtonClick
                    : handledNextButton
                }
                endIcon={<ArrowForwardIcon />}
              >
                {autoSchedulerTitle.TIMESAVER}
              </Button>
              <Button
                size="large"
                variant="outlined"
                onClick={onClearDraftClick}
                disabled={!schedulerDraftData?.id}
                sx={{ mt: { xs: 3, sm: 0 } }}
                startIcon={<DeleteIcon />}
              >
                {autoSchedulerTitle.CLEAR_DRAFT}
              </Button>
            </Box>
          </form>
        </Card>
      </Grow>
    </Box>
  );
}
