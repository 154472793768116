// ** React Imports
import { useEffect, useState } from "react";

// ** MUI Imports
import {
  Avatar,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  LinearProgress,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// ** Third Party Library
import axios from "axios";
import { useNavigate } from "react-router-dom";

// ** Redux
import { setTTAPIProgress } from "store/Slice/PlatformSlice";
import { useAppDispatch, useAppSelector } from "store/store";
import {
  deleteTiktokProfile,
  getLatestProfile,
  getTiktokProfiles,
} from "store/Thunk/platformConnectionThunk";
import { notificationSuccess } from "store/Slice/notificationSlice";

// ** Custom Components
import config from "config/config";
import { PLATFORMS } from "@core/constants/enum";
import AdAccountsTab from "pages/platform/Tabs/AdAccountsTab";
import ConnectionLostDialog from "pages/platform/Dialogs/ConnectionLostDialog";

// ** Icons
import CloseIcon from "@mui/icons-material/Close";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import RefreshTwoToneIcon from "@mui/icons-material/RefreshTwoTone";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

// ** Assets
import { ProfileIcon } from "assets";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface Props {
  setSidebarDrawer?: any;
  sidebarDrawer?: any;
  onGetPlatform?: any;
  setIsBackdrop?: any;
  apiSource: any;
  setApiSource: any;
}

export default function ViewDetailsTiktokDrawer({
  setSidebarDrawer,
  sidebarDrawer,
  onGetPlatform,
  setIsBackdrop,
  apiSource,
  setApiSource,
}: Props) {
  const { tiktok, ttApiProgress } = useAppSelector(
    (state: any) => state.PlatformSlice
  );
  type Profile = {
    name: string;
  };
  const dispatch: any = useAppDispatch();
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [profile, setProfile] = useState<Profile[]>([]);
  const [adAccount, setAddAccount] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialog, setDialog] = useState({ open: false, name: "", id: null });
  const [loadingDelete, setLoadingdelete] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState("");
  const [lostAccountDialog, setLostAccountDialog] = useState<any>(false);
  const [isDialogOpen, setIsDialogOpen] = useState<any>(true);

  useEffect(() => {
    setIsDialogOpen(true);
    let source: any = axios.CancelToken.source();
    setApiSource(source);
    dispatch(
      setTTAPIProgress({
        loading: false,
        success: false,
        message: "",
      })
    );
    dispatch(getTiktokProfiles({ navigate })).then((response: any) => {
      setLoading(false);
    });
  }, []);

  useEffect(() => {
    let nameArray: any = [];
    let profileArray: any = [];

    tiktok?.length &&
      tiktok?.map((item: any) => {
        nameArray.push({
          name: item.first_name
            ? item.first_name
            : "" + " " + item.last_name
            ? item.last_name
            : "",
          avatar_url: item?.avatar_url || "",
          id: item?.id,
        });
        item?.ad_accounts?.map((items: any) => {
          profileArray.push(items);
        });
      });
    profileArray.sort((a: any, b: any) => b.active - a.active);
    nameArray?.length ? setProfile(nameArray) : setProfile([]);
    profileArray?.length ? setAddAccount(profileArray) : setAddAccount([]);
    setTimeout(() => {
      if (ttApiProgress?.loading) {
        dispatch(
          setTTAPIProgress({
            loading: false,
            success: true,
            message: "",
          })
        );
      }
    }, 2000);
  }, [tiktok]);

  useEffect(() => {
    const isLostAccount = tiktok?.filter(
      (item: any) => !item.is_connection_established
    );
    if (isLostAccount.length && !loading && isDialogOpen) {
      setLostAccountDialog(isLostAccount.length > 0);
    }
    if (!loading) {
      setIsDialogOpen(false);
    }
  }, [loading]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleRedirectUrl = () => {
    if (sidebarDrawer?.title == "Tiktok Ads") {
      window.open(config.TIKTOK_CONNECTION_URL, "_self");
      setIsDialogOpen(true);
    }
  };

  const handledRemoveProfile = (id: any) => {
    if (id) {
      setLoadingdelete(true);
      dispatch(deleteTiktokProfile({ id })).then((response: any) => {
        dispatch(getTiktokProfiles({ navigate })).then((response: any) => {
          setLoadingdelete(false);
          dispatch(notificationSuccess("Profile Deleted Successfully"));
          setDialog({ ...dialog, open: false });
          if (response?.payload?.data?.results.length == 0) {
            setSidebarDrawer({ ...sidebarDrawer, open: false });
            onGetPlatform();
          }
        });
      });
    }
  };

  const handleRefreshButton = () => {
    // setRefreshLoading("Refreshing");
    // setIsBackdrop(true);
    dispatch(
      setTTAPIProgress({
        loading: true,
        success: false,
        message:
          "Just a moment while we are gathering the most up-to-date information...",
      })
    );
    const data = {
      ad_platform: PLATFORMS.TIKTOK,
    };
    dispatch(getLatestProfile({ data, apiSource })).then((res: any) => {
      if (res?.payload?.status == 200) {
        dispatch(
          setTTAPIProgress({
            loading: true,
            success: false,
            message: "Almost there, please wait...",
          })
        );

        setTimeout(() => {
          dispatch(
            setTTAPIProgress({
              loading: true,
              success: false,
              message: "Just a few more seconds...",
            })
          );
          dispatch(getTiktokProfiles({ navigate })).then((res: any) => {
            // setIsBackdrop(false);
            if (res?.payload?.status == 200) {
              dispatch(
                setTTAPIProgress({
                  loading: true,
                  success: true,
                  message:
                    "Success! Latest data has been retrieved and is ready for use.",
                })
              );
              // setRefreshLoading("Done");
              // setIsBackdrop(false);
            }
            // else {
            //   setRefreshLoading("");
            // }
          });
        }, 2000);
      }
    });
  };

  const handleCloseDialog = () => {
    setLostAccountDialog(!lostAccountDialog);
  };

  return (
    <>
      {loading ? (
        <>
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
          <Box
            className="sync-align-justify-center"
            sx={{
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <Box className="sync-justify-end" sx={{ padding: 3 }}>
            <IconButton
              onClick={() => setSidebarDrawer({ open: false, Drawer: "" })}
            >
              <CloseIcon style={{ height: 25, width: 25 }} />
            </IconButton>
          </Box>
          <Box sx={{ px: { md: "3.625rem", xs: "2.265rem" } }}>
            <Box>
              <Typography variant="h5" className="sync-justify-center">
                {sidebarDrawer?.title}
              </Typography>
            </Box>
            <Grid container sx={{ mt: 5 }}>
              {profile &&
                profile?.map((item: any) => (
                  <Grid item xs={12} sm={6} padding={2}>
                    <Box
                      className="sync-align-center"
                      sx={{
                        border: "1px solid #EB5757",
                        borderWidth: "1.5px",
                        background: "rgba(255, 77, 73, 0.05)",
                        borderRadius: "10px",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: "10px",
                      }}
                    >
                      <Box className="sync-align-center">
                        <Avatar
                          alt={ProfileIcon}
                          sx={{ width: 30, height: 30 }}
                          src={item?.avatar_url || ProfileIcon}
                        />
                        {/* <Box
                          component="img"
                          src={ProfileIcon}
                          height={30}
                          width={30}
                        /> */}
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 500, color: "#EB5757", ml: 3 }}
                        >
                          {item.name}
                        </Typography>
                      </Box>
                      <Box className="sync-align-center">
                        <IconButton
                          onClick={() =>
                            setDialog({
                              ...dialog,
                              open: true,
                              name: item?.name,
                              id: item?.id,
                            })
                          }
                        >
                          <CloseIcon style={{ height: 20, width: 20 }} />
                        </IconButton>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              <Grid item xs={12} sm={6} padding={2}>
                <Box
                  className="sync-align-center"
                  sx={{
                    border: "1px solid #6D788D",
                    borderWidth: "1.5px",
                    background:
                      "linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), #6D788D;",
                    borderRadius: "10px",
                    flexDirection: "row",
                  }}
                  onClick={handleRedirectUrl}
                >
                  <IconButton
                    style={{ padding: "13px", backgroundColor: "transparent" }}
                  >
                    <AddCircleOutlineOutlinedIcon
                      style={{ height: 30, width: 30 }}
                    />
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: 500, color: "#6D788D", ml: 3 }}
                    >
                      Add Profile
                    </Typography>
                  </IconButton>
                </Box>
              </Grid>
            </Grid>

            <Box sx={{ width: "100%", mt: 5, padding: 0 }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: { md: "center", sm: "center", xs: "flex-start" },
                  justifyContent: "space-between",
                  flexDirection: { md: "row", sm: "row", xs: "column-reverse" },
                }}
              >
                <Box
                  sx={{
                    borderBottom: 1,
                    borderColor: "divider",
                    width: {
                      md: "auto",
                      sm: "auto",
                      xs: "100%",
                    },
                  }}
                >
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="AD ACCOUNTS" {...a11yProps(0)} />
                  </Tabs>
                </Box>

                {/* Refresh button stuff */}
                {/* {refreshLoading == "Refreshing" ? (
                  <Typography
                    sx={{
                      fontWeight: "500",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <CircularProgress
                      size={20}
                      sx={{ mr: 2, height: 2, width: 2 }}
                      color="secondary"
                    />
                    Refreshing data...
                  </Typography>
                ) : refreshLoading == "Done" ? (
                  <Typography
                    sx={{
                      fontWeight: "500",
                      color: "rgba(103, 203, 36, 1)",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <DoneRoundedIcon sx={{ mr: 2 }} color="success" />
                    Data up to date
                  </Typography>
                ) : (
                  <Button variant="text" onClick={handleRefreshButton}>
                    <RefreshTwoToneIcon sx={{ mr: 2 }} />
                    Refresh data
                  </Button>
                )} */}

                {ttApiProgress?.success ? (
                  <Typography
                    sx={{
                      fontWeight: "500",
                      color: "rgba(103, 203, 36, 1)",
                      display: "flex",
                      alignItems: "center",
                      mb: { md: 0, sm: 0, xs: 2 },
                    }}
                  >
                    <DoneRoundedIcon sx={{ mr: 2 }} color="success" />
                    Data up to date
                  </Typography>
                ) : (
                  <Button
                    variant="text"
                    onClick={handleRefreshButton}
                    sx={{ mb: { md: 0, sm: 0, xs: 2 } }}
                  >
                    <RefreshTwoToneIcon sx={{ mr: 2 }} />
                    Refresh data
                  </Button>
                )}
              </Box>

              <Box>
                <TabPanel value={value} index={0}>
                  <AdAccountsTab
                    setIsBackdrop={setIsBackdrop}
                    adAccount={adAccount}
                    title={sidebarDrawer?.title}
                  />
                </TabPanel>
              </Box>
            </Box>
            <Box className="sync-justify-end">
              <Button
                variant="outlined"
                sx={{ margin: 3 }}
                onClick={() => setSidebarDrawer({ open: false, Drawer: "" })}
              >
                CANCEL
              </Button>
              <Button
                variant="contained"
                sx={{ margin: 3 }}
                onClick={() => setSidebarDrawer({ open: false, Drawer: "" })}
              >
                FINISH
              </Button>
            </Box>
          </Box>
          {ttApiProgress?.loading && (
            <Box
              className="drawer-loader-backdrop"
              sx={{ width: { md: "60%", sm: "100%", xs: "100%" } }}
            >
              <CircularProgress
                size={30}
                sx={{ mr: 2, mb: 4, height: 2, width: 2, color: "#FF4D49" }}
                // color="secondary"
              />
              <Typography
                sx={{
                  fontWeight: "500",
                  display: "flex",
                  alignItems: "center",
                  color: "#FF4D49",
                  width: "60%",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                {ttApiProgress?.message}
              </Typography>
            </Box>
          )}
        </>
      )}
      <Dialog
        fullWidth
        open={dialog?.open}
        maxWidth="sm"
        scroll="body"
        onClose={() =>
          setDialog({ ...dialog, open: false, name: "", id: null })
        }
      >
        <DialogContent
          sx={{
            px: { sm: 10 },
            py: { sm: 7.5 },
            position: "relative",
          }}
        >
          <Box>
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              Unlink Profile
            </Typography>
            <Typography variant="subtitle2" sx={{ fontWeight: 400, mt: 3 }}>
              Are you sure you want to unlink "{dialog?.name}"?{" "}
            </Typography>
            <Box sx={{ display: "flex", mt: 7 }}>
              <Button
                fullWidth
                size="large"
                variant="outlined"
                sx={{ mb: 4, mr: 2 }}
                onClick={() =>
                  setDialog({ ...dialog, open: false, name: "", id: null })
                }
              >
                CANCEL
              </Button>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                onClick={() => handledRemoveProfile(dialog?.id)}
                loading={loadingDelete}
                variant="contained"
                disabled={loading}
                sx={{ mb: 4, ml: 2 }}
              >
                UNLINK
              </LoadingButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      {lostAccountDialog && (
        <ConnectionLostDialog
          profiles={tiktok}
          open={lostAccountDialog}
          platform={"tiktok"}
          handleClose={handleCloseDialog}
          onReconnect={handleRedirectUrl}
          handledRemoveProfile={handledRemoveProfile}
        />
      )}
    </>
  );
}
