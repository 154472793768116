import { createAsyncThunk } from "@reduxjs/toolkit";
import { setEarningData, setIncoiceDetails, setPayoutAmount, setPayoutBalance } from "../Slice/EarningSlice";
import { setLoading } from "../Slice/CreateJobSlice";
import { notificationSuccess, notificationFail } from "../Slice/notificationSlice";
import API from "../api";

export const getEarningData = createAsyncThunk(
    "brandList",
    async ({dispatch}: any) => {
        try {
            const response = await API.get(
                `api/v1/marketplace/payout/`
            );

            dispatch(setEarningData(response.data))
            return response;
        } catch (err: any) {
            dispatch(notificationFail(err?.message));
            return err;
        }
    },
);

export const getPayoutAmount = createAsyncThunk(
    "brandList",
    async ({dispatch}: any) => {
        try {
            const response = await API.post(
                `api/v1/marketplace/payout/`
            );
            dispatch(setPayoutAmount(response.data.data))
            return response;
        } catch (err: any) {
            dispatch(notificationFail(err?.message));
            return err;
        }
    },
);

export const getPayoutBalance = createAsyncThunk(
    "brandList",
    async ({dispatch}: any) => {
        try {
            const response = await API.get(
                `api/v1/marketplace/payout-balance/`
            );
            dispatch(setPayoutBalance(response.data.data))
            return response;
        } catch (err: any) {
            dispatch(notificationFail(err?.message));
            return err;
        }
    },
);

export const getInvoiceDetails = createAsyncThunk(
    "brandList",
    async (req: any, {dispatch}:any) => {
        try {
            const response = await API.get(
                `api/v1/marketplace/payout/${req?.id}`
            );
            dispatch(setIncoiceDetails(response.data))
            return response;
        } catch (err: any) {
            dispatch(notificationFail(err?.message));
            return err;
        }
    },
);
