import { useState } from "react";
import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Chip,
  Grow,
  LinearProgress,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CheckCircleOutlineIcon from "mdi-material-ui/CheckCircleOutline";
import { useAppDispatch } from "store/store";
import { submitPostLink } from "store/Thunk/createJobThunk";
import ContentTypeCard from "components/ContentTypeCard";
import SubmitPost from "components/Dialogs/SubmitPost";

export default function MySubmissionCard(props: any) {
  const {
    cardData,
    redirectURL,
    selectedPublishType,
    selected,
    onClickContinue,
  } = props;
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isOpenSubmission, setIsOpenSubmission] = useState<boolean>(false);
  const [linkTxt, setLinkTxt] = useState("");

  const handleJobDetailClick = () => {
    navigate(redirectURL);
  };

  const handleLinkClick = () => {
    setIsOpenSubmission(true);
  };

  const handleClose = () => {
    setIsOpenSubmission(false);
  };

  const useStyles = {
    button: {
      "&:hover": {
        backgroundColor: "#fff !important",
        color: "#000aff !important",
      },
    },
  };

  const handleSubmit = () => {
    let formData = new FormData();

    let data = {
      approval_status: selected,
      published_content_type: selectedPublishType,
    };

    formData.append("social_post_link", linkTxt);
    dispatch(
      submitPostLink({ data, submission_id: cardData.id, formData })
    ).then((res: any) => {
      if (res?.payload?.status == 200 || res?.payload?.status == 201) {
        setIsOpenSubmission(false);
        onClickContinue();
      }
    });
  };

  const calculatePercentage = () => {
    return;
  };

  // approval_needed -> creator_post_pending -> post_confirmation_pending -> approved
  const cardContent = (status: any) => {
    if (status === "approval_needed") {
      return (
        <Box my={4} mx={2}>
          <Box
            height={"140px"}
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <AccessTimeIcon color="warning" sx={{ fontSize: "25px" }} />
            <Typography sx={{ fontWeight: "400", fontSize: "14px", mt: 2 }}>
              Video approval pending
            </Typography>
          </Box>

          <Button
            sx={useStyles.button}
            onClick={handleJobDetailClick}
            fullWidth
            variant="text"
          >
            View submission
          </Button>
        </Box>
      );
    } else if (status === "creator_post_pending") {
      return (
        <Box my={4} mx={2}>
          <Box width="100%" height="140px">
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <CheckCircleOutlineIcon
                color="success"
                sx={{ fontSize: "25px" }}
              />
              <Typography sx={{ fontWeight: "400", fontSize: "14px", mt: 2 }}>
                Video approved by brand
              </Typography>
            </Box>

            <Box onClick={() => handleLinkClick()} sx={{ cursor: "pointer" }}>
              <Alert
                severity="info"
                sx={{ my: 4, width: "100%", padding: "0px 16px" }}
              >
                <AlertTitle>Submit link to post</AlertTitle>
                Click here to submit
              </Alert>
            </Box>
          </Box>
          <Button onClick={handleJobDetailClick} fullWidth variant="text">
            View submission
          </Button>
        </Box>
      );
    } else if (status === "post_confirmation_pending") {
      return (
        <Box my={4} mx={2}>
          <Box
            height="140px"
            display="flex"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
          >
            <AccessTimeIcon color="warning" sx={{ fontSize: "25px" }} />
            <Typography sx={{ fontWeight: "400", fontSize: "14px", mt: 2 }}>
              Post confirmation pending
            </Typography>
          </Box>

          <Button onClick={handleJobDetailClick} fullWidth variant="text">
            View submission
          </Button>
        </Box>
      );
    } else if (status === "declined") {
      return (
        <Box my={4} mx={2}>
          <Box
            height="140px"
            display="flex"
            flexDirection="column"
            justifyContent="space-around"
          >
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
            >
              <HighlightOffIcon color="error" sx={{ fontSize: "25px" }} />
              <Typography sx={{ fontWeight: "400", fontSize: "14px", mt: 2 }}>
                Video declined by brand
              </Typography>
            </Box>

            <Box width="250px" px={2}>
              <p className="quote-feedback">
                {cardData.approval_status[0].feedback !== ""
                  ? cardData.approval_status[0].feedback
                  : "This video does not represent what we want to stand for. We want to be known for our quality We want to be known for our qua We want to be known for our qua"}
              </p>
            </Box>
          </Box>

          <Button onClick={handleJobDetailClick} fullWidth variant="text">
            View submission
          </Button>
        </Box>
      );
    } else {
      return (
        <Box my={4} mx={2}>
          <Box height={"140px"} px={2}>
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Typography sx={{ fontWeight: "400", fontSize: "14px" }}>
                  Views
                </Typography>
                <Typography sx={{ fontWeight: "700", fontSize: "16px" }}>
                  {cardData.view_count}
                </Typography>
              </Box>
              <Box>
                <Typography sx={{ fontWeight: "400", fontSize: "14px" }}>
                  Milestone
                </Typography>
                <Typography sx={{ fontWeight: "700", fontSize: "16px" }}>
                  {cardData.milestone.completed +
                    " of " +
                    cardData.milestone.total}
                </Typography>
              </Box>
            </Box>
            <Box mt={4}>
              <LinearProgress
                variant="determinate"
                value={48}
                // value={() => calculatePercentage()}
                color="success"
              />
              <Typography sx={{ fontWeight: "400", fontSize: "12px", mt: 1 }}>
                {"Next milestone at " + cardData.next_milestone_view + " views"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 2,
              }}
            >
              <Typography sx={{ fontWeight: "400", fontSize: "14px" }}>
                Earned
              </Typography>
              <Typography sx={{ fontWeight: "700", fontSize: "20px" }}>
                {"$" + cardData.budget_value.spent}
              </Typography>
            </Box>
          </Box>
          <Button sx={useStyles.button} fullWidth variant="text">
            View submission
          </Button>
        </Box>
      );
    }
  };

  const getStatusChip = (status: any) => {
    if (
      status === "approval_needed" ||
      status === "post_confirmation_pending" ||
      status === "pending"
    ) {
      return (
        <Chip
          icon={<AccessTimeIcon fontSize="small" />}
          label="PENDING"
          color="warning"
          size="small"
        />
      );
    } else if (status === "creator_post_pending") {
      return (
        <Chip
          icon={<AccessTimeIcon fontSize="small" />}
          label="ACTION REQUIRED"
          color="info"
          size="small"
        />
      );
    } else if (status === "declined") {
      return (
        <Chip
          icon={<AccessTimeIcon fontSize="small" />}
          label="DECLINED"
          color="error"
          size="small"
        />
      );
    } else {
      return (
        <Chip
          icon={<AttachMoneyIcon fontSize="small" />}
          label="EARNING"
          color="success"
          size="small"
        />
      );
    }
  };

  return (
    <Grow in={true}>
      <Box className="list-card-block">
        {/* Job image part */}
        <Box sx={{ position: "relative", width: "100%", height: "162px" }}>
          <Box className="image-wrapper">
            <Box className="detail-left-icon">
              <ContentTypeCard
                contentType={cardData.job_details.publish_content_type}
              />
            </Box>
            <Box
              sx={{
                position: "absolute",
                right: "8px",
                top: { xs: "19px" },
              }}
            >
              {getStatusChip(
                cardData?.approval_status?.length
                  ? cardData?.approval_status[0]?.approval_status
                  : null
              )}
            </Box>
            <Box>
              <p className="list-card-title">{cardData.job_details.title}</p>
              <Typography variant="caption" color="#fff">
                {cardData?.brand?.brand_name}
              </Typography>
            </Box>
          </Box>
          <Box
            component="img"
            src={cardData.job_details.thumbnails_url}
            className="list-card-image"
          />
        </Box>
        {/* Job content part */}
        {cardContent(
          cardData?.approval_status?.length
            ? cardData?.approval_status[0]?.approval_status
            : null
        )}
        <SubmitPost
          isOpenSubmission={isOpenSubmission}
          handleClose={handleClose}
          handleSubmit={handleSubmit}
          setLinkTxt={setLinkTxt}
        />
      </Box>
    </Grow>
  );
}
