// ** React Imports
import { useEffect, useState } from "react";

// ** MUI Imports
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  LinearProgress,
  Typography,
  IconButton,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// ** Third Party Library
import { useNavigate } from "react-router-dom";

// ** Redux
import { useAppDispatch, useAppSelector } from "store/store";
import {
  deleteLinkfireScrapperProfile,
  getLinkfireScrapperConnection,
  getLinkfireScrapperInfo,
} from "store/Thunk/platformConnectionThunk";

// ** Custom Components
import LinkfireScrapperTab from "pages/platform/Tabs/LinkfireScrapperTab";

// ** Icons
import CloseIcon from "@mui/icons-material/Close";

// ** Assets
import { ProfileIcon } from "assets";

interface Props {
  setSidebarDrawer?: any;
  title?: any;
  onSpotifyLogin?: any;
  setLoading?: any;
  setIsBackdrop?: any;
}

export default function LinkfireScrapperDrawer({
  setSidebarDrawer,
  title,
  onSpotifyLogin,
  setLoading,
  setIsBackdrop,
}: Props) {
  const dispatch: any = useAppDispatch();

  const { linkfireScrapperConnection } = useAppSelector(
    (state: any) => state.PlatformSlice
  );

  const [scrapperloading, setScrapperLoading] = useState(true);
  const [connectionLoaging, setConnectionLoading] = useState(true);
  const [dialog, setDialog] = useState({ open: false, name: "", id: null });
  const [loadingDelete, setLoadingdelete] = useState(false);
  const [linkDialog, setLinkDialog] = useState({ open: false });

  useEffect(() => {
    if (title == "Linkfire Data Scraper") {
      let data: any = null;
      let page: any = 1;
      dispatch(getLinkfireScrapperInfo({ data, page })).then((res: any) => {
        setScrapperLoading(false);
      });
      dispatch(getLinkfireScrapperConnection({ data })).then((res: any) => {
        setConnectionLoading(false);
      });
    }
  }, []);

  const handledRemoveProfile = (id: any) => {
    setLoadingdelete(true);
    let data = {
      id: linkfireScrapperConnection?.id,
    };
    dispatch(deleteLinkfireScrapperProfile({ data })).then((response: any) => {
      setLoadingdelete(false);
      setLoading(true);
      setDialog({ open: false, name: "", id: null });
      setSidebarDrawer({ open: false, Drawer: "" });
      onSpotifyLogin();
    });
  };

  return (
    <>
      {scrapperloading || connectionLoaging ? (
        <>
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <Box sx={{ height: "100%" }}>
            <Box className="sync-justify-end" sx={{ padding: 3 }}>
              <IconButton
                onClick={() => setSidebarDrawer({ open: false, Drawer: "" })}
              >
                <CloseIcon style={{ height: 25, width: 25 }} />
              </IconButton>
            </Box>
            <Box sx={{ paddingLeft: "58px", paddingRight: "58px" }}>
              <Box>
                <Typography variant="h5" className="sync-justify-center">
                  {title}
                </Typography>
              </Box>
              <Grid container sx={{ mt: 5 }}>
                <Grid item xs={12} sm={6} padding={2}>
                  <Box
                    className="sync-align-center"
                    sx={{
                      border: "1px solid #EB5757",
                      borderWidth: "1.5px",
                      background: "rgba(255, 77, 73, 0.05)",
                      borderRadius: "10px",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      padding: "10px",
                    }}
                  >
                    <Box className="sync-align-center">
                      <Box
                        component="img"
                        src={ProfileIcon}
                        height={30}
                        width={30}
                      />
                      <Typography
                        variant="subtitle1"
                        sx={{ fontWeight: 500, color: "#EB5757", ml: 3 }}
                      >
                        {linkfireScrapperConnection?.linkfire_username}
                      </Typography>
                    </Box>
                    <Box className="sync-align-center">
                      <IconButton
                        onClick={() =>
                          setDialog({
                            ...dialog,
                            open: true,
                            name: linkfireScrapperConnection?.linkfire_username,
                            id: null,
                          })
                        }
                      >
                        <CloseIcon style={{ height: 20, width: 20 }} />
                      </IconButton>
                    </Box>
                  </Box>
                </Grid>
              </Grid>

              {/* -------------------------------Linkfire Tab ---------------------------*/}
              <LinkfireScrapperTab
                setIsBackdrop={setIsBackdrop}
                title={title}
                dialog={linkDialog}
                setDialog={setLinkDialog}
              />

              <Box className="sync-justify-end">
                <Button
                  variant="outlined"
                  sx={{ margin: 3 }}
                  onClick={() => setSidebarDrawer({ open: false, Drawer: "" })}
                >
                  CANCEL
                </Button>
                <Button
                  variant="contained"
                  sx={{ margin: 3 }}
                  onClick={() => setSidebarDrawer({ open: false, Drawer: "" })}
                >
                  FINISH
                </Button>
              </Box>
            </Box>
          </Box>
        </>
      )}

      {/* ------------------------------------Remove Profile Dialog----------------------*/}
      <Dialog
        fullWidth
        open={dialog?.open}
        maxWidth="sm"
        scroll="body"
        onClose={() =>
          setDialog({ ...dialog, open: false, name: "", id: null })
        }
      >
        <DialogContent
          sx={{
            px: { sm: 10 },
            py: { sm: 7.5 },
            position: "relative",
          }}
        >
          <Box>
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              Unlink Profile
            </Typography>
            <Typography variant="subtitle2" sx={{ fontWeight: 400, mt: 3 }}>
              Are you sure you want to unlink "{dialog?.name}"?{" "}
            </Typography>
            <Box sx={{ display: "flex", mt: 7 }}>
              <Button
                fullWidth
                size="large"
                variant="outlined"
                sx={{ mb: 4, mr: 2 }}
                onClick={() =>
                  setDialog({ ...dialog, open: false, name: "", id: null })
                }
              >
                CANCEL
              </Button>
              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                onClick={() => handledRemoveProfile(dialog?.id)}
                loading={loadingDelete}
                variant="contained"
                sx={{ mb: 4, ml: 2 }}
              >
                UNLINK
              </LoadingButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
