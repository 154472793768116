// ** MUI Imports
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Slider } from "@mui/material";
import { MediaPlanner } from "assets";
import { useEffect, useState } from "react";

export const tier = [
  {
    name: "Tier 1 Plan",
    value: 99,
    label: "$99",
    adSpend: 10.0,
  },
  {
    name: "Tier 2 Plan",
    value: 199,
    label: "$199",
    adSpend: 20.0,
  },
  {
    name: "Tier 3 Plan",
    value: 299,
    label: "$299",
    adSpend: 30.0,
  },
  {
    name: "Tier 4 Plan",
    value: 399,
    label: "$399",
    adSpend: 40.0,
  },
  {
    name: "Tier 5 Plan",
    value: 479,
    label: "$479",
    adSpend: 60.0,
  },
  {
    name: "Tier 6 Plan",
    value: 699,
    label: "$699",
    adSpend: 100.0,
  },
  {
    name: "Tier 7 Plan",
    value: 899,
    label: "$899",
    adSpend: 150.0,
  },
  {
    name: "Tier 8 Plan",
    value: 1199,
    label: "$1199",
    adSpend: 200.0,
  },
  {
    name: "Tier 9 Plan",
    value: 1799,
    label: "$1799",
    adSpend: 300.0,
  },
  {
    name: "Tier 10 Plan",
    value: 2499,
    label: "$2499",
    adSpend: 500.0,
  },
  {
    name: "Tier 10 Plan",
    value: 3000,
    label: "Let's talk",
    adSpend: 500.0,
  },
];

const PricingHeader = ({ state, setState, allPlan }: any) => {
  const [tierArr, setTierArr] = useState<any>([]);

  useEffect(() => {
    if (allPlan.length) {
      let tempArr: any[] = [];
      allPlan.map((val: any, index: any) => {
        tempArr.push({
          name: val?.nickname,
          value: val.amount / 100,
          label: `$${val.amount / 100}`,
          adSpend: val?.metadata?.ad_spend_limit || 0,
          plan_id: val?.id,
          is_enterprise: false,
        });
      });
      const distance =
        tempArr[tempArr.length - 1].value - tempArr[tempArr.length - 2].value;
      tempArr.push({
        name: null,
        value: tempArr[tempArr.length - 1].value + distance,
        label: "Let's talk",
        adSpend: 0,
        plan_id: 0,
        is_enterprise: true,
      });

      setTierArr(tempArr);
    }
  }, [allPlan]);

  useEffect(() => {
    if (state?.currentPlan?.customer_subscription?.length) {
      const price_id = state?.currentPlan?.customer_subscription[0]?.plan?.id;
      let defaultTierIndex = tierArr.findIndex(
        (obj: any) => obj.plan_id == price_id
      );
      setState({
        ...state,
        is_enterprise: false,
        sliderValue: tierArr[defaultTierIndex + 1],
        selectedSliderValue: tierArr[defaultTierIndex + 1],
      });
    } else {
      setState({
        ...state,
        is_enterprise: false,
        sliderValue: tierArr[0],
        selectedSliderValue: tierArr[0],
      });
    }
  }, [tierArr, state?.currentPlan]);

  const handleChangeTier = (event: any, newValue: any) => {
    let tierObj = tierArr.filter((tier: any) => tier.value == newValue);
    if (tierObj[0].is_enterprise) {
      setState({
        ...state,
        is_enterprise: true,
        selectedSliderValue: tierObj[0],
      });
    } else {
      setState({
        ...state,
        is_enterprise: false,
        sliderValue: tierObj[0],
        selectedSliderValue: tierObj[0],
      });
    }
  };

  const valueLabelFormat = (value: number) => {
    return tierArr.filter((mark: any) => mark.value === value)[0]?.label;
  };

  return (
    <Box mt={12} mb={4}>
      <Box
        className="app-type-img"
        component="img"
        alt="App type image not found"
        src={MediaPlanner}
        sx={{
          width: "40px",
          mb: 4,
        }}
      />
      <Typography variant="h4">Media Planner pricing plans</Typography>
      <Typography variant="body2" sx={{ mt: 2 }}>
        To use our Media Planner you need a monthly plan.
      </Typography>

      {tierArr.length > 0 && (
        <Slider
          sx={{
            width: "60%",
            my: 5,
            color: "#666CFF",
            "& .MuiSlider-track": {
              height: 2,
            },
            "& .MuiSlider-markLabel": {
              display: "none",
            },
          }}
          max={tierArr[tierArr.length - 1].value}
          min={tierArr[0].value}
          aria-label="Custom marks"
          defaultValue={1}
          value={state?.selectedSliderValue?.value || null}
          onChange={handleChangeTier}
          step={null}
          marks={tierArr}
          valueLabelDisplay="auto"
          valueLabelFormat={valueLabelFormat}
        />
      )}

      <Box display="flex" flexDirection="row" justifyContent="center">
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 500,
            fontSize: "18px",
            color: "rgba(76, 78, 100, 0.68)",
          }}
        >
          Monthly ad spend up to
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            ml: 2,
            fontWeight: 700,
            fontSize: "18px",
            color: "rgba(76, 78, 100, 0.68)",
          }}
        >
          {`$${state?.sliderValue?.adSpend}`}
        </Typography>
      </Box>
    </Box>
  );
};

export default PricingHeader;
