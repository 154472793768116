import { combineReducers, configureStore } from '@reduxjs/toolkit'
import authReducer from 'store/Slice/AuthSlice';
import notificationReducer from 'store/Slice/notificationSlice';

import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import PlatformSlice from 'store/Slice/PlatformSlice';
import autoSchedulerSlice from 'store/Slice/autoSchedulerSlice';
import createJobReducer from 'store/Slice/CreateJobSlice';
import createBrandProfileSlice from 'store/Slice/CreateBrandSlice';
import userSlice from 'store/Slice/userSlice';
import earningSlice from 'store/Slice/EarningSlice';
import settingSlice from 'store/Slice/SettingSlice';
import subscriptionSlice from 'store/Slice/SubscriptionSlice';

const combinedReducer = combineReducers({
  authReducer,
  notificationReducer,
  PlatformSlice,
  autoSchedulerSlice,
  createJobReducer,
  createBrandProfileSlice,
  userSlice,
  earningSlice,
  settingSlice,
  subscriptionSlice,
});

export const store = configureStore({
  reducer: combinedReducer,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export default store;
