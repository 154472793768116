// ** React Imports
import { useEffect, useState } from "react";

// ** MUI Imports
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import Grid, { GridProps } from "@mui/material/Grid";

// ** Third Party Library
import { useNavigate } from "react-router-dom";

// ** Redux
import { useAppDispatch } from "store/store";
import { getPlatformCount } from "store/Thunk/platformConnectionThunk";

// ** Custom Components
import GetStartedPage from "./get-started";
import { route_info } from "navigation/route";

// ** Assets
import { DashboardImage, DashboardPlatform } from "assets";

// Styled Grid component
const StyledGrid = styled(Grid)<GridProps>(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    order: -1,
    display: "flex",
    justifyContent: "center",
  },
}));

// Styled component for the image
const Img = styled("img")(({ theme }) => ({
  right: 0,
  bottom: 0,
  width: 298,
  position: "absolute",
  [theme.breakpoints.down("sm")]: {
    width: 250,
    position: "static",
  },
}));

export default function DashboardPage() {
  const navigate = useNavigate();
  const dispatch: any = useAppDispatch();

  const header: any = localStorage.getItem("header");
  const firstName: any = JSON.parse(header).first_name;

  const [userName, setUserName] = useState<String>("");
  const [isAccountConnected, setIsAccountConnected] = useState<Boolean>(false);
  const [isLoading, setIsLoading] = useState<Boolean>(true);

  useEffect(() => {
    setUserName(firstName);
  }, [firstName]);

  useEffect(() => {
    dispatch(getPlatformCount({ navigate }))
      .then((res: any) => {
        if (res?.payload?.status === 200 || res?.payload?.status === 201) {
          let response = res.payload.data.data[0];

          let tempTitle: any = [];

          if (response) {
            setIsLoading(false);
            if (
              response.tiktok &&
              response.tiktok > 0 &&
              response.tiktok_ad_account_count > 0
            ) {
              tempTitle.push("Tiktok");
            }
            if (
              response.facebook &&
              response.facebook > 0 &&
              response.facebook_ad_account_count > 0
            ) {
              tempTitle.push("Facebook");
            }
            if (
              response.snapchat &&
              response.snapchat > 0 &&
              response.snapchat_ad_account_count > 0
            ) {
              tempTitle.push("Snap");
            }

            if (tempTitle?.length) {
              setIsAccountConnected(true);
            }
          }
        }
      })
      .catch((res: any) => {
        return true;
      });
  }, []);

  return (
    <>
      <Card sx={{ position: "relative" }}>
        <CardContent
          sx={{
            p: (theme) => `${theme.spacing(7.5, 7.5)} !important`,
            justifyContent: isLoading ? "center" : "auto",
            display: isLoading ? "flex" : "auto",
          }}
        >
          {isLoading ? (
            <CircularProgress disableShrink size={30} />
          ) : (
            <Grid container spacing={6}>
              <Grid item xs={12} sm={6}>
                <Typography variant="h5" sx={{ mb: 4.5 }}>
                  {isAccountConnected
                    ? "Good day "
                    : "Welcome to Kanhasoft "}

                  <Box component="span" sx={{ fontWeight: "bold" }}>
                    {userName}
                  </Box>
                  {isAccountConnected ? "👋" : "🎉"}
                </Typography>
                <Typography sx={{ mb: 4.5 }} variant="body2">
                  {isAccountConnected
                    ? "Which ads do you want to schedule today?"
                    : "Connect your ad manager accounts to get started with scheduling"}
                </Typography>
                <Button
                  variant="contained"
                  onClick={() =>
                    isAccountConnected
                      ? navigate(route_info.market_planner.auto_scheduler)
                      : navigate(route_info.market_planner.platform)
                  }
                >
                  {isAccountConnected ? "GO TO MEDIA PLANNER" : "GET STARTED"}
                </Button>
              </Grid>
              <StyledGrid item xs={12} sm={6}>
                <Img
                  alt="Congratulations John"
                  src={DashboardPlatform}
                  sx={{ right: "20px", bottom: "-30px", width: "270px" }}
                />
              </StyledGrid>
            </Grid>
          )}
        </CardContent>
      </Card>
    </>
  );
}
