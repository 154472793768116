// ** MUI Imports
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box, { BoxProps } from "@mui/material/Box";
import CustomChip from "@core/components/mui/chip";

// ** Icons Imports
import CircleOutline from "mdi-material-ui/CircleOutline";

import {
  PricingIllustration1,
  PricingIllustration2,
  PricingIllustration3,
} from "assets";
import { useEffect, useState } from "react";

// ** Styled Component for the wrapper of whole component
const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  minWidth: "260px",
  position: "relative",
  padding: theme.spacing(5, 5, 5),
  borderRadius: theme.shape.borderRadius,
}));

// ** Styled Component for the wrapper of all the features of a plan
const BoxFeature = styled(Box)<BoxProps>(({ theme }) => ({
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(5),
  "& > :not(:first-of-type)": {
    marginTop: theme.spacing(4),
  },
}));

const pricingPlans = {
  freePlan: {
    imgWidth: 100,
    title: "Basic",
    imgHeight: 100,
    monthlyPrice: 0,
    currentPlan: true,
    popularPlan: false,
    daysLeft: 3,
    subtitle: "A simple start for everyone",
    imgSrc: PricingIllustration1,
    amountTitle: "FREE",
    yearlyPlan: {
      perMonth: 0,
      totalAnnual: 0,
    },
    planBenefits: [
      "Unlimited ad scheduling",
      "Ad manager integrations",
      "Using and creating presets",
      "Reusing creatives and settings",
      "Advertiser account overviews",
    ],
  },
  tierPlan: {
    planBenefits: [
      "Unlimited ad scheduling",
      "Ad manager integrations",
      "Using and creating presets",
      "Reusing creatives and settings",
      "Advertiser account overviews",
    ],
  },
};

const CurrentPlanLayout = (props: any) => {
  // ** Props
  const { currentPlan } = props;

  const getTrialLabel = () => {
    if (currentPlan?.is_free_trial) {
      return `${currentPlan?.free_trial_days_left + 1} day${
        currentPlan?.free_trial_days_left > 0 ? "s" : ""
      } left`;
    } else if (
      !currentPlan?.is_free_trial &&
      currentPlan?.customer_subscription?.length
    ) {
      return "Current plan";
    } else if (
      currentPlan?.is_free_trial &&
      currentPlan?.free_trial_days_left <= 0
    ) {
      return "Trial ended";
    }
    return "";
  };

  const getCustomChipColor = () => {
    if (currentPlan?.is_free_trial) {
      return "error";
    } else if (
      !currentPlan?.is_free_trial &&
      currentPlan?.customer_subscription?.length
    ) {
      return "info";
    } else if (
      currentPlan?.is_free_trial &&
      currentPlan?.free_trial_days_left <= 0
    ) {
      return "error";
    }
    return "default";
  };

  const getPlanImage = () => {
    if (
      !currentPlan.is_free_trial &&
      currentPlan?.customer_subscription?.length
    ) {
      return <img src={PricingIllustration2} width={100} height={100} />;
    } else if (currentPlan.is_free_trial) {
      return <img src={PricingIllustration1} width={100} height={100} />;
    }
  };

  const getPlanTitle = () => {
    if (
      !currentPlan.is_free_trial &&
      currentPlan?.customer_subscription?.length
    ) {
      return currentPlan?.customer_subscription[0]?.plan?.nickname || "";
    } else if (currentPlan.is_free_trial) {
      return "Free Trial";
    }
  };

  const getPlanSubTitle = () => {
    if (
      !currentPlan.is_free_trial &&
      currentPlan?.customer_subscription?.length
    ) {
      return "For small to medium businesses";
    } else if (currentPlan.is_free_trial) {
      return "Experience the power";
    }
  };

  const getPlanAmountLabel = () => {
    if (
      !currentPlan?.is_free_trial &&
      currentPlan?.customer_subscription?.length
    ) {
      const amount = currentPlan.customer_subscription.length
        ? currentPlan.customer_subscription[0].items.data[0].plan.amount / 100
        : "-";
      return (
        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Typography variant="body2" sx={{ mt: 1.6, alignSelf: "flex-start" }}>
            $
          </Typography>
          <Typography
            variant="h3"
            sx={{
              fontWeight: 500,
              color: "#666CFF",
              lineHeight: 1.17,
            }}
          >
            {amount}
          </Typography>
          <Typography variant="body2" sx={{ mb: 1.6, alignSelf: "flex-end" }}>
            /month
          </Typography>
        </Box>
      );
    } else if (currentPlan?.is_free_trial) {
      return (
        <Typography
          variant="h3"
          sx={{
            fontWeight: 500,
            color: "#666CFF",
            lineHeight: 1.17,
          }}
        >
          Free
        </Typography>
      );
    }
  };

  const renderFeatures = (arr: any) => {
    return arr.map((item: string, index: number) => (
      <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
        <CircleOutline
          sx={{ mr: 2.5, fontSize: "0.875rem", color: "text.secondary" }}
        />
        <Typography variant="body2">{item}</Typography>
      </Box>
    ));
  };

  const getPlanFeature = () => {
    if (
      !currentPlan.is_free_trial &&
      currentPlan.customer_subscription.length
    ) {
      return (
        <BoxFeature>
          {renderFeatures(pricingPlans.tierPlan.planBenefits)}
        </BoxFeature>
      );
    } else if (currentPlan.is_free_trial) {
      return (
        <BoxFeature>
          {renderFeatures(pricingPlans.freePlan.planBenefits)}
        </BoxFeature>
      );
    }
  };

  const getButtonName = () => {
    if (
      currentPlan.free_trial_days_left < 1 &&
      !currentPlan.customer_subscription.length
    ) {
      return "Free trial ended";
    } else {
      return "Your Current Plan";
    }
  };

  const isExpired = () => {
    if (
      currentPlan.free_trial_days_left < 1 &&
      !currentPlan.customer_subscription.length
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <BoxWrapper sx={{ border: `1px solid rgba(76, 78, 100, 0.12)` }}>
      {getTrialLabel() ? (
        <CustomChip
          skin="light"
          size="small"
          label={getTrialLabel()}
          color={getCustomChipColor()}
          sx={{
            top: 16,
            right: 24,
            position: "absolute",
            "& .MuiChip-label": {
              px: 2.5,
              fontSize: "0.8125rem",
            },
          }}
        />
      ) : null}
      <Box sx={{ display: "flex", justifyContent: "center", mt: 10 }}>
        {getPlanImage()}
      </Box>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h5" sx={{ mb: 1.5 }}>
          {getPlanTitle()}
        </Typography>
        <Typography variant="body2">{getPlanSubTitle()}</Typography>
        <Box sx={{ mt: 5, mb: 10, position: "relative" }}>
          {getPlanAmountLabel()}
        </Box>
      </Box>
      <Box textAlign="start">
        <Typography
          variant="body2"
          sx={{
            fontSize: "14px",
            fontWeight: 700,
          }}
        >
          All features included
        </Typography>
        {getPlanFeature()}
      </Box>
      <Button
        fullWidth
        variant={isExpired() ? "contained" : "outlined"}
        disabled={isExpired()}
        className={!isExpired() ? "success-btn" : ""}
        disableRipple
      >
        {getButtonName()}
      </Button>
    </BoxWrapper>
  );
};

export default CurrentPlanLayout;
