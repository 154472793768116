import { MARKET_PLACE } from "@core/constants/title-constants";
import {
  Typography,
  useTheme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
} from "@mui/material";

export default function SubmitPost(props: any) {
  const theme = useTheme();
  const { isOpenSubmission, handleClose, handleSubmit, setLinkTxt } = props;

  return (
    <Dialog
      open={isOpenSubmission}
      onClose={handleClose}
      aria-labelledby="user-view-edit"
      aria-describedby="user-view-edit-description"
    >
      <>
        <DialogTitle sx={{ pb: 0 }}>{MARKET_PLACE.CREATE_CONTENT.SUBMIT_LINK_TO_SOCIAL_POST}</DialogTitle>

        <DialogContent 
        sx={{ width: {md: "350px", xs: "100%"} }}
        >
          <Typography
            variant="body2"
            color={theme.palette.customColors.border_color_light}
            sx={{ mb: "27px" }}
          >
            {MARKET_PLACE.CREATE_CONTENT.PAST_THE_LINK}
          </Typography>

          <TextField
            sx={{ width: "100%" }}
            placeholder="Link to social post"
            onChange={(e: any) => setLinkTxt(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ width: "48%" }}
            variant="outlined"
            onClick={handleClose}
          >
            Cancel
          </Button>
          <Button
            sx={{ width: "48%" }}
            variant="contained"
            color="primary"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}
