import { Background, Logo } from "assets";
import {
    Box,
    Typography
  } from "@mui/material";
import themeConfig from "config/themeConfig";

export default function DropdownNotFound({name}: any){
    return( 
        <Typography sx={{display: 'flex',paddingRight: '16px', 
        paddingLeft: '16px', paddingTop: '8px', paddingBottom: '8px' }}>No {name} were found</Typography>
    )

}