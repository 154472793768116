// ** React Imports
import { ChangeEvent, useState, useEffect, useCallback } from "react";

// ** MUI Imports
import { Box, Card, Typography } from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import {
  DataGrid,
  GridColumns,
  GridRenderCellParams,
  GridSortModel,
} from "@mui/x-data-grid";

// ** Custom Components
import { ThemeColor } from "@core/layouts/types";
import CustomChip from "@core/components/mui/chip";
import CustomAvatar from "@core/components/mui/avatar";
import { getInitials } from "@core/utils/get-initials";
import PrintToolbar from "views/table/PrintToolbar";

// ** Custom Types
import { DataGridRowType } from "types/types";

interface StatusObj {
  [key: number]: {
    title: string;
    color: ThemeColor;
  };
}

type SortType = "asc" | "desc" | undefined | null;

// ** renders client column
const renderClient = (params: GridRenderCellParams) => {
  const { row } = params;
  const stateNum = Math.floor(Math.random() * 6);
  const states = [
    "success",
    "error",
    "warning",
    "info",
    "primary",
    "secondary",
  ];
  const color = states[stateNum];

  if (row.avatar.length) {
    return (
      <CustomAvatar
        src={`/images/avatars/${row.avatar}`}
        sx={{ mr: 3, width: "1.875rem", height: "1.875rem" }}
      />
    );
  } else {
    return (
      <CustomAvatar
        skin="light"
        color={color as ThemeColor}
        sx={{ mr: 3, fontSize: ".8rem", width: "1.875rem", height: "1.875rem" }}
      >
        {getInitials(row.user_name ? row.user_name : "John Doe")}
      </CustomAvatar>
    );
  }
};

const statusObj: StatusObj = {
  1: { title: "professional", color: "primary" },
  2: { title: "Optimized", color: "success" },
  3: { title: "Error", color: "error" },
  4: { title: "Not Available", color: "secondary" },
};

const columns: GridColumns = [
  {
    flex: 0.25,
    minWidth: 250,
    field: "user_name",
    headerName: "USER",
    renderCell: (params: GridRenderCellParams) => {
      const { row } = params;

      return (
        <Box className="sync-align-center">
          <Box
            sx={{
              background: row.color,
              height: "9px",
              width: "9px",
              borderRadius: "5px",
              mr: 3,
            }}
          ></Box>
          <Box className="sync-direction-column">
            <Typography
              noWrap
              variant="subtitle2"
              sx={{ color: "text.primary" }}
            >
              {row.user_name}
            </Typography>
          </Box>
        </Box>
      );
    },
  },
  {
    flex: 0.175,
    minWidth: 120,
    headerName: "Email",
    field: "profile",
    renderCell: (params: GridRenderCellParams) => (
      <Typography variant="body2" sx={{ color: "text.primary" }}>
        {params.row.email}
      </Typography>
    ),
  },
  {
    flex: 0.175,
    minWidth: 120,
    headerName: "Role",
    field: "post",
    renderCell: (params: GridRenderCellParams) => (
      <Typography variant="body2" sx={{ color: "text.primary" }}>
        {params.row.post}
      </Typography>
    ),
  },
  {
    flex: 0.175,
    minWidth: 140,
    field: "status",
    headerName: "Status",
    renderCell: (params: GridRenderCellParams) => {
      const status = statusObj[params.row.status];

      return (
        <CustomChip
          size="small"
          skin="light"
          color={status.color}
          label={status.title}
          sx={{ "& .MuiChip-label": { textTransform: "capitalize" } }}
        />
      );
    },
  },
  {
    flex: 0.175,
    minWidth: 110,
    field: "Comments",
    headerName: "optimize",
    renderCell: (params: GridRenderCellParams) => {
      const { row } = params;
      return (
        <Box>
          <MessageIcon sx={{ color: "#9E9FAB" }} />
        </Box>
      );
    },
  },
];

export default function UserTable() {
  const rowsData: any = [
    {
      age: "21",
      avatar: "7.png",
      city: "Łazy",
      email: "susanna.Lind57@gmail.com",
      experience: "7 Years",
      user_name: "Jordan Stevenson",
      id: 31,
      post: "Admin",
      optimize: true,
      profile: "Lofi Fruits",
      status: 1,
      color: "#72E128",
      budget: "€500",
      bid: "€0.22 -> €0.23",
    },
    {
      age: "22",
      avatar: "7.png",
      city: "Łazy",
      email: "estelle.Bailey10@gmail.com",
      experience: "7 Years",
      user_name: "Benedetto Rossiter",
      id: 32,
      post: "Admin",
      optimize: false,
      profile: "Lofi Fruits",
      status: 2,
      color: "primary.main",
      budget: "€500",
      bid: "€0.22 -> €0.23",
    },
    {
      age: "23",
      avatar: "7.png",
      city: "Łazy",
      email: "susanna.Lind57@gmail.com",
      experience: "7 Years",
      user_name: "Bentlee Emblin",
      id: 33,
      post: "Campaign manager",
      optimize: true,
      profile: "Lofi Fruits",
      status: 3,
      color: "#72E128",
      budget: "€500",
      bid: "€0.22 -> €0.23",
    },
    {
      age: "24",
      avatar: "7.png",
      city: "Łazy",
      email: "estelle.Bailey10@gmail.com",
      experience: "7 Years",
      user_name: "Bertha Biner",
      id: 34,
      post: "Campaign manager",
      optimize: false,
      profile: "Lofi Fruits",
      status: 4,
      color: "#72E128",
      budget: "€500",
      bid: "€0.22 -> €0.23",
    },
    {
      age: "25",
      avatar: "7.png",
      city: "Łazy",
      email: "milo86@hotmail.com",
      experience: "7 Years",
      user_name: "Beverlie Krabbe",
      id: 35,
      post: "Campaign manager",
      optimize: false,
      profile: "Lofi Fruits",
      status: 4,
      color: "primary.main",
      budget: "€500",
      bid: "€0.22 -> €0.23",
    },
    {
      age: "26",
      avatar: "7.png",
      city: "Łazy",
      email: "lonnie35@hotmail.com",
      experience: "7 Years",
      user_name: "Bradan Rosebotham",
      id: 36,
      post: "Scheduler",
      optimize: false,
      profile: "Lofi Fruits",
      status: 3,
      color: "primary.main",
      budget: "€500",
      bid: "€0.22 -> €0.23",
    },
    {
      age: "27",
      avatar: "7.png",
      city: "Łazy",
      email: "ahmad_Collins@yahoo.com",
      experience: "7 Years",
      user_name: "Bradan Rosebotham",
      id: 37,
      post: "Bree Kilday",
      optimize: false,
      profile: "Lofi Fruits",
      status: 2,
      color: "primary.main",
      budget: "€500",
      bid: "€0.22 -> €0.23",
    },
    {
      age: "28",
      avatar: "7.png",
      city: "Łazy",
      email: "milo86@hotmail.com",
      experience: "7 Years",
      user_name: "Breena Gallemore",
      id: 38,
      post: "Scheduler",
      optimize: true,
      profile: "Lofi Fruits",
      status: 1,
      color: "primary.main",
      budget: "€500",
      bid: "€0.22 -> €0.23",
    },
    {
      age: "27",
      avatar: "7.png",
      city: "Łazy",
      email: "lonnie35@hotmail.com",
      experience: "7 Years",
      user_name: "Breena Gallemore",
      id: 39,
      post: "Scheduler",
      optimize: false,
      profile: "Lofi Fruits",
      status: 2,
      color: "primary.main",
      budget: "€500",
      bid: "€0.22 -> €0.23",
    },
    {
      age: "28",
      avatar: "7.png",
      city: "Łazy",
      email: "ahmad_Collins@yahoo.com",
      experience: "7 Years",
      user_name: "Brockie Myles",
      id: 40,
      post: "Scheduler",
      optimize: true,
      profile: "Lofi Fruits",
      status: 1,
      color: "primary.main",
      budget: "€500",
      bid: "€0.22 -> €0.23",
    },
    {
      age: "27",
      avatar: "7.png",
      city: "Łazy",
      email: "otho21@gmail.com",
      experience: "7 Years",
      user_name: "Brockie Myles",
      id: 41,
      post: "Scheduler",
      optimize: false,
      profile: "Lofi Fruits",
      status: 2,
      color: "primary.main",
      budget: "€500",
      bid: "€0.22 -> €0.23",
    },
    {
      age: "28",
      avatar: "7.png",
      city: "Łazy",
      email: "florencio.Little@hotmail.com",
      experience: "7 Years",
      user_name: "Brockie Myles",
      id: 42,
      post: "Scheduler",
      optimize: true,
      profile: "Lofi Fruits",
      status: 1,
      color: "primary.main",
      budget: "€500",
      bid: "€0.22 -> €0.23",
    },
  ];

  function loadServerRows(currentPage: number, data: DataGridRowType[]) {
    return data.slice(currentPage * pageSize, (currentPage + 1) * pageSize);
  }

  const [page, setPage] = useState(0);
  const [total, setTotal] = useState<number>(0);
  const [sort, setSort] = useState<SortType>("asc");
  const [pageSize, setPageSize] = useState<number>(7);
  const [rows, setRows] = useState<DataGridRowType[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [sortColumn, setSortColumn] = useState<string>("user_name");
  const [data] = useState<DataGridRowType[]>(rowsData);
  const [filteredData, setFilteredData] = useState<DataGridRowType[]>([]);

  const fetchTableData = useCallback(
    async (sort: SortType, q: string, column: string) => {
      setTotal(rowsData.length);
      setRows(loadServerRows(page, rowsData));
    },
    [page, pageSize]
  );

  useEffect(() => {
    fetchTableData(sort, searchValue, sortColumn);
  }, [fetchTableData, searchValue, sort, sortColumn]);

  const handleSortModel = (newModel: GridSortModel) => {
    if (newModel.length) {
      setSort(newModel[0].sort);
      setSortColumn(newModel[0].field);
      fetchTableData(newModel[0].sort, searchValue, newModel[0].field);
    } else {
      setSort("asc");
      setSortColumn("user_name");
    }
  };

  const escapeRegExp = (value: string) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };

  const handleSearch = (searchValue: string) => {
    setSearchValue(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = data.filter((row) => {
      return Object.keys(row)?.some((field) => {
        // @ts-ignore
        return searchRegex.test(row[field].toString());
      });
    });
    if (searchValue.length) {
      setFilteredData(filteredRows);
    } else {
      setFilteredData([]);
    }
  };
  return (
    <Box sx={{ mt: 5 }}>
      <Typography variant="h5">User List</Typography>
      <Card>
        <DataGrid
          sx={{ mt: 5 }}
          autoHeight
          pagination
          rows={rows.length ? rows : data}
          rowCount={total}
          columns={columns}
          checkboxSelection
          pageSize={pageSize}
          sortingMode="server"
          paginationMode="server"
          onSortModelChange={handleSortModel}
          rowsPerPageOptions={[7, 10, 25, 50]}
          onPageChange={(newPage) => setPage(newPage)}
          components={{ Toolbar: PrintToolbar }}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          componentsProps={{
            toolbar: {
              value: searchValue,
              clearSearch: () => handleSearch(""),
              onChange: (event: ChangeEvent<HTMLInputElement>) =>
                handleSearch(event.target.value),
            },
          }}
        />
      </Card>
    </Box>
  );
}
