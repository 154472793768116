import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
    currentPlan: any;
    planList: any;
    stripeChekoutUrl: any;
}

const initialState: InitialState = {
    currentPlan: {},
    planList: [],
    stripeChekoutUrl: ""
};

const subscriptionSlice = createSlice({
    name: 'settingSlice',
    initialState: initialState,
    reducers: {
        setCurrentPlan: (
            state: Draft<InitialState>,
            action: PayloadAction<any>
        ) => {
            state.currentPlan = action.payload;
        },
        setPlanList: (
            state: Draft<InitialState>,
            action: PayloadAction<any>
        ) => {
            state.planList = action.payload;
        },
        setStripeCheckoutURL: (
            state: Draft<InitialState>,
            action: PayloadAction<any>
        ) => {
            state.stripeChekoutUrl = action.payload;
        },
    }
});

export const { setCurrentPlan, setPlanList, setStripeCheckoutURL } =
    subscriptionSlice.actions;

export default subscriptionSlice.reducer;
