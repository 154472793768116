// ** React
import { useState, Fragment, useEffect, useContext } from "react";

// ** MUI Imports
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Collapse,
  FormControlLabel,
  IconButton,
  ImageList,
  ImageListItem,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";

// ** Third Party Library
import moment from "moment";

// ** Redux
import { useAppDispatch, useAppSelector } from "store/store";
import {
  getSchedulerHistoryRowData,
  postStopScheduling,
  reuseCreatives,
} from "store/Thunk/autoSchedulerThunk";
import { ScreenLoading } from "store/Slice/AuthSlice";
import { SchedulerHistoryRowData } from "store/Slice/autoSchedulerSlice";
import { AutoschudlerContext } from "context/autoschedulerContex";

// ** Custom Component
import { PLATFORMS } from "@core/constants/enum";
import CustomChip from "@core/components/mui/chip";
import { MEDIA_PLANNER } from "@core/constants/title-constants";
import { BATCH_STATUS } from "@core/constants/enum";
import GetCountryName from "components/GetCountryData/getCountryName";

// ** Icons
import StopIcon from "@mui/icons-material/Stop";
import CloseIcon from "@mui/icons-material/Close";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import FacebookIcon from "icon/facebook";
import SnapchatIcon from "icon/snapchat";
import TiktokIcon from "icon/tiktok";

interface Props {
  setDialogOpen?: any;
  setNestedDialog?: any;
  historyRowData?: any;
  schedulingLoading?: any;
  setSchedulingLoading?: any;
  setStep?: any;
  handleStatusUpdateClick(historyRowData: any): void;
  onReuseCreativesSelect(historyRowData: any): void;
}

const NestedTableDialog = ({
  setDialogOpen,
  setNestedDialog,
  historyRowData,
  schedulingLoading,
  setSchedulingLoading,
  setStep,
  handleStatusUpdateClick,
  onReuseCreativesSelect,
}: Props) => {
  const dispatch = useAppDispatch();
  let autoSchedulerTitle = MEDIA_PLANNER.auto_scheduler;

  const { handleAutodraftData }: any = useContext(AutoschudlerContext);

  const { uploadsesId, schedulerHistoryRowData } = useAppSelector(
    (state: any) => state.autoSchedulerSlice
  );
  const { screenLoading }: any = useAppSelector((state) => state.authReducer);

  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(null);
  const [reuseLoading, setReuseLoading] = useState<boolean>(false);
  const [selectAll, setSelectAll] = useState<any>([]);
  const [selectedCreatives, setSelectedCreatives] = useState<any>({});
  const [selectedAllCreatives, setSelectedAllCreative] = useState(false);

  useEffect(() => {
    if (schedulerHistoryRowData) {
      setTableData(schedulerHistoryRowData);
    }
  }, [schedulerHistoryRowData]);

  useEffect(() => {
    getLatestSchedulerHistoryRowData();
  }, []);

  const getLatestSchedulerHistoryRowData = () => {
    if (historyRowData) {
      const data = {
        uploadsesid: historyRowData,
      };
      dispatch(ScreenLoading(true));
      dispatch(getSchedulerHistoryRowData({ data }));
    }
  };

  const handledBackClick = () => {
    dispatch(SchedulerHistoryRowData([]));
    setNestedDialog(false);
    setDialogOpen(true);
  };

  const handleStartButtonClick = (row: any) => {
    setSchedulingLoading(true);
    const data = {
      ad_scheduler_id: row?.id,
      completed:
        row?.error?.status == "Stop" || row?.error?.status == "Error"
          ? "No"
          : "Stop",
    };

    dispatch(postStopScheduling({ data })).then((res: any) => {
      setSchedulingLoading(false);
      getLatestSchedulerHistoryRowData();
      if (res?.payload?.status == 200 || res?.payload?.status == 201) {
        handleStatusUpdateClick(historyRowData);
      }
    });
  };

  const handledReuseBatchButton = () => {
    setReuseLoading(true);
    let tempArray: any = [];

    tableData?.map((item: any, index: number) => {
      item?.ad_creative?.map((creative: any, i: number) => {
        selectedCreatives &&
          selectedCreatives[index]?.map((selectedCrev: any) => {
            if (creative?.id == selectedCrev) {
              tempArray.push({
                filename: creative.filename,
                url: creative.url,
                creative_type: creative.creative_type,
                placement_type: creative.placement_type,
                user_id: creative.user_id,
                resolution: creative.resolution,
                creative_size: creative.creative_size,
              });
            }
          });
      });
    });

    let reuseBatchData = {
      uploadsesid: uploadsesId,
      reuse_creatives: tempArray,
      old_uploadsesid: historyRowData,
    };

    dispatch(reuseCreatives({ reuseBatchData })).then((res: any) => {
      setReuseLoading(true);
      if (res.error) {
        setReuseLoading(false);
      } else {
        // handleAutodraftData(4);
        onReuseCreativesSelect(res?.payload?.data?.data[0]);
        setReuseLoading(false);
        setNestedDialog(false);
        setDialogOpen(false);
        setStep(4);
      }
    });
  };

  const handledCheckAllCreatives = (checked: any) => {
    setSelectedAllCreative(checked);
    if (checked) {
      let selected: any = {};
      let selectAll: any = [];
      tableData?.map((item: any, index: number) => {
        const array: any = [];
        selectAll.push(index);
        item?.ad_creative?.map((creative: any) => {
          array?.push(creative?.id);
        });
        selected = { ...selected, [index]: array };
      });
      setSelectAll(selectAll);
      setSelectedCreatives(selected);
    } else {
      setSelectedCreatives({});
      setSelectAll([]);
    }
  };

  const Row = (row: any, index: number) => {
    const handleOpen = (index: any) => {
      if (open === index) {
        setOpen(null);
      } else {
        setOpen(index);
        handleSelectAll(true, index, false);
      }
    };

    const handledCheck = (check: any, file: any) => {
      if (selectedCreatives && selectedCreatives[index]?.length) {
        if (check) {
          var tempArray = [...selectedCreatives[index], file];
          selectedCreatives[index]?.length + 1 === row?.ad_creative?.length
            ? setSelectAll([...selectAll, index])
            : setSelectAll(selectAll.filter((item: any) => item !== index));
          setSelectedCreatives({ ...selectedCreatives, [index]: tempArray });
          if (selectAll?.length + 1 === tableData?.length) {
            setSelectedAllCreative(true);
          }
        } else {
          selectedCreatives[index]?.length - 1 === row?.ad_creative?.length
            ? setSelectAll([...selectAll, index])
            : setSelectAll(selectAll.filter((item: any) => item !== index));

          var creativeArray = selectedCreatives[index].filter(
            (item: any) => item !== file
          );

          if (selectAll?.length + 1 === tableData?.length) {
            setSelectedAllCreative(true);
          }
          if (creativeArray?.length) {
            setSelectedCreatives({
              ...selectedCreatives,
              [index]: creativeArray,
            });
          } else {
            const newState = { ...selectedCreatives };
            delete newState[index];
            setSelectedCreatives(newState);
          }
          if (selectedCreatives?.length !== row?.ad_creative) {
            setSelectedAllCreative(false);
          }
        }
      } else {
        if (row?.ad_creative?.length === 1) {
          setSelectAll([...selectAll, index]);
        }
        var array = {
          ...selectedCreatives,
          [index]: [file],
        };
        setSelectedCreatives(array);
      }
    };

    const handleSelectAll = (check: any, value: any, isCheckbox: boolean) => {
      var array: any = [];
      if (
        Object.keys(selectedCreatives).includes(index.toString()) &&
        !isCheckbox
      ) {
      } else {
        if (check) {
          setSelectAll([...selectAll, value]);
          row?.ad_creative?.map((item: any) => {
            array = [...array, item?.id];
          });
          setSelectedCreatives({ ...selectedCreatives, [index]: array });
          if (selectAll?.length + 1 === tableData?.length) {
            setSelectedAllCreative(true);
          }
        } else {
          setSelectAll(selectAll.filter((item: any) => item !== value));
          const newState = { ...selectedCreatives };
          delete newState[index];
          setSelectedCreatives(newState);
          if (selectedCreatives?.length !== row?.ad_creative) {
            setSelectedAllCreative(false);
          }
        }
      }
    };

    const statusColor =
      row?.error?.status === "Yes"
        ? "success"
        : row?.error?.status === "Error"
        ? "error"
        : "warning";

    const landing_page_url = row?.ad_creative
      ?.filter((val: any) => val.landingpage_url)
      .map((val: any) => val.landingpage_url)
      .join(", ");

    const renderCountries = (countries: string) => {
      const countryList = countries.split(",");

      if (countryList.length > 3) {
        const remainingCountries = countryList
          .slice(3)
          .map((item: string) => GetCountryName(item))
          .join(", ");
        return (
          <Fragment>
            {countryList.slice(0, 3).map((item: string, index: number) => (
              <Box key={index} sx={{ display: "flex", mt: 1 }}>
                <Box
                  component="img"
                  src={`https://cdn.ip2location.com/assets/img/flags/${item.toLowerCase()}.png`}
                />
                <Typography variant="subtitle2" sx={{ ml: 2 }}>
                  {GetCountryName(item)}
                </Typography>
              </Box>
            ))}
            <Typography variant="subtitle2">
              + {countryList.length - 3} more ({remainingCountries})
            </Typography>
          </Fragment>
        );
      }

      return countryList.map((item: string, index: number) => (
        <Box key={index} sx={{ display: "flex", mt: 1 }}>
          <Box
            component="img"
            src={`https://cdn.ip2location.com/assets/img/flags/${item.toLowerCase()}.png`}
          />
          <Typography variant="subtitle2" sx={{ ml: 2 }}>
            {GetCountryName(item)}
          </Typography>
        </Box>
      ));
    };

    return (
      <Fragment>
        <>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme: any) => theme.zIndex.drawer + 1,
            }}
            open={reuseLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
        </>
        <TableRow
          sx={{
            "& > *": { borderBottom: "unset" },
            backgroundColor:
              open == index ? "rgba(255, 77, 73, 0.05)" : "transparent",
          }}
          onClick={() => handleOpen(index)}
          key={index}
        >
          <TableCell>
            <Checkbox
              indeterminate={
                selectedCreatives[index]?.length &&
                selectedCreatives[index]?.length < row?.ad_creative?.length
              }
              checked={selectAll?.includes(index)}
              onChange={(e) => handleSelectAll(e.target.checked, index, true)}
            />
          </TableCell>
          <TableCell component="th" scope="row">
            {row?.platform == PLATFORMS.FACEBOOK && (
              <FacebookIcon active={true} />
            )}
            {row?.platform == PLATFORMS.SNAP && <SnapchatIcon active={true} />}
            {row?.platform == PLATFORMS.TIKTOK && <TiktokIcon active={true} />}
          </TableCell>
          <TableCell>{row?.selected_placement || "-"}</TableCell>
          <TableCell sx={{ width: 1500 }}>{row?.campaign_name}</TableCell>
          <TableCell sx={{ width: 170 }}>{landing_page_url || "-"}</TableCell>
          <TableCell sx={{ width: 170 }}>
            <Box>
              {/* <Box sx={{ display: "flex" }}>
                <Typography variant="subtitle2" sx={{ fontWeight: 700 }}>
                  {row?.countries?.split(",")?.length}
                </Typography>
                <Typography variant="subtitle2" sx={{ fontWeight: 700, ml: 1 }}>
                  {autoSchedulerTitle.COUNTRIES}
                </Typography>
              </Box> */}
              <>
                <Tooltip
                  componentsProps={{
                    tooltip: {
                      sx: {
                        maxWidth: "500px",
                      },
                    },
                  }}
                  title={
                    row?.countries?.split(",")?.length > 3
                      ? row?.countries
                          .split(",")
                          ?.slice(3, row?.countries?.length)
                          .map((item: any, index: number) => {
                            return GetCountryName(item);
                          })
                          .join(", ")
                      : null
                  }
                >
                  <Typography variant="subtitle2" sx={{ ml: 2 }}>
                    {row?.countries && renderCountries(row.countries)}
                  </Typography>
                </Tooltip>
              </>
            </Box>
          </TableCell>
          <TableCell>{row?.ad_creative?.length} </TableCell>
          <TableCell>
            <Box
              className="sync-align-center"
              sx={{ zIndex: 1000 }}
              onClick={(e) => e.stopPropagation()}
            >
              <CustomChip
                size="small"
                skin="light"
                color={statusColor}
                label={
                  row?.error?.status === BATCH_STATUS.ERROR
                    ? BATCH_STATUS.ERROR
                    : row?.error?.status === BATCH_STATUS.NO
                    ? BATCH_STATUS.SCHEDULING
                    : row?.error?.status === BATCH_STATUS.STOP
                    ? BATCH_STATUS.STOPPED
                    : BATCH_STATUS.DONE
                }
                sx={{ "& .MuiChip-label": { textTransform: "capitalize" } }}
              />
              {row?.error?.status === BATCH_STATUS.ERROR && (
                <Tooltip title={row?.error?.error_message}>
                  <ErrorOutlineIcon
                    sx={{ ml: 2, color: "rgba(76, 78, 100, 0.54)" }}
                  />
                </Tooltip>
              )}
              {row?.error?.status === BATCH_STATUS.NO && (
                <>
                  <IconButton onClick={() => handleStartButtonClick(row)}>
                    <StopIcon sx={{ color: "primary.main" }} />
                  </IconButton>
                  <Typography
                    variant="subtitle2"
                    color="primary.main"
                    sx={{ fontWeight: 500 }}
                  >
                    {BATCH_STATUS.STOP}
                  </Typography>
                </>
              )}

              {(row?.error?.status === BATCH_STATUS.ERROR ||
                row?.error?.status == BATCH_STATUS.STOP) && (
                <>
                  <IconButton onClick={() => handleStartButtonClick(row)}>
                    <PlayArrowIcon sx={{ color: "primary.main" }} />
                  </IconButton>
                  <Typography
                    variant="subtitle2"
                    color="primary.main"
                    sx={{ fontWeight: 500 }}
                  >
                    {BATCH_STATUS.START}
                  </Typography>
                </>
              )}
            </Box>
          </TableCell>
        </TableRow>

        <TableRow>
          <TableCell colSpan={10} sx={{ py: "0 !important" }}>
            <Collapse
              in={index === open ? true : false}
              timeout="auto"
              unmountOnExit
            >
              {row?.ad_creative?.length ? (
                <Box sx={{ m: 2 }}>
                  <Typography
                    sx={{ fontSize: "18px", fontWeight: 500 }}
                    gutterBottom
                    component="div"
                  >
                    {autoSchedulerTitle.CREATIVES_INSIDE}
                  </Typography>
                  <FormControlLabel
                    sx={{ mb: 2 }}
                    label="Select All"
                    control={
                      <Checkbox
                        indeterminate={
                          selectedCreatives[index]?.length &&
                          selectedCreatives[index]?.length <
                            row?.ad_creative?.length
                        }
                        checked={selectAll?.includes(index)}
                        onChange={(e) =>
                          handleSelectAll(e.target.checked, index, true)
                        }
                      />
                    }
                  />
                  <ImageList
                    sx={{ width: "100%", height: "100%" }}
                    cols={4}
                    rowHeight={250}
                  >
                    {row?.ad_creative?.map((item: any, indexing: number) => {
                      return (
                        <ImageListItem key={indexing} sx={{ height: 200 }}>
                          {item?.creative_type == "Video" ? (
                            <video width="200px" height="200px" controls>
                              <source src={item?.url} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                          ) : (
                            <Box
                              component={"img"}
                              src={item?.url}
                              alt={item?.filename}
                              loading="lazy"
                              sx={{ height: 200, width: 200 }}
                            />
                          )}

                          <Checkbox
                            sx={{ position: "absolute" }}
                            checked={
                              selectedCreatives[index]?.includes(item?.id)
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              handledCheck(e.target.checked, item?.id)
                            }
                          />
                        </ImageListItem>
                      );
                    })}
                  </ImageList>
                </Box>
              ) : (
                <Typography
                  sx={{ padding: 5, fontWeight: 500 }}
                  variant="subtitle1"
                >
                  {autoSchedulerTitle.NO_CREATIVES}
                </Typography>
              )}
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  const extraName = schedulerHistoryRowData[0]?.extra_name
    ? `${schedulerHistoryRowData[0]?.extra_name}, `
    : "";

  const formattedScheduledDate = moment(
    schedulerHistoryRowData[0]?.scheduled_for
  ).format("DD MMM HH:mm");

  return (
    <>
      <Box>
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme: any) => theme.zIndex.drawer + 1,
          }}
          open={schedulingLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Box>

      <Box sx={{ p: "20px" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h5">
            {autoSchedulerTitle.BATCH_DETAILS}
          </Typography>
          <IconButton onClick={handledBackClick}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Typography variant="subtitle2" sx={{ fontWeight: 700, mt: 2 }}>
          {extraName}
          {formattedScheduledDate}
        </Typography>
      </Box>

      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell>
                <Checkbox
                  indeterminate={
                    selectAll.length && selectAll.length < tableData.length
                  }
                  checked={selectedAllCreatives}
                  onChange={(e) => handledCheckAllCreatives(e.target.checked)}
                />
              </TableCell>
              <TableCell>{autoSchedulerTitle.PLATFORM}</TableCell>
              <TableCell>{autoSchedulerTitle.PLACEMENT}</TableCell>
              <TableCell>{autoSchedulerTitle.CAMPAIGN}</TableCell>
              <TableCell>{autoSchedulerTitle.LANDINGPAGE_URL}</TableCell>
              <TableCell>{autoSchedulerTitle.COUNTRIES}</TableCell>
              <TableCell>{autoSchedulerTitle.CREATIVES}</TableCell>
              <TableCell>{autoSchedulerTitle.STATUS}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody sx={{ cursor: "pointer" }}>
            {tableData &&
              tableData?.map((row: any, index: any) => Row(row, index))}
          </TableBody>
        </Table>
        {screenLoading && (
          <LinearProgress
            sx={{
              position: "absolute",
              top: "180px",
              left: 0,
              right: 0,
              height: 2,
            }}
          />
        )}
      </TableContainer>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          px: "20px",
          pb: "20px",
        }}
      >
        <Button
          variant="contained"
          sx={{ mt: 4 }}
          onClick={() => handledReuseBatchButton()}
          disabled={Object.keys(selectedCreatives)?.length ? false : true}
        >
          {autoSchedulerTitle.REUSE_BATCH}
        </Button>
      </Box>
    </>
  );
};

export default NestedTableDialog;
