// ** React Imports
import { useState, useEffect } from "react";

// ** MUI Imports
import {
  useTheme,
  Box,
  Button,
  Typography,
  Grid,
  LinearProgress,
  CircularProgress,
} from "@mui/material";
import { alpha } from "@mui/material/styles";

// ** Third Party library
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import _ from "lodash";

// ** Redux
import { useAppSelector, useAppDispatch } from "store/store";
import { getCreatorInfo } from "store/Thunk/myCreatorInfoThunk";
import {
  getAllJobsDetail,
  submitJobSubmission,
} from "store/Thunk/createJobThunk";
import { notificationFail } from "store/Slice/notificationSlice";

// ** Custom Component
import { route_info } from "navigation/route";
import { MARKET_PLACE } from "@core/constants/title-constants";
import { DIMENSIONS } from "@core/constants/enum";
import DetailSubmissionCard from "components/DetailSubmissionCard";
import ContentTypeCard from "components/ContentTypeCard";
import ProfileCompletedDialog from "pages/marketplace/create-content/Dialog/ProfileCompleted";
import SubmissionUploadedDialog from "pages/marketplace/create-content/Dialog/SubmissionUploaded";
import Milestone from "pages/marketplace/create-content/Milestone";
import Earning from "pages/marketplace/create-content/EarningCard";

// ** Assets
import {
  Tiktok,
  Snapchat,
  Instagram,
  BlankImage,
  VerticalBlue,
  HorizontalBlue,
  SquareBlue,
} from "assets";

export default function CreatorMarketplaceJobDetail() {
  const navigate = useNavigate();
  const dispatch: any = useAppDispatch();
  const theme = useTheme();
  let { id } = useParams();
  const header: any = localStorage.getItem("header");
  const user_id: any = JSON.parse(header).user_id;

  const { loadingState, allJobDetail } = useAppSelector(
    (state: any) => state.createJobReducer
  );

  const [step, setStep] = useState<number>(2);
  const [jobDetail, setJobDetail] = useState<any>();
  const [openUploadFile, setOpenUploadFile] = useState<boolean>(false);
  const [isProfileCompleted, setIsProfileCompleted] = useState<boolean>(false);
  const [isSubmissionUploaded, setIsSubmissionUploaded] =
    useState<boolean>(false);
  const [isSubmissionCompleted, setIsSubmissionCompleted] =
    useState<boolean>(false);
  const [files, setFiles] = useState<any>();
  const [isVideoOnly, setIsVideoOnly] = useState<boolean>(true);
  const [uploadLoader, setUploadLoader] = useState<boolean>(false);

  useEffect(() => {
    // Call Job detail API
    let data = { id };
    dispatch(getAllJobsDetail({ data }));
  }, []);

  useEffect(() => {
    // Handle API response after fetching job detail
    setJobDetail(allJobDetail);

    if (!_.isEmpty(allJobDetail)) {
      if (
        allJobDetail.publish_content_type === "collect_video_only" &&
        !_.isEmpty(allJobDetail.submission) &&
        allJobDetail.submission.approval_status[0].approval_status !==
          "declined"
      ) {
        setIsSubmissionUploaded(true);
      }
      if (allJobDetail.publish_content_type === "collect_video_and_post") {
        setIsVideoOnly(false);
        if (!_.isEmpty(allJobDetail.submission)) setIsSubmissionUploaded(true);
        else setIsSubmissionUploaded(false);
      }
      if (allJobDetail?.submission) {
        setStep(allJobDetail?.submission?.milestone?.completed);
      }
    }
  }, [allJobDetail]);

  // Handle upload content button
  const handleUploadContent = (isResubmit: boolean) => {
    if (!isResubmit) {
      dispatch(getCreatorInfo({ navigate }))
        .then((res: any) => {
          let data = { id };

          if (!res.error) {
            let tempData = res.payload.data;

            setOpenUploadFile(true);

            // Check creator profile progress to upload data
            if (tempData && tempData.length) {
              if (tempData[0].percentage < 75) {
                // Open these code after upload content checked
                setIsProfileCompleted(false);
                setIsSubmissionUploaded(false);
              } else {
                setIsProfileCompleted(true);
              }
            } else {
              setIsProfileCompleted(false);
            }

            dispatch(getAllJobsDetail({ data }));
          } else {
            dispatch(
              notificationFail(
                "We're sorry, but something didn't work as expected. Please try again."
              )
            );
          }
        })
        .catch((error: any) => {
          const result = error.response;
          return Promise.reject(result);
        });
    } else {
      setIsProfileCompleted(true);
      setOpenUploadFile(true);
    }
  };

  // Close upload content dialog
  const handleUploadContentClose = () => {
    setFiles(null);
    setOpenUploadFile(false);
  };

  const handleJobSubmission = async ({ formData }: any) => {
    let isResubmit: boolean = false;

    if (Object.keys(allJobDetail?.submission).length !== 0) {
      if (
        allJobDetail?.submission?.approval_status[0]?.approval_status ==
        "declined"
      ) {
        isResubmit = true;
      }
    }

    try {
      const response: any = await dispatch(
        submitJobSubmission({ formData, id, isResubmit })
      );

      if (!response.error) {
        setIsSubmissionUploaded(true);
        setIsSubmissionCompleted(true);
        setFiles(null);
      } else {
        setIsSubmissionUploaded(false);
        setIsSubmissionCompleted(false);
        dispatch(
          notificationFail(
            "We're sorry, but something didn't work as expected. Please try again."
          )
        );
      }
    } catch {
      setIsSubmissionUploaded(false);
      setIsSubmissionCompleted(false);
      dispatch(
        notificationFail(
          "We're sorry, but something didn't work as expected. Please try again."
        )
      );
    } finally {
      setOpenUploadFile(false);
      setUploadLoader(false);
    }
  };

  const handleSubmitSubmission = () => {
    let formData = new FormData();

    // Adding files to the formdata
    formData.append("job_id", jobDetail.id);
    formData.append("video", files);
    setUploadLoader(true);
    handleJobSubmission({ formData });
  };

  // If creator profile is incomplete
  const handleCompleteProfile = () => {
    navigate(route_info.market_place.my_creator_info);
  };

  // Sumission uploaded successfully
  const handleContinue = () => {
    setIsSubmissionCompleted(false);
  };

  const getRequirements = (requirement: any) => {
    let requirements = [];
    if (requirement?.hashtag && requirement?.hashtags.length) {
      requirements.push(
        `Must contain the following hashtags: ${requirement?.hashtags}`
      );
    }
    if (requirement?.caption && requirement?.captionValue) {
      requirements.push(
        `Must use the following caption: ${requirement?.captionValue}`
      );
    }
    if (requirement?.taggedAccount && requirement?.taggedAccountValue) {
      requirements.push(
        `Must tag the following accounts: ${requirement?.taggedAccountValue}`
      );
    }
    if (requirement?.videoLength && requirement?.videoLengthValue) {
      requirements.push(
        `Minimum video length (in seconds) must be: ${requirement?.videoLengthValue}`
      );
    }
    if (requirement?.footage && requirement?.footageValue) {
      requirements.push(
        `Must include the following footage: ${requirement?.footageValue}`
      );
    }
    if (requirement?.sound && requirement?.soundValue) {
      requirements.push(
        `Must include the following sound: ${requirement?.soundValue}`
      );
    }

    return (
      requirements &&
      requirements.length > 0 && (
        <Box>
          <Typography variant="h6" color={theme.palette.customColors.comet}>
            {MARKET_PLACE.CREATE_CONTENT.JOB_REQUIREMENTS}
          </Typography>
          {requirements.length > 0 &&
            requirements.map((data: any, index: any) => (
              <Box key={requirement} className="sync-flex-direction-align">
                <div className="disc"></div>
                <Typography
                  variant="body2"
                  color={alpha(theme.palette.customColors.border_color, 0.68)}
                >
                  {data}
                </Typography>
              </Box>
            ))}
        </Box>
      )
    );
  };

  return (
    <Box className="job-detail-main-block">
      {loadingState ? (
        <Box className="loader" sx={{ height: "100%" }}>
          <CircularProgress size={20} />
        </Box>
      ) : (
        <>
          <Box className="job-title-block">
            <Typography
              variant="subtitle2"
              onClick={() => navigate(route_info.market_place.all_job)}
              className="txt-back-to-overview"
            >
              {MARKET_PLACE.CREATE_CONTENT.BACK_TO_OVERVIEW}
            </Typography>
            {user_id === jobDetail?.user?.id ? (
              <Button
                variant="contained"
                onClick={() =>
                  navigate(route_info.market_place.submissions, {
                    state: { job_id: jobDetail.id },
                  })
                }
              >
                {MARKET_PLACE.CREATE_CONTENT.VIEW_SUBMISSION}
              </Button>
            ) : isSubmissionUploaded ? (
              <Button disabled variant="contained">
                {MARKET_PLACE.CREATE_CONTENT.VIDEO_SUBMITTED}
              </Button>
            ) : (
              <Button
                variant="contained"
                onClick={() => handleUploadContent(false)}
              >
                {MARKET_PLACE.CREATE_CONTENT.UPLOAD_YOUR_CONTENT}
                {openUploadFile && <input type="file" hidden />}
              </Button>
            )}
          </Box>
          <Grid container spacing={6} className="job-detail-block">
            <Grid item xs={12} md={6}>
              <Box
                className="detail-left-block"
                sx={{ bgcolor: theme.palette.primary.contrastText }}
              >
                <Box sx={{ position: "relative" }}>
                  <Box className="detail-img-overlay">
                    <Box className="detail-left-icon">
                      <ContentTypeCard
                        contentType={jobDetail?.publish_content_type}
                      />
                    </Box>
                  </Box>

                  <Box
                    className="job-detail-image"
                    component="img"
                    alt="Job detail image not found"
                    src={jobDetail?.thumbnails_url}
                  />
                </Box>
                <Box sx={{ pt: "20px", px: "20px" }}>
                  <Box className="sync-flex-direction">
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          color: theme.palette.customColors.comet,
                        }}
                      >
                        {jobDetail?.title}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          color: alpha(
                            theme.palette.customColors.border_color,
                            0.68
                          ),
                        }}
                      >
                        {"Opened on " +
                          moment(jobDetail?.created_at).format("DD/MMM/YYYY")}
                      </Typography>
                    </Box>
                    <Box
                      className="job-detail-social-image"
                      component="img"
                      alt="Job detail image not found"
                      src={
                        jobDetail?.platforms
                          ? jobDetail?.platforms === "Instagram"
                            ? Instagram
                            : jobDetail?.platforms === "Snapchat"
                            ? Snapchat
                            : jobDetail?.platforms === "Tiktok"
                            ? Tiktok
                            : BlankImage
                          : jobDetail?.dimension === DIMENSIONS.VERTICAL
                          ? VerticalBlue
                          : jobDetail?.dimension === DIMENSIONS.HORIZONTAL
                          ? HorizontalBlue
                          : jobDetail?.dimension === DIMENSIONS.SQUARE
                          ? SquareBlue
                          : BlankImage
                      }
                    />
                  </Box>

                  <Box sx={{ my: "20px" }}>
                    <Typography
                      variant="h6"
                      color={theme.palette.customColors.comet}
                    >
                      {MARKET_PLACE.CREATE_CONTENT.JOB_DETAILS}
                    </Typography>

                    <Typography
                      variant="body2"
                      color={alpha(
                        theme.palette.customColors.border_color,
                        0.68
                      )}
                      sx={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {jobDetail?.job_description}
                    </Typography>
                  </Box>

                  <Box>{getRequirements(jobDetail?.job_requirements)}</Box>

                  <Box sx={{ my: "20px" }}>
                    <Typography
                      variant="h6"
                      color={theme.palette.customColors.comet}
                    >
                      {MARKET_PLACE.CREATE_CONTENT.BUDGET}
                    </Typography>
                    <Typography
                      variant="h5"
                      color={theme.palette.customColors.comet}
                      sx={{ mb: "8px" }}
                    >
                      {"$" + (jobDetail?.selected_budget || 0)}
                    </Typography>
                    <Typography
                      variant="caption"
                      color={alpha(
                        theme.palette.customColors.border_color,
                        0.68
                      )}
                    >
                      {"$ " +
                        (jobDetail?.budget_value.spent || 0) +
                        " already spent (" +
                        (jobDetail?.budget_value.percentage || 0) +
                        "% of total budget)"}
                    </Typography>

                    <LinearProgress
                      variant="determinate"
                      value={jobDetail?.budget_value.percentage}
                      color="success"
                      className="linear-progress"
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="detail-right-block">
                <Box className="detail-brand-img">
                  <Box
                    className="img"
                    component="img"
                    alt="Publish type image not found"
                    src={jobDetail?.brand_details.brand_logo_url}
                  />
                </Box>
                <Box>
                  <Typography variant="h6" className="brand_title">
                    {"About " + jobDetail?.brand_details.brand_name}
                  </Typography>
                  <Typography variant="body2" className="brand_desc">
                    {jobDetail?.brand_details.brand_description}
                  </Typography>
                </Box>
              </Box>
              <Box>
                {isVideoOnly ? (
                  <Earning earning={jobDetail || 0} />
                ) : (
                  <Milestone steps={jobDetail.milestone} step={step} />
                )}
                {!_.isEmpty(jobDetail?.submission) && (
                  <DetailSubmissionCard
                    cardData={jobDetail}
                    handleResubmitVideo={() => handleUploadContent(true)}
                  />
                )}
              </Box>
            </Grid>
            <ProfileCompletedDialog
              loader={uploadLoader}
              openUploadFile={openUploadFile}
              handleUploadContentClose={handleUploadContentClose}
              isProfileCompleted={isProfileCompleted}
              loadingState={loadingState}
              files={files}
              setFiles={setFiles}
              handleSubmitSubmission={handleSubmitSubmission}
              handleCompleteProfile={handleCompleteProfile}
            />

            <SubmissionUploadedDialog
              isSubmissionCompleted={isSubmissionCompleted}
              handleUploadContentClose={handleUploadContentClose}
              handleContinue={handleContinue}
            />
          </Grid>
        </>
      )}
    </Box>
  );
}
