import { Box } from "@mui/material";
import { VideoAndPost, VideoLibrary } from "assets";

export default function ContentTypeCard(props: any) {
  const { contentType } = props;

  return (
    <Box
      component="img"
      src={contentType === "collect_video_only" ? VideoLibrary : VideoAndPost}
    />
  );
}
