import axios from "axios";
import config from "../config/config";
export const API = axios.create({ baseURL: config.BASE_URL });

API.interceptors.request.use((req: any) => {
  if (localStorage.getItem("header")) {
    const header: any = localStorage.getItem("header")
    const token: any = JSON.parse(header).token;
    const uid: any = JSON.parse(header).uid;

    if (token && uid) {
      req.headers.Authorization = `Token ${token}`;
      req.headers.uid = uid;
    }

    if (req.customHeaders) {
      for (const headerName in req.customHeaders) {
        // If the header value is `null`, remove the header
        if (req.customHeaders[headerName] === null) {
          delete req.headers[headerName];
        }
      }
    }
  }

  return req;
});

export default API;