// ** MUI Imports
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box, { BoxProps } from "@mui/material/Box";

// ** Icons Imports
import CircleOutline from "mdi-material-ui/CircleOutline";

import { PricingIllustration1, PricingIllustration2 } from "assets";
import { useAppDispatch, useAppSelector } from "store/store";
import { upgradeSubscription } from "store/Thunk/subscriptionThunk";
import { useEffect, useState } from "react";
import { setStripeCheckoutURL } from "store/Slice/SubscriptionSlice";
import { ButtonLoading } from "store/Slice/AuthSlice";
import { CircularProgress } from "@mui/material";

// ** Styled Component for the wrapper of whole component
const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  minWidth: "260px",
  position: "relative",
  padding: theme.spacing(5, 5, 5),
  borderRadius: theme.shape.borderRadius,
}));

// ** Styled Component for the wrapper of all the features of a plan
const BoxFeature = styled(Box)<BoxProps>(({ theme }) => ({
  marginTop: theme.spacing(5),
  marginBottom: theme.spacing(5),
  "& > :not(:first-of-type)": {
    marginTop: theme.spacing(4),
  },
}));

const pricingPlans = {
  freePlan: {
    imgWidth: 100,
    title: "Basic",
    imgHeight: 100,
    monthlyPrice: 0,
    currentPlan: true,
    popularPlan: false,
    daysLeft: 3,
    subtitle: "A simple start for everyone",
    imgSrc: PricingIllustration1,
    amountTitle: "FREE",
    yearlyPlan: {
      perMonth: 0,
      totalAnnual: 0,
    },
    planBenefits: [
      "Unlimited ad scheduling",
      "Ad manager integrations",
      "Using and creating presets",
      "Reusing creatives and settings",
      "Advertiser account overviews",
    ],
  },
  tierPlan: {
    planBenefits: [
      "Unlimited ad scheduling",
      "Ad manager integrations",
      "Using and creating presets",
      "Reusing creatives and settings",
      "Advertiser account overviews",
    ],
  },
};

const TierPlanLayout = (props: any) => {
  // ** Props
  const { currentPlan, state, isSelected } = props;
  const [selectedValue, setSelectedValue] = useState<any>(null);
  const dispatch = useAppDispatch();
  const { stripeChekoutUrl } = useAppSelector(
    (state) => state.subscriptionSlice
  );
  const { buttonLoading } = useAppSelector((state) => state.authReducer);

  useEffect(() => {
    if (stripeChekoutUrl && !currentPlan?.customer_subscription.length) {
      // const stripeURL = `https://checkout.stripe.com/c/pay/${upgradePlan[0].sessionId}`;
      window.open(stripeChekoutUrl, "_self");
      setStripeCheckoutURL("");
    }
  }, [stripeChekoutUrl]);

  useEffect(() => {
    setSelectedValue(state?.sliderValue);
  }, [state]);

  const renderFeatures = (arr: any) => {
    return arr.map((item: string, index: number) => (
      <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
        <CircleOutline
          sx={{ mr: 2.5, fontSize: "0.875rem", color: "text.secondary" }}
        />
        <Typography variant="body2">{item}</Typography>
      </Box>
    ));
  };

  const handleUpgrade = () => {
    if (selectedValue?.plan_id) {
      const _request = {
        plan_id: selectedValue?.plan_id,
        subscription_id:
          currentPlan?.customer_subscription.length > 0
            ? currentPlan?.customer_subscription[0]?.id
            : "",
      };
      dispatch(ButtonLoading(true));
      dispatch(upgradeSubscription({ _request }));
    }
  };

  return (
    <Box className={isSelected ? "gradient-border-box" : "simple-border-box"}>
      <BoxWrapper>
        <Box sx={{ display: "flex", justifyContent: "center", mt: 10 }}>
          <img src={PricingIllustration2} width={100} height={100} />
        </Box>
        <Box sx={{ textAlign: "center" }}>
          <Typography variant="h5" sx={{ mb: 1.5 }}>
            {selectedValue?.name || ""}
          </Typography>
          <Typography variant="body2">
            For small to medium businesses
          </Typography>
          <Box sx={{ mt: 5, mb: 10, position: "relative" }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <Typography
                variant="body2"
                sx={{ mt: 1.6, alignSelf: "flex-start" }}
              >
                $
              </Typography>
              <Typography
                variant="h3"
                sx={{
                  fontWeight: 500,
                  color: "#666CFF",
                  lineHeight: 1.17,
                }}
              >
                {selectedValue?.value || 0}
              </Typography>
              <Typography
                variant="body2"
                sx={{ mb: 1.6, alignSelf: "flex-end" }}
              >
                /month
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box textAlign="start">
          <Typography
            variant="body2"
            sx={{
              fontSize: "14px",
              fontWeight: 700,
            }}
          >
            All features included
          </Typography>
          <BoxFeature>
            {renderFeatures(pricingPlans.tierPlan.planBenefits)}
          </BoxFeature>
        </Box>
        <Button
          fullWidth
          color={"primary"}
          onClick={handleUpgrade}
          disabled={buttonLoading}
          variant={isSelected ? "contained" : "outlined"}
          className={isSelected && !buttonLoading ? "gradient-btn" : ""}
        >
          {buttonLoading ? <CircularProgress size={20} /> : "Upgrade"}
        </Button>
      </BoxWrapper>
    </Box>
  );
};

export default TierPlanLayout;
