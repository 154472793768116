import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { BoxProps } from "@mui/material/Box";
import { melonFloating } from "assets";
import { useNavigate } from "react-router-dom";
import BlankLayout from "@core/layouts/BlankLayout";
import FooterIllustrationsV2 from "components/screenLayout/FooterIllustrationsV2";

const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  [theme.breakpoints.down("md")]: {
    width: "90vw",
  },
}));

const Img = styled("img")(({ theme }) => ({
  marginTop: theme.spacing(15),
  marginBottom: theme.spacing(15),
  [theme.breakpoints.down("lg")]: {
    height: 450,
    marginTop: theme.spacing(10),
    marginBottom: theme.spacing(10),
  },
  [theme.breakpoints.down("md")]: {
    height: 400,
  },
}));

export default function UnAuthorisezPage() {
  const navigate = useNavigate();
  return (
    <BlankLayout>
      <Box className="content-center">
        <Box
          className="sync-align-center"
          sx={{
            p: 5,
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <BoxWrapper>
            <Typography variant="h1" sx={{ mb: 2.5 }}>
              401
            </Typography>
            <Typography variant="h5" sx={{ mb: 2.5 }}>
              You should not be here 🔐
            </Typography>
            <Typography variant="body2">
              You don’t have permission to access this page. Contact your
            </Typography>
            <Typography variant="body2">
              admin to update your role or float around here forever.
            </Typography>
          </BoxWrapper>
          <Img
            alt="error-illustration"
            src={melonFloating}
            sx={{ height: 300, width: 300 }}
          />
          <Button
            component="a"
            variant="contained"
            sx={{ px: 5.5 }}
            onClick={() => navigate("/")}
          >
            Back to Dashboard
          </Button>
        </Box>
        <FooterIllustrationsV2 />
      </Box>
    </BlankLayout>
  );
}
