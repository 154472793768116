import { Box, CircularProgress, LinearProgress } from "@mui/material";

export default function ScreenLoading(){
    return(
        <Box sx={{height: '100%', width: '100%'}}>
          <Box sx={{ width: '100%' }}>
            <LinearProgress />
          </Box>
          <Box
          className="sync-align-justify-center"
            sx={{
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        </Box>
    )
}