// ** React Imports
import { useCallback, useEffect, useRef, useState } from "react";

// ** MUI Imports
import { Avatar, Box, InputBase, Typography } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { styled, alpha } from "@mui/material/styles";

// ** Icons
import SearchIcon from "@mui/icons-material/Search";

// ** Assets
import { ProfileIcon } from "assets";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export interface Props {
  instaAccount?: any;
  setInstaAccount?: any;
}

export default function InstaAccountsTab({
  instaAccount,
  setInstaAccount,
}: Props) {
  let inputRef: any = useRef(null);
  const [accountData, setAccountData] = useState(instaAccount);

  useEffect(() => {
    setAccountData(instaAccount);
  }, [instaAccount]);

  const columns = [
    {
      flex: 0.33,
      minWidth: 200,
      field: "instagram_account_name",
      headerName: "Name",
      renderCell: (params: any) => {
        return (
          <Box display={"flex"} flexDirection={"row"} alignItems={"center"}>
            <Avatar
              alt={ProfileIcon}
              sx={{ width: 30, height: 30, mr: 2 }}
              src={params?.row?.instagram_profile_pic || ProfileIcon}
            />
            <Typography sx={{ fontSize: "14px" }}>
              {params?.row?.instagram_account_name}
            </Typography>
          </Box>
        );
      },
    },
    {
      flex: 0.33,
      minWidth: 230,
      field: "ad_account",
      headerName: "Page Id",
      renderCell: (params: any) => {
        return (
          <div className="rowitem">
            {params?.row?.facebook_page?.page_id || "-"}
          </div>
        );
      },
    },
    {
      flex: 0.34,
      field: "instagram_account_id",
      minWidth: 80,
      headerName: "ID",
    },
  ];

  const handleSearchChange = (value: string) => {
    const accountArray: any = instaAccount.filter((element: any) => {
      if (
        element.instagram_account_name
          .toLocaleLowerCase()
          .includes(value.toLocaleLowerCase())
      ) {
        return element;
      }
    });

    setAccountData(accountArray);
  };

  // Handle ESC & shortcut keys keydown events
  const handleKeydown = useCallback((event: KeyboardEvent) => {
    // ** Shortcut keys to open searchbox (Ctrl + /)
    if (event.ctrlKey && event.which === 191) {
      const inputElement = inputRef.current;
      if (inputElement) {
        inputElement.select();
        event.preventDefault();
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [handleKeydown]);

  return (
    <Box>
      <Box sx={{ mt: 5 }}>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search (Ctrl+/)"
            inputRef={inputRef}
            inputProps={{ "aria-label": "search" }}
            onChange={(e: any) => handleSearchChange(e.target.value)}
          />
        </Search>
      </Box>

      <Box sx={{ mt: 5 }}>
        <DataGrid
          disableSelectionOnClick
          columns={columns}
          rows={accountData}
          getRowId={(row: any) => row?.id}
          sx={{ height: "100vh", display: "flex" }}
        />
      </Box>
    </Box>
  );
}
