import { useEffect, useState } from "react";
import {
  Box,
  Chip,
  ChipProps,
  Grid,
  styled,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { MARKET_PLACE } from "@core/constants/title-constants";
import { TITLE } from "@core/constants/enum";
import { useAppDispatch, useAppSelector } from "store/store";
import { downloadAll, getSubmissionHistory } from "store/Thunk/createJobThunk";
import VideoModal from "components/Dialogs/VideoModal";
import SubmissionHistoryCard from "components/SubmissionCard/SubmissionHistoryCard";
import { PixelMelonFalling } from "assets";

const MChip = styled(Chip)<ChipProps>(({ theme }: any) => ({
  minWidth: "70px",
  maxHeight: "24px",
  marginRight: theme.spacing(2),
}));

export default function SubmissionHistoryTabs(props: any) {
  const { selectedPublishType, submissionTabTitle } = props;
  const dispatch: any = useAppDispatch();
  const { submissionHistory, newSubmissionHistory } = useAppSelector(
    (state: any) => state.createJobReducer
  );

  const [historySelected, setHistorySelected] = useState(
    submissionTabTitle[0].label
  );
  const [openVideoModal, setOpenVideoModal] = useState<boolean>(false);
  const [isLoader, setIsLoader] = useState<boolean>(false);

  useEffect(() => {
    let selected;
    if (historySelected === TITLE.APPROVED) selected = "accepted";
    else selected = "declined";
    setIsLoader(false);
    dispatch(
      getSubmissionHistory({
        selected,
        dispatch,
        selectedPublishType: selectedPublishType,
      })
    )
      .then((res: any) => {
        setIsLoader(false);
      })
      .catch((err: any) => {
        setIsLoader(false);
      });
  }, [selectedPublishType, historySelected]);

  const handleClick = (item: any) => {
    setHistorySelected(item.label);
  };

  const handleVideoPlay = () => {
    setOpenVideoModal(!openVideoModal);
  };

  const handleClose = () => {
    setOpenVideoModal(false);
  };

  const handleDownloadAll = () => {
    dispatch(downloadAll({ dispatch }));
  };

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          {newSubmissionHistory && newSubmissionHistory?.length
            ? submissionTabTitle.map((item: any) => (
                <MChip
                  key={item.label}
                  className="category_chip"
                  label={item.label}
                  clickable
                  color="default"
                  onClick={() => handleClick(item)}
                  variant={
                    historySelected &&
                    historySelected.toLocaleLowerCase() ===
                      item.label.toLocaleLowerCase()
                      ? "filled"
                      : "outlined"
                  }
                />
              ))
            : ""}
        </Box>
        {submissionHistory?.length > 0 && (
          <Button
            onClick={() => handleDownloadAll()}
            variant="outlined"
            startIcon={<DownloadIcon />}
          >
            {MARKET_PLACE.REQUEST_CONTENT.DOWNLOAD_ALL}
          </Button>
        )}
      </Box>

      <Box mt={4}>
        {isLoader ? (
          <Box className="loader">
            <CircularProgress size={20} />
          </Box>
        ) : (
          <Grid container>
            {submissionHistory?.length > 0
              ? submissionHistory?.map((submissionHistory: any) => (
                  <Grid
                    item
                    md={3}
                    sm={6}
                    xs={12}
                    key={submissionHistory.title}
                  >
                    <SubmissionHistoryCard
                      onClickPlay={handleVideoPlay}
                      cardData={submissionHistory}
                    />
                  </Grid>
                ))
              : !isLoader && (
                  <Box className="no-data-found-block">
                    <Box
                      component="img"
                      src={PixelMelonFalling}
                      className="no-data-found-img"
                    />
                    <Typography variant="subtitle2">
                      {MARKET_PLACE.REQUEST_CONTENT.NO_DATA}
                    </Typography>
                  </Box>
                )}
          </Grid>
        )}
      </Box>
      <VideoModal isOpen={openVideoModal} handleClose={handleClose} />
    </Box>
  );
}
