export default function TiktokIcon({active = false,height = "30", width="30"}){

    if (active) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 30 30" fill="none">
            <rect width="30" height="30" rx="5" fill="#141414"/>
            <g clipPath="url(#clip0_916_13401)">
            <path d="M19.0978 12.4016C20.3867 13.3475 21.9667 13.904 23.6727 13.904V10.534C23.3498 10.534 23.0282 10.5001 22.7125 10.4312V13.0826C21.0065 13.0826 19.4277 12.5262 18.1376 11.5803V18.4544C18.1376 21.8934 15.4207 24.6815 12.07 24.6815C10.8199 24.6815 9.65707 24.2932 8.69214 23.6279C9.79374 24.7843 11.3313 25.5016 13.0314 25.5016C16.3833 25.5016 19.1002 22.7135 19.1002 19.2746L19.0978 12.4016ZM20.283 9.00265C19.6245 8.26358 19.1909 7.30921 19.0978 6.25323V5.82019H18.1871C18.4156 7.16164 19.198 8.30834 20.283 9.00265ZM10.8105 20.991C10.4429 20.495 10.2438 19.8891 10.2438 19.2661C10.2438 17.6936 11.4868 16.4175 13.0196 16.4175C13.3047 16.4175 13.5898 16.4623 13.862 16.5506V13.1068C13.5439 13.0621 13.2222 13.0427 12.9018 13.05V15.7305C12.6296 15.6421 12.3457 15.5974 12.0594 15.5974C10.5266 15.5974 9.28359 16.8723 9.28359 18.446C9.28359 19.5589 9.90448 20.5217 10.8105 20.991Z" fill="#FB3159"/>
            <path d="M18.1366 11.5803C19.4267 12.5262 21.0055 13.0826 22.7116 13.0826V10.4312C21.7595 10.2231 20.916 9.71268 20.2821 9.00265C19.197 8.30834 18.4159 7.16164 18.1861 5.82019H15.7944V19.2758C15.7885 20.8446 14.5479 22.1147 13.0187 22.1147C12.1174 22.1147 11.3162 21.6732 10.8096 20.991C9.90355 20.5217 9.28265 19.5589 9.28265 18.4472C9.28265 16.8735 10.5256 15.5986 12.0584 15.5986C12.3518 15.5986 12.6358 15.6458 12.9008 15.7317V13.05C9.609 13.1201 6.96045 15.8804 6.96045 19.2758C6.96045 20.9704 7.62023 22.5066 8.69002 23.6292C9.65495 24.2944 10.8178 24.6827 12.0679 24.6827C15.4186 24.6827 18.1355 21.8946 18.1355 18.4557L18.1366 11.5803Z" fill="white"/>
            <path d="M22.7114 10.4311V9.71381C21.8524 9.71502 21.0112 9.46826 20.2819 9.00136C20.9276 9.72712 21.777 10.2267 22.7114 10.4311ZM18.1871 5.82011C18.1647 5.69189 18.1482 5.56246 18.1364 5.43304V5H14.834V18.4556C14.8293 20.0244 13.5887 21.2945 12.0582 21.2945C11.6093 21.2945 11.1852 21.1856 10.8093 20.9909C11.316 21.6743 12.1171 22.1147 13.0184 22.1147C14.5477 22.1147 15.7895 20.8446 15.7942 19.2757V5.82011H18.1871ZM12.9018 13.0499V12.2866C12.6261 12.2479 12.348 12.2286 12.0688 12.2286C8.71689 12.2286 6 15.0167 6 18.4556C6 20.6111 7.06743 22.5114 8.69097 23.629C7.62118 22.5065 6.9614 20.9703 6.9614 19.2757C6.9614 15.8804 9.60877 13.12 12.9018 13.0499Z" fill="#39F4ED"/>
            </g>
            <defs>
            <clipPath id="clip0_916_13401">
            <rect width="18" height="21" fill="white" transform="translate(6 5)"/>
            </clipPath>
            </defs>
            </svg>
        );
    } else {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 30 30" fill="none">
            <rect width="30" height="30" rx="5" fill="#AFAFAF"/>
            <g clipPath="url(#clip0_916_13409)">
            <path d="M19.0978 12.4016C20.3867 13.3475 21.9667 13.904 23.6727 13.904V10.534C23.3498 10.534 23.0282 10.5001 22.7125 10.4312V13.0826C21.0065 13.0826 19.4277 12.5262 18.1376 11.5803V18.4544C18.1376 21.8934 15.4207 24.6815 12.07 24.6815C10.8199 24.6815 9.65707 24.2932 8.69214 23.6279C9.79374 24.7843 11.3313 25.5016 13.0314 25.5016C16.3833 25.5016 19.1002 22.7135 19.1002 19.2746L19.0978 12.4016ZM20.283 9.00265C19.6245 8.26358 19.1909 7.30921 19.0978 6.25323V5.82019H18.1871C18.4156 7.16164 19.198 8.30834 20.283 9.00265ZM10.8105 20.991C10.4429 20.495 10.2438 19.8891 10.2438 19.2661C10.2438 17.6936 11.4868 16.4175 13.0196 16.4175C13.3047 16.4175 13.5898 16.4623 13.862 16.5506V13.1068C13.5439 13.0621 13.2222 13.0427 12.9018 13.05V15.7305C12.6296 15.6421 12.3457 15.5974 12.0594 15.5974C10.5266 15.5974 9.28359 16.8723 9.28359 18.446C9.28359 19.5589 9.90448 20.5217 10.8105 20.991Z" fill="#484848"/>
            <path d="M18.1366 11.5803C19.4267 12.5262 21.0055 13.0826 22.7116 13.0826V10.4312C21.7595 10.2231 20.916 9.71268 20.2821 9.00265C19.197 8.30834 18.4159 7.16164 18.1861 5.82019H15.7944V19.2758C15.7885 20.8446 14.5479 22.1147 13.0187 22.1147C12.1174 22.1147 11.3162 21.6732 10.8096 20.991C9.90355 20.5217 9.28265 19.5589 9.28265 18.4472C9.28265 16.8735 10.5256 15.5986 12.0584 15.5986C12.3518 15.5986 12.6358 15.6458 12.9008 15.7317V13.05C9.609 13.1201 6.96045 15.8804 6.96045 19.2758C6.96045 20.9704 7.62023 22.5066 8.69002 23.6292C9.65495 24.2944 10.8178 24.6827 12.0679 24.6827C15.4186 24.6827 18.1355 21.8946 18.1355 18.4557L18.1366 11.5803Z" fill="white"/>
            <path d="M22.7114 10.4311V9.71381C21.8524 9.71502 21.0112 9.46826 20.2819 9.00136C20.9276 9.72712 21.777 10.2267 22.7114 10.4311ZM18.1871 5.82011C18.1647 5.69189 18.1482 5.56246 18.1364 5.43304V5H14.834V18.4556C14.8293 20.0244 13.5887 21.2945 12.0582 21.2945C11.6093 21.2945 11.1852 21.1856 10.8093 20.9909C11.316 21.6743 12.1171 22.1147 13.0184 22.1147C14.5477 22.1147 15.7895 20.8446 15.7942 19.2757V5.82011H18.1871ZM12.9018 13.0499V12.2866C12.6261 12.2479 12.348 12.2286 12.0688 12.2286C8.71689 12.2286 6 15.0167 6 18.4556C6 20.6111 7.06743 22.5114 8.69097 23.629C7.62118 22.5065 6.9614 20.9703 6.9614 19.2757C6.9614 15.8804 9.60877 13.12 12.9018 13.0499Z" fill="#D1D1D1"/>
            </g>
            <defs>
            <clipPath id="clip0_916_13409">
            <rect width="18" height="21" fill="white" transform="translate(6 5)"/>
            </clipPath>
            </defs>
            </svg>  
        );
    }
}