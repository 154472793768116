import { Box, Typography, useTheme, Chip, Grow } from "@mui/material";
import BoltIcon from "@mui/icons-material/Bolt";
import { useAppDispatch } from "store/store";
import { getVideoOtp } from "store/Thunk/createJobThunk";
import SubmissionStatusHistoryCard from "pages/marketplace/create-content/SubmissionStatusCard/SubmissionStatusHistoryCard";
import { Avatar, VideoPlay } from "assets";

export default function SubmissionHistoryCard(props: any) {
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const { cardData, onClickPlay } = props;

  const handlePlay = () => {
    let formData = new FormData();

    formData.append("video_id", cardData?.video_id);
    dispatch(getVideoOtp({ formData }));
    onClickPlay();
  };
  return (
    <Grow in={true}>
    <Box className="list-card-block">
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height={300}
        width={"100%"}
      >
        <Box
          component="img"
          src={cardData && cardData?.vdo_payload && cardData?.vdo_payload?.posters?.length > 0 && cardData?.vdo_payload?.posters[0].url}
          sx={{ objectFit: "cover", height: "100%", width: "100%" }}
        />
      </Box>
      <Box className="submission-image-wrapper">
        <Box sx={{ m: "10px" }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              mb: "4px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box component="img" src={Avatar} height="24px" width="24px" />
              <Typography
                variant="body2"
                color={theme.palette.customColors.white}
                ml="10px"
              >
                {"@" + cardData.user.first_name + "_" + cardData.user.last_name}
              </Typography>
            </Box>

            <Chip
              icon={<BoltIcon fontSize="small" />}
              label="NEW"
              color="primary"
              size="small"
            />
          </Box>
          <Typography
            sx={{ fontSize: "14px", fontWeight: "700" }}
            color={theme.palette.customColors.white}
          >
            {cardData.brand.brand_name}
          </Typography>
        </Box>
        <Box
          onClick={() => handlePlay()}
          component="img"
          src={VideoPlay}
          height="30px"
          width="30px"
          alignSelf="center"
        />

        <SubmissionStatusHistoryCard
          status={cardData.approval_status[0].approval_status}
          title={cardData.approval_status[0].approval_status}
          onClickDownload={cardData?.submission_video_url?.redirect}
        />
      </Box>
    </Box>
    </Grow>
  );
}
