// ** MUI Imports
import {
  Box,
  Typography,
  Step,
  StepLabel,
  Stepper,
  useTheme,
  Grid,
} from "@mui/material";

// ** Custom Components
import StepperWrapper from "@core/styles/mui/stepper";
import { MARKET_PLACE } from "@core/constants/title-constants";
import StepperCustomDot from "./StepperCustomDot";

// ** Assets
import { Dollar } from "assets";

export default function Milestone(props: any) {
  const theme = useTheme();
  const { steps, step } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Box className="post-type-block">
          <Box className="jobmilestone-block">
            <Box className="milestone-img" component="img" src={Dollar} />
            <Typography
              variant="h6"
              color={theme.palette.customColors.comet}
              sx={{ ml: "10px" }}
            >
              {MARKET_PLACE.CREATE_CONTENT.JOB_MILESTONES}
            </Typography>
          </Box>
          <Box>
            <StepperWrapper jobMilestone>
              <Stepper activeStep={step} alternativeLabel>
                <Step>
                  <StepLabel StepIconComponent={StepperCustomDot}>
                    Video Approved
                  </StepLabel>
                </Step>
                {steps.map((milestone: any) => (
                  <Step key={milestone.view_count}>
                    <StepLabel StepIconComponent={StepperCustomDot}>
                      {milestone.view_count + " views"}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </StepperWrapper>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
}
