// ** React
import { useContext, useEffect, useState } from "react";

// ** MUI Imports
import {
  Box,
  Button,
  Card,
  Chip,
  Divider,
  FormControl,
  Grid,
  Grow,
  TextField,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";

// ** Redux
import { useAppDispatch, useAppSelector } from "store/store";
import {
  deleteCreativeUpload,
  postCreativeUpload,
  reviewPageAdsetCalculation,
  updateCreativeUpload,
} from "store/Thunk/autoSchedulerThunk";
import { AutoschudlerContext } from "context/autoschedulerContex";

// ** Custom Component
import { PLATFORMS } from "@core/constants/enum";
import DropzoneWrapper from "@core/styles/libs/react-dropzone";
import { MEDIA_PLANNER } from "@core/constants/title-constants";
import FileUploaderRestrictions from "pages/auto-scheduler/FileUpload/FileUploaderRestrictions";

// ** Mui Icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import Validations from "helper/validations";

interface Props {
  onBackwordButtonClick?(): void;
  onClearDraftClick?(): void;
  onBatchNameChange?(item: any): any;
  schedulerCreatives?: any;
  setSchedulerCreatives?: any;
  onAutodraftData?: any;
  onReviewPageData?: any;
}

interface CreativeProp {
  name: string;
  size: any;
  percentage: number;
  isError: boolean;
  isSucess: boolean;
}

interface updateProp {
  id?: number;
  uploadSesid?: any;
  heading?: string;
  landingpage_url?: string;
  caption?: string;
}

export default function CreativesStep({
  onBackwordButtonClick,
  onClearDraftClick,
  onBatchNameChange,
  schedulerCreatives,
  setSchedulerCreatives,
  onAutodraftData,
  onReviewPageData,
}: Props) {
  const dispatch: any = useAppDispatch();
  let autoSchedulerTitle = MEDIA_PLANNER.auto_scheduler;

  const {
    creativesProgress,
    creativesData,
    uploadsesId,
    schedulerDraftData,
    reuseBatchCreative,
  } = useAppSelector((state: any) => state.autoSchedulerSlice);

  const {
    campaign,
    state,
    facebook,
    tiktok,
    snapchat,
    autoLinkfire,
    availablePlatformTitle,
  }: any = useContext(AutoschudlerContext);

  const [files, setFiles] = useState<File[]>([]);
  const [creativesCount, setCreativesCount] = useState<any>([]);
  const [isLandingpageUrl, setIsLandingpageUrl] = useState(false);
  const [creatives, setCreatives] = useState<CreativeProp[]>([]);
  const [updateCreative, setUpdateCreative] = useState<updateProp[]>([]);
  const [isShowApplyToAll, setIsShowApplyToAll] = useState(false);
  const [landingUrlError, setLandingUrlError] = useState<any[]>([]);
  const [isBackdrop, setIsBackdrop] = useState(false);
  const [isClearDraftEnabled, setIsClearDraftEnabled] = useState(false);

  useEffect(() => {
    if (
      creativesData &&
      creativesData.length &&
      creativesData[0]?.uploaded_creative
    ) {
      const temp = creativesData[0]?.uploaded_creative.map(
        (val: any, index: any) => {
          let item = schedulerCreatives?.creativesArray[index] || {};
          if (item && Object.keys(item).length) {
            return { ...val, landingpage_url: item.landingpage_url };
          }
          return val;
        }
      );

      setSchedulerCreatives({
        ...schedulerCreatives,
        creativesArray: temp,
      });

      let creative = {
        ...schedulerCreatives,
        creativesArray: temp,
      };
      onAutodraftData(creative);
    }
  }, [creativesData]);

  useEffect(() => {
    if (!schedulerDraftData?.id) {
      setIsClearDraftEnabled(true);
    }
  }, [schedulerDraftData]);

  useEffect(() => {
    var array: any = {
      Facebook: [],
      Instagram: [],
      Tiktok: [],
      Snap: [],
    };

    setCreativesCount(array);
  }, [creativesData[0]?.creative_placement_type_counting]);

  useEffect(() => {
    var create: any = creatives;
    if (creatives?.length) {
      create[creatives?.length - 1].percentage = creativesProgress;
      setCreatives(creatives);
    }
  }, [creativesProgress]);

  useEffect(() => {
    if (reuseBatchCreative?.length) {
      setSchedulerCreatives({
        ...schedulerCreatives,
        updateCreative: reuseBatchCreative,
        creativesArray: reuseBatchCreative,
      });
    }
  }, [reuseBatchCreative]);

  useEffect(() => {
    setIsLandingpageUrl(false);
    const temp = schedulerCreatives?.creativesArray?.map((item: any) => {
      if (!item?.landingpage_url) {
        setIsLandingpageUrl(true);
      }
    });
  }, [schedulerCreatives?.creativesArray]);

  useEffect(() => {
    return () => {
      setCreatives([]);
      setUpdateCreative([]);
    };
  }, []);

  const getRequiredSize = (type: any) => {
    if (["png", "jpg", "jpeg", "gif"].includes(type)) {
      return "16 MB";
    } else if (["mp4"].includes(type)) {
      return "512 MB";
    }
  };

  const handledAcceptedFiles = (files: any) => {
    setFiles(files);
    setLandingUrlError([]);
    if (files) {
      setSchedulerCreatives({ ...schedulerCreatives, files: files });
    }
    var temp = uploadsesId;
    var creative: any = creatives;
    for (let i = creative.length; i < files.length; i++) {
      let fileType = files[i].type.split("/");
      if (
        (["png", "jpg", "jpeg", "gif"].includes(fileType[1]) &&
          files[i].size > 16000000) ||
        (["mp4"].includes(fileType[1]) && files[i].size > 512000000)
      ) {
        creative.push({
          name: files[i]?.name,
          size: files[i]?.size,
          percentage: 0,
          isError: true,
          errorMessage: `${files[i]?.name} is larger than ${getRequiredSize(
            fileType[1]
          )}`,
          isSucess: false,
          isWarning: false,
          warningMessage: "",
        });
      } else {
        creative.push({
          name: files[i]?.name,
          size: files[i]?.size,
          percentage: 0,
          isError: false,
          errorMessage: "",
          isSucess: false,
          isWarning: false,
          warningMessage: "",
        });
        var data: any = new FormData();
        const blobFile = new Blob([files[i]], { type: files[i]?.type });
        data.append("creatives", blobFile, files[i]?.name);
        data?.append("schedule_platforms", availablePlatformTitle?.toString());
        data?.append("uploadSesid", temp);
        data?.append("facebook_profile_id", 1);

        if (temp) {
          dispatch(postCreativeUpload({ data })).then((res: any) => {
            if (res?.payload?.status == 201) {
              if (res?.payload?.data.data[0].warning_message) {
                creative[i].isWarning = true;
                creative[i].warningMessage =
                  res?.payload?.data.data[0].warning_message;
              } else {
                creative[i].isSucess = true;
              }
            }
            if (res?.payload?.response?.status == 406) {
              creative[i].isError = true;
              creative[i].errorMessage = res?.payload?.response?.data?.message;
            }
            if (res?.payload?.code == "ERR_NETWORK") {
              creative[i].isError = true;
              creative[i].errorMessage = res?.payload?.message;
            }
            setCreatives([...creative]);
          });
        }
      }
    }
  };

  const handleValueChange = (
    id: any,
    uploadsesid: any,
    value: any,
    platform: string
  ) => {
    setIsShowApplyToAll(true);
    var update: any = schedulerCreatives?.creativesArray;
    const newArray = [...schedulerCreatives?.creativesArray];
    const index: any = newArray?.findIndex((element: any) => element?.id == id);
    newArray[index] = { ...newArray[index], landingpage_url: value };

    setUpdateCreative(newArray);
    if (newArray?.length) {
      setSchedulerCreatives({
        ...schedulerCreatives,
        updateCreative: newArray,
        creativesArray: newArray,
      });
    }
  };

  const handleApplyToAll = () => {
    const newArray = [...schedulerCreatives?.creativesArray].map(
      (item: any) => ({
        ...item,
        landingpage_url: updateCreative?.[0]?.landingpage_url,
      })
    );

    setUpdateCreative(newArray);
    if (newArray?.length) {
      setSchedulerCreatives({
        ...schedulerCreatives,
        updateCreative: newArray,
        creativesArray: newArray,
      });
    }
  };

  const handleRemoveFile = (file: any) => {
    const data = {
      creative_name: file?.name ? file?.name : file?.filename,
      uploadSesid: uploadsesId,
      schedule_platforms: availablePlatformTitle?.toString(),
    };
    setIsBackdrop(true);

    dispatch(deleteCreativeUpload({ data })).then((res: any) => {
      if (res?.payload?.status == 201) {
        setIsBackdrop(false);
        var tempFiles = schedulerCreatives?.creativesArray;
        const filteredData = tempFiles?.filter(
          (i: any) => i?.filename !== (file?.name ? file?.name : file?.filename)
        );

        const temp = creatives;
        const tempdata = temp.filter(
          (i: any) => i.name !== (file?.name ? file?.name : file?.filename)
        );
        setCreatives([...tempdata]);

        // const uploadedFiles = schedulerCreatives?.files;
        const filtered = files.filter(
          (i: any) => i.name !== (file?.name ? file?.name : file?.filename)
        );
        setFiles(filtered);
        if (filteredData && filtered) {
          // setSchedulerCreatives({
          //   ...schedulerCreatives,
          //   creativesArray: filteredData,
          //   files: [...filtered]
          // });
          const data: any = {
            ...schedulerCreatives,
            creativesArray: filteredData,
            files: [...filtered],
          };
          onAutodraftData(data);
        }
      } else {
        setIsBackdrop(false);
      }
    });
  };

  const handleForwardButtonClick = () => {
    const data = {
      creative_data: updateCreative,
    };

    let error: any[] = !autoLinkfire
      ? Validations.validateCreativeLandingUrl(data)
      : [];

    const haveError = error.filter((val) => val);

    if (!haveError.length) {
      dispatch(updateCreativeUpload({ data })).then((res: any) => {});
      const countriesString = campaign?.countries
        .map(function (val: any) {
          return val.Code;
        })
        .join(",");
      var placement_type = "";
      if (facebook?.instagramPlacement && facebook?.facebookPlacement) {
        placement_type =
          facebook?.facebookPlacement?.join(",") +
          "," +
          facebook?.instagramPlacement?.join(",");
      } else if (facebook?.instagramPlacement && !facebook?.facebookPlacement) {
        placement_type = facebook?.instagramPlacement?.join(",");
      } else if (!facebook?.instagramPlacement && facebook?.facebookPlacement) {
        placement_type = facebook?.facebookPlacement?.join(",");
      }
      const _request = {
        uploadsesid: uploadsesId,
        countries: countriesString,
        bundle_countries: campaign?.isBundleCountries,
        daily_budget: campaign?.budget,
        Facebook: availablePlatformTitle?.includes(PLATFORMS.FACEBOOK)
          ? {
              max_nr_creatives: facebook?.maximumCreative,
              placements: facebook?.advantage,
              placement_type:
                !facebook?.advantage && placement_type ? placement_type : "",
            }
          : {},
        Tiktok: availablePlatformTitle?.includes(PLATFORMS.TIKTOK)
          ? {
              max_nr_creatives: tiktok?.maximumCreative,
              placements: true,
              placement_type: "",
            }
          : {},
        Snap: availablePlatformTitle?.includes(PLATFORMS.SNAP)
          ? {
              max_nr_creatives: snapchat?.maximumCreative,
              placements: true,
              placement_type: "",
            }
          : {},
      };
      dispatch(reviewPageAdsetCalculation({ _request })).then((res: any) => {
        onReviewPageData(res);
      });
    }
    setLandingUrlError(error);
  };

  const handleButton = () => {
    return (
      <>
        <Divider sx={{ mt: 8 }} />
        <Box
          className="footer-button"
          sx={{ justifyContent: { xs: "center", sm: "space-between" } }}
        >
          <Box
            sx={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
            gap={2}
          >
            <Button
              size="large"
              type="submit"
              variant="outlined"
              color="secondary"
              onClick={onBackwordButtonClick}
              startIcon={<ArrowBackIcon />}
            >
              Settings
            </Button>
            <Button
              size="large"
              type="submit"
              variant="contained"
              onClick={() => handleForwardButtonClick()}
              disabled={
                !schedulerCreatives?.creativesArray?.length ||
                (!autoLinkfire && isLandingpageUrl)
              }
              endIcon={<ArrowForwardIcon />}
            >
              Review
            </Button>
          </Box>
          <Button
            size="large"
            variant="outlined"
            disabled={isClearDraftEnabled}
            onClick={onClearDraftClick}
            sx={{ mt: { xs: 3, sm: 0 } }}
            startIcon={<DeleteIcon />}
          >
            {autoSchedulerTitle.CLEAR_DRAFT}
          </Button>
        </Box>
      </>
    );
  };

  return (
    <Box>
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme: any) => theme.zIndex.drawer + 1,
        }}
        open={isBackdrop}
      >
        <CircularProgress color="primary" />
      </Backdrop>
      <Box sx={{ padding: 5 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {autoSchedulerTitle.CREATIVES}
        </Typography>
        <Typography variant="subtitle2" sx={{ mb: 2 }}>
          {autoSchedulerTitle.DRAG_DROP}
        </Typography>
      </Box>
      <Box>
        <DropzoneWrapper>
          <Grid container spacing={6} className="match-height">
            <Grid item xs={12}>
              <FileUploaderRestrictions
                files={files}
                setFiles={setFiles}
                handledAcceptedFiles={handledAcceptedFiles}
                handleRemoveFile={handleRemoveFile}
                creatives={creatives}
                creativesArray={schedulerCreatives?.creativesArray}
                handleValueChange={handleValueChange}
                updateCreative={updateCreative}
                landingUrlError={landingUrlError}
                handleButton={handleButton}
                handleApplyToAll={handleApplyToAll}
                showApplyToAll={isShowApplyToAll}
                platform={availablePlatformTitle}
              />
            </Grid>
          </Grid>
        </DropzoneWrapper>
        {schedulerCreatives?.creativesArray.length > 0 && (
          <Grow in={true}>
            <Card sx={{ padding: 10 }}>
              <Box className="align-justify-item-center">
                <Chip
                  label={autoSchedulerTitle.BATCH_SETTINGS}
                  sx={{ flexGrow: 0 }}
                />
                <Divider sx={{ flexGrow: 1 }} />
              </Box>
              <Box>
                <Box
                  sx={{ mt: 5, width: { md: "40%", sm: "60%", xs: "100%" } }}
                >
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <TextField
                      fullWidth
                      placeholder={autoSchedulerTitle.PLACEHOLDER}
                      value={state.batchName}
                      label={autoSchedulerTitle.BATCH_NAME}
                      onChange={onBatchNameChange}
                    />
                  </FormControl>
                </Box>
                {handleButton()}
              </Box>
            </Card>
          </Grow>
        )}
      </Box>
    </Box>
  );
}
