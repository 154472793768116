// ** React Imports
import React, { useEffect, useState } from "react";

// ** MUI Imports
import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  LinearProgress,
  Typography,
  Drawer,
  Slide,
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";

// ** Third Party Library
import { useLocation, useNavigate } from "react-router-dom";

// ** Redux
import { useAppDispatch } from "store/store";
import {
  setFBAPIProgress,
  setSCAPIProgress,
  setTTAPIProgress,
} from "store/Slice/PlatformSlice";
import {
  connectWithFacebook,
  connectWithGoogle,
  connectWithSnapchat,
  connectWithTiktok,
  getPlatformCount,
} from "store/Thunk/platformConnectionThunk";

// ** Custom Components
import config from "config/config";
import { route_info } from "navigation/route";
import ViewDetailsDrawer from "pages/platform/Drawer/ViewDetailsDrawer";
import SpootifyLoginDialog from "pages/platform/Dialogs/SpootifyLoginDialog";
import LinkFireLoginDialog from "pages/platform/Dialogs/LinkFireLoginDialog";
import ScrapperDrawer from "pages/platform/Drawer/ScrapperDrawer";
import ViewDetailsFacebookDrawer from "pages/platform/Drawer/ViewDetailsFacebookDrawer";
import ViewDetailsTiktokDrawer from "pages/platform/Drawer/ViewDetailsTiktokDrawer";
import ViewDetailsGoogleDrawer from "pages/platform/Drawer/ViewDetailsGoogleDrawer";
import LinkfireScrapperDrawer from "pages/platform/Drawer/LinkfireScrapperDrawer";
import LinkFireGeneratorLoginDialog from "pages/platform/Dialogs/LinkFireGeneratorLoginDialog";
import LinkfireGeneratorDrawer from "pages/platform/Drawer/LinkfireGeneratorDrawer";
import LinkFireGeneratorBoardDialog from "pages/platform/Dialogs/LinkFireGeneratorBoardDialog";

// ** Icons
import FacebookIcon from "icon/facebook";
import TiktokIcon from "icon/tiktok";
import SnapchatIcon from "icon/snapchat";
import GoogleIcon from "icon/google";
import SpootifyIcon from "icon/spootify";
import LinkfireIcon from "icon/linkfire";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function iconTagName(iconName: string) {
  if (iconName == "facebook") return FacebookIcon;
  else if (iconName == "tiktok") return TiktokIcon;
  else if (iconName == "snapchat") return SnapchatIcon;
  else if (iconName == "google") return GoogleIcon;
  else if (iconName == "spootify") return SpootifyIcon;
  else if (iconName == "linkfire") return LinkfireIcon;
}

export default function PlatformPage() {
  const location = useLocation();
  const dispatch: any = useAppDispatch();
  const navigate: any = useNavigate();
  const code: any = new URLSearchParams(location.search).get("code");
  const platform: any = new URLSearchParams(location.search).get("state");

  const [loading, setLoading] = useState(true);
  const [apiSource, setApiSource] = useState<any>("");
  const [sidebarDrawer, setSidebarDrawer] = useState({
    open: false,
    drawer: "",
    title: "",
  });
  const [dialogOpen, setDialogOpen] = useState({ open: false, dialog: "" });
  const [params, setParams] = useState({ code: "", platform: "" });
  const [connect, setConnect] = useState({
    facebook: "CONNECT",
    tiktok: "CONNECT",
    snapchat: "CONNECT",
    google: "CONNECT",
  });
  const [isBackdrop, setIsBackdrop] = useState(false);
  const [connectionCount, setConnectionCount] = useState({
    facebook: 0,
    tiktok: 0,
    snapchat: 0,
    google: 0,
    "spotify-scraper": 0,
    "linkfire-scraper": 0,
    linkfire: 0,
  });

  const SocialArr = [
    {
      icon: "facebook",
      title: "Meta Ads",
      status: connect.facebook,
      redirect_path: config.FACEBOOK_CONNECTION_URL,
      count: connectionCount.facebook,
    },
    {
      icon: "tiktok",
      title: "Tiktok Ads",
      status: connect.tiktok,
      redirect_path: config.TIKTOK_CONNECTION_URL,
      count: connectionCount.tiktok,
    },
    {
      icon: "snapchat",
      title: "Snapchat Ads",
      status: connect.snapchat,
      redirect_path: config.SNAP_CONNECTION_URL,
      count: connectionCount.snapchat,
    },
    // {
    //   icon: "google",
    //   title: "Google Ads",
    //   status: connect.google,
    //   redirect_path: "",
    //   // redirect_path: config.GOOGLE_CONNECTION_URL,
    //   count: connectionCount.google,
    // },
  ];

  const OtherArr = [
    {
      icon: "spootify",
      title: "Spotify",
      status: "CONNECT",
      redirect_path: "",
      count: connectionCount["spotify-scraper"],
    },
    {
      icon: "linkfire",
      title: "Linkfire Data Scraper",
      status: "CONNECT",
      redirect_path: "",
      count: connectionCount["linkfire-scraper"],
    },
    {
      icon: "linkfire",
      title: "Linkfire Generator",
      status: "VIEW DETAILS",
      redirect_path: "",
      count: connectionCount.linkfire,
    },
  ];

  useEffect(() => {
    setParams({ code: code, platform: platform });
    if (!code && !platform) {
      getPlatform();
    }
  }, []);

  useEffect(() => {
    if (params.platform == "snapchat" && params.code) {
      setLoading(true);
      const code: any = params.code;
      dispatch(connectWithSnapchat({ code, navigate }))
        .then((res: any) => {
          handleTimeOut("Snapchat Ads");
          navigate(route_info.market_planner.platform);
          getPlatform();
        })
        .catch((err: any) => {
          navigate("route_info.market_planner.platform");
        });
    } else if (params.platform == "facebook" && params.code) {
      setLoading(true);
      const code: any = params.code;
      dispatch(connectWithFacebook({ code, navigate }))
        .then((res: any) => {
          handleTimeOut("Meta Ads");
          navigate(route_info.market_planner.platform);
          getPlatform();
        })
        .catch((err: any) => {
          navigate(route_info.market_planner.platform);
        });
    } else if (params.platform == "tiktok" && params.code) {
      setLoading(true);
      const code: any = params.code;
      dispatch(connectWithTiktok({ code, navigate }))
        .then((res: any) => {
          handleTimeOut("Tiktok Ads");
          navigate(route_info.market_planner.platform);
          getPlatform();
        })
        .catch((err: any) => {
          navigate(route_info.market_planner.platform);
        });
    } else if (params.platform == "google" && params.code) {
      setLoading(true);
      const code: any = params.code;
      dispatch(connectWithGoogle({ code, navigate }))
        .then((res: any) => {
          handleTimeOut("Google Ads");
          // navigate(route_info.market_planner.platform);
          getPlatform();
        })
        .catch((err: any) => {
          navigate(route_info.market_planner.platform);
        });
    }
  }, [params.code, params.platform]);

  const getPlatform = () => {
    dispatch(getPlatformCount({ navigate })).then((response: any) => {
      setLoading(false);
      if (response.payload.data.data[0]) {
        setConnectionCount(response.payload.data.data[0]);
      }
    });
  };

  const handleTimeOut = (title: string) => {
    setTimeout(() => {
      setSidebarDrawer({
        open: true,
        drawer: "AdAccount",
        title: title,
      });
    }, 1000);
  };

  const handleCloseDrawer = () => {
    apiSource.cancel("drawer closed");
    setSidebarDrawer({ open: false, drawer: "", title: "" });
    dispatch(
      setSCAPIProgress({
        loading: false,
        success: false,
        message: "",
      })
    );
    dispatch(
      setFBAPIProgress({
        loading: false,
        success: false,
        message: "",
      })
    );
    dispatch(
      setTTAPIProgress({
        loading: false,
        success: false,
        message: "",
      })
    );
  };

  const handleRedirectSocialUrl = (redirectUrl: string) => {
    window.open(redirectUrl, "_self");
  };

  return (
    <>
      {loading ? (
        <>
          <Box sx={{ width: "100%", height: "0.5%" }}>
            <LinearProgress />
          </Box>
          <Box
            className="sync-align-justify-center"
            sx={{
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <Box>
          <Backdrop
            sx={{
              color: "#fff",
              zIndex: (theme: any) => theme.zIndex.drawer + 1,
            }}
            open={isBackdrop}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Box>
            <Typography variant="h6" sx={{ mb: 3 }}>
              Social Platforms
            </Typography>
            <Grid container spacing={5}>
              {SocialArr &&
                SocialArr.map((item, index: number) => {
                  let TagName: any = iconTagName(item.icon);
                  if (item.count == 0) {
                    return (
                      <Grid item xs={8} sm={4} md={3} key={index}>
                        <Box
                          key={index}
                          sx={{
                            borderStyle: "dashed",
                            borderWidth: 2,
                            borderColor: "#D6D6D6",
                            borderRadius: 1,
                            padding: 5,
                            backgroundColor: "white",
                            cursor: "auto",
                            maxWidth: "277px",
                          }}
                        >
                          <TagName active={false} />
                          <Typography variant="h6">{item.title}</Typography>
                          <Button
                            variant="text"
                            sx={{
                              p: "0px",
                              mt: 2,
                              color: "#FF4D49",
                              "&:hover": {
                                backgroundColor: "#fff !important",
                                color: "#aaa !important",
                              },
                            }}
                            disabled={
                              item.title === "Google Ads" ? true : false
                            }
                            onClick={() =>
                              item?.redirect_path
                                ? handleRedirectSocialUrl(item?.redirect_path)
                                : ""
                            }
                          >
                            CONNECT
                          </Button>
                        </Box>
                      </Grid>
                    );
                  } else {
                    return (
                      <Grid item xs={8} sm={4} md={3}>
                        <Card sx={{ height: "100%", maxWidth: "277px" }}>
                          <Box
                            sx={{
                              padding: 5,
                            }}
                          >
                            <TagName active={true} />
                            <Typography variant="h6">{item.title}</Typography>
                            <Button
                              disabled={
                                item.title === "Google Ads" ? true : false
                              }
                              variant="text"
                              sx={{ padding: "0px", mt: 2 }}
                              onClick={() =>
                                setSidebarDrawer({
                                  open: true,
                                  drawer: "AdAccount",
                                  title: item.title,
                                })
                              }
                            >
                              VIEW DETAILS
                            </Button>
                          </Box>
                        </Card>
                      </Grid>
                    );
                  }
                })}
            </Grid>
            {/* <Typography variant="h6" sx={{ mb: 3, mt: 6 }}>
              Other Integrations
            </Typography>
            <Grid container spacing={5}>
              {OtherArr &&
                OtherArr.map((item, index: number) => {
                  let TagName: any = iconTagName(item.icon);
                  if (item.count == 0) {
                    return (
                      <Grid item xs={6} sm={4} md={3} key={index}>
                        <Box
                          key={index}
                          sx={{
                            borderStyle: "dashed",
                            borderWidth: 2,
                            borderColor: "#D6D6D6",
                            borderRadius: 1,
                            padding: 5,
                            backgroundColor: "white",
                            cursor: "auto",
                          }}
                        >
                          <TagName active={false} />
                          <Typography variant="h6">{item.title}</Typography>
                          <Button
                            variant="text"
                            disabled
                            sx={{
                              padding: "0px",
                              mt: 2,
                              color: "#FF4D49",
                              "&:hover": {
                                backgroundColor: "#fff !important",
                                color: "#aaa !important",
                              },
                            }}
                            onClick={() =>
                              setDialogOpen({ open: true, dialog: item.title })
                            }
                          >
                            CONNECT
                          </Button>
                        </Box>
                      </Grid>
                    );
                  } else {
                    return (
                      <Grid item xs={6} sm={4} md={3}>
                        <Card sx={{ height: "100%" }}>
                          <Box
                            sx={{
                              padding: 5,
                            }}
                          >
                            <TagName active={true} />
                            <Typography variant="h6">{item.title}</Typography>
                            <Button
                              disabled
                              variant="text"
                              sx={{ padding: "0px", mt: 2 }}
                              onClick={() =>
                                setSidebarDrawer({
                                  open: true,
                                  drawer: "Scrapper",
                                  title: item.title,
                                })
                              }
                            >
                              VIEW DETAILS
                            </Button>
                          </Box>
                        </Card>
                      </Grid>
                    );
                  }
                })}
            </Grid> */}
          </Box>

          <Drawer
            anchor="right"
            open={sidebarDrawer.open}
            onClose={handleCloseDrawer}
            ModalProps={{ keepMounted: true }}
            sx={{
              "& .MuiDrawer-paper": {
                width: { md: ["60%"], xs: ["100%"] },
              },
            }}
          >
            {sidebarDrawer.drawer == "AdAccount" &&
              sidebarDrawer.title == "Snapchat Ads" && (
                <ViewDetailsDrawer
                  setSidebarDrawer={setSidebarDrawer}
                  sidebarDrawer={sidebarDrawer}
                  onGetPlatform={getPlatform}
                  setIsBackdrop={setIsBackdrop}
                  apiSource={apiSource}
                  setApiSource={setApiSource}
                />
              )}

            {sidebarDrawer.drawer == "AdAccount" &&
              sidebarDrawer.title == "Meta Ads" && (
                <ViewDetailsFacebookDrawer
                  setSidebarDrawer={setSidebarDrawer}
                  sidebarDrawer={sidebarDrawer}
                  onGetPlatform={getPlatform}
                  setIsBackdrop={setIsBackdrop}
                  apiSource={apiSource}
                  setApiSource={setApiSource}
                />
              )}

            {sidebarDrawer.drawer == "AdAccount" &&
              sidebarDrawer.title == "Tiktok Ads" && (
                <ViewDetailsTiktokDrawer
                  setSidebarDrawer={setSidebarDrawer}
                  sidebarDrawer={sidebarDrawer}
                  onGetPlatform={getPlatform}
                  setIsBackdrop={setIsBackdrop}
                  apiSource={apiSource}
                  setApiSource={setApiSource}
                />
              )}
            {sidebarDrawer.drawer == "AdAccount" &&
              sidebarDrawer.title == "Google Ads" && (
                <ViewDetailsGoogleDrawer
                  setSidebarDrawer={setSidebarDrawer}
                  sidebarDrawer={sidebarDrawer}
                  onGetPlatform={getPlatform}
                />
              )}
            {sidebarDrawer.title == "Spotify" && (
              <ScrapperDrawer
                setSidebarDrawer={setSidebarDrawer}
                title={sidebarDrawer.title}
                onSpotifyLogin={getPlatform}
                setLoading={setLoading}
                setIsBackdrop={setIsBackdrop}
              />
            )}
            {sidebarDrawer.title == "Linkfire Data Scraper" && (
              <LinkfireScrapperDrawer
                setSidebarDrawer={setSidebarDrawer}
                title={sidebarDrawer.title}
                onSpotifyLogin={getPlatform}
                setLoading={setLoading}
                setIsBackdrop={setIsBackdrop}
              />
            )}

            {sidebarDrawer.title == "Linkfire Generator" && (
              <LinkfireGeneratorDrawer
                setSidebarDrawer={setSidebarDrawer}
                title={sidebarDrawer.title}
                onSpotifyLogin={getPlatform}
                setLoading={setLoading}
              />
            )}
          </Drawer>

          <Dialog
            fullWidth
            open={dialogOpen.open}
            TransitionComponent={Transition}
            maxWidth="sm"
            scroll="body"
            onClose={() => setDialogOpen({ open: false, dialog: "" })}
          >
            <DialogContent
              sx={{
                px: { xs: 8, sm: 15 },
                py: { xs: 8, sm: 12.5 },
                position: "relative",
              }}
            >
              {dialogOpen.dialog == "Spotify" && (
                <SpootifyLoginDialog
                  setDialogOpen={setDialogOpen}
                  onSpotifyLogin={getPlatform}
                  setLoading={setLoading}
                  setSidebarDrawer={setSidebarDrawer}
                />
              )}
              {dialogOpen.dialog == "Linkfire Data Scraper" && (
                <LinkFireLoginDialog
                  setDialogOpen={setDialogOpen}
                  onSpotifyLogin={getPlatform}
                  setLoading={setLoading}
                  setSidebarDrawer={setSidebarDrawer}
                />
              )}
              {dialogOpen.dialog == "Linkfire Generator" && (
                <LinkFireGeneratorLoginDialog
                  setDialogOpen={setDialogOpen}
                  onSpotifyLogin={getPlatform}
                  setSidebarDrawer={setSidebarDrawer}
                />
              )}
              {dialogOpen.dialog == "Linkfire Board" && (
                <LinkFireGeneratorBoardDialog
                  setDialogOpen={setDialogOpen}
                  onSpotifyLogin={getPlatform}
                  setLoading={setLoading}
                  setSidebarDrawer={setSidebarDrawer}
                />
              )}
            </DialogContent>
          </Dialog>
        </Box>
      )}
    </>
  );
}
