// ** React Imports
import { useEffect, useRef, useState } from "react";

// ** MUI Imports
import {
  Box,
  Button,
  TextField,
  Typography,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Grow,
  Grid,
} from "@mui/material";
import LinearProgress, {
  LinearProgressProps,
} from "@mui/material/LinearProgress";

// ** Third Party Library
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";

// ** Redux
import { useAppDispatch, useAppSelector } from "store/store";
import {
  getCreatorInfo,
  saveCreatorInfo,
  updateCreatorInfo,
  connectSnapChat,
  connectFacebook,
  connectTiktok,
} from "store/Thunk/myCreatorInfoThunk";
import { setPresignedUrlAPI } from "store/Thunk/createJobThunk";

// ** Custom Component
import config from "config/config";
import { route_info } from "navigation/route";
import { MARKET_PLACE } from "@core/constants/title-constants";
import { PLATFORMS } from "@core/constants/enum";
import InstagramConnectionDialog from "pages/marketplace/create-content/Dialog/InstagramConnection";

// ** Icons
import IconButton from "@mui/material/IconButton";
import CancelIcon from "@mui/icons-material/Cancel";
import CropOriginalIcon from "@mui/icons-material/CropOriginal";

// ** Assets
import {
  Instagram,
  Snapchat,
  Tiktok,
  Paypal,
  InstagramGrey,
  SnapchatGrey,
  TiktokGrey,
} from "assets";

// ** Custom Styles
import "./styles.scss";

const LinearProgressWithLabel = ({
  value,
  isVisible,
}: LinearProgressProps & { value: number; isVisible: boolean }) => {
  return (
    <Box className="linear-pogress-block">
      {isVisible && (
        <>
          <LinearProgress
            sx={{
              transition: "all .8s",
            }}
            variant="determinate"
            value={value}
            color="success"
          />
          <Typography sx={{ ml: "8px" }} variant="body2" color="text.secondary">
            {`${Math.round(value || 0)}% complete`}
          </Typography>
        </>
      )}
    </Box>
  );
};

export default function MyCreatorInfo() {
  const dispatch: any = useAppDispatch();
  const navigate: any = useNavigate();
  const location = useLocation();
  const inputFile = useRef<any>(null);
  const code: any = new URLSearchParams(location.search).get("code");
  const platform: any = new URLSearchParams(location.search).get("state");
  const error_code: any = new URLSearchParams(location.search).get(
    "error_code"
  );

  const { loadingState, creatorInfo, objectKey } = useAppSelector(
    (state: any) => state.createJobReducer
  );

  const [isButtonDisable, setIsButtonDisable] = useState<boolean>(true);
  const [isPaymentGateway, setIsPaymentGateway] = useState<boolean>(true);
  const [isInstagramConnected, setIsInstagramConnected] =
    useState<boolean>(false);
  const [isTiktokConnected, setIsTiktokConnected] = useState<boolean>(false);
  const [isDisconnectSocialMedia, setIsDisconnectSocialMedia] =
    useState<boolean>(false);
  const [isSnapchatConnected, setIsSnapchatConnected] =
    useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [tempUser, setTempUser] = useState<any>();
  const [user, setUser] = useState<any>({
    id: 0,
    name: "",
    profileImage: "",
    profileImageFile: null,
    displayName: "",
    percentage: 0,
    isSocialConnection: false,
  });
  const [selectedPlatform, setSelectedPlatform] = useState<string>("");
  const [isInstagramDialogOpen, setIsInstagramDialogOpen] =
    useState<boolean>(false);
  const [isVisibleProgress, setIsVisibleProgress] = useState<boolean>(true);

  useEffect(() => {
    dispatch(getCreatorInfo({ navigate }));
  }, []);

  useEffect(() => {
    let isSocialConnection = false;
    if (creatorInfo) {
      setTempUser(creatorInfo);

      if (creatorInfo?.integrated_platforms.Instagram) {
        setIsInstagramConnected(true);
        isSocialConnection = true;
      }
      if (creatorInfo?.integrated_platforms.Snapchat) {
        setIsSnapchatConnected(true);
        isSocialConnection = true;
      }
      if (creatorInfo?.integrated_platforms.Tiktok) {
        setIsTiktokConnected(true);
        isSocialConnection = true;
      }

      setUser({
        ...user,
        id: creatorInfo.user.id,
        name: creatorInfo.display_name,
        profileImage: creatorInfo.profile_picture_url,
        percentage: creatorInfo.percentage,
        isSocialConnection: isSocialConnection,
      });
    }
  }, [creatorInfo]);

  useEffect(() => {
    const hasProfileImage = user.profileImage;
    const hasName = user.name;
    const hasSocialConnection = user.isSocialConnection;
    let percentage = 0;

    if (!hasProfileImage && !hasName && !hasSocialConnection) {
      percentage = 0;
    }
    if (
      (hasProfileImage && hasName) ||
      (hasProfileImage && hasSocialConnection) ||
      (hasName && hasSocialConnection)
    ) {
      percentage = 50;
      setIsButtonDisable(false);
    } else if (hasProfileImage) percentage = 25;
    else if (hasName) percentage = 25;
    else if (hasSocialConnection) percentage = 25;
    else if (tempUser)
      if (
        hasProfileImage !== tempUser.profile_picture ||
        hasName !== tempUser.display_name
      )
        setIsButtonDisable(false);

    setUser({ ...user, percentage: percentage });

    if (user.percentage === 100) {
      setIsVisibleProgress(false);
    } else setIsVisibleProgress(true);
  }, [user.name, user.percentage, user.profileImage, user.isSocialConnection]);

  useEffect(() => {
    let formData = new FormData();

    if (error_code) {
      navigate(route_info.market_place.my_creator_info);
    } else {
      formData.append("code", code);

      if (platform === PLATFORMS.INSTAGRAM.toLowerCase()) {
        dispatch(connectFacebook({ code, callback: navigate }))
          .then((res: any) => {
            navigate(route_info.market_place.my_creator_info);
          })
          .catch((err: any) => {
            navigate(route_info.market_place.my_creator_info);
          });
      } else if (platform === PLATFORMS.TIKTOK.toLowerCase()) {
        dispatch(connectTiktok({ code }))
          .then((res: any) => {
            navigate(route_info.market_place.my_creator_info);
          })
          .catch((err: any) => {
            navigate(route_info.market_place.my_creator_info);
          });
      } else if (platform === PLATFORMS.SNAPCHAT.toLowerCase()) {
        dispatch(connectSnapChat({ code }))
          .then((res: any) => {
            navigate(route_info.market_place.my_creator_info);
          })
          .catch((err: any) => {
            navigate(route_info.market_place.my_creator_info);
          });
      } else return;
    }
  }, [platform, error_code]);

  const handleDiscard = () => {
    if (
      user.profileImage !== tempUser.profile_picture ||
      user.name !== tempUser.display_name
    )
      setUser({
        ...user,
        profileImage: tempUser.profile_picture,
        profileImageFile: null,
        name: tempUser.display_name,
      });
  };

  const handleSave = () => {
    let formData = new FormData();

    // Adding files to the formdata
    formData.append("display_name", user.name);
    formData.append("profile_picture", objectKey);
    if (tempUser) dispatch(updateCreatorInfo({ formData, id: tempUser.id }));
    else
      dispatch(saveCreatorInfo(formData)).then((res: any) => {
        if (!res.error) {
          toast.success(res.message, {
            duration: 2000,
          });
        }
      });
  };

  const handleReset = () => {
    if ((user && user.profileImage) !== (tempUser && tempUser.profileImage))
      setUser({
        ...user,
        profileImage: tempUser?.profileImage || null,
        profileImageFile: null,
      });
    else
      setUser({
        ...user,
        profileImage: null,
        profileImageFile: null,
      });
  };

  const handleUploadProfile = (event: any) => {
    const fileUploaded = event.target.files[0];
    const temp = URL.createObjectURL(fileUploaded);
    const type = fileUploaded.type.split("/");
    const size = Math.ceil(fileUploaded.size / 1024); //convert from byte to KB

    if (size > 800) {
      setErrorMessage("File size is larger then 800K");
    } else if (!["jpg", "png", "jpeg"].includes(type[1])) {
      setErrorMessage("Invalid file type. Please upload .jpg or .png file");
    } else {
      setUser({ ...user, profileImage: temp, profileImageFile: fileUploaded });
      setErrorMessage("");
    }

    fileUploaded && handleLogoUpload(event.target.files);
  };

  const handleLogoUpload = async (file: any) => {
    let data = {
      type: "upload_creator_profile",
      file_name: file[0].name,
    };
    dispatch(setPresignedUrlAPI({ data, file }));
  };

  const handleDeletePaymentGateway = () => {
    setIsPaymentGateway(false);
  };

  const handleConnect = (socialType: string, redirectURL: string) => {
    let tempPlatform = null;
    if (socialType === "insta") {
      setIsInstagramDialogOpen(true);
      tempPlatform = PLATFORMS.INSTAGRAM;
    } else if (socialType === "tiktok") {
      tempPlatform = PLATFORMS.TIKTOK;
      window.open(redirectURL, "_self");
    } else if (socialType === "snapchat") {
      tempPlatform = PLATFORMS.SNAPCHAT;
      window.open(redirectURL, "_self");
    } else return true;

    setSelectedPlatform(tempPlatform);
    //
  };

  const handleDisconnect = (socialType: string) => {
    // setIsDisconnectSocialMedia(true);
    // setSelectedPlatform(socialType);
  };

  const handleConfirmDisconnect = () => {
    setIsDisconnectSocialMedia(false);
  };

  const initialOptions = {
    "client-id":
      "AVqf20HrlwP5-yNscvm1YI0gyOufcTzbR9T1va0f4Qsp5YwOyNtWIK3ka3OqPEYkpn48TBKUpbdqzsai",
    "data-client-token":
      "access_token$sandbox$fx5wfjszcc6ymfdj$4e89670135a049244726157cb09ed9a8",
  };

  const handleInstagramDialogClose = () => {
    setIsInstagramDialogOpen(false);
  };

  return (
    <Box className="my-creator-info-block">
      {loadingState ? (
        <Box className="loader">
          <CircularProgress size={20} />
        </Box>
      ) : (
        <>
          <Box className="sync-flex-direction" sx={{ flexWrap: "wrap" }}>
            <Box>
              <Typography variant="h5" sx={{ mb: 2 }}>
                {MARKET_PLACE.CREATE_CONTENT.MY_CREATOR_PROFILE}
              </Typography>
              <Typography
                variant="body2"
                sx={{ color: "rgba(76, 78, 100, 0.68)", mb: 2 }}
              >
                {
                  MARKET_PLACE.CREATE_CONTENT
                    .INFORMATION_NEEDED_TO_BE_ABLE_TO_UPLOAD
                }
              </Typography>
            </Box>
            <Box className="sync-flex-direction-align">
              <Button
                disabled={isButtonDisable}
                sx={{
                  mr: 5,
                  background: (theme: any) =>
                    theme.palette?.customColors?.market_place_theme,
                }}
                variant="contained"
                className="info-button"
                onClick={handleSave}
              >
                {MARKET_PLACE.CREATE_CONTENT.SAVE}
              </Button>
              <Button
                variant="outlined"
                className="info-button"
                onClick={handleDiscard}
              >
                {MARKET_PLACE.CREATE_CONTENT.DISCARD}
              </Button>
            </Box>
          </Box>
          <Grow in={true}>
            <Grid container className="creator-info-block">
              <Grid item xs={12} md={7}>
                <LinearProgressWithLabel
                  value={user.percentage || 0}
                  isVisible={isVisibleProgress}
                />
                <Box
                  className="sync-flex-direction-align"
                  sx={{ mb: "24px", flexWrap: "wrap" }}
                >
                  <Box className="profile-img-block">
                    {user.profileImage ? (
                      <Box
                        className="user-profile-block"
                        component="img"
                        alt="Profile Image"
                        src={user.profileImage}
                      />
                    ) : (
                      <CropOriginalIcon />
                    )}
                  </Box>
                  <Box>
                    <input
                      type="file"
                      onChange={handleUploadProfile}
                      ref={inputFile}
                    />

                    <Box sx={{ mb: "10px" }}>
                      <Button
                        sx={{ mr: 5, mb: 2 }}
                        id="contained-button-file"
                        variant="contained"
                        className="upload-logo-btn"
                        // className="upload-content-btn"
                        onClick={() => inputFile?.current?.click()}
                      >
                        {MARKET_PLACE.CREATE_CONTENT.UPLOAD_PROFILE_PICTURE}
                      </Button>
                      <Button
                        type="submit"
                        variant="outlined"
                        onClick={handleReset}
                      >
                        {MARKET_PLACE.CREATE_CONTENT.RESET}
                      </Button>
                    </Box>

                    {errorMessage ? (
                      <Typography
                        variant="caption"
                        sx={{
                          color: (theme: any) =>
                            theme.palette.customColors.error,
                        }}
                      >
                        {errorMessage}
                      </Typography>
                    ) : (
                      <Typography
                        variant="caption"
                        sx={{
                          color: (theme: any) =>
                            theme?.palette?.customColors?.border_color_light,
                        }}
                      >
                        {MARKET_PLACE.CREATE_CONTENT.ALLOW_JPG_PNG}
                      </Typography>
                    )}
                  </Box>
                </Box>

                <TextField
                  id="demo-helper-text-misaligned-no-helper"
                  style={{ width: "100%", marginBottom: "24px" }}
                  label="Display name (this is the name that other users will see)"
                  // This defaultValue is to show API data in TextField
                  defaultValue={
                    user.name &&
                    "Display name (this is the name that other users will see)"
                  }
                  value={user.name || ""}
                  onChange={(event: any) =>
                    setUser({ ...user, name: event.target.value })
                  }
                />

                <Typography
                  variant="body1"
                  sx={{
                    color: (theme: any) =>
                      theme?.palette?.customColors?.border_color_light,
                    mb: "10px",
                  }}
                >
                  {MARKET_PLACE.CREATE_CONTENT.CONNECT_YOUR_PAYPAL}
                </Typography>

                {isPaymentGateway ? (
                  <Box className="paypal-account-block">
                    <Box className="active-bullet"></Box>
                    <Typography variant="body2" className="paypal-acocunt-card">
                      johndoe@mail.com
                    </Typography>
                    <IconButton
                      aria-label="close"
                      onClick={handleDeletePaymentGateway}
                      sx={{ p: 0 }}
                    >
                      <CancelIcon />
                    </IconButton>
                  </Box>
                ) : (
                  <Button
                    type="submit"
                    size="large"
                    variant="contained"
                    style={{
                      backgroundColor: "#FFC43A",
                      width: "70%",
                      borderRadius: "4px",
                      marginBottom: "48px",
                    }}
                  >
                    {MARKET_PLACE.CREATE_CONTENT.CONNECT_WITH}
                    <img src={Paypal} style={{ marginLeft: "10px" }} />
                  </Button>
                )}

                <Typography
                  variant="body1"
                  sx={{ color: "rgba(76, 78, 100, 0.87)", mb: "10px" }}
                >
                  {MARKET_PLACE.CREATE_CONTENT.CONNECT_SOCIAL_ACCOUNT}
                </Typography>
              </Grid>

              <Grid item xs={12} md={5}></Grid>
              <Grid item xs={12} md={3}>
                <Box
                  className="social-media-box"
                  sx={{
                    mb: 2,
                    borderStyle: isInstagramConnected ? "solid" : "dotted",
                    borderColor: isInstagramConnected
                      ? "transparent"
                      : "#D6D6D6",
                    boxShadow: isInstagramConnected
                      ? "0px 2px 10px rgba(76, 78, 100, 0.22)"
                      : "0px 0px 0px",
                  }}
                >
                  <Box
                    className="social-connection-img"
                    component="img"
                    alt="Instagram image not found"
                    src={isInstagramConnected ? Instagram : InstagramGrey}
                  />
                  <Typography
                    variant="h6"
                    sx={{ mb: "5px", color: "rgba(76, 78, 100, 0.87)" }}
                  >
                    {PLATFORMS.FACEBOOK}
                  </Typography>

                  <Typography
                    onClick={() =>
                      !isInstagramConnected &&
                      handleConnect("insta", config.INSTAGRAM_CONNECTION_URL)
                    }
                    variant="subtitle2"
                    className="txt-edit"
                  >
                    {isInstagramConnected ? "Connected" : "Connect"}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={3}>
                <Box
                  className="social-media-box"
                  sx={{
                    mb: 2,
                    borderStyle: isTiktokConnected ? "solid" : "dotted",
                    borderColor: isTiktokConnected ? "transparent" : "#D6D6D6",
                    boxShadow: isTiktokConnected
                      ? "0px 2px 10px rgba(76, 78, 100, 0.22)"
                      : "0px 0px 0px",
                  }}
                >
                  <Box
                    className="social-connection-img"
                    component="img"
                    alt="Instagram image not found"
                    src={isTiktokConnected ? Tiktok : TiktokGrey}
                  />

                  <Typography
                    variant="h6"
                    sx={{ mb: "5px", color: "rgba(76, 78, 100, 0.87)" }}
                  >
                    {PLATFORMS.TIKTOK}
                  </Typography>

                  <Typography
                    onClick={() =>
                      !isTiktokConnected &&
                      handleConnect("tiktok", config.TIKTOK_CONNECT_URL)
                    }
                    variant="subtitle2"
                    className="txt-edit"
                  >
                    {isTiktokConnected ? "Connected" : "Connect"}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={3}>
                <Box
                  className="social-media-box"
                  sx={{
                    mb: 2,
                    borderStyle: isSnapchatConnected ? "solid" : "dotted",
                    borderColor: isSnapchatConnected
                      ? "transparent"
                      : "#D6D6D6",
                    boxShadow: isSnapchatConnected
                      ? "0px 2px 10px rgba(76, 78, 100, 0.22)"
                      : "0px 0px 0px",
                  }}
                >
                  <Box
                    className="social-connection-img"
                    component="img"
                    alt="Instagram image not found"
                    src={isSnapchatConnected ? Snapchat : SnapchatGrey}
                  />

                  <Typography
                    variant="h6"
                    sx={{ mb: "5px", color: "rgba(76, 78, 100, 0.87)" }}
                  >
                    {PLATFORMS.SNAPCHAT}
                  </Typography>

                  <Typography
                    onClick={() =>
                      isSnapchatConnected
                        ? handleDisconnect("snapchat")
                        : handleConnect(
                            "snapchat",
                            config.SNAPCHAT_CONNECTION_URL
                          )
                    }
                    variant="subtitle2"
                    className="txt-edit"
                  >
                    {isSnapchatConnected ? "Connected" : "Connect"}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={3}></Grid>
            </Grid>
          </Grow>
        </>
      )}

      <InstagramConnectionDialog
        isOpen={isInstagramDialogOpen}
        onClickClose={handleInstagramDialogClose}
      />

      <Dialog
        fullWidth
        open={isDisconnectSocialMedia}
        aria-labelledby="user-view-edit"
        aria-describedby="user-view-edit-description"
      >
        <DialogTitle className="detail-dialog-title" sx={{ pb: 0 }}>
          Alert
        </DialogTitle>

        <DialogContent>
          {loadingState ? (
            <Box className="loader">
              <CircularProgress size={20} />
            </Box>
          ) : (
            <Box>
              <DialogContentText id="alert-dialog-description">
                {`Are you sure to disconnect/remove your ${selectedPlatform} connection?`}
              </DialogContentText>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDisconnectSocialMedia(false)}>
            Cancel
          </Button>
          <Button
            color="error"
            onClick={() => handleConfirmDisconnect()}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}
