// ** React Imports
import { useEffect } from "react";

// ** MUI Imports
import { Box, Slider, Typography, Card, Divider, Grow } from "@mui/material";

// ** Custom Components
import { MARKET_PLACE } from "@core/constants/title-constants";
import {
  video_post_marks,
  video_only_marks,
} from "@core/constants/global-constants";
import FooterButton from "components/FooterButton/footerButtton";

interface Props {
  state: any;
  setState: any;
  onForwardButtonClick?(): void;
  onBackwardButtonClick?(): void;
  onClearDraftButtonClick(): void;
}

export default function SettingStep({
  state,
  setState,
  onForwardButtonClick,
  onBackwardButtonClick,
  onClearDraftButtonClick,
}: Props) {
  const budgetValue = state.selectedBudget;

  let isVideoOnly =
    state.placement?.publishContentType === "collect_video_only" ? true : false;
  let marks = isVideoOnly ? video_only_marks : video_post_marks;

  useEffect(() => {
    setState({
      ...state,
      selectedBudget:
        budgetValue && budgetValue !== 0 ? budgetValue : marks[0].value,
    });
  }, []);

  const handleChange = (event: any, newValue: any) => {
    setState({ ...state, selectedBudget: newValue });
  };

  return (
    <Box>
      <Typography variant="h5" sx={{ mb: 2 }}>
        {MARKET_PLACE.REQUEST_CONTENT.BUDGET_DIVIDER}
      </Typography>
      <Typography variant="subtitle2" sx={{ mb: "20px" }}>
        {MARKET_PLACE.REQUEST_CONTENT.BUDGET_DIVIDER_SUBTITLE}
      </Typography>
      <Grow in={true}>
        <Card className="borderRadiusSpecific box-border">
          <Slider
            sx={{
              width: { md: "80%", xs: "100%" },
              mb: "30px",
              "& .MuiSlider-track": {
                height: 2,
              },
            }}
            max={marks[marks.length - 1].value}
            min={marks[0].value}
            aria-label="Custom marks"
            defaultValue={0}
            value={state.selectedBudget}
            onChange={handleChange}
            step={null}
            valueLabelDisplay="auto"
            marks={marks}
            valueLabelFormat={(value) => (
              <>
                <p>{"$" + value}</p>
              </>
            )}
          />

          <Typography variant="subtitle2">
            {isVideoOnly
              ? marks[0].value === state.selectedBudget
                ? "Collect more videos, minimal effort by creators"
                : marks[1].value === state.selectedBudget
                ? "Balanced videos and effort by creators"
                : "Fewer videos, more effort by creators"
              : marks[0].value === state.selectedBudget
              ? "More creators will join, less viral potential"
              : marks[1].value === state.selectedBudget
              ? "Balanced number of creators and viral potential"
              : "Fewer but bigger creators, more viral potential"}
          </Typography>

          <Box>
            <Divider sx={{ mb: "30px", my: "30px" }} />
            <FooterButton
              state={state}
              backwardTitle="requirements"
              forwardTitle="budget divider"
              handledBackwardButtonClick={onBackwardButtonClick}
              handleNextSliderClick={onForwardButtonClick}
              handleClearDraftButtonClick={onClearDraftButtonClick}
            />
          </Box>
        </Card>
      </Grow>
    </Box>
  );
}
