import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";
import { ButtonLoading, ScreenLoading } from "store/Slice/AuthSlice";
import {
  notificationFail,
  notificationSuccess,
} from "../Slice/notificationSlice";
import { setAccountDetails } from "store/Slice/SettingSlice";
import { AxiosRequestConfig } from "axios";

interface MyAxiosRequestConfig extends AxiosRequestConfig {
  customHeaders?: {
    [key: string]: any;
  };
}

const requestConfig: MyAxiosRequestConfig = {
  customHeaders: {
    uid: null  // Removes the uid header from the request
  }
};

export const getAccountDetails = createAsyncThunk(
    "getAccountDetails",
    async ({}: any, {dispatch}) => {
        try {
            
            const header: any = localStorage.getItem("header")
            const user_id = JSON.parse(header).user_id 
            const response = await API.get(
                `/api/v1/accounts/user/${user_id}/`
            )
          dispatch(ScreenLoading(false));
            let updatedData = {...JSON.parse(header), profile_pic: response?.data?.profile_pic || ""}

            localStorage.setItem("header", JSON.stringify(updatedData));
            dispatch(setAccountDetails(response.data))
            return response;
        } catch (err: any) {
            dispatch(ScreenLoading(false));
            return err;
        }
    }
);

export const resetPassword = createAsyncThunk(
  "resetPassword",
  async ({ _request }: any, { dispatch }) => {
    try {
        
        const response: any = await API.post(
        "/api/v1/accounts/reset-password/",
        _request
        );
        if (response?.data?.error) {
            dispatch(notificationFail(response?.data?.message));
        } else {
            dispatch(notificationSuccess(response?.data?.message));
        }
        dispatch(ButtonLoading(false));
        
        return response;
    } catch (err: any) {
        dispatch(ButtonLoading(false));
        dispatch(notificationFail(err.response.data.message));
        return err;
    }
  }
);

export const updateAccountData = createAsyncThunk(
  "updateAccountData",
  async ({ _request }: any, { dispatch }) => {
    try {
      let formData = new FormData();

      formData.append("first_name", _request.first_name);
      formData.append("last_name", _request.last_name);
      formData.append("email", _request.email);
      formData.append("phone_number", _request.phone_number);
      formData.append("company_name", _request.company_name);
      formData.append("country", _request.country);
      formData.append("gender", _request.gender);
      formData.append("profile_pic", _request.profile_pic);
      const header: any = localStorage.getItem("header")
      const user_id = JSON.parse(header).user_id 
      const response: any = await API.put(
      `/api/v1/accounts/user/${user_id}/`,
          formData, requestConfig);
      if (response?.data?.error) {
          dispatch(notificationFail(response?.data?.message));
      } else {
          dispatch(getAccountDetails({}))
          dispatch(notificationSuccess(response?.data?.message));
      }
      dispatch(ButtonLoading(false));
      
      return response;
    } catch (err: any) {
        dispatch(ButtonLoading(false));
        dispatch(notificationFail(err.response.data.message));
        return err;
    }
  }
);
