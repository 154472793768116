// ** MUI Imports
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  ListItem,
  List,
} from "@mui/material";

// ** Custom Component
import config from "config/config";
import { MARKET_PLACE } from "@core/constants/title-constants";
import { requirements } from "@core/constants/global-constants";

export default function InstagramConnectionDialog(props: any) {
  const { isOpen, onClickClose } = props;

  const handleConnection = () => {
    window.open(config.INSTAGRAM_CONNECTION_URL, "_self");
    onClickClose();
  };

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="user-view-edit"
      aria-describedby="user-view-edit-description"
    >
      <>
        <DialogTitle className="detail-dialog-title" sx={{ pb: 0 }}>
          {MARKET_PLACE.CREATE_CONTENT.CONNECT_INSTAGRAM}
        </DialogTitle>

        <DialogContent>
          <Typography className="details-dialog-subtitle">
            {MARKET_PLACE.CREATE_CONTENT.CONNECT_INSTAGRAM_DESCRIPTION}
          </Typography>
          <List sx={{ listStyleType: "disc", pl: 6 }}>
            {requirements.map((req: any, index: number) => (
              <ListItem
                className="details-dialog-subtitle details-dialog-subtitle-new"
                key={index}
                // sx={{ display: "list-item", pb: 0, pl: "3px"}}
              >
                {req}
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ fontSize: { xs: "13px" } }}
            variant="outlined"
            onClick={onClickClose}
          >
            Cancel
          </Button>
          <Button
            sx={{
              p: { xs: "0.4375rem 0.5rem", md: "0.4375rem 1.375rem" },
              fontSize: { xs: "13px", md: "14px" },
            }}
            variant="contained"
            color="primary"
            onClick={() => handleConnection()}
            autoFocus
          >
            {MARKET_PLACE.CREATE_CONTENT.FACEBOOK_LOGIN}
          </Button>
        </DialogActions>
      </>
    </Dialog>
  );
}
