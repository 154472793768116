import { Box, Button, Switch, Typography } from "@mui/material";
import { useState } from "react";
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import { GridColumns, GridRenderCellParams } from '@mui/x-data-grid'
import CustomChip from '@core/components/mui/chip'

// ** Utils Import
import { ThemeColor } from "@core/layouts/types";
import CampaignTiktokTabs from "./CampaignTiktokTabs";
import AdsetTiktokTabs from "./AdsetTiktokTabs";


interface StatusObj {
  [key: number]: {
    title: string
    color: ThemeColor
  }
}

const statusObj: StatusObj = {
  1: { title: 'professional', color: 'primary' },
  2: { title: 'Optimized', color: 'success' },
  3: { title: 'Error', color: 'error' },
  4: { title: 'Not Available', color: 'secondary' },
}

const columns: GridColumns = [
  {
    flex: 0.25,
    minWidth: 400,
    field: 'campaign_name',
    headerName: 'Campaign name',
    renderCell: (params: GridRenderCellParams) => {
      const { row } = params

      return (
        <Box className="sync-align-center" >
          <Box sx={{background: row.color, height: '9px', width: '9px', borderRadius: '5px', mr: 3}}></Box>
          <Box className="sync-direction-column" >
            <Typography noWrap variant='subtitle2' sx={{ color: 'text.primary'}}>
              {row.campaign_name}
            </Typography>
          </Box>
        </Box>
      )
    }
  },
  {
    flex: 0.175,
    minWidth: 120,
    headerName: 'PROFILE',
    field: 'profile',
    renderCell: (params: GridRenderCellParams) => (
      <Typography variant='body2' sx={{ color: 'text.primary' }}>
        {params.row.profile}
      </Typography>
    )
  },
  {
    flex: 0.175,
    minWidth: 110,
    field: 'Auto optimize',
    headerName: 'optimize',
    renderCell: (params: GridRenderCellParams) => {
      const { row } = params;
      return(
      <Box>
      <Switch defaultChecked={row.optimize ? true : false} />
      </Box>
    )}
  },
  {
    flex: 0.175,
    minWidth: 140,
    field: 'status',
    headerName: 'Status',
    renderCell: (params: GridRenderCellParams) => {
      const status = statusObj[params.row.status]

      return (
        <CustomChip
          size='small'
          skin='light'
          color={status.color}
          label={status.title}
          sx={{ '& .MuiChip-label': { textTransform: 'capitalize' } }}
        />
      )
    }
  }
]

export default function CampaignTab() {

  const [activeButton, setActiveButton] = useState<string>("Campaigns");

  return (
    <>
    <Box sx={{display: 'flex', justifyContent: 'space-between', mt: 8}}>
      <Box
        sx={{
          padding: 2,
          border: 1,
          borderRadius: 1,
          color: "primary.main",
          width: "329px",
          display: "flex",
          justifyContent: "space-between",
          position: {md:'absolute'},
          zIndex: 1
        }}
      >
        <Button
          variant={activeButton == "Campaigns" ? "contained" : "text"}
          onClick={() => setActiveButton("Campaigns")}
          sx={{ width: "152px", paddingLeft: 3, paddingRight: 3 }}
        >
         <FolderOpenIcon sx={{mr: 2}}/>Campaigns
        </Button>
        <Button
          variant={activeButton == "Adsets" ? "contained" : "text"}
          onClick={() => setActiveButton("Adsets")}
          sx={{ width: "152px" }}
        >
          <GridViewOutlinedIcon sx={{mr: 2}}/> Adsets
        </Button>
      </Box>
        </Box>
        { activeButton === "Campaigns" ? <CampaignTiktokTabs /> :  <AdsetTiktokTabs /> }
      </>
  );
}
