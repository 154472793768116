export const route_info = {
    market_planner: {
        dashboard: "/dashboard",
        presets: "/presets",
        auto_scheduler: "/auto-scheduler",
        campaigns: "/campaigns",
        // account_settings: "/account-settings",
        user_permission: "/user-permission",
        platform: "/platform"
    },
    market_place: {
        all_job: "/all-jobs",
        job_detail: "/all-jobs/job-detail",
        creator_marketplace: "/creator-marketplace",
        create_content: "/create-content",
        create_new_job: "/create-new-job",
        my_creator_info: "/my-creator-info",
        my_brand_profile: "/my-brand-profile",
        my_brand_jobs: "/my-brand-jobs",
        my_submission: "/my-submission",
        submissions: "/submissions",
        my_earnings: "/my-earnings",
        invoice: "/my-earnings/invoice",
        my_job_detail: "/my-brand-jobs/job-detail"
    },
    auth_route: {
        base: "/",
        register: "/register",
        create_account: "/create-account",
        forgot_password: "/forgot-password",
        reset_password: "/reset-password",
        verify_email: "/verify-email",
        login: "/"
    },
    common: {
        overview: "/overview",
        setting:"/account-settings"
    },
    error: {
        error_page: "*",
        unauthorized: "/401",
        server_error: "/500",
    }
}
