// ** MUI Imports
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
} from "@mui/material";

interface Props {
  isOpen?: any;
  onContinueClick?: any;
}

export default function LinkShared({ isOpen, onContinueClick }: Props) {
  return (
    <Dialog open={isOpen}>
      <DialogTitle className="detail-dialog-title" sx={{ pb: 0 }}>
        Your link is shared 🎉
      </DialogTitle>

      <DialogContent sx={{ maxWidth: "450px" }}>
        <Typography>
          Your social post is now shared with the brand. You will receive a
          notification when the submission has been approved or declined.
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{ width: "100%" }}
          color="primary"
          variant="contained"
          onClick={onContinueClick}
        >
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}
