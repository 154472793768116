import {
  Box,
  Button,
  Divider,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Typography,
  Grid,
  InputLabel,
  FormControl,
  FormHelperText,
  CircularProgress,
  Backdrop,
} from "@mui/material";
import { useState } from "react";
import { EyeOffOutline, EyeOutline } from "mdi-material-ui";
import Validations from "helper/validations";
import { useAppDispatch, useAppSelector } from "store/store";
import { resetPassword } from "store/Thunk/settingThunk";
import { ButtonLoading, ScreenLoading } from "store/Slice/AuthSlice";

interface State {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  newPassword: string;
  confirmPassword: string;
  showPassword: boolean;
  showNewPassword: boolean;
  showConfirmPassword: boolean;
}

interface FormValidation {
  password?: string;
  newPassword?: string;
  confirmPassword?: string;
}

export default function SecurityTab() {
  const [values, setValues] = useState({
    password: "",
    newPassword: "",
    confirmPassword: "",
    showPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
  });
  const [formError, setFormError] = useState<FormValidation>();
  const { screenLoading, buttonLoading } = useAppSelector(
    (state) => state.authReducer
  );
  const dispatch: any = useAppDispatch();

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleSaveBtn = (event: any) => {
    let data = {
      password: values.password.trim(),
      newPassword: values.newPassword.trim(),
      confirmPassword: values.confirmPassword.trim(),
    };

    let error = Validations.validateSecurityForm(data);

    if (Object.entries(error).length === 0 && error.constructor === Object) {
      let _request = {
        new_password: data.newPassword,
        old_password: data.password,
      };
      dispatch(ButtonLoading(true));
      dispatch(resetPassword({ _request }));
    }

    setFormError(error);
  };

  return (
    <Box>
      {/* {screenLoading && (
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme: any) => theme.zIndex.drawer + 1,
          }}
          open={screenLoading}
        >
          <CircularProgress size={20} />
        </Backdrop>
      )} */}
      <Box sx={{ margin: 5 }}>
        <Box sx={{ mt: 5 }}>
          <Box sx={{ flexDirection: "column", my: 7 }}>
            <Typography variant="h5">Set a new password</Typography>
            <Typography variant="subtitle2">
              Your new password must be at least 8 characters long and contain a
              mix of upper and lower case letters, a number, and a symbol.
            </Typography>
          </Box>
          <Grid container spacing={5} sx={{ width: { md: "50%" } }}>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="auth-register-password">
                  Current Password
                </InputLabel>
                <OutlinedInput
                  label="Current Password"
                  value={values.password}
                  id="auth-register-password"
                  onChange={handleChange("password")}
                  type={values.showPassword ? "text" : "password"}
                  error={formError && formError.password ? true : false}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        aria-label="toggle password visibility"
                      >
                        {values.showPassword ? (
                          <EyeOutline fontSize="small" />
                        ) : (
                          <EyeOffOutline fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {formError && formError.password && (
                  <FormHelperText error id="password-error">
                    {formError.password}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="auth-register-password">
                  New Password
                </InputLabel>
                <OutlinedInput
                  label="New Password"
                  value={values.newPassword}
                  id="auth-register-password"
                  onChange={handleChange("newPassword")}
                  type={values.showNewPassword ? "text" : "password"}
                  error={formError && formError.newPassword ? true : false}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() =>
                          setValues({
                            ...values,
                            showNewPassword: !values.showNewPassword,
                          })
                        }
                        onMouseDown={handleMouseDownPassword}
                        aria-label="toggle password visibility"
                      >
                        {values.showNewPassword ? (
                          <EyeOutline fontSize="small" />
                        ) : (
                          <EyeOffOutline fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {formError && formError.newPassword && (
                  <FormHelperText error id="password-error">
                    {formError.newPassword}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={12}>
              <FormControl fullWidth>
                <InputLabel htmlFor="auth-register-password">
                  Confirm Password
                </InputLabel>
                <OutlinedInput
                  label="Confirm Password"
                  value={values.confirmPassword}
                  id="auth-register-password"
                  onChange={handleChange("confirmPassword")}
                  type={values.showConfirmPassword ? "text" : "password"}
                  error={formError && formError.confirmPassword ? true : false}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={() =>
                          setValues({
                            ...values,
                            showConfirmPassword: !values.showConfirmPassword,
                          })
                        }
                        onMouseDown={handleMouseDownPassword}
                        aria-label="toggle password visibility"
                      >
                        {values.showConfirmPassword ? (
                          <EyeOutline fontSize="small" />
                        ) : (
                          <EyeOffOutline fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {formError && formError.confirmPassword && (
                  <FormHelperText error id="password-error">
                    {formError.confirmPassword}
                  </FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>
          <Divider sx={{ my: 5 }} />
          {/* <Box className="sync-align-center">
          <VpnKeyIcon sx={{ mr: 3 }} />
          <Typography variant="h5">Two-factor authentication</Typography>
        </Box>
        <Box sx={{ flexDirection: "column" }} className="sync-align-center">
          <Box
            className="sync-align-justify-center"
            sx={{
              background:
                "linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), #FF4D49",
              height: "48px",
              width: "48px",
              borderRadius: "8px",
              mt: 10,
            }}
          >
            <LockOpenIcon
              width={"16px"}
              height={"21px"}
              sx={{ color: "#FF4D49" }}
            />
          </Box>
          <Typography variant="h6" sx={{ mt: 5 }}>
            Two factor authentication is not enabled yet.
          </Typography>
          <Typography variant="subtitle2" sx={{ mt: 5 }}>
            Two-factor authentication adds an additional layer of
          </Typography>
          <Typography variant="subtitle2">
            security to your account by requiring more than just a
          </Typography>
          <Typography variant="subtitle2">
            password to log in. Learn more.
          </Typography>
        </Box> */}
          <Box>
            <Button variant="outlined" sx={{ mr: 3 }}>
              CANCEL
            </Button>
            <Button
              variant="contained"
              onClick={handleSaveBtn}
              disabled={buttonLoading}
              className={!buttonLoading ? "gradient-btn" : ""}
              sx={{
                minWidth: "160px",
              }}
            >
              {buttonLoading ? <CircularProgress size={20} /> : "SAVE CHANGES"}
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
