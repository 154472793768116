// ** React
import { useContext, useEffect, useState } from "react";

// ** MUI Imports
import {
  Alert,
  AlertTitle,
  Autocomplete,
  Box,
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormHelperText,
  Grow,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  Switch,
  TextField,
  Typography,
} from "@mui/material";

// ** Third Party Library
import _, { toNumber } from "lodash";

// ** Redux
import { useAppDispatch, useAppSelector } from "store/store";
import { getAllScraperGroup } from "store/Thunk/platformConnectionThunk";
import { AutoschudlerContext } from "context/autoschedulerContex";

// ** Custom Component
import country_codes from "data/json/country_codes.json";
import facebook_language_response from "data/json/facebook_language_response.json";
import tiktok_language_response from "data/json/tiktok_language_response.json";
import snap_language_response from "data/json/snap_language_response.json";
import DropdownNotFound from "components/DropdownNotFound/DropdownNotFound";
import { MEDIA_PLANNER } from "@core/constants/title-constants";
import { BID_STRATEGY, CAMPAIGN_OBJECTIVE } from "@core/constants/enum";

// ** Icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";

interface Props {
  onForwardButtonClick?(): void;
  onBackwordButtonClick?(): void;
  onClearDraftClick?(): void;
  campaign: any;
  setCampaign: any;
}

export default function Champaing({
  onForwardButtonClick,
  onBackwordButtonClick,
  onClearDraftClick,
  campaign,
  setCampaign,
}: Props) {
  const dispatch: any = useAppDispatch();
  let autoSchedulerTitle = MEDIA_PLANNER.auto_scheduler;
  let autoSchedulerSnackbar = MEDIA_PLANNER.snackbar;

  const { scraperGroupData } = useAppSelector(
    (state: any) => state.PlatformSlice
  );
  const { schedulerDraftData } = useAppSelector(
    (state: any) => state.autoSchedulerSlice
  );
  const { availablePlatformTitle }: any = useContext(AutoschudlerContext);

  const [open, setOpen] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addCountry, setAddCountry] = useState("");
  const [error, setError] = useState({
    alreadyExist: "",
    notMatch: "",
  });
  const [groupLoading, setGroupLoading] = useState(false);
  const [language, setLanguage] = useState([]);
  const [countryList] = useState(country_codes);
  const [tempCountry, setTempCountry] = useState([]);
  const [isClearDraftEnabled, setIsClearDraftEnabled] = useState(false);

  useEffect(() => {
    setIsClearDraftEnabled(!schedulerDraftData?.id);
  }, [schedulerDraftData]);

  useEffect(() => {
    if (!campaign?.group) {
      var default_group: any = scraperGroupData?.find(
        (element: any) => element?.group_name == "Default"
      )?.id;
      setCampaign({ ...campaign, group: default_group });
    }
  }, [scraperGroupData]);

  useEffect(() => {
    var languageArray: any = [];
    if (availablePlatformTitle?.includes("Facebook")) {
      languageArray = languageArray?.concat(facebook_language_response);
    }
    if (availablePlatformTitle?.includes("Tiktok")) {
      languageArray = languageArray?.concat(tiktok_language_response);
    }
    if (availablePlatformTitle?.includes("Snap")) {
      languageArray = languageArray?.concat(snap_language_response);
    }

    setLanguage(languageArray);
    let data: any = null;
    dispatch(getAllScraperGroup({ data })).then((res: any) => {
      if (res?.payload?.data?.length) {
        setGroupLoading(false);
      } else {
        setGroupLoading(true);
      }
    });
  }, []);

  useEffect(() => {
    if (tempCountry?.length && campaign?.isBundleCountries) {
      let lanArray: any = {};
      campaign?.countries?.map((item: any) => {
        lanArray = { ...lanArray, [item?.Code]: tempCountry };
      });
      setCampaign({ ...campaign, customLanguages: lanArray });
    } else if (!campaign?.isBundleCountries) {
      let lanArray: any = [];
      campaign?.countries?.map((item: any) => {
        if (campaign?.customLanguages?.[item?.Code]) {
          lanArray = {
            ...lanArray,
            [item?.Code]: campaign?.customLanguages?.[item?.Code],
          };
        }
      });
      setCampaign({ ...campaign, customLanguages: lanArray });
    }
  }, [campaign?.countries]);

  const onInputChange = (e: any) => {
    let value = e.target.value;
    value = value.includes(",") ? value.replace(",", ".") : value;
    const re = /^[0-9.]+$/;
    if (value === "" || re.test(value)) {
      if (
        campaign?.bidCost?.includes(".") ||
        (value[value.length - 1] == "." &&
          campaign?.bidCost[campaign?.bidCost.length - 2] == ".")
      ) {
        if (value[value.length - 1] != ".") {
          setCampaign({
            ...campaign,
            bidCost: value.includes(",") ? value.replace(",", ".") : value,
          });
        } else if (
          value[value.length - 1] == "." &&
          campaign?.bidCost[campaign?.bidCost.length - 2] == "."
        ) {
          setCampaign({
            ...campaign,
            bidCost: value.includes(",") ? value.replace(",", ".") : value,
          });
        }
      } else {
        setCampaign({
          ...campaign,
          bidCost: value.includes(",") ? value.replace(",", ".") : value,
        });
      }
    }
  };

  const onBudgetChange = (e: any) => {
    let value = e.target.value;
    value = value.replace(",", ".");
    setCampaign({ ...campaign, budget: value });
  };

  const handledBudget = () => {
    if (toNumber(campaign?.bidCost) >= toNumber(campaign?.budget)) {
      const bid: any = toNumber(campaign?.bidCost) + 1;
      setCampaign({ ...campaign, budget: bid < 20 ? "20" : bid.toString() });
      bid < 20 ? setOpen(false) : setOpen(true);
    } else if (toNumber(campaign?.budget) < 20) {
      const bid: any = toNumber(campaign?.bidCost) + 1;
      setCampaign({ ...campaign, budget: "20" });
    }
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const handleCountryChange = (country: any) => {
    if (country.search(",") >= 0) {
      setDialogOpen(true);
      setAddCountry(country);
    }
  };

  const handledCountryChange = (event: any, newValue: any) => {
    var isUpdate = true;
    if (isUpdate) {
      if (newValue?.length) {
        if (
          campaign?.countries?.some(
            (code: any) =>
              code?.Code == newValue[newValue?.length - 1]?.Code &&
              campaign?.countries.length <= newValue.length &&
              newValue.length
          )
        ) {
          var countryArr: any = campaign?.countries;
          var spliceIndex: any;
          spliceIndex = countryArr.findIndex(
            (value: any) => value.Code == newValue[newValue?.length - 1]?.Code
          );
          countryArr?.splice(spliceIndex, 1);
          setCampaign({ ...campaign, countries: countryArr });
        } else {
          setCampaign({ ...campaign, countries: newValue });
        }
      } else {
        setCampaign({ ...campaign, countries: newValue });
      }
    }
  };

  const handledAddCountry = () => {
    setError({ alreadyExist: "", notMatch: "" });
    setDialogOpen(false);
    var existArray: any = [];
    var notMatchArray: any = [];
    const element = addCountry.split(",").map((element) => element.trim());
    const countryArray: any = element.filter((item: any) => item != "");
    var newCountry: any = campaign?.countries;
    countryArray?.map((item: any) => {
      if (
        !newCountry?.some((code: any) => code?.Code == item) &&
        countryList?.some((code: any) => code?.Code == item)
      ) {
        newCountry.push(
          countryList[countryList.findIndex((value: any) => value.Code == item)]
        );
        setCampaign({ ...campaign, countries: newCountry });
      } else if (newCountry?.some((code: any) => code?.Code == item)) {
        existArray?.push(item);
      } else if (!countryList?.some((code: any) => code?.Code == item)) {
        notMatchArray?.push(item);
      }
    });

    setError({
      alreadyExist: existArray?.length
        ? existArray.toString() + " are already selected."
        : "",
      notMatch: notMatchArray?.length
        ? "Could not detected following country: " + notMatchArray.toString()
        : "",
    });
  };

  const handledLanguageChange = (newValue: any) => {
    setTempCountry(newValue);
    var lanArray: any = {};
    campaign?.countries?.map((item: any) => {
      lanArray = { ...lanArray, [item?.Code]: newValue };
    });
    setCampaign({ ...campaign, bundlelanguages: newValue });
  };

  return (
    <Box sx={{ height: "auto" }}>
      <Box sx={{ padding: 5 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {autoSchedulerTitle.SET_CAMPAIGN_DETAILS}
        </Typography>
        <Typography variant="subtitle2" sx={{ mb: 2 }}>
          {autoSchedulerTitle.SELECT_OBJECTIVES}
        </Typography>
      </Box>
      <Grow in={true}>
        <Card sx={{ height: "100%", padding: 10 }}>
          <Box
            sx={{
              mx: { md: 8, sm: 4, xs: 2 },
              maxWidth: "467px",
              width: { md: "50%", sm: "100%", xs: "100%" },
            }}
          >
            {/* ------------------ select group input -------------------------------------------------*/}

            <Box
              sx={{
                width: { md: "221px", sm: "221px", xs: "100%" },
              }}
            >
              <FormControl fullWidth sx={{ mt: 8 }}>
                <InputLabel>{autoSchedulerTitle.GROUP}</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  placeholder={autoSchedulerTitle.LABELS}
                  label={autoSchedulerTitle.GROUP}
                  value={campaign?.group}
                  onChange={(e: any) =>
                    setCampaign({ ...campaign, group: e.target.value })
                  }
                >
                  {scraperGroupData.length == 0 && groupLoading && (
                    <DropdownNotFound name={"group"} />
                  )}
                  {scraperGroupData.length == 0 && !groupLoading && (
                    <Box className="sync-align-justify-center full-height-width">
                      <CircularProgress />
                    </Box>
                  )}
                  {scraperGroupData?.map((item: any) => (
                    <MenuItem value={item?.id} key={item?.id}>
                      <Box className="sync-align-center">
                        <Box
                          component="img"
                          src={item?.profile_url}
                          style={{
                            height: "30px",
                            width: "30px",
                            marginRight: "5px",
                            borderRadius: "20px",
                          }}
                          loading="lazy"
                        />
                        <Typography sx={{ fontSize: "14px" }}>
                          {item?.group_name}
                        </Typography>
                      </Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            {/*----------------------------- Campaign Objective input ---------------------------------------*/}

            <Box
              sx={{
                width: { md: "221px", sm: "221px", xs: "100%" },
              }}
            >
              <FormControl fullWidth sx={{ mt: 8 }}>
                <InputLabel>
                  {autoSchedulerTitle.CAMPAIGN_OBJECTIVES + " *"}
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label={autoSchedulerTitle.CAMPAIGN_OBJECTIVES + " *"}
                  value={campaign?.objective}
                  onChange={(event: any) =>
                    setCampaign({ ...campaign, objective: event.target.value })
                  }
                >
                  <MenuItem value={CAMPAIGN_OBJECTIVE.TRAFFIC}>
                    {CAMPAIGN_OBJECTIVE.TRAFFIC}
                  </MenuItem>
                  <MenuItem value={CAMPAIGN_OBJECTIVE.CONVERSIONS}>
                    {CAMPAIGN_OBJECTIVE.CONVERSIONS}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>

            {/*----------------------------- Budget input -------------------------------------------------*/}

            <Box
              sx={{
                width: { md: "221px", sm: "221px", xs: "100%" },
              }}
            >
              <FormControl fullWidth sx={{ mt: 8 }}>
                <TextField
                  placeholder={autoSchedulerTitle.MIN_BUDGET}
                  type="number"
                  onChange={(e) => onBudgetChange(e)}
                  onBlur={handledBudget}
                  variant="outlined"
                  label={autoSchedulerTitle.MIN_BUDGET}
                  value={campaign?.budget}
                  inputProps={{ min: 20 }}
                />
              </FormControl>
            </Box>

            {/*----------------------------- Bid strategy input -------------------------------------------------*/}

            <Box sx={{ display: "flex", flexWrap: "wrap" }} columnGap={6}>
              <Box
                sx={{
                  mt: 8,
                  width: { md: "221px", sm: "221px", xs: "100%" },
                }}
              >
                <FormControl fullWidth>
                  <InputLabel>
                    {autoSchedulerTitle.BID_STRATEGY + " *"}
                  </InputLabel>
                  <Select
                    label={autoSchedulerTitle.BID_STRATEGY + " *"}
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={campaign?.bidStategy}
                    onChange={(event: any) =>
                      setCampaign({
                        ...campaign,
                        bidStategy: event.target.value,
                      })
                    }
                  >
                    <MenuItem value={BID_STRATEGY.LOWEST_COST}>
                      {autoSchedulerTitle.HIGHEST_VOLUME}
                    </MenuItem>
                    <MenuItem value={BID_STRATEGY.BID_CAP}>
                      {autoSchedulerTitle.BID_CAP}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>

              {/*----------------------------------- Bid Cap -----------------------------------------*/}
              {campaign?.bidStategy == BID_STRATEGY.BID_CAP && (
                <Grow in={true}>
                  <Box
                    sx={{
                      mt: 8,
                      width: { md: "221px", sm: "221px", xs: "100%" },
                    }}
                  >
                    <FormControl fullWidth>
                      <TextField
                        placeholder={autoSchedulerTitle.BID_COST}
                        label={autoSchedulerTitle.BID_COST}
                        value={campaign?.bidCost}
                        inputProps={{ min: 0 }}
                        onChange={(e) => onInputChange(e)}
                        onBlur={handledBudget}
                      />
                    </FormControl>
                  </Box>
                </Grow>
              )}
            </Box>

            {/*-------------------------- Select Country Dropdown ----------------------------------------*/}

            <Box sx={{ mt: 8 }}>
              <Autocomplete
                fullWidth
                multiple
                id="tags-outlined"
                options={countryList}
                freeSolo
                onChange={(event, newValue) =>
                  handledCountryChange(event, newValue)
                }
                value={campaign?.countries}
                getOptionLabel={(option: any) => _.startCase(option.Name)}
                disableCloseOnSelect
                filterSelectedOptions
                renderInput={(params: any) => (
                  <TextField
                    {...params}
                    placeholder={autoSchedulerTitle.SEARCH_COUTNRIES}
                    label={autoSchedulerTitle.COUNTRIES + " *"}
                    variant="outlined"
                    onChange={(e) => handleCountryChange(e.target.value)}
                  />
                )}
              />
              <Box>
                {error?.alreadyExist && (
                  <FormHelperText error id="password-error">
                    {error?.alreadyExist}
                  </FormHelperText>
                )}

                {error?.notMatch && (
                  <FormHelperText error id="password-error">
                    {error?.notMatch}
                  </FormHelperText>
                )}
              </Box>
            </Box>

            {/*-------------------------- Select Bundle countries Toggle ----------------------------------------*/}
            <Box className="sync-align-center" sx={{ mt: 4 }}>
              <Switch
                checked={
                  campaign?.countries?.length && campaign?.isBundleCountries
                    ? true
                    : false
                }
                onChange={(e: any) =>
                  setCampaign({
                    ...campaign,
                    isBundleCountries: e.target.checked,
                  })
                }
                disabled={!campaign?.countries?.length}
              />
              <Typography
                variant="subtitle1"
                sx={{ color: "rgba(76, 78, 100, 0.87)" }}
              >
                {autoSchedulerTitle.BUNDLE_COUNTRIES}
              </Typography>
            </Box>

            {/*-------------------------- Select Languages Toggle ----------------------------------------*/}
            <Box className="sync-align-center" sx={{ mt: 4 }}>
              <Switch
                checked={
                  campaign?.countries?.length && campaign?.isCustomLanguage
                    ? true
                    : false
                }
                onChange={(e: any) =>
                  setCampaign({
                    ...campaign,
                    isCustomLanguage: e.target.checked,
                  })
                }
                disabled={!campaign?.countries?.length}
              />
              <Typography
                variant="subtitle1"
                sx={{ color: "rgba(76, 78, 100, 0.87)" }}
              >
                {autoSchedulerTitle.SELECT_LANGUAGE}
              </Typography>
            </Box>

            {/*-------------------------- Select Languages Countrywise ----------------------------------------*/}

            {!campaign?.isBundleCountries &&
              campaign?.isCustomLanguage &&
              campaign?.countries?.map((item: any) => (
                <Grow in={true}>
                  <Box sx={{ width: "467px", mx: 3, mb: 5 }}>
                    <Box className="sync-align-center" sx={{ mb: 3 }}>
                      <Typography
                        variant="subtitle1"
                        sx={{ color: "rgba(76, 78, 100, 0.87)" }}
                      >
                        {autoSchedulerTitle.SELECT_LANGUAGES_FOR}
                        {item?.Name}
                      </Typography>
                    </Box>
                    <Autocomplete
                      multiple
                      id="tags-outlined"
                      options={language}
                      freeSolo
                      onChange={(event, newValue) =>
                        setCampaign({
                          ...campaign,
                          customLanguages: {
                            ...campaign?.customLanguages,
                            [item?.Code]: newValue,
                          },
                        })
                      }
                      value={campaign?.customLanguages[item?.Code]}
                      getOptionLabel={(option: any) => option.name}
                      disableCloseOnSelect
                      filterSelectedOptions
                      renderInput={(params: any) => (
                        <TextField
                          {...params}
                          placeholder={autoSchedulerTitle.SEARCH_LANGUAGES}
                          label={autoSchedulerTitle.LANGUAGES}
                          variant="outlined"
                          onChange={(e) => handleCountryChange(e.target.value)}
                        />
                      )}
                    />
                    <Box>
                      {error?.alreadyExist && (
                        <FormHelperText error id="password-error">
                          {error?.alreadyExist}
                        </FormHelperText>
                      )}

                      {error?.notMatch && (
                        <FormHelperText error id="password-error">
                          {error?.notMatch}
                        </FormHelperText>
                      )}
                    </Box>
                  </Box>
                </Grow>
              ))}

            {/*-------------------------- Select Languages for multiple countries ----------------------------------------*/}

            {campaign?.isBundleCountries && campaign?.isCustomLanguage && (
              <Grow in={true}>
                <Box sx={{ maxWidth: "467px", mt: 4 }}>
                  <Box className="sync-align-center" sx={{ ml: 4 }}>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: "rgba(76, 78, 100, 0.87)" }}
                    >
                      {autoSchedulerTitle.SELECT_LANGUAGES_FOR}
                      {campaign?.countries
                        ?.map((item: any) => {
                          return " " + item.Name;
                        })
                        .toString()}
                    </Typography>
                  </Box>
                  <Autocomplete
                    sx={{ maxWidth: "467px", mt: 4 }}
                    multiple
                    id="tags-outlined"
                    options={language}
                    freeSolo
                    // value={campaign?.customLanguages[campaign?.countries[0]?.Code]}
                    value={campaign?.bundlelanguages}
                    onChange={(event, newValue) =>
                      handledLanguageChange(newValue)
                    }
                    getOptionLabel={(option: any) => option.name}
                    disableCloseOnSelect
                    filterSelectedOptions
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        placeholder={autoSchedulerTitle.SEARCH_LANGUAGES}
                        label={autoSchedulerTitle.LANGUAGES}
                        variant="outlined"
                        onChange={(e) => handleCountryChange(e.target.value)}
                      />
                    )}
                  />
                  <Box>
                    {error?.alreadyExist && (
                      <FormHelperText error id="password-error">
                        {error?.alreadyExist}
                      </FormHelperText>
                    )}

                    {error?.notMatch && (
                      <FormHelperText error id="password-error">
                        {error?.notMatch}
                      </FormHelperText>
                    )}
                  </Box>
                </Box>
              </Grow>
            )}
          </Box>
          <Divider />

          <Box
            className="footer-button"
            sx={{ justifyContent: { xs: "center", sm: "space-between" } }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
              gap={2}
            >
              <Button
                size="large"
                type="submit"
                variant="outlined"
                color="secondary"
                onClick={onBackwordButtonClick}
                startIcon={<ArrowBackIcon />}
              >
                {autoSchedulerTitle.TIMESAVER}
              </Button>
              <Button
                size="large"
                type="submit"
                variant="contained"
                disabled={
                  !(
                    campaign?.objective?.length &&
                    campaign?.countries?.length &&
                    campaign?.bidStategy &&
                    ((campaign?.bidStategy === BID_STRATEGY.BID_CAP &&
                      campaign?.bidCost > 0) ||
                      campaign?.bidStategy !== BID_STRATEGY.BID_CAP)
                  )
                }
                onClick={onForwardButtonClick}
                endIcon={<ArrowForwardIcon />}
              >
                {autoSchedulerTitle.SETTINGS}
              </Button>
            </Box>
            <Button
              size="large"
              variant="outlined"
              onClick={onClearDraftClick}
              sx={{ mt: { xs: 3, sm: 0 } }}
              startIcon={<DeleteIcon />}
              disabled={isClearDraftEnabled}
            >
              {autoSchedulerTitle.CLEAR_DRAFT}
            </Button>
          </Box>

          <Snackbar
            open={open}
            autoHideDuration={3000}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "center",
            }}
          >
            <Alert onClose={handleClose} severity="info" sx={{ width: "100%" }}>
              <AlertTitle>{autoSchedulerSnackbar.BUDGET_BELOW_BID}</AlertTitle>
              {autoSchedulerSnackbar.UPDATED_BUDGET}
            </Alert>
          </Snackbar>
        </Card>
      </Grow>

      {/*------------------------------ dialogs ---------------------------------------------------------*/}

      <Dialog
        fullWidth
        open={dialogOpen}
        scroll="body"
        onClose={() => setDialogOpen(false)}
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "450px",
            },
          },
        }}
      >
        <DialogContent>
          <Typography variant="h5">{autoSchedulerTitle.ADD_COUNTRY}</Typography>
          <Divider></Divider>
          <Box sx={{ mt: 4 }}>
            <Box sx={{ display: "flex" }}>
              <Typography
                variant="subtitle1"
                sx={{ mb: 2, color: "rgba(76, 78, 100, 0.87)" }}
              >
                {autoSchedulerTitle.ADD_COUNTRY}
              </Typography>
              <Typography variant="subtitle2" sx={{ mt: 3, ml: 2 }}>
                {autoSchedulerTitle.BY_COMMA_SEPERATE}
              </Typography>
            </Box>
            <FormControl fullWidth>
              <TextField
                sx={{ mt: 2 }}
                type="text"
                value={addCountry}
                onChange={(e) => setAddCountry(e.target.value)}
              />
            </FormControl>
          </Box>

          <Box className="sync-justify-end" sx={{ mt: 6 }}>
            <Button variant="contained" onClick={handledAddCountry}>
              {autoSchedulerTitle.ADD_COUNTRY}
            </Button>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
}
