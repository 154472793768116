// ** MUI Imports
import {
  Box,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";

// ** Assets
import { PixelMelonLeaning2 } from "assets";

export default function SubmissionUploadedDialog(props: any) {
  const { isSubmissionCompleted, handleUploadContentClose, handleContinue } =
    props;

  return (
    <Dialog
      open={isSubmissionCompleted}
      onClose={handleUploadContentClose}
      aria-labelledby="user-view-edit"
      aria-describedby="user-view-edit-description"
    >
      <DialogTitle className="detail-dialog-title">
        Submission uploaded 🎉
      </DialogTitle>
      <DialogContent sx={{ pb: "25px" }}>
        <Typography variant="body2" className="dialog_content">
          Your submission is now viewable for the brand. You will receive a
          notification when the submission has been approved or declined.
        </Typography>

        <Box sx={{ display: "flex", justifyContent: "center", mb: "20px" }}>
          <Box
            className="submission-success-img"
            component="img"
            alt="Publish type image not found"
            src={PixelMelonLeaning2}
          />
        </Box>
        <Button
          variant="contained"
          className="continue-btn"
          onClick={handleContinue}
        >
          Continue
        </Button>
      </DialogContent>
    </Dialog>
  );
}
