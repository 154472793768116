import { Box, Switch, Typography } from "@mui/material";
import { ChangeEvent, useState, useEffect, useCallback } from "react";
import { DataGrid, GridColumns, GridRenderCellParams, GridSortModel } from '@mui/x-data-grid';
import CustomChip from '@core/components/mui/chip';
import CustomAvatar from '@core/components/mui/avatar';

// ** Utils Import
import { DataGridRowType } from "types/types";
import { getInitials } from "@core/utils/get-initials";
import { ThemeColor } from "@core/layouts/types";
import ServerSideToolbar from "views/table/ServerSideToolbar";

interface StatusObj {
  [key: number]: {
    title: string
    color: ThemeColor
  }
}

type SortType = 'asc' | 'desc' | undefined | null

// ** renders client column
const renderClient = (params: GridRenderCellParams) => {
  const { row } = params
  const stateNum = Math.floor(Math.random() * 6)
  const states = ['success', 'error', 'warning', 'info', 'primary', 'secondary']
  const color = states[stateNum]

  if (row.avatar.length) {
    return <CustomAvatar src={`/images/avatars/${row.avatar}`} sx={{ mr: 3, width: '1.875rem', height: '1.875rem' }} />
  } else {
    return (
      <CustomAvatar
        skin='light'
        color={color as ThemeColor}
        sx={{ mr: 3, fontSize: '.8rem', width: '1.875rem', height: '1.875rem' }}
      >
        {getInitials(row.campaign_name ? row.campaign_name : 'John Doe')}
      </CustomAvatar>
    )
  }
}

const statusObj: StatusObj = {
  1: { title: 'professional', color: 'primary' },
  2: { title: 'Optimized', color: 'success' },
  3: { title: 'Error', color: 'error' },
  4: { title: 'Not Available', color: 'secondary' },
}

const columns: GridColumns = [
  {
    flex: 0.25,
    minWidth: 400,
    field: 'campaign_name',
    headerName: 'ADSET NAME',
    filterable: true,
    renderCell: (params: GridRenderCellParams) => {
      const { row } = params

      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{background: row.color, height: '9px', width: '9px', borderRadius: '5px', mr: 3}}></Box>
          <Box className="sync-direction-column">
            <Typography noWrap variant='subtitle2' sx={{ color: 'text.primary'}}>
              {row.campaign_name}
            </Typography>
          </Box>
        </Box>
      )
    }
  },
  {
    flex: 0.150,
    minWidth: 120,
    headerName: 'STRATEGY',
    field: 'STRATEGY',
    renderCell: (params: GridRenderCellParams) => (
      <Typography variant='body2' sx={{ color: 'text.primary' }}>
        {params.row.profile}
      </Typography>
    )
  },
  {
    flex: 0.150,
    minWidth: 110,
    field: 'Auto optimize',
    headerName: 'Auto optimize',
    renderCell: (params: GridRenderCellParams) => {
      const { row } = params;
      return(
      <Box>
      <Switch defaultChecked={row.optimize ? true : false} />
      </Box>
    )}
  },
  
  {
    flex: 0.150,
    minWidth: 140,
    field: 'status',
    headerName: 'OPTIMIZATION STATUS',
    renderCell: (params: GridRenderCellParams) => {
      const status = statusObj[params.row.status]

      return (
        <CustomChip
          size='small'
          skin='light'
          color={status.color}
          label={status.title}
          sx={{ '& .MuiChip-label': { textTransform: 'capitalize' } }}
        />
      )
    }
  },
  {
    flex: 0.125,
    field: 'budget',
    minWidth: 80,
    editable: true,
    headerName: 'Budget',
    renderCell: (params: GridRenderCellParams) => (
      <Box sx={{ borderBottom: 'dashed 1.5px #0088cc' }}>
      <Typography variant='body2' sx={{ color: 'text.primary' }}>
        {params.row.budget}
      </Typography>
      </Box>
    )
  },
  {
    flex: 0.75,
    field: 'bid2',
    minWidth: 150,
    editable: true,
    headerName: 'Bid',
    renderCell: (params: GridRenderCellParams) => (
    <Box sx={{display: 'flex'}}>
      <Typography variant='body2' sx={{ color: 'text.primary' }}>
        {params.row.bid1}
      </Typography>
      <Box sx={{ borderBottom: 'dashed 1.5px #0088cc' }}>
      <Typography variant='body2' sx={{ color: 'text.primary' }}>
      {params.row.bid2}
      </Typography>
      </Box>
    </Box>
    )
  },
]

export default function AdsetTiktokTabs() {

  const rowsData = [
    {
      age: "21",
      avatar : "7.png",
      city :  "Łazy",
      email : "adew@etsy.com",
      experience : "7 Years",
      campaign_name :  "Lofi3 - Week 09 (2022) - Story - 2022babyw9-a",
      id : 31,
      post :  "Environmental Specialist",
      optimize : true,
      profile : "Lofi Fruits",
      status : 1,
      color: '#72E128',
      budget: '€500',
      bid1: '€0.22 -> ',
      bid2: '€0.23'
    },
     {
      age: "22",
     avatar : "7.png",
      city :  "Łazy",
      email : "adew@etsy.com",
      experience : "7 Years",
      campaign_name :  "Lofi4 - Week 09 (2022) - Story - 2022babyw9-a",
      id : 32,
      post :  "Environmental Specialist",
      optimize : false,
      profile : "Lofi Fruits",
      status : 2,
      color: 'primary.main',
      budget: '€500',
      bid1: '€0.22 -> ',
      bid2: '€0.23'
    },
      {
       age: "23",
       avatar : "7.png",
       city :  "Łazy",
       email : "adew@etsy.com",
       experience : "7 Years",
       campaign_name :  "Lofi - Week 09 (2022) - Story - 2022babyw9-a",
       id : 33,
       post :  "Environmental Specialist",
       optimize : true,
       profile : "Lofi Fruits",
       status : 3,
       color: '#72E128',
       budget: '€500',
       bid1: '€0.22 -> ',
        bid2: '€0.23'
      },
       {
        age: "24",
        avatar : "7.png",
        city :  "Łazy",
        email : "adew@etsy.com",
        experience : "7 Years",
        campaign_name :  "Lofi - Week 09 (2022) - Story - 2022babyw9-a",
        id : 34,
        post :  "Environmental Specialist",
        optimize : false,
        profile : "Lofi Fruits",
        status : 4,
        color: '#72E128',
        budget: '€500',
        bid1: '€0.22 -> ',
        bid2: '€0.23'
      },
        {
         age: "25",
         avatar : "7.png",
         city :  "Łazy",
         email : "adew@etsy.com",
         experience : "7 Years",
         campaign_name :  "Lofi - Week 09 (2022) - Story - 2022babyw9-a",
         id : 35,
         post :  "Environmental Specialist",
         optimize : false,
         profile : "Lofi Fruits",
         status : 4,
         color: 'primary.main',
         budget: '€500',
         bid1: '€0.22 -> ',
         bid2: '€0.23'
        },
         {
          age: "26",
          avatar : "7.png",
          city :  "Łazy",
          email : "adew@etsy.com",
          experience : "7 Years",
          campaign_name :  "Lofi - Week 09 (2022) - Story - 2022babyw9-a",
          id : 36,
          post :  "Environmental Specialist",
          optimize : false,
          profile : "Lofi Fruits",
          status : 3,
          color: 'primary.main',
          budget: '€500',
          bid1: '€0.22 -> ',
          bid2: '€0.23'
        },
          {
           age: "27",
           avatar : "7.png",
           city :  "Łazy",
           email : "adew@etsy.com",
           experience : "7 Years",
           campaign_name :  "Lofi - Week 09 (2022) - Story - 2022babyw9-a",
           id : 37,
           post :  "Environmental Specialist",
           optimize : false,
           profile : "Lofi Fruits",
           status : 2,
           color: 'primary.main',
           budget: '€500',
           bid1: '€0.22 -> ',
           bid2: '€0.23'
          },
           {
            age: "28",
            avatar : "7.png",
            city :  "Łazy",
            email : "adew@etsy.com",
            experience : "7 Years",
            campaign_name :  "Lofi - Week 09 (2022) - Story - 2022babyw9-a",
            id : 38,
            post :  "Environmental Specialist",
            optimize : true,
            profile : "Lofi Fruits",
            status : 1,
            color: 'primary.main',
            budget: '€500',
            bid1: '€0.22 -> ',
            bid2: '€0.23'
          },
  ]

  function loadServerRows(currentPage: number, data: DataGridRowType[]) {
    return data.slice(currentPage * pageSize, (currentPage + 1) * pageSize)
  }

  const [page, setPage] = useState(0)
  const [total, setTotal] = useState<number>(0)
  const [sort, setSort] = useState<SortType>('asc')
  const [pageSize, setPageSize] = useState<number>(7)
  const [rows, setRows] = useState<DataGridRowType[]>([])
  const [searchValue, setSearchValue] = useState<string>('')
  const [sortColumn, setSortColumn] = useState<string>('campaign_name')
  const [data] = useState<DataGridRowType[]>(rowsData)
  const [filteredData, setFilteredData] = useState<DataGridRowType[]>([])

  const fetchTableData = useCallback(
    async (sort: SortType, q: string, column: string) => {
          setTotal(rowsData.length);
          setRows(loadServerRows(page, rowsData));
    },
    [page, pageSize]
  )

  useEffect(() => {
    fetchTableData(sort, searchValue, sortColumn)
  }, [fetchTableData, searchValue, sort, sortColumn])

  const handleSortModel = (newModel: GridSortModel) => {
    if (newModel.length) {
      setSort(newModel[0].sort)
      setSortColumn(newModel[0].field)
      fetchTableData(newModel[0].sort, searchValue, newModel[0].field)
    } else {
      setSort('asc')
      setSortColumn('campaign_name')
    }
  }

  const escapeRegExp = (value: string) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&')
  }

  const handleSearch = (searchValue: string) => {
    setSearchValue(searchValue)
    const searchRegex = new RegExp(escapeRegExp(searchValue), 'i')
    const filteredRows = data.filter(row => {
      return Object.keys(row)?.some(field => {
        // @ts-ignore
        return searchRegex.test(row[field].toString())
      })
    })
    if (searchValue.length) {
      setFilteredData(filteredRows)
    } else {
      setFilteredData([])
    }
  }

  return (
    <>
      <Box sx={{position:{md: 'relative'}}}>
      <DataGrid
        autoHeight
        pagination
        rows={rows.length ? rows : data}
        rowCount={total}
        columns={columns}
        checkboxSelection
        pageSize={pageSize}
        sortingMode='server'
        paginationMode='server'
        onSortModelChange={handleSortModel}
        rowsPerPageOptions={[7, 10, 25, 50]}
        onPageChange={newPage => setPage(newPage)}
        components={{ Toolbar: ServerSideToolbar }}
        onPageSizeChange={newPageSize => setPageSize(newPageSize)}
        componentsProps={{
          toolbar: {
            value: searchValue,
            clearSearch: () => handleSearch(''),
            onChange: (event: ChangeEvent<HTMLInputElement>) => handleSearch(event.target.value)
          }
        }}
      />
      </Box>
      </>
  );
}
