import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";
import { getJobBrandData, getJobCategoryData, publishContentTypeData, getAllJob, getJobDetail,getFilteredJobCategoryData, getMyJobs, getArchivedJobs, getHistory, setMySubmissionList, setMySubmissionHistoryList, setTempMySubmissionHistoryList, setSubmissionList, setVideoData, setObjectKey, setDraftData, setLoading, setNewSubmissionList, setMyNewSubmissionList, getNewHistory, setMyNewSubmissionHistoryList, getNewFilteredJobCategoryData, setDownloadVideoInfo, setShowDownloader } from "../Slice/CreateJobSlice";
import { notificationSuccess, notificationFail } from "../Slice/notificationSlice";
import { STATUS } from '@core/constants/enum';
import {historyTabTitle} from "@core/constants/global-constants";

interface CreateNewJobParams {
    formData: any;
}

interface GetSubmissionsParams {
    selected_status: STATUS;
    published_content_type: string;
}


export const publishContentTypeCreateJob = createAsyncThunk(
    "publishContentTypeCreateJob",
    async (publishContentType: any, { dispatch }: any) => {
        dispatch(publishContentTypeData(publishContentType));
    }
);

export const getJobCategory = createAsyncThunk(
    "getJobCategory",
    async ({navigate}: any ,{dispatch}) => {
        try {
            const response = await API.get(
                `api/v1/marketplace/job-category/`
            )
            dispatch(getJobCategoryData(response.data));
            return response.data;
        } catch (err: any) {
            return err;
        }
    }
);

export const getBrandInfo = createAsyncThunk(
    "getBrandInfo",
    async ({navigate}: any ,{dispatch}) => {
        try {
            const response = await API.get(
                `api/v1/marketplace/brand-profile?flag=my_brand`
            )
            dispatch(getJobBrandData(response.data));
            return response;
        } catch (err: any) {
            return err;
        }
    }
);

export const getAllJobsCreateContent = createAsyncThunk(
    "getAllJobsCreateContent",
    async ({data}: any, {dispatch}) => {
        let { published_content_type } = data;
        let url = `/api/v1/marketplace/job/`;

        dispatch(setLoading(true));
        if(published_content_type !== "collect_both") {
            url = url.concat(`?publish_content_type=${published_content_type}`);
        }

        try {
            const response = await API.get(url)
            dispatch(getAllJob(response.data.data));
            return response;
        } catch (error: any) {
            throw error;
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const getFilteredJobs = createAsyncThunk(
    "getFilteredJobs",
    async ({data}: any ,{dispatch}) => {
        let { published_content_type, category_type, sortByCategoryList } = data;
        let tempArray = sortByCategoryList;
        let url = `/api/v1/marketplace/job/?`;
        if(published_content_type !== "collect_both") {
            url = url.concat(`publish_content_type=${published_content_type}`);
        }

        if(category_type !== 0) {
            url = url.concat(`&category=${category_type}`)
        }

        try {
            const response = await API.get(url)
            if(response && response?.data?.data && response?.data?.data?.length > 0){
                tempArray = [...response?.data?.data]
            }
            if(category_type === 0) {
               dispatch(getNewFilteredJobCategoryData(tempArray));
           }
            dispatch(getFilteredJobCategoryData(response.data.data));
            return response;
        } catch (err: any) {
            return err;
        }
    }
);

export const getMyJobsRequestContent = createAsyncThunk(
    "getMyJobs",
    async (data: any ,{dispatch}) => {
        const {myArchivedJobList} = data
        let tempArray = myArchivedJobList
        try {
            dispatch(setLoading(true));
            let url = `/api/v1/marketplace/my-job/?flag=${data.flag}`;

            if(data.flag === "closed") {
                url = url.concat(`&page=${data.page}`);
            }

            const response = await API.get(url)
            if(response && response?.data && response?.data?.length > 0){
                tempArray = [...response.data]
            }

            if(data.flag === "active") {
            dispatch(getMyJobs(response.data));
            } else dispatch(getArchivedJobs(tempArray));
            dispatch(setLoading(false));
            return tempArray;
        } catch (err: any) {
            dispatch(setLoading(false));
            return err;
        }
    }
);

export const getAllJobsDetail = createAsyncThunk(
    "getJobDetail",
    async ({data}: any, {dispatch}) => {

        try {
            dispatch(setLoading(true));
            const response = await API.get(
                `/api/v1/marketplace/job/${data.id}`
            )
            dispatch(getJobDetail(response.data));
            return response;
        } catch (err: any) {
            throw err;
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const submitJobSubmission = createAsyncThunk(
    "getJobDetail",
    async ({formData, id, isResubmit}: any ,{dispatch}) => {
        let data = {id: id};

        try {
            const response: any = await API.post(
                `/api/v1/marketplace/submission/?resubmit=${isResubmit}`, formData
            )

            dispatch(getAllJobsDetail({ data }));
            return response;
        } catch (err: any) {
            dispatch(notificationFail(err?.data.message));
            return err;
        }
    }
);

export const getMySubmissions = createAsyncThunk(
    "getSubmissionList",
    async (data: any,{dispatch}: any) => {
        let response : any = null;
        let tempType = null;

        let tempArray = data.mySubmissionList;
        tempType = (data.approval_status).toLowerCase();
        try {
            dispatch(setLoading(true));
            let url =  `/api/v1/marketplace/my-submission/?page=${data.page}`;
            if (data.published_content_type !== "collect_both") {
                url = url.concat(`&publish_content_type=${data.published_content_type}`)
            }
            if(tempType !== STATUS.ALL) {
                if (data.published_content_type !== "collect_both") {
                    url = url.concat("&")
                }
                url = url.concat(`&approval_status=${tempType}`)
            }
            response = await API.get(url)
            if(response && response?.data && response?.data?.length > 0) {
                tempArray = [ ...response.data];
            }
            dispatch(setMySubmissionList(tempArray));
            if(tempType === STATUS.ALL) {
                dispatch(setMyNewSubmissionList(tempArray));
            }
            dispatch(setLoading(false));
            return tempArray;
        } catch (err: any) {
            dispatch(setLoading(false));
            return err;
        }
    }
);

export const getMySubmissionsHistory = createAsyncThunk(
    "getMySubmissionsHistory",
    async (data: any, {dispatch}) => {
        const { published_content_type, approval_status, mySubmissionList, page } = data;
        let response : any = null;
        let tempType = null;
        tempType = (approval_status).toLowerCase();
        let tempArray = mySubmissionList;
        try {
            dispatch(setLoading(true));
            let url =  `/api/v1/marketplace/my-submission/?approval_status=${tempType}&page=${page}`;
            if (published_content_type !== "collect_both") {
                url = url.concat(`&publish_content_type=${published_content_type}`)
            }
            response = await API.get(url);
            if(response && response?.data && response?.data?.length > 0) {
                tempArray = [...response.data];
            }
            if(tempType === STATUS.APPROVED) {
               dispatch(setMyNewSubmissionHistoryList(tempArray));
           }
            dispatch(setMySubmissionHistoryList(tempArray));
            dispatch(setLoading(false));
            return tempArray;
        }
        catch (error: any) {
            return error;
        } finally {
            dispatch(setLoading(false));
          }
    }
);

export const getMySubmissionsSearch = createAsyncThunk(
    "getMySubmissionSearchList",
    async ({data}: any, {dispatch}) => {
        let { approval_status, published_content_type, searchTxt } = data;
        let tempType = (approval_status).toLowerCase();
        let url =  `/api/v1/marketplace/my-submission/?page=1&approval_status=${tempType}`;

        if(searchTxt) {
            url =  url.concat(`&search=${searchTxt}`);
        } 
        if (published_content_type !== "collect_both") {
                url = url.concat(`&publish_content_type=${published_content_type}`)
            
        }

        dispatch(setTempMySubmissionHistoryList([]));

        try {
            const response = await API.get(url);
            dispatch(setTempMySubmissionHistoryList(response.data));

            return response;
        }
        catch (error: any) {
            return error;
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const getSubmissions = createAsyncThunk(
    "getSubmissionList",
    async (data: GetSubmissionsParams, {dispatch}) => {
        const { selected_status, published_content_type } = data;


        let url = `api/v1/marketplace/submission/?search=${published_content_type}`;

        if(selected_status !== STATUS.ALL) {
            url = url.concat(`&status=${selected_status}`);
        }

        try {
            dispatch(setLoading(true));
            const response = await API.get(url)
            dispatch(setSubmissionList(response.data));
            if(selected_status === STATUS.ALL) {
                dispatch(setNewSubmissionList(response.data))
            }
            return response;
        } catch (error: any) {
            throw error;
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const getSubmissionHistory = createAsyncThunk(
    "getHistory",
    async ({selected, dispatch, selectedPublishType}: any) => {
        let temp = selected.toLowerCase();
        let url = `api/v1/marketplace/my-past-submission/?approval_status=${temp}&job_id=${selectedPublishType}`;
        try {
            let response = await API.get(url);
            dispatch(getHistory(response.data));
            if(temp === historyTabTitle[0].name){
            dispatch(getNewHistory(response.data));
            }
            return response;
        } catch (error: any) {
            throw error;
        }
    }
);

export const downloadAll = createAsyncThunk(
    "getBrandInfo",
    async ({dispatch} :any) => {
        try {
            dispatch(setShowDownloader(true))
            const response = await API.get(
                `api/v1/marketplace/download-submission-video/`, {
                    responseType: 'blob',
                    onDownloadProgress: (progressEvent: any) => {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        dispatch(setDownloadVideoInfo({
                            fileSize: progressEvent.total,
                            percentage: percentCompleted,
                        }))
                    },
                }
            )
            const url = window.URL.createObjectURL(new Blob([response?.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'videos.zip'); // or any other extension
            document.body.appendChild(link);
            link.click();
            return response
        } catch (err: any) {
            return err;
        }
    }
);

export const createNewJob = createAsyncThunk(
    "createJob",
    async ({formData}: CreateNewJobParams ,{dispatch}) => {

        try {
            dispatch(setLoading(true));
            const response = await API.post(
                `/api/v1/marketplace/job/`, formData
            )
            // dispatch(notificationSuccess(response?.data.message));
            return response;
        } catch (error: any) {
            dispatch(notificationFail(error?.message));
            throw error;
        }
        finally {
            dispatch(setLoading(false));
        }
    }
);


export const submitPostLink = createAsyncThunk(
    "submitLink",
    async ({data, submission_id, formData}: any ,{dispatch}) => {

        try {
            dispatch(setLoading(true));
            const response = await API.put(
                `/api/v1/marketplace/submission/${submission_id}/`, formData
            )
            dispatch(getMySubmissions(data));

            return response;
        } catch (error: any) {
            dispatch(notificationFail(error.data.message));
            throw error;
        } finally {
            dispatch(setLoading(false));
          }
    }
);

export const getVideoOtp = createAsyncThunk(
    "getVideoOTP",
    async ({formData}: any ,{dispatch}) => {

        try {
            const response = await API.post(
                `api/v1/marketplace/video-otp/`, formData
            )

            if(response.data.error) {
                dispatch(notificationFail(response?.data.message));
            } else {
                dispatch(setVideoData(response.data));
                // dispatch(notificationSuccess(response?.data.message));
            }
            return response;
        } catch (err: any) {
            dispatch(notificationFail(err?.data.message));
            return err;
        }
    }
);

export const getMyJobSearch = createAsyncThunk(
    "getMySubmissionSearchList",
    async ({data}: any, {dispatch}) => {
        let { searchTxt} = data;

        let url =  `/api/v1/marketplace/my-job/?`;

        if(searchTxt) {
            url =  url.concat(`search=${searchTxt}`);
        } else {
            url = url.concat(`flag=closed`);
        }

        try {
            const response = await API.get(url);
            dispatch(getArchivedJobs(response.data));

            return response;
        }
        catch (error: any) {
            return error;
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const draftData = createAsyncThunk(
    "setDraftData",
    async ({data}: any, {dispatch}) => {
        let url =  `/api/v1/marketplace/draft/`;


        try {
            let response = null;

            if(data) response = await API.post(url, data);
            else {
                response = await API.get(url);
                dispatch(setDraftData(response.data));
            }

            return response;
        }
        catch (error: any) {
            return error;
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const closeJob = createAsyncThunk(
    "closeJob",
    async ({id}: any ,{dispatch}) => {

        try {
            dispatch(setLoading(true));
            const response = await API.put(
                `api/v1/marketplace/update-job-status/${id}/`
            )

            dispatch(notificationSuccess(response?.data.message));

            return response;
        } catch (error: any) {
            dispatch(notificationFail(error.data.message));
            throw error;
        } finally {
            dispatch(setLoading(false));
          }
    }
);

export const setPresignedUrlAPI = createAsyncThunk(
    "setSignedUrl",
    async ({data, file}: any, {dispatch}) => {
        let url =  `/api/v1/marketplace/presigned-url/`;

        try {
            let response = null;

            response = await API.post(url, data).then(async (res: any) => {
                const responseData = res.data.data;

                if(!res.error) {
                    dispatch(setObjectKey(responseData.objectKey))
                }

            });

            return response;
        }
        catch (error: any) {
            return error;
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const clearDraft = createAsyncThunk(
    "setSignedUrl",
    async ({id}: any, {dispatch}) => {
        let url =  `/api/v1/marketplace/draft/${id}`;

        dispatch(setLoading(true));

        try {
            let response = null;

            response = await API.delete(url);


            return response;
        }
        catch (error: any) {
            return error;
        } finally {
            dispatch(setLoading(false));
        }
    }
);

export const getSubmissionListUsingId = createAsyncThunk(
    "submissionListUsingId",
    async ({id, dispatch}: any) => {


        try {
            const response = await API.get(
                `/api/v1/marketplace/submission/?job_id=${id}`
            );


            dispatch(setSubmissionList(response.data));
            return response;
        } catch (err: any) {
            dispatch(notificationFail(err?.message));
            return err;
        }
    },
);



