// ** React
import { useState } from "react";

// ** Mui Imports
import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  TextField,
  Typography,
} from "@mui/material";

// ** Custom Component
import { MEDIA_PLANNER } from "@core/constants/title-constants";
import { BATCH_STATUS, BID_STRATEGY } from "@core/constants/enum";

// ** Mui Icons
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

interface Props {
  state: any;
  setState: any;
  setPresetDialogOpen: any;
  onOverWriteChange: any;
  isPresetsOverwrite: any;
}

export default function ChangePresetsDialog({
  state,
  setState,
  setPresetDialogOpen,
  onOverWriteChange,
  isPresetsOverwrite,
}: Props) {
  let autoSchedulerTitle = MEDIA_PLANNER.auto_scheduler;

  const [isPresets, setIsPresets] = useState("overwrite");

  return (
    <Box sx={{ margin: 2 }}>
      <Typography variant="h5" sx={{ fontWeight: 600 }}>
        {autoSchedulerTitle.UNSAVED_CHANGES}
      </Typography>
      <Typography variant="subtitle2" sx={{ mt: 3 }}>
        {autoSchedulerTitle.YOU_HAVE_MADE_CHANGES}
      </Typography>
      <Typography variant="subtitle2">
        {" "}
        <FiberManualRecordIcon sx={{ fontSize: "8px" }} /> Countries
      </Typography>
      <Typography variant="subtitle2">
        {" "}
        <FiberManualRecordIcon sx={{ fontSize: "8px" }} /> Bid cost
      </Typography>
      <Typography variant="subtitle2" sx={{ mt: 3 }}>
        {autoSchedulerTitle.SELECT_WHICH_PRESET + " Conversions-1"}{" "}
      </Typography>
      <FormControl sx={{ margin: 3 }}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          defaultValue="female"
          name="radio-buttons-group"
          value={isPresetsOverwrite}
          // onChange={(event: any) => }
          onChange={(event: any) => {
            console.log("eeeeeee", event.target.value);
            onOverWriteChange(event);
            // setIsPresets(event.target.value);
          }}
        >
          <FormControlLabel
            value="overwrite"
            control={<Radio />}
            label="Overwrite this preset"
          />
          <FormControlLabel
            value="new"
            control={<Radio />}
            label="Create new preset from changes"
          />
          {isPresetsOverwrite == "new" && (
            <FormHelperText>
              <TextField
                variant="outlined"
                label={autoSchedulerTitle.PRESET_NAME}
                value={state?.changePresetName}
                placeholder={autoSchedulerTitle.PRESET_NAME}
                onChange={(e: any) =>
                  setState({ ...state, changePresetName: e.target.value })
                }
              />
            </FormHelperText>
          )}
          <FormControlLabel
            value="nothing"
            control={<Radio />}
            label="Do nothing"
          />
        </RadioGroup>
      </FormControl>
      <Button
        size="large"
        type="submit"
        variant="outlined"
        sx={{ mb: 4, width: "48%", margin: "1%" }}
        color="secondary"
        fullWidth
        onClick={() => setPresetDialogOpen(false)}
      >
        {autoSchedulerTitle.CANCEL}
      </Button>
      <Button
        size="large"
        type="submit"
        variant="contained"
        sx={{ mb: 4, width: "48%", margin: "1%" }}
        fullWidth
        onClick={() => setPresetDialogOpen(false)}
      >
        {autoSchedulerTitle.CONTINUE}
      </Button>
    </Box>
  );
}
