import { useNavigate } from "react-router-dom";
import { Box, Button, Grow, LinearProgress, Typography } from "@mui/material";
import { route_info } from "navigation/route";
import { PLATFORMS } from "@core/constants/enum";
import ContentTypeCard from "components/ContentTypeCard";
import {
  Tiktok,
  Snapchat,
  Instagram,
  BlankImage,
  Vertical,
  Horizontal,
  Square,
} from "assets";

export default function MyJobsCard(props: any) {
  const { cardData, style } = props;
  const navigate = useNavigate();
  let hover_search = require("../../assets/all-jobs/hover_search.svg");

  return (
    <Grow in={true}>
      <Box style={style} className="list-card-block">
        {/* Job image part */}
        <Box
          onClick={() =>
            navigate(`${route_info.market_place.my_job_detail}/${cardData.id}`)
          }
          sx={{
            position: "relative",
            width: "100%",
            height: "162px",
            cursor: "pointer",
          }}
        >
          <Box
            className="image-wrapper"
            sx={{
              "&:hover": {
                "&::before": {
                  content: `url(${hover_search})`,
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  zIndex: "2",
                },
                "&::after": {
                  content: "''",
                  position: "absolute",
                  top: "0",
                  left: "0",
                  width: "100%",
                  height: "100%",
                  background:
                    "linear-gradient(183.61deg, rgba(0, 0, 0, 0.4) 2.97%, rgba(0, 0, 0, 0.33) 108.85%)",
                  zIndex: "1",
                },
              },
            }}
          >
            <Box
              sx={{
                height: "42px",
                width: "42px",
                borderRadius: "100px",
                background: "#fff",
                p: "5px",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <ContentTypeCard contentType={cardData.publish_content_type} />
            </Box>
            <Box>
              <p className="list-card-title">{cardData.title}</p>
              <Typography variant="caption" color="#fff">
                {(cardData && cardData?.brand_details?.brand_name) || ""}
              </Typography>
            </Box>
          </Box>

          <Box
            component="img"
            className="list-card-image"
            src={cardData.thumbnails_url}
          />
        </Box>
        {/* Job content part */}
        <Box my={4} mx={2}>
          <Box px={2}>
            <Box display="flex">
              <Box
                sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}
              >
                <Typography sx={{ fontWeight: "400", fontSize: "14px" }}>
                  Submissions
                </Typography>
                <Typography sx={{ fontWeight: "700", fontSize: "16px" }}>
                  {cardData.submission_count}
                </Typography>
              </Box>
              <Box
                sx={{ display: "flex", flexGrow: 1, flexDirection: "column" }}
              >
                <Typography sx={{ fontWeight: "400", fontSize: "14px" }}>
                  Views
                </Typography>
                <Typography sx={{ fontWeight: "700", fontSize: "16px" }}>
                  {cardData.view_count}
                </Typography>
              </Box>
            </Box>
            <Box mt={4}>
              <Typography sx={{ fontWeight: "400", fontSize: "14px" }}>
                Spend
              </Typography>
              <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                my={1}
              >
                <Typography sx={{ fontWeight: "700", fontSize: "16px" }}>
                  {"$" + cardData.budget_value.spent}
                </Typography>
                <Typography sx={{ fontWeight: "700", fontSize: "16px" }}>
                  {"$" + cardData.budget}
                </Typography>
              </Box>
              <LinearProgress
                variant="determinate"
                value={cardData.budget_value.percentage}
                color="success"
              />
              <Typography sx={{ fontWeight: "400", fontSize: "12px", mt: 1 }}>
                {cardData.budget_value.percentage + "% of total budget"}
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                mt: 2,
              }}
            >
              <Box
                component="img"
                src={
                  cardData.platforms
                    ? cardData.platforms === PLATFORMS.INSTAGRAM
                      ? Instagram
                      : cardData.platforms === PLATFORMS.TIKTOK
                      ? Tiktok
                      : cardData.platforms === PLATFORMS.SNAPCHAT
                      ? Snapchat
                      : BlankImage
                    : cardData.dimension === "Vertical"
                    ? Vertical
                    : cardData.dimension === "Horizontal"
                    ? Horizontal
                    : cardData.dimension === "Square"
                    ? Square
                    : BlankImage
                }
              />
              <Typography sx={{ fontWeight: "700", fontSize: "20px" }}>
                {cardData.selected_budget}
              </Typography>
            </Box>
            <Box mt={2}>
              <Button
                sx={useStyles.button}
                fullWidth
                variant="text"
                onClick={() =>
                  navigate(route_info.market_place.submissions, {
                    state: {
                      job: { id: cardData.id, title: cardData.title },
                      isSubmission: false,
                    },
                  })
                }
              >
                View submission
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    </Grow>
  );
}

const useStyles = {
  button: {
    "&:hover": {
      backgroundColor: "#fff !important",
      color: "#000aff !important",
    },
  },
};
