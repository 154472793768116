// ** MUI Imports
import { Box, Typography, useTheme } from "@mui/material";

// ** Custom Components
import { STATUS, TITLE } from "@core/constants/enum";

// ** Icons
import IconButton from "@mui/material/IconButton";
import DownloadOutline from "mdi-material-ui/DownloadOutline";

// ** Assets
import { Pending, Approve, Decline, BlankImage } from "assets";

export default function SubmissionStatusHistoryCard(props: any) {
  const { status, onClickDownload } = props;
  const theme = useTheme();
  return (
    <Box className="submission-status-block">
      <Box
        className="status-img"
        component="img"
        src={
          status === STATUS.APPROVAL_NEEDED ||
          status === STATUS.CREATOR_POST_PENDING ||
          status === STATUS.POST_CONFIRMATION_PENDING
            ? Pending
            : status === STATUS.ACCEPTED
            ? Approve
            : status === STATUS.DECLINED
            ? Decline
            : BlankImage
        }
      />
      <Typography
        variant="body2"
        sx={{
          color: theme.palette.customColors.white,
        }}
      >
        {status == STATUS.ACCEPTED ? TITLE.APPROVED : TITLE.DECLINED}
        {/* {status !== STATUS.DECLINED
          ? "Submission " +
            (status === STATUS.APPROVAL_NEEDED ||
            status === STATUS.CREATOR_POST_PENDING ||
            status === STATUS.POST_CONFIRMATION_PENDING
              ? TITLE.PENDING
              : status === STATUS.ACCEPTED
              ? TITLE.APPROVED
              : TITLE.DECLINED)
          : "Post Disapproved"} */}
      </Typography>
      <Typography
        sx={{
          marginLeft: "auto",
          display: "flex",
          mr: "10px",
          alignContent: "center",
        }}
      >
        <IconButton
          onClick={() => window.open(onClickDownload, "_blank")}
          sx={{ color: theme.palette.customColors.white }}
        >
          <DownloadOutline />
        </IconButton>
      </Typography>
    </Box>
  );
}
