// ** MUI Imports
import Box from "@mui/material/Box";
import Grid, { GridProps } from "@mui/material/Grid";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import { IconProps } from "@mui/material";
import { ThemeColor } from "@core/layouts/types";
import TiktokIcon from "icon/tiktok";
import FacebookIcon from "icon/facebook";
import SnapchatIcon from "icon/snapchat";

export type CustomCheckboxIconsProps = {
  name: string;
  icon?: any;
  color?: ThemeColor;
  selected: string[];
  gridProps: GridProps;
  data?: any;
  iconProps?: Omit<IconProps, "icon">;
  handleChange: (e: any, value: string) => void;
};

const CustomCheckboxIcons = (props: CustomCheckboxIconsProps) => {
  // ** Props
  const {
    data,
    icon,
    name,
    selected,
    gridProps,
    iconProps,
    handleChange,
    color = "primary",
  } = props;

  const { title, value } = data;

  const renderComponent = () => {
    return (
      <Grid item {...gridProps}>
        <Box
          onClick={(e) =>
            handleChange(selected.includes(value) ? false : true, value)
          }
          sx={{
            height: "152px",
            display: "flex",
            borderRadius: 1,
            cursor: "pointer",
            width: "147px",
            position: "relative",
            alignItems: "center",
            flexDirection: "column",
            padding: "20px",
            border: (theme) => `1px solid ${theme.palette.divider}`,
            ...(selected.includes(value)
              ? { borderColor: `${color}.main` }
              : {
                  "&:hover": {
                    borderColor: (theme) =>
                      `rgba(${theme.palette.customColors.main}, 0.25)`,
                  },
                }),
          }}
        >
          {title === "Tiktok" ? (
            <TiktokIcon active={true} />
          ) : title === "Meta" ? (
            <FacebookIcon active={true} />
          ) : (
            <SnapchatIcon active={true} />
          )}

          {title ? (
            typeof title === "string" ? (
              <Typography
                sx={{
                  color: "rgba(76, 78, 100, 0.68)",
                  fontWeight: 500,
                  my: "auto",
                }}
              >
                {title}
              </Typography>
            ) : (
              title
            )
          ) : null}

          <Checkbox
            size="small"
            color={color}
            name={`${name}-${value}`}
            checked={selected.includes(value)}
            sx={{ mb: -2, ...(!icon && !title && { mt: -2 }) }}
          />
        </Box>
      </Grid>
    );
  };

  return data ? renderComponent() : null;
};

export default CustomCheckboxIcons;
