// ** MUI Imports
import { Box, Grid, Typography } from "@mui/material";

// ** Third Party Library
import { useNavigate } from "react-router-dom";

// ** Custom Components
import { route_info } from "navigation/route";

// ** Assets
import { CreatorMarketplace, MediaLibrary, MediaPlanner } from "assets";

export default function Marketplace() {
  const navigate = useNavigate();

  const redirectToPage = (redirectURL: string) => {
    navigate(redirectURL);
  };
  return (
    <Box sx={{ pl: "15px" }}>
      <Typography variant="h5" sx={{ mb: 3 }}>
        App overview
      </Typography>

      <Grid container rowSpacing={4}>
        <Grid item md={7} sm={7} xs={12}>
          <Box
            className="market-place-block"
            onClick={() => redirectToPage(route_info.market_planner.dashboard)}
          >
            <Box
              sx={{
                p: 4,
                pr: "20px",
                borderRight: 1,
                borderColor: "rgba(76, 78, 100, 0.12)",
              }}
            >
              <Box
                className="app-type-img"
                component="img"
                alt="App type image not found"
                src={MediaPlanner}
              />
            </Box>
            <Box sx={{ p: 4 }}>
              <Typography
                variant="body2"
                sx={{
                  color: "text.primary",
                  fontWeight: 500,
                  fontSize: 20,
                  mb: "5px",
                }}
              >
                Media planner
              </Typography>
              <Typography variant="body2">
                Connect your ad accounts and schedule the same ads across
                multiple platforms in one go.
              </Typography>
            </Box>
          </Box>
        </Grid>

        {/* Uncomment these lines while Marketplace and Media Library features needed */}

         <Grid item md={7} sm={7} xs={12}>
          <Box
            className="market-place-block"
            onClick={() =>
              redirectToPage(route_info.market_place.creator_marketplace)
            }
          >
            <Box
              sx={{ p: 4, pr: "20px", borderRight: 1, borderColor: "rgba(76, 78, 100, 0.12)" }}
            >
              <img width={70} height={70} src={CreatorMarketplace} />
            </Box>
            <Box sx={{ p: 4 }}>
              <Typography
                variant="body2"
                sx={{ color: "text.primary", fontWeight: 500, fontSize: 20, mb: "5px" }}
              >
                Creator marketplace
              </Typography>
              <Typography variant="body2">
                Where content makers and brands from across the world meet to
                create meaningful content together.
              </Typography>
            </Box>
          </Box>
        </Grid>

        {/* <Grid item md={7} sm={7} xs={12}>
          <Box
            className="market-place-block"
            onClick={() => console.log("OnCLick on Media Planner")}
          >
            <Box
              sx={{ p: 4, pr: "20px", borderRight: 1, borderColor: "rgba(76, 78, 100, 0.12)" }}
            >
              <img width={70} height={70} src={MediaLibrary} />
            </Box>
            <Box sx={{ p: 4 }}>
              <Typography
                variant="body2"
                sx={{ color: "text.primary", fontWeight: 500, fontSize: 20, mb: "5px" }}
              >
                Media library
              </Typography>
              <Typography variant="body2">
                Upload files from your device and view your approved content
                from the creator marketplace.
              </Typography>
            </Box>
          </Box>
        </Grid>  */}
      </Grid>
    </Box>
  );
}
