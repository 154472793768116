import { Box, Typography, useTheme } from "@mui/material";

export default function StatusIcon(props: any) {
  const { statusIcon, statusTitle, handleStatusClick } = props;
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        cursor: "pointer",
        mr: "4px",
      }}
      onClick={() => handleStatusClick(statusTitle)}
    >
      <Box
        component="img"
        src={statusIcon}
        sx={{
          height: "35px",
          width: "35px",
          p: 1,
          borderRadius: "50px",
          backgroundColor: "#000",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          mr: 1,
        }}
      />
      <Typography variant="caption" color={theme.palette.customColors.white}>
        {statusTitle}
      </Typography>
    </Box>
  );
}
