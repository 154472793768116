import "App.css";
import "styles/common.scss";
import "react-datepicker/dist/react-datepicker.css";
import "react-perfect-scrollbar/dist/css/styles.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "pages/login";
import Register from "pages/register";
import CreateAccount from "pages/create-account";
import ForgetPassword from "pages/forget-password";
import ResetPassword from "pages/reset-password";
import {
  SettingsConsumer,
  SettingsProvider,
} from "@core/context/settingsContext";
import ThemeComponent from "@core/theme/ThemeComponent";
import UserLayout from "layouts/UserLayout";

import DashboardPage from "pages/dashboard-page";
import PlatformPage from "pages/platform";
import ErrorPage from "pages/error-page/ErrorPage";
import UnAuthorisezPage from "pages/error-page/UnAuthorisezPage";
import ServerErrorPage from "pages/error-page/ServerErrorPage";
import AutoScheduler from "pages/auto-scheduler";
import themeConfig from "config/themeConfig";
import { Helmet } from "react-helmet";
import Campaign from "pages/Campaign";
import AccountSettings from "pages/account-settings";
import { useAppDispatch, useAppSelector } from "./store/store";
import { useEffect, useState } from "react";
import { notificationClear } from "store/Slice/notificationSlice";
import Notification from "components/notification/notification";
import UserAndPermission from "pages/user_permission";
import ProtectedRoute from "components/screenLayout/protected-route";
import AuthRoute from "components/screenLayout/auth-route";
import VerifyEmail from "pages/register/verify-email";
import { NotificationType } from "common/interface/interface";
import MarketPlace from "pages/marketplace";
import CreatorMarketplace from "pages/marketplace/creator-marketplace";
import AllJobs from "pages/marketplace/create-content/all-jobs";
import JobDetail from "pages/marketplace/create-content/job-detail";
import CreateNewJob from "pages/marketplace/request-content/create-job";
import MyCreatorInfo from "pages/marketplace/create-content/my-creator-info";
import MyBrandProfile from "pages/marketplace/request-content/my-brand-profile";
import MyBrandJobs from "pages/marketplace/request-content/my-job";
import MySubmissions from "pages/marketplace/create-content/my-submission";
import Submissions from "pages/marketplace/request-content/submissions";
import MyEarnings from "pages/marketplace/create-content/Earnings/my-earnings";
import EarningInvoice from "pages/marketplace/create-content/Earnings/Invoice";
import MyJobDetail from "pages/marketplace/request-content/my-job/Detail";

import { route_info } from "navigation/route";
import DownloaderContainer from "pages/marketplace/downloader";

const initialState: NotificationType = {
  message: "",
  status: false,
  type: "",
  position: "",
};

function App(props: any) {
  const { Component } = props;
  const setConfig = Component?.setConfig ?? undefined;
  const dispatch: any = useAppDispatch();
  let notificationInfo = useAppSelector(
    (state: any) => state.notificationReducer
  );

  const [notificationData, setNotificationData] =
    useState<NotificationType>(initialState);

  const clearNotificationMsg = () => {
    setTimeout(() => {
      dispatch(notificationClear());
    }, 10000);
    return true;
  };

  useEffect(() => {
    if (notificationInfo) {
      setNotificationData(notificationInfo);
    }
  }, [notificationInfo]);
  return (
    <BrowserRouter>
      <SettingsProvider {...(setConfig ? { pageSettings: setConfig() } : {})}>
        <Helmet>
          <title>{`${themeConfig.templateName} | Multi platform scheduler`}</title>
          <meta
            name="description"
            content="Let us save you a lot of time and trouble with scheduling advertisements on multiple platforms."
          />
          <meta
            name="keywords"
            content="Material Design, MUI, Admin Template, React Admin Template"
          />
          <meta name="viewport" content="initial-scale=1, width=device-width" />
        </Helmet>
        <SettingsConsumer>
          {({ settings }) => {
            return (
              <ThemeComponent settings={settings}>
                {notificationData?.message && (
                  <>
                    <Notification
                      position={notificationData?.position}
                      msg={notificationData?.message}
                      status={notificationData?.status ? "success" : "error"}
                    />
                    {clearNotificationMsg()}
                  </>
                )}

                <Routes>
                  {/* common route */}
                  <Route
                    path="/"
                    element={
                      <AuthRoute>
                        <Login />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path={route_info.auth_route.register}
                    element={
                      <AuthRoute>
                        <Register />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path={route_info.auth_route.create_account}
                    element={
                      <AuthRoute>
                        <CreateAccount />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path={route_info.auth_route.forgot_password}
                    element={
                      <AuthRoute>
                        <ForgetPassword />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path={route_info.auth_route.reset_password}
                    element={
                      <AuthRoute path="/reset-password">
                        <ResetPassword />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path={route_info.auth_route.verify_email}
                    element={
                      <AuthRoute path="/verify-email">
                        <VerifyEmail />
                      </AuthRoute>
                    }
                  />
                  <Route
                    path={route_info.common.overview}
                    element={
                      <ProtectedRoute>
                        <UserLayout children={<MarketPlace />} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.common.setting}
                    element={
                      <ProtectedRoute>
                        <UserLayout children={<AccountSettings />} />
                      </ProtectedRoute>
                    }
                  />

                  {/* mediaplanner routes */}
                  <Route
                    path={route_info.market_planner.platform}
                    element={
                      <ProtectedRoute>
                        <UserLayout children={<PlatformPage />} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.market_planner.dashboard}
                    element={
                      <ProtectedRoute>
                        <UserLayout children={<DashboardPage />} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.market_planner.auto_scheduler}
                    element={
                      <ProtectedRoute>
                        <UserLayout children={<AutoScheduler />} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.market_planner.campaigns}
                    element={
                      <ProtectedRoute>
                        <UserLayout children={<Campaign />} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.market_planner.user_permission}
                    element={
                      <ProtectedRoute>
                        <UserLayout children={<UserAndPermission />} />
                      </ProtectedRoute>
                    }
                  />

                  {/* marketplace routes */}
                  <Route
                    path={route_info.market_place.all_job}
                    element={
                      <ProtectedRoute>
                        <UserLayout children={<AllJobs />} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.market_place.job_detail + "/:id"}
                    element={
                      <ProtectedRoute>
                        <UserLayout children={<JobDetail />} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.market_place.creator_marketplace}
                    element={
                      <ProtectedRoute>
                        <UserLayout children={<CreatorMarketplace />} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.market_place.create_new_job}
                    element={
                      <ProtectedRoute>
                        <UserLayout children={<CreateNewJob />} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.market_place.my_creator_info}
                    element={
                      <ProtectedRoute>
                        <UserLayout children={<MyCreatorInfo />} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.market_place.my_brand_profile}
                    element={
                      <ProtectedRoute>
                        <UserLayout children={<MyBrandProfile />} />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path={route_info.market_place.my_brand_jobs}
                    element={
                      <ProtectedRoute>
                        <UserLayout children={<MyBrandJobs />} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.market_place.my_submission}
                    element={
                      <ProtectedRoute>
                        <UserLayout children={<MySubmissions />} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.market_place.submissions}
                    element={
                      <ProtectedRoute>
                        <UserLayout children={<Submissions />} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.market_place.my_earnings}
                    element={
                      <ProtectedRoute>
                        <UserLayout children={<MyEarnings />} />
                      </ProtectedRoute>
                    }
                  />
                  <Route
                    path={route_info.market_place.invoice + "/:id"}
                    element={
                      <ProtectedRoute>
                        <UserLayout children={<EarningInvoice />} />
                      </ProtectedRoute>
                    }
                  />

                  <Route
                    path={route_info.market_place.my_job_detail + "/:id"}
                    element={
                      <ProtectedRoute>
                        <UserLayout children={<MyJobDetail />} />
                      </ProtectedRoute>
                    }
                  />

                  {/* Error routes */}
                  <Route
                    path={route_info.error.error_page}
                    element={<ErrorPage />}
                  />
                  <Route
                    path={route_info.error.unauthorized}
                    element={<UnAuthorisezPage />}
                  />
                  <Route
                    path={route_info.error.server_error}
                    element={<ServerErrorPage />}
                  />
                </Routes>
              </ThemeComponent>
            );
          }}
        </SettingsConsumer>
        <DownloaderContainer />
      </SettingsProvider>
    </BrowserRouter>
  );
}

export default App;
