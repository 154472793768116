import { MARKET_PLACE } from "@core/constants/title-constants";
import {
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";

interface Props {
  isOpen: boolean;
  onClickClose?: any;
}

export default function CompleteBrandProfileDialog({
  isOpen,
  onClickClose,
}: Props) {
  return (
    <Dialog
      open={isOpen}
      aria-labelledby="user-view-edit"
      aria-describedby="user-view-edit-description"
    >
      <DialogTitle className="detail-dialog-title" sx={{ pb: 0 }}>
        {MARKET_PLACE.REQUEST_CONTENT.COMPLETE_YOUR_BRAND_PROFILE}
      </DialogTitle>

      <DialogContent sx={{ width: "450px" }}>
        <Typography>
          {MARKET_PLACE.REQUEST_CONTENT.COMPLETE_BRAND_PROFILE_SUBTITLE}
        </Typography>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center" }}>
        <Button
          variant="contained"
          color="primary"
          onClick={onClickClose}
          sx={{ width: "100%" }}
        >
          {MARKET_PLACE.REQUEST_CONTENT.COMPLETE_BRAND_PROFILE}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
