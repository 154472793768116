// ** React Imports
import { useEffect, useState } from "react";

// ** MUI Imports
import {
  Box,
  Typography,
  CircularProgress,
  Grid,
  Button,
  IconButton,
  Tooltip,
  Grow,
} from "@mui/material";

// ** Redux
import { useAppSelector, useAppDispatch } from "store/store";
import {
  getEarningData,
  getPayoutAmount,
  getPayoutBalance,
} from "store/Thunk/earningThunk";

// ** Custom Components
import { MARKET_PLACE } from "@core/constants/title-constants";
import EarningTable from "pages/marketplace/create-content/Earnings/EarningTable";
import PayoutDialog from "../Dialog/Payout";

// ** Assets
import { EarningData, Dollar, Info } from "assets";

// ** Custom Styles
import "../styles.scss";

export default function MyEarnings() {
  const dispatch = useAppDispatch();
  const { loadingState } = useAppSelector(
    (state: any) => state.createJobReducer
  );
  const { payoutBalance } = useAppSelector((state: any) => state.earningSlice);

  const [payoutBalanceState, setPayoutBalanceState] = useState<any>({});
  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);
  // const currentBalStyle:any = {
  //   '@media (max-width: 1077px)': {
  //     width: "100%",
  //   },
  // }

  useEffect(() => {
    setPayoutBalanceState(payoutBalance);
  }, [payoutBalance]);

  useEffect(() => {
    dispatch(getEarningData({ dispatch }));
    dispatch(getPayoutBalance({ dispatch }));
    // setShow(true)
  }, []);

  const handlePayoutClick = () => {
    dispatch(getPayoutAmount({ dispatch }));
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleContinue = () => {
    setOpen(false);
  };
  return (
    <Box className="my-creator-info-block">
      <PayoutDialog
        isOpen={show}
        onClickClose={handleClose}
        isSubmissionCompleted={open}
        setOpen={setOpen}
        handleContinue={handleContinue}
      />

      {loadingState ? (
        <Box className="loader">
          <CircularProgress size={20} />
        </Box>
      ) : (
        <>
          <Box>
            <Typography variant="h5" sx={{ mb: 2 }}>
              {MARKET_PLACE.CREATE_CONTENT.MY_EARNING}
            </Typography>
            <Typography
              variant="body2"
              color="rgba(76, 78, 100, 0.68)"
              sx={{ mb: "30px" }}
            >
              {MARKET_PLACE.CREATE_CONTENT.COLLECT_PAYOUT_VIEW}
            </Typography>
          </Box>
          <Box className="earning-overview">
            <Grow in={true}>
              <Grid container spacing={4} sx={{ mb: "30px" }}>
                <Grid item xs={12} md={3} sm={6}>
                  <Box
                    className="total-earning"
                    sx={{
                      // width: "45%",
                      mb: { sm: "10px", md: 0 },
                    }}
                  >
                    <Box
                      component="img"
                      src={EarningData}
                      className="earning-signs"
                    />
                    <Box>
                      <Typography variant="h6" color="rgba(76, 78, 100, 0.87)">
                        {"$" +
                          ((payoutBalanceState &&
                            payoutBalanceState.total_earnings) ||
                            0)}
                      </Typography>
                      <Typography
                        variant="caption"
                        color="rgba(76, 78, 100, 0.68)"
                      >
                        {MARKET_PLACE.CREATE_CONTENT.TOTAL_EARNING}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item sm={6} xs={12} md={5}>
                  <Box
                    sx={{ width: "fit-content" }}
                    // style={currentBalStyle}
                    className="total-earning"
                  >
                    <Box className="sync-flex-direction-align sync-flex-direction">
                      <Box
                        component="img"
                        src={Dollar}
                        className="earning-signs"
                      />
                      <Box sx={{ mr: "10px" }}>
                        <Typography
                          variant="h6"
                          color="rgba(76, 78, 100, 0.87)"
                        >
                          {"$" +
                            ((payoutBalanceState &&
                              payoutBalanceState.current_balance) ||
                              0)}
                        </Typography>
                        <Typography
                          variant="caption"
                          color="rgba(76, 78, 100, 0.68)"
                        >
                          {MARKET_PLACE.CREATE_CONTENT.CURRENT_BALANCE}
                          <Tooltip
                            placement="top"
                            title={
                              "A threshold of $100 is applied. This means your balance needs to be at least $100 before you can cash your earnings."
                            }
                          >
                            <IconButton
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                              sx={{
                                p: 0,
                                "&.MuiButtonBase-root:hover": {
                                  backgroundColor: "transparent",
                                },
                              }}
                            >
                              <Box
                                component="img"
                                src={Info}
                                className="tooltip-icon"
                              />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                      </Box>
                    </Box>
                    <Button
                      variant="contained"
                      disabled={
                        payoutBalanceState &&
                        payoutBalanceState.current_balance < 100
                      }
                      onClick={() => handlePayoutClick()}
                    >
                      {MARKET_PLACE.CREATE_CONTENT.PAY_OUT}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grow>
          </Box>

          <Box>
            <Typography variant="h5" sx={{ mb: 2 }}>
              {MARKET_PLACE.CREATE_CONTENT.TRANSACTIONS}
            </Typography>

            <EarningTable />
          </Box>
        </>
      )}
    </Box>
  );
}
