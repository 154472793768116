import { useState } from "react";
import {
  useTheme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";

export default function ClearDraftConfirmation(props: any) {
  const theme = useTheme();
  const { isOpen, onClickConfirm, onClickClose, confirmLoading } = props;
  

  let content = (
    <>
      <DialogTitle
        sx={{ pb: 0, borderBottom: 1, borderBottomColor: "#e9ecef" }}
      >
        Delete draft
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClickClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 15,
          color: theme.palette.customColors.comet,
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        <Typography>Are you sure you want to delete draft?</Typography>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClickClose}>
          Cancel
        </Button>
        <LoadingButton color="primary" variant="contained" onClick={onClickConfirm} loading={confirmLoading}
          // variant="contained"
          disabled={confirmLoading}>
          Confirm
        </LoadingButton>
      </DialogActions>
    </>
  );

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="user-view-edit"
      aria-describedby="user-view-edit-description"
    >
      {content}
    </Dialog>
  );
}
