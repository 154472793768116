// ** MUI Imports
import { Box, Typography, Grid } from "@mui/material";

// ** Third Party Library
import { useNavigate } from "react-router-dom";

// ** Custom Components
import { route_info } from "navigation/route";

export default function CreatorMarketplace() {
  const navigate = useNavigate();

  const redirectToPage = (redirectURL: string) => {
    navigate(redirectURL);
  };

  return (
    <Box sx={{ height: "100%" }}>
      <Typography variant="h5" sx={{ mb: 2 }}>
        Creator marketplace
      </Typography>

      <Box className="marketplace-main-block">
        <Grid
          container
          spacing={2}
          sx={{ paddingLeft: { md: "6.5rem", lg: "6.5rem" } }}
        >
          <Grid item md={5} sm={5} xs={12}>
            <div
              className="creator-market-place-block"
              onClick={() => redirectToPage(route_info.market_place.all_job)}
            >
              <Box className="creator-marketplace-block-index" />
              <Box>
                <Typography className="market-place-title" variant="body2">
                  Create content
                </Typography>
                <Typography className="index-caption" variant="body2">
                  Earn money as a creator by creating content for brands and
                  getting paid per view.
                </Typography>
              </Box>
            </div>
          </Grid>
          <Grid
            sx={{ display: "flex", alignItems: "center" }}
            item
            md={2}
            sm={2}
            xs={12}
          >
            <Typography variant="h5" className="txt-or">
              OR
            </Typography>
          </Grid>
          <Grid item md={5} sm={5} xs={12}>
            <div
              className="creator-market-place-block"
              onClick={() =>
                redirectToPage(route_info.market_place.my_brand_jobs)
              }
            >
              <Box className="creator-marketplace-block-index" />
              <Box>
                <Typography className="market-place-title" variant="body2">
                  Request content
                </Typography>
                <Typography className="index-caption" variant="body2">
                  Gain relevant content for your campaign through a network of
                  talented creators.
                </Typography>
              </Box>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}
