import { useTheme, IconButton, Dialog, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VdoCipher from "components/VdoCipher";
import { useAppSelector } from "store/store";

export default function VideoModal(props: any) {
  const theme = useTheme();

  const { videoData } = useAppSelector((state: any) => state.createJobReducer);
  const { isOpen, handleClose } = props;
  return (
    <Dialog
      fullScreen
      open={isOpen}
      aria-labelledby="user-view-edit"
      aria-describedby="user-view-edit-description"
    >
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 15,
          zIndex: 9999,
          color: theme?.palette?.customColors?.comet,
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ height: "100%", mt: "40px" }}>
        <VdoCipher videoData={videoData} />
      </DialogContent>
    </Dialog>
  );
}
