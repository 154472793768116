// ** React Imports
import React, { useEffect, useState } from "react";

// ** MUI Imports
import {
  Box,
  Typography,
  FormControl,
  Button,
  Select,
  MenuItem,
  CircularProgress,
  FormHelperText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// ** Redux
import { useAppDispatch, useAppSelector } from "store/store";
import {
  addLinkfireGeneratorArtist,
  getAllScraperGroup,
  getLinkfiregeneratorInfo,
} from "store/Thunk/platformConnectionThunk";

// ** Custom Components
import DropdownNotFound from "components/DropdownNotFound/DropdownNotFound";

interface Props {
  setDialog?: any;
  page?: any;
}

export default function LinkfireGeneratorLinkDialog({
  setDialog,
  page,
}: Props) {
  const dispatch = useAppDispatch();

  const { scraperGroupData } = useAppSelector(
    (state: any) => state.PlatformSlice
  );

  const [values, setValues] = React.useState({
    group: "",
  });
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState({ group: false, login: false });

  useEffect(() => {
    let data: any = null;
    dispatch(getAllScraperGroup({ data })).then((res: any) => {
      res?.payload?.data?.length
        ? setLoading({ ...loading, group: false })
        : setLoading({ ...loading, group: true });
    });
  }, []);

  const handledSubmit = (event: any) => {
    event.preventDefault();
    if (values.group == "") {
      setError(true);
    } else {
      setLoading({ ...loading, login: true });
      setError(false);

      let data: any = {
        scraper_group_id: values?.group,
      };
      dispatch(addLinkfireGeneratorArtist({ data })).then((res: any) => {
        setLoading({ ...loading, login: false });
        if (res?.payload?.status == 201) {
          setDialog({ open: false });
          let data: any = null;
          dispatch(getLinkfiregeneratorInfo({ data, page }));
        }
      });
    }
  };

  return (
    <Box>
      <Typography variant="h5" sx={{ fontWeight: 600 }}>
        Generate Linkfire link
      </Typography>
      <Typography variant="subtitle2" sx={{ fontWeight: 400, mt: 3 }}>
        Pick a group in which you want the new link to be generated.
      </Typography>
      <form noValidate autoComplete="off" onSubmit={handledSubmit}>
        <FormControl fullWidth sx={{ mb: 4, mt: 5 }}>
          <>
            <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
              Group
            </Typography>
            <FormControl fullWidth sx={{ width: "300px", mt: 2, mb: 2 }}>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                placeholder="Labels..."
                value={values?.group}
                error={error}
                onChange={(e: any) =>
                  setValues({ ...values, group: e.target.value })
                }
              >
                {scraperGroupData.length == 0 && loading.group && (
                  <DropdownNotFound name={"group"} />
                )}
                {scraperGroupData.length == 0 && !loading.group && (
                  <Box className="sync-align-justify-center full-height-width">
                    <CircularProgress />
                  </Box>
                )}
                {scraperGroupData?.map((item: any) => (
                  <MenuItem value={item?.id}>{item?.group_name}</MenuItem>
                ))}
              </Select>
              {error && (
                <FormHelperText error>
                  Please enter linkfire link
                </FormHelperText>
              )}
            </FormControl>
          </>
        </FormControl>
        <Box sx={{ display: "flex" }}>
          <Button
            fullWidth
            size="large"
            variant="outlined"
            sx={{ mb: 4, mr: 2 }}
            onClick={() => setDialog({ open: false })}
          >
            Cancel
          </Button>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            loading={loading.login}
            sx={{ mb: 4, ml: 2 }}
          >
            Generate link
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
}
