// ** React Imports
import React, { useEffect, useState } from "react";

// ** MUI Imports
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControlLabel,
  InputBase,
  Switch,
  Slide,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { styled, alpha } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";

// ** Redux
import { useAppDispatch, useAppSelector } from "store/store";
import {
  getSpootifyScraper,
  updateSpotifyInfo,
} from "store/Thunk/platformConnectionThunk";
// ** Custom Components
import SpootifyAddArtistDialog from "../Dialogs/SpootifyAddArtistDialog";

// ** Icons
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

interface Props {
  title?: any;
  setIsBackdrop?: any;
}

export default function ScrapperTab({ title, setIsBackdrop }: Props) {
  const dispatch: any = useAppDispatch();
  const { spootifyScrapper } = useAppSelector(
    (state: any) => state.PlatformSlice
  );
  const [dialog, setDialog] = useState({ open: false });
  const [accountData, setAccountData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<number>(spootifyScrapper?.count);

  useEffect(() => {
    setAccountData(spootifyScrapper?.results);
    setTotal(spootifyScrapper?.count);
  }, [spootifyScrapper]);

  useEffect(() => {
    let data: any = null;
    dispatch(getSpootifyScraper({ data, page })).then((res: any) => {});
  }, [page]);

  const handledToggleChange = (checked: any, id: any) => {
    const data = {
      active: checked ? "Yes" : "No",
    };
    setIsBackdrop(true);
    let datas: any = null;
    dispatch(updateSpotifyInfo({ data, id })).then((response: any) => {
      dispatch(getSpootifyScraper({ datas, page })).then((res: any) => {
        setIsBackdrop(false);
      });
    });
  };

  const columns = [
    {
      flex: 0.25,
      minWidth: 200,
      field: "link",
      headerName: "ARTIST",
      renderCell: (cellValues: any) => {
        return (
          <FormControlLabel
            control={
              <Switch
                disabled={false}
                checked={cellValues?.row?.active == "Yes" ? true : false}
              />
            }
            label={cellValues?.row?.profile_name}
            onChange={(e: any) =>
              handledToggleChange(e.target.checked, cellValues.row.id)
            }
          />
        );
      },
    },
    {
      flex: 0.1,
      field: "profile_id",
      minWidth: 80,
      headerName: "ID",
    },
  ];

  const handleSearchChange = (value: string) => {
    const accountArray: any = spootifyScrapper?.results?.filter(
      (element: any) => {
        if (
          element?.profile_name
            ?.toLocaleLowerCase()
            ?.includes(value?.toLocaleLowerCase())
        ) {
          return element;
        }
      }
    );
    setAccountData(accountArray);
  };

  return (
    <Box>
      <Box
        className="sync-justify-between"
        sx={{
          mt: 5,
        }}
      >
        <Box>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search (Ctrl+/)"
              inputProps={{ "aria-label": "search" }}
              onChange={(e: any) => handleSearchChange(e.target.value)}
            />
          </Search>
        </Box>
        <Box>
          <Button
            variant="text"
            startIcon={<AddCircleOutlineOutlinedIcon />}
            sx={{ fontSize: "12px" }}
            onClick={() => setDialog({ open: true })}
          >
            ADD {title == "Spotify" ? "Artist" : "Links"}
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 5 }}>
        <DataGrid
          columns={columns}
          rows={accountData}
          pagination
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage + 1)}
          pageSize={10}
          sx={{ height: "100vh", display: "flex" }}
          rowCount={total}
        />
      </Box>
      <Dialog
        fullWidth
        open={dialog.open}
        TransitionComponent={Transition}
        maxWidth="sm"
        scroll="body"
        onClose={() => setDialog({ open: false })}
      >
        <DialogContent
          sx={{
            px: { xs: 8, sm: 15 },
            py: { xs: 8, sm: 12.5 },
            position: "relative",
          }}
        >
          {title == "Spotify" && (
            <SpootifyAddArtistDialog setDialog={setDialog} page={page} />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}
