// ** React Imports
import React from "react";

// ** MUI Imports
import {
  Box,
  Typography,
  FormControl,
  Button,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// ** Redux
import { useAppDispatch, useAppSelector } from "store/store";
import { postLoginWithLinkfireGeneratorBoard } from "store/Thunk/platformConnectionThunk";

interface State {
  board?: string;
}

interface Props {
  setDialogOpen?: any;
  onSpotifyLogin?: any;
  setLoading?: any;
  setSidebarDrawer?: any;
}

interface FormValidation {
  board?: string;
}

export default function LinkFireGeneratorBoardDialog({
  setDialogOpen,
  onSpotifyLogin,
  setLoading,
  setSidebarDrawer,
}: Props) {
  const dispatch = useAppDispatch();
  const [values, setValues] = React.useState({
    board: "",
    name: "",
  });
  const [loginLoading, setLoginLoading] = React.useState<boolean>(false);
  const [error, setError] = React.useState<boolean>(false);
  const { linkfireGeneratorLoginData } = useAppSelector(
    (state: any) => state.PlatformSlice
  );

  const handleSubmit = (event: any) => {
    event.preventDefault();
    if (values.board == "") {
      setError(true);
    } else {
      setError(false);
      let data: any = {
        board_id: values.board,
        name: values.name,
      };
      dispatch(postLoginWithLinkfireGeneratorBoard({ data })).then(
        (res: any) => {
          if (res.payload.status == 200) {
            setLoading(true);
            setDialogOpen(false);
            onSpotifyLogin();
            setLoginLoading(false);
            setSidebarDrawer({
              open: true,
              drawer: "Linkfire Generator",
              title: "Linkfire Generator",
            });
          }
        }
      );
    }
  };

  const handledLinkfireBoard = (value: any) => {
    const index = linkfireGeneratorLoginData?.findIndex(
      (element: any) => element?.id == value
    );
    setValues({
      ...values,
      board: value,
      name: linkfireGeneratorLoginData[index]?.name,
    });
  };

  return (
    <Box>
      <Typography variant="h5" sx={{ fontWeight: 600 }}>
        Connect to Linkfire Generator
      </Typography>
      <Typography variant="subtitle2" sx={{ fontWeight: 400, mt: 3 }}>
        Enter your Linkfire API key to automatically create Linkfire links for
        your ad campaigns
      </Typography>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <FormControl fullWidth sx={{ mt: 5, flexDirection: "column" }}>
          <RadioGroup
            aria-label="controlled"
            name="controlled"
            value={values?.board}
            onChange={(e) => handledLinkfireBoard(e.target.value)}
          >
            {linkfireGeneratorLoginData?.length &&
              linkfireGeneratorLoginData?.map((item: any) => (
                <FormControlLabel
                  value={item?.id}
                  control={<Radio />}
                  label={item?.name}
                />
              ))}
          </RadioGroup>
        </FormControl>
        {error && (
          <FormHelperText sx={{ color: "primary.main", mb: 4 }}>
            Please select board.
          </FormHelperText>
        )}
        <Box sx={{ display: "flex" }}>
          <Button
            fullWidth
            size="large"
            variant="outlined"
            sx={{ mb: 4, mr: 2 }}
            onClick={() => setDialogOpen({ open: false, dialog: "" })}
          >
            Cancel
          </Button>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            loading={loginLoading}
            variant="contained"
            sx={{ mb: 4, ml: 2 }}
          >
            Connect
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
}
