import {
  Box,
  Typography,
  useTheme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  CircularProgress,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { MARKET_PLACE } from "@core/constants/title-constants";

export default function DeclineSubmissionMessageDialog(props: any) {
  const theme = useTheme();
  const {
    isHelperText,
    isOpen,
    isMessageSent,
    onClickClose,
    onClickSendMessage,
    onChangeMessage,
    loader,
  } = props;

  let content;
  if (!isMessageSent) {
    if (loader) {
      content = (
        <Box className="loader" sx={{ width: "200px" }}>
          <CircularProgress size={20} />
        </Box>
      );
    } else {
      content = (
        <>
          <DialogTitle className="detail-dialog-title" sx={{ pb: 0 }}>
            {MARKET_PLACE.REQUEST_CONTENT.FEEDBACK_THE_CREATOR}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={onClickClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 15,
              color: theme?.palette?.customColors?.comet,
            }}
          >
            <CloseIcon />
          </IconButton>

          <DialogContent>
            <Typography
              variant="body2"
              className="dialog_content"
              color={theme?.palette?.customColors?.border_color_light}
              sx={{ mb: "27px" }}
            >
              {MARKET_PLACE.REQUEST_CONTENT.FEEDBACK_THE_CREATOR_DESCRIPTION}
            </Typography>
            <Box>
              <TextField
                helperText={isHelperText && "Message field is requried"}
                placeholder="Your feedback here..."
                multiline
                rows={4}
                sx={{
                  width: "100%",
                  ".MuiFormHelperText-root": {
                    color: "red",
                  },
                }}
                onChange={(e: any) => onChangeMessage(e)}
              />
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{ width: "49%" }}
              variant="outlined"
              onClick={onClickClose}
            >
              Cancel
            </Button>
            <Button
              sx={{ width: "49%", padding: "0.4375rem 0.3125rem" }}
              variant="contained"
              color="primary"
              onClick={() => onClickSendMessage("decline")}
            >
              {MARKET_PLACE.REQUEST_CONTENT.DECLINE_SUBMISSION}
            </Button>
          </DialogActions>
        </>
      );
    }
  } else {
    content = (
      <>
        <DialogTitle className="detail-dialog-title">
          {MARKET_PLACE.REQUEST_CONTENT.SUBMISSION_DECLINED}
        </DialogTitle>
        <DialogContent sx={{ pb: "25px" }}>
          <Typography
            variant="body2"
            className="dialog_content"
            color={theme.palette.customColors.border_color_light}
            sx={{ mb: "27px" }}
          >
            {MARKET_PLACE.REQUEST_CONTENT.SUBMISSION_DECLINED_DESCRIPTION}
          </Typography>
          <Button
            variant="contained"
            className="continue-btn"
            onClick={onClickClose}
          >
            Continue
          </Button>
        </DialogContent>
      </>
    );
  }

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="user-view-edit"
      aria-describedby="user-view-edit-description"
    >
      {content}
    </Dialog>
  );
}
