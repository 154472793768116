import {
  Alert,
  AlertTitle,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  InputLabel,
  Radio,
  RadioGroup,
  Slide,
  Slider,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import CloudQueueIcon from "@mui/icons-material/CloudQueue";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import "cleave.js/dist/addons/cleave-phone.us";
import {
  CreatorMarketplace,
  MediaLibrary,
  MediaPlanner,
  PricingIllustration1,
  PricingIllustration2,
  PricingIllustration3,
} from "assets";
import PricingPlans from "../pricing/PricingPlans";
import PricingCTA from "../pricing/PricingCTA";
import PricingHeader from "../pricing/PricingHeader";
import PricingFooter from "../pricing/PricingFooter";
import {
  getCurrentSubscription,
  getPlanList,
} from "store/Thunk/subscriptionThunk";
import { useAppDispatch, useAppSelector } from "store/store";
import { ScreenLoading } from "store/Slice/AuthSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { SlideProps } from "@mui/material/Slide";
import { route_info } from "navigation/route";

type TransitionProps = Omit<SlideProps, "direction">;

const data = {
  pricingPlans: [
    {
      imgWidth: 100,
      title: "Basic",
      imgHeight: 100,
      monthlyPrice: 0,
      currentPlan: true,
      popularPlan: false,
      daysLeft: 3,
      subtitle: "A simple start for everyone",
      imgSrc: PricingIllustration1,
      amountTitle: "FREE",
      yearlyPlan: {
        perMonth: 0,
        totalAnnual: 0,
      },
      planBenefits: [
        "100 responses a month",
        "Unlimited forms and surveys",
        "Unlimited fields",
        "Basic form creation tools",
        "Up to 2 subdomains",
      ],
    },
    {
      imgWidth: 100,
      imgHeight: 100,
      monthlyPrice: 49,
      title: "Standard",
      popularPlan: true,
      currentPlan: false,
      subtitle: "For small to medium businesses",
      imgSrc: PricingIllustration2,
      amountTitle: "FREE",
      yearlyPlan: {
        perMonth: 40,
        totalAnnual: 480,
      },
      planBenefits: [
        "Unlimited responses",
        "Unlimited forms and surveys",
        "Instagram profile page",
        "Google Docs integration",
        "Custom “Thank you” page",
      ],
    },
    {
      imgWidth: 100,
      imgHeight: 100,
      monthlyPrice: 99,
      popularPlan: false,
      currentPlan: false,
      title: "Enterprise",
      subtitle: "Solution for big organizations",
      imgSrc: PricingIllustration3,
      amountTitle: "Let's talk",
      yearlyPlan: {
        perMonth: 80,
        totalAnnual: 960,
      },
      planBenefits: [
        "PayPal payments",
        "Logic Jumps",
        "File upload with 5GB storage",
        "Custom domain support",
        "Stripe integration",
      ],
    },
  ],
};

const checkout_status = ["success", "failure"];

const TransitionDown = (props: TransitionProps) => {
  return <Slide {...props} direction="down" />;
};

export default function BillingTab() {
  const [state, setState] = useState<any>({
    currentPlan: null,
    trial_alert: false,
  });
  const [checkoutStatus, setCheckoutStatus] = useState<any>("");
  const [checkoutSnackbar, setCheckoutSnackbar] = useState<boolean>(false);
  const location = useLocation();
  const navigate: any = useNavigate();
  const dispatch = useAppDispatch();
  const { currentPlan, planList } = useAppSelector(
    (state) => state.subscriptionSlice
  );
  const { screenLoading } = useAppSelector((state) => state.authReducer);

  const [allPlan, setAllPlan] = useState<any>([]);

  useEffect(() => {
    dispatch(getPlanList({}));
  }, []);

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      const status = searchParams.get("status");
      setCheckoutStatus(status);
      navigate(`${route_info.common.setting}#your_plan`);
      setTimeout(() => {
        setCheckoutStatus("");
      }, 9000);
    }
  }, [location]);

  useEffect(() => {
    if (checkoutStatus) {
      setCheckoutSnackbar(true);
    } else {
      setCheckoutSnackbar(false);
    }
  }, [checkoutStatus]);

  useEffect(() => {
    if (
      currentPlan.free_trial_days_left < 1 &&
      !currentPlan.customer_subscription.length
    ) {
      setState({ ...state, currentPlan: currentPlan, trial_alert: true });
    } else {
      setState({ ...state, currentPlan: currentPlan, trial_alert: false });
    }
  }, [currentPlan]);

  useEffect(() => {
    if (planList.length) {
      setAllPlan(planList);
    } else {
      setAllPlan([]);
    }
  }, [planList]);

  useEffect(() => {
    dispatch(ScreenLoading(true));
    dispatch(getCurrentSubscription({}));
  }, []);

  const handleCloseAlert = () => {
    setState({ ...state, trial_alert: !state?.trial_alert });
  };

  const getStatusMessage = () => {
    if (checkoutStatus === "success") {
      return "You're all set! Your upgraded plan is now active.";
    } else if (checkoutStatus === "failure") {
      return "We're sorry, there was an error while upgrading your account. Please try again later or contact our customer support for further assistance.";
    }
  };

  const getSeverity = () => {
    if (checkoutStatus === "success") {
      return "success";
    } else if (checkoutStatus === "failure") {
      return "error";
    }
  };

  const handleCloseSnackbar = () => {
    setCheckoutSnackbar(!checkoutSnackbar);
    setCheckoutStatus("");
  };

  return (
    <Box>
      {state?.trial_alert && (
        <Box display="flex" justifyContent="center" mt={4}>
          <Alert
            severity="warning"
            onClose={handleCloseAlert}
            sx={{ width: "80%" }}
          >
            <AlertTitle>
              Your free 30-days trial has expired. Upgrade now to keep
              scheduling your ads everywhere
            </AlertTitle>
          </Alert>
        </Box>
      )}
      {/* {checkoutStatus && checkout_status.includes(checkoutStatus) && (
        <Box display="flex" justifyContent="center" mt={4}>
          <Alert
            severity={getSeverity()}
            onClose={handleCloseCheckoutAlert}
            sx={{ width: "80%" }}
          >
            <AlertTitle>
              {checkoutStatus === "success" &&
                }
              {checkoutStatus === "failure" &&
                }
            </AlertTitle>
          </Alert>
        </Box>
      )} */}
      <Box
        sx={{
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          // mb: 12,
        }}
      >
        <PricingHeader state={state} setState={setState} allPlan={allPlan} />
        <PricingPlans data={data} state={state} setState={setState} />
      </Box>
      {/* <PricingCTA /> */}
      <PricingFooter data={data} />
      <Snackbar
        open={checkoutSnackbar}
        onClose={handleCloseSnackbar}
        message={getStatusMessage()}
        autoHideDuration={10000}
        key={checkoutStatus}
        TransitionComponent={TransitionDown}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          elevation={3}
          variant="filled"
          onClose={handleCloseSnackbar}
          severity={getSeverity()}
          sx={{ color: "#fff" }}
        >
          <AlertTitle sx={{ color: "#fff" }}>{getStatusMessage()}</AlertTitle>
        </Alert>
      </Snackbar>
      {/* <Grid container spacing={5}>
        <Grid item xs={12} sm={8} spacing={5}>
          <Grid container spacing={5}>
            <Grid item xs={12} sm={12}>
              <InputLabel>Payment Method</InputLabel>
              <RadioGroup
                row
                aria-label="controlled"
                name="controlled"
                value={value}
                onChange={handleChange}
              >
                <FormControlLabel
                  value="Male"
                  control={<Radio />}
                  label="Credit/Debit/ATM Card"
                />
                <FormControlLabel
                  value="Female"
                  control={<Radio />}
                  label="COD/Cheque"
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextField fullWidth label="Name" placeholder="Name" />
            </Grid>

            <Grid item xs={12} sm={12}>
              <TextField
                fullWidth
                label="Card"
                type="text"
                placeholder="Card"
                value={card}
                onChange={(e: any) => setCard(e.target.value)}
              ></TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Expiry Date"
                placeholder="Expiry Date"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="CVV"
                type="text"
                placeholder="CVV"
              ></TextField>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} spacing={5}>
          <Box
            sx={{
              border: 1,
              borderRadius: "10px",
              borderColor: "rgba(76, 78, 100, 0.20)",
              padding: 5,
            }}
          >
            <Typography variant="h6">Your Current Plan</Typography>
            <Box
              sx={{
                borderRadius: "16px",
                width: "85px",
              }}
            >
              <Typography sx={{ fontSize: "13px", color: "primary.main", margin: "10px" }}>
                Basic Plan
              </Typography>
            </Box>
            <Box className="sync-align-center">
              <PersonOutlineIcon sx={{ mr: 2 }} />
              <Typography variant="subtitle1">5 Users</Typography>
            </Box>
            <Box className="sync-align-center">
              <CloudQueueIcon sx={{ mr: 2 }} />
              <Typography variant="subtitle1">10 GB storage</Typography>
            </Box>
            <Box className="sync-align-center">
              <HelpOutlineIcon sx={{ mr: 2 }} />
              <Typography variant="subtitle1">Basic Support</Typography>
            </Box>
            <Button variant="contained" fullWidth sx={{ mt: 5 }}>
              Save Changes
            </Button>
          </Box>
        </Grid>
      </Grid>
      <Box sx={{ mt: 5 }}>
        <Button variant="outlined" sx={{ mr: 3 }}>
          CANCEL
        </Button>
        <Button variant="contained">SAVE CHANGES</Button>
      </Box> */}
    </Box>
  );
}
