// ** React
import { useContext, useEffect, useState } from "react";

// ** MUI Imports
import { Box, Tab, Tabs, Typography } from "@mui/material";

// ** Redux
import { AutoschudlerContext } from "context/autoschedulerContex";

// ** Custom Component
import { PLATFORMS } from "@core/constants/enum";
import FacebookTabs from "pages/auto-scheduler/SettingTabs/FacebookTabs";
import SnapchatTabs from "pages/auto-scheduler/SettingTabs/SnapchatTabs";
import TiktokTabs from "pages/auto-scheduler/SettingTabs/TiktokTabs";
import { MEDIA_PLANNER } from "@core/constants/title-constants";
import { CAMPAIGN_OBJECTIVE } from "@core/constants/enum";

interface Props {
  onForwardButtonClick(): void;
  onBackwordButtonClick?(): void;
  onClearDraftClick?(): void;
  snapchat: any;
  setSnapchat: any;
  tiktok: any;
  setTiktok: any;
  facebook: any;
  setFacebook: any;
  onNextPlatformClick: any;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function SettingStep({
  onForwardButtonClick,
  onBackwordButtonClick,
  onClearDraftClick,
  snapchat,
  tiktok,
  setTiktok,
  facebook,
  setFacebook,
  onNextPlatformClick,
}: Props) {
  let autoSchedulerTitle = MEDIA_PLANNER.auto_scheduler;

  const {
    state,
    setState,
    campaign,
    availablePlatform,
    availablePlatformTitle,
  }: any = useContext(AutoschudlerContext);

  const [value, setValue] = useState(0);
  const [flag, setFlag] = useState(false);

  useEffect(() => {
    availablePlatform.map((data: any, index: number) => {
      if (availablePlatformTitle.includes(data)) {
        setValue(index);
      }
    });

    if (
      availablePlatformTitle.length &&
      state?.settingPlatform &&
      !availablePlatformTitle.includes(state?.settingPlatform)
    ) {
      setState({ ...state, settingPlatform: 0 });
    }
  }, [availablePlatformTitle]);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setState({ ...state, settingPlatform: newValue });
  };

  const handleNextButton = () => {
    setValue(value + 1);
    setState({ ...state, settingPlatform: value + 1 });
  };

  const handleNextButtonForAll = () => {
    setFlag(false);

    if (
      availablePlatformTitle?.includes(PLATFORMS.FACEBOOK) &&
      ((campaign?.objective === CAMPAIGN_OBJECTIVE.CONVERSIONS &&
        facebook?.pixel &&
        facebook?.optimizeEvent == "") ||
        !facebook?.profile ||
        !facebook?.advertiserAccount ||
        (facebook?.activeButton == "Existing" && !facebook?.existingAccount) ||
        !facebook?.ageGroup?.length ||
        !facebook?.heading ||
        !facebook?.caption ||
        (!facebook?.advantage && !facebook?.advantageAndPlacements?.length))
    ) {
      setFlag(true);
    } else if (
      availablePlatformTitle?.includes(PLATFORMS.TIKTOK) &&
      ((campaign?.objective === CAMPAIGN_OBJECTIVE.CONVERSIONS &&
        tiktok?.pixel &&
        tiktok?.optimizeEvent == "") ||
        !tiktok?.profile ||
        !tiktok?.advertiserAccount ||
        (tiktok?.activeButton == "Existing" && !tiktok?.existingAccount) ||
        !tiktok?.ageGroup?.length ||
        !tiktok?.caption ||
        !tiktok?.identity ||
        !tiktok?.dayparsingOffset)
    ) {
      setFlag(true);
    } else if (
      availablePlatformTitle?.includes(PLATFORMS.SNAP) &&
      ((campaign?.objective === CAMPAIGN_OBJECTIVE.CONVERSIONS &&
        snapchat?.pixel &&
        snapchat?.optimizeEvent == "") ||
        !snapchat?.profile ||
        !snapchat?.advertiserAccount ||
        (snapchat?.activeButton == "Existing" && !snapchat?.existingAccount) ||
        !snapchat?.ageGroup?.length ||
        snapchat?.heading?.length < 2 ||
        snapchat?.companyName?.length < 2)
    ) {
      setFlag(true);
    } else {
      setFlag(true);
      onForwardButtonClick();
    }
  };

  return (
    <Box>
      <Box sx={{ padding: 5 }}>
        <Typography variant="h5" sx={{ mb: 2 }}>
          {autoSchedulerTitle.SETTINGS_PER_PLATFORM}
        </Typography>
        <Typography variant="subtitle2" sx={{ mb: 2 }}>
          {autoSchedulerTitle.MAKE_SURE_EVERYTHING}
        </Typography>
      </Box>

      <Box sx={{ width: "100%" }}>
        <Box pl={5}>
          <Tabs
            value={state?.settingPlatform}
            onChange={handleChange}
            aria-label="basic tabs example"
            sx={{ mb: 5 }}
          >
            {availablePlatform
              ?.filter((item: any) =>
                availablePlatformTitle?.includes(item?.value)
              )
              ?.map((title: any, index: any) => (
                <Tab
                  key={title?.title}
                  label={title?.title}
                  iconPosition="start"
                  icon={
                    state?.settingPlatform == index
                      ? title?.activeTabIcon
                      : title?.deactiveTabIcon
                  }
                  {...a11yProps(title?.id)}
                />
              ))}
          </Tabs>
        </Box>

        <Box>
          {availablePlatform
            .filter((item: any) => availablePlatformTitle?.includes(item.value))
            ?.map((item: any, index: any) => (
              <TabPanel
                value={state?.settingPlatform}
                index={index}
                key={item?.value}
              >
                {item?.value == PLATFORMS.TIKTOK && (
                  <TiktokTabs
                    tiktok={tiktok}
                    setTiktok={setTiktok}
                    onBackwordButtonClick={onBackwordButtonClick}
                    onForwardButtonClick={onForwardButtonClick}
                    onClearDraftClick={onClearDraftClick}
                    handleNextButton={handleNextButton}
                    availablePlatform={availablePlatform}
                    handleNextButtonForAll={handleNextButtonForAll}
                    flag={flag}
                    onNextPlatformClick={onNextPlatformClick}
                  />
                )}
                {item?.value == PLATFORMS.FACEBOOK && (
                  <FacebookTabs
                    facebook={facebook}
                    setFacebook={setFacebook}
                    onBackwordButtonClick={onBackwordButtonClick}
                    onForwardButtonClick={onForwardButtonClick}
                    onClearDraftClick={onClearDraftClick}
                    handleNextButton={handleNextButton}
                    handleNextButtonForAll={handleNextButtonForAll}
                    onNextPlatformClick={onNextPlatformClick}
                    flag={flag}
                  />
                )}
                {item?.value == PLATFORMS.SNAP && (
                  <SnapchatTabs
                    onBackwordButtonClick={onBackwordButtonClick}
                    onClearDraftClick={onClearDraftClick}
                    handleNextButtonForAll={handleNextButtonForAll}
                    flag={flag}
                  />
                )}
              </TabPanel>
            ))}
        </Box>
      </Box>
    </Box>
  );
}
