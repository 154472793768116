import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import Stack from "@mui/material/Stack";
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import { useAppDispatch, useAppSelector } from "store/store";
import { setPaymentDetails } from "store/Slice/CreateJobSlice";

export type ReactPayPalProps = {
  totalPayment: any;
  setOrderID?: any;
  paymentDetailsHandle: (e: any) => void;
  paymentDetailsHandleNew:(e: any) => void;
};

export default function ReactPayPal(props: ReactPayPalProps) {
  const {
    totalPayment,
    setOrderID,
    paymentDetailsHandle,
    paymentDetailsHandleNew
  } = props
  const dispatch = useAppDispatch();
  
  const paymentDetails: any = useAppSelector((state: any) => state.createJobReducer.paymentDetails);
  const [paid, setPaid] = React.useState<boolean>(false);
  const [error, setError] = React.useState(null);
  const [ ErrorMessage, setErrorMessage] = useState("")
  const createOrder = async (data: any, actions: any) => {
    return await actions.order.create({
      intent: "CAPTURE",
      purchase_units: [
        {
          description: "Your description",
          amount: {
            currency_code: "USD",
            value: totalPayment,
          },
          payment_instruction: {
            payment_method: {
              payee_selected: "DEBIT_CARD",
            },
            payment_options: {
              allowed_payment_method: "INSTANT_FUNDING_SOURCE",
            },
          },
        },
      ],
    })
    .then((order: any) => {
      setOrderID(order);  
    
      paymentDetailsHandle(data?.paymentSource)
      return order;
    });
  };


  const onApprove = async (data: any, actions: any) => {
    const order = await actions.order.capture().then(function (details: any) {
      const { payer } = details;
      let order = { 
        payment_id: payer?.payer_id,
        payment_status: "completed"
      }
      paymentDetailsHandleNew(order)
      setPaid(true);
      // setPaymentNew({...paymentNew, payment_id: payer?.payer_id, payment_status: "Completed"})
    });
  };

 
  
  
  const onError = () => {
    console.log("Error Message")
  }

  useEffect(() => {
    // If the payment has been made
    if (paid) {
      <Stack sx={{ width: "100%" }} spacing={2}>
        <Alert severity="success">Payment successful.!</Alert>
      </Stack>;
    } else if (error) {
      <Stack sx={{ width: "100%" }} spacing={2}>
        <Alert severity="error">
          Error Occurred in processing payment.! Please try again.
        </Alert>
      </Stack>;
    }
  }, [paid]);
  // Default Render
  return (
    <PayPalScriptProvider
    options={{
      "client-id":
          "ATz3m_j60-DY_0hKKmIPIEzO1rDmmJKbXC6w0wFZxV510zy9WousQW5H3dA7N33fFTJn-F8-4Zz9XEdb",
        }}
        >
      <PayPalButtons createOrder={createOrder} onApprove={onApprove} onError={onError}/>
    </PayPalScriptProvider>
  );
}
