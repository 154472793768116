// ** MUI Imports
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";

// ** Icons Imports
import MenuIcon from "mdi-material-ui/Menu";

// ** Type Import

// ** Components
// import Autocomplete from 'layouts/components/Autocomplete'
import ModeToggler from "@core/layouts/components/shared-components/ModeToggler";
import UserDropdown from "@core/layouts/components/shared-components/UserDropdown";
import LanguageDropdown from "@core/layouts/components/shared-components/LanguageDropdown";
import NotificationDropdown from "@core/layouts/components/shared-components/NotificationDropdown";
import { Settings } from "@core/context/settingsContext";
import AutocompleteComponent from "../Autocomplete";

interface Props {
  hidden: boolean;
  settings: Settings;
  toggleNavVisibility: () => void;
  saveSettings: (values: Settings) => void;
}

const AppBarContent = (props: Props) => {
  // ** Props
  const { hidden, settings, saveSettings, toggleNavVisibility } = props;

  return (
    <Box
      sx={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Box
        className="actions-left"
        sx={{ mr: 2, display: "flex", alignItems: "center" }}
      >
        {hidden ? (
          <IconButton
            color="inherit"
            sx={{ ml: -2.75 }}
            onClick={toggleNavVisibility}
          >
            <MenuIcon />
          </IconButton>
        ) : null}
        {/* <AutocompleteComponent hidden={hidden} settings={settings} /> */}
      </Box>
      <Box
        className="actions-right"
        sx={{ display: "flex", alignItems: "center" }}
      >
        <UserDropdown settings={settings} />
      </Box>
    </Box>
  );
};

export default AppBarContent;
