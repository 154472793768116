// ** Mui Imports
import { Box, Button, Typography } from "@mui/material";

// ** Images
import { PixelMelonLeaning2 } from "assets";

interface Props {
  onStartButtonClick?: any;
}

export default function CompletedDialog({ onStartButtonClick }: Props) {
  return (
    <Box sx={{ margin: 2 }}>
      <Typography variant="h5">Batch is scheduled 🎉</Typography>
      <Typography variant="subtitle2">
        Your ads are scheduled and will be published soon. Keep track of the
        progress in the history drawer.
      </Typography>
      <Box sx={{ my: 10 }}>
        <Box component="img" src={PixelMelonLeaning2} />
      </Box>
      <Button
        variant="contained"
        fullWidth
        onClick={() => onStartButtonClick()}
      >
        FINISH
      </Button>
    </Box>
  );
}
