import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
    brandProfileData: any;
    brandList: any;
    jobTitleList: any;
    faceBookConnect: any;
    
}

const initialState: InitialState = {
    brandProfileData: null,
    brandList: [],
    jobTitleList: [],
    faceBookConnect: [],
   
};

const createBrandProfileSlice = createSlice({
    name: 'createBrandProfile',
    initialState: initialState,
    reducers: {
        setBrandProfile: (
            state: Draft<InitialState>,
            action: PayloadAction<[]>
        ) => {
            state.brandProfileData = action.payload;
        },
        setBrandList: (
            state: Draft<InitialState>,
            action: PayloadAction<[]>
        ) => {
            state.brandList = action.payload;
        },
        setJobTitleList: (
            state: Draft<InitialState>,
            action: PayloadAction<[]>
        ) => {
            state.jobTitleList = action.payload;
        },
        setFacebookConnect: (
            state: Draft<InitialState>,
            action: PayloadAction<[]>
        ) => {
            state.faceBookConnect = action.payload;
        },
        
    }
});

export const { setBrandProfile, setBrandList, setJobTitleList, setFacebookConnect } =
    createBrandProfileSlice.actions;

export default createBrandProfileSlice.reducer;
