import { PLATFORMS } from "@core/constants/enum";
import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import API from "../api";
import {
  TiktokAdAccount, TiktokProfiles, SnapChatProfiles, FacebookProfiles, SnapchatAdAccount,
  FacebookAdAccount,
  SnapchatCampaign,
  FacebookCampaign,
  TiktokCampaign,
  TiktokIdentity,
  TiktokInterests,
  SnapchatInterests,
  FacebookInterests,
  SnapchatPixels,
  TiktokPixels,
  FacebookPixels,
  SnapchatOptimizeEvents,
  TiktokOptimizeEvents,
  FacebookOptimizeEvents,
  SnapchatCustomAudience,
  FacebookCustomAudience,
  TiktokCustomAudience,
  CreativesProgress,
  CreativesData,
  SchedulerHistoryData,
  FacebookPageData,
  InstagramPageData,
  UploadSesId,
  ReviewPageAdsetData,
  SchedulerDraftData,
  PresetsData,
  SelectedPresetsData,
  reUseBatchCreatives,
  SchedulerHistoryRowData
} from "../Slice/autoSchedulerSlice";
import { autoschedulerNotificationSuccess, notificationFail, notificationSuccess, autoschedulerNotificationFail } from "../Slice/notificationSlice";
import { ScreenLoading } from "store/Slice/AuthSlice";

export const getProfiles = createAsyncThunk(
  "getProfiles",
  async ({ platform }: any, { dispatch }) => {
    try {
      const response = await API.post(
        `/api/v1/common/profile/`, platform
      );
      if (platform?.ad_platform == PLATFORMS.SNAP) {
        dispatch(SnapChatProfiles(response?.data?.data));
      } else if (platform?.ad_platform == PLATFORMS.FACEBOOK) {
        dispatch(FacebookProfiles(response?.data?.data));
      } else if (platform?.ad_platform == PLATFORMS.TIKTOK) {
        dispatch(TiktokProfiles(response?.data?.data));
      }
      return response;
    } catch (err: any) {
      return err;
    }
  },
);

export const getAdAccount = createAsyncThunk(
  "getAdAccount",
  async ({ platform }: any, { dispatch }) => {
    try {
      const response = await API.post(
        `/api/v1/common/get-adaccount-using-profile-id/`, platform
      );
      if (platform?.ad_platform == PLATFORMS.SNAP) {
        dispatch(SnapchatAdAccount(response?.data?.data));
      } else if (platform?.ad_platform == PLATFORMS.FACEBOOK) {
        dispatch(FacebookAdAccount(response?.data?.data));
      } else if (platform?.ad_platform == PLATFORMS.TIKTOK) {
        dispatch(TiktokAdAccount(response?.data?.data));
      }
      return response;
    } catch (err: any) {
      return err;
    }
  },
);



export const getIdentity = createAsyncThunk(
  "getIdentity",
  async ({ data }: any, { dispatch }) => {
    try {
      const response = await API.post(
        `/api/v1/common/identity/`, data
      );
      dispatch(TiktokIdentity(response?.data?.data));
      return response;
    } catch (err: any) {
      return err;
    }
  },
);

export const getCampaign = createAsyncThunk(
  "getCampaign",
  async ({ campaignReq, token }: any, { dispatch }) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    try {
      const response = await API.post(
        `/api/v1/common/get-campaign-using-adaccount/`, campaignReq, token
      );
      setTimeout(() => {
        source.cancel('Operation canceled by the user.');
      })

      if (campaignReq?.ad_platform == PLATFORMS.SNAP) {
        dispatch(SnapchatCampaign(response?.data?.data));
      } else if (campaignReq?.ad_platform == PLATFORMS.FACEBOOK) {
        dispatch(FacebookCampaign(response?.data?.data));
      } else if (campaignReq?.ad_platform == PLATFORMS.TIKTOK) {
        dispatch(TiktokCampaign(response?.data?.data));
      }
      return response;
    } catch (err: any) {
      return err;
    }
  },
);

export const getInterests = createAsyncThunk(
  "getInterests",
  async ({ interestReq, token }: any, { dispatch }) => {
    const CancelToken = axios.CancelToken;
    const source = CancelToken.source();

    try {
      const response = await API.post(
        `/api/v1/common/interest/`, interestReq, token
      );
      setTimeout(() => {
        source.cancel('Operation canceled by the user.');
      },)
      if (interestReq?.ad_platform == PLATFORMS.SNAP && response?.data?.data) {
        dispatch(SnapchatInterests(response?.data?.data));
      } else if (interestReq?.ad_platform == PLATFORMS.FACEBOOK && response?.data?.data) {
        dispatch(FacebookInterests(response?.data?.data));
      } else if (interestReq?.ad_platform == PLATFORMS.TIKTOK && response?.data?.data) {
        dispatch(TiktokInterests(response?.data?.data));
      }
      return response;
    } catch (err: any) {
      return err;
    }
  },
);

export const getPixels = createAsyncThunk(
  "getPixels",
  async ({ platform }: any, { dispatch }) => {
    try {
      const response = await API.post(
        `/api/v1/common/get-pixel-using-adaccount/`, platform
      );
      if (platform?.ad_platform == PLATFORMS.SNAP) {
        dispatch(SnapchatPixels(response?.data?.data));
      } else if (platform?.ad_platform == PLATFORMS.FACEBOOK) {
        dispatch(FacebookPixels(response?.data?.data));
      } else if (platform
        ?.ad_platform == PLATFORMS.TIKTOK) {
        dispatch(TiktokPixels(response?.data?.data));
      }
      return response;
    } catch (err: any) {
      dispatch(autoschedulerNotificationFail(err.response.data.message));
      return err;
    }
  },
);

export const getOptimizeEvents = createAsyncThunk(
  "getOptimizeEvents",
  async ({ platformData }: any, { dispatch }) => {
    try {
      const response = await API.post(
        `/api/v1/common/get-optimize-event-using-adaccount/`, platformData
      );
      if (platformData?.ad_platform == PLATFORMS.SNAP) {
        dispatch(SnapchatOptimizeEvents(response?.data?.data));
      } else if (platformData?.ad_platform == PLATFORMS.FACEBOOK) {
        dispatch(FacebookOptimizeEvents(response?.data?.data));
      } else if (platformData
        ?.ad_platform == PLATFORMS.TIKTOK) {
        dispatch(TiktokOptimizeEvents(response?.data?.data));
      }
      return response;
    } catch (err: any) {
      return err;
    }
  },
);

export const getCustomAudience = createAsyncThunk(
  "getCustomAudience",
  async ({ platformData }: any, { dispatch }) => {
    try {
      const response = await API.post(
        `/api/v1/common/get-custom-audience-using-adaccount/`, platformData
      );
      if (platformData?.ad_platform == PLATFORMS.SNAP) {
        dispatch(SnapchatCustomAudience(response?.data?.data));
      } else if (platformData?.ad_platform == PLATFORMS.FACEBOOK) {
        dispatch(FacebookCustomAudience(response?.data?.data));
      } else if (platformData
        ?.ad_platform == PLATFORMS.TIKTOK) {
        dispatch(TiktokCustomAudience(response?.data?.data));
      }
      return response;
    } catch (err: any) {
      return err;
    }
  },
);

export const postCreativeUpload = createAsyncThunk(
  "getCustomAudience",
  async ({ data }: any, { dispatch }) => {
    try {
      var percentages: any = 0
      const response = await API.post(
        `/api/v1/common/ad-creative-upload/`, data,
        {
          onUploadProgress
            : (progressEvent) => {
              if (progressEvent?.progress) {
                percentages = +((progressEvent?.progress * 100).toFixed(2))
                dispatch(CreativesProgress(percentages));
              }
            },
        }
      )
      dispatch(CreativesData(response?.data?.data));
      dispatch(autoschedulerNotificationSuccess("Your creatives were successfully uploaded."));
      return response;
    } catch (err: any) {
      return err;
    }
  },
);

export const deleteCreativeUpload = createAsyncThunk(
  "getCustomAudience",
  async ({ data }: any, { dispatch }) => {
    try {
      const response = await API.post(
        `/api/v1/common/ad-creative-delete/`, data
      );
      dispatch(autoschedulerNotificationSuccess("Your creatives were successfully removed."));
      return response;
    } catch (err: any) {
      return err;
    }
  },
);

export const updateCreativeUpload = createAsyncThunk(
  "updateCreativeUpload",
  async ({ data }: any, { dispatch }) => {
    try {
      const response = await API.put(
        `/api/v1/common/bulk-ad-creative-landingpage-url-update/`, data
      );
      return response;
    } catch (err: any) {
      return err;
    }
  },
);

export const getSchedulerHistory = createAsyncThunk(
  "getSchedulerHistory",
  async ({ search, group, page }: any, { dispatch }) => {
    try {
      const response = await API.get(
        `/api/v1/common/schedule-historys-progress/?search=${search}&ordering=&scraper_group=${group}&page=${page + 1}`);
      dispatch(SchedulerHistoryData(response?.data))
      return response;
    } catch (err: any) {
      dispatch(autoschedulerNotificationFail(err.response.data.message));
      return err;
    }
  },
);

export const getSchedulerHistoryRowData = createAsyncThunk(
  "getSchedulerHistory",
  async ({ data }: any, { dispatch }) => {
    try {
      const response = await API.post(
        `/api/v1/common/schedule-historys-details/`, data);
        dispatch(ScreenLoading(false));
        dispatch(SchedulerHistoryRowData(response?.data?.data))
      return response;
    } catch (err: any) {
      dispatch(autoschedulerNotificationFail(err.response.data.message));
      return err;
    }
  },
);

function sortByName(value: any) {
  return value?.sort((a: any, b: any) => {
    const nameA = a.page_name.toUpperCase();
    const nameB = b.page_name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
}

function sortByInstaName(value: any) {
  return value?.sort((a: any, b: any) => {
    const nameA = a.instagram_account_name.toUpperCase();
    const nameB = b.instagram_account_name.toUpperCase();
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }
    return 0;
  });
}

export const getFacebookPages = createAsyncThunk(
  "getFacebookPages",
  async ({ data }: any, { dispatch }) => {
    try {
      const response = await API.post(
        `/api/v1/facebook/facebook-pages-list/`, data
      );

      var temp = [];
      temp = sortByName(response?.data?.data);
      dispatch(FacebookPageData(temp))
      return response;
    } catch (err: any) {
      dispatch(autoschedulerNotificationFail(err.response.data.message));
      return err;
    }
  },
);

export const getInstagramPages = createAsyncThunk(
  "getInstagramPages",
  async ({ data }: any, { dispatch }) => {
    try {
      const response = await API.post(
        `/api/v1/facebook/instagram-account-using-business-id/`, data
      );
      var temp = [];
      temp = sortByInstaName(response?.data?.data);
      dispatch(InstagramPageData(temp));
      return response;
    } catch (err: any) {
      dispatch(autoschedulerNotificationFail(err.response.data.message));
      return err;
    }
  },
);

export const postRetryScheduleBatch = createAsyncThunk(
  "postRetryScheduleBatch",
  async ({ data }: any, { dispatch }) => {
    try {
      const response = await API.post(
        `/api/v1/common/retry-schedule-batch/`, data
      );
      return response;
    } catch (err: any) {
      dispatch(autoschedulerNotificationFail(err.response.data.message));
      return err;
    }
  },
);

export const postStopScheduling = createAsyncThunk(
  "postStopScheduling",
  async ({ data }: any, { dispatch }) => {
    try {
      const response = await API.post(
        `/api/v1/common/stop-scheduling/`, data
      );
      return response;
    } catch (err: any) {
      dispatch(autoschedulerNotificationFail(err.response.data.message));
      return err;
    }
  },
);

export const postReuseHistoryBatch = createAsyncThunk(
  "postReuseHistoryBatch",
  async ({ data }: any, { dispatch }) => {
    try {
      const response = await API.post(
        `/api/v1/common/reuse-history-batch/`, data
      );
      dispatch(autoschedulerNotificationSuccess(response.data.message));
      return response;
    } catch (err: any) {
      dispatch(autoschedulerNotificationFail(err.response.data.message));
      return err;
    }
  },
);

export const getCountryTimeZone = createAsyncThunk(
  "getCountryTimeZone",
  async ({ timeZone }: any, { dispatch }) => {
    try {
      const response = await fetch(`http://worldtimeapi.org/api/timezone/${timeZone}`, { mode: 'cors' })
      .then((response) => response.json())
      .then((data) => { return data}).catch(error => console.error(error))
      return response;
    } catch (err: any) {
  throw err;
}
    },
  );
export const getGenerateUploadsesId = createAsyncThunk(
  "getGenerateUploadsesId",
  async ({ data }: any, { dispatch }) => {
    try {
      const response = await API.get(
        `/api/v1/common/generate-uploadses-id/`
      );
      dispatch(UploadSesId(response.data.data.uploadsesid));
      return response;
    } catch (err: any) {
      dispatch(autoschedulerNotificationFail(err.response.data.message));
      return err;
    }
  },
);

export const fetchLatestAdaccountData = createAsyncThunk(
  "fetchLatestAdaccountData",
  async ({ data }: any, { dispatch }) => {
    try {
      const response = await API.post(
        `/api/v1/common/fetch-latest-adaccount-data/`, data
      );
      dispatch(UploadSesId(response.data.data.uploadsesid));
      return response;
    } catch (err: any) {
      dispatch(autoschedulerNotificationFail(err.response.data.message));
      return err;
    }
  },
);

export const reviewPageAdsetCalculation = createAsyncThunk(
  "reviewPageAdsetCalculation",
  async ({ _request }: any, { dispatch }) => {
    try {
      const response = await API.post(
        `/api/v1/common/review-page-adset-calculation/`, _request
      );
      dispatch(ReviewPageAdsetData(response.data.data));
      return response;
    } catch (err: any) {
      dispatch(autoschedulerNotificationFail(err.response.data.message));
      return err;
    }
  },
);

export const postCreateNewScheduler = createAsyncThunk(
  "postCreateNewScheduler",
  async ({ schedulerData }: any, { dispatch }) => {
    try {
      const response = await API.post(
        `/api/v1/common/create-scheduler/`, schedulerData
      );
      return response;
    } catch (err: any) {
      dispatch(autoschedulerNotificationFail(err.response.data.message));
      return err;
    }
  },
);

export const postAutoSchedulerDraft = createAsyncThunk(
  "postAutoSchedulerDraft",
  async ({ data }: any, { dispatch }) => {
    try {
      const response = await API.post(
        `/api/v1/common/auto-scheduler-draft/`, data
        );
        dispatch(SchedulerDraftData(response.data.data[0]));
      if(response.data.data[0]?.draft_data?.uploadsesId){
        dispatch(UploadSesId(response.data.data[0]?.draft_data?.uploadsesId));
      }else{
        dispatch(getGenerateUploadsesId({}));
      }
      return response;
    } catch (err: any) {
      dispatch(autoschedulerNotificationFail(err.response.data.message));
      return err;
    }
  },
);

export const getAutoSchedulerDraft = createAsyncThunk(
  "getAutoSchedulerDraft",
  async ({ }: any, { dispatch }) => {
    try {
      const response = await API.get(
        `/api/v1/common/auto-scheduler-draft/`
        );
      dispatch(SchedulerDraftData(response.data.data[0]));
      if(response.data.data[0]?.draft_data?.uploadsesId){
        dispatch(UploadSesId(response.data.data[0]?.draft_data?.uploadsesId));
      }else{
        dispatch(getGenerateUploadsesId({}));
      }
      return response;
    } catch (err: any) {
      dispatch(autoschedulerNotificationFail(err.response.data.message));
      return err;
    }
  },
);

export const getAllPresets = createAsyncThunk(
  "getAllPresets",
  async ({ data }: any, { dispatch }) => {
    try {
      const response = await API.get(
        `/api/v1/common/schedule-presets/?with_schedule_preset_settings_data=True`
        );
        var tempData: any = response?.data;
        tempData?.splice(0,0,{
                  created_at: "2023-03-28T11:46:03.878644",
                  created_by: "Nilay kadia",
                  id: 0,
                  preset_name: "Add New",
                  schedule_presets_settings: []
                })
                dispatch(PresetsData(tempData))
      // dispatch(SchedulerDraftData(response.data.data[0]));
      // if(response.data.data[0]?.draft_data?.uploadsesId){
      //   dispatch(UploadSesId(response.data.data[0]?.draft_data?.uploadsesId));
      // }else{
      //   dispatch(getGenerateUploadsesId({}));
      // }
      return response;
    } catch (err: any) {
      dispatch(autoschedulerNotificationFail(err.response.data.message));
      return err;
    }
  },
);

export const postNewPresets = createAsyncThunk(
  "postNewPresets",
  async ({data}: any, { dispatch }) => {
    try {
      const response = await API.post(
        `/api/v1/common/schedule-presets/`, data
      );
      // dispatch(SchedulerDraftData(response.data.data[0]));
      // if(response.data.data[0]?.draft_data?.uploadsesId){
      //   dispatch(UploadSesId(response.data.data[0]?.draft_data?.uploadsesId));
      // }else{
      //   dispatch(getGenerateUploadsesId({}));
      // }
      dispatch(PresetsData(response.data))
      return response;
    } catch (err: any) {
      dispatch(autoschedulerNotificationFail(err.response.data.message));
      return err;
    }
  },
);

export const clearDraft = createAsyncThunk(
  "clearDraftData",
  async ({id}: any, { dispatch }) => {
    try {
      const response = await API.delete(
        `/api/v1/common/auto-scheduler-draft/${id}/`
      );
      dispatch(autoschedulerNotificationSuccess("Your draft were successfully deleted. "));
      dispatch(SchedulerDraftData({}));
      return response;
    } catch (err: any) {
      dispatch(autoschedulerNotificationFail(err.response.data.message));
    }

  })

export const getSinglPresetsData = createAsyncThunk(
  "getSinglPresetsData",
  async ({Id}: any, { dispatch }) => {
    try {
      const response = await API.get(
        `/api/v1/common/schedule-presets/${Id}/?with_schedule_preset_settings_data=True`
      );
      // dispatch(SchedulerDraftData(response.data.data[0]));
      // if(response.data.data[0]?.draft_data?.uploadsesId){
      //   dispatch(UploadSesId(response.data.data[0]?.draft_data?.uploadsesId));
      // }else{
      //   dispatch(getGenerateUploadsesId({}));
      // }
      dispatch(SelectedPresetsData(response?.data?.schedule_presets_settings))
      return response;
    } catch (err: any) {
      dispatch(autoschedulerNotificationFail(err.response.data.message));
      return err;
    }
  },
);

export const deletePresetsData = createAsyncThunk(
  "deletePresetsData",
  async ({Id}: any, { dispatch }) => {
    try {
      const response = await API.delete(
        `/api/v1/common/schedule-presets/${Id}/`
      );
      return response;
    } catch (err: any) {
      dispatch(autoschedulerNotificationFail(err.response.data.message));
      return err;
    }
  },
);



export const reuseCreatives = createAsyncThunk(
  "postNewPresets",
  async ({reuseBatchData}: any, { dispatch }) => {
    try {
      const response = await API.post(
        `/api/v1/common/insert-reuse-creatives/`, reuseBatchData
      );

      let tempArr: any = [];

      if(response) {
        response.data.data.map((data: any, index: any) => {
          data.uploaded_creative.map((creatives: any, i: any) => {
            tempArr.push(creatives)
          })
        })
      }

      dispatch(reUseBatchCreatives(tempArr));

      return response;
    } catch (err: any) {
      dispatch(autoschedulerNotificationFail(err.response.data.message));
      return err;
    }
  },
);
