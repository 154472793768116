// ** React Imports
import { useEffect, useCallback, useState, useRef } from "react";

// ** MUI Imports
import {
  Box,
  Button,
  Grid,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { debounce } from "@mui/material/utils";

// ** Third Party Library
import { useNavigate } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// ** Redux
import { useAppDispatch, useAppSelector } from "store/store";
import {
  getMyJobsRequestContent,
  getMyJobSearch,
  getBrandInfo,
} from "store/Thunk/createJobThunk";

// ** Custom Components
import { route_info } from "navigation/route";
import { MARKET_PLACE } from "@core/constants/title-constants";
import {
  CustomRightArrow,
  CustomLeftArrow,
} from "@core/constants/global-constants";
import CompleteBrandProfileDialog from "components/Dialogs/CompleteBrandProfile";
import ListCard from "components/ListCard";
import MyJobsCard from "components/MyJobsCard";
import ShowMore from "components/ShowMore";

// ** Assets
import { PixelMelonHiSvg, PixelMelonFalling } from "assets";

export default function MyBrandJobs() {
  const navigate = useNavigate();
  const parentRef: any = useRef(null);
  const dispatch: any = useAppDispatch();
  const { loadingState, myJobs, archivedJobs } = useAppSelector(
    (state: any) => state.createJobReducer
  );
  let jobBrandProfile: any = useAppSelector(
    (state: any) => state.createJobReducer.jobBrandDetail
  );
  const [openRedirectToCreate, setOpenRedirectToCreate] =
    useState<boolean>(false);
  const [page, setPage] = useState(1);
  const [responsive, setResponsive] = useState<any>({
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  });

  useEffect(() => {
    dispatch(getMyJobsRequestContent({ flag: "active", page: page, navigate }));
    dispatch(getMyJobsRequestContent({ flag: "closed", page: page, navigate }));
    dispatch(getBrandInfo(dispatch));
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (parentRef.current) {
        const parentWidth = parentRef.current.clientWidth;
        setResponsive({
          superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: parentWidth / 308,
            slidesToSlide: 4,
          },
          desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: parentWidth / 308,
            slidesToSlide: 3,
          },
          tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: parentWidth / 308,
            slidesToSlide: 2,
          },
          mobile: {
            breakpoint: { max: 464, min: 0 },
            items: parentWidth / 308,
            slidesToSlide: 1,
          },
        });
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (parentRef.current) {
      const parentWidth = parentRef.current.clientWidth;
      setResponsive({
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: parentWidth / 308,
          slidesToSlide: 4,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: parentWidth / 308,
          slidesToSlide: 3,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: parentWidth / 308,
          slidesToSlide: 2,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: parentWidth / 308,
          slidesToSlide: 1,
        },
      });
    }
  }, [parentRef.current]);

  const handleCreateJobClick = () => {
    if (jobBrandProfile && jobBrandProfile.length <= 0) {
      setOpenRedirectToCreate(true);
    } else {
      navigate(route_info.market_place.create_new_job);
    }
  };

  const handleSearch = useCallback(
    debounce((data) => {
      let temp = {
        searchTxt: data.target.value,
      };
      dispatch(getMyJobSearch({ data: temp }));
    }, 500),
    []
  );

  const handleCompleteBrandProfileClick = () => {
    setOpenRedirectToCreate(false);
    navigate(route_info.market_place.my_brand_profile);
  };

  const handleSubmissionHistoryShowMore = () => {
    setPage(page + 1);
    let data = {
      flag: "closed",
      page: page + 1,
      navigate,
      myArchivedJobList: archivedJobs,
    };
    dispatch(getMyJobsRequestContent(data));
  };

  return (
    <Box className="all-jobs">
      <Box className="job-title-block" sx={{ flexWrap: "wrap" }}>
        <Typography variant="h6">
          {MARKET_PLACE.REQUEST_CONTENT.ACTIVE_JOBS}
        </Typography>
        <Button
          variant="contained"
          className="upload-content"
          onClick={() => handleCreateJobClick()}
        >
          {MARKET_PLACE.REQUEST_CONTENT.CREATE_NEW_JOB}
        </Button>
      </Box>
      {loadingState ? (
        <Box className="loader">
          <CircularProgress size={20} />
        </Box>
      ) : myJobs && myJobs.length ? (
        <Box ref={parentRef}>
          <Carousel
            className="job-list-carousel"
            customRightArrow={<CustomRightArrow />}
            customLeftArrow={<CustomLeftArrow />}
            responsive={responsive}
          >
            {myJobs?.map((jobData: any, index: number) => (
              <Grid item md={4} sm={6} xs={12}>
                <MyJobsCard cardData={jobData} />
              </Grid>
            ))}
          </Carousel>
        </Box>
      ) : (
        !loadingState && (
          <Box className="no-data-found-block">
            <Box
              component="img"
              src={PixelMelonHiSvg}
              className="no-data-found-img"
              sx={{ mb: "15px" }}
            />
            <Box sx={{ display: "flex", flexDirection: "row" }}>
              <Typography variant="subtitle2" sx={{ mr: "3px" }}>
                {MARKET_PLACE.REQUEST_CONTENT.IT_IS_PRETTY}
              </Typography>
              <Button
                className="no-data-found-btn"
                variant="text"
                onClick={() => navigate(route_info.market_place.create_new_job)}
              >
                Create a new job
              </Button>
              <Typography variant="subtitle2">
                {MARKET_PLACE.REQUEST_CONTENT.TO_START_COLLECTING}
              </Typography>
            </Box>
          </Box>
        )
      )}
      <>
        <Grid container sx={{ alignItems: "center", mb: 4 }}>
          <Grid item md={6} sm={3} xs={12}>
            <Typography variant="h6">
              {MARKET_PLACE.REQUEST_CONTENT.ARCHIVED_JOBS}
            </Typography>
          </Grid>
          <Grid item md={4} sm={4} xs={12} sx={{ py: { xs: 2 } }}>
            <TextField
              fullWidth
              size="small"
              placeholder="Search closed submissions..."
              label="Search closed submissions..."
              onChange={(e: any) => handleSearch(e)}
            />
          </Grid>
          <Grid item md={2} sm={5} xs={12} className="search-box">
            <ShowMore handleOnClick={handleSubmissionHistoryShowMore} />
          </Grid>
        </Grid>
        {/* <Grid container> */}
        <Box gap={1} display="flex" flexDirection={"row"} flexWrap={"wrap"}>
          {archivedJobs?.length > 0 ? (
            archivedJobs?.map((jobData: any, index: number) => (
              <ListCard
                style={{ width: "283px" }}
                cardData={jobData}
                redirectURL={route_info.market_place.my_job_detail}
                isArchived={true}
                isSubmission={false}
              />
            ))
          ) : (
            <Box className="no-data-found-block">
              <Box
                component="img"
                src={PixelMelonFalling}
                className="no-data-found-img"
              />
              <Typography variant="subtitle2">
                {MARKET_PLACE.REQUEST_CONTENT.NO_JOBS}
              </Typography>
            </Box>
          )}
        </Box>
        {/* </Grid> */}
      </>
      <CompleteBrandProfileDialog
        isOpen={openRedirectToCreate}
        onClickClose={handleCompleteBrandProfileClick}
      />
    </Box>
  );
}
