// ** React
import { ChangeEvent, useState, useEffect, useCallback } from "react";

// ** MUI Imports
import { Box, Typography } from "@mui/material";
import {
  DataGrid,
  GridColumns,
  GridRenderCellParams,
  GridSortModel,
} from "@mui/x-data-grid";

// ** Custom Component
import TimesaverToolbar from "views/table/TimesaverToolbar";

// ** Icons
import SnapchatIcon from "icon/snapchat";
import TiktokIcon from "icon/tiktok";
import FacebookIcon from "icon/facebook";
import UsaIcon from "icon/usa";
import BrazilImage from "icon/brazil";

type DataGridRowType = {
  age: string;
  avatar: string;
  city: string;
  email: string;
  experience: string;
  user_name: string;
  id: number;
  post: string;
  optimize: true;
  profile: string;
  status: number;
  color: string;
  budget: string;
  bid: string;
  batch: string;
  platform: string;
  Campaign: string;
  Creatives: string;
  Countries: string;
};

type SortType = "asc" | "desc" | undefined | null;

const columns: GridColumns = [
  {
    flex: 0.14,
    minWidth: 100,
    field: "batch",
    headerName: "Batch",
    renderCell: (params: GridRenderCellParams) => (
      <Box className="sync-align-center" sx={{ cursor: "pointer" }}>
        <Typography noWrap variant="subtitle2" sx={{ color: "text.primary" }}>
          {params.row.batch}statusObj
        </Typography>
      </Box>
    ),
  },
  {
    flex: 0.1,
    minWidth: 10,
    headerName: "user_name",
    field: "USER",
    renderCell: (params: GridRenderCellParams) => (
      <Typography
        variant="body2"
        sx={{ color: "text.primary", cursor: "pointer" }}
      >
        {params.row.user_name}
      </Typography>
    ),
  },
  {
    flex: 0.12,
    minWidth: 120,
    headerName: "PLATFORM",
    field: "platform",
    renderCell: (params: GridRenderCellParams) => (
      <Box sx={{ display: "flex", cursor: "pointer" }}>
        <Box sx={{ mr: 2 }}>
          <FacebookIcon active={true} />
        </Box>
        <Box sx={{ mr: 2 }}>
          <SnapchatIcon active={true} />
        </Box>
        <Box sx={{ mr: 2 }}>
          <TiktokIcon active={true} />
        </Box>
      </Box>
    ),
  },
  {
    flex: 0.24,
    minWidth: 140,
    field: "Campaign",
    headerName: "Campaign",
    renderCell: (params: GridRenderCellParams) => (
      <Box className="sync-direction-column" sx={{ cursor: "pointer" }}>
        <Box>
          <Typography variant="body2" sx={{ color: "text.primary" }}>
            Deep House - Week 44 (2022) [Post]
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" sx={{ color: "text.primary" }}>
            [Traffic] - 2022housew44-045
          </Typography>
        </Box>
      </Box>
    ),
  },
  {
    flex: 0.09,
    minWidth: 110,
    field: "Creatives",
    headerName: "Creatives",
    renderCell: (params: GridRenderCellParams) => (
      <Box>
        <Typography
          variant="body2"
          sx={{ color: "text.primary", cursor: "pointer" }}
        >
          2 creatives
        </Typography>
      </Box>
    ),
  },
  {
    flex: 0.13,
    minWidth: 110,
    field: "color",
    headerName: "Countries",
    renderCell: (params: GridRenderCellParams) => (
      <Box sx={{ cursor: "pointer" }}>
        <Typography variant="body2" sx={{ color: "text.primary" }}>
          <UsaIcon /> United States
        </Typography>
        <Typography variant="body2" sx={{ color: "text.primary" }}>
          <BrazilImage /> Brazil
        </Typography>
      </Box>
    ),
  },
];

interface Props {
  setDialogOpen?: any;
  setNestedDialog?: any;
}

export default function PlatformTableDialog({
  setDialogOpen,
  setNestedDialog,
}: Props) {
  const rowsData: any = [
    {
      age: "21",
      avatar: "7.png",
      city: "Łazy",
      email: "susanna.Lind57@gmail.com",
      experience: "7 Years",
      user_name: "User name1",
      id: 1,
      post: "Admin",
      optimize: true,
      profile: "Lofi Fruits",
      status: 1,
      color: "#72E128",
      budget: "€500",
      bid: "€0.22 -> €0.23",
      batch: "Batch 10 Oct. 08:29",
      platform: "",
      Campaign: "",
      Creatives: "",
      Countries: "",
    },
    {
      age: "21",
      avatar: "7.png",
      city: "Łazy",
      email: "susanna.Lind57@gmail.com",
      experience: "7 Years",
      user_name: "User name2",
      id: 2,
      post: "Admin",
      optimize: true,
      profile: "Lofi Fruits",
      status: 1,
      color: "#72E128",
      budget: "€500",
      bid: "€0.22 -> €0.23",
      batch: "Batch 11 Oct. 08:29",
      platform: "",
      Campaign: "",
      Creatives: "",
      Countries: "",
    },
    {
      age: "21",
      avatar: "7.png",
      city: "Łazy",
      email: "susanna.Lind57@gmail.com",
      experience: "7 Years",
      user_name: "User name3",
      id: 3,
      post: "Admin",
      optimize: true,
      profile: "Lofi Fruits",
      status: 1,
      color: "#72E128",
      budget: "€500",
      bid: "€0.22 -> €0.23",
      batch: "Batch 12 Oct. 08:29",
      platform: "",
      Campaign: "",
      Creatives: "",
      Countries: "",
    },
    {
      age: "21",
      avatar: "7.png",
      city: "Łazy",
      email: "susanna.Lind57@gmail.com",
      experience: "7 Years",
      user_name: "User name4",
      id: 4,
      post: "Admin",
      optimize: true,
      profile: "Lofi Fruits",
      status: 1,
      color: "#72E128",
      budget: "€500",
      bid: "€0.22 -> €0.23",
      batch: "Batch 13 Oct. 08:29",
      platform: "",
      Campaign: "",
      Creatives: "",
      Countries: "",
    },
    {
      age: "21",
      avatar: "7.png",
      city: "Łazy",
      email: "susanna.Lind57@gmail.com",
      experience: "7 Years",
      user_name: "User name5",
      id: 5,
      post: "Admin",
      optimize: true,
      profile: "Lofi Fruits",
      status: 1,
      color: "#72E128",
      budget: "€500",
      bid: "€0.22 -> €0.23",
      batch: "Batch 14 Oct. 08:29",
      platform: "",
      Campaign: "",
      Creatives: "",
      Countries: "",
    },
    {
      age: "21",
      avatar: "7.png",
      city: "Łazy",
      email: "susanna.Lind57@gmail.com",
      experience: "7 Years",
      user_name: "User name6",
      id: 6,
      post: "Admin",
      optimize: true,
      profile: "Lofi Fruits",
      status: 1,
      color: "#72E128",
      budget: "€500",
      bid: "€0.22 -> €0.23",
      batch: "Batch 15 Oct. 08:29",
      platform: "",
      Campaign: "",
      Creatives: "",
      Countries: "",
    },
    {
      age: "21",
      avatar: "7.png",
      city: "Łazy",
      email: "susanna.Lind57@gmail.com",
      experience: "7 Years",
      user_name: "User name7",
      id: 7,
      post: "Admin",
      optimize: true,
      profile: "Lofi Fruits",
      status: 1,
      color: "#72E128",
      budget: "€500",
      bid: "€0.22 -> €0.23",
      batch: "Batch 16 Oct. 08:29",
      platform: "",
      Campaign: "",
      Creatives: "",
      Countries: "",
    },
    {
      age: "21",
      avatar: "7.png",
      city: "Łazy",
      email: "susanna.Lind57@gmail.com",
      experience: "7 Years",
      user_name: "User name8",
      id: 8,
      post: "Admin",
      optimize: true,
      profile: "Lofi Fruits",
      status: 1,
      color: "#72E128",
      budget: "€500",
      bid: "€0.22 -> €0.23",
      batch: "Batch 17 Oct. 08:29",
      platform: "",
      Campaign: "",
      Creatives: "",
      Countries: "",
    },
    {
      age: "21",
      avatar: "7.png",
      city: "Łazy",
      email: "susanna.Lind57@gmail.com",
      experience: "7 Years",
      user_name: "User name9",
      id: 9,
      post: "Admin",
      optimize: true,
      profile: "Lofi Fruits",
      status: 1,
      color: "#72E128",
      budget: "€500",
      bid: "€0.22 -> €0.23",
      batch: "Batch 18 Oct. 08:29",
      platform: "",
      Campaign: "",
      Creatives: "",
      Countries: "",
    },
    {
      age: "21",
      avatar: "7.png",
      city: "Łazy",
      email: "susanna.Lind57@gmail.com",
      experience: "7 Years",
      user_name: "User name10",
      id: 10,
      post: "Admin",
      optimize: true,
      profile: "Lofi Fruits",
      status: 1,
      color: "#72E128",
      budget: "€500",
      bid: "€0.22 -> €0.23",
      batch: "Batch 19 Oct. 08:29",
      platform: "",
      Campaign: "",
      Creatives: "",
      Countries: "",
    },
    {
      age: "21",
      avatar: "7.png",
      city: "Łazy",
      email: "susanna.Lind57@gmail.com",
      experience: "7 Years",
      user_name: "User name11",
      id: 11,
      post: "Admin",
      optimize: true,
      profile: "Lofi Fruits",
      status: 1,
      color: "#72E128",
      budget: "€500",
      bid: "€0.22 -> €0.23",
      batch: "Batch 20 Oct. 08:29",
      platform: "",
      Campaign: "",
      Creatives: "",
      Countries: "",
    },
    {
      age: "21",
      avatar: "7.png",
      city: "Łazy",
      email: "susanna.Lind57@gmail.com",
      experience: "7 Years",
      user_name: "User name12",
      id: 12,
      post: "Admin",
      optimize: true,
      profile: "Lofi Fruits",
      status: 1,
      color: "#72E128",
      budget: "€500",
      bid: "€0.22 -> €0.23",
      batch: "Batch 21 Oct. 08:29",
      platform: "",
      Campaign: "",
      Creatives: "",
      Countries: "",
    },
    {
      age: "21",
      avatar: "7.png",
      city: "Łazy",
      email: "susanna.Lind57@gmail.com",
      experience: "7 Years",
      user_name: "User name13",
      id: 13,
      post: "Admin",
      optimize: true,
      profile: "Lofi Fruits",
      status: 1,
      color: "#72E128",
      budget: "€500",
      bid: "€0.22 -> €0.23",
      batch: "Batch 22 Oct. 08:29",
      platform: "",
      Campaign: "",
      Creatives: "",
      Countries: "",
    },
  ];

  function loadServerRows(currentPage: number, data: DataGridRowType[]) {
    return data.slice(currentPage * pageSize, (currentPage + 1) * pageSize);
  }

  const [page, setPage] = useState(0);
  const [total, setTotal] = useState<number>(0);
  const [sort, setSort] = useState<SortType>("asc");
  const [pageSize, setPageSize] = useState<number>(7);
  const [rows, setRows] = useState<DataGridRowType[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [sortColumn, setSortColumn] = useState<string>("batch");
  const [data] = useState<DataGridRowType[]>(rowsData);

  const fetchTableData = useCallback(
    async (sort: SortType, q: string, column: string) => {
      setTotal(rowsData.length);
      setRows(loadServerRows(page, rowsData));
    },
    [page, pageSize]
  );

  useEffect(() => {
    fetchTableData(sort, searchValue, sortColumn);
  }, [fetchTableData, searchValue, sort, sortColumn]);

  const handleSortModel = (newModel: GridSortModel) => {
    if (newModel.length) {
      setSort(newModel[0].sort);
      setSortColumn(newModel[0].field);
      fetchTableData(newModel[0].sort, searchValue, newModel[0].field);
    } else {
      setSort("asc");
      setSortColumn("batch");
    }
  };

  const escapeRegExp = (value: string) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };

  const handleSearch = (searchValue: string) => {
    setSearchValue(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = data.filter((row) => {
      return Object.keys(row)?.some((field) => {
        // @ts-ignore
        return searchRegex.test(row[field].toString());
      });
    });
  };

  const handledRowClick = () => {
    setDialogOpen(false);
    setNestedDialog(true);
  };

  return (
    <Box>
      <DataGrid
        onRowClick={handledRowClick}
        autoHeight
        pagination
        rows={rows?.length ? rows : data}
        rowCount={total}
        columns={columns}
        pageSize={pageSize}
        sortingMode="server"
        paginationMode="server"
        onSortModelChange={handleSortModel}
        rowsPerPageOptions={[7, 10, 25, 50]}
        onPageChange={(newPage) => setPage(newPage)}
        components={{ Toolbar: TimesaverToolbar }}
        onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
        componentsProps={{
          toolbar: {
            value: searchValue,
            clearSearch: () => handleSearch(""),
            onChange: (event: ChangeEvent<HTMLInputElement>) =>
              handleSearch(event.target.value),
          },
        }}
      />
    </Box>
  );
}
