// ** React Imports
import { ReactNode, useEffect, useState } from "react";

// ** MUI Imports
import { deepmerge } from "@mui/utils";
import { Theme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import GlobalStyles from "@mui/material/GlobalStyles";
import {
  ThemeProvider,
  createTheme,
  responsiveFontSizes,
} from "@mui/material/styles";

// ** Type Imports
// import { Settings } from '../@core/context/settingsContext'

// ** Theme Config

// ** Direction component for LTR or RTL
import DirectionProvider from "../../layouts/components/Direction";

// ** Theme Override Imports
import overrides from "./overrides";
import typography from "./typography";

import palette from "./palette";

// ** Theme
import themeOptions from "./ThemeOptions";
import UserThemeOptions from "../../layouts/UserThemeOptions";

// ** Global Styles
import GlobalStyling from "./globalStyles";
import { Settings } from "../../hooks/context/settingsContext";
import themeConfig from "../../config/themeConfig";
import { PaletteMode } from "@mui/material";
import { Skin } from "../layouts/types";
import { useLocation } from "react-router-dom";
import { route_info } from "../../navigation/route";

interface Props {
  settings: Settings;
  children: ReactNode;
}

const ThemeComponent = (props: Props) => {
  // ** Props
  const { settings, children } = props;
  const [moduleName, setModuleName] = useState<any>("");

  const location = useLocation();

  useEffect(() => {
    const { auth_route, common, market_planner, market_place } = route_info;
    const isAuthRoute = Object.values(auth_route).some((route) =>
      location.pathname.startsWith(route)
    );
    const isCommon = Object.values(common).some((route) =>
      location.pathname.startsWith(route)
    );
    const isMarketPlannerRoute = Object.values(market_planner).some((route) =>
      location.pathname.startsWith(route)
    );
    const isMarketPlaceRoute = Object.values(market_place).some((route) =>
      location.pathname.startsWith(route)
    );

    if (isCommon) {
      setModuleName("");
    } else if (isMarketPlaceRoute) {
      setModuleName("market_place");
    } else if (isMarketPlannerRoute || isAuthRoute) {
      setModuleName("market_planner");
    } else {
      setModuleName("");
    }

    // const marketPlannerRoute: any = Object.values(route_info.market_planner)
    // const marketPlaceRoute: any = Object.values(route_info.market_place)

    // const authRoute: any = Object.values(route_info.auth_route)

    // if (Array.isArray(marketPlannerRoute) && (marketPlannerRoute.includes(location.pathname) || authRoute.includes(location.pathname))) {
    //   setModuleName("market_planner");
    // } else if (Array.isArray(marketPlaceRoute) && marketPlaceRoute.includes(location.pathname)) {
    //   setModuleName("market_place");
    // } else {
    //   setModuleName("");
    // }
  }, [location.pathname]);

  // ** Merged ThemeOptions of Core and User
  const coreThemeConfig = themeOptions(settings);

  // ** Pass ThemeOptions to CreateTheme Function to create partial theme without component overrides
  let theme = createTheme(coreThemeConfig);

  // ** Deep Merge Component overrides of core and user
  const mergeComponentOverrides = (theme: Theme, settings: Settings) =>
    deepmerge(
      { ...overrides(theme, settings) },
      UserThemeOptions()?.components
    );

  // ** Deep Merge Typography of core and user
  const mergeTypography = (theme: Theme) =>
    deepmerge(typography(theme), UserThemeOptions()?.typography);

  // ** Deep Merge primary color of core and user
  const mergePaletteOverrides = (
    mode: PaletteMode,
    skin: Skin,
    moduleName: string
  ) => {
    let overrideStyle = {
      primary: {
        main: "rgba(76, 78, 100, 0.87)",
        dark: "rgba(76, 78, 100, 0.87)",
      },
      background: {
        mainColor: "#F7F7F9",
      },
    };

    if (moduleName) {
      overrideStyle = {
        primary: {
          main: moduleName === "market_planner" ? "#FF4D49" : "#666CFF",
          dark: moduleName === "market_planner" ? "#D13F3C" : "#5459D1",
        },
        background: {
          mainColor: moduleName === "market_planner" ? "#F7F7F9" : "#FFFFFF",
        },
      };
    }
    return deepmerge(palette(mode, skin), overrideStyle);
  };
  // ** Continue theme creation and pass merged component overrides to CreateTheme function
  theme = createTheme(theme, {
    palette: {
      ...mergePaletteOverrides(settings.mode, settings.skin, moduleName),
    },
    components: { ...mergeComponentOverrides(theme, settings) },
    typography: { ...mergeTypography(theme) },
  });

  // ** Set responsive font sizes to true
  if (themeConfig.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return (
    <ThemeProvider theme={theme}>
      <DirectionProvider direction={settings.direction}>
        <CssBaseline />
        <GlobalStyles styles={() => GlobalStyling(theme, settings) as any} />
        {children}
      </DirectionProvider>
    </ThemeProvider>
  );
};

export default ThemeComponent;
