import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "../api";
import {
  notificationFail,
  notificationSuccess,
} from "../Slice/notificationSlice";
import { FacebookData, GoogleData, LinkfireGeneratorConnection, LinkfireGeneratorInfo, LinkfireGeneratorLoginData, LinkfireScrapperConnection, LinkfireScrapperInfo, ScrapperGroupData, SnapchatData, SpootifyConnection, SpootifyData, TiktokData, LatestProfileData } from "../Slice/PlatformSlice";

export const connectWithSnapchat = createAsyncThunk(
    "connectWithSnapchat",
    async ({ code, navigate }: any, { dispatch }) => {

      try {
        const response = await API.get(
          `/api/v1/snapchat/connect-snapchat/?code=${code}`
          );
          dispatch(notificationSuccess(response.data.message));
          return response;
      } catch (err: any) {
        dispatch(notificationFail(err.response.data.message));
        return err;
      }
    },
  );

  export const connectWithFacebook = createAsyncThunk(
    "connectWithFacebook",
    async ({ code, navigate }: any, { dispatch }) => {

      try {
        const response = await API.get(
          `/api/v1/facebook/connect-facebook/?code=${code}`
          );
          dispatch(notificationSuccess(response.data.message));
          return response;
      } catch (err: any) {
        dispatch(notificationFail(err.response.data.message));
        return err;
      }
    },
  );

  export const connectWithTiktok = createAsyncThunk(
    "connectWithTiktok",
    async ({ code, navigate }: any, { dispatch }) => {

      try {
        const response = await API.get(
          `/api/v1/tiktok/connect-tiktok/?code=${code}`
          );
          dispatch(notificationSuccess(response.data.message));

          return response;
      } catch (err: any) {
        dispatch(notificationFail(err.response.data.message));
        return err;
      }
    },
  );

  export const connectWithGoogle = createAsyncThunk(
    "connectWithGoogle",
    async ({ code, navigate }: any, { dispatch }) => {

      try {
        const response = await API.get(
          `/api/v1/Google/connect-google?code=${code}`
          );
          dispatch(notificationSuccess(response.data.message));

          return response;
      } catch (err: any) {
        dispatch(notificationFail(err.response.data.message));
        return err;
      }
    },
  );



  export const getPlatformCount = createAsyncThunk(
    "getPlatformCount",
    async ( {navigate}: any ,{dispatch} ) => {
      try {
        const response = await API.get(
          `/api/v1/common/platform-count/`

          );
          dispatch(notificationSuccess(response.data.message));
          return response;
      } catch (err: any) {
        dispatch(notificationFail(err.response.data.message));
        return err;
      }
    },
  );

  export const getSnapProfiles = createAsyncThunk(
    "getSnapProfiles",
    async ( {navigate}: any ,{dispatch} ) => {
      try {
        const response = await API.get(
          `/api/v1/snapchat/snap_profiles/`
          );
          dispatch(notificationSuccess(response.data.message));
          dispatch(SnapchatData(response.data.data));
          return response;
      } catch (err: any) {
        dispatch(notificationFail(err.response.data.message));
        return err;
      }
    },
  );

  export const getTiktokProfiles = createAsyncThunk(
    "getTiktokProfiles",
    async ( {navigate}: any ,{dispatch} ) => {
      try {
        const response = await API.get(
          `/api/v1/tiktok/tiktok_profiles/`
          );
          dispatch(notificationSuccess(response.data.message));
          dispatch(TiktokData(response.data.data));
          return response;
      } catch (err: any) {
        dispatch(notificationFail(err.response.data.message));
        return err;
      }
    },
  );

  export const getFacebookProfiles = createAsyncThunk(
    "getFacebookProfiles",
    async ( {navigate}: any ,{dispatch} ) => {
      try {
        const response = await API.get(
          `/api/v1/facebook/facebook_profiles/`
          );
          dispatch(notificationSuccess(response.data.message));
          dispatch(FacebookData(response.data.data));
          return response;
      } catch (err: any) {
        dispatch(notificationFail(err.response.data.message));
        return err;
      }
    },
  );

  export const getGoogleProfiles = createAsyncThunk(
    "getGoogleProfiles",
    async ( {navigate}: any ,{dispatch} ) => {
      try {
        const response = await API.get(
          `/api/v1/Google/google_profiles/`
          );
          dispatch(notificationSuccess(response.data.message));
          dispatch(GoogleData(response.data.data));
          return response;
      } catch (err: any) {
        dispatch(notificationFail(err.response.data.message));
        return err;
      }
    },
  );

  export const updateAdaccountActiveStatus = createAsyncThunk(
    "updateAdaccountActiveStatus",
    async ( {data, id, navigate}: any ,{dispatch} ) => {
      try {
        const response = await API.put(
          `/api/v1/common/adaccount-active-status-channge/${id}/`, data
          );
          return response;
      } catch (err: any) {
        return err;
      }
    },
  );

  export const updateSpotifyInfo = createAsyncThunk(
    "updateSpotifyInfo",
    async ( {data, id}: any ,{dispatch} ) => {
      try {
        const response = await API.put(
          `/api/v1/linkfire/spotify-scraper-info/${id}/`, data
          );
          return response;
      } catch (err: any) {
        return err;
      }
    },
  );

  export const updateLinkfireScrapperInfo = createAsyncThunk(
    "updateSpotifyInfo",
    async ( {data, id}: any ,{dispatch} ) => {
      try {
        const response = await API.put(
          `/api/v1/linkfire/linkfire-data-scraper/${id}/`, data
          );
          dispatch(notificationSuccess(response.data.message));
          return response;
      } catch (err: any) {
        return err;
      }
    },
  );





  export const deleteSnapProfile = createAsyncThunk(
    "deleteSnapProfile",
    async ({id}: any ,{dispatch} ) => {
      try {
        const response = await API.delete(
          `/api/v1/snapchat/snap_profiles/${id}/`,
          );
          return response;
      } catch (err: any) {
        return err;
      }
    },
  );

  export const deleteFacebookProfile = createAsyncThunk(
    "deleteFacebookProfile",
    async ({id}: any ,{dispatch} ) => {
      try {
        const response = await API.delete(
          `/api/v1/facebook/facebook_profiles/${id}/`,
          );
          return response;
      } catch (err: any) {
        return err;
      }
    },
  );

  export const deleteTiktokProfile = createAsyncThunk(
    "deleteTiktokProfile",
    async ({id}: any ,{dispatch} ) => {
      try {
        const response = await API.delete(
          `/api/v1/tiktok/tiktok_profiles/${id}/`,
          );
          return response;
      } catch (err: any) {
        return err;
      }
    },
  );

  export const deleteGoogleProfile = createAsyncThunk(
    "deleteGoogleProfile",
    async ({id}: any ,{dispatch} ) => {
      try {
        const response = await API.delete(
          `/api/v1/Google/google_profiles/${id}/`,
          );
          return response;
      } catch (err: any) {
        return err;
      }
    },
  );

  export const deleteSpootifyProfile = createAsyncThunk(
    "deleteSpootifyProfile",
    async ({data}: any ,{dispatch} ) => {
      try {
        const response = await API.delete(
          `/api/v1/linkfire/spotify-scraper-connection/`,{data}
          );
          dispatch(notificationSuccess(response?.data?.message))
          return response;

      } catch (err: any) {
        dispatch(notificationFail(err.response.data.message));
        return err;
      }
    },
  );

  export const deleteLinkfireScrapperProfile = createAsyncThunk(
    "deleteLinkfireScrapperProfile",
    async ({data}: any ,{dispatch} ) => {
      try {
        const response = await API.delete(
          `/api/v1/linkfire/linkfire-data-scraper-connection/`,{data}
          );
          dispatch(notificationSuccess(response?.data?.message))
          return response;
      } catch (err: any) {
        dispatch(notificationFail(err.response.data.message));
        return err;
      }
    },
  );

  export const deleteLinkfireGeneratorProfile = createAsyncThunk(
    "deleteLinkfireGeneratorProfile",
    async ({data}: any ,{dispatch} ) => {
      try {
        const response = await API.delete(
          `/api/v1/linkfire/connect-linkfire-generator/`,{data}
          );
          dispatch(notificationSuccess(response?.data?.message))
          return response;
      } catch (err: any) {
        dispatch(notificationFail(err.response.data.message));
        return err;
      }
    },
  );

  export const postLoginWithSpootify = createAsyncThunk(
    "postLoginWithSpootify",
    async ({_data}: any ,{dispatch} ) => {
      try {
        const response = await API.post(
          `/api/v1/linkfire/spotify-scraper-connection/`,_data
          );
          dispatch(notificationSuccess(response.data.message));
          return response;
      } catch (err: any) {
        dispatch(notificationFail(err.response.data.message));
        return err;
      }
    },
  );

  export const postLoginWithLinkfireScrapper = createAsyncThunk(
    "postLoginWithLinkfireScrapper",
    async ({_data}: any ,{dispatch} ) => {
      try {
        const response = await API.post(
          `/api/v1/linkfire/linkfire-data-scraper-connection/`,_data

          );
          dispatch(notificationSuccess(response.data.message));
          return response;
      } catch (err: any) {
        dispatch(notificationFail(err.response.data.message));
        return err;
      }
    },
  );

  export const postLoginWithLinkfireGenerator = createAsyncThunk(
    "postLoginWithLinkfireGenerator",
    async ({_data}: any ,{dispatch} ) => {
      try {
        const response = await API.post(
          `/api/v1/linkfire/connect-linkfire-generator/`,_data
          );
          dispatch(LinkfireGeneratorLoginData(response.data.data[0]?.board_data))
          return response;
      } catch (err: any) {
        return err;
      }
    },
  );

  export const postLoginWithLinkfireGeneratorBoard = createAsyncThunk(
    "postLoginWithLinkfireGeneratorBoard",
    async ({data}: any ,{dispatch} ) => {
      try {
        const response = await API.post(
          `/api/v1/linkfire/add-linkfire-board/`,data
          );
          dispatch(LinkfireGeneratorLoginData(response.data.data[0]))
          dispatch(notificationSuccess(response.data.message));
          return response;
      } catch (err: any) {
        dispatch(notificationFail(err.response.data.message));
        return err;
      }
    },
  );

  export const getSpootifyScraper = createAsyncThunk(
    "getSpootifyScraper",
    async ( {data, page}: any,{dispatch}: any) => {
      try {
        const response = await API.get(
          `/api/v1/linkfire/spotify-scraper-info?search=&ordering=&page=${page}`
          );
          dispatch(SpootifyData(response?.data));
          return response;
      } catch (err: any) {
        return err;
      }
    },
  );

  export const getLinkfireScrapperConnection = createAsyncThunk(
    "getLinkfireScraper",
    async ( {data}: any,{dispatch}: any) => {
      try {
        const response = await API.get(
          `/api/v1/linkfire/linkfire-data-scraper-connection/`
          );
          dispatch(LinkfireScrapperConnection(response?.data?.data[0]));
          return response;
      } catch (err: any) {
        return err;
      }
    },
  );

  export const getLinkfireGeneratorConnection = createAsyncThunk(
    "getLinkfireGeneratorConnection",
    async ( {data}: any,{dispatch}: any) => {
      try {
        const response = await API.get(
          `/api/v1/linkfire/connect-linkfire-generator/`
          );
          dispatch(LinkfireGeneratorConnection(response?.data?.data[0]));
          return response;
      } catch (err: any) {
        return err;
      }
    },
  );

  export const getLinkfireScrapperInfo = createAsyncThunk(
    "getLinkfireScrapperInfo",
    async ( {data, page}: any,{dispatch}: any) => {
      try {
        const response = await API.get(
          `/api/v1/linkfire/linkfire-data-scraper?search=&ordering=&page=${page}`
          );
          dispatch(LinkfireScrapperInfo(response?.data));
          return response;
      } catch (err: any) {
        return err;
      }
    },
  );

  export const getLinkfiregeneratorInfo = createAsyncThunk(
    "getLinkfiregeneratorInfo",
    async ( {data, page}: any,{dispatch}: any) => {
      try {
        const response = await API.get(
          `/api/v1/linkfire/linkfire-generator?search=&ordering=&page=${page}`
          );
          dispatch(LinkfireGeneratorInfo(response?.data));
          return response;
      } catch (err: any) {
        return err;
      }
    },
  );

  export const getSpootifyConnection = createAsyncThunk(
    "getSpootifyConnection",
    async ( {data}: any,{dispatch}: any) => {
      try {
        const response = await API.get(
          `/api/v1/linkfire/spotify-scraper-connection/`
          );
          dispatch(SpootifyConnection(response?.data?.data[0]));
          return response;
      } catch (err: any) {
        return err;
      }
    },
  );

  export const addSpootifyArtist = createAsyncThunk(
    "addSpootifyArtist",
    async ({_data}: any, {dispatch}) => {
      try {
        const response = await API.post(
          `/api/v1/linkfire/spotify-scraper-info/`,_data
          );
          dispatch(notificationSuccess(response.data.message));
          return response;
      } catch (err: any) {
        dispatch(notificationFail(err.response.data.message));
        return err;
      }
    },
  );

  export const addLinkfireScrapperArtist = createAsyncThunk(
    "addLinkfireScrapperArtist",
    async ({_data}: any, {dispatch}) => {
      try {
        const response = await API.post(
          `/api/v1/linkfire/linkfire-data-scraper/`,_data
          );
          dispatch(notificationSuccess(response.data.message));
          return response;
      } catch (err: any) {
        dispatch(notificationFail(err.response.data.message));
        return err;
      }
    },
  );

  export const addLinkfireGeneratorArtist = createAsyncThunk(


    "addLinkfireScrapperArtist",
    async ({data}: any, {dispatch}) => {
      try {
        const response = await API.post(
          `api/v1/linkfire/generate-linkfire-link/`,data
          );
          dispatch(notificationSuccess(response.data.message));
          return response;
      } catch (err: any) {
        dispatch(notificationFail(err.response.data.message));
        return err;
      }
    },
  );


  export const getAllScraperGroup = createAsyncThunk(
    "getAllScraperGroup",
    async ({data}: any, {dispatch}) => {
      try {
        const response = await API.get(
          `/api/v1/linkfire/scraper-group/?search=&ordering=`
          );
          dispatch(ScrapperGroupData(response.data));
          return response;
      } catch (err: any) {
        dispatch(notificationFail(err.response.data.message));
        return err;
      }
    },
  );

  export const getLatestProfile = createAsyncThunk(
    "getLatestProfile",
    async ({data, apiSource}: any, {dispatch}) => {
      try {
        const response = await API.post(
          `/api/v1/common/fetch-latest-profile-data/`, data, {
            cancelToken: apiSource?.token || ""
          }
        );
          dispatch(LatestProfileData(response.data.data))
          // dispatch(ScrapperGroupData(response.data));
          return response;
      } catch (err: any) {
        dispatch(notificationFail(err.response.data.message));
        return err;
      }
    },
  );


