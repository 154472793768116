// ** React Imports
import React, { useEffect, useState } from "react";

// ** MUI Imports
import {
  Box,
  Typography,
  FormControl,
  TextField,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
  Button,
  styled,
  BoxProps,
  InputLabel,
  FormHelperText,
  Link,
  Divider,
  Select,
  MenuItem,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// ** Third Party Library
import { useLocation, useNavigate } from "react-router-dom";

// ** Store
import { useAppDispatch, useAppSelector } from "store/store";
import { AuthLoading } from "store/Slice/AuthSlice";
import {
  loginWithGoogle,
  register,
  updateUser,
  verifyRegisterOtp,
  verifyEmail,
} from "store/Thunk/authThunk";

// ** Custom Components
import { route_info } from "navigation/route";
import LogoImage from "components/logo/logo";
import ScreenLoading from "components/ScreenLoading";
import ScreenLayout from "components/screenLayout/screen-layout";

// ** Icons
import EyeOutline from "mdi-material-ui/EyeOutline";
import EyeOffOutline from "mdi-material-ui/EyeOffOutline";

// ** Helper
import config from "config/config";
import Validations from "helper/validations";

interface State {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  showPassword: boolean;
  business: string;
  company_name: string;
}

interface FormValidation {
  privacy?: boolean;
  firstName?: string;
  lastName?: string;
  password?: string;
  email?: string;
}

interface BusinessFormValidation {
  // business?: string;
  company_name?: string;
  last_name?: string;
  first_name?: string;
  reason?: string;
}

const BoxWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  maxWidth: "400px",
  [theme.breakpoints.down("md")]: {
    maxWidth: 400,
  },
}));

export default function Register() {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const code: any = new URLSearchParams(location.search).get("code");
  const platform: any = new URLSearchParams(location.search).get("state");
  const { authLoading } = useAppSelector((state) => state.authReducer);
  const [activeScreen, setActiveScreen] = useState(
    code && platform ? "" : "register"
  );
  const [params, setParams] = useState({ code: "", platform: "" });
  const [values, setValues] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    showPassword: false,
    business: "",
    privacy: false,
    company_name: "",
    reasons: "",
  });
  const [formError, setFormError] = React.useState<FormValidation>();
  const [businessFormError, setBusinessFormError] =
    React.useState<BusinessFormValidation>();
  const [loading, setLoading] = React.useState<boolean>();
  const [isMusicSelect, setIsMusicSelect] = useState(false);
  const [isBusinessType, setIsBusinessType] = useState(false);
  const useridb64: any = new URLSearchParams(location.search).get("useridb64");
  const otpb64: any = new URLSearchParams(location.search).get("otpb64");
  const [screenLoading, setScreenLoading] = React.useState<boolean>(
    useridb64 && otpb64 ? true : false
  );
  const [isSocialLogin, setIsSocialLogin] = useState(false);

  const useArray = [
    "Bulk scheduling ads",
    "Monitor ad campaigns",
    "Request content from creators",
    "Create content for brands",
  ];

  useEffect(() => {
    if (useridb64 && otpb64) {
      setScreenLoading(true);
      const _request = {
        useridb64: useridb64,
        otpb64: otpb64,
      };
      dispatch(verifyRegisterOtp({ _request, navigate })).then((res: any) => {
        if (res.payload.response.data.error) {
          setScreenLoading(false);
          navigate(route_info.auth_route.login);
        }
      });
    }
  }, []);

  useEffect(() => {
    setLoading(authLoading);
  }, [authLoading]);

  useEffect(() => {
    setParams({ code: code, platform: platform });
  }, []);

  useEffect(() => {
    if (params.code && params.platform) {
      let _request: any = {
        code: params.code,
        platform: params.platform,
      };
      setScreenLoading(true);
      dispatch(loginWithGoogle({ _request, navigate }))
        .then((res: any) => {
          setScreenLoading(false);
          if (res.payload.status == 201 || res.payload.status == 200) {
            setIsSocialLogin(true);
            localStorage.setItem(
              "header",
              JSON.stringify(res.payload.data.data[0])
            );

            if (!res.payload.data.data[0].reason_to_use_everywhereai) {
              setActiveScreen("create");
              setIsBusinessType(true);
            } else {
              localStorage.setItem(
                "userData",
                JSON.stringify(res.payload.data.data[0])
              );
              setIsSocialLogin(false);
              navigate(route_info.market_planner.dashboard);
            }
          } else {
            setIsSocialLogin(false);
            navigate("/");
          }
        })
        .catch((err: any) => {
          setScreenLoading(false);
          setIsSocialLogin(false);
          navigate("/");
        });
    }
  }, [params.code, params.platform]);

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    let _request: any = {
      email: values.email.trim(),
      password: values.password.trim(),
      privacy: values.privacy,
    };

    let allError = Validations.validateRegisterForm(_request);
    if (
      Object.entries(allError).length === 0 &&
      allError.constructor === Object
    ) {
      handleVerifyEmail();
    } else {
      setFormError(allError);
    }
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValues({ ...values, business: event.target.value });

    if ((event.target as HTMLInputElement).value === "music label") {
      setIsMusicSelect(true);
    } else {
      setIsMusicSelect(false);
    }
  };

  const handledCreateAccount = (event: any) => {
    event.preventDefault();
    let validate: any = {};
    let update_request: any = {};
    let _request: any = {};

    if (!isSocialLogin) {
      validate = {
        first_name: values.firstName,
        last_name: values.lastName,
        company_name: values.company_name,
        reason: values?.reasons,
      };
      update_request = {
        company_name: values.company_name,
        first_name: values.firstName,
        last_name: values.lastName,
        reason_to_use_everywhereai: values?.reasons,
      };

      _request = {
        first_name: values.firstName,
        last_name: values.lastName,
        reason_to_use_everywhereai: values?.reasons,
        email: values.email,
        password: values.password,
        company_name: values.company_name,
      };
    } else {
      validate = {
        company_name: values.company_name,
        reason: values?.reasons,
      };
      update_request = {
        company_name: values.company_name,
        reason_to_use_everywhereai: values?.reasons,
      };
      _request = {
        company_name: values.company_name,
        reason_to_use_everywhereai: values?.reasons,
      };
    }

    let allError = Validations.validateBusinessForm(validate, isSocialLogin);

    if (
      Object.entries(allError).length === 0 &&
      allError.constructor === Object
    ) {
      dispatch(AuthLoading(true));
      if (isBusinessType) {
        dispatch(updateUser({ update_request, navigate }));
      } else {
        dispatch(register({ _request, navigate })).then((res: any) => {
          if (res?.payload?.response?.status != 201) {
            setActiveScreen("create");
          }
        });
      }
    } else {
      setBusinessFormError(allError);
    }
  };

  const handleVerifyEmail = () => {
    let data = {
      email: values.email,
    };

    dispatch(verifyEmail({ data })).then((res: any) => {
      if (res.payload.status === 200) {
        setActiveScreen("create");
      }
    });
  };

  return (
    <>
      {screenLoading ? (
        <Box sx={{ height: "100vh", width: "100%" }}>
          <ScreenLoading />
        </Box>
      ) : (
        <>
          {activeScreen && (
            <ScreenLayout>
              {activeScreen == "register" ? (
                <BoxWrapper>
                  <Box
                    className="sync-align-justify-center"
                    sx={{
                      mb: 8,
                    }}
                  >
                    <LogoImage />
                  </Box>
                  <Box sx={{ mb: 6 }}>
                    <Typography
                      variant="h5"
                      sx={{ mb: 1.5, letterSpacing: "0.18px", fontWeight: 600 }}
                    >
                      Get ready for hyperspeed 🚀
                    </Typography>
                    <Typography variant="body2">
                      Let us save you a lot of time and trouble with scheduling
                    </Typography>
                  </Box>
                  <form noValidate autoComplete="off" onSubmit={handleSubmit}>
                    {/* <TextField
                      autoFocus
                      fullWidth
                      id="firstname"
                      value={values.firstName}
                      label="First Name"
                      inputProps={{ style: { textTransform: "capitalize" } }}
                      sx={{ mb: 4 }}
                      onChange={handleChange("firstName")}
                      error={formError && formError.firstName ? true : false}
                      helperText={formError && formError.firstName}
                    />
                    <TextField
                      fullWidth
                      id="lastname"
                      label="Last Name"
                      inputProps={{ style: { textTransform: "capitalize" } }}
                      value={values.lastName}
                      sx={{ mb: 4 }}
                      onChange={handleChange("lastName")}
                      error={formError && formError.lastName ? true : false}
                      helperText={formError && formError.lastName}
                    /> */}
                    <TextField
                      fullWidth
                      type="email"
                      label="Email"
                      value={values.email}
                      sx={{ mb: 4 }}
                      onChange={handleChange("email")}
                      error={formError && formError.email ? true : false}
                      helperText={formError && formError.email}
                    />
                    <FormControl fullWidth>
                      <InputLabel htmlFor="auth-register-password">
                        Password
                      </InputLabel>
                      <OutlinedInput
                        label="Password"
                        value={values.password}
                        id="auth-register-password"
                        onChange={handleChange("password")}
                        type={values.showPassword ? "text" : "password"}
                        error={formError && formError.email ? true : false}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              edge="end"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              aria-label="toggle password visibility"
                            >
                              {values.showPassword ? (
                                <EyeOutline fontSize="small" />
                              ) : (
                                <EyeOffOutline fontSize="small" />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {formError && formError.password && (
                        <FormHelperText error id="password-error">
                          {formError.password}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={values.privacy}
                          onChange={() =>
                            setValues({ ...values, privacy: !values.privacy })
                          }
                        />
                      }
                      label={
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <span>I agree to </span>
                          <Link
                            target="_blank"
                            rel="noopener"
                            href="https://fruitsmusic.com/privacy-policy"
                            sx={{ ml: "8px" }}
                          >
                            <Typography
                              variant="body2"
                              component={Link}
                              sx={{ color: "primary.main" }}
                            >
                              privacy policy
                            </Typography>
                          </Link>
                          <Typography
                            variant="body2"
                            sx={{ color: "#aaa", mx: "8px" }}
                          >
                            &
                          </Typography>
                          <Link
                            href="https://www.fruitsmusic.com/terms-and-conditions"
                            target="_blank"
                            rel="noopener"
                          >
                            <Typography
                              variant="body2"
                              component={Link}
                              sx={{ color: "primary.main" }}
                            >
                              terms & conditions
                            </Typography>
                          </Link>
                        </Box>
                      }
                    />
                    {formError && formError.privacy && !values.privacy && (
                      <FormHelperText
                        error
                        id="password-error"
                        sx={{ mb: 4, ml: "14px" }}
                      >
                        {formError.privacy}
                      </FormHelperText>
                    )}

                    <Button
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      sx={{ mb: 7 }}
                    >
                      ONE MORE STEP
                    </Button>
                    <Box
                      className="sync-align-justify-center"
                      sx={{
                        flexWrap: "wrap",
                      }}
                    >
                      <Typography sx={{ mr: 2, color: "text.secondary" }}>
                        Already familiar with us?{" "}
                      </Typography>
                      <Typography>
                        <Link onClick={() => navigate("/")}>
                          <Typography
                            component={Link}
                            sx={{ color: "primary.main" }}
                          >
                            Sign in here
                          </Typography>
                        </Link>
                      </Typography>
                    </Box>
                    <Divider
                      sx={{
                        mt: 5,
                        mb: 7.5,
                        "& .MuiDivider-wrapper": { px: 4 },
                      }}
                    >
                      or
                    </Divider>
                    <Box className="sync-align-justify-center">
                      <Link href={config.FACEBOOK_AUTH_URL}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="10"
                          height="18"
                          viewBox="0 0 10 18"
                          fill="none"
                          style={{ margin: "15px" }}
                        >
                          <g clipPath="url(#clip0_2_8)">
                            <path
                              d="M6.66546 17.9972V9.80115H9.43046L9.84146 6.59215H6.66546V4.54815C6.66546 3.62215 6.92346 2.98815 8.25246 2.98815H9.93646V0.12715C9.11746 0.03915 8.29346 -0.00285004 7.46946 0.000149963C5.02546 0.000149963 3.34746 1.49215 3.34746 4.23115V6.58615H0.600464V9.79515H3.35346V17.9972H6.66546Z"
                              fill="#497CE2"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2_8">
                              <rect width="10" height="18" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </Link>
                      <Link href={config.GOOGLE_AUTH_URL}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="18"
                          viewBox="0 0 19 18"
                          fill="none"
                          style={{ margin: "15px" }}
                        >
                          <g clipPath="url(#clip0_2_10)">
                            <path
                              d="M18.1975 7.37358H9.87052V10.8246H14.6625C14.2165 13.0176 12.3495 14.2776 9.87052 14.2776C6.94752 14.2776 4.59152 11.9216 4.59152 8.99757C4.59152 6.07457 6.94752 3.71858 9.87052 3.71858C11.1295 3.71858 12.2675 4.16558 13.1605 4.89657L15.7605 2.29758C14.1765 0.916575 12.1455 0.0645752 9.87052 0.0645752C4.91652 0.0645752 0.936523 4.04358 0.936523 8.99858C0.936523 13.9536 4.91552 17.9326 9.87052 17.9326C14.3375 17.9326 18.3995 14.6836 18.3995 8.99858C18.3995 8.47058 18.3185 7.90158 18.1975 7.37358Z"
                              fill="#DB4437"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_2_10">
                              <rect width="19" height="18" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </Link>
                    </Box>
                  </form>
                </BoxWrapper>
              ) : (
                <BoxWrapper>
                  <Box
                    className="sync-align-justify-center"
                    sx={{
                      mb: 8,
                    }}
                  >
                    <LogoImage />
                  </Box>
                  <Box sx={{ mb: 4 }}>
                    <Typography
                      variant="h5"
                      sx={{ fontWeight: 600, letterSpacing: "0.18px" }}
                    >{`Let us know your business 💼`}</Typography>
                    <Typography variant="body2">
                      Get access to essential functionalities to your company
                    </Typography>
                  </Box>

                  <form
                    noValidate
                    onSubmit={handledCreateAccount}
                    autoComplete="off"
                  >
                    {!isSocialLogin && (
                      <>
                        <TextField
                          autoFocus
                          fullWidth
                          id="firstname"
                          value={values.firstName}
                          label="First Name"
                          inputProps={{
                            style: { textTransform: "capitalize" },
                          }}
                          sx={{ mb: 4 }}
                          onChange={handleChange("firstName")}
                          error={
                            businessFormError && businessFormError.first_name
                              ? true
                              : false
                          }
                          helperText={
                            businessFormError && businessFormError.first_name
                          }
                        />
                        <TextField
                          fullWidth
                          id="lastname"
                          label="Last Name"
                          inputProps={{
                            style: { textTransform: "capitalize" },
                          }}
                          value={values.lastName}
                          sx={{ mb: 4 }}
                          onChange={handleChange("lastName")}
                          error={
                            businessFormError && businessFormError.last_name
                              ? true
                              : false
                          }
                          helperText={
                            businessFormError && businessFormError.last_name
                          }
                        />
                      </>
                    )}

                    <FormControl fullWidth>
                      <InputLabel>
                        How will you primarily use Kanhasoft?
                      </InputLabel>
                      <Select
                        fullWidth
                        // onChange={handleChange("reasons")}
                        onChange={(event: any) =>
                          setValues({ ...values, reasons: event.target.value })
                        }
                        error={
                          businessFormError && businessFormError.reason
                            ? true
                            : false
                        }
                        // helperText={
                        //   businessFormError && businessFormError.reason ? "yes" : "no"
                        // }
                        label="How will you primarily use Kanhasoft?"
                        defaultValue=""
                        id="form-layouts-separator-select"
                        labelId="form-layouts-separator-select-label"
                      >
                        {useArray?.map((item: any) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </Select>
                      {businessFormError && businessFormError.reason && (
                        <FormHelperText
                          error
                          id="password-error"
                          sx={{ ml: "14px" }}
                        >
                          {businessFormError.reason}
                        </FormHelperText>
                      )}
                    </FormControl>
                    <TextField
                      fullWidth
                      id="company_name"
                      value={values.company_name}
                      label="Company Name"
                      inputProps={{ style: { textTransform: "capitalize" } }}
                      sx={{ mt: 4 }}
                      onChange={handleChange("company_name")}
                      error={
                        businessFormError && businessFormError.company_name
                          ? true
                          : false
                      }
                      helperText={
                        businessFormError && businessFormError.company_name
                      }
                    />
                    {/* <FormControl>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        defaultValue="female"
                        value={values?.business}
                        name="radio-buttons-group"
                        onChange={handleRadioChange}
                        sx={{
                          mt: 4,
                        }}
                      >
                        <FormControlLabel
                          value="music label"
                          control={<Radio />}
                          label="Music Label"
                        />
                        {isMusicSelect && (
                          <FormHelperText sx={{ color: "primary.main" }}>
                            <Typography variant="subtitle2" sx={{ mb: 1 }}>
                              Registering as a music label enables integrations
                            </Typography>
                            <Typography variant="subtitle2" sx={{ mb: 1 }}>
                              with Spotify & Linkfire
                            </Typography>
                          </FormHelperText>
                        )}

                        <FormControlLabel
                          value="E-commerce"
                          control={<Radio />}
                          label="E-commerce"
                        />
                        <FormControlLabel
                          value="Other"
                          control={<Radio />}
                          label="Other"
                        />
                      </RadioGroup>

                      {businessFormError?.business && (
                        <FormHelperText sx={{ color: "primary.main" }}>
                          {businessFormError?.business}
                        </FormHelperText>
                      )}
                    </FormControl> */}

                    <LoadingButton
                      fullWidth
                      size="large"
                      type="submit"
                      loading={loading}
                      variant="contained"
                      disabled={loading}
                      sx={{ my: 4 }}
                    >
                      {isSocialLogin ? "SUBMIT" : "CREATE ACCOUNT"}
                    </LoadingButton>
                    <Box
                      className="sync-align-justify-center"
                      sx={{
                        flexWrap: "wrap",
                      }}
                    >
                      <Typography>
                        <Link
                          onClick={() => setActiveScreen("register")}
                          style={{ textDecoration: "none" }}
                        >
                          <Typography
                            className="bottom-text"
                            sx={{ color: "primary.main" }}
                          >
                            Go back
                          </Typography>
                        </Link>
                      </Typography>
                    </Box>
                  </form>
                </BoxWrapper>
              )}
            </ScreenLayout>
          )}
        </>
      )}
    </>
  );
}
