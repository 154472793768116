export default function FacebookIcon({active = false, width="30", height="30"}){

    if (active) {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 30 30" fill="none">
          <g clipPath="url(#clip0_916_13417)">
          <path d="M4.28571 0H25.7143C28.0714 0 30 1.92857 30 4.28571V25.7143C30 28.0714 28.0714 30 25.7143 30H4.28571C1.92857 30 0 28.0714 0 25.7143V4.28571C0 1.92857 1.92857 0 4.28571 0Z" fill="#156CF0"/>
          <path d="M6.4 17.5999C6.4 13.9999 8.2 10.3999 10.3 10.3999C11.4 10.3999 12.4 11.0999 13.9 13.1999C12.5 15.2999 11.7 16.6999 11.7 16.6999C9.8 19.5999 9.2 20.2999 8.1 20.2999C7 20.1999 6.4 19.2999 6.4 17.5999ZM18.7 16.2999L17.4 14.1999C17.1 13.5999 16.7 13.0999 16.4 12.5999C17.6 10.7999 18.5 9.8999 19.7 9.8999C22.1 9.8999 24 13.3999 24 17.6999C24 19.2999 23.5 20.2999 22.4 20.2999C21.2 20.1999 20.7 19.4999 18.7 16.2999ZM15.3 11.0999C13.5 8.8999 12.1 7.9999 10.3 7.9999C6.7 7.9999 4 12.6999 4 17.5999C4 20.6999 5.5 22.5999 8 22.5999C9.8 22.5999 11.1 21.7999 13.4 17.6999C13.4 17.6999 14.4 15.9999 15 14.7999C15.2 15.1999 15.5 15.5999 15.7 15.9999L16.8 17.7999C18.9 21.2999 20.1 22.4999 22.2 22.4999C24.6 22.4999 26 20.4999 26 17.3999C26 12.1999 23.2 7.8999 19.8 7.8999C18 7.9999 16.6 9.3999 15.3 11.0999Z" fill="white"/>
          </g>
          <defs>
          <clipPath id="clip0_916_13417">
          <rect width="30" height="30" fill="white"/>
          </clipPath>
          </defs>
          </svg>
        );
    } else {
        return (
          <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 30 31" fill="none">
          <g clipPath="url(#clip0_916_13535)">
          <path d="M4.28571 0.5H25.7143C28.0714 0.5 30 2.42857 30 4.78571V26.2143C30 28.5714 28.0714 30.5 25.7143 30.5H4.28571C1.92857 30.5 0 28.5714 0 26.2143V4.78571C0 2.42857 1.92857 0.5 4.28571 0.5Z" fill="#AFAFAF"/>
          <path d="M6.4 18.0999C6.4 14.4999 8.2 10.8999 10.3 10.8999C11.4 10.8999 12.4 11.5999 13.9 13.6999C12.5 15.7999 11.7 17.1999 11.7 17.1999C9.8 20.0999 9.2 20.7999 8.1 20.7999C7 20.6999 6.4 19.7999 6.4 18.0999ZM18.7 16.7999L17.4 14.6999C17.1 14.0999 16.7 13.5999 16.4 13.0999C17.6 11.2999 18.5 10.3999 19.7 10.3999C22.1 10.3999 24 13.8999 24 18.1999C24 19.7999 23.5 20.7999 22.4 20.7999C21.2 20.6999 20.7 19.9999 18.7 16.7999ZM15.3 11.5999C13.5 9.3999 12.1 8.4999 10.3 8.4999C6.7 8.4999 4 13.1999 4 18.0999C4 21.1999 5.5 23.0999 8 23.0999C9.8 23.0999 11.1 22.2999 13.4 18.1999C13.4 18.1999 14.4 16.4999 15 15.2999C15.2 15.6999 15.5 16.0999 15.7 16.4999L16.8 18.2999C18.9 21.7999 20.1 22.9999 22.2 22.9999C24.6 22.9999 26 20.9999 26 17.8999C26 12.6999 23.2 8.3999 19.8 8.3999C18 8.4999 16.6 9.8999 15.3 11.5999Z" fill="white"/>
          </g>
          <defs>
          <clipPath id="clip0_916_13535">
          <rect width="30" height="30" fill="white" transform="translate(0 0.5)"/>
          </clipPath>
          </defs>
          </svg>
        );
    }
}