// ** MUI Imports
import React, { useEffect, useState } from "react";

// ** MUI Imports
import {
  Box,
  Typography,
  FormControl,
  TextField,
  Button,
  Select,
  CircularProgress,
  MenuItem,
  FormHelperText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// ** Redux
import { useAppDispatch, useAppSelector } from "store/store";
import {
  addLinkfireScrapperArtist,
  getAllScraperGroup,
  getLinkfireScrapperInfo,
} from "store/Thunk/platformConnectionThunk";

// ** Custom Components
import DropdownNotFound from "components/DropdownNotFound/DropdownNotFound";

// ** Helper
import Validations from "helper/validations";

interface Props {
  setDialog?: any;
  page?: any;
}

export default function LinkfireLinksDialog({ setDialog, page }: Props) {
  const dispatch = useAppDispatch();

  const { scraperGroupData } = useAppSelector(
    (state: any) => state.PlatformSlice
  );

  const [values, setValues] = React.useState({
    links: "",
    group: "",
  });
  const [formError, setFormError] = useState({
    urls: "",
    scraper_group_id: "",
  });
  const [loading, setLoading] = useState({ group: false, login: false });

  useEffect(() => {
    let data: any = null;
    dispatch(getAllScraperGroup({ data })).then((res: any) => {
      res?.payload?.data?.length
        ? setLoading({ ...loading, group: false })
        : setLoading({ ...loading, group: false });
    });
  }, []);

  const handledSubmit = (event: any) => {
    event.preventDefault();
    let _request: any = {
      urls: values?.links,
      scraper_group_id: values?.group,
    };
    let allError: any = Validations.validateLinkfireAddLinkForm(_request);
    let _data: any = {
      urls: values?.links.split(/\r?\n/),
      scraper_group_id: values?.group,
    };

    if (
      Object.entries(allError).length === 0 &&
      allError.constructor === Object
    ) {
      setLoading({ ...loading, login: true });
      dispatch(addLinkfireScrapperArtist({ _data })).then((res: any) => {
        if (res?.payload?.status == 201) {
          let data: any = null;
          dispatch(getLinkfireScrapperInfo({ data, page })).then((res: any) => {
            setLoading({ ...loading, login: false });
            setDialog({ open: false });
          });
        }
      });
      setFormError({
        urls: "",
        scraper_group_id: "",
      });
    } else {
      setFormError(allError);
    }
  };

  return (
    <Box>
      <Typography variant="h5" sx={{ fontWeight: 600 }}>
        Add Linkfire links
      </Typography>
      <Typography variant="subtitle2" sx={{ fontWeight: 400, mt: 3 }}>
        Submit all Linkfire links. Make sure each link is on a separate line.{" "}
      </Typography>
      <Typography variant="subtitle2" sx={{ fontWeight: 400, mt: 3 }}>
        {" "}
        <Typography
          variant="subtitle2"
          sx={{ fontWeight: 400, mt: 3, fontSize: "10px" }}
        >
          Example link: https://lnk.to/mylinkfire{" "}
        </Typography>{" "}
      </Typography>
      <form noValidate autoComplete="off" onSubmit={handledSubmit}>
        <FormControl fullWidth sx={{ mb: 4, mt: 5 }}>
          <TextField
            id="outlined-multiline-static"
            multiline
            placeholder="Linkfire links..."
            rows={4}
            value={values.links}
            onChange={(e) => setValues({ ...values, links: e.target.value })}
            label="Linkfire links..."
            error={formError && formError.urls ? true : false}
            helperText={formError && formError.urls}
          />
        </FormControl>
        <Typography variant="subtitle1" sx={{ fontWeight: "500" }}>
          Group
        </Typography>
        <FormControl fullWidth sx={{ width: "300px", mt: 2, mb: 6 }}>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            placeholder="Labels..."
            value={values?.group}
            error={formError && formError.scraper_group_id ? true : false}
            onChange={(e: any) =>
              setValues({ ...values, group: e.target.value })
            }
          >
            {scraperGroupData.length == 0 && loading.group && (
              <DropdownNotFound name={"group"} />
            )}
            {scraperGroupData.length == 0 && !loading.group && (
              <Box className="sync-align-justify-center full-height-width">
                <CircularProgress />
              </Box>
            )}
            {scraperGroupData?.map((item: any) => (
              <MenuItem value={item?.id}>{item?.group_name}</MenuItem>
            ))}
          </Select>
          {formError && formError.scraper_group_id && (
            <FormHelperText error>Please enter linkfire link</FormHelperText>
          )}
        </FormControl>

        <Box sx={{ display: "flex" }}>
          <Button
            fullWidth
            size="large"
            variant="outlined"
            sx={{ mb: 4, mr: 2 }}
            onClick={() => setDialog({ open: false })}
          >
            Cancel
          </Button>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            variant="contained"
            sx={{ mb: 4, ml: 2 }}
            loading={loading.login}
          >
            ADD LINKS
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
}
