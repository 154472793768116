import {
  Box,
  Select,
  SelectChangeEvent,
  Switch,
  Typography,
} from "@mui/material";
import { ChangeEvent, useState, useEffect, useCallback } from "react";
import {
  DataGrid,
  GridColDef,
  GridColumns,
  GridRenderCellParams,
  GridSortModel,
  useGridApiContext,
} from "@mui/x-data-grid";
import CustomChip from "@core/components/mui/chip";
import { DataGridRowType } from "types/types";
import { ThemeColor } from "@core/layouts/types";
import ServerSideToolbar from "views/table/ServerSideToolbar";

interface StatusObj {
  [key: number]: {
    title: string;
    color: ThemeColor;
  };
}

type SortType = "asc" | "desc" | undefined | null;

// ** renders client column
const statusObj: StatusObj = {
  1: { title: "professional", color: "primary" },
  2: { title: "Optimized", color: "success" },
  3: { title: "Error", color: "error" },
  4: { title: "Not Available", color: "secondary" },
};

function SelectEditInputCell(props: GridRenderCellParams) {
  const { id, value, field } = props;
  const apiRef = useGridApiContext();

  const handleChange = async (event: SelectChangeEvent) => {
    await apiRef.current.setEditCellValue({
      id,
      field,
      value: event.target.value,
    });
    apiRef.current.stopCellEditMode({ id, field });
  };

  return (
   
    <Select
      value={value}
      onChange={handleChange}
      native
      sx={{
        boxShadow: "none",
        ".MuiOutlinedInput-notchedOutline": { border: 0 },
        "input:focus-visible": {
          outline: "0px !important",
        },
        border: 0,
      }}
    >
      <option>Lofi Fruits</option>
      <option>Lofi Fruits 1</option>
      <option>Lofi Fruits 2</option>
    </Select>
  );
}

const renderSelectEditInputCell: GridColDef["renderCell"] = (params: any) => {
  return <SelectEditInputCell {...params} />;
};

const columns: GridColumns = [
  {
    flex: 0.25,
    minWidth: 400,
    field: "campaign_name",
    headerName: "Campaign name",
    renderCell: (params: GridRenderCellParams) => {
      const { row } = params;

      return (
        <Box className="sync-align-center" >
          <Box
            sx={{
              background: row.color,
              height: "9px",
              width: "9px",
              borderRadius: "5px",
              mr: 3,
            }}
          ></Box>
          <Box className="sync-direction-column">
            <Typography
              noWrap
              variant="subtitle2"
              sx={{ color: "text.primary" }}
            >
              {row.campaign_name}
            </Typography>
          </Box>
        </Box>
      );
    },
  },
  {
    flex: 0.175,
    minWidth: 120,
    headerName: "PROFILE",
    field: "profile",
    editable: true,
    type: "select",
    renderEditCell: renderSelectEditInputCell,
  },
  {
    flex: 0.175,
    minWidth: 110,
    field: "Auto optimize",
    headerName: "Auto optimize",
    renderCell: (params: GridRenderCellParams) => {
      const { row } = params;
      return (
        <Box>
          <Switch defaultChecked={row.optimize ? true : false} />
        </Box>
      );
    },
  },
  {
    flex: 0.175,
    minWidth: 140,
    field: "status",
    headerName: "OPTIMIZATION STATUS",
    renderCell: (params: GridRenderCellParams) => {
      const status = statusObj[params.row.status];

      return (
        <CustomChip
          size="small"
          skin="light"
          color={status.color}
          label={status.title}
          sx={{ "& .MuiChip-label": { textTransform: "capitalize" } }}
        />
      );
    },
  },
];

export default function CampaignTiktokTabs() {
  const rowsData = [
    {
      age: "21",
      avatar: "7.png",
      city: "Łazy",
      email: "adew@etsy.com",
      experience: "7 Years",
      campaign_name: "Lofi1 - Week 09 (2022) - Story - 2022babyw9-a",
      id: 31,
      post: "Environmental Specialist",
      optimize: true,
      profile: "Lofi Fruits",
      status: 1,
      color: "#72E128",
      budget: "€500",
      bid: "€0.22 -> €0.23",
    },
    {
      age: "22",
      avatar: "7.png",
      city: "Łazy",
      email: "adew@etsy.com",
      experience: "7 Years",
      campaign_name: "Lofi2 - Week 09 (2022) - Story - 2022babyw9-a",
      id: 32,
      post: "Environmental Specialist",
      optimize: false,
      profile: "Lofi Fruits",
      status: 2,
      color: "primary.main",
      budget: "€500",
      bid: "€0.22 -> €0.23",
    },
    {
      age: "23",
      avatar: "7.png",
      city: "Łazy",
      email: "adew@etsy.com",
      experience: "7 Years",
      campaign_name: "Lofi - Week 09 (2022) - Story - 2022babyw9-a",
      id: 33,
      post: "Environmental Specialist",
      optimize: true,
      profile: "Lofi Fruits",
      status: 3,
      color: "#72E128",
      budget: "€500",
      bid: "€0.22 -> €0.23",
    },
    {
      age: "24",
      avatar: "7.png",
      city: "Łazy",
      email: "adew@etsy.com",
      experience: "7 Years",
      campaign_name: "Lofi - Week 09 (2022) - Story - 2022babyw9-a",
      id: 34,
      post: "Environmental Specialist",
      optimize: false,
      profile: "Lofi Fruits",
      status: 4,
      color: "#72E128",
      budget: "€500",
      bid: "€0.22 -> €0.23",
    },
    {
      age: "25",
      avatar: "7.png",
      city: "Łazy",
      email: "adew@etsy.com",
      experience: "7 Years",
      campaign_name: "Lofi - Week 09 (2022) - Story - 2022babyw9-a",
      id: 35,
      post: "Environmental Specialist",
      optimize: false,
      profile: "Lofi Fruits",
      status: 4,
      color: "primary.main",
      budget: "€500",
      bid: "€0.22 -> €0.23",
    },
    {
      age: "26",
      avatar: "7.png",
      city: "Łazy",
      email: "adew@etsy.com",
      experience: "7 Years",
      campaign_name: "Lofi - Week 09 (2022) - Story - 2022babyw9-a",
      id: 36,
      post: "Environmental Specialist",
      optimize: false,
      profile: "Lofi Fruits",
      status: 3,
      color: "primary.main",
      budget: "€500",
      bid: "€0.22 -> €0.23",
    },
    {
      age: "27",
      avatar: "7.png",
      city: "Łazy",
      email: "adew@etsy.com",
      experience: "7 Years",
      campaign_name: "Lofi - Week 09 (2022) - Story - 2022babyw9-a",
      id: 37,
      post: "Environmental Specialist",
      optimize: false,
      profile: "Lofi Fruits",
      status: 2,
      color: "primary.main",
      budget: "€500",
      bid: "€0.22 -> €0.23",
    },
    {
      age: "28",
      avatar: "7.png",
      city: "Łazy",
      email: "adew@etsy.com",
      experience: "7 Years",
      campaign_name: "Lofi - Week 09 (2022) - Story - 2022babyw9-a",
      id: 38,
      post: "Environmental Specialist",
      optimize: true,
      profile: "Lofi Fruits",
      status: 1,
      color: "primary.main",
      budget: "€500",
      bid: "€0.22 -> €0.23",
    },
    {
      age: "27",
      avatar: "7.png",
      city: "Łazy",
      email: "adew@etsy.com",
      experience: "7 Years",
      campaign_name: "Lofi - Week 09 (2022) - Story - 2022babyw9-a",
      id: 39,
      post: "Environmental Specialist",
      optimize: false,
      profile: "Lofi Fruits",
      status: 2,
      color: "primary.main",
      budget: "€500",
      bid: "€0.22 -> €0.23",
    },
    {
      age: "28",
      avatar: "7.png",
      city: "Łazy",
      email: "adew@etsy.com",
      experience: "7 Years",
      campaign_name: "Lofi - Week 09 (2022) - Story - 2022babyw9-a",
      id: 40,
      post: "Environmental Specialist",
      optimize: true,
      profile: "Lofi Fruits",
      status: 1,
      color: "primary.main",
      budget: "€500",
      bid: "€0.22 -> €0.23",
    },
    {
      age: "27",
      avatar: "7.png",
      city: "Łazy",
      email: "adew@etsy.com",
      experience: "7 Years",
      campaign_name: "Lofi - Week 09 (2022) - Story - 2022babyw9-a",
      id: 41,
      post: "Environmental Specialist",
      optimize: false,
      profile: "Lofi Fruits",
      status: 2,
      color: "primary.main",
      budget: "€500",
      bid: "€0.22 -> €0.23",
    },
    {
      age: "28",
      avatar: "7.png",
      city: "Łazy",
      email: "adew@etsy.com",
      experience: "7 Years",
      campaign_name: "Lofi - Week 09 (2022) - Story - 2022babyw9-a",
      id: 42,
      post: "Environmental Specialist",
      optimize: true,
      profile: "Lofi Fruits",
      status: 1,
      color: "primary.main",
      budget: "€500",
      bid: "€0.22 -> €0.23",
    },
  ];

  function loadServerRows(currentPage: number, data: DataGridRowType[]) {
    return data.slice(currentPage * pageSize, (currentPage + 1) * pageSize);
  }
  const [page, setPage] = useState(0);
  const [total, setTotal] = useState<number>(0);
  const [sort, setSort] = useState<SortType>("asc");
  const [pageSize, setPageSize] = useState<number>(7);
  const [rows, setRows] = useState<DataGridRowType[]>([]);
  const [searchValue, setSearchValue] = useState<string>("");
  const [sortColumn, setSortColumn] = useState<string>("campaign_name");
  const [data] = useState<DataGridRowType[]>(rowsData);
  const [filteredData, setFilteredData] = useState<DataGridRowType[]>([]);

  const fetchTableData = useCallback(
    async (sort: SortType, q: string, column: string) => {
     
    },
    [page, pageSize]
  );

  useEffect(() => {
    fetchTableData(sort, searchValue, sortColumn);
  }, [fetchTableData, searchValue, sort, sortColumn]);

  const handleSortModel = (newModel: GridSortModel) => {
    if (newModel.length) {
      setSort(newModel[0].sort);
      setSortColumn(newModel[0].field);
      fetchTableData(newModel[0].sort, searchValue, newModel[0].field);
    } else {
      setSort("asc");
      setSortColumn("campaign_name");
    }
  };

  const escapeRegExp = (value: string) => {
    return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
  };

  const handleSearch = (searchValue: string) => {
    setSearchValue(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = data.filter((row) => {
      return Object.keys(row)?.some((field) => {
        // @ts-ignore
        return searchRegex.test(row[field].toString());
      });
    });
  };

  return (
    <>
      <Box sx={{ position: { sm: "relative" } }}>
        <DataGrid
          autoHeight
          pagination
          rows={rows.length ? rows : data}
          rowCount={total}
          columns={columns}
          checkboxSelection
          pageSize={pageSize}
          sortingMode="server"
          paginationMode="server"
          onSortModelChange={handleSortModel}
          rowsPerPageOptions={[7, 10, 25, 50]}
          onPageChange={(newPage) => setPage(newPage)}
          components={{ Toolbar: ServerSideToolbar }}
          onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          componentsProps={{
            toolbar: {
              value: searchValue,
              clearSearch: () => handleSearch(""),
              onChange: (event: ChangeEvent<HTMLInputElement>) =>
                handleSearch(event.target.value),
            },
          }}
        />
      </Box>
    </>
  );
}
