// ** MUI Imports
import { Box, Link, Typography, Button } from "@mui/material";

// ** Third Party Library
import { useLocation, useNavigate } from "react-router-dom";

// ** Redux
import { useAppDispatch } from "store/store";
import { postResendOtp } from "store/Thunk/authThunk";

// ** Custom Components
import { route_info } from "navigation/route";
import ScreenLayout from "components/screenLayout/screen-layout";

// ** Icons
import EmailIcon from "@mui/icons-material/Email";

export default function VerifyEmail({ route }: any) {
  const location = useLocation();
  const data = location?.state;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleOtpResend = () => {
    if (data?.user_id && data?.user) {
      const _request = {
        user_id: data?.user_id,
        email: data?.user,
      };
      dispatch(postResendOtp({ _request }));
    }
  };

  const handleContinue = () => {
    navigate(route_info.auth_route.login);
  };

  return (
    <ScreenLayout>
      <Box
        sx={{ flexDirection: "column" }}
        className="sync-align-justify-center"
      >
        <EmailIcon sx={{ fontSize: "42px", color: "#FF4D49" }} />
        <Typography
          variant="h5"
          sx={{ margin: 2, color: "#000", fontWeight: 500 }}
        >
          Confirm your account
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            color: "#000",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          We have sent an email to{" "}
          <span style={{ fontStyle: "oblique", fontWeight: 700 }}>
            {" "}
            {data?.user}.{" "}
          </span>
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            margin: 2,
            color: "#000",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          Verify your email to start your Kanhasoft trial today.
        </Typography>
        <Typography
          variant="subtitle2"
          sx={{
            color: "#000",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          If you accidentally mistyped your email during sign up, you'll need to{" "}
          <Link onClick={() => navigate(route_info.auth_route.login)}>
            create a new account
          </Link>
          .
        </Typography>
        <Button
          sx={{
            my: 5,
          }}
          variant="contained"
          onClick={handleContinue}
        >
          I Confirmed my email
        </Button>

        <Typography
          variant="subtitle2"
          sx={{
            margin: 2,
            color: "#000",
            fontWeight: 500,
            textAlign: "center",
          }}
        >
          Nothing in your inbox?{" "}
          <Link onClick={handleOtpResend}>Try sending another email</Link>
        </Typography>
      </Box>
    </ScreenLayout>
  );
}
