import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
    publishContentType: any;
    jobCategoryArray: any;
    jobBrandDetail: any;
    allJobs: any;
    filteredCategoryJobArray: any;
    newFilteredCategoryJobArray: any;
    loadingState: boolean;
    myJobs: any;
    archivedJobs: any;
    allJobDetail: any;
    submissionList: any;
    newSubmissionList: any;
    submissionHistory: any;
    newSubmissionHistory: any;
    mySubmissionList: any;
    myNewSubmissionList: any;
    tempMySubmissionList: any;
    mySubmissionHistoryList: any;
    myNewSubmissionHistoryList: any;
    videoData: any;
    snapchatData: any;
    creatorInfo: any;
    objectKey: any;
    storedDraftData: any;
    localContentType: any;
    paymentDetails: any;
    showDownloader: boolean;
    downloadVideoInfo: any;
}

const initialState: InitialState = {
    publishContentType: "",
    jobCategoryArray: null,
    jobBrandDetail: null,
    allJobs: [],
    filteredCategoryJobArray: [],
    newFilteredCategoryJobArray: [],
    loadingState: false,
    myJobs: [],
    archivedJobs: [],
    allJobDetail: null,
    submissionList: [],
    newSubmissionList: [],
    submissionHistory: [],
    newSubmissionHistory: [],
    tempMySubmissionList: [],
    mySubmissionList: [],
    myNewSubmissionList: [],
    mySubmissionHistoryList: [],
    myNewSubmissionHistoryList: [],
    videoData: {},
    snapchatData: null,
    creatorInfo: null,
    objectKey: null,
    storedDraftData: null,
    localContentType: null,
    paymentDetails: {
        payment_status: null,
        payment_type: null,
        payment_id: ""
    },
    showDownloader: false,
    downloadVideoInfo: {
        percentage: 0,
        fileSize: 0,
    },
};

const createJobSlice = createSlice({
    name: 'createJob',
    initialState: initialState,
    reducers: {
        publishContentTypeData: (
            state: Draft<InitialState>,
            action: PayloadAction<string>
        ) => {
            state.publishContentType = action.payload;
        },
        getJobCategoryData: (
            state: Draft<InitialState>,
            action: PayloadAction<[]>,
        ) => {
            state.jobCategoryArray = action.payload;
        },
        getJobBrandData: (
            state: Draft<InitialState>,
            action: PayloadAction<[]>
        ) => {
            state.jobBrandDetail = action.payload;
        },
        getAllJob: (
            state: Draft<InitialState>,
            action: PayloadAction<[]>
        ) => {
            state.allJobs = action.payload;
        },
        getFilteredJobCategoryData: (
            state: Draft<InitialState>,
            action: PayloadAction<[]>
        ) => {
            state.filteredCategoryJobArray = action.payload;
        },
        getNewFilteredJobCategoryData: (
            state: Draft<InitialState>,
            action: PayloadAction<[]>
        ) => {
            state.newFilteredCategoryJobArray = action.payload;
        },
        getMyJobs: (
            state: Draft<InitialState>,
            action: PayloadAction<[]>
        ) => {
            state.myJobs = action.payload;
        },
        getArchivedJobs: (
            state: Draft<InitialState>,
            action: PayloadAction<[]>
        ) => {
            state.archivedJobs = action.payload;
        },
        getJobDetail: (
            state: Draft<InitialState>,
            action: PayloadAction<[]>
        ) => {
            state.allJobDetail = action.payload;
        },
        getHistory: (
            state: Draft<InitialState>,
            action: PayloadAction<[]>
        ) => {
            state.submissionHistory = action.payload;
        },
        getNewHistory: (
            state: Draft<InitialState>,
            action: PayloadAction<[]>
        ) => {
            state.newSubmissionHistory = action.payload;
        },
        setMySubmissionList: (
            state: Draft<InitialState>,
            action: PayloadAction<[]>
        ) => {
            state.mySubmissionList = action.payload;
        },
        setMyNewSubmissionList: (
            state: Draft<InitialState>,
            action: PayloadAction<[]>
        ) => {
            state.myNewSubmissionList = action.payload;
        },
        setMySubmissionHistoryList: (
            state: Draft<InitialState>,
            action: PayloadAction<[]>
        ) => {
            state.mySubmissionHistoryList = action.payload;
        },
        setMyNewSubmissionHistoryList: (
            state: Draft<InitialState>,
            action: PayloadAction<[]>
        ) => {
            state.myNewSubmissionHistoryList = action.payload;
        },
        setTempMySubmissionHistoryList: (
            state: Draft<InitialState>,
            action: PayloadAction<[]>
        ) => {
            state.tempMySubmissionList = action.payload;
        },
        setSubmissionList: (
            state: Draft<InitialState>,
            action: PayloadAction<[]>
        ) => {
            state.submissionList = action.payload;
        },
        setNewSubmissionList: (
            state: Draft<InitialState>,
            action: PayloadAction<[]>
        ) => {
            state.newSubmissionList = action.payload;
        },
        setVideoData: (
            state: Draft<InitialState>,
            action: PayloadAction<[]>
        ) => {
            state.videoData = action.payload;
        },
        setSnapChatData: (
            state: Draft<InitialState>,
            action: PayloadAction<[]>
        ) => {
            state.snapchatData = action.payload;
        },
        setCreatorInfo: (
            state: Draft<InitialState>,
            action: PayloadAction<{}>
        ) => {
            state.creatorInfo = action.payload;
        },
        setObjectKey: (
            state: Draft<InitialState>,
            action: PayloadAction<"">
        ) => {
            state.objectKey = action.payload;
        },
        setDraftData: (
            state: Draft<InitialState>,
            action: PayloadAction<{}>
        ) => {
            state.storedDraftData = action.payload;
        },
        setLocalContentType: (
            state: Draft<InitialState>,
            action: PayloadAction<{}>
        ) => {
            state.localContentType = action.payload;
        },
        setLoading: (
            state: Draft<InitialState>,
            action: PayloadAction<boolean>
        ) => {
            state.loadingState = action.payload;
        },
        setPaymentDetails: (
            state: Draft<InitialState>,
            action: PayloadAction<[]>
        ) => {
            state.paymentDetails = action.payload;
        },
        setDownloadVideoInfo: (
            state: Draft<InitialState>,
            action: PayloadAction<any>
        ) => {
            state.downloadVideoInfo = action.payload;
        },
        setShowDownloader: (
            state: Draft<InitialState>,
            action: PayloadAction<any>
        ) => {
            state.showDownloader = action.payload;
        },
    }
});

export const { setPaymentDetails, publishContentTypeData, getJobCategoryData, getJobBrandData, getAllJob, getFilteredJobCategoryData, getNewFilteredJobCategoryData, getMyJobs, getArchivedJobs, getJobDetail, getHistory, getNewHistory, setMySubmissionList, setMyNewSubmissionList, setMySubmissionHistoryList, setMyNewSubmissionHistoryList, setTempMySubmissionHistoryList, setSubmissionList, setNewSubmissionList, setVideoData, setSnapChatData, setCreatorInfo, setObjectKey, setDraftData, setLocalContentType, setLoading, setDownloadVideoInfo, setShowDownloader } =
    createJobSlice.actions;

export default createJobSlice.reducer;
