// ** MUI Imports
import {
  Box,
  Typography,
  useTheme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  CircularProgress,
} from "@mui/material";

// ** Custom Component
import { MARKET_PLACE } from "@core/constants/title-constants";
import FileUploaderRestrictions from "components/FileUpload/FileUploaderRestrictions";

// ** Icons
import CloseIcon from "@mui/icons-material/Close";

export default function ProfileCompletedDialog(props: any) {
  const theme = useTheme();
  const {
    loader,
    openUploadFile,
    handleCompleteProfile,
    isProfileCompleted,
    handleUploadContentClose,
    loadingState,
    files,
    setFiles,
    handleSubmitSubmission,
  } = props;

  return (
    <Dialog
      fullWidth={isProfileCompleted}
      open={openUploadFile}
      aria-labelledby="user-view-edit"
      aria-describedby="user-view-edit-description"
    >
      {loader ? (
        <Box className="loader" sx={{ height: "250px" }}>
          <CircularProgress size={20} />
        </Box>
      ) : isProfileCompleted ? (
        <>
          <DialogTitle className="detail-dialog-title" sx={{ pb: 0 }}>
            {MARKET_PLACE.CREATE_CONTENT.UPLOAD_YOUR_CONTENT}
          </DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handleUploadContentClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 15,
              color: theme.palette.customColors.comet,
            }}
          >
            <CloseIcon />
          </IconButton>

          <DialogContent>
            {loadingState ? (
              <Box className="loader">
                <CircularProgress size={20} />
              </Box>
            ) : (
              <Box className="file-uploader-block">
                <FileUploaderRestrictions
                  files={files}
                  setFiles={setFiles}
                  onClickSubmit={handleSubmitSubmission}
                  handleRemoveFile={() => setFiles(null)}
                />
              </Box>
            )}
          </DialogContent>
        </>
      ) : (
        <>
          <DialogTitle
            sx={{ fontSize: "17px" }}
            className="detail-dialog-title"
          >
            {MARKET_PLACE.CREATE_CONTENT.COMPLETE_YOUR_CREATOR_PROFILE}
          </DialogTitle>
          <DialogContent sx={{ pb: "25px" }}>
            <Typography
              variant="body2"
              className="dialog_content"
              color={theme.palette.customColors.border_color_light}
              sx={{ mb: "27px" }}
            >
              {MARKET_PLACE.CREATE_CONTENT.COMPLETE_YOUR_CREATOR_DESCRIPTION}
            </Typography>
            <Button
              sx={{ fontSize: { xs: "13px", md: "14px" } }}
              variant="contained"
              className="continue-btn"
              onClick={handleCompleteProfile}
            >
              {MARKET_PLACE.CREATE_CONTENT.COMPLETE_CREATOR_PROFILE}
            </Button>
          </DialogContent>
        </>
      )}
    </Dialog>
  );
}
