import { useEffect, useState } from "react";
import {
  Box,
  Chip,
  ChipProps,
  Grid,
  styled,
  Typography,
  CircularProgress,
} from "@mui/material";
import { route_info } from "navigation/route";
import { useAppDispatch, useAppSelector } from "store/store";
import { getMySubmissions } from "store/Thunk/createJobThunk";
import { setMySubmissionList } from "store/Slice/CreateJobSlice";
import { STATUS, TITLE } from "@core/constants/enum";
import ShowMore from "components/ShowMore";
import MySubmissionCard from "components/MySubmissionCard";
import LinkShared from "pages/marketplace/create-content/Dialog/LinkShared";
import { PixelMelonFalling } from "assets";

const MChip = styled(Chip)<ChipProps>(({ theme }: any) => ({
  minWidth: "70px",
  maxHeight: "24px",
  marginRight: theme.spacing(2),
}));

export default function MySubmissionTabs(props: any) {
  const { selectedPublishType, submissionTabTitle, isHistory } = props;
  const dispatch: any = useAppDispatch();
  const { loadingState, mySubmissionList, myNewSubmissionList } =
    useAppSelector((state: any) => state.createJobReducer);

  const [selected, setSelected] = useState("all");
  const [page, setPage] = useState(1);
  const [submissionList, setSubmissionsList] = useState<any>([]);
  const [isLinkShareOpen, setIsLinkShareOpen] = useState<any>(false);

  let tempApprovalStatus =
    selected === TITLE.EARNING
      ? STATUS.EARNING
      : selected === TITLE.ACTION_REQUIRED
      ? STATUS.ACTION_REQUIRED
      : selected === TITLE.CREATOR_POST_PENDING ||
        selected === TITLE.POST_CONFIRMATION_NEEDED ||
        selected === TITLE.PENDING
      ? STATUS.PENDING
      : selected === TITLE.DECLINED
      ? STATUS.DECLINED
      : STATUS.ALL;

  useEffect(() => {
    let data = {
      approval_status: tempApprovalStatus,
      published_content_type: selectedPublishType,
      page: page,
      mySubmissionList: mySubmissionList,
    };
    dispatch(getMySubmissions(data));
  }, [selected, selectedPublishType]);

  useEffect(() => {
    setSubmissionsList(mySubmissionList);
  }, [mySubmissionList]);

  const handleClick = (event: any) => {
    setSelected(event.target.innerText);
    dispatch(setMySubmissionList([]));
  };
  const handleSubmissionShowMore = () => {
    setPage(page + 1);
    let data = {
      approval_status: tempApprovalStatus,
      published_content_type: selectedPublishType,
      page: page + 1,
      mySubmissionList: mySubmissionList,
    };
    dispatch(getMySubmissions(data));
  };

  const handleClickContinue = () => {
    setIsLinkShareOpen(!isLinkShareOpen);
  };
  return (
    <Box>
      <Box sx={{ mt: 5 }}>
        {/* {submissionList.length > 0 && ( */}
        <Grid container rowGap={3}>
          <Grid item xs={12} sm={9} md={10}>
            {myNewSubmissionList && myNewSubmissionList.length
              ? submissionTabTitle.map((item: any) => (
                  <MChip
                    key={item.label}
                    className="category_chip"
                    label={item.label}
                    clickable
                    color="default"
                    onClick={handleClick}
                    variant={
                      selected &&
                      selected.toLocaleLowerCase() ===
                        item.label.toLocaleLowerCase()
                        ? "filled"
                        : "outlined"
                    }
                  />
                ))
              : ""}
          </Grid>
          <Grid
            item
            xs={12}
            sm={3}
            md={2}
            display={"flex"}
            justifyContent={{
              xs: "flex-start",
              sm: "flex-end",
              md: "flex-end",
            }}
            alignItems="flex-start"
          >
            {submissionList && submissionList.length > 0 && (
              <ShowMore handleOnClick={handleSubmissionShowMore} />
            )}
          </Grid>
        </Grid>
      </Box>
      <Box mt={4}>
        {loadingState ? (
          <Box className="loader">
            <CircularProgress size={20} />
          </Box>
        ) : (
          <Grid container>
            {submissionList && submissionList.length > 0
              ? submissionList.map((jobData: any, index: number) => (
                  <Grid
                    item
                    md={4}
                    sm={6}
                    xs={12}
                    key={jobData.job_details.title}
                  >
                    <MySubmissionCard
                      cardData={jobData}
                      isHistory={isHistory}
                      redirectURL={route_info.market_place.job_detail}
                      selected={selected}
                      selectedPublishType={selectedPublishType}
                      onClickContinue={handleClickContinue}
                    />
                  </Grid>
                ))
              : !loadingState && (
                  <Box className="no-data-found-block">
                    <Box
                      component="img"
                      src={PixelMelonFalling}
                      className="no-data-found-img"
                    />
                    <Typography variant="subtitle2">
                      There are currently no submissions available for this
                      category. Please check back later.
                    </Typography>
                  </Box>
                )}
          </Grid>
        )}
      </Box>
      <LinkShared
        isOpen={isLinkShareOpen}
        onContinueClick={handleClickContinue}
      />
    </Box>
  );
}
