// ** React Imports
import { useEffect, useState } from "react";

// ** MUI Imports
import {
  Box,
  Button,
  CircularProgress,
  Tabs,
  Tab,
  Dialog,
  IconButton,
  DialogContent,
  Grid,
  LinearProgress,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

// ** Third Party Library
import { useNavigate } from "react-router-dom";

// ** Redux
import { useAppDispatch, useAppSelector } from "store/store";
import { notificationSuccess } from "store/Slice/notificationSlice";
import {
  deleteGoogleProfile,
  getGoogleProfiles,
} from "store/Thunk/platformConnectionThunk";

// ** Custom Components
import config from "config/config";
import AdAccountsTab from "pages/platform/Tabs/AdAccountsTab";

// ** Icons
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";
import CloseIcon from "@mui/icons-material/Close";

// ** Assets
import { ProfileIcon } from "assets";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

interface Props {
  setSidebarDrawer?: any;
  sidebarDrawer?: any;
  onGetPlatform?: any;
}

export default function ViewDetailsGoogleDrawer({
  setSidebarDrawer,
  sidebarDrawer,
  onGetPlatform,
}: Props) {
  const dispatch: any = useAppDispatch();
  const navigate = useNavigate();

  type Profile = {
    name: string;
    id: number;
  };

  const { google } = useAppSelector((state: any) => state.PlatformSlice);
  const [value, setValue] = useState(0);
  const [profile, setProfile] = useState<Profile[]>([]);
  const [adAccount, setAddAccount] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dialog, setDialog] = useState({ open: false, name: "", id: null });
  const [loadingDelete, setLoadingdelete] = useState(false);

  useEffect(() => {
    let nameArray: any = [];
    let profileArray: any = [];
    google?.length &&
      google?.map((item: any) => {
        nameArray.push({
          name: item.first_name + " " + item.last_name,
          id: item?.id,
        });
        item?.ad_accounts?.map((items: any) => {
          profileArray.push(items);
        });
      });
    profileArray.sort((a: any, b: any) => b.active - a.active);
    nameArray?.length ? setProfile(nameArray) : setProfile([]);
    profileArray?.length ? setAddAccount(profileArray) : setAddAccount([]);
  }, [google]);

  useEffect(() => {
    dispatch(getGoogleProfiles({ navigate })).then((response: any) => {
      setLoading(false);
    });
  }, []);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const handleRedirectUrl = () => {
    if (sidebarDrawer?.title == "Snapchat Ads") {
      window.open(config.GOOGLE_CONNECTION_URL, "_self");
    }
  };

  const handledRemoveProfile = (id: any) => {
    if (id) {
      setLoadingdelete(true);
      dispatch(deleteGoogleProfile({ id })).then((response: any) => {
        dispatch(getGoogleProfiles({ navigate })).then((response: any) => {
          setLoadingdelete(false);
          dispatch(notificationSuccess("Profile Deleted Successfully"));
          setDialog({ ...dialog, open: false });
          if (response?.payload?.data?.results.length == 0) {
            setSidebarDrawer({ ...sidebarDrawer, open: false });
            onGetPlatform();
          }
        });
      });
    }
  };

  return (
    <>
      {loading ? (
        <>
          <Box sx={{ width: "100%" }}>
            <LinearProgress />
          </Box>
          <Box
            className="sync-align-justify-center"
            sx={{
              height: "100%",
            }}
          >
            <CircularProgress />
          </Box>
        </>
      ) : (
        <>
          <Box className="sync-justify-end" sx={{ padding: 3 }}>
            <IconButton
              onClick={() => setSidebarDrawer({ open: false, Drawer: "" })}
            >
              <CloseIcon style={{ height: 25, width: 25 }} />
            </IconButton>
          </Box>
          <Box sx={{ paddingLeft: "58px", paddingRight: "58px" }}>
            <Box>
              <Typography variant="h5" className="sync-justify-center">
                {sidebarDrawer?.title}
              </Typography>
            </Box>
            <Grid container sx={{ mt: 5 }}>
              {profile &&
                profile?.map((item: any) => (
                  <Grid item xs={12} sm={6} padding={2}>
                    <Box
                      className="sync-align-center"
                      sx={{
                        border: "1px solid #EB5757",
                        borderWidth: "1.5px",
                        background: "rgba(255, 77, 73, 0.05)",
                        borderRadius: "10px",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        padding: "10px",
                      }}
                    >
                      <Box className="sync-align-center">
                        <Box
                          component="img"
                          src={ProfileIcon}
                          height={30}
                          width={30}
                        />
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: 500, color: "#EB5757", ml: 3 }}
                        >
                          {item.name}
                        </Typography>
                      </Box>
                      <Box className="sync-align-center">
                        <IconButton
                          onClick={() =>
                            setDialog({
                              ...dialog,
                              open: true,
                              name: item?.name,
                              id: item?.id,
                            })
                          }
                        >
                          <CloseIcon style={{ height: 20, width: 20 }} />
                        </IconButton>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              <Grid item xs={12} sm={6} padding={2}>
                <Box
                  className="sync-align-center"
                  sx={{
                    border: "1px solid #6D788D",
                    borderWidth: "1.5px",
                    background:
                      "linear-gradient(0deg, rgba(255, 255, 255, 0.88), rgba(255, 255, 255, 0.88)), #6D788D;",
                    borderRadius: "10px",
                    flexDirection: "row",
                  }}
                >
                  <IconButton
                    style={{ padding: "13px" }}
                    onClick={handleRedirectUrl}
                  >
                    <AddCircleOutlineOutlinedIcon
                      style={{ height: 30, width: 30 }}
                    />
                    <Typography
                      variant="subtitle1"
                      sx={{ fontWeight: 500, color: "#6D788D", ml: 3 }}
                    >
                      Add Profile
                    </Typography>
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ width: "100%", mt: 5, padding: 0 }}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="basic tabs example"
                >
                  <Tab label="AD ACCOUNTS" {...a11yProps(0)} />
                </Tabs>
              </Box>
              <Box>
                <TabPanel value={value} index={0}>
                  <AdAccountsTab
                    adAccount={adAccount}
                    title={sidebarDrawer?.title}
                  />
                </TabPanel>
              </Box>
            </Box>

            <Box className="sync-justify-end">
              <Button
                variant="outlined"
                sx={{ margin: 3 }}
                onClick={() => setSidebarDrawer({ open: false, Drawer: "" })}
              >
                CANCEL
              </Button>
              <Button
                variant="contained"
                sx={{ margin: 3 }}
                onClick={() => setSidebarDrawer({ open: false, Drawer: "" })}
              >
                FINISH
              </Button>
            </Box>
          </Box>
        </>
      )}
      <Dialog
        fullWidth
        open={dialog?.open}
        maxWidth="sm"
        scroll="body"
        onClose={() =>
          setDialog({ ...dialog, open: false, name: "", id: null })
        }
      >
        <DialogContent
          sx={{
            px: { sm: 10 },
            py: { sm: 7.5 },
            position: "relative",
          }}
        >
          <Box>
            <Typography variant="h5" sx={{ fontWeight: 600 }}>
              Unlink Profile
            </Typography>
            <Typography variant="subtitle2" sx={{ fontWeight: 400, mt: 3 }}>
              Are you sure you want to unlink "{dialog?.name}"?{" "}
            </Typography>
            <Box sx={{ display: "flex", mt: 7 }}>
              <Button
                fullWidth
                size="large"
                variant="outlined"
                sx={{ mb: 4, mr: 2 }}
                onClick={() =>
                  setDialog({ ...dialog, open: false, name: "", id: null })
                }
              >
                CANCEL
              </Button>

              <LoadingButton
                fullWidth
                size="large"
                type="submit"
                onClick={() => handledRemoveProfile(dialog?.id)}
                loading={loadingDelete}
                variant="contained"
                disabled={loading}
                sx={{ mb: 4, ml: 2 }}
              >
                UNLINK
              </LoadingButton>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </>
  );
}
