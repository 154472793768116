import { Background, Logo } from "assets";
import { Box, Typography } from "@mui/material";
import themeConfig from "config/themeConfig";

export default function LogoImage() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {" "}
      <Box component="img" height={"45px"} width={"45px"} src={Logo} />
      <Typography
        variant="h6"
        sx={{
          ml: 2,
          lineHeight: 1,
          fontWeight: 700,
          fontSize: "1.5rem !important",
        }}
      >
        {themeConfig.templateName}
      </Typography>
    </Box>
  );
}
