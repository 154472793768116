// ** MUI Imports
import Grid from "@mui/material/Grid";

import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import Box, { BoxProps } from "@mui/material/Box";

// ** Custom Components Imports
import PlanDetails from "components/plan-details";
import CustomChip from "@core/components/mui/chip";

import {
  PricingIllustration1,
  PricingIllustration2,
  PricingIllustration3,
} from "assets";

// ** Icons Imports
import CircleOutline from "mdi-material-ui/CircleOutline";

// ** Types
import { PricingPlanType } from "components/plan-details/types";
import CurrentPlanLayout from "pages/account-settings/pricing-plans/CurrentPlan";
import TierPlanLayout from "pages/account-settings/pricing-plans/TierPlan";
import EnterpriseLayout from "pages/account-settings/pricing-plans/ExnterprisePlan";
import { Stack } from "@mui/material";

const pricingPlans = {
  freePlan: {
    imgWidth: 100,
    title: "Basic",
    imgHeight: 100,
    monthlyPrice: 0,
    currentPlan: true,
    popularPlan: false,
    daysLeft: 3,
    subtitle: "A simple start for everyone",
    imgSrc: PricingIllustration1,
    amountTitle: "FREE",
    yearlyPlan: {
      perMonth: 0,
      totalAnnual: 0,
    },
    planBenefits: [
      "Unlimited ad scheduling",
      "Ad manager integrations",
      "Using and creating presets",
      "Spotify integration",
      "Linkfire integration",
    ],
  },
  tierPlan: {
    planBenefits: [
      "Unlimited ad scheduling",
      "Ad manager integrations",
      "Using and creating presets",
      "Spotify integration",
      "Linkfire integration",
    ],
  },
};

const PricingPlans = (props: any) => {
  // ** Props
  const { data, state } = props;

  const getIsEnterprise = () => {
    return state?.is_enterprise ? true : false;
  };

  return (
    <Box
      display="flex"
      alignSelf="center"
      // sx={{ minWidth: { xs: "100%", sm: "100%", md: "75%", lg: "75%" } }}
      // minWidth={"75%"}
    >
      <Stack
        direction={"row"}
        rowGap={4}
        columnGap={4}
        flexWrap="wrap"
        display="flex"
        justifyContent="center"
      >
        {state?.currentPlan?.free_trial_days_left && (
          <Box>
            <CurrentPlanLayout currentPlan={state?.currentPlan} />
          </Box>
        )}
        {!getIsEnterprise() && (
          <Box>
            <TierPlanLayout
              currentPlan={state?.currentPlan}
              state={state}
              isSelected={!getIsEnterprise()}
            />
          </Box>
        )}
        <Box>
          <EnterpriseLayout isSelected={getIsEnterprise()} />
        </Box>
      </Stack>
    </Box>
  );
};

export default PricingPlans;
