import { useEffect, useState } from "react";
import {
  Box,
  Chip,
  ChipProps,
  Grid,
  styled,
  Typography,
  CircularProgress,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "store/store";
import { getJobCategory, getFilteredJobs } from "store/Thunk/createJobThunk";
import ListCard from "components/ListCard";
import { route_info } from "navigation/route";
import { PixelMelonFalling } from "assets";

const MChip = styled(Chip)<ChipProps>(({ theme }: any) => ({
  minWidth: "70px",
  maxHeight: "24px",
  marginRight: theme.spacing(2),
}));

export default function SortByCategory(props: any) {
  const { selectedPublishType } = props;
  const { filteredCategoryJobArray, newFilteredCategoryJobArray } =
    useAppSelector((state: any) => state.createJobReducer);
  const dispatch: any = useAppDispatch();
  const navigate: any = useNavigate();

  const [selected, setSelected] = useState(0);
  const [categoryArr, setCategoryArr] = useState<any>([]);
  const [jobList, setJobsList] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    dispatch(getJobCategory({ navigate })).then((res: any) => {
      let tempCategoryArr = [
        {
          id: 0,
          category_name: "All",
        },
      ];

      res.payload.map((data: any, index: number) => {
        tempCategoryArr.push(data);
      });
      setCategoryArr(tempCategoryArr);
    });
  }, []);

  useEffect(() => {
    let data = {
      published_content_type: selectedPublishType,
      category_type: selected === 0 ? 0 : selected,
      sortByCategoryList: jobList,
    };

    setLoading(true);
    dispatch(getFilteredJobs({ data }))
      .then((response: any) => {
        let temp = response.payload.data.data;
        setLoading(false);
        setJobsList(temp);
      })
      .catch((err: any) => {
        setLoading(false);
      });
  }, [selected, selectedPublishType]);

  const handleClick = (item: any) => {
    setSelected(item.id);
  };

  return (
    <Box sx={{ pl: "10px" }}>
      <Box>
        {newFilteredCategoryJobArray && newFilteredCategoryJobArray.length
          ? categoryArr.map((item: any) => (
              <MChip
                key={item.category_name}
                className="category_chip"
                label={item.category_name}
                clickable
                color="default"
                onClick={() => handleClick(item)}
                variant={selected === item.id ? "filled" : "outlined"}
              />
            ))
          : null}
      </Box>
      <Box mt={4}>
        {loading ? (
          <Box className="loader">
            <CircularProgress size={20} />
          </Box>
        ) : filteredCategoryJobArray.length > 0 ? (
          <Box gap={1} display="flex" flexDirection={"row"} flexWrap={"wrap"}>
            {filteredCategoryJobArray.map((jobData: any, index: number) => (
              <ListCard
                style={{ width: "283px" }}
                cardData={jobData}
                redirectURL={route_info.market_place.job_detail}
              />
            ))}
          </Box>
        ) : (
          !loading && (
            <Box className="no-data-found-block">
              <Box
                component="img"
                src={PixelMelonFalling}
                className="no-data-found-img"
              />
              <Typography variant="subtitle2">
                There are currently no jobs available for this category. Please
                check back later.
              </Typography>
            </Box>
          )
        )}
      </Box>
    </Box>
  );
}
