export default {
  // BASE_URL: 'https://devapi.everywhere-ai.com/',
  // BASE_URL: 'https://api.everywhere-ai.com/',
  // BASE_URL: 'http://192.168.1.49:8932/',
  BASE_URL: 'https://sfapi.kanhasoftdev.com/',
  STRIPE_BASE_URL: 'https://api.stripe.com/',

  GOOGLE_AUTH_URL: 'https://accounts.google.com/o/oauth2/v2/auth?response_type=code&access_type=offline&client_id=155458877600-cbm5mfm0jqomnk6n9jbfiqcq3itq61gh.apps.googleusercontent.com&redirect_uri=https://app.everywhere-ai.com/register&scope=https://www.googleapis.com/auth/userinfo.email+https://www.googleapis.com/auth/userinfo.profile&state=google',

  FACEBOOK_AUTH_URL: "https://www.facebook.com/v16.0/dialog/oauth?client_id=906246054134743&state=facebook&response_type=code&redirect_uri=https://app.everywhere-ai.com/register&display=popup",

  FACEBOOK_CONNECTION_URL: "https://www.facebook.com/v16.0/dialog/oauth?client_id=906246054134743&state=facebook&response_type=code&redirect_uri=https://devapp.everywhere-ai.com/platform&display=popup&scope=ads_read,ads_management,email,public_profile,business_management,pages_manage_ads,pages_manage_metadata,pages_read_engagement,pages_read_user_content,instagram_basic,instagram_manage_insights",

  // FACEBOOK_CONNECTION_URL: "https://www.facebook.com/v16.0/dialog/oauth?client_id=1407064343420581&state=facebook&response_type=code&redirect_uri=https://devapp.everywhere-ai.com/platform&display=popup&scope=ads_read,ads_management,email,public_profile,business_management,pages_manage_ads,pages_manage_metadata,pages_read_engagement,pages_read_user_content,instagram_basic,instagram_manage_insights",

  SNAP_CONNECTION_URL: "https://accounts.snapchat.com/login/oauth2/authorize?response_type=code&client_id=347050c8-d205-4c5f-892e-af028e0b260b&redirect_uri=https://app.everywhere-ai.com/platform&scope=snapchat-marketing-api snapchat-offline-conversions-api snapchat-profile-api&state=snapchat",

  TIKTOK_CONNECTION_URL: "https://ads.tiktok.com/marketing_api/auth?app_id=7171429284156801025&state=tiktok&redirect_uri=https%3A%2F%2Fapp.everywhere-ai.com%2Fplatform&rid=1a8wk00syxw",

  GOOGLE_CONNECTION_URL: "https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/userinfo.profile+https://www.googleapis.com/auth/userinfo.email+https://www.googleapis.com/auth/adwords&access_type=offline&redirect_uri=https://app.everywhere-ai.com/platform&response_type=code&client_id=155458877600-cbm5mfm0jqomnk6n9jbfiqcq3itq61gh.apps.googleusercontent.com&state=google",



  //Working on past account jonh_jain

  // INSTAGRAM_CONNECTION_URL: "https://www.facebook.com/login/reauth.php?app_id=778965973652750&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fv13.0%2Fdialog%2Foauth%3Fclient_id%3D778965973652750%26redirect_uri%3Dhttps%253A%252F%252Ffruitsagency.com%252F%26scope%3Dinstagram_basic%252Cpages_show_list%26ret%3Dlogin%26fbapp_pres%3D0%26logger_id%3Def5ac4ae-1c96-4224-bb14-279769d6fe5c%26tp%3Dunspecified%26cbt%3D1675086014057&cancel_url=https%3A%2F%2Ffruitsagency.com%2F%3Ferror%3Daccess_denied%26error_code%3D200%26error_description%3DPermissions%2Berror%26error_reason%3Duser_denied%23_%3D_&display=page&locale=en_GB&pl_dbl=0",
  TIKTOK_CONNECT_URL: "https://www.tiktok.com/auth/authorize?client_key=7171429284156801025&scope=user.info.basic,user.insights,video.list,video.insights,comment.list,comment.list.manage,video.publish,user.insights.creator,tcm.order.update&response_type=code&redirect_uri=https://app.everywhere-ai.com/my-creator-info&state=tiktok&rid=jlpa0pipgb8&type=",

  SNAPCHAT_CONNECTION_URL: "https://accounts.snapchat.com/login/oauth2/authorize?response_type=code&client_id=496f7265-517d-428f-b445-21945835480d&redirect_uri=https://fruitsagency.com/platform&scope=snapchat-marketing-api&state=snapchat",

  INSTAGRAM_CONNECTION_URL: "https://www.facebook.com/v16.0/dialog/oauth?client_id=906246054134743&redirect_uri=https://app.everywhere-ai.com/my-creator-info&scope=pages_show_list,instagram_basic,instagram_manage_insights,pages_read_engagement&state=instagram",

  PAYPAL_CLIENT_ID: "AfDPLCaTnuJg4JQNHLdJ6ZYxxng2eD02e90dHdK1gjszujyrU5TV12WK_TfBhZGSqSYJlK1qvrDNxzuB",
  PAYPAL_SECRET: "EOxW0yqL0H6TaHLvR64oQ7iPoHG0bqGNbaDJFC9X3uCcqCviOG1rANYi14q5YkA5tYJlq47XSKqXGQK9",

  STRIPE_SCRETE_KEY: "sk_test_51MvbFPKBGIWRaQsLqH78deEwFL2qJwhWYMEoeXUq7dFCS5n1WAeB2D1tt4ThDoOdZ3d98pBOT8z7azL7TRvSGqZc006dipfcms",

  ENV: `${process.env.NODE_ENV}`
};