// ** React Imports
import { useCallback, useEffect, useRef, useState } from "react";

// ** MUI Imports
import {
  Box,
  FormControlLabel,
  InputBase,
  Switch,
  Typography,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { styled, alpha } from "@mui/material/styles";

// ** Third Party Library
import getSymbolFromCurrency from "currency-symbol-map";
import { useNavigate } from "react-router-dom";

// ** Redux
import { useAppDispatch } from "store/store";
import {
  getFacebookProfiles,
  getSnapProfiles,
  getTiktokProfiles,
  updateAdaccountActiveStatus,
} from "store/Thunk/platformConnectionThunk";

// ** Custom Components
import CustomChip from "@core/components/mui/chip";

// ** Icons
import SearchIcon from "@mui/icons-material/Search";

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

export interface Props {
  adAccount?: any;
  title?: any;
  setIsBackdrop?: any;
}

export default function AdAccountsTab({
  adAccount,
  title,
  setIsBackdrop,
}: Props) {
  let inputRef: any = useRef(null);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [accountData, setAccountData] = useState(adAccount);
  const [switchLoading, setSwitchLoading] = useState<any>({});

  useEffect(() => {
    setAccountData(adAccount);
  }, [adAccount]);

  const handledToggleChange = (checked: any, id: any) => {
    const data = {
      active: checked ? 1 : 0,
    };
    // setIsBackdrop(true);
    setSwitchLoading((prevState: any) => ({ ...prevState, [id]: true }));
    dispatch(updateAdaccountActiveStatus({ data, id, navigate }))
      .then((response: any) => {
        if (response?.payload?.status == 200 && title == "Meta Ads") {
          dispatch(getFacebookProfiles({ navigate }))
            .then((res: any) => {
              // setIsBackdrop(false);
              setSwitchLoading((prevState: any) => ({
                ...prevState,
                [id]: false,
              }));
            })
            .catch((error) => {
              setSwitchLoading((prevState: any) => ({
                ...prevState,
                [id]: false,
              }));
            });
        } else if (
          response?.payload?.status == 200 &&
          title == "Snapchat Ads"
        ) {
          dispatch(getSnapProfiles({ navigate }))
            .then((res: any) => {
              // setIsBackdrop(false);
              setSwitchLoading((prevState: any) => ({
                ...prevState,
                [id]: false,
              }));
            })
            .catch((error) => {
              setSwitchLoading((prevState: any) => ({
                ...prevState,
                [id]: false,
              }));
            });
        } else if (response?.payload?.status == 200 && title == "Tiktok Ads") {
          dispatch(getTiktokProfiles({ navigate }))
            .then((res: any) => {
              // setIsBackdrop(false);
              setSwitchLoading((prevState: any) => ({
                ...prevState,
                [id]: false,
              }));
            })
            .catch((error) => {
              setSwitchLoading((prevState: any) => ({
                ...prevState,
                [id]: false,
              }));
            });
        }
      })
      .catch((error) => {
        setSwitchLoading((prevState: any) => ({
          ...prevState,
          [id]: false,
        }));
      });
  };

  const columns = [
    {
      flex: 0.3,
      minWidth: 200,
      field: "account_name",
      headerName: "ACCOUNT",
      renderCell: (cellValues: any) => {
        return (
          <Box>
            <FormControlLabel
              control={
                <>
                  {switchLoading[cellValues.row.id] ? (
                    <CircularProgress size={20} sx={{ ml: 6, mr: 3 }} />
                  ) : (
                    <Switch
                      disabled={
                        cellValues.row.live_ad_account_status == "1" ||
                        cellValues.row.live_ad_account_status ==
                          "STATUS_ENABLE" ||
                        cellValues.row.live_ad_account_status == "ACTIVE" ||
                        cellValues.row.live_ad_account_status == "Active"
                          ? false
                          : true
                      }
                      checked={cellValues.row.active == 0 ? false : true}
                      onChange={(e) =>
                        handledToggleChange(e.target.checked, cellValues.row.id)
                      }
                    />
                  )}
                </>
              }
              label={cellValues.row.account_name}
            />
          </Box>
        );
      },
    },
    {
      flex: 0.2,
      minWidth: 100,
      field: "live_ad_account_status",
      headerName: "STATUS",
      renderCell: (cellValues: any) => {
        const status: any =
          cellValues.row.live_ad_account_status == "1" ||
          cellValues.row.live_ad_account_status == "STATUS_ENABLE" ||
          cellValues.row.live_ad_account_status == "ACTIVE"
            ? "Active"
            : cellValues.row.live_ad_account_status == "2"
            ? "Pending Review"
            : "Inactive";
        // (cellValues.row.live_ad_account_status == "3" ? "Inactive" : cellValues.row.live_ad_account_status));
        var tooltip: string =
          cellValues.row.live_ad_account_status == "1"
            ? "The ad account is currently active and can be used to create and run campaigns."
            : cellValues.row.live_ad_account_status == "2"
            ? "The ad account is currently being reviewed by Facebook and cannot be used to create or run campaigns until the review is completed."
            : cellValues.row.live_ad_account_status == "3"
            ? "The ad account is not currently active and cannot be used to create or run campaigns. This may be due to a number of reasons, including billing issues or a violation of Facebook's ad policies."
            : "";
        var color: any;
        if (
          status == "ACTIVE" ||
          status == "STATUS_ENABLE" ||
          status == "Active"
        ) {
          color = "success";
        } else if (status == "PENDING" || status == "Pending Review") {
          color = "warning";
        } else if (status == "Inactive" || status == "STATUS_DISABLE") {
          color = "error";
        } else if ((title = "Snapchat Ads")) {
          color = "error";
        } else if ("Tiktok Ads") {
          color = "warning";
        }

        return (
          <Tooltip title={tooltip}>
            <Box>
              <CustomChip
                size="small"
                skin="light"
                color={color}
                label={status}
                sx={{ "& .MuiChip-label": { textTransform: "capitalize" } }}
              />
            </Box>
          </Tooltip>
        );
      },
    },
    {
      flex: 0.2,
      minWidth: 100,
      field: "spend",
      headerName: "Spend last 28 days",
      renderCell: (cellValues: any) => {
        const status = cellValues?.row?.last_28days_spend_status;
        const currency = getSymbolFromCurrency(cellValues?.row?.currency);
        let spend;

        if (
          cellValues.row.active != 0 &&
          (cellValues.row.live_ad_account_status == "1" ||
            cellValues.row.live_ad_account_status == "STATUS_ENABLE" ||
            cellValues.row.live_ad_account_status == "ACTIVE")
        ) {
          if (status == 1) {
            spend = (
              <Box>
                <CustomChip
                  size="small"
                  skin="light"
                  color={"warning"}
                  label={"INPROGRESS"}
                  sx={{ "& .MuiChip-label": { textTransform: "capitalize" } }}
                />
              </Box>
            );
          } else if (cellValues.row.spend != null) {
            spend = currency + cellValues?.row?.spend;
          } else {
            spend = "-";
          }
        } else {
          spend = "-";
        }
        return <Typography>{spend}</Typography>;
      },
    },
    {
      flex: 0.3,
      field: "account_id",
      minWidth: 300,
      headerName: "ID",
    },
  ];

  const handleSearchChange = (value: string) => {
    const accountArray: any = adAccount.filter((element: any) => {
      if (
        element.account_name
          .toLocaleLowerCase()
          .includes(value.toLocaleLowerCase())
      ) {
        return element;
      }
    });
    setAccountData(accountArray);
  };

  // Handle ESC & shortcut keys keydown events
  const handleKeydown = useCallback((event: KeyboardEvent) => {
    // ** Shortcut keys to open searchbox (Ctrl + /)
    if (event.ctrlKey && event.which === 191) {
      const inputElement = inputRef.current;
      if (inputElement) {
        inputElement.select();
        event.preventDefault();
      }
    }
  }, []);

  useEffect(() => {
    document.addEventListener("keydown", handleKeydown);

    return () => {
      document.removeEventListener("keydown", handleKeydown);
    };
  }, [handleKeydown]);

  return (
    <Box>
      <Box sx={{ mt: 5 }}>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>
          <StyledInputBase
            placeholder="Search (Ctrl+/)"
            inputRef={inputRef}
            inputProps={{ "aria-label": "search" }}
            onChange={(e: any) => handleSearchChange(e.target.value)}
          />
        </Search>
      </Box>
      <Box sx={{ mt: 5 }}>
        <DataGrid
          disableSelectionOnClick
          columns={columns}
          rows={accountData}
          sx={{ height: "100vh", display: "flex" }}
        />
      </Box>
    </Box>
  );
}
