import { Box, Typography, useTheme, Link } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import DownloadIcon from "@mui/icons-material/Download";
import { Pending, Approve, Decline, BlankImage, DownloadWhite } from "assets";
import { STATUS, TITLE } from "@core/constants/enum";

export default function SubmissionStatusCard(props: any) {
  const { status, title, onClickDownload } = props;
  const theme = useTheme();
  return (
    <Box
      className="submission-status-block"
      sx={{
        justifyContent:
          status === STATUS.ACCEPTED ? "space-between" : "initial",
      }}
    >
      <Box className="sync-flex-direction-align">
        <Box
          className="status-img"
          component="img"
          src={
            status === STATUS.APPROVAL_NEEDED ||
            status === STATUS.CREATOR_POST_PENDING ||
            status === STATUS.POST_CONFIRMATION_PENDING
              ? Pending
              : status === STATUS.ACCEPTED
              ? Approve
              : status === STATUS.DECLINED
              ? Decline
              : BlankImage
          }
        />
        <Typography variant="caption" color={theme.palette.customColors.white}>
          {status === STATUS.CREATOR_POST_PENDING
            ? TITLE.CREATOR_POST_PENDING
            : null}
        </Typography>
      </Box>
      {status === STATUS.ACCEPTED && (
        <Link href="#" onClick={onClickDownload} download>
          <Box
            component="img"
            src={DownloadWhite}
            height="18px"
            width="15px"
            mr="10px"
          />
        </Link>
      )}
    </Box>
  );
}
