// ** MUI Imports
import { Box, Button, Typography } from "@mui/material";

// ** Custom Components
import { MARKET_PLACE } from "@core/constants/title-constants";

// ** Assets
import { PixelMelonLeaning2, Copy } from "assets";

interface Props {
  setJobCreatedDialog?: any;
  setLinkCopy?: any;
  onLinkCopy?: any;
  onClickFinish?: any;
  linkCopy?: boolean;
}

export default function CompletedDialog({
  onLinkCopy,
  onClickFinish,
  linkCopy,
}: Props) {
  return (
    <Box sx={{ margin: 2 }}>
      <Typography variant="h5">
        {MARKET_PLACE.REQUEST_CONTENT.JOB_IS_CREATED}
      </Typography>
      <Typography variant="subtitle2">
        {MARKET_PLACE.REQUEST_CONTENT.JOB_IS_NOW_VIEWABLE}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          mt: "25px",
          mb: "20px",
        }}
      >
        <img src={PixelMelonLeaning2} />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          mb: "25px",
          alignItems: "center",
          cursor: "pointer",
        }}
      >
        <img src={Copy} style={{ marginRight: "10px" }} />
        {linkCopy ? (
          <Typography
            variant="subtitle2"
            color="#72E128"
            sx={{ textTransform: "uppercase" }}
          >
            {MARKET_PLACE.REQUEST_CONTENT.LINK_COPIED}
          </Typography>
        ) : (
          <Typography
            onClick={onLinkCopy}
            variant="subtitle2"
            color="#666CFF"
            sx={{ textTransform: "uppercase" }}
          >
            {MARKET_PLACE.REQUEST_CONTENT.COPY_SHARE_LINK}
          </Typography>
        )}
      </Box>
      <Button variant="contained" fullWidth onClick={onClickFinish}>
        {MARKET_PLACE.REQUEST_CONTENT.FINISH}
      </Button>
    </Box>
  );
}
