export enum STATUS {
  ALL = "all",
  APPROVAL_NEEDED = "approval_needed",
  CREATOR_POST_PENDING = "creator_post_pending",
  POST_CONFIRMATION_PENDING = "post_confirmation_pending",
  ACCEPTED = "accepted",
  APPROVED = "approved",
  DECLINED = "declined",
  ACTION_REQUIRED = "action_required",
  PENDING = "pending",
  EARNING = "earning",
}

export enum PLATFORMS {
  TIKTOK = "Tiktok",
  SNAPCHAT = "Snapchat",
  INSTAGRAM = "Instagram",
  SNAP = "Snap",
  FACEBOOK = "Facebook",
}

export enum DIMENSIONS {
  VERTICAL = "Vertical",
  HORIZONTAL = "Horizontal",
  SQUARE = "Square",
}

export enum POST_TYPE {
  VIDEO_ONLY = "Video Only",
  VIDEO_AND_POST = "Video and Post",
  ALL = "All",
}

export enum IMAGE_NOT_FOUND {
  NOT_FOUND = "Image not found",
}

export enum TITLE {
  ALL = "All",
  EARNING = "Earning",
  ACTION_REQUIRED = "Action Required",
  PENDING = "Pending",
  APPROVED = "Approved",
  DECLINED = "Declined",
  VIDEO_APPROVAL_NEEDED = "Video approval needed",
  POST_CONFIRMATION_NEEDED = "Post confirmation needed",
  CREATOR_POST_PENDING = "Creator post pending",
  POST_DISAPPROVED = "Post disapproved",
}

export enum BATCH_STATUS {
  ERROR = "Error",
  NO = "No",
  YES = "Yes",
  STOP = "Stop",
  DONE = "Done",
  START = "Start",
  SCHEDULING = "Scheduling",
  STOPPED = "Stopped",
}

export enum BID_STRATEGY {
  LOWEST_COST = "Highest Volume",
  BID_CAP = "Bid Cap",
}

export enum CAMPAIGN_OBJECTIVE {
  TRAFFIC = "Traffic",
  CONVERSIONS = "Conversions",
}

export enum OPTIMIZE_EVENT_TYPE {
  ON_WEB_ORDER = "ON_WEB_ORDER",
  ON_WEB_CART = "ON_WEB_CART",
  INITIATE_ORDER = "INITIATE_ORDER",
  ON_WEB_DETAIL = "ON_WEB_DETAIL",
  SHOPPING = "SHOPPING",

  PIXEL_PURCHASE = "PIXEL_PURCHASE",
  PIXEL_ADD_TO_CART = "PIXEL_ADD_TO_CART",
  PIXEL_PAGE_VIEW = "PIXEL_PAGE_VIEW",
  PIXEL_SIGNUP = "PIXEL_SIGNUP",
  STORY_OPENS = "STORY_OPENS",
  IMPRESSIONS = "IMPRESSIONS",
  SWIPES = "SWIPES",
}

export enum OPTIMIZE_EVENT_TYPE_VALUE {
  PURCHASE = "Purchase",
  ADD_TO_CART = "Add to cart",
  INITIATED_CHECKOUT = "Initiated Checkout",
  CONTENT_VIEW = "Content View",
  COMPLETE_PAYMENT = "Complete Payment",
  PAGE_VIEW = "Page View",
  SIGN_UP = "Sign up",
  STORY_OPENS = "Story Opens",
  IMPRESSIONS = "Impressions",
  SWIPES = "Swipes / Clicks",
}

export enum UPLOAD_TYPE {
  VIDEO = "Video",
  IMAGE = "Image",
}

export enum PLACEMENT_TYPE {
  STORY = "Story",
  POST = "Post",
}
