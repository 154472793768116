// ** React
import { useState, Fragment, useEffect, useCallback } from "react";

// ** MUI Imports
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Typography,
  Grow,
} from "@mui/material";
import { Theme } from "@mui/material/styles";

// ** Third Party Imports
import { useNavigate } from "react-router-dom";
import moment from "moment";

// ** Redux
import { useAppSelector } from "store/store";

// ** Custom Components
import { route_info } from "navigation/route";
import CustomChip from "@core/components/mui/chip";
import { MARKET_PLACE } from "@core/constants/title-constants";
import TableHeader from "views/apps/user/list/TableHeader";
import EarningNestedTable from "pages/marketplace/create-content/Earnings/EarningTable/EarningNestedTable";

// ** Icons
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

// ** Assets
import { PixelMelonFalling } from "assets";

interface Props {
  setDialogOpen?: any;
  earningData?: any;
  schedulingLoading?: any;
  setSchedulingLoading?: any;
}

const EarningTable = ({ schedulingLoading, setSchedulingLoading }: Props) => {
  const navigate = useNavigate();

  const { earningData } = useAppSelector((state: any) => state.earningSlice);
  const [tableData, setTableData] = useState([]);
  const [open, setOpen] = useState(null);
  const [selectAll, setSelectAll] = useState<any>([]);
  const [value, setValue] = useState<string>("");
  const [selected, setSelected] = useState<number>(0);
  const [isDataAvailable, setIsDataAvailable] = useState<boolean>(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  useEffect(() => {
    var tableArray: any = [];

    if (earningData?.length) {
      earningData?.map((item: any, index: any) => {
        tableArray.push({
          id: item?.id,
          date: moment(item?.created_at).format("DD/MM/YYYY"),
          status: item?.payout_status,
          details: item?.payout_method,
          amount: item?.amount,
          earning_payout: item?.earning_payout,
        });
      });

      setIsDataAvailable(true);
      setTableData(tableArray);
    } else {
      setIsDataAvailable(false);
    }
  }, [earningData]);

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const Row = (row: any, index: number) => {
    const handleOpen = (index: any) => {
      if (open == index) {
        setOpen(null);
      } else {
        setOpen(index);
      }

      handleSelectAll(index);
    };

    const handleSelectAll = (value: any) => {
      setSelected(value);
      let temp = selectAll;
      if (temp.includes(value)) {
        temp = temp.filter((x: any) => x !== value);
      } else temp.push(value);

      setSelectAll(temp);
    };

    const statusColor = row?.status == "paid_out" ? "success" : "warning";

    return (
      <Fragment>
        <TableRow
          sx={{
            backgroundColor:
              selected === index && selectAll.includes(index)
                ? "rgba(102, 108, 255, 0.05);"
                : "white",
            cursor: "auto",
          }}
          key={index}
        >
          <TableCell
            sx={{
              "&.MuiTableCell-body": {
                pt: "0px !important",
                pb: "0px !important",
              },
            }}
          >
            <IconButton
              onClick={() => {
                handleOpen(index);
              }}
            >
              {selected === index && selectAll.includes(index) ? (
                <KeyboardArrowDownIcon />
              ) : (
                <KeyboardArrowRightIcon />
              )}
            </IconButton>
          </TableCell>

          <TableCell
            sx={{
              "&.MuiTableCell-body": {
                pt: "0px !important",
                pb: "0px !important",
              },
            }}
          >
            <Typography
              color={
                selected === index && selectAll.includes(index)
                  ? "#666CFF"
                  : "#636578"
              }
              variant="subtitle2"
              sx={{ fontWeight: "bold", cursor: "pointer" }}
              onClick={() =>
                navigate(`${route_info.market_place.invoice}/${row.id}`, {
                  state: index,
                })
              }
            >
              {"#" + (index + 1)}
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              "&.MuiTableCell-body": {
                pt: "0px !important",
                pb: "0px !important",
              },
            }}
          >
            {row?.date}
          </TableCell>
          <TableCell sx={{ width: 1000 }}>
            <CustomChip
              skin="light"
              size="small"
              label={
                row?.status === "paid_out" ? "Paid out" : "Ready for payout"
              }
              color={statusColor}
              sx={{
                textTransform: "capitalize",
                "& .MuiChip-label": { px: 2.5, lineHeight: 1.385 },
              }}
            />
          </TableCell>
          <TableCell sx={{ width: 1000 }}>{row?.details}</TableCell>
          <TableCell sx={{ width: 1000 }}>{row?.amount}</TableCell>
        </TableRow>

        <TableRow>
          <TableCell colSpan={10} sx={{ py: "0 !important" }}>
            <Collapse
              in={index == open ? true : false}
              timeout="auto"
              unmountOnExit
            >
              {isDataAvailable ? (
                <EarningNestedTable earningPayoutData={row.earning_payout} />
              ) : (
                <Typography
                  sx={{ padding: 5, fontWeight: 500 }}
                  variant="subtitle1"
                >
                  {MARKET_PLACE.CREATE_CONTENT.NO_EARNING}
                </Typography>
              )}
            </Collapse>
          </TableCell>
        </TableRow>
      </Fragment>
    );
  };

  const handleFilter = useCallback((val: string) => {
    setValue(val);
  }, []);

  const handleExportClick = () => {
    console.log("Hello clicked export");
  };

  return (
    <>
      {tableData && tableData.length ? (
        <>
          <Grow in={true}>
            <TableContainer
              component={Paper}
              sx={{ boxShadow: "0px 2px 10px rgba(76, 78, 100, 0.22)" }}
            >
              <TableHeader
                value={value}
                handleFilter={handleFilter}
                handleExport={handleExportClick}
                toggle={() => console.log("Clicked")}
              />
              <Table
                aria-label="collapsible table"
                sx={{
                  "& .MuiTableCell-root": {
                    padding: "16px, 16px, 16px, 20px",
                  },
                }}
              >
                <TableHead
                  className="demo"
                  sx={{ "& .MuiTableCell-head": { lineHeight: "0.959rem" } }}
                >
                  <TableRow sx={{ backgroundColor: "rgba(245, 245, 247, 1)" }}>
                    <TableCell></TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          marginTop: "5px",
                          marginBottom: "5px",
                          pr: "142px",
                          borderRight: 2,
                          borderColor: "rgba(76, 78, 100, 0.12)",
                        }}
                      >
                        #
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          paddingRight: "100px",
                          borderRight: 2,
                          borderColor: "rgba(76, 78, 100, 0.12)",
                        }}
                      >
                        {MARKET_PLACE.CREATE_CONTENT.DATE}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          borderRight: 2,
                          borderColor: "rgba(76, 78, 100, 0.12)",
                        }}
                      >
                        {MARKET_PLACE.CREATE_CONTENT.STATUS}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box
                        sx={{
                          borderRight: 2,
                          borderColor: "rgba(76, 78, 100, 0.12)",
                          paddingRight: "100px",
                        }}
                      >
                        {MARKET_PLACE.CREATE_CONTENT.DETAILS}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Box>{MARKET_PLACE.CREATE_CONTENT.AMOUNT_IN_USD}</Box>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData &&
                    tableData
                      ?.slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row: any, index: any) => Row(row, index))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grow>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={tableData?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </>
      ) : (
        <Box className="no-data-found-box">
          <Box
            component="img"
            src={PixelMelonFalling}
            className="no-data-found-img"
          />
          <Typography variant="body2" color="rgba(76, 78, 100, 0.68)">
            {MARKET_PLACE.CREATE_CONTENT.THERE_ARE_NO_EARNING}
          </Typography>
        </Box>
      )}
    </>
  );
};

export default EarningTable;
