// ** React
import { useContext, useEffect, useState } from "react";

// ** MUI Imports
import {
  Box,
  Card,
  Chip,
  Collapse,
  Divider,
  Grid,
  IconButton,
  ListItem,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Tooltip,
} from "@mui/material";

// ** Third Party Library
import axios from "axios";

// ** Redux
import { useAppDispatch, useAppSelector } from "store/store";
import {
  getAdAccount,
  getProfiles,
  getCampaign,
} from "store/Thunk/autoSchedulerThunk";

import { AutoschudlerContext } from "context/autoschedulerContex";

// ** Custom Component
import CustomChip from "@core/components/mui/chip";
import { PLATFORMS } from "@core/constants/enum";
import { MEDIA_PLANNER } from "@core/constants/title-constants";
import GetCountryName from "components/GetCountryData/getCountryName";

// ** Icons
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { FileDocumentOutline } from "mdi-material-ui";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import BoltIcon from "@mui/icons-material/Bolt";

export type TreeData = {
  id: string;
  name: string;
  disabledButton: boolean;
  cardData: any[];
  children?: TreeData[];
};

const handleFilePreview = (file: any) => {
  if (file?.url != "") {
    return (
      <>
        {file?.creative_type == "Video" ? (
          <video
            width={38}
            height={38}
            style={{
              borderRadius: "10px",
              padding: "3px",
              border: `1px solid rgba(93, 89, 98, 0.14)`,
            }}
            src={file?.url}
          />
        ) : (
          <Box
            component="img"
            width={38}
            height={38}
            sx={{
              borderRadius: "10px",
              padding: "3px",
              border: `1px solid rgba(93, 89, 98, 0.14)`,
            }}
            alt={file?.filename}
            src={file?.url}
          />
        )}
      </>
    );
  } else {
    return <FileDocumentOutline />;
  }
};

function Row(props: any) {
  const { row, index } = props;
  const [open, setOpen] = useState(false);
  const [itemsToShow, setItemsToShow] = useState(1);

  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowDownIcon /> : <KeyboardArrowRightIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          <Typography
            variant="subtitle2"
            sx={{ fontWeight: "500", color: "rgba(99, 101, 120, 1)" }}
          >
            {" "}
            Adset {index + 1}{" "}
          </Typography>
        </TableCell>
        <TableCell align="right">
          <>
            {row?.countries?.split(",")?.length ? (
              <Box sx={{ display: "flex", mt: 1 }}>
                <Box
                  component="img"
                  src={
                    "https://cdn.ip2location.com/assets/img/flags/" +
                    row?.countries?.split(",")[0]?.toLowerCase() +
                    ".png"
                  }
                ></Box>
                <Tooltip
                  title={
                    row?.countries?.split(",")?.length > 1
                      ? row?.countries
                          .split(",")
                          ?.slice(1, row?.countries?.length - 1)
                          .map((item: any, index: number) => {
                            return GetCountryName(item);
                          })
                          .join(", ")
                      : null
                  }
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ ml: 2, cursor: "pointer" }}
                  >
                    {GetCountryName(row?.countries?.split(",")[0])} &nbsp;{" "}
                    {row?.countries?.split(",")?.length > 1
                      ? `+ ${row?.countries?.split(",")?.length - 1} more`
                      : ""}
                  </Typography>
                </Tooltip>
              </Box>
            ) : (
              <Box sx={{ display: "flex", mt: 1 }}>
                <Box
                  component="img"
                  src={
                    "https://cdn.ip2location.com/assets/img/flags/" +
                    row?.countries?.toLowerCase() +
                    ".png"
                  }
                ></Box>
                <Typography variant="subtitle2" sx={{ ml: 2 }}>
                  {GetCountryName(row?.countries)}
                </Typography>
              </Box>
            )}
          </>
        </TableCell>
        <TableCell>{row?.ad_creative?.length}</TableCell>
        <TableCell>{row?.daily_budget}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Grid container sx={{ margin: 2 }}>
              {row?.ad_creative?.map((item: any, index: any) => (
                <Grid item sm={12} xs={12} md={6}>
                  <Box sx={{ display: "flex", width: "100%" }}>
                    <ListItem
                      key={item?.filename}
                      sx={{
                        borderRadius: 1,
                        border: `1px solid rgba(93, 89, 98, 0.14)`,
                        margin: 1,
                        display: "flex",
                        width: "100%",
                        justifyContent: "space-between",
                        alignItems: "center",
                      }}
                    >
                      <>
                        <Box sx={{ display: "flex" }}>
                          <Box
                            sx={{
                              diplay: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              mr: 2,
                              height: 38,
                              width: 38,
                            }}
                          >
                            {handleFilePreview(item)}
                          </Box>
                          <Box>
                            <Typography
                              variant="subtitle2"
                              sx={{ fontWeight: "600", overflow: "hidden" }}
                            >
                              {item?.filename?.slice(0, 30) +
                                (item?.filename?.length > 30 ? "..." : "")}
                            </Typography>
                            <Typography variant="subtitle2">
                              {item?.creative_size}
                            </Typography>
                          </Box>
                        </Box>
                      </>
                      <Box>
                        <CustomChip
                          size="small"
                          skin="light"
                          color="info"
                          label={`${item?.placement_type} format`}
                          sx={{
                            "& .MuiChip-label": { textTransform: "capitalize" },
                          }}
                        />
                        {/* <IconButton onClick={() => handleRemoveFile(file)}>
                <Close fontSize="small" />
              </IconButton> */}
                      </Box>
                    </ListItem>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

const ReviewTiktokTabs = () => {
  const dispatch = useAppDispatch();
  let autoSchedulerTitle = MEDIA_PLANNER.auto_scheduler;

  const { campaign, tiktok, state }: any = useContext(AutoschudlerContext);

  const {
    tiktokProfiles,
    tiktokAdAccount,
    tiktokCampaign,
    reviewPageAdsetData,
    availablePlatformTitle,
  } = useAppSelector((state: any) => state.autoSchedulerSlice);
  const [profileName, setProfileName] = useState("");
  const [adAccountName, setAdAccountName] = useState("");
  const [existingCampaign, setExistingCampaign] = useState<any>();

  useEffect(() => {
    const platform = {
      ad_platform: PLATFORMS.TIKTOK,
    };
    dispatch(getProfiles({ platform }));
  }, []);

  useEffect(() => {
    if (availablePlatformTitle?.includes("Tiktok")) {
      const platform = {
        ad_platform: PLATFORMS.TIKTOK,
        profile_id: tiktok?.profile,
      };
      dispatch(getAdAccount({ platform }));
    }
  }, []);

  useEffect(() => {
    let CancelToken: any = axios.CancelToken;
    let cancel: any;

    const campaignReq = {
      ad_platform: PLATFORMS.TIKTOK,
      profile_id: tiktok?.profile,
      advertiser_id: tiktok?.advertiserAccount,
      search_keyword: "",
      objective: campaign?.objective,
    };

    const token = {
      cancelToken: new CancelToken(function executor(c: any) {
        cancel = c;
      }),
    };

    dispatch(getCampaign({ campaignReq, token }));
  }, []);

  useEffect(() => {
    let temp = tiktokCampaign?.find((obj: any) => {
      return obj?.campaign_id === tiktok?.existingAccount;
    });

    setExistingCampaign(temp);
  }, [tiktokCampaign]);

  useEffect(() => {
    if (tiktokProfiles) {
      const selectedProfile = tiktokProfiles.find((item: any) => {
        return item?.id === tiktok?.profile;
      });
      setProfileName(
        selectedProfile?.first_name
          ? selectedProfile?.first_name + " " + selectedProfile?.last_name
          : selectedProfile?.last_name
      );
    }
  }, [tiktokProfiles]);

  useEffect(() => {
    if (tiktokAdAccount) {
      const selectedAdaccount = tiktokAdAccount?.find((item: any) => {
        return item?.account_id === tiktok?.advertiserAccount;
      });
      setAdAccountName(selectedAdaccount?.account_name);
    }
  }, [tiktokAdAccount]);

  const instaInterest = () => {
    let temp = (
      <Tooltip
        componentsProps={{
          tooltip: {
            sx: {
              maxWidth: "500px",
            },
          },
        }}
        title={
          tiktok?.interest?.length > 1
            ? tiktok?.interest
                ?.slice(1, tiktok?.interest?.length)
                .map((item: any, index: number) => {
                  return item.name;
                })
                .join(", ")
            : null
        }
      >
        <Typography variant="subtitle2" sx={{ ml: 2 }}>
          {tiktok?.interest &&
            tiktok?.interest
              ?.slice(0, 1)
              .map((item: any, index: number) => {
                return item.name;
              })
              .join(", ")}
          {tiktok?.interest?.length > 1
            ? ` +${tiktok?.interest?.length - 1} more`
            : ""}
        </Typography>
      </Tooltip>
    );
    return temp;
  };

  const ageGroup = () => {
    let startAge = tiktok?.ageGroup[0].split("-");
    let endAge = tiktok?.ageGroup[tiktok?.ageGroup.length - 1].split("-");
    let tempStr = startAge[0] + " - " + endAge[endAge.length - 1];

    return tempStr;
  };

  return (
    <>
      {/* Tabs Settings */}
      <Card sx={{ mt: 4, padding: 8, pb: 0 }}>
        <Box className="align-justify-item-center" sx={{ overflow: "hidden" }}>
          <Chip label="3a. Review settings for TikTok" />
          <Divider sx={{ width: "100%" }} />
        </Box>
        <Grid container>
          {/* Left side secion */}
          <Grid item xs={12} md={6}>
            <Box className="review_media_common_container">
              <Box display={"flex"} flexWrap={"wrap"}>
                <Typography variant="subtitle2" className="review_label">
                  {autoSchedulerTitle.PROFILE + ":"}
                </Typography>
                <Typography variant="subtitle2" className="review_value">
                  {profileName ? profileName : "-"}
                </Typography>
              </Box>

              <Box display={"flex"} flexWrap={"wrap"}>
                <Typography variant="subtitle2" className="review_label">
                  Advertiser account:
                </Typography>
                <Typography variant="subtitle2" className="review_value">
                  {adAccountName ? adAccountName : "-"}
                </Typography>
              </Box>

              <Box display={"flex"} flexWrap={"wrap"}>
                <Typography variant="subtitle2" className="review_label">
                  {autoSchedulerTitle.CAMPAIGN + ":"}
                </Typography>
                {tiktok?.activeButton !== "Existing" ? (
                  <>
                    <Typography variant="subtitle2" className="review_value">
                      {tiktok?.newAccount ? tiktok?.newAccount : "-"}
                      <Chip
                        icon={<BoltIcon fontSize="small" />}
                        label="NEW"
                        color="success"
                        size="small"
                        sx={{ ml: "10px" }}
                      />
                    </Typography>
                  </>
                ) : (
                  <Typography variant="subtitle2" className="review_value">
                    {tiktok?.activeButton == "Existing" &&
                      (existingCampaign?.campaign_name
                        ? existingCampaign?.campaign_name
                        : "-")}
                  </Typography>
                )}
              </Box>

              <Box display={"flex"} flexWrap={"wrap"}>
                <Typography variant="subtitle2" className="review_label">
                  {autoSchedulerTitle.INTEREST + ":"}
                </Typography>
                <Typography variant="subtitle2" className="review_value">
                  {tiktok?.interest?.length > 0 ? instaInterest() : "-"}
                </Typography>
              </Box>

              <Box display={"flex"} flexWrap={"wrap"}>
                <Typography variant="subtitle2" className="review_label">
                  {autoSchedulerTitle.AGE_GROUP_REVIEW + ":"}
                </Typography>
                <Typography variant="subtitle2" className="review_value">
                  {tiktok?.ageGroup?.length > 0 ? ageGroup() : "-"}
                </Typography>
              </Box>

              <Box display={"flex"} flexWrap={"wrap"}>
                <Typography variant="subtitle2" className="review_label">
                  {autoSchedulerTitle.CUSTOM_AUDIENCE_REIVEW + ":"}
                </Typography>
                <Typography variant="subtitle2" className="review_value">
                  {tiktok?.customAudiences ? "ON" : "OFF"}
                </Typography>
              </Box>
            </Box>
          </Grid>

          {/* Right side secion */}
          <Grid item xs={12} md={6}>
            <Box className="review_media_common_container">
              <Box display={"flex"} flexWrap={"wrap"}>
                <Typography variant="subtitle2" className="review_label">
                  {autoSchedulerTitle.IDENTITY + ":"}
                </Typography>
                <Typography variant="subtitle2" className="review_value">
                  {tiktok?.identity ? tiktok?.identityName : "-"}
                </Typography>
              </Box>

              <Box display={"flex"} flexWrap={"wrap"}>
                <Typography variant="subtitle2" className="review_label">
                  {autoSchedulerTitle.PIXEL + ":"}
                </Typography>
                <Typography variant="subtitle2" className="review_value">
                  {tiktok?.pixel ? tiktok?.pixelName : "-"}
                </Typography>
              </Box>

              <Box display={"flex"} flexWrap={"wrap"}>
                <Typography variant="subtitle2" className="review_label">
                  {autoSchedulerTitle.OPTIMIZE_EVENT + ":"}
                </Typography>
                <Typography variant="subtitle2" className="review_value">
                  {tiktok?.optimizeEvent?.length
                    ? tiktok?.optimizeEventName
                    : "-"}
                </Typography>
              </Box>

              <Box display={"flex"} flexWrap={"wrap"}>
                <Typography variant="subtitle2" className="review_label">
                  {autoSchedulerTitle.CREATIVE_PER_AD + ":"}
                </Typography>
                <Typography variant="subtitle2" className="review_value">
                  {tiktok?.maximumCreative ? tiktok?.maximumCreative : "-"}
                </Typography>
              </Box>

              <Box display={"flex"} flexWrap={"wrap"}>
                <Typography variant="subtitle2" className="review_label">
                  {autoSchedulerTitle.CAPTION + ":"}
                </Typography>
                <Typography variant="subtitle2" className="review_value">
                  {tiktok?.caption ? tiktok?.caption : "-"}
                </Typography>
              </Box>

              <Box display={"flex"} flexWrap={"wrap"}>
                <Typography variant="subtitle2" className="review_label">
                  {autoSchedulerTitle.ACCELERATE_SPEND + ":"}
                </Typography>
                <Typography variant="subtitle2" className="review_value">
                  {tiktok?.accelerateSpending ? "ON" : "OFF"}
                </Typography>
              </Box>

              <Box display={"flex"} flexWrap={"wrap"}>
                <Typography variant="subtitle2" className="review_label">
                  {autoSchedulerTitle.DAYPARTING + ":"}
                </Typography>
                <Typography variant="subtitle2" className="review_value">
                  {tiktok?.dayparting ? "ON" : "OFF"}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Card>
      {/* <Card sx={{ mt: 4, padding: 8 }}>
        <Box className="align-justify-item-center">
          <Chip label="2. Adset structure" />
          <Divider sx={{ width: "100%" }} />
        </Box>
        <Box sx={{ padding: 4 }}>
          <Typography variant="subtitle1">
            Adset structure is based on countries, smart schedule, and the total
            number of creatives.
          </Typography>
        </Box>


        <TableContainer component={Paper}>
          <Table aria-label="collapsible table">
            <TableHead sx={{ background: "rgba(245, 245, 247, 1)" }}>
              <TableRow>
                <TableCell />
                <TableCell>Adset</TableCell>
                <TableCell>Countries</TableCell>
                <TableCell>creatives</TableCell>
                <TableCell>Daily budget</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {reviewPageAdsetData?.Tiktok?.map((row: any, index: number) => (
                <Row key={index} row={row} index={index} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Card> */}
    </>
  );
};

export default ReviewTiktokTabs;
