export default function LinkfireIcon({active = false}){

    if (active) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <rect width="30" height="30" rx="5" fill="#FF3850"/>
            <g clipPath="url(#clip0_916_13435)">
            <path d="M26.5102 6.61777C26.571 6.55727 26.5895 6.46713 26.5573 6.38823C26.5233 6.30907 26.4445 6.25739 26.3571 6.25677H13.4792C8.24454 6.25677 4 10.3957 4 15.5011C4 20.6033 8.24454 24.7433 13.4792 24.7433C15.9905 24.7507 18.4016 23.775 20.1789 22.0319C22.8913 19.4469 24.4183 14.5818 25.6085 10.1502L25.6467 10.0095L25.7318 9.69115C25.7484 9.62771 25.7339 9.56033 25.6927 9.50886C25.6511 9.45702 25.5878 9.42659 25.5207 9.42618H23.9107C23.8234 9.42655 23.7443 9.37588 23.7094 9.29729C23.6759 9.21784 23.695 9.12635 23.7575 9.06621L26.5102 6.61777Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M13.8408 10.5456H17.4617V10.5446C18.336 10.5455 19.0443 11.2418 19.0446 12.1006V15.6556C19.044 16.5143 18.3358 17.2104 17.4617 17.2116H13.8408C12.9666 17.2104 12.2583 16.5144 12.2574 15.6556V14.9896H13.615V15.6556C13.6172 15.7776 13.7171 15.876 13.8413 15.8785H17.4617C17.5865 15.8773 17.6874 15.7783 17.6886 15.6556V12.1006C17.6869 11.9782 17.5863 11.8794 17.4617 11.8777H13.8408C13.7195 11.8777 13.6144 11.9804 13.6144 12.1006V12.7676H12.2574V12.1011C12.2594 11.2426 12.9674 10.5471 13.8413 10.5451L13.8408 10.5456ZM14.7472 18.5462C14.8712 18.5451 14.9718 18.4472 14.9741 18.3254V17.6578H16.3311V18.3238C16.3302 19.1826 15.6213 19.8782 14.7472 19.8782H11.1263C10.2523 19.8777 9.54403 19.1819 9.54346 18.3233V14.7693C9.54374 13.9103 10.2519 13.2137 11.1263 13.2123H14.7472C15.622 13.2132 16.3308 13.9099 16.3311 14.7693V15.4338H14.9741V14.7693C14.9732 14.6465 14.8722 14.5471 14.7472 14.5459H11.1268C11.0025 14.5479 10.9026 14.6471 10.902 14.7693V18.3243C10.9037 18.4458 11.0032 18.5439 11.1268 18.5462H14.7472Z" fill="#FF3850"/>
            </g>
            <defs>
            <clipPath id="clip0_916_13435">
            <rect width="23" height="19" fill="white" transform="translate(4 6)"/>
            </clipPath>
            </defs>
            </svg>
        );
    } else {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 30 31" fill="none">
<rect y="0.5" width="30" height="30" rx="5" fill="#AFAFAF"/>
<g clipPath="url(#clip0_916_13528)">
<path d="M26.5102 7.11776C26.571 7.05726 26.5895 6.96711 26.5573 6.88822C26.5233 6.80906 26.4445 6.75737 26.3571 6.75676H13.4792C8.24454 6.75676 4 10.8957 4 16.0011C4 21.1033 8.24454 25.2433 13.4792 25.2433C15.9905 25.2507 18.4016 24.275 20.1789 22.5319C22.8913 19.9469 24.4183 15.0818 25.6085 10.6502L25.6467 10.5095L25.7318 10.1911C25.7484 10.1277 25.7339 10.0603 25.6927 10.0088C25.6511 9.95701 25.5878 9.92657 25.5207 9.92616H23.9107C23.8234 9.92654 23.7443 9.87586 23.7094 9.79727C23.6759 9.71783 23.695 9.62634 23.7575 9.56619L26.5102 7.11776Z" fill="white"/>
<path fillRule="evenodd" clipRule="evenodd" d="M13.8408 11.0456H17.4617V11.0446C18.336 11.0455 19.0443 11.7417 19.0446 12.6006V16.1556C19.044 17.0143 18.3358 17.7104 17.4617 17.7115H13.8408C12.9666 17.7104 12.2583 17.0144 12.2574 16.1556V15.4896H13.615V16.1556C13.6172 16.2776 13.7171 16.376 13.8413 16.3785H17.4617C17.5865 16.3773 17.6874 16.2782 17.6886 16.1556V12.6006C17.6869 12.4782 17.5863 12.3793 17.4617 12.3777H13.8408C13.7195 12.3777 13.6144 12.4804 13.6144 12.6006V13.2676H12.2574V12.6011C12.2594 11.7426 12.9674 11.0471 13.8413 11.0451L13.8408 11.0456ZM14.7472 19.0462C14.8712 19.0451 14.9718 18.9472 14.9741 18.8254V18.1578H16.3311V18.8238C16.3302 19.6826 15.6213 20.3782 14.7472 20.3782H11.1263C10.2523 20.3777 9.54403 19.6818 9.54346 18.8233V15.2693C9.54374 14.4103 10.2519 13.7137 11.1263 13.7123H14.7472C15.622 13.7132 16.3308 14.4099 16.3311 15.2693V15.9338H14.9741V15.2693C14.9732 15.1465 14.8722 15.047 14.7472 15.0459H11.1268C11.0025 15.0479 10.9026 15.1471 10.902 15.2693V18.8243C10.9037 18.9458 11.0032 19.0439 11.1268 19.0462H14.7472Z" fill="#AFAFAF"/>
</g>
<defs>
<clipPath id="clip0_916_13528">
<rect width="23" height="19" fill="white" transform="translate(4 6.5)"/>
</clipPath>
</defs>
</svg>
        );
    }
}