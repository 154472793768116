// ** React
import { Fragment, useContext, useEffect, useState } from "react";

// ** Mui Imports
import {
  Typography,
  Box,
  Select,
  MenuItem,
  Button,
  FormControl,
  Switch,
  TextField,
  Chip,
  Stack,
  CircularProgress,
  Autocomplete,
  FormHelperText,
  Divider,
  InputLabel,
  Card,
  Backdrop,
  Grow,
  Avatar,
} from "@mui/material";

// ** Third Party Library
import axios from "axios";

// ** Redux
import { useAppDispatch, useAppSelector } from "store/store";
import {
  fetchLatestAdaccountData,
  getAdAccount,
  getCampaign,
  getCustomAudience,
  getInterests,
  getOptimizeEvents,
  getPixels,
  getProfiles,
} from "store/Thunk/autoSchedulerThunk";

import { AutoschudlerContext } from "context/autoschedulerContex";

// ** Custom Component
import {
  PLATFORMS,
  BATCH_STATUS,
  BID_STRATEGY,
  OPTIMIZE_EVENT_TYPE,
  OPTIMIZE_EVENT_TYPE_VALUE,
  CAMPAIGN_OBJECTIVE,
} from "@core/constants/enum";
import {
  snapChatAge,
  profileName,
  isSpendingValidate,
  handledOptimizeEvent,
} from "@core/constants/global-constants";
import DropdownNotFound from "components/DropdownNotFound/DropdownNotFound";
import { MEDIA_PLANNER } from "@core/constants/title-constants";

// ** Mui Icons
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteIcon from "@mui/icons-material/Delete";
import RefreshTwoToneIcon from "@mui/icons-material/RefreshTwoTone";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { ProfileIcon } from "assets";

export interface Props {
  onBackwordButtonClick: any;
  onClearDraftClick: any;
  handleNextButtonForAll?: any;
  flag: any;
}

export default function SnapchatTabs({
  onBackwordButtonClick,
  onClearDraftClick,
  handleNextButtonForAll,
  flag,
}: Props) {
  const dispatch = useAppDispatch();

  let autoSchedulerTitle = MEDIA_PLANNER.auto_scheduler;

  const {
    snapchatProfiles,
    snapchatAdAccount,
    snapchatCampaign,
    snapchatInterests,
    snapchatPixels,
    snapchatOptimizeEvent,
    snapchatCustomAudience,
    schedulerDraftData,
  } = useAppSelector((state: any) => state.autoSchedulerSlice);

  const { campaign, snapchat, setSnapchat }: any =
    useContext(AutoschudlerContext);

  const header: any = localStorage.getItem("header");
  const companyName: any = JSON.parse(header).company_name;
  let CancelToken: any = axios.CancelToken;
  let cancel: any;

  const [loading, setLoading] = useState({
    adAccount: false,
    profile: false,
    campaign: false,
    identity: false,
    interest: false,
    pixel: false,
    optimizeEvent: false,
    customAudience: false,
  });
  const [allAgeGroup, setAllAgeGroup] = useState(true);
  const [campaignLoading, setCampaignLoading] = useState(false);
  const [interestLoading, setInterestLoading] = useState(false);
  const [refreshLoading, setRefreshLoading] = useState("");
  const [adAccountLoading, setAdAccountLoading] = useState(false);
  const [isBackdrop, setIsBackdrop] = useState(false);
  const [pixelsLoading, setPixelsLoading] = useState(false);
  const [isClearDraftEnabled, setIsClearDraftEnabled] = useState(false);

  useEffect(() => {
    setIsClearDraftEnabled(!schedulerDraftData?.id);
  }, [schedulerDraftData]);

  useEffect(() => {
    const platform = {
      ad_platform: PLATFORMS.SNAP,
    };
    dispatch(getProfiles({ platform })).then((res: any) => {
      res?.payload?.data?.data[0]?.length
        ? setLoading({ ...loading, profile: false })
        : setLoading({ ...loading, profile: true });
    });

    if (snapchat?.profile) {
      handleProfileChange(snapchat?.profile);
    }

    if (snapchat?.profile && snapchat?.advertiserAccount) {
      handleAdaccountChange(snapchat?.advertiserAccount);
      if (snapchat?.pixel) {
        handlePixelChange(snapchat?.pixel);
      }
    }

    setSnapchat({ ...snapchat, companyName: companyName });
    if (snapchat?.existingAccount) {
      handledExistingCampaingChange("");
    }
  }, []);

  useEffect(() => {
    setRefreshLoading("");
  }, [snapchat?.advertiserAccount, snapchat.profile]);

  useEffect(() => {
    if (snapChatAge?.length == snapchat?.ageGroup?.length) {
      setAllAgeGroup(true);
    } else {
      setAllAgeGroup(false);
    }
  }, [snapchat?.ageGroup]);

  const handledInterstChange = (e: any) => {
    if (cancel != undefined) {
      cancel();
    }

    const interestReq = {
      ad_platform: PLATFORMS.SNAP,
      profile_id: snapchat?.profile,
      advertiser_id: snapchat?.advertiserAccount,
      search_keyword: e,
    };

    const token = {
      cancelToken: new CancelToken(function executor(c: any) {
        cancel = c;
      }),
    };
    setInterestLoading(true);
    dispatch(getInterests({ interestReq, token }))
      .then((response: any) => {
        if (response?.payload?.status == 200) {
          setInterestLoading(false);
        }
        return response.data.response;
      })
      .catch((error: any) => {
        const result = error.response;
        return Promise.reject(result);
      });
  };

  const handleProfileChange = (e: any) => {
    const snapchatProfileEmail = snapchatProfiles?.find((obj: any) => {
      return obj?.id === e;
    });
    setSnapchat({
      ...snapchat,
      profile: e,
      profileEmail: snapchatProfileEmail?.email
        ? snapchatProfileEmail?.email
        : snapchat?.profileEmail,
      firstName: snapchatProfileEmail?.first_name,
      lastName: snapchatProfileEmail?.last_name,
      selectedAudience: snapchat.profile != e ? [] : snapchat?.selectedAudience,
      interest: snapchat.profile != e ? [] : snapchat?.interest,
      optimizeEvent: snapchat.profile != e ? [] : snapchat?.optimizeEvent,
      existingAccount: snapchat.profile != e ? "" : snapchat?.existingAccount,
      pixel: snapchat.profile != e ? "" : snapchat?.pixel,
      advertiserAccount:
        snapchat.profile != e ? "" : snapchat?.advertiserAccount,
    });

    const platform = {
      ad_platform: PLATFORMS.SNAP,
      profile_id: e,
    };
    dispatch(getAdAccount({ platform })).then((res: any) => {
      res?.payload?.data?.data?.length
        ? setAdAccountLoading(false)
        : setAdAccountLoading(true);
    });
  };

  const handleAdaccountChange = (e: any) => {
    const snapchatAdAccountName = snapchatAdAccount?.find((obj: any) => {
      return obj?.account_id === e;
    });
    var advertiseName = "";
    var campaignData = false;
    var pixelsData = false;
    var customAudienceData = false;

    const platform = {
      ad_platform: PLATFORMS.SNAP,
      profile_id: snapchat?.profile,
      advertiser_id: e,
    };

    const platformData = {
      ad_platform: PLATFORMS.SNAP,
      profile_id: snapchat?.profile,
      account_id: e,
    };

    dispatch(getPixels({ platform })).then((res: any) => {
      res?.payload?.data?.data[0]?.length
        ? setPixelsLoading(false)
        : setPixelsLoading(true);
    });

    dispatch(getCustomAudience({ platformData })).then((res: any) => {
      customAudienceData = res?.payload?.data?.data[0]?.length ? false : true;
    });

    setLoading({
      ...loading,
      campaign: campaignData,
      pixel: pixelsData,
      customAudience: customAudienceData,
    });

    var name = snapchatAdAccount?.find(
      (element: any) => element?.account_id == e
    )?.account_name;
    var objective =
      campaign?.objective != "" ? " - " + campaign?.objective : "";
    var countries =
      campaign?.countries.length >= 2
        ? " - " + "Multi Countries"
        : campaign?.countries.length == 0
        ? " - No Countries  "
        : " - " + campaign?.countries[0]?.Name;
    var language = campaign?.customLanguage
      ? " - Custom Language"
      : " - No Language";
    advertiseName = name + objective + countries + language;
    var disableCampaign =
      name +
      objective +
      " - {COUNTRY}" +
      " - {LANGUAGE_INFO}" +
      " - {LANDINGPAGE}";
    setSnapchat({
      ...snapchat,
      advertiserAccount: e,
      newAccount: advertiseName,
      disableCampaignName: disableCampaign,
      autoGenerateName: false,
      caption: "",
      adAccountName: snapchatAdAccountName?.account_name,
      selectedAudience:
        snapchat?.advertiserAccount != e ? [] : snapchat?.selectedAudience,
      interest: snapchat?.advertiserAccount != e ? [] : snapchat?.interest,
      optimizeEvent:
        snapchat?.advertiserAccount != e ? [] : snapchat?.optimizeEvent,
      existingAccount:
        snapchat?.advertiserAccount != e ? "" : snapchat?.existingAccount,
      pixel: snapchat?.advertiserAccount != e ? "" : snapchat?.pixel,
    });
  };

  const handledExistingCampaingChange = (e: any) => {
    if (cancel != undefined) {
      cancel();
    }

    const campaignReq = {
      ad_platform: PLATFORMS.SNAP,
      profile_id: snapchat?.profile,
      advertiser_id: snapchat?.advertiserAccount,
      search_keyword: e,
      objective: campaign.objective,
    };

    const token = {
      cancelToken: new CancelToken(function executor(c: any) {
        cancel = c;
      }),
    };
    setCampaignLoading(true);
    dispatch(getCampaign({ campaignReq, token }))
      .then((response: any) => {
        if (response?.payload?.status == 200) {
          setCampaignLoading(false);
        }
        return response.data.response;
      })
      .catch((error: any) => {
        const result = error.response;
        return Promise.reject(result);
      });
  };

  const handledAgeGroupChange = (name: any, index: number) => {
    let agesArray: any = snapchat?.ageGroup;

    if (snapchat?.ageGroup.includes(name)) {
      var filterArray: any = agesArray;
      agesArray = filterArray.filter((item: any) => item != name);
    } else {
      agesArray.push(name);
    }

    if (agesArray.length) {
      var array: any = [];
      var minimum: any;
      var maximum: any;
      var finaleArray: any = [];
      snapChatAge?.map((itemss: any, index: any) => {
        agesArray?.map((item: any) => {
          if (itemss?.name == item) {
            array.push(Number(item?.substring(0, 2)));
          }
        });
        if (
          array?.length &&
          array[0] == Number(itemss?.name?.substring(0, 2))
        ) {
          minimum = index;
        }
        if (array[array?.length - 1] == Number(itemss?.name?.substring(0, 2))) {
          maximum = index;
        }
      });
      for (let i = minimum; i < maximum + 1; i++) {
        finaleArray.push(snapChatAge[i]?.name);
      }

      setSnapchat({ ...snapchat, ageGroup: finaleArray });
    } else {
      setSnapchat({ ...snapchat, ageGroup: [] });
    }
  };

  const handledAllAgeGroup = () => {
    setAllAgeGroup(!allAgeGroup);
    if (!allAgeGroup) {
      const ageArray: any = snapChatAge?.map((item: any) => item?.name) ?? [];
      setSnapchat({ ...snapchat, ageGroup: ageArray });
    } else {
      setSnapchat({ ...snapchat, ageGroup: [] });
    }
  };

  const handlePixelChange = (e: any) => {
    const platformData = {
      ad_platform: PLATFORMS.SNAP,
      profile_id: snapchat?.profile,
      account_id: snapchat?.advertiserAccount,
      pixel_id: e,
    };

    const pixelName = snapchatPixels.find((obj: any) => {
      return obj.pixel_id === e;
    });

    dispatch(getOptimizeEvents({ platformData })).then((res: any) => {
      setLoading({
        ...loading,
        optimizeEvent: res?.payload?.data?.data[0]?.length > 0 ? false : true,
      });
    });
    setSnapchat({ ...snapchat, pixel: e, pixelName: pixelName?.name });
  };

  // const handledOptimizeEvent = (value: any) => {
  //   switch (value) {
  //     case OPTIMIZE_EVENT_TYPE.PIXEL_PURCHASE:
  //       return OPTIMIZE_EVENT_TYPE_VALUE.PURCHASE;
  //     case OPTIMIZE_EVENT_TYPE.PIXEL_ADD_TO_CART:
  //       return OPTIMIZE_EVENT_TYPE_VALUE.ADD_TO_CART;
  //     case OPTIMIZE_EVENT_TYPE.PIXEL_PAGE_VIEW:
  //       return OPTIMIZE_EVENT_TYPE_VALUE.PAGE_VIEW;
  //     case OPTIMIZE_EVENT_TYPE.PIXEL_SIGNUP:
  //       return OPTIMIZE_EVENT_TYPE_VALUE.SIGN_UP;
  //     case OPTIMIZE_EVENT_TYPE.STORY_OPENS:
  //       return OPTIMIZE_EVENT_TYPE_VALUE.STORY_OPENS;
  //     case OPTIMIZE_EVENT_TYPE.IMPRESSIONS:
  //       return OPTIMIZE_EVENT_TYPE_VALUE.IMPRESSIONS;
  //     case OPTIMIZE_EVENT_TYPE.SWIPES:
  //       return OPTIMIZE_EVENT_TYPE_VALUE.SWIPES;
  //     default:
  //       return value;
  //   }
  // };

  const handledButton = () => {
    const { objective } = campaign ?? {};
    const {
      pixel,
      profile,
      advertiserAccount,
      activeButton,
      existingAccount,
      ageGroup,
      heading,
      companyName,
    } = snapchat ?? {};

    var required =
      (objective !== CAMPAIGN_OBJECTIVE.TRAFFIC && !pixel) ||
      !profile ||
      !advertiserAccount ||
      (activeButton === "Existing" && !existingAccount) ||
      !ageGroup?.length ||
      heading?.length < 2 ||
      companyName?.length < 2;
    return (
      <Grow in={true}>
        <Box>
          <Divider sx={{ my: 5 }} />
          {flag && (
            <FormHelperText sx={{ mb: 2 }} error id="password-error">
              Please fill all the required fields in tabs.
            </FormHelperText>
          )}
          <Box
            className="footer-button"
            sx={{ justifyContent: { xs: "center", sm: "space-between" } }}
          >
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
              gap={2}
            >
              <Button
                size="large"
                type="submit"
                variant="outlined"
                color="secondary"
                onClick={onBackwordButtonClick}
                startIcon={<ArrowBackIcon />}
              >
                campaign
              </Button>
              <Button
                size="large"
                type="submit"
                variant="contained"
                onClick={() => handleNextButtonForAll()}
                disabled={required}
                endIcon={<ArrowForwardIcon />}
              >
                Creatives
              </Button>
            </Box>
            <Button
              size="large"
              variant="outlined"
              onClick={onClearDraftClick}
              sx={{ mt: { xs: 3, sm: 0 } }}
              disabled={isClearDraftEnabled}
              startIcon={<DeleteIcon />}
            >
              {autoSchedulerTitle.CLEAR_DRAFT}
            </Button>
          </Box>
        </Box>
      </Grow>
    );
  };

  const handleRefreshButton = async () => {
    setIsBackdrop(true);
    setRefreshLoading("Refreshing");
    if (snapchat?.profile && snapchat?.advertiserAccount) {
      const data = {
        ad_platform: PLATFORMS.SNAP,
        profile_id: snapchat?.profile,
        advertiser_id: snapchat?.advertiserAccount,
      };

      try {
        const res = await dispatch(fetchLatestAdaccountData({ data }));

        if (res?.payload?.status === 200) {
          setRefreshLoading("Done");
          handleProfileChange(snapchat.profile);
          handleAdaccountChange(snapchat.advertiserAccount);
        } else {
          setRefreshLoading("");
        }
      } catch (error) {
        console.error(error);
      }

      setIsBackdrop(false);
    }
  };

  // const handleOptimizeEventChange = (e: any) => {
  //   const pixelName = Object.keys(snapchatOptimizeEvent)?.find((obj: any) => {
  //     return obj === e;
  //   });

  //   setSnapchat({
  //     ...snapchat,
  //     optimizeEvent: e,
  //     optimizeEventName: pixelName,
  //   });
  // };

  const handleOptimizeEventChange = (e: any) => {
    const pixelName = Object.keys(snapchatOptimizeEvent)?.find(
      (obj: any) => snapchatOptimizeEvent[obj] === e
    );

    setSnapchat({
      ...snapchat,
      optimizeEvent: e,
      optimizeEventName: handledOptimizeEvent(pixelName),
    });
  };

  return (
    <Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme: any) => theme.zIndex.drawer + 1 }}
        open={isBackdrop}
      ></Backdrop>
      <Grow in={true}>
        <Card sx={{ padding: 8 }}>
          <Box className="align-justify-item-center">
            <Chip
              label={autoSchedulerTitle.ACCOUNT_DETAILS}
              sx={{ flexGrow: 0 }}
            />
            <Divider sx={{ flexGrow: 1 }} />
          </Box>
          <Box
            sx={{
              mx: { md: 8, sm: 4, xs: 2 },
              maxWidth: "467px",
              width: { md: "50%", sm: "50%", xs: "100%" },
            }}
          >
            <>
              <Box sx={{ width: { md: "221px", sm: "221px", xs: "100%" } }}>
                <FormControl fullWidth sx={{ mt: 8 }}>
                  <InputLabel>{autoSchedulerTitle.PROFILE + " *"}</InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    placeholder={autoSchedulerTitle.LABELS}
                    label={autoSchedulerTitle.PROFILE + " *"}
                    value={snapchat?.profile}
                    onChange={(e: any) => handleProfileChange(e.target.value)}
                  >
                    {snapchatProfiles.length == 0 && loading.profile && (
                      <DropdownNotFound name={"profile"} />
                    )}
                    {snapchatProfiles.length == 0 && !loading.profile && (
                      <Box className="sync-align-justify-center full-height-width">
                        <CircularProgress />
                      </Box>
                    )}
                    {snapchatProfiles?.map((item: any, index: number) => (
                      <MenuItem value={item?.id} key={index}>
                        <Box className="sync-align-center">
                          <Avatar
                            alt={ProfileIcon}
                            sx={{ width: 22, height: 22, mr: 2 }}
                            src={item?.avatar_url || ProfileIcon}
                          />
                          <Typography sx={{ fontSize: "14px" }}>
                            {profileName(item)}
                          </Typography>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              {snapchat?.profile && (
                <Grow in={true}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      flexWrap: "wrap",
                      mt: 8,
                    }}
                    rowGap={2}
                  >
                    <Box
                      sx={{
                        width: { md: "221px", sm: "221px", xs: "100%" },
                      }}
                    >
                      <FormControl fullWidth>
                        <InputLabel>
                          {autoSchedulerTitle.AD_ACCOUNT + " *"}
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          label={autoSchedulerTitle.AD_ACCOUNT + " *"}
                          placeholder={autoSchedulerTitle.LABELS}
                          value={snapchat?.advertiserAccount}
                          onChange={(e: any) =>
                            handleAdaccountChange(e.target.value)
                          }
                        >
                          {snapchatAdAccount?.length == 0 &&
                            adAccountLoading && (
                              <DropdownNotFound name={"account"} />
                            )}
                          {snapchatAdAccount?.length == 0 &&
                            !adAccountLoading && (
                              <Box className="sync-align-justify-center full-height-width">
                                <CircularProgress />
                              </Box>
                            )}
                          {snapchatAdAccount?.map((item: any, index: any) => (
                            <MenuItem value={item?.account_id} key={index}>
                              {item?.account_name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box sx={{ ml: 2 }}>
                      {refreshLoading == BATCH_STATUS.DONE ? (
                        <Typography
                          sx={{
                            fontWeight: "500",
                            color: "rgba(103, 203, 36, 1)",
                            display: "flex",
                            alignItems: "center",
                            ml: 2,
                          }}
                        >
                          <DoneRoundedIcon sx={{ mr: 2 }} color="success" />
                          {autoSchedulerTitle.DATA_UPTO_DATE}
                        </Typography>
                      ) : refreshLoading == autoSchedulerTitle.REFRESHING &&
                        snapchat?.advertiserAccount ? (
                        <Typography
                          sx={{
                            fontWeight: "500",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <CircularProgress
                            size={20}
                            sx={{ mr: 2, height: 2, width: 2 }}
                            color="secondary"
                          />
                          {autoSchedulerTitle.REFRESHING_DATA}
                        </Typography>
                      ) : refreshLoading == "" &&
                        snapchat?.advertiserAccount ? (
                        <Button variant="text" onClick={handleRefreshButton}>
                          <RefreshTwoToneIcon sx={{ mr: 2 }} />
                          {autoSchedulerTitle.REFRESH_DATA}
                        </Button>
                      ) : (
                        <></>
                      )}
                    </Box>
                  </Box>
                </Grow>
              )}
            </>
            {snapchat.advertiserAccount && (
              <Grow in={true}>
                <Box>
                  <Box
                    sx={{
                      padding: 2,
                      border: 1,
                      borderRadius: 1,
                      color: "rgba(76, 78, 100, 0.22)",
                      mt: 8,
                      display: "flex",
                      width: "max-content",
                    }}
                    columnGap={2}
                  >
                    <Button
                      variant={
                        snapchat?.activeButton == "Existing"
                          ? "contained"
                          : "text"
                      }
                      onClick={() =>
                        setSnapchat({ ...snapchat, activeButton: "Existing" })
                      }
                      sx={{
                        paddingLeft: 3,
                        paddingRight: 3,
                      }}
                    >
                      {autoSchedulerTitle.USE_EXISTING_CAMPAIGN}
                    </Button>
                    <Button
                      variant={
                        snapchat?.activeButton == "New" ? "contained" : "text"
                      }
                      onClick={() =>
                        setSnapchat({ ...snapchat, activeButton: "New" })
                      }
                    >
                      {autoSchedulerTitle.CREATE_NEW_CAMPAIGN}
                    </Button>
                  </Box>

                  {/* ------------------------------Existing Campaign---------------------------------------*/}
                  {snapchat?.activeButton == "Existing" && (
                    <Grow in={true}>
                      <Box sx={{ mt: 8 }}>
                        <FormControl fullWidth>
                          <Autocomplete
                            id="tags-outlined"
                            options={snapchatCampaign}
                            loading={campaignLoading}
                            noOptionsText={autoSchedulerTitle.NO_CAMPAIGN_FOUND}
                            onFocus={() => handledExistingCampaingChange("")}
                            onChange={(e: any, value: any) =>
                              setSnapchat({
                                ...snapchat,
                                existingAccount: value?.campaign_id,
                              })
                            }
                            value={
                              snapchat?.existingAccount != ""
                                ? snapchatCampaign?.find(
                                    (element: any) =>
                                      element?.campaign_id ==
                                      snapchat?.existingAccount
                                  )
                                : null
                            }
                            renderInput={(params: any) => (
                              <TextField
                                onChange={(e: any) =>
                                  handledExistingCampaingChange(e.target.value)
                                }
                                {...params}
                                placeholder="Search campaign..."
                                label={autoSchedulerTitle.CAMPAIGN + " *"}
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <Fragment>
                                      {campaignLoading ? (
                                        <CircularProgress size={20} />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </Fragment>
                                  ),
                                }}
                              />
                            )}
                            renderOption={(props, option: any) => (
                              <li {...props}>
                                <Box
                                  className="sync-align-center"
                                  sx={{ px: "16px", py: "6px" }}
                                >
                                  <Box
                                    sx={{
                                      background:
                                        option?.active == "Yes"
                                          ? "green"
                                          : "red",
                                      height: "9px",
                                      width: "9px",
                                      borderRadius: "5px",
                                      mr: 3,
                                    }}
                                  ></Box>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      flexDirection: "column",
                                    }}
                                  >
                                    <Typography
                                      noWrap
                                      variant="subtitle2"
                                      sx={{ color: "text.primary" }}
                                    >
                                      {option?.campaign_name}
                                    </Typography>
                                  </Box>
                                </Box>
                              </li>
                            )}
                            getOptionLabel={(option: any) =>
                              option?.campaign_name
                            }
                            disableCloseOnSelect
                            filterSelectedOptions
                          />
                        </FormControl>
                      </Box>
                    </Grow>
                  )}

                  {snapchat?.activeButton == "New" && (
                    <Grow in={true}>
                      <Box sx={{ mt: 8 }}>
                        {snapchat?.autoGenerateName ? (
                          <Box>
                            <TextField
                              fullWidth
                              disabled
                              variant="outlined"
                              label={autoSchedulerTitle.CAMPAIGN_NAME}
                              placeholder={autoSchedulerTitle.CAMPAIGN_NAME}
                              value={snapchat?.disableCampaignName}
                            />
                          </Box>
                        ) : (
                          <Box>
                            <TextField
                              fullWidth
                              variant="outlined"
                              label={autoSchedulerTitle.CAMPAIGN_NAME}
                              placeholder={autoSchedulerTitle.CAMPAIGN_NAME}
                              value={snapchat?.newAccount}
                              onChange={(e: any) =>
                                setSnapchat({
                                  ...snapchat,
                                  newAccount: e.target.value,
                                })
                              }
                            />
                          </Box>
                        )}
                        <Box className="sync-align-center" sx={{ mt: 4 }}>
                          <Switch
                            checked={snapchat?.autoGenerateName}
                            onChange={(e) =>
                              setSnapchat({
                                ...snapchat,
                                autoGenerateName: e.target.checked,
                              })
                            }
                          />
                          <Typography
                            variant="subtitle1"
                            sx={{ color: "rgba(76, 78, 100, 0.87)" }}
                          >
                            {autoSchedulerTitle.AUTO_GENERATE}
                          </Typography>
                        </Box>
                      </Box>
                    </Grow>
                  )}
                </Box>
              </Grow>
            )}
          </Box>
          {snapchat?.existingAccount != "" ||
          snapchat?.activeButton == "New" ? (
            <></>
          ) : (
            handledButton()
          )}
        </Card>
      </Grow>
      {snapchat.advertiserAccount && (
        <Grow in={true}>
          <Box>
            {(snapchat?.existingAccount || snapchat?.activeButton == "New") && (
              <Grow in={true}>
                <Box>
                  <Card sx={{ padding: 8, mt: 10 }}>
                    <Box className="align-justify-item-center">
                      <Chip
                        label={autoSchedulerTitle.TARGETING}
                        sx={{ flexGrow: 0 }}
                      />
                      <Divider sx={{ flexGrow: 1 }} />
                    </Box>
                    <Box sx={{ mx: { md: 8, sm: 4, xs: 2 } }}>
                      <Box
                        sx={{
                          mt: 8,
                          maxWidth: "467px",
                          width: { md: "50%", sm: "50%", xs: "100%" },
                        }}
                      >
                        <FormControl fullWidth>
                          <Autocomplete
                            multiple
                            id="tags-outlined"
                            options={snapchatInterests}
                            getOptionLabel={(option: any) => option.name}
                            loading={interestLoading}
                            noOptionsText={autoSchedulerTitle.NO_INTEREST_FOUND}
                            value={snapchat.interest}
                            onChange={(e: any, values) =>
                              setSnapchat({ ...snapchat, interest: values })
                            }
                            onFocus={() => handledInterstChange("")}
                            disableCloseOnSelect
                            filterSelectedOptions
                            renderInput={(params: any) => (
                              <TextField
                                onChange={(e: any) =>
                                  handledInterstChange(e.target.value)
                                }
                                {...params}
                                placeholder={autoSchedulerTitle.PICK_INTEREST}
                                label={autoSchedulerTitle.INTEREST}
                                variant="outlined"
                                InputProps={{
                                  ...params.InputProps,
                                  endAdornment: (
                                    <Fragment>
                                      {interestLoading ? (
                                        <CircularProgress size={20} />
                                      ) : null}
                                      {params.InputProps.endAdornment}
                                    </Fragment>
                                  ),
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      </Box>

                      <Box sx={{ mt: 8 }}>
                        <Typography
                          variant="subtitle1"
                          sx={{ fontWeight: "400" }}
                        >
                          {autoSchedulerTitle.AGE_GROUP}
                        </Typography>
                        <Stack
                          direction="row"
                          spacing={1}
                          sx={{ mt: 4, flexWrap: "wrap" }}
                          rowGap={2}
                        >
                          <Chip
                            sx={{
                              width: "80px",
                              color: allAgeGroup ? "white" : "primary.main",
                              borderColor: "red",
                              backgroundColor: allAgeGroup
                                ? "primary.main"
                                : "white",
                            }}
                            label="All"
                            variant={allAgeGroup ? "filled" : "outlined"}
                            onClick={handledAllAgeGroup}
                          />
                          {snapChatAge &&
                            snapChatAge?.map((item: any, index: any) => {
                              return (
                                <Chip
                                  key={index}
                                  sx={{
                                    width: "80px",
                                    color: snapchat?.ageGroup?.includes(
                                      item?.name
                                    )
                                      ? "white"
                                      : "primary.main",
                                    borderColor: "red",
                                    backgroundColor:
                                      snapchat?.ageGroup?.includes(item?.name)
                                        ? "primary.main"
                                        : "white",
                                  }}
                                  label={item?.name}
                                  variant={
                                    snapchat?.ageGroup?.includes(item?.name)
                                      ? "filled"
                                      : "outlined"
                                  }
                                  onClick={(e: any) =>
                                    handledAgeGroupChange(item?.name, index)
                                  }
                                />
                              );
                            })}
                        </Stack>
                      </Box>

                      <Box className="sync-align-center" sx={{ mt: 4 }}>
                        <Switch
                          checked={snapchat?.customAudiences}
                          onChange={(e) =>
                            setSnapchat({
                              ...snapchat,
                              customAudiences: e.target.checked,
                            })
                          }
                        />
                        <Typography
                          variant="subtitle1"
                          sx={{ color: "rgba(76, 78, 100, 0.87)" }}
                        >
                          {autoSchedulerTitle.CUSTOM_AUDIENCE}
                        </Typography>
                      </Box>

                      {snapchat?.customAudiences && (
                        <Grow in={true}>
                          <Box
                            sx={{
                              maxWidth: "467px",
                              width: { md: "50%", sm: "50%", xs: "100%" },
                            }}
                          >
                            <FormControl fullWidth sx={{ mt: 4 }}>
                              <Autocomplete
                                multiple
                                id="tags-outlined"
                                options={snapchatCustomAudience}
                                getOptionLabel={(option: any) => option.name}
                                noOptionsText={
                                  autoSchedulerTitle.NO_AUDIENCE_FOUND
                                }
                                disableCloseOnSelect
                                filterSelectedOptions
                                value={snapchat?.selectedAudience}
                                onChange={(value: any, select: any) =>
                                  setSnapchat({
                                    ...snapchat,
                                    selectedAudience: select,
                                  })
                                }
                                renderInput={(params: any) => (
                                  <TextField
                                    {...params}
                                    label="Audience"
                                    variant="outlined"
                                    placeholder={
                                      autoSchedulerTitle.PICK_AUDIENCE
                                    }
                                  />
                                )}
                              />
                            </FormControl>
                          </Box>
                        </Grow>
                      )}
                    </Box>
                  </Card>

                  <Card sx={{ padding: 8, mt: 10 }}>
                    <Box className="align-justify-item-center">
                      <Chip
                        label={autoSchedulerTitle.AD_SETTINGS}
                        sx={{ flexGrow: 0 }}
                      />
                      <Divider sx={{ flexGrow: 1 }} />
                    </Box>
                    <Box
                      sx={{
                        mx: { md: 8, sm: 4, xs: 2 },
                      }}
                    >
                      <Box
                        sx={{
                          maxWidth: "467px",
                          width: { md: "50%", sm: "50%", xs: "100%" },
                        }}
                      >
                        <FormControl fullWidth sx={{ mt: 8 }}>
                          <TextField
                            value={snapchat?.companyName}
                            variant="outlined"
                            placeholder={autoSchedulerTitle.COMPANY_NAME}
                            label={autoSchedulerTitle.COMPANY_NAME + " *"}
                            inputProps={{ maxLength: 25 }}
                            onChange={(e: any) =>
                              setSnapchat({
                                ...snapchat,
                                companyName: e.target.value,
                              })
                            }
                            helperText={`Remaining characters: ${
                              25 - snapchat?.companyName?.length
                            }`}
                          />
                        </FormControl>
                      </Box>

                      {/*-------------------------------- Pixels ------------------------------------------*/}

                      <Box
                        sx={{ display: "flex", flexWrap: "wrap" }}
                        columnGap={4}
                      >
                        <Box
                          sx={{
                            mt: 8,
                            width: { md: "221px", sm: "221px", xs: "100%" },
                          }}
                        >
                          <FormControl fullWidth>
                            <InputLabel>
                              {autoSchedulerTitle.PIXELS + " "}
                              {campaign?.objective == CAMPAIGN_OBJECTIVE.TRAFFIC
                                ? ""
                                : "*"}
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              placeholder={autoSchedulerTitle.LABELS}
                              value={snapchat?.pixel}
                              label={autoSchedulerTitle.PIXELS + " *"}
                              onChange={(e: any) =>
                                handlePixelChange(e.target.value)
                              }
                            >
                              {snapchatPixels.length == 0 && pixelsLoading && (
                                <DropdownNotFound name={"pixels"} />
                              )}
                              {snapchatPixels.length == 0 && !pixelsLoading && (
                                <Box className="sync-align-justify-center full-height-width">
                                  <CircularProgress />
                                </Box>
                              )}
                              {snapchatPixels?.map((item: any, index: any) => (
                                <MenuItem value={item?.pixel_id} key={index}>
                                  {item?.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>

                        {snapchat?.pixel && (
                          <Grow in={true}>
                            <Box
                              sx={{
                                mt: 8,
                                width: { md: "221px", sm: "221px", xs: "100%" },
                              }}
                            >
                              <FormControl fullWidth>
                                <InputLabel>
                                  {campaign?.objective ===
                                  CAMPAIGN_OBJECTIVE.CONVERSIONS
                                    ? autoSchedulerTitle.OPTIMIZE_EVENT + " *"
                                    : autoSchedulerTitle.OPTIMIZE_EVENT}
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  placeholder={autoSchedulerTitle.LABELS}
                                  label={
                                    campaign?.objective ===
                                    CAMPAIGN_OBJECTIVE.CONVERSIONS
                                      ? autoSchedulerTitle.OPTIMIZE_EVENT + " *"
                                      : autoSchedulerTitle.OPTIMIZE_EVENT
                                  }
                                  value={snapchat?.optimizeEvent}
                                  onChange={
                                    (e: any) =>
                                      handleOptimizeEventChange(e.target.value)
                                    // setSnapchat({
                                    //   ...snapchat,
                                    //   optimizeEvent: e.target.value,
                                    // })
                                  }
                                >
                                  {Object.keys(snapchatOptimizeEvent).length ==
                                    0 &&
                                    loading.optimizeEvent && (
                                      <DropdownNotFound name={"event"} />
                                    )}
                                  {Object.keys(snapchatOptimizeEvent).length ==
                                    0 &&
                                    !loading.optimizeEvent && (
                                      <Box className="sync-align-justify-center full-height-width">
                                        <CircularProgress />
                                      </Box>
                                    )}
                                  {Object.keys(snapchatOptimizeEvent)?.map(
                                    (item: any, index: number) => (
                                      <MenuItem
                                        value={snapchatOptimizeEvent[item]}
                                        key={index}
                                      >
                                        {handledOptimizeEvent(item)}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            </Box>
                          </Grow>
                        )}
                      </Box>

                      {/*--------------------------- Maximum number of creatives in one adgroup -----------------*/}

                      <Box
                        sx={{
                          width: { md: "221px", sm: "221px", xs: "100%" },
                        }}
                      >
                        <FormControl fullWidth sx={{ mt: 8 }}>
                          <InputLabel>
                            {autoSchedulerTitle.MAX_CREATIVE}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            placeholder={autoSchedulerTitle.LABELS}
                            label={autoSchedulerTitle.MAX_CREATIVE}
                            value={snapchat?.maximumCreative}
                            onChange={(e: any) =>
                              setSnapchat({
                                ...snapchat,
                                maximumCreative: e.target.value,
                              })
                            }
                          >
                            {[...Array(20)].map((_, index) => (
                              <MenuItem key={index} value={index + 1}>
                                {index + 1}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Box>
                      <Box
                        sx={{
                          width: { md: "221px", sm: "221px", xs: "100%" },
                        }}
                      >
                        <FormControl fullWidth sx={{ mt: 8 }}>
                          <TextField
                            label={autoSchedulerTitle.HEADING + " *"}
                            variant="outlined"
                            placeholder={autoSchedulerTitle.HEADING}
                            value={snapchat?.heading}
                            onChange={(e: any) =>
                              setSnapchat({
                                ...snapchat,
                                heading: e.target.value,
                              })
                            }
                            inputProps={{
                              maxlength: 25,
                            }}
                            helperText={`Remaining characters: ${
                              25 - snapchat?.heading?.length
                            }`}
                          />
                        </FormControl>
                      </Box>

                      {campaign?.bidStategy == BID_STRATEGY.BID_CAP &&
                        campaign?.budget != "" &&
                        isSpendingValidate.includes(
                          snapchat?.optimizeEvent
                        ) && (
                          <Grow in={true}>
                            <Box className="sync-align-center" sx={{ mt: 4 }}>
                              <Switch
                                checked={Boolean(snapchat?.accelerateSpending)}
                                onChange={(e: any, checked: boolean) =>
                                  setSnapchat({
                                    ...snapchat,
                                    accelerateSpending: checked,
                                  })
                                }
                              />
                              <Typography
                                variant="subtitle1"
                                sx={{ color: "rgba(76, 78, 100, 0.87)" }}
                              >
                                {autoSchedulerTitle.ACCELERATE_SPEND}
                              </Typography>
                            </Box>
                          </Grow>
                        )}

                      {campaign?.objective === CAMPAIGN_OBJECTIVE.CONVERSIONS &&
                        snapchatPixels?.length == 0 && (
                          <FormHelperText sx={{ color: "primary.main" }}>
                            {autoSchedulerTitle.CREATE_FIRST}
                          </FormHelperText>
                        )}
                    </Box>
                    {handledButton()}
                  </Card>
                </Box>
              </Grow>
            )}
          </Box>
        </Grow>
      )}
    </Box>
  );
}
