import { createAsyncThunk } from "@reduxjs/toolkit";
import { setBrandList, setBrandProfile, setJobTitleList } from "../Slice/CreateBrandSlice";
import { setLoading } from "../Slice/CreateJobSlice";
import { notificationSuccess, notificationFail } from "../Slice/notificationSlice";
import { getSubmissions } from "store/Thunk/createJobThunk";
import API from "../api";

export const saveBrandProfile = createAsyncThunk(
    "saveBrandProfile",
    async ({formData}: any, {dispatch}) => {
        try {
            const response = await API.post(
                `api/v1/marketplace/brand-profile/`,
                formData
            );
            dispatch(notificationSuccess(response?.data.message));
            let header: any = localStorage.getItem("header")
            header = {...JSON.parse(header), brand: response.data[0]}
            localStorage.setItem("header", JSON.stringify(header));

            // dispatch(setBrandProfile(response.data[0]))
            return response;
        } catch (err: any) {
            dispatch(notificationFail(err?.message));
            return err;
        }
    },
);

export const getBrandProfile = createAsyncThunk(
    "getBrandProfile",
    async ({navigate}: any, {dispatch}) => {
        try {
            dispatch(setLoading(true));
            const response = await API.get(
                `api/v1/marketplace/brand-profile/`
                )
                dispatch(setLoading(false));
                dispatch(setBrandProfile(response.data[0]));
                return response;
            } catch (err: any) {
                dispatch(setLoading(false));
                dispatch(notificationFail(err.message));
            return err;
        }
    }
);

export const updateBrandProfile = createAsyncThunk(
    "updateBrandProfile",
    async ({formData, id}: any, {dispatch}) => {
        try {
            const response = await API.patch(
                `api/v1/marketplace/brand-profile/${id}/`,
                formData
            );
            let header: any = localStorage.getItem("header")
            header = {...JSON.parse(header), brand: response.data.data[0]}
            localStorage.setItem("header", JSON.stringify(header));
            dispatch(notificationSuccess(response?.data.message));
            return response;
        } catch (err: any) {
            dispatch(notificationFail(err?.message));
            return err;
        }
    },
);

export const getBrandList = createAsyncThunk(
    "brandList",
    async ({dispatch}: any) => {
        try {
            const response = await API.get(
                `api/v1/marketplace/brand-profile/`
            );
            dispatch(setBrandList(response.data))
            return response;
        } catch (err: any) {
            dispatch(notificationFail(err?.message));
            return err;
        }
    },
);

export const updatePostStatus = createAsyncThunk(
    "updateBrandProfile",
    async ({formData, selected_status, approval_status_id}: any, {dispatch}) => {
        dispatch(setLoading(true));
        try {
            const response = await API.patch(
                `api/v1/marketplace/submission-approval-status/${approval_status_id}/`,
                formData
            );
            dispatch(getSubmissions({...formData, selected_status: selected_status}));
            dispatch(notificationSuccess(response?.data.message));

            return response;
        } catch (err: any) {
            dispatch(notificationFail(err?.message));
            return err;
        } finally {
            dispatch(setLoading(false));
        }
    },
);

export const getJobTitle = createAsyncThunk(
    "jobTitle",
    async ({dispatch}: any) => {
        try {
            const response = await API.get(
                `/api/v1/marketplace/job-titles/`
            );

            dispatch(setJobTitleList(response.data.data))
            return response;
        } catch (err: any) {
            dispatch(notificationFail(err?.message));
            return err;
        }
    },
);
