import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
// ** MUI Imports
import {
  Grid,
  Card,
  Table,
  Button,
  Divider,
  TableRow,
  TableHead,
  TableBody,
  Typography,
  TableContainer,
} from "@mui/material";
import Box, { BoxProps } from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import { styled, useTheme } from "@mui/material/styles";
import TableCell, { TableCellBaseProps } from "@mui/material/TableCell";
import { Thankyou } from "assets";
import moment from "moment";

// ** Configs
import { useAppSelector } from "store/store";
import { getInvoiceDetails } from "store/Thunk/earningThunk";
import themeConfig from "config/themeConfig";

// ** Types
import { SingleInvoiceType } from "types/apps/invoiceTypes";
import { MARKET_PLACE } from "@core/constants/title-constants";

interface Props {
  // data: SingleInvoiceType;
  state?: string
}

const MUITableCell = styled(TableCell)<TableCellBaseProps>(({ theme }) => ({
  border: 0,
  padding: `${theme.spacing(1, 0)} !important`,
}));

const CalcWrapper = styled(Box)<BoxProps>(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  "&:not(:last-of-type)": {
    marginBottom: theme.spacing(2),
  },
}));

export default function Invoice(Props: any) {
  // ** Hook
  
  const theme = useTheme();
  const dispatch: any = useDispatch();
  const { earningData, invoiceDetails } = useAppSelector((state: any) => state.earningSlice);
  const id: any = useParams();
  const location = useLocation();
  const header: any = localStorage.getItem("header");
  const user_id: any = JSON.parse(header).user_id;
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    let req = {
      id: Number(id?.id),
    };
    dispatch(getInvoiceDetails(req));
  }, []);

  const downloadUrl = `http://192.168.1.49:8932/api/v1/marketplace/generate-invoice/${Number(
    id?.id
  )}/?user_id=${user_id}`;
  return (
    <Card>
      <Box>
        <CardContent>
          <Grid container>
            <Grid item sm={6} xs={12} sx={{ mb: { sm: 0, xs: 4 } }}>
              <Box sx={{ display: "flex", flexDirection: "column" }}>
                <Box sx={{ mb: 6, display: "flex", alignItems: "center" }}>
                  <Typography
                    variant="h6"
                    sx={{ ml: 2, fontWeight: 700, lineHeight: 1.2 }}
                  >
                    {themeConfig.templateName}
                  </Typography>
                </Box>
                <Box>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    Office 149, 450 South Brand Brooklyn
                  </Typography>
                  <Typography variant="body2" sx={{ mb: 1 }}>
                    San Diego County, CA 91905, USA
                  </Typography>
                  <Typography variant="body2">
                    +1 (123) 456 7891, +44 (876) 543 2198
                  </Typography>
                </Box>
              </Box>
            </Grid>
            <Grid item sm={6} xs={12}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: { xs: "flex-start", sm: "flex-end" },
                }}
              >
                <Table sx={{ maxWidth: "200px", border: "none" }}>
                  <TableBody>
                    <TableRow>
                      <MUITableCell>
                        <Typography variant="h6">{MARKET_PLACE.CREATE_CONTENT.INVOICE}</Typography>
                      </MUITableCell>
                      <MUITableCell>
                        <Typography variant="h6">
                          #{location?.state + 1}
                        </Typography>
                      </MUITableCell>
                    </TableRow>
                    <TableRow>
                      <MUITableCell>
                        <Typography variant="body2">{MARKET_PLACE.CREATE_CONTENT.DATE_ISSUED}</Typography>
                      </MUITableCell>
                      <MUITableCell>
                        <Typography variant="body2">13 Mar 2023</Typography>
                      </MUITableCell>
                    </TableRow>
                    <TableRow>
                      <MUITableCell>
                        <Typography variant="body2">{MARKET_PLACE.CREATE_CONTENT.DATE_DUE}</Typography>
                      </MUITableCell>
                      <MUITableCell>
                        <Typography variant="body2">23 Mar 2023</Typography>
                      </MUITableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </Box>
            </Grid>
          </Grid>
        </CardContent>

        <Divider sx={{ mt: 6.5, mb: 5.5 }} />

        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={6} sx={{ mb: { lg: 0, xs: 4 } }}>
              <Typography
                variant="subtitle2"
                sx={{ mb: 3, color: "text.primary", letterSpacing: ".1px" }}
              >
                {MARKET_PLACE.CREATE_CONTENT.INVOICE_TO}
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Jordan Stevenson
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                Hall-Robbins PLC
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                7777 Mendez Plains
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                (616) 865-4180
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                don85@johnson.com
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              sx={{
                display: "flex",
                justifyContent: ["flex-start", "flex-end"],
              }}
            >
              <div>
                <Typography
                  variant="subtitle2"
                  sx={{ mb: 3, color: "text.primary", letterSpacing: ".1px" }}
                >
                  {MARKET_PLACE.CREATE_CONTENT.BILL_TO}
                </Typography>
                <TableContainer>
                  <Table>
                    <TableBody>
                      <TableRow>
                        <MUITableCell>
                          <Typography variant="body2">{MARKET_PLACE.CREATE_CONTENT.TOTAL_DUE}</Typography>
                        </MUITableCell>
                        <MUITableCell>
                          <Typography variant="body2">$12,110.55</Typography>
                        </MUITableCell>
                      </TableRow>
                      <TableRow>
                        <MUITableCell>
                          <Typography variant="body2">{MARKET_PLACE.CREATE_CONTENT.BANK_NAME}</Typography>
                        </MUITableCell>
                        <MUITableCell>
                          <Typography variant="body2">American Bank</Typography>
                        </MUITableCell>
                      </TableRow>
                      <TableRow>
                        <MUITableCell>
                          <Typography variant="body2">{MARKET_PLACE.CREATE_CONTENT.COUNTRY}</Typography>
                        </MUITableCell>
                        <MUITableCell>
                          <Typography variant="body2">United States</Typography>
                        </MUITableCell>
                      </TableRow>
                      <TableRow>
                        <MUITableCell>
                          <Typography variant="body2">{MARKET_PLACE.CREATE_CONTENT.IBAN}</Typography>
                        </MUITableCell>
                        <MUITableCell>
                          <Typography variant="body2">
                            ETD95476213874685
                          </Typography>
                        </MUITableCell>
                      </TableRow>
                      <TableRow>
                        <MUITableCell>
                          <Typography variant="body2">{MARKET_PLACE.CREATE_CONTENT.SWIFT_CODE}</Typography>
                        </MUITableCell>
                        <MUITableCell>
                          <Typography variant="body2">BR91905</Typography>
                        </MUITableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </Grid>
          </Grid>
        </CardContent>

        <Divider sx={{ mt: 6.5, mb: 0 }} />

        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>{MARKET_PLACE.CREATE_CONTENT.JOB}</TableCell>
                <TableCell>{MARKET_PLACE.CREATE_CONTENT.BRAND}</TableCell>
                <TableCell>{MARKET_PLACE.CREATE_CONTENT.TYPE}</TableCell>
                <TableCell>{MARKET_PLACE.CREATE_CONTENT.DESCRIPTION}</TableCell>
                <TableCell>{MARKET_PLACE.CREATE_CONTENT.EARNED}</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {invoiceDetails?.earning_payout?.map((raw: any) => (
                <TableRow>
                  <TableCell>{raw?.job?.title}</TableCell>
                  <TableCell>{raw?.job?.brand?.brand_name}</TableCell>
                  <TableCell>{raw?.job?.publish_content_type}</TableCell>
                  <TableCell>
                    View milestone {raw?.milestone?.milestone_number} out of 4
                  </TableCell>
                  <TableCell>${raw?.amount}</TableCell>
                </TableRow>
              ))}
              {/* <TableRow>
                <TableCell>Nike x Fruits</TableCell>
                <TableCell>Nike</TableCell>
                <TableCell>Video and post</TableCell>
                <TableCell>View milestone 1 out of 4</TableCell>
                <TableCell>$100</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Best Burger in Town</TableCell>
                <TableCell>John’s Steakhouse</TableCell>
                <TableCell>Video only</TableCell>
                <TableCell>Video submission</TableCell>
                <TableCell>$150</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Nike x Fruits</TableCell>
                <TableCell>Nike</TableCell>
                <TableCell>Video and post</TableCell>
                <TableCell>View milestone 1 out of 4</TableCell>
                <TableCell>$150</TableCell>
              </TableRow> */}
            </TableBody>
          </Table>
        </TableContainer>

        <CardContent sx={{ pt: 8 }}>
          <Grid container>
            <Grid
              item
              xs={12}
              sm={7}
              lg={9}
              sx={{ order: { sm: 1, xs: 2 } }}
            ></Grid>
            <Grid
              item
              xs={12}
              sm={5}
              lg={3}
              sx={{ mb: { sm: 0, xs: 4 }, order: { sm: 2, xs: 1 } }}
            >
              <CalcWrapper>
                <Typography variant="body2">{MARKET_PLACE.CREATE_CONTENT.SUBTOTAL}</Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.primary",
                    letterSpacing: ".25px",
                    fontWeight: 600,
                  }}
                >
                  $1800
                </Typography>
              </CalcWrapper>
              <CalcWrapper>
                <Typography variant="body2">{MARKET_PLACE.CREATE_CONTENT.DISCOUNT}</Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.primary",
                    letterSpacing: ".25px",
                    fontWeight: 600,
                  }}
                >
                  $28
                </Typography>
              </CalcWrapper>
              <CalcWrapper>
                <Typography variant="body2">{MARKET_PLACE.CREATE_CONTENT.TAX}</Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.primary",
                    letterSpacing: ".25px",
                    fontWeight: 600,
                  }}
                >
                  21%
                </Typography>
              </CalcWrapper>
              <Divider sx={{ mt: 5, mb: 3 }} />
              <CalcWrapper>
                <Typography variant="body2">{MARKET_PLACE.CREATE_CONTENT.TOTAL}</Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: "text.primary",
                    letterSpacing: ".25px",
                    fontWeight: 600,
                  }}
                >
                  ${invoiceDetails?.amount}
                </Typography>
              </CalcWrapper>
            </Grid>
          </Grid>
        </CardContent>

        <Divider sx={{ mt: 4.5, mb: 0 }} />

        <CardContent
          sx={{
            flexDirection: "row",
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <Box
            component="img"
            src={Thankyou}
            height="20px"
            width="20px"
            mr="10px"
          />
          <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
            {MARKET_PLACE.CREATE_CONTENT.THE_EVERYWHEREAI_TEAM_WOULD}
          </Typography>
        </CardContent>
      </Box>
      <CardContent>
        <Box
          sx={{
            mt: 0,
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          {/* <Link href={`/apps/invoice/print/10`}>
            <Button
              sx={{ mr: 4 }}
              target="_blank"
              component="a"
              variant="outlined"
            >
              Print
            </Button>
          </Link> */}

          <Button
            component="a"
            target="_blank"
            href={"https://www.africau.edu/images/default/sample.pdf"}
            variant="outlined"
            color="primary"
            sx={{
              mr: 4,
              "&:focus": { textDecoration: "none" },
              "&:hover": { textDecoration: "none" },
              "&:visited": { textDecoration: "none" },
              "&:link": { textDecoration: "none" },
              "&:active": {
                textDecoration: "none",
              },
            }}
          >
            {MARKET_PLACE.CREATE_CONTENT.PRINT}
          </Button>

          <Button
            component="a"
            target="_blank"
            href={downloadUrl}
            variant="contained"
            color="primary"
            sx={{
              "&:focus": { textDecoration: "none", color: "white" },
              "&:hover": { textDecoration: "none", color: "white" },
              "&:visited": { textDecoration: "none", color: "white" },
              "&:link": { textDecoration: "none", color: "white" },
              "&:active": {
                textDecoration: "none",
                color: "white",
              },
            }}
          >
            {MARKET_PLACE.CREATE_CONTENT.DOWNLOAD}
          </Button>
        </Box>
      </CardContent>
    </Card>
  );
}
