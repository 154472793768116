import { useEffect, memo } from "react";
import { Box } from "@mui/material";
import { useAppSelector } from "store/store";

function VideoPlayer(props: any) {
  let windowObj: any = window;
  const { videoData } = useAppSelector((state: any) => state.createJobReducer);
  console.log("videoData", videoData)
  useEffect(() => {
    if (typeof windowObj !== "undefined") {
      let videoObj = {
        otp: "20160313versUSE323yOQmkRXTTZQlZqgf2bcGeea26tqMoHlikgb459GwewbKGj",
        playbackInfo:
          "eyJ2aWRlb0lkIjoiMmRjN2E5MTkyYjkzNDQ5OTg4NWNiYmEzNjhkYjJmZjYifQ==",
      };
      let vdo_container = document.querySelector(`#child`);
      if (vdo_container) {
        var video = new windowObj.VdoPlayer({
          otp: videoData?.data?.otp || null,
          playbackInfo: videoData?.data.playbackInfo || null,
          theme: "9ae8bbe8dd964ddc9bdb932cca1cb59a",
          container: vdo_container,
          allowFullScreen: true,
          annotations: [
            {
              name: "keyboard",
              options: {
                bindings: {
                  Space: (player: any) =>
                    player.status === 1 ? player.pause() : player.play(),
                  // 'Up' : (player : any) => player.setVolume(player.volume + 0.2),
                  // 'Down' : (player : any) => player.setVolume(player.volume - 0.2),
                  // 'Left': (player : any) => player.seek(player.currentTime - 20),
                  // 'Right': (player : any) => player.seek(player.currentTime + 20),
                },
              },
            },
          ],
        });

        video.addEventListener(`load`, () => {
          console.log("loaded");
        });

        video.addEventListener("seeking", () => {
          //   console.log("seeking");
          //   let currTime: number = Math.round(video.currentTime);
          //   let videoDuration: number = video._metaData.duration;
          //   let watchTime = videoDuration - 120;
          //   if (currTime >= watchTime) {
          //     watchedFlag = true;
          //     setWatchLesson(+lessonObj.lesson_id);
          //   }
        });

        video.addEventListener("progress", () => {
          //   console.log("progress");
          //   let currTime: number = Math.round(video.currentTime);
          //   let videoDuration: number = video._metaData.duration;
          //   let watchTime = videoDuration - 120;
          //   if (
          //     currTime >= watchTime &&
          //     currTime < videoDuration &&
          //     !watchedFlag
          //   ) {
          //     watchedFlag = true;
          //     setWatchLesson(+lessonObj.lesson_id);
          //   }
        });

        video.addEventListener("play", function () {
          fullScreen(`child`);
        });
        video.addEventListener("pause", function () {
          cancelFullScreen();
          console.log("pause");
          //   let currTime: number = Math.round(video.currentTime);
          //   let videoDuration: number = video._metaData.duration;
          //   let watchTime = videoDuration - 120;
          //   if (currTime >= watchTime) {
          //     watchedFlag = true;
          //     setWatchLesson(+lessonObj.lesson_id);
          //   }
        });
        video.addEventListener("ended", () => {
          // cancelFullScreen()
          //   let currTime: number = Math.round(video.currentTime);
          //   let videoDuration: number = video._metaData.duration;
          //   let watchTime = videoDuration - 120;
          //   if (currTime >= watchTime) {
          //     console.log("Video Watched");
          //     watchedFlag = true;
          //     setWatchLesson(+lessonObj.lesson_id);
          // }
        });
      }
    }
  }, [windowObj]);

  const fullScreen = (htmlContent: any) => {
    let e: any = document.getElementById(htmlContent);

    if (document.fullscreenElement == null) {
      if (e.requestFullscreen) {
        e.requestFullscreen();
      } else if (e.webkitRequestFullscreen) {
        e.webkitRequestFullscreen();
      } else if (e.mozRequestFullScreen) {
        e.mozRequestFullScreen();
      } else if (e.msRequestFullscreen) {
        e.msRequestFullscreen();
      }
    }
  };
  const cancelFullScreen = () => {
    try {
      if (document && document.fullscreenElement) {
        document.exitFullscreen();
      }
    } catch (error) {
      console.log("error ", error);
    }
  };

  return (
    <Box className="flex-auto">
      <div id="all-player-top">
        <div id="all-player">
          <div
            style={{
              position: "relative",
              zIndex: 1,
              height: "100%",
              width: "100%",
            }}
            id={`child`}
          />
        </div>
      </div>
    </Box>
  );
}

export default memo(VideoPlayer);
