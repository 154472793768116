export default function TiktokTabIcon({ active = false }) {

    
    if (active) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" style={{marginRight: '5px'}} width="15" height="16" viewBox="0 0 15 16" fill="none" >
                <rect y="0.5" width="15" height="15" rx="7.5" fill="#FF4D49" />
                <g clipPath="url(#clip0_525_15529)">
                    <path d="M9.54904 6.70087C10.1935 7.17383 10.9835 7.45205 11.8365 7.45205V5.76706C11.675 5.76706 11.5142 5.75013 11.3564 5.71565V7.04138C10.5034 7.04138 9.71398 6.76317 9.06893 6.29021V9.72728C9.06893 11.4468 7.71046 12.8408 6.03512 12.8408C5.41009 12.8408 4.82866 12.6467 4.34619 12.314C4.89699 12.8922 5.66576 13.2509 6.51581 13.2509C8.19176 13.2509 9.55022 11.8568 9.55022 10.1374L9.54904 6.70087ZM10.1416 5.00138C9.81237 4.63185 9.59555 4.15467 9.54904 3.62667V3.41016H9.09368C9.20794 4.08088 9.59911 4.65423 10.1416 5.00138ZM5.40537 10.9955C5.22158 10.7476 5.12202 10.4446 5.12202 10.1331C5.12202 9.34688 5.74351 8.70882 6.50992 8.70882C6.65248 8.70882 6.79504 8.7312 6.93112 8.77535V7.05347C6.77207 7.03109 6.61124 7.02142 6.45101 7.02505V8.3653C6.31493 8.32112 6.17296 8.29877 6.02981 8.29877C5.2634 8.29877 4.64192 8.93621 4.64192 9.72308C4.64192 10.2795 4.95236 10.7609 5.40537 10.9955Z" fill="#811412" />
                    <path d="M9.06831 6.29021C9.71337 6.76317 10.5027 7.04138 11.3558 7.04138V5.71565C10.8798 5.61163 10.458 5.3564 10.1411 5.00139C9.59849 4.65423 9.20793 4.08088 9.09306 3.41016H7.89721V10.138C7.89426 10.9224 7.27396 11.5574 6.50933 11.5574C6.05868 11.5574 5.65809 11.3367 5.40478 10.9955C4.95177 10.7609 4.64132 10.2795 4.64132 9.72366C4.64132 8.93684 5.26281 8.29936 6.02922 8.29936C6.1759 8.29936 6.31788 8.32296 6.45042 8.36589V7.02505C4.8045 7.06012 3.48022 8.44027 3.48022 10.138C3.48022 10.9853 3.81012 11.7534 4.34501 12.3146C4.82748 12.6473 5.40891 12.8414 6.03393 12.8414C7.70931 12.8414 9.06774 11.4473 9.06774 9.72791L9.06831 6.29021Z" fill="white" />
                    <path d="M11.3557 5.71555V5.35691C10.9262 5.35751 10.5056 5.23413 10.141 5.00068C10.4638 5.36356 10.8885 5.61334 11.3557 5.71555ZM9.09357 3.41005C9.08236 3.34595 9.0741 3.28123 9.06821 3.21652V3H7.417V9.72781C7.41466 10.5122 6.79433 11.1472 6.02911 11.1472C5.80466 11.1472 5.59259 11.0928 5.40467 10.9955C5.65798 11.3372 6.05856 11.5573 6.50921 11.5573C7.27385 11.5573 7.89477 10.9223 7.8971 10.1379V3.41005H9.09357ZM6.45089 7.02494V6.64332C6.31305 6.62396 6.17402 6.61428 6.03441 6.61428C4.35844 6.61428 3 8.00833 3 9.72781C3 10.8055 3.53372 11.7557 4.34548 12.3145C3.81059 11.7533 3.4807 10.9852 3.4807 10.1379C3.4807 8.44018 4.80439 7.06002 6.45089 7.02494Z" fill="#FFB9B8" />
                </g>
                <defs>
                    <clipPath id="clip0_525_15529">
                        <rect x="3" y="3" width="9" height="10.5" rx="3" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        );
    } else {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="15" style={{marginRight: '5px'}} height="16" viewBox="0 0 15 16" fill="none">
                <rect y="0.5" width="15" height="15" rx="7.5" fill="#868796" />
                <g clipPath="url(#clip0_525_15792)">
                    <path d="M9.54898 6.70087C10.1934 7.17383 10.9834 7.45205 11.8364 7.45205V5.76706C11.675 5.76706 11.5142 5.75013 11.3563 5.71565V7.04138C10.5033 7.04138 9.71392 6.76317 9.06887 6.29021V9.72728C9.06887 11.4468 7.7104 12.8408 6.03505 12.8408C5.41003 12.8408 4.8286 12.6467 4.34613 12.314C4.89693 12.8922 5.66569 13.2509 6.51575 13.2509C8.1917 13.2509 9.55016 11.8568 9.55016 10.1374L9.54898 6.70087ZM10.1416 5.00138C9.81231 4.63185 9.59549 4.15467 9.54898 3.62667V3.41016H9.09362C9.20788 4.08088 9.59905 4.65423 10.1416 5.00138ZM5.40531 10.9955C5.22152 10.7476 5.12196 10.4446 5.12196 10.1331C5.12196 9.34688 5.74345 8.70882 6.50986 8.70882C6.65242 8.70882 6.79498 8.7312 6.93106 8.77535V7.05347C6.77201 7.03109 6.61118 7.02142 6.45095 7.02505V8.3653C6.31487 8.32112 6.1729 8.29877 6.02975 8.29877C5.26334 8.29877 4.64185 8.93621 4.64185 9.72308C4.64185 10.2795 4.9523 10.7609 5.40531 10.9955Z" fill="#444552" />
                    <path d="M9.06828 6.29021C9.71334 6.76317 10.5027 7.04138 11.3558 7.04138V5.71565C10.8797 5.61163 10.458 5.3564 10.141 5.00139C9.59846 4.65423 9.2079 4.08088 9.09303 3.41016H7.89718V10.138C7.89423 10.9224 7.27393 11.5574 6.5093 11.5574C6.05864 11.5574 5.65806 11.3367 5.40475 10.9955C4.95174 10.7609 4.64129 10.2795 4.64129 9.72366C4.64129 8.93684 5.26278 8.29936 6.02919 8.29936C6.17587 8.29936 6.31784 8.32296 6.45039 8.36589V7.02505C4.80447 7.06012 3.48019 8.44027 3.48019 10.138C3.48019 10.9853 3.81008 11.7534 4.34498 12.3146C4.82744 12.6473 5.40888 12.8414 6.0339 12.8414C7.70928 12.8414 9.06771 11.4473 9.06771 9.72791L9.06828 6.29021Z" fill="white" />
                    <path d="M11.3557 5.71555V5.35691C10.9262 5.35751 10.5056 5.23413 10.141 5.00068C10.4638 5.36356 10.8885 5.61334 11.3557 5.71555ZM9.09357 3.41005C9.08236 3.34595 9.0741 3.28123 9.06821 3.21652V3H7.417V9.72781C7.41466 10.5122 6.79433 11.1472 6.02911 11.1472C5.80466 11.1472 5.59259 11.0928 5.40467 10.9955C5.65798 11.3372 6.05856 11.5573 6.50921 11.5573C7.27385 11.5573 7.89477 10.9223 7.8971 10.1379V3.41005H9.09357ZM6.45089 7.02494V6.64332C6.31305 6.62396 6.17402 6.61428 6.03441 6.61428C4.35844 6.61428 3 8.00833 3 9.72781C3 10.8055 3.53372 11.7557 4.34548 12.3145C3.81059 11.7533 3.4807 10.9852 3.4807 10.1379C3.4807 8.44018 4.80439 7.06002 6.45089 7.02494Z" fill="#BFBFC8" />
                </g>
                <defs>
                    <clipPath id="clip0_525_15792">
                        <rect x="3" y="3" width="9" height="10.5" rx="3" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        );
    }
}