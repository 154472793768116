// ** React Imports
import { useEffect, useRef, useState } from "react";

// ** MUI Imports
import {
  Box,
  Typography,
  Grid,
  Select,
  MenuItem,
  CircularProgress,
} from "@mui/material";

// ** Third Party Library
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

// ** Redux
import { useAppDispatch, useAppSelector } from "store/store";
import { getAllJobsCreateContent } from "store/Thunk/createJobThunk";
import { setLocalContentType } from "store/Slice/CreateJobSlice";

// ** Custom Components
import { route_info } from "navigation/route";
import {
  CustomRightArrow,
  CustomLeftArrow,
} from "@core/constants/global-constants";
import { MARKET_PLACE } from "@core/constants/title-constants";
import { POST_TYPE, IMAGE_NOT_FOUND } from "@core/constants/enum";
import SortByCategory from "components/SortByCategory";
import ListCard from "components/ListCard";

// ** Assets
import { VideoLibrary, VideoAndPost, PixelMelonFalling } from "assets";

export default function CreatorMarketplaceAllJobs() {
  const dispatch: any = useAppDispatch();
  const parentRef: any = useRef(null);

  const { loadingState, allJobs, localContentType } = useAppSelector(
    (state: any) => state.createJobReducer
  );
  const [contentPublishType, setContenPublishType] = useState<any>(
    localContentType || "collect_both"
  );
  const [tabValue, setTabValue] = useState<string>("All");
  const [responsive, setResponsive] = useState<any>({
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
      slidesToSlide: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  });

  useEffect(() => {
    if (localContentType) setContenPublishType(localContentType);
    const handleResize = () => {
      if (parentRef.current) {
        const parentWidth = parentRef.current.clientWidth;
        setResponsive({
          superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: parentWidth / 308,
            slidesToSlide: 4,
          },
          desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: parentWidth / 308,
            slidesToSlide: 3,
          },
          tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: parentWidth / 308,
            slidesToSlide: 2,
          },
          mobile: {
            breakpoint: { max: 464, min: 0 },
            items: parentWidth / 308,
            slidesToSlide: 1,
          },
        });
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (parentRef.current) {
      const parentWidth = parentRef.current.clientWidth;
      setResponsive({
        superLargeDesktop: {
          breakpoint: { max: 4000, min: 3000 },
          items: parentWidth / 308,
          slidesToSlide: 4,
        },
        desktop: {
          breakpoint: { max: 3000, min: 1024 },
          items: parentWidth / 308,
          slidesToSlide: 3,
        },
        tablet: {
          breakpoint: { max: 1024, min: 464 },
          items: parentWidth / 308,
          slidesToSlide: 2,
        },
        mobile: {
          breakpoint: { max: 464, min: 0 },
          items: parentWidth / 308,
          slidesToSlide: 1,
        },
      });
    }
  }, [parentRef.current]);

  useEffect(() => {
    let data = {
      published_content_type: contentPublishType,
      // category_type: tabValue,
    };

    dispatch(getAllJobsCreateContent({ data }));
    dispatch(setLocalContentType(contentPublishType));
  }, [contentPublishType]);

  const handleTabChange = (newValue: any) => {
    setTabValue(newValue);
  };

  const handleChangePublishType = (event: any) =>
    setContenPublishType(event.target.value);

  let renderNewJob =
    allJobs &&
    allJobs?.length &&
    allJobs?.map((jobData: any, index: number) => (
      <ListCard
        key={jobData?.title}
        cardData={jobData}
        redirectURL={route_info?.market_place?.job_detail}
      />
    ));

  return (
    <Box className="all-jobs">
      <Grid container alignItems="center" sx={{ mb: "20px", pl: "10px" }}>
        <Grid item md={9} sm={7} xs={12}>
          <Typography variant="h6">
            {MARKET_PLACE.CREATE_CONTENT.NEW_JOBS_FOR_YOU}
          </Typography>
        </Grid>
        <Grid item md={3} sm={5} xs={12}>
          <Select
            className="select-filter"
            placeholder="Choose category..."
            value={contentPublishType}
            onChange={handleChangePublishType}
          >
            <MenuItem value={"collect_video_only"}>
              <Box
                className="filter-publish-type-img"
                component="img"
                alt={IMAGE_NOT_FOUND.NOT_FOUND}
                src={VideoLibrary}
              />
              {POST_TYPE.VIDEO_ONLY}
            </MenuItem>
            <MenuItem value={"collect_video_and_post"}>
              <Box
                className="filter-publish-type-img"
                component="img"
                alt={IMAGE_NOT_FOUND.NOT_FOUND}
                src={VideoAndPost}
              />
              {POST_TYPE.VIDEO_AND_POST}
            </MenuItem>
            <MenuItem value={"collect_both"}>
              <Box
                className="filter-publish-type-img"
                component="img"
                alt={IMAGE_NOT_FOUND.NOT_FOUND}
                src={VideoAndPost}
              />
              {POST_TYPE.ALL}
            </MenuItem>
          </Select>
        </Grid>
      </Grid>
      {loadingState ? (
        <Box className="loader">
          <CircularProgress size={20} />
        </Box>
      ) : allJobs && allJobs.length > 0 ? (
        <Box ref={parentRef}>
          <Carousel
            className="job-list-carousel"
            customRightArrow={<CustomRightArrow />}
            customLeftArrow={<CustomLeftArrow />}
            responsive={responsive}
          >
            {renderNewJob}
          </Carousel>
        </Box>
      ) : (
        !loadingState && (
          <Box className="no-data-found-block">
            <Box
              component="img"
              src={PixelMelonFalling}
              className="no-data-found-img"
            />
            <Typography variant="subtitle2">
              There are currently no jobs available. Please check back later.
            </Typography>
          </Box>
        )
      )}

      <Typography variant="h6" sx={{ mb: "20px", pl: "10px" }}>
        {MARKET_PLACE.CREATE_CONTENT.SORT_BY_CATEGORY}
      </Typography>
      <SortByCategory
        selectedPublishType={contentPublishType}
        onTabChange={handleTabChange}
      />
    </Box>
  );
}
