// ** React Imports
import React, { useEffect, useState } from "react";

// ** MUI Imports
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  FormControlLabel,
  InputBase,
  Link,
  MenuItem,
  Select,
  Switch,
  Typography,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { DataGrid } from "@mui/x-data-grid";
import { styled, alpha } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";

// ** Redux
import { useAppDispatch, useAppSelector } from "store/store";
import {
  getAllScraperGroup,
  getLinkfireScrapperInfo,
  getSpootifyConnection,
  getSpootifyScraper,
  updateLinkfireScrapperInfo,
  updateSpotifyInfo,
} from "store/Thunk/platformConnectionThunk";

// ** Custom Components
import LinkfireLinksDialog from "pages/platform/Dialogs/LinkfireLinksDialog";

// ** Icons
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

interface Props {
  title?: any;
  setDialog?: any;
  dialog?: any;
  setIsBackdrop?: any;
}

export default function LinkfireScrapperTab({
  title,
  dialog,
  setDialog,
  setIsBackdrop,
}: Props) {
  const dispatch: any = useAppDispatch();
  const { linkfireScrapperInfo, scraperGroupData } = useAppSelector(
    (state: any) => state.PlatformSlice
  );
  const [accountData, setAccountData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<number>(linkfireScrapperInfo?.count);

  useEffect(() => {
    let data: any = null;
    dispatch(getAllScraperGroup({ data })).then((res: any) => {});
  }, []);

  useEffect(() => {
    let data: any = null;
    dispatch(getLinkfireScrapperInfo({ data, page })).then((res: any) => {});
  }, [page]);

  useEffect(() => {
    setAccountData(linkfireScrapperInfo?.results);
    setTotal(linkfireScrapperInfo?.count);
  }, [linkfireScrapperInfo]);

  const handledToggleChange = (checked: any, id: any) => {
    const data = {
      active: checked ? "Yes" : "No",
    };

    updateScrapperInfo(data, id);
  };

  const updateScrapperInfo = (data: any, id: any) => {
    setIsBackdrop(true);
    setLoading(true);
    let datas: any = null;
    dispatch(updateLinkfireScrapperInfo({ data, id })).then((response: any) => {
      dispatch(getLinkfireScrapperInfo({ datas, page })).then((res: any) => {
        setIsBackdrop(false);
        setLoading(false);
      });
    });
  };

  const columns: any = [
    {
      flex: 0.45,
      minWidth: 200,
      field: "link",
      headerName: "LINKS",
      renderCell: (cellValues: any) => {
        return (
          <FormControlLabel
            control={
              <Switch
                checked={cellValues?.row?.active == "Yes" ? true : false}
              />
            }
            label={cellValues?.row?.url}
            onChange={(e: any) =>
              handledToggleChange(e.target.checked, cellValues.row.id)
            }
          />
        );
      },
    },
    {
      flex: 0.25,
      field: "scraped",
      minWidth: 80,
      headerName: "SCRAPED AMOUNT",
    },
    ,
    {
      flex: 0.3,
      field: "last_scraped",
      minWidth: 80,
      headerName: "LAST SCRAPED",
    },
    {
      flex: 0.3,
      field: "id",
      minWidth: 80,
      headerName: "GROUP",
      renderCell: (cellValues: any) => {
        return (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            placeholder="Labels..."
            size="small"
            value={cellValues?.row?.scraper_group?.id}
            onChange={(e: any) =>
              handleGroupChange(e.target.value, cellValues?.row?.id)
            }
          >
            {scraperGroupData?.map((item: any) => (
              <MenuItem value={item?.id}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box
                    component="img"
                    src={item?.profile_url}
                    style={{
                      height: "30px",
                      width: "30px",
                      marginRight: "5px",
                      borderRadius: "20px",
                    }}
                    loading="lazy"
                  />
                  <Typography sx={{ fontSize: "14px" }}>
                    {item?.group_name}
                  </Typography>
                </Box>
              </MenuItem>
            ))}
          </Select>
        );
      },
    },
  ];

  const handleSearchChange = (value: string) => {
    const accountArray: any = linkfireScrapperInfo?.results?.filter(
      (element: any) => {
        if (
          element.url.toLocaleLowerCase().includes(value.toLocaleLowerCase())
        ) {
          return element;
        }
      }
    );
    setAccountData(accountArray);
  };

  const handleGroupChange = (value: any, id: any) => {
    const data = {
      scraper_group_id: value,
    };
    updateScrapperInfo(data, id);
  };

  return (
    <Box>
      <Box
        className="sync-justify-between"
        sx={{
          mt: 5,
        }}
      >
        <Box>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search (Ctrl+/)"
              inputProps={{ "aria-label": "search" }}
              onChange={(e: any) => handleSearchChange(e.target.value)}
            />
          </Search>
        </Box>
        <Box>
          <Button
            variant="text"
            startIcon={<AddCircleOutlineOutlinedIcon />}
            sx={{ fontSize: "12px" }}
            onClick={() => setDialog({ open: true })}
          >
            ADD {title == "Spotify" ? "Artist" : "Links"}
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 5 }}>
        <DataGrid
          columns={columns}
          rows={accountData}
          pagination
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage + 1)}
          pageSize={10}
          sx={{ height: "100vh", display: "flex" }}
          rowCount={total}
        />
      </Box>

      <Dialog
        fullWidth
        open={dialog.open}
        TransitionComponent={Transition}
        maxWidth="sm"
        scroll="body"
        onClose={() => setDialog({ open: false })}
      >
        <DialogContent
          sx={{
            px: { xs: 8, sm: 15 },
            py: { xs: 8, sm: 12.5 },
            position: "relative",
          }}
        >
          {title == "Linkfire Data Scraper" && (
            <LinkfireLinksDialog page={page} setDialog={setDialog} />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
}
