import MESSAGES from "./messages";

const reWhiteSpace = /^[^-\s][\w\s-]+$/;
const passwordValidation =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/;
const alphabet = /^[a-zA-Z]*$/;
const number = /^[0-9]+$/;
const phoneNumber = /^\+(?:[0-9] ?){6,14}[0-9]$/;
const personNameValidation = /^[a-zA-Z ]*$/;
const reWhiteSp = /^[^\s]+(\s+[^\s]+)*$/;
const emailValidation = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/;
// \b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Z|a-z]{2,}\b'
// const emailValidation = /^\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,3}$/;
const alphabetWithSpace = /[^\w -]/;
const smallLetter = /^(?=.*[a-z])/;
const capitalLetter = /^(?=.*[A-Z])/;
const urlValidation =
  /^[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/;

const Validations = {
  validateLoginForm: (form: any) => {
    const errors: Partial<any> = {};
    const { email, password } = form;

    if (!email) {
      errors.email = MESSAGES.LOGIN.ERROR.EMAIL.EMPTY;
    } else if (email && !emailValidation.test(String(email).toLowerCase())) {
      errors.email = MESSAGES.LOGIN.ERROR.EMAIL.EMAIL_VALIDATION;
    }

    if (!reWhiteSp.test(String(password).toLowerCase())) {
      errors.password = MESSAGES.VALID_PASSWORD.ERROR.PASSWORD.WHITE_SPACE;
    }
    if (!password) {
      errors.password = MESSAGES.LOGIN.ERROR.PASSWORD.EMPTY;
    }
    return errors;
  },
  validateRegisterForm: (form: any) => {
    const errors: Partial<any> = {};
    const { first_name, last_name, email, password, privacy } = form;

    if (!email) {
      errors.email = MESSAGES.REGISTER.ERROR.EMAIL.EMPTY;
    }
    if (email && !emailValidation.test(String(email).toLowerCase())) {
      errors.email = MESSAGES.REGISTER.ERROR.EMAIL.EMAIL_VALIDATION;
    }

    if (!reWhiteSp.test(String(password).toLowerCase())) {
      errors.password = MESSAGES.VALID_PASSWORD.ERROR.PASSWORD.WHITE_SPACE;
    }

    if (password.length < 8) {
      errors.password = MESSAGES.VALID_PASSWORD.ERROR.PASSWORD.INVALID_LENGTH;
    }

    if (
      !smallLetter.test(password) ||
      !capitalLetter.test(password) ||
      password.search(/[0-9]/) < 0 ||
      password.search(/[!#_$@%&]/) < 0
    ) {
      errors.password = MESSAGES.VALID_PASSWORD.ERROR.PASSWORD.STRONG_PASSWORD;
    }

    if (!password) {
      errors.password = MESSAGES.REGISTER.ERROR.PASSWORD.EMPTY;
    }

    if (!privacy) {
      errors.privacy = MESSAGES.REGISTER.ERROR.PRIVACY.EMPTY;
    }

    return errors;
  },

  validateBusinessForm: (form: any, isSocialLogin: any) => {
    const errors: Partial<any> = {};
    const { first_name, last_name, company_name, reason } = form;

    if (!first_name && !isSocialLogin) {
      errors.first_name = MESSAGES.REGISTER.ERROR.FIRST_NAME.EMPTY;
    }

    if (!last_name && !isSocialLogin) {
      errors.last_name = MESSAGES.REGISTER.ERROR.LAST_NAME.EMPTY;
    }

    // if (!company_name) {
    //   errors.company_name = MESSAGES.REGISTER.ERROR.COMPANY_NAME.EMPTY;
    // }

    if (!reason) {
      errors.reason = MESSAGES.REGISTER.ERROR.BUSINESS.EMPTY;
    }
    return errors;
  },
  validateForgetPasswordForm: (form: any) => {
    const errors: Partial<any> = {};
    const { email } = form;

    if (!email) {
      errors.email = MESSAGES.LOGIN.ERROR.EMAIL.EMPTY;
    } else if (email && !emailValidation.test(String(email).toLowerCase())) {
      errors.email = MESSAGES.LOGIN.ERROR.EMAIL.EMAIL_VALIDATION;
    }

    return errors;
  },
  validateResetPasswordForm: (form: any) => {
    const errors: Partial<any> = {};
    const { password1, password2 } = form;

    if (!reWhiteSp.test(String(password1).toLowerCase())) {
      errors.password1 = MESSAGES.VALID_PASSWORD.ERROR.PASSWORD.WHITE_SPACE;
    }

    if (password1.length < 8) {
      errors.password1 = MESSAGES.VALID_PASSWORD.ERROR.PASSWORD.INVALID_LENGTH;
    }

    if (
      !smallLetter.test(password1) ||
      !capitalLetter.test(password1) ||
      password1.search(/[0-9]/) < 0 ||
      password1.search(/[!#_$@%&]/) < 0
    ) {
      errors.password1 = MESSAGES.VALID_PASSWORD.ERROR.PASSWORD.STRONG_PASSWORD;
    }

    if (!password1) {
      errors.password1 = MESSAGES.REGISTER.ERROR.PASSWORD.EMPTY;
    }
    // return errors;

    if (!reWhiteSp.test(String(password2).toLowerCase())) {
      errors.password2 = MESSAGES.VALID_PASSWORD.ERROR.PASSWORD.WHITE_SPACE;
    }

    if (password2.length < 8) {
      errors.password2 = MESSAGES.VALID_PASSWORD.ERROR.PASSWORD.INVALID_LENGTH;
    }

    if (
      !smallLetter.test(password2) ||
      !capitalLetter.test(password2) ||
      password2.search(/[0-9]/) < 0 ||
      password2.search(/[!#_$@%&]/) < 0
    ) {
      errors.password2 = MESSAGES.VALID_PASSWORD.ERROR.PASSWORD.STRONG_PASSWORD;
    } else if (password1 != password2) {
      errors.password2 = MESSAGES.REGISTER.ERROR.NOT_MATCH.INVALID;
    }

    if (!password2) {
      errors.password2 = MESSAGES.REGISTER.ERROR.PASSWORD.EMPTY;
    }
    return errors;
  },
  validateSpootifyLoginForm: (form: any) => {
    const errors: Partial<any> = {};
    const { email, password, privacy } = form;

    if (!email) {
      errors.email = MESSAGES.LOGIN.ERROR.EMAIL.EMPTY;
    } else if (email && !emailValidation.test(String(email).toLowerCase())) {
      errors.email = MESSAGES.LOGIN.ERROR.EMAIL.EMAIL_VALIDATION;
    }

    // if (!reWhiteSp.test(String(password).toLowerCase())) {
    //   errors.password = MESSAGES.VALID_PASSWORD.ERROR.PASSWORD.WHITE_SPACE;
    // }
    // if (password.length < 8 || !smallLetter.test(password) || !capitalLetter.test(password) || password.search(/[0-9]/) < 0 || password.search(/[!#_$@%&]/) < 0) {
    //   errors.password = MESSAGES.VALID_PASSWORD.ERROR.PASSWORD.STRONG_PASSWORD;
    // }
    if (!password) {
      errors.password = MESSAGES.LOGIN.ERROR.PASSWORD.EMPTY;
    }

    if (!privacy) {
      errors.privacy = MESSAGES.REGISTER.ERROR.PRIVACY.EMPTY;
    }
    return errors;
  },

  validateLinkfireGeneratorLoginForm: (form: any) => {
    const errors: Partial<any> = {};
    const { client_secret } = form;

    if (!client_secret) {
      errors.client_secret =
        MESSAGES.LOGIN_LINKFIRE_GENERATOR.ERROR.API_KEY.EMPTY;
    }
    return errors;
  },

  validateLinkfireAddLinkForm: (form: any) => {
    const errors: Partial<any> = {};
    const { urls, scraper_group_id } = form;

    if (!urls) {
      errors.urls = MESSAGES.ADD_LINKFIRE_GENERATOR.ERROR.URLS.EMPTY;
    }

    if (!scraper_group_id) {
      errors.scraper_group_id =
        MESSAGES.ADD_LINKFIRE_GENERATOR.ERROR.GROUP_ID.EMPTY;
    }
    return errors;
  },

  validateSpotifyAddLinkForm: (form: any) => {
    const errors: Partial<any> = {};
    const { name, scraper_group_id } = form;

    if (!name) {
      errors.name = MESSAGES.ADD_SPOOTIFY.ERROR.NAME.EMPTY;
    }

    if (!scraper_group_id) {
      errors.scraper_group_id = MESSAGES.ADD_SPOOTIFY.ERROR.GROUP_ID.EMPTY;
    }
    return errors;
  },
  validateCreateJobForm: (form: any) => {
    const errors: Partial<any> = {};
    const { title, description, category, thumbnail, thumbnail_url } = form;

    if (!title) {
      errors.title = MESSAGES.JOB.ERROR.TITLE.EMPTY;
    }

    if (!description) {
      errors.description = MESSAGES.JOB.ERROR.DESCRIPTION.EMPTY;
    }

    if (!category) {
      errors.category = MESSAGES.JOB.ERROR.CATEGORY.EMPTY;
    }

    if (!thumbnail || !thumbnail_url) {
      errors.thumbnail = MESSAGES.JOB.ERROR.THUMBNAIL.EMPTY;
    }
    return errors;
  },
  validateSecurityForm: (form: any) => {
    const errors: Partial<any> = {};
    const { password, newPassword, confirmPassword } = form;

    if (!password) {
      errors.password = MESSAGES.RESET_PASSWORD.ERROR.OLD_PASSWORD.EMPTY;
    }

    if (!newPassword) {
      errors.newPassword = MESSAGES.RESET_PASSWORD.ERROR.NEW_PASSWORD.EMPTY;
    } else if (
      !smallLetter.test(newPassword) ||
      !capitalLetter.test(newPassword) ||
      newPassword.search(/[0-9]/) < 0 ||
      newPassword.search(/[!#_$@%&]/) < 0
    ) {
      errors.newPassword =
        MESSAGES.RESET_PASSWORD.ERROR.NEW_PASSWORD.STRONG_PASSWORD;
    } else if (newPassword.length < 8) {
      errors.newPassword =
        MESSAGES.RESET_PASSWORD.ERROR.NEW_PASSWORD.INVALID_LENGTH;
    }

    if (!confirmPassword) {
      errors.confirmPassword =
        MESSAGES.RESET_PASSWORD.ERROR.CONFIRM_PASSWORD.EMPTY;
    } else if (confirmPassword != newPassword) {
      errors.confirmPassword =
        MESSAGES.RESET_PASSWORD.ERROR.CONFIRM_PASSWORD.NOT_MATCH;
    }
    return errors;
  },
  validateAccountForm: (form: any) => {
    const errors: Partial<any> = {};
    const { name, email, phone_number, company, country, gender } = form;

    if (!name) {
      errors.name = MESSAGES.ACCOUNT_FORM.ERROR.NAME.EMPTY;
    }

    if (!email) {
      errors.email = MESSAGES.ACCOUNT_FORM.ERROR.NAME.EMPTY;
    }
    if (phone_number && phone_number.length > 11) {
      errors.phone_number = MESSAGES.ACCOUNT_FORM.ERROR.PHONE_NUMBER.INVALID;
    }
    if (!company) {
      errors.company = MESSAGES.ACCOUNT_FORM.ERROR.COMPANY.EMPTY;
    }
    // if (!country) {
    //   errors.country = MESSAGES.ACCOUNT_FORM.ERROR.COUNTRY.EMPTY;
    // }
    // if (!gender) {
    //   errors.gender = MESSAGES.ACCOUNT_FORM.ERROR.GENDER.EMPTY;
    // }

    return errors;
  },
  validateContactDialogForm: (form: any) => {
    const errors: Partial<any> = {};
    const { monthly_adspend } = form;
    console.log("monthly_adspend", monthly_adspend);

    if (!monthly_adspend) {
      errors.monthly_adspend =
        MESSAGES.CONTACT_DIALOG_FORM.ERROR.AD_SPEND.EMPTY;
    }

    return errors;
  },
  validateCreativeLandingUrl: (data: any) => {
    const errors: any[] = [];
    const { creative_data } = data;

    if (creative_data && creative_data.length) {
      creative_data.map((val: any, index: any) => {
        let errorTxt = val.landingpage_url;
        if (!val.landingpage_url) {
          errors.push(MESSAGES.LANDING_PAGE_URL.ERROR.URL.EMPTY);
        } else if (
          val.landingpage_url &&
          !urlValidation.test(String(val.landingpage_url))
        ) {
          errors.push(MESSAGES.LANDING_PAGE_URL.ERROR.URL.INVALID);
        } else {
          errors.push("");
        }
      });
    }

    return errors;
  },
};

export default Validations;
