import { createSlice, Draft, PayloadAction } from '@reduxjs/toolkit';

interface InitialState {
    earningData: any;
    payoutBalance: any;
    payoutAmount: any;
    invoiceDetails: any;
}

const initialState: InitialState = {
    earningData: null,
    payoutBalance: null,
    payoutAmount: null,
    invoiceDetails: [],
};

const earningData = createSlice({
    name: 'createEarningData',
    initialState: initialState,
    reducers: {
        setEarningData: (
            state: Draft<InitialState>,
            action: PayloadAction<[]>
        ) => {
            state.earningData = action.payload;
        },
        setPayoutAmount: (
            state: Draft<InitialState>,
            action: PayloadAction<[]>
        ) => {
            state.payoutAmount = action.payload;
        },
        setPayoutBalance: (
            state: Draft<InitialState>,
            action: PayloadAction<[]>
        ) => {
            state.payoutBalance = action.payload;
        },
        setIncoiceDetails: (
            state: Draft<InitialState>,
            action: PayloadAction<[]>
        ) => {
            state.invoiceDetails = action.payload;
        }
    }
});

export const { setEarningData, setPayoutAmount, setPayoutBalance, setIncoiceDetails } =
    earningData.actions;

export default earningData.reducer;
