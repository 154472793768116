// ** React Imports
import { useEffect, useState } from "react";

// ** MUI Imports
import {
  Box,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  Typography,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { alpha } from "@mui/material/styles";

// ** Third Party Library
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import _ from "lodash";

// ** Redux
import { useAppDispatch, useAppSelector } from "store/store";
import { notificationFail } from "store/Slice/notificationSlice";
import { getAllJobsDetail, closeJob } from "store/Thunk/createJobThunk";

// ** Custom Components
import { DIMENSIONS } from "@core/constants/enum";
import { MARKET_PLACE } from "@core/constants/title-constants";
import ContentTypeCard from "components/ContentTypeCard";
import Earning from "pages/marketplace/create-content/EarningCard";
import Milestone from "pages/marketplace/create-content/Milestone";

// ** Assets
import {
  BlankImage,
  HorizontalBlue,
  Instagram,
  Snapchat,
  SquareBlue,
  Tiktok,
  VerticalBlue,
} from "assets";

export default function MyJobDetail() {
  const navigate = useNavigate();
  const dispatch: any = useAppDispatch();
  const theme = useTheme();
  let { id } = useParams();

  const { loadingState, allJobDetail } = useAppSelector(
    (state: any) => state.createJobReducer
  );

  const [step, setStep] = useState<number>(2);
  const [jobDetail, setJobDetail] = useState<any>();
  const [isVideoOnly, setIsVideoOnly] = useState<boolean>(true);
  const jobRequirements = [
    "Must have the hashtag #ad in the caption",
    "Content must be in video format",
  ];
  const [isJobClosed, setIsJobClosed] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSubmissionAvailable, setIsSubmissionAvailable] =
    useState<boolean>(false);

  useEffect(() => {
    // Call Job detail API
    APICall();
  }, []);

  const APICall = () => {
    let data = { id };
    dispatch(getAllJobsDetail({ data }));
  };

  useEffect(() => {
    // Handle API response after fetching job detail
    setJobDetail(allJobDetail);
    if (allJobDetail && !_.isEmpty(allJobDetail.submission)) {
      setIsSubmissionAvailable(true);
    }
  }, [allJobDetail]);

  // Handle close job button
  const onCloseJobClick = () => {
    setIsJobClosed(true);
  };

  const handleCloseJob = () => {
    setIsLoading(true);
    dispatch(closeJob({ id: allJobDetail.id }))
      .then((res: any) => {
        if (!res?.error) {
          setIsJobClosed(false);
          setIsLoading(false);
          APICall();
        } else {
          dispatch(
            notificationFail(
              "We're sorry, but something didn't work as expected. Please try again."
            )
          );
          setIsLoading(false);
          setIsJobClosed(false);
        }
      })
      .catch((err: any) => {
        setIsLoading(false);
        setIsJobClosed(false);
      });
  };
  return (
    <Box className="job-detail-main-block">
      {loadingState ? (
        <Box className="loader" sx={{ height: "100%" }}>
          <CircularProgress size={20} />
        </Box>
      ) : (
        <>
          <Box className="job-title-block">
            <Typography
              variant="subtitle2"
              onClick={() => navigate("/all-jobs")}
              className="txt-back-to-overview"
            >
              {MARKET_PLACE.CREATE_CONTENT.BACK_TO_OVERVIEW}
            </Typography>

            <Button
              disabled={allJobDetail?.status === "closed"}
              variant="contained"
              onClick={() => onCloseJobClick()}
            >
              {allJobDetail?.status === "closed"
                ? "Job Closed"
                : "Close This Job"}
            </Button>
          </Box>
          <Grid container spacing={6} className="job-detail-block">
            <Grid item xs={12} md={6}>
              <Box
                className="detail-left-block"
                sx={{ bgcolor: theme.palette.primary.contrastText }}
              >
                <Box sx={{ position: "relative" }}>
                  <Box className="detail-img-overlay">
                    <Box className="detail-left-icon">
                      <ContentTypeCard
                        contentType={jobDetail?.publish_content_type}
                      />
                    </Box>
                  </Box>

                  <Box
                    className="job-detail-image"
                    component="img"
                    alt="Job detail image not found"
                    src={jobDetail?.thumbnails_url}
                  />
                </Box>
                <Box sx={{ pt: "20px", px: "20px" }}>
                  <Box className="sync-flex-direction">
                    <Box>
                      <Typography
                        variant="h6"
                        sx={{
                          color: theme.palette.customColors.comet,
                        }}
                      >
                        {jobDetail?.title}
                      </Typography>
                      <Typography
                        variant="caption"
                        sx={{
                          color: alpha(
                            theme.palette.customColors.border_color,
                            0.68
                          ),
                        }}
                      >
                        {"Opened on " +
                          moment(jobDetail?.created_at).format("DD/MMM/YYYY")}
                      </Typography>
                    </Box>
                    <Box
                      className="job-detail-social-image"
                      component="img"
                      alt="Job detail image not found"
                      src={
                        jobDetail?.platforms
                          ? jobDetail?.platforms === "Instagram"
                            ? Instagram
                            : jobDetail?.platforms === "Snapchat"
                            ? Snapchat
                            : jobDetail?.platforms === "Tiktok"
                            ? Tiktok
                            : BlankImage
                          : jobDetail?.dimension === DIMENSIONS.VERTICAL
                          ? VerticalBlue
                          : jobDetail?.dimension === DIMENSIONS.HORIZONTAL
                          ? HorizontalBlue
                          : jobDetail?.dimension === DIMENSIONS.SQUARE
                          ? SquareBlue
                          : BlankImage
                      }
                    />
                  </Box>

                  <Box sx={{ my: "20px" }}>
                    <Typography
                      variant="h6"
                      color={theme.palette.customColors.comet}
                    >
                      {MARKET_PLACE.CREATE_CONTENT.JOB_DETAILS}
                    </Typography>

                    <Typography
                      variant="body2"
                      color={alpha(
                        theme.palette.customColors.border_color,
                        0.68
                      )}
                      sx={{
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      {jobDetail?.job_description}
                    </Typography>
                  </Box>

                  <Box>
                    <Typography
                      variant="h6"
                      color={theme.palette.customColors.comet}
                    >
                      {MARKET_PLACE.CREATE_CONTENT.JOB_REQUIREMENTS}
                    </Typography>

                    {jobRequirements.length > 0
                      ? jobRequirements.map(
                          (requirement: any, index: number) => (
                            <Box
                              key={requirement}
                              className="sync-flex-direction-align"
                            >
                              <div className="disc"></div>
                              <Typography
                                variant="body2"
                                color={alpha(
                                  theme.palette.customColors.border_color,
                                  0.68
                                )}
                              >
                                {requirement}
                              </Typography>
                            </Box>
                          )
                        )
                      : null}
                  </Box>

                  <Box sx={{ my: "20px" }}>
                    <Typography
                      variant="h6"
                      color={theme.palette.customColors.comet}
                    >
                      {MARKET_PLACE.CREATE_CONTENT.BUDGET}
                    </Typography>
                    <Typography
                      variant="h5"
                      color={theme.palette.customColors.comet}
                      sx={{ mb: "8px" }}
                    >
                      {"$" + (jobDetail?.selected_budget || 0)}
                    </Typography>
                    <Typography
                      variant="caption"
                      color={alpha(
                        theme.palette.customColors.border_color,
                        0.68
                      )}
                    >
                      {"$ " +
                        (jobDetail?.budget_value.spent || 0) +
                        " already spent (" +
                        (jobDetail?.budget_value.percentage || 0) +
                        "% of total budget)"}
                    </Typography>

                    <LinearProgress
                      variant="determinate"
                      value={jobDetail?.budget_value.percentage}
                      color="success"
                      className="linear-progress"
                    />
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box className="detail-right-block">
                <Box className="detail-brand-img">
                  <Box
                    className="img"
                    component="img"
                    alt="Publish type image not found"
                    src={jobDetail?.brand_details.brand_logo_url}
                  />
                </Box>
                <Box>
                  <Typography variant="h6" className="brand_title">
                    {"About " + jobDetail?.brand_details.brand_name}
                  </Typography>
                  <Typography variant="body2" className="brand_desc">
                    {jobDetail?.brand_details.brand_description}
                  </Typography>
                </Box>
              </Box>
              <Box>
                {isVideoOnly ? (
                  <Earning earning={jobDetail || 0} />
                ) : (
                  <Milestone steps={jobDetail.milestone} step={step} />
                )}
              </Box>
            </Grid>
          </Grid>
        </>
      )}

      <Dialog
        open={isJobClosed}
        fullWidth={isLoading}
        aria-labelledby="user-view-edit"
        aria-describedby="user-view-edit-description"
      >
        {isSubmissionAvailable ? (
          <>
            <DialogTitle className="detail-dialog-title" sx={{ pb: 0 }}>
              {MARKET_PLACE.REQUEST_CONTENT.CANNOT_CLOSE_JOB}
            </DialogTitle>

            <DialogContent>
              <Typography>
                {MARKET_PLACE.REQUEST_CONTENT.CANNOT_CLOSE_JOB_DESCRIPTION}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button color="primary" onClick={() => setIsJobClosed(false)}>
                Go back
              </Button>
            </DialogActions>
          </>
        ) : isLoading ? (
          <Box className="loader">
            <CircularProgress size={20} />
          </Box>
        ) : (
          <>
            <DialogTitle className="detail-dialog-title">
              {MARKET_PLACE.REQUEST_CONTENT.CLOSE_THIS_JOB_TITLE}
            </DialogTitle>
            <DialogContent>
              <Typography
                variant="body2"
                className="dialog_content"
                color={theme.palette.customColors.border_color_light}
              >
                {MARKET_PLACE.REQUEST_CONTENT.CLOSE_THIS_JOB_DESCRIPTION}
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={() => setIsJobClosed(false)}>
                Cancel
              </Button>
              <Button
                sx={{
                  padding: { md: "0.4375rem 1.375rem", xs: "0.4rem 0.54rem" },
                }}
                variant="contained"
                color="primary"
                onClick={() => handleCloseJob()}
                autoFocus
              >
                {MARKET_PLACE.REQUEST_CONTENT.CLOSE_THIS_JOB}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
}
