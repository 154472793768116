export default function GoogleIcon({active = false}){

    if (active) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
            <rect width="30" height="30" rx="5" fill="#EBEBEB"/>
            <g clipPath="url(#clip0_916_13449)">
            <path d="M18.1492 10.3285C19.1598 8.63459 18.5601 6.46853 16.8097 5.49053C15.0593 4.51252 12.821 5.09291 11.8104 6.78687L4.49092 19.0556C3.48032 20.7496 4.08005 22.9156 5.83047 23.8936C7.5809 24.8716 9.81915 24.2912 10.8297 22.5973L18.1492 10.3285Z" fill="#F9BB2D"/>
            <path d="M11.8103 10.3285C10.7997 8.63459 11.3994 6.46853 13.1499 5.49053C14.9003 4.51252 17.1385 5.09291 18.1491 6.78687L25.4686 19.0556C26.4792 20.7496 25.8795 22.9156 24.1291 23.8936C22.3786 24.8716 20.1403 24.2912 19.1297 22.5973L11.8103 10.3285Z" fill="#4688F1"/>
            <path d="M11.3207 20.8285C11.3207 22.7845 9.68216 24.3702 7.66095 24.3702C5.63973 24.3702 4.00122 22.7845 4.00122 20.8285C4.00122 18.8725 5.63973 17.2869 7.66095 17.2869C9.68216 17.2869 11.3207 18.8725 11.3207 20.8285Z" fill="#3AA757"/>
            </g>
            <defs>
            <clipPath id="clip0_916_13449">
            <rect width="22" height="20" fill="white" transform="translate(4 5)"/>
            </clipPath>
            </defs>
            </svg>
        );
    } else {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 31" fill="none">
            <rect y="0.5" width="30" height="30" rx="5" fill="#AFAFAF"/>
            <g clipPath="url(#clip0_916_13498)">
            <path d="M18.1492 10.8285C19.1598 9.13459 18.5601 6.96853 16.8097 5.99053C15.0593 5.01252 12.821 5.59291 11.8104 7.28687L4.49092 19.5556C3.48032 21.2496 4.08005 23.4156 5.83047 24.3936C7.5809 25.3716 9.81915 24.7912 10.8297 23.0973L18.1492 10.8285Z" fill="#E1E1E1"/>
            <path d="M11.8103 10.8285C10.7997 9.13459 11.3994 6.96853 13.1499 5.99053C14.9003 5.01252 17.1385 5.59291 18.1491 7.28687L25.4686 19.5556C26.4792 21.2496 25.8795 23.4156 24.1291 24.3936C22.3786 25.3716 20.1403 24.7912 19.1297 23.0973L11.8103 10.8285Z" fill="#575757"/>
            <path d="M11.3207 21.3285C11.3207 23.2845 9.68216 24.8701 7.66095 24.8701C5.63973 24.8701 4.00122 23.2845 4.00122 21.3285C4.00122 19.3724 5.63973 17.7868 7.66095 17.7868C9.68216 17.7868 11.3207 19.3724 11.3207 21.3285Z" fill="#949494"/>
            </g>
            <defs>
            <clipPath id="clip0_916_13498">
            <rect width="22" height="20" fill="white" transform="translate(4 5.5)"/>
            </clipPath>
            </defs>
            </svg>
        );
    }
}