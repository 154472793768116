// ** React Imports
import React, { useEffect, useRef, useState } from "react";

// ** MUI Imports
import { Box, LinearProgress, Link } from "@mui/material";

// ** Custom Components
import { bytes } from "helper/common";

// ** Redux
import { useAppDispatch, useAppSelector } from "store/store";
import {
  setDownloadVideoInfo,
  setShowDownloader,
} from "store/Slice/CreateJobSlice";

// ** Icons
import DownloadIcon from "@mui/icons-material/DownloadForOfflineRounded";
import SuccessIcon from "@mui/icons-material/CheckCircleRounded";
import MinimizeIcon from "@mui/icons-material/MinimizeRounded";
import CloseIcon from "@mui/icons-material/CloseRounded";

export default function DownloaderContainer() {
  const dispatch: any = useAppDispatch();

  let { downloadVideoInfo, showDownloader } = useAppSelector(
    (state: any) => state.createJobReducer
  );

  const [showItem, setShowItem] = useState<boolean>(true);
  const [downloadInfo, setDownloadInfo] = useState<any>({
    percentage: 0,
    fileSize: 0,
  });

  useEffect(() => {
    setDownloadInfo({ ...downloadInfo, ...downloadVideoInfo });
    if (downloadVideoInfo?.percentage == 100) {
      const intervalId = setInterval(() => {
        dispatch(setShowDownloader(false));
        dispatch(
          setDownloadVideoInfo({
            fileSize: 0,
            percentage: 0,
          })
        );
      }, 10000);

      return () => clearInterval(intervalId);
    }
  }, [downloadVideoInfo]);

  const minimizeUploader = () => {
    setShowItem(!showItem);
  };

  const cancelUploader = () => {
    dispatch(setShowDownloader(!showDownloader));
  };

  const getPercentageLabel = () => {
    if (downloadInfo?.percentage > 0) {
      return `${downloadInfo?.percentage} % downloading...`;
    } else {
      return "Please wait";
    }
  };

  return (
    <Box
      className="upload__status"
      sx={showDownloader ? { display: "block" } : { display: "none" }}
    >
      <Box className="head__upload">
        <p>Download All</p>
        <Box className="hide__upload">
          {/* <a> */}
          <Link>
            <MinimizeIcon
              sx={{ color: "#FFFFFF" }}
              onClick={() => minimizeUploader()}
            />
          </Link>
          <Link>
            <CloseIcon
              sx={{ color: "#FFFFFF" }}
              onClick={() => cancelUploader()}
            />
          </Link>
        </Box>
      </Box>
      <Box className="content__upload">
        {showItem && (
          <Box className="elem__upload uploaded__already">
            <Box className="upload__elem--status">
              <span>
                {downloadInfo?.percentage === 100 ? (
                  <SuccessIcon color="success" fontSize="large" />
                ) : (
                  <DownloadIcon color="primary" fontSize="large" />
                )}
              </span>
            </Box>
            <Box className="upload__info">
              <Box className="top__upload">
                {downloadInfo?.percentage !== 100 ? (
                  <Box className="upload__text">
                    <p>{getPercentageLabel()}</p>
                  </Box>
                ) : (
                  <>
                    <Box className="upload__text">
                      <p>Videos.zip</p>
                    </Box>
                    <Box className="upload__size">
                      <span>{bytes(downloadInfo?.fileSize || 0)}</span>
                    </Box>
                  </>
                )}
              </Box>
              <Box className="bottom__upload">
                <Box className="progress__upload">
                  <LinearProgress
                    variant={
                      downloadInfo?.percentage > 0
                        ? "determinate"
                        : "indeterminate"
                    }
                    value={downloadInfo?.percentage}
                    color="success"
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {/* {props.uploaderShow
        ? props.uploads.map((upload) => {
            return <UploadItem key={upload.id} {...upload} />;
          })
        : undefined} */}
      </Box>
    </Box>
  );
}
