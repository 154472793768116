export default function FacebookTabIcon({ active = false }) {

    if (active) {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="15" style={{marginRight: '5px'}} height="16" viewBox="0 0 15 16" fill="none">
                <g clipPath="url(#clip0_525_15805)">
                    <path d="M2.14286 0.5H12.8571C14.0357 0.5 15 1.46429 15 2.64286V13.3571C15 14.5357 14.0357 15.5 12.8571 15.5H2.14286C0.964286 15.5 0 14.5357 0 13.3571V2.64286C0 1.46429 0.964286 0.5 2.14286 0.5Z" fill="#FF4D49" />
                    <path d="M3.2 9.29995C3.2 7.49995 4.1 5.69995 5.15 5.69995C5.7 5.69995 6.2 6.04995 6.95 7.09995C6.25 8.14995 5.85 8.84995 5.85 8.84995C4.9 10.3 4.6 10.65 4.05 10.65C3.5 10.6 3.2 10.15 3.2 9.29995ZM9.35 8.64995L8.7 7.59995C8.55 7.29995 8.35 7.04995 8.2 6.79995C8.8 5.89995 9.25 5.44995 9.85 5.44995C11.05 5.44995 12 7.19995 12 9.34995C12 10.15 11.75 10.65 11.2 10.65C10.6 10.6 10.35 10.25 9.35 8.64995ZM7.65 6.04995C6.75 4.94995 6.05 4.49995 5.15 4.49995C3.35 4.49995 2 6.84995 2 9.29995C2 10.85 2.75 11.8 4 11.8C4.9 11.8 5.55 11.4 6.7 9.34995C6.7 9.34995 7.2 8.49995 7.5 7.89995C7.6 8.09995 7.75 8.29995 7.85 8.49995L8.4 9.39995C9.45 11.15 10.05 11.75 11.1 11.75C12.3 11.75 13 10.75 13 9.19995C13 6.59995 11.6 4.44995 9.9 4.44995C9 4.49995 8.3 5.19995 7.65 6.04995Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_525_15805">
                        <rect y="0.5" width="15" height="15" rx="7.5" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        );
    } else {
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width="15" style={{marginRight: '5px'}} height="16" viewBox="0 0 15 16" fill="none">
                <g clipPath="url(#clip0_525_15620)">
                    <path d="M2.14286 0.5H12.8571C14.0357 0.5 15 1.46429 15 2.64286V13.3571C15 14.5357 14.0357 15.5 12.8571 15.5H2.14286C0.964286 15.5 0 14.5357 0 13.3571V2.64286C0 1.46429 0.964286 0.5 2.14286 0.5Z" fill="#868796" />
                    <path d="M3.2 9.29995C3.2 7.49995 4.1 5.69995 5.15 5.69995C5.7 5.69995 6.2 6.04995 6.95 7.09995C6.25 8.14995 5.85 8.84995 5.85 8.84995C4.9 10.3 4.6 10.65 4.05 10.65C3.5 10.6 3.2 10.15 3.2 9.29995ZM9.35 8.64995L8.7 7.59995C8.55 7.29995 8.35 7.04995 8.2 6.79995C8.8 5.89995 9.25 5.44995 9.85 5.44995C11.05 5.44995 12 7.19995 12 9.34995C12 10.15 11.75 10.65 11.2 10.65C10.6 10.6 10.35 10.25 9.35 8.64995ZM7.65 6.04995C6.75 4.94995 6.05 4.49995 5.15 4.49995C3.35 4.49995 2 6.84995 2 9.29995C2 10.85 2.75 11.8 4 11.8C4.9 11.8 5.55 11.4 6.7 9.34995C6.7 9.34995 7.2 8.49995 7.5 7.89995C7.6 8.09995 7.75 8.29995 7.85 8.49995L8.4 9.39995C9.45 11.15 10.05 11.75 11.1 11.75C12.3 11.75 13 10.75 13 9.19995C13 6.59995 11.6 4.44995 9.9 4.44995C9 4.49995 8.3 5.19995 7.65 6.04995Z" fill="white" />
                </g>
                <defs>
                    <clipPath id="clip0_525_15620">
                        <rect y="0.5" width="15" height="15" rx="7.5" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        );
    }
}