// ** React Imports
import { ReactNode, useEffect, useState } from "react";

// ** MUI Imports
import { Theme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

// ** Layout Imports
// !Do not remove this Layout import
import Layout from "../@core/layouts/Layout";

// ** Navigation Imports
import VerticalNavItems from "../navigation/vertical";
import HorizontalNavItems from "../navigation/horizontal";

// ** Component Import
// Uncomment the below line (according to the layout type) when using server-side menu
// import ServerSideVerticalNavItems from './components/vertical/ServerSideNavItems'
// import ServerSideHorizontalNavItems from './components/horizontal/ServerSideNavItems'

import VerticalAppBarContent from "./components/vertical/AppBarContent";
import HorizontalAppBarContent from "./components/horizontal/AppBarContent";

// ** Hook Import
import { useSettings } from "@core/hooks/useSettings";
import { route_info } from "navigation/route";
import { useLocation } from "react-router-dom";

interface Props {
  children: ReactNode;
}

const UserLayout = ({ children }: Props) => {
  // ** Hooks
  const { settings, saveSettings } = useSettings();

  const [moduleName, setModuleName] = useState<any>("");

  const location = useLocation();

  useEffect(() => {
    const { market_planner, market_place } = route_info;
    const isMarketPlannerRoute = Object.values(market_planner).some((route) =>
      location.pathname.startsWith(route)
    );
    const isMarketPlaceRoute = Object.values(market_place).some((route) =>
      location.pathname.startsWith(route)
    );
    if (isMarketPlannerRoute) {
      setModuleName("market_planner");
    } else if (isMarketPlaceRoute) {
      setModuleName("market_place");
    } else {
      setModuleName("");
    }
    // const marketPlannerRoute: any = Object.values(route_info.market_planner);
    // const marketPlaceRoute: any = Object.values(route_info.market_place);
    // if (
    //   Array.isArray(marketPlannerRoute) &&
    //   marketPlannerRoute.includes(location.pathname)
    // ) {
    //   setModuleName("market_planner");
    // } else if (
    //   Array.isArray(marketPlaceRoute) &&
    //   marketPlaceRoute.includes(location.pathname)
    // ) {
    //   setModuleName("market_place");
    // } else {
    //   setModuleName("");
    // }
  }, [location.pathname]);

  /**
   *  The below variable will hide the current layout menu at given screen size.
   *  The menu will be accessible from the Hamburger icon only (Vertical Overlay Menu).
   *  You can change the screen size from which you want to hide the current layout menu.
   *  Please refer useMediaQuery() hook: https://mui.com/material-ui/react-use-media-query/,
   *  to know more about what values can be passed to this hook.
   *  ! Do not change this value unless you know what you are doing. It can break the template.
   */
  const hidden = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));

  return (
    <Layout
      hidden={hidden}
      settings={settings}
      saveSettings={saveSettings}
      {...(settings.layout === "horizontal"
        ? {
            // ** Navigation Items
            horizontalNavItems: HorizontalNavItems(),

            // Uncomment the below line when using server-side menu in horizontal layout and comment the above line
            // horizontalNavItems: ServerSideHorizontalNavItems(),

            // ** AppBar Content
            horizontalAppBarContent: () => (
              <HorizontalAppBarContent
                hidden={hidden}
                settings={settings}
                saveSettings={saveSettings}
              />
            ),
          }
        : {
            // ** Navigation Items
            verticalNavItems: VerticalNavItems(moduleName),

            // Uncomment the below line when using server-side menu in vertical layout and comment the above line
            // verticalNavItems: ServerSideVerticalNavItems(),

            // ** AppBar Content
            verticalAppBarContent: (props) => (
              <VerticalAppBarContent
                hidden={hidden}
                settings={settings}
                saveSettings={saveSettings}
                toggleNavVisibility={props.toggleNavVisibility}
              />
            ),
          })}
    >
      {children}
    </Layout>
  );
};

export default UserLayout;
