import { useContext, useState } from "react";

import {
  useTheme,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  TextField,
  Autocomplete,
  Box,
} from "@mui/material";

import moment from "moment";

import { AutoschudlerContext } from "context/autoschedulerContex";

import TimeZone from "data/json/timezone_by_name.json";
import country_codes from "data/json/country_codes.json";

import CloseIcon from "@mui/icons-material/Close";

export default function CountryModal(props: any) {
  const theme = useTheme();
  const { isOpen, onClickClose, onTimezoneChange } = props;
  const { campaign }: any = useContext(AutoschudlerContext);
  const [selectedTimeZone, setSelectedTimeZone] = useState<any>([]);
  const [offset, setOffet] = useState({});

  const handledCountryChange = async (
    event: any,
    newValue: any,
    index: number
  ) => {
    const countryCode = country_codes;
    let selected = [...selectedTimeZone];
    selected[index] = newValue;
    const response = await getTimeZone(newValue?.timezone);
    const code: any = countryCode?.find(
      (item: any) => item?.Name == newValue?.country_name
    )?.Code;
    setOffet({ ...offset, [code]: response?.utc_offset });
    setSelectedTimeZone(selected);
    onTimezoneChange(code, response?.utc_offset);
  };

  function convertUTCToTimezone(data: any) {
    var date = new Date();
    let tempTime = date.toLocaleString("en-US", {
      timeZone: data.timezone,
    });
    var timeZoneUSA = moment(tempTime).format("MMM DD YY, h:mm:ss a");
    return timeZoneUSA;
  }

  const getTimeZone = async (timeZone: any) => {
    const response = await fetch(
      `http://worldtimeapi.org/api/timezone/${timeZone}`
    )
      .then((response) => response.json())
      .then((data) => {
        return data;
      })
      .catch((error) => console.error(error));
    return response;
  };

  const filteredTimeZone = (countryCode: any, index: any) => {
    let timeZoneObj: any = { ...TimeZone };
    let arr: any = [];

    Object?.values(timeZoneObj[countryCode])?.map(
      (value: any, index: number) => {
        arr?.push({ ...value[0] });
      }
    );

    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Autocomplete
          sx={{ my: 2, width: "60%" }}
          id="tags-outlined"
          options={arr}
          freeSolo
          onChange={(event, newValue) =>
            handledCountryChange(event, newValue, index)
          }
          value={selectedTimeZone[index]}
          getOptionLabel={(option: any) =>
            `${option.timezone} (UTC ${option.utc_offset})`
          }
          // disableCloseOnSelect
          filterSelectedOptions
          renderInput={(params: any) => (
            <TextField
              {...params}
              placeholder="Search countries..."
              label="Countries *"
              variant="outlined"
            />
          )}
        />
        <Typography sx={{ width: "30%" }}>
          {selectedTimeZone[index] &&
            convertUTCToTimezone(selectedTimeZone[index])}
        </Typography>
      </Box>
    );
  };

  let content = (
    <>
      <DialogTitle
        sx={{ pb: 0, borderBottom: 1, borderBottomColor: "#e9ecef" }}
      >
        Auto Set Timezones
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={onClickClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 15,
          color: theme.palette.customColors.comet,
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogContent>
        {campaign.countries.map(
          (country: any, index: any) => filteredTimeZone(country.Code, index)
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="contained"
          onClick={onClickClose}
          disabled={selectedTimeZone?.length != campaign.countries?.length}
        >
          Save
        </Button>
      </DialogActions>
    </>
  );

  return (
    <Dialog
      fullWidth
      open={isOpen}
      aria-labelledby="user-view-edit"
      aria-describedby="user-view-edit-description"
    >
      {content}
    </Dialog>
  );
}
