// ** React Imports
import React, { useEffect, useState } from "react";

// ** MUI Imports
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  InputBase,
  Typography,
} from "@mui/material";
import Slide from "@mui/material/Slide";
import { DataGrid } from "@mui/x-data-grid";
import { styled, alpha } from "@mui/material/styles";
import { TransitionProps } from "@mui/material/transitions";

// ** Redux
import { useAppDispatch, useAppSelector } from "store/store";
import { getLinkfiregeneratorInfo } from "store/Thunk/platformConnectionThunk";

// ** Custom Components
import CustomChip from "@core/components/mui/chip";
import LinkfireGeneratorLinkDialog from "pages/platform/Dialogs/LinkfireGeneratorLinkDialog";

// ** Icons
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineOutlinedIcon from "@mui/icons-material/AddCircleOutlineOutlined";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: "inherit",
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  "&:hover": {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));

interface Props {
  title?: any;
}

export default function LinkfireGeneratorTab({ title }: Props) {
  const dispatch: any = useAppDispatch();
  const { linkfireGeneratorInfo } = useAppSelector(
    (state: any) => state.PlatformSlice
  );
  const [dialog, setDialog] = useState({ open: false });
  const [accountData, setAccountData] = useState([]);
  const [page, setPage] = useState(1);
  const [total, setTotal] = useState<number>(linkfireGeneratorInfo?.count);

  useEffect(() => {
    setAccountData(linkfireGeneratorInfo?.results);
    setTotal(linkfireGeneratorInfo?.count);
  }, [linkfireGeneratorInfo]);

  useEffect(() => {
    let data: any = null;
    dispatch(getLinkfiregeneratorInfo({ data, page })).then((res: any) => {});
  }, [page]);

  const columns = [
    {
      flex: 0.15,
      minWidth: 150,
      field: "domain",
      headerName: "LINK",
    },
    {
      flex: 0.1,
      field: "status",
      minWidth: 80,
      headerName: "STATUS",
      renderCell: (cellValues: any) => {
        return (
          <CustomChip
            size="small"
            skin="light"
            color={cellValues?.row?.status == "Published" ? "success" : "error"}
            label={cellValues?.row?.status}
            sx={{ "& .MuiChip-label": { textTransform: "capitalize" } }}
          />
        );
      },
    },
    {
      flex: 0.1,
      field: "board_id",
      minWidth: 80,
      headerName: "Group",
      renderCell: (cellValues: any) => {
        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {cellValues?.row?.scraper_group?.profile_url && (
              <Box
                component="img"
                src={cellValues?.row?.scraper_group?.profile_url}
                style={{
                  height: "30px",
                  width: "30px",
                  marginRight: "5px",
                  borderRadius: "20px",
                }}
                loading="lazy"
              />
            )}{" "}
            <Typography sx={{ fontSize: "14px" }}>
              {cellValues?.row?.scraper_group?.group_name}
            </Typography>
          </Box>
        );
      },
    },
    {
      flex: 0.1,
      field: "created_at",
      minWidth: 150,
      headerName: "CREATED ON",
    },
  ];

  const handleSearchChange = (value: string) => {
    const accountArray: any = linkfireGeneratorInfo?.results?.filter(
      (element: any) => {
        if (
          element?.domain
            ?.toLocaleLowerCase()
            ?.includes(value?.toLocaleLowerCase())
        ) {
          return element;
        }
      }
    );
    setAccountData(accountArray);
  };

  return (
    <Box>
      <Box
        className="sync-justify-between"
        sx={{
          mt: 5,
        }}
      >
        <Box>
          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search (Ctrl+/)"
              inputProps={{ "aria-label": "search" }}
              onChange={(e: any) => handleSearchChange(e?.target?.value)}
            />
          </Search>
        </Box>
        <Box>
          <Button
            variant="text"
            startIcon={<AddCircleOutlineOutlinedIcon />}
            sx={{ fontSize: "12px" }}
            onClick={() => setDialog({ open: true })}
          >
            Generate link
          </Button>
        </Box>
      </Box>
      <Box sx={{ mt: 5 }}>
        <DataGrid
          columns={columns}
          rows={accountData}
          pagination
          paginationMode="server"
          onPageChange={(newPage) => setPage(newPage + 1)}
          pageSize={10}
          rowCount={total}
          sx={{ height: "100vh", display: "flex" }}
        />
      </Box>
      <Dialog
        fullWidth
        open={dialog.open}
        TransitionComponent={Transition}
        maxWidth="sm"
        scroll="body"
        onClose={() => setDialog({ open: false })}
      >
        <DialogContent
          sx={{
            px: { xs: 8, sm: 15 },
            py: { xs: 8, sm: 12.5 },
            position: "relative",
          }}
        >
          <LinkfireGeneratorLinkDialog setDialog={setDialog} page={page} />
        </DialogContent>
      </Dialog>
    </Box>
  );
}
