// ** React Imports
import React from "react";

// ** MUI Imports
import {
  Box,
  Typography,
  FormControl,
  TextField,
  OutlinedInput,
  InputAdornment,
  IconButton,
  FormControlLabel,
  Checkbox,
  Button,
  InputLabel,
  FormHelperText,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { EyeOffOutline, EyeOutline } from "mdi-material-ui";

// ** Third Party Library
import { useNavigate } from "react-router-dom";

// ** Redux
import { useAppDispatch } from "store/store";
import { AuthLoading } from "store/Slice/AuthSlice";
import { postLoginWithSpootify } from "store/Thunk/platformConnectionThunk";

// ** Helper
import Validations from "helper/validations";

interface State {
  email?: string;
  password: string;
  showPassword: boolean;
}

interface Props {
  setDialogOpen?: any;
  onSpotifyLogin?: any;
  setLoading?: any;
  setSidebarDrawer?: any;
}

interface FormValidation {
  password?: string;
  email?: string;
  privacy?: boolean;
}

export default function SpootifyLoginDialog({
  setDialogOpen,
  onSpotifyLogin,
  setLoading,
  setSidebarDrawer,
}: Props) {
  const dispatch = useAppDispatch();

  const [values, setValues] = React.useState({
    email: "",
    password: "",
    privacy: false,
    showPassword: false,
  });
  const [formError, setFormError] = React.useState<FormValidation>();
  const [loginLoading, setLoginLoading] = React.useState<boolean>(false);

  const handleChange =
    (prop: keyof State) => (event: React.ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    let _request: any = {
      email: values.email.trim(),
      password: values.password.trim(),
      privacy: values.privacy,
    };

    let allError = Validations.validateSpootifyLoginForm(_request);
    let _data: any = {
      spotify_username: values.email.trim(),
      spotify_password: values.password.trim(),
    };

    if (
      Object.entries(allError).length === 0 &&
      allError.constructor === Object
    ) {
      setLoginLoading(true);
      dispatch(postLoginWithSpootify({ _data })).then((res: any) => {
        if (res?.payload?.status == 200) {
          setLoading(true);
          dispatch(AuthLoading(true));
          setDialogOpen(false);
          onSpotifyLogin();
          setLoginLoading(false);
          setSidebarDrawer({ open: true, drawer: "Spotify", title: "Spotify" });
        }
      });
      setFormError({});
    } else {
      setFormError(allError);
    }
  };

  return (
    <Box>
      <Typography variant="h5" sx={{ fontWeight: 600 }}>
        Connect to Spotify for Artists
      </Typography>
      <Typography variant="subtitle2" sx={{ fontWeight: 400, mt: 3 }}>
        Enter your Spotify for Artist login details to view graphs, get insights
        on streams, listeners, and followers per artist and country. You can
        also receive useful notifications based on this information.
      </Typography>
      <form noValidate autoComplete="off" onSubmit={handleSubmit}>
        <FormControl fullWidth sx={{ mb: 4, mt: 5 }}>
          <TextField
            fullWidth
            id="email"
            sx={{ mb: 4 }}
            placeholder="Email"
            label="Email"
            onChange={handleChange("email")}
            error={formError && formError.email ? true : false}
            helperText={formError && formError.email}
          />
        </FormControl>
        <FormControl fullWidth>
          <InputLabel>Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={values.showPassword ? "text" : "password"}
            value={values.password}
            placeholder="Password"
            onChange={handleChange("password")}
            label="Password"
            error={formError && formError.password ? true : false}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {values.showPassword ? <EyeOutline /> : <EyeOffOutline />}
                </IconButton>
              </InputAdornment>
            }
          />
          {formError && formError.password && (
            <FormHelperText error id="password-error">
              {formError.password}
            </FormHelperText>
          )}
        </FormControl>
        <Box
          className="sync-flex-wrap"
          sx={{
            mb: 4,
            mt: 3,
          }}
        >
          <FormControlLabel
            label="I hereby grant permission to Kanhasoft to get access to my Spotify for Artists account and data."
            control={
              <Checkbox
                checked={values.privacy}
                onChange={() =>
                  setValues({ ...values, privacy: !values.privacy })
                }
              />
            }
          />
          {formError && formError.privacy && (
            <FormHelperText
              error
              id="password-error"
              sx={{ mb: 4, ml: "14px" }}
            >
              {formError.privacy}
            </FormHelperText>
          )}
        </Box>
        <Box sx={{ display: "flex" }}>
          <Button
            fullWidth
            size="large"
            variant="outlined"
            sx={{ mb: 4, mr: 2 }}
            onClick={() => setDialogOpen({ open: false, dialog: "" })}
          >
            Cancel
          </Button>
          <LoadingButton
            fullWidth
            size="large"
            type="submit"
            loading={loginLoading}
            variant="contained"
            sx={{ mb: 4, ml: 2 }}
          >
            Login To Spotify
          </LoadingButton>
        </Box>
      </form>
    </Box>
  );
}
