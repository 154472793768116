import { useEffect, useState } from "react";
import {
  Box,
  Chip,
  ChipProps,
  Grid,
  styled,
  Typography,
  CircularProgress,
} from "@mui/material";
import { route_info } from "navigation/route";
import { useAppDispatch, useAppSelector } from "store/store";
import { getSubmissions } from "store/Thunk/createJobThunk";
import { updatePostStatus } from "store/Thunk/myBrandProfileThunk";
import SubmissionCard from "components/SubmissionCard";
import DeclineSubmissionMessageDialog from "components/Dialogs/DeclineSubmissionMessage";
import { STATUS } from "@core/constants/enum";
import { PixelMelonFalling } from "assets";
import { MARKET_PLACE } from "@core/constants/title-constants";

const MChip = styled(Chip)<ChipProps>(({ theme }: any) => ({
  minWidth: "70px",
  maxHeight: "24px",
  marginRight: theme.spacing(2),
}));

export default function SubmissionTabs(props: any) {
  const { selectedPublishType, submissionTabTitle } = props;
  const dispatch: any = useAppDispatch();
  const { loadingState, submissionList, newSubmissionList } = useAppSelector(
    (state: any) => state.createJobReducer
  );

  const [selected, setSelected] = useState<any>("all");
  const [isFeedbackMessage, setIsFeedbackMessage] = useState<boolean>(false);
  const [selectedData, setSelectedData] = useState<any>();
  const [message, setMessage] = useState<string>("");
  const [isHelperText, setIsHelperText] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isMessageSent, setIsMessageSent] = useState<boolean>(false);

  useEffect(() => {
    let data = {
      selected_status: selected,
      published_content_type: selectedPublishType,
    };
    dispatch(getSubmissions(data));
  }, [selected, selectedPublishType]);

  const handleClick = (item: any) => {
    setSelected(item.name);
  };

  const handleFeedBackClick = (cardData: any) => {
    setIsHelperText(false);
    setIsFeedbackMessage(true);
    setSelectedData(cardData);
  };

  const handleFeedBackClose = () => {
    setIsFeedbackMessage(false);
  };

  const handleMessageChange = (e: any) => {
    setMessage(e.target.value);
  };

  const handleSubmit = (statusTitle: string, cardData: any) => {
    let isDecline = statusTitle.toLowerCase() === "decline";
    let tempSelectedData = cardData ? cardData : selectedData;

    if (isDecline && message === "") setIsHelperText(true);

    let formData = {
      submission: tempSelectedData?.id,
      published_content_type: selectedPublishType,
      approval_status:
        tempSelectedData.job_details.publish_content_type ===
        "collect_video_and_post"
          ? !isDecline
            ? tempSelectedData?.approval_status[0].approval_status ===
              STATUS.APPROVAL_NEEDED
              ? STATUS.CREATOR_POST_PENDING
              : tempSelectedData?.approval_status[0].approval_status ===
                STATUS.CREATOR_POST_PENDING
              ? STATUS.POST_CONFIRMATION_PENDING
              : tempSelectedData?.approval_status[0].approval_status ===
                STATUS.POST_CONFIRMATION_PENDING
              ? STATUS.ACCEPTED
              : STATUS.DECLINED
            : STATUS.DECLINED
          : !isDecline
          ? STATUS.ACCEPTED
          : STATUS.DECLINED,
      selectedPublishType: tempSelectedData.job_details.publish_content_type,
      feedback: message,
    };

    dispatch(
      updatePostStatus({
        formData,
        selected_status: selected,
        approval_status_id: tempSelectedData.approval_status[0].id,
      })
    )
      .then((res: any) => {
        setIsLoading(false);
        setIsMessageSent(true);
      })
      .catch((err: any) => {
        setIsLoading(false);
        setIsMessageSent(false);
      });
  };

  useEffect(() => {
    message && setIsHelperText(false);
  }, [message]);

  return (
    <Box>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Box>
          {newSubmissionList && newSubmissionList.length
            ? submissionTabTitle.map((item: any) => (
                <MChip
                  key={item.label}
                  className="category_chip"
                  label={item.label}
                  clickable
                  color="default"
                  onClick={() => handleClick(item)}
                  variant={
                    selected &&
                    selected.toLocaleLowerCase() ===
                      item.name.toLocaleLowerCase()
                      ? "filled"
                      : "outlined"
                  }
                />
              ))
            : ""}
        </Box>
      </Box>
      <Box mt={4}>
        {loadingState ? (
          <Box className="loader">
            <CircularProgress size={20} />
          </Box>
        ) : (
          <Grid container>
            {submissionList && submissionList.length
              ? submissionList.map((jobData: any, index: number) => (
                  <Grid key={jobData.id} item md={3} xs={12} sm={6}>
                    <SubmissionCard
                      cardData={jobData}
                      redirectURL={route_info.market_place.job_detail}
                      selectedPublishType={selectedPublishType}
                      handleFeedBackClick={handleFeedBackClick}
                      handleAccept={handleSubmit}
                    />
                  </Grid>
                ))
              : !loadingState && (
                  <Box className="no-data-found-block">
                    <Box
                      component="img"
                      src={PixelMelonFalling}
                      className="no-data-found-img"
                    />
                    <Typography variant="subtitle2">
                      {MARKET_PLACE.REQUEST_CONTENT.NO_SUBMISSION}
                    </Typography>
                  </Box>
                )}
          </Grid>
        )}
      </Box>
      <DeclineSubmissionMessageDialog
        isHelperText={isHelperText}
        isOpen={isFeedbackMessage}
        onClickClose={handleFeedBackClose}
        onClickSendMessage={handleSubmit}
        onChangeMessage={handleMessageChange}
        loader={isLoading}
        isMessageSent={isMessageSent}
      />
    </Box>
  );
}
