import {
  Alert,
  AlertTitle,
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { ChangeEvent, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { Blank_Image } from "assets";
import { useAppDispatch, useAppSelector } from "store/store";
import { getAccountDetails, updateAccountData } from "store/Thunk/settingThunk";
import Validations from "helper/validations";
import { ButtonLoading, ScreenLoading } from "store/Slice/AuthSlice";
import country_codes from "data/json/country_codes.json";
import { postResendOtp } from "store/Thunk/authThunk";

export default function AccountTab() {
  const [files, setFiles] = useState<File[]>([]);
  const [formError, setFormError] = useState<any>({});
  const [values, setValues] = useState<any>({
    user_id: "",
    name: "",
    email: "",
    phone_number: "",
    gender: "",
    company: "",
    country: {},
    profile_pic: "",
    profile_pic_url: "",
    is_verified_user: true,
  });
  const [countryList] = useState(country_codes);
  const dispatch: any = useAppDispatch();
  const { accountDetails } = useAppSelector((state) => state.settingSlice);
  const { screenLoading, buttonLoading } = useAppSelector(
    (state) => state.authReducer
  );

  useEffect(() => {
    dispatch(ScreenLoading(true));
    dispatch(getAccountDetails({}));
  }, []);

  useEffect(() => {
    let countryObj = {};
    if (accountDetails?.country) {
      countryObj = countryList.filter(
        (val) =>
          val.Name.toLocaleLowerCase() ===
          accountDetails?.country?.toLocaleLowerCase()
      )[0];
    }
    setValues({
      ...values,
      user_id: accountDetails?.id || "",
      name: `${accountDetails?.first_name} ${accountDetails.last_name}`,
      email: accountDetails?.email || "",
      phone_number: accountDetails?.phone_number || "",
      company: accountDetails?.company?.name || "",
      country: countryObj,
      gender: accountDetails?.gender,
      profile_pic: "",
      profile_pic_url: accountDetails?.profile_pic || "",
      is_verified_user: accountDetails?.is_verified_user || false,
    });
  }, [accountDetails]);

  useEffect(() => {
    if (files[0] && typeof files[0] == "object") {
      setValues({
        ...values,
        profile_pic: files[0],
      });
    } else {
      setValues({
        ...values,
        profile_pic: "",
        // profile_pic_url: "",
      });
    }
  }, [files]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleRadioChange = (event: ChangeEvent<HTMLInputElement>) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const handleSaveBtn = (event: any) => {
    let data = {
      name: values.name.trim(),
      email: values.email.trim(),
      phone_number: values.phone_number.trim(),
      company: values.company.trim(),
      country: values?.country?.Name?.toLocaleLowerCase(),
      gender: values?.gender || "",
    };

    let error = Validations.validateAccountForm(data);

    if (Object.entries(error).length === 0 && error.constructor === Object) {
      const _request = {
        first_name: data?.name?.split(" ")[0] || "",
        last_name: data?.name?.split(" ")[1] || "",
        email: data?.email || "",
        phone_number: data?.phone_number || "",
        company_name: data?.company || "",
        country: data?.country || "",
        gender: data?.gender || "",
        profile_pic: values?.profile_pic || "",
      };
      dispatch(ButtonLoading(true));
      dispatch(updateAccountData({ _request }));
    }

    setFormError(error);
  };

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    multiple: false,
    maxSize: 800000,
    accept: {
      "image/*": [".png", ".jpg"],
    },
    onDrop: (acceptedFiles: File[]) => {
      setFiles(acceptedFiles.map((file: File) => Object.assign(file)));
    },
    onDropRejected: () => {
      toast.error("You can only upload 1 files & maximum size of 2 MB.", {
        duration: 2000,
      });
    },
  });

  const isSuperUser = () => {
    const header: any = localStorage.getItem("header");
    const is_superuser = JSON.parse(header).is_superuser;

    return is_superuser ? true : false;
  };

  const getImgSrc = () => {
    if (values.profile_pic) {
      return URL.createObjectURL(values.profile_pic as any);
    } else if (values.profile_pic_url) {
      return values.profile_pic_url;
    } else {
      return Blank_Image;
    }
  };

  const handledCountryChange = (event: any, newValue: any) => {
    setValues({ ...values, country: newValue ? newValue : {} });
  };

  const handleOtpResend = () => {
    if (values?.user_id && values?.email) {
      const _request = {
        user_id: values?.user_id,
        email: values?.email,
      };
      dispatch(postResendOtp({ _request }));
    }
  };

  return (
    <Box sx={{ margin: 5 }}>
      {!values?.is_verified_user && (
        <Alert severity="warning">
          <AlertTitle>
            Your email is not confirmed. Please check your inbox.
          </AlertTitle>
          <Link onClick={handleOtpResend}>Resend Confirmation</Link>
        </Alert>
      )}

      <Box sx={{ mt: 5 }} className="sync-align-center">
        <Box sx={{ mr: 5 }}>
          <Box
            component="img"
            width={120}
            style={{ borderRadius: "10px" }}
            height={120}
            alt={files[0]?.name}
            src={getImgSrc()}
            className="single-file-image"
          />
        </Box>
        <Box>
          <Button
            variant="contained"
            sx={{
              mr: 5,
            }}
            {...getRootProps({ className: "dropzone gradient-btn" })}
          >
            Upload new photo
          </Button>
          <Button
            variant="outlined"
            disabled={!values?.profile_pic}
            onClick={() => setFiles([])}
          >
            Reset
          </Button>
          <Typography sx={{ fontSize: "12px", mt: 3 }}>
            Allowed JPG, GIF or PNG. Max size of 800K
          </Typography>
        </Box>
      </Box>

      <Box sx={{ mt: 5 }}>
        <Grid container spacing={5}>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Name"
              placeholder="Name"
              name="name"
              value={values?.name}
              onChange={(e: any) => handleChange(e)}
              error={formError && formError.name ? true : false}
              helperText={formError && formError.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <InputLabel error={formError && formError.gender ? true : false}>
              Gender
            </InputLabel>
            <RadioGroup
              row
              aria-label="controlled"
              name="gender"
              value={values?.gender}
              onChange={handleRadioChange}
            >
              <FormControlLabel value={1} control={<Radio />} label="Male" />
              <FormControlLabel value={2} control={<Radio />} label="Female" />
              <FormControlLabel value={0} control={<Radio />} label="Other" />
            </RadioGroup>
            {formError && formError.gender && (
              <FormHelperText error id="password-error">
                {formError.gender}
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Email"
              placeholder="Email"
              name="email"
              value={values?.email}
              onChange={(e: any) => handleChange(e)}
              error={formError && formError.email ? true : false}
              helperText={formError && formError.email}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <TextField
              fullWidth
              label="Phone"
              placeholder="Phone"
              name="phone_number"
              value={values?.phone_number}
              onChange={(e: any) => handleChange(e)}
              error={formError && formError.phone_number ? true : false}
              helperText={formError && formError.phone_number}
            />
          </Grid>
          {isSuperUser() && (
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Company"
                placeholder="Company"
                name="company"
                value={values?.company}
                onChange={(e: any) => handleChange(e)}
                error={formError && formError.company ? true : false}
                helperText={formError && formError.company}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <Autocomplete
              id="country"
              options={countryList}
              onChange={(event: any, newValue: any) =>
                handledCountryChange(event, newValue)
              }
              value={values?.country || ""}
              getOptionLabel={(option: any) => option?.Name || ""}
              renderInput={(params: any) => (
                <TextField
                  {...params}
                  placeholder="Search country..."
                  label="Country"
                  variant="outlined"
                />
              )}
            />
            {/* <TextField
              fullWidth
              label="Country"
              placeholder="Country"
              name="country"
              value={values.country}
              onChange={(e: any) => handleChange(e)}
              error={formError && formError.country ? true : false}
              helperText={formError && formError.country}
            /> */}
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <TextField fullWidth label="Role" placeholder="Role" />
          </Grid> */}
          {/* <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="form-layouts-separator-select-label">
                Status
              </InputLabel>
              <Select
                label="Status"
                defaultValue=""
                id="form-layouts-separator-select"
                labelId="form-layouts-separator-select-label"
              >
                <MenuItem value="UK">Active</MenuItem>
                <MenuItem value="USA">Inactive</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}
        </Grid>
        <Box sx={{ mt: 5 }}>
          <Button variant="outlined" sx={{ mr: 3 }}>
            CANCEL
          </Button>
          <Button
            variant="contained"
            onClick={handleSaveBtn}
            disabled={buttonLoading}
            className={!buttonLoading ? "gradient-btn" : ""}
            sx={{
              minWidth: "160px",
            }}
          >
            {buttonLoading ? <CircularProgress size={20} /> : "SAVE CHANGES"}
          </Button>
        </Box>
      </Box>
    </Box>
  );
}
